import React, { useState, useEffect } from 'react'
import { ShoppingBag, MapPin } from 'react-feather'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Badge, Typography, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux'

import { rider as RiderApi } from 'core.services/api'

import { selectors as citiesSelectors } from 'core.modules/cities/reducer';
import { makeRiderFullName } from 'core.utils/parser/rider'

export default function RiderAutcomplete({ order, rider = {}, selectedCity, handleRiderChange }) {
    const allCities = useSelector((state) => citiesSelectors.getAllCities(state));

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const [currRider, setCurrRider] = useState(rider)
    const [isLoading, setIsLoading] = useState(false)
    const [riders, setRiders] = useState([])    

    // const filterRiderSameCityAndOnline = (riders, order) => riders.filter((r) => r.status === 'online' && r.city === order.cityId.id).sort((a,b) => a.numberOfOrders - b.numberOfOrders)
    // const filterRiderSameCityAndOffline = (riders, order) => riders.filter((r) => r.status === 'offline' && r.city === order.cityId.id).sort((a,b) => a.numberOfOrders - b.numberOfOrders)

    // useEffect(() => {
    //     const riderSameCityAndOnline = filterRiderSameCityAndOnline(riders, order)
    //     const riderSameCityAndOffline = filterRiderSameCityAndOffline(riders, order)
    //     const ridersRest = riders.filter(r =>  {
    //       const index = [...riderSameCityAndOnline, ...riderSameCityAndOffline].map(r => r.id).indexOf(r.id)
    //       return index === -1
    //     })

    //     setRiders([...riderSameCityAndOnline, ...riderSameCityAndOffline, ...ridersRest])
    // }, [riders])
    
    useEffect(() => {
        setCurrRider(rider)
        /* setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 200) */
    }, [rider])     

    const getCityName = id => {
      const index = allCities.map((c) => c.id || c._id).indexOf(id)
      if (index > -1) return allCities[index].name
      return ""
    }

    const handleOpenChange = async (value) => {
      setOpen(value)
      if (!value) setRiders([])
      else setLoading(true)

      if (value) {
        try {
          let riders = []
          if (!selectedCity) {
            riders = await RiderApi.getActiveRiders()
          } else {
            riders = await RiderApi.getCityRiders(selectedCity)
          }
          setRiders(riders)
          setLoading(false)
        } catch (error) {
          setRiders([])
          setLoading(false)
        }
      }
    }

    const onChangeRider = (value) => {
      setCurrRider(value)
      const orderId = order.id || order._id
      handleRiderChange(orderId, value?.id ||value?._id)
    }

    if (isLoading) {
      return <div style={{width: 250}}></div>
    }

    return (
      <>
        {/* <Autocomplete                       
            freeSolo={true}
            disabled={order.deliveryType === 'pickup'}
            defaultValue={currRider?.firstName ? makeRiderFullName(currRider) : ''}
            value={currRider} 
            options={[...riders]}
            getOptionLabel={(option) => {
              if (typeof option === 'object') {
                if (!option.firstName) return ""
                return makeRiderFullName(option)
              }
              return option}}
            renderOption={(option) =>  (
              <React.Fragment>
                <div>
                  <p
                    style={{ margin:0  }}                  
                  >
                    <Badge color="primary" variant="dot" invisible={option.status !== 'online'}>
                      <Typography style={{ fontWeight: option.city === order.cityId.id ? "bold" : "300"  }}>{makeRiderFullName(option)}</Typography>
                    </Badge>                     
                  </p>
                  <p style={{color: "grey", marginTop:5, marginBottom:0}}>
                    <ShoppingBag size={14} /> {option.numberOfOrders}
                  </p>                                    
                  <p style={{color: "grey", marginTop:5, marginBottom:0}}>
                    <MapPin size={14} /> {getCityName(option.city)} 
                  </p>                                    
                </div>
              </React.Fragment>
            )}
            autoHighlight
            style={{ width: 150, backgroundColor: order.deliveryType === 'pickup' ? "lightgrey": "transparent"  }}
            onChange={(e, value) => {onChangeRider(value)}}
            renderInput={(params) => <TextField {...params} variant="outlined" style={{fontWeight:"bold"}} />}        
          /> */}
          <Autocomplete
              id="riders"
              // freeSolo={true}
              disabled={order.deliveryType === 'pickup'}
              defaultValue={currRider?.firstName ? makeRiderFullName(currRider) : ''}
              style={{ width: 150, backgroundColor: order.deliveryType === 'pickup' ? "lightgrey": "transparent"  }}
              open={open}
              onOpen={() => handleOpenChange(true)}
              onClose={() => handleOpenChange(false)}
              value={currRider} 
              options={riders}
              getOptionLabel={(option) => {
                if (typeof option === 'object') {
                  return option.firstName ? makeRiderFullName(option) : ""
                }
                return option
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <div>
                    <p
                      style={{ margin:0  }}                  
                    >
                      <Badge color="primary" variant="dot" invisible={option.status !== 'online'}>
                        <Typography style={{ fontWeight: option.city == (order.cityId?.id || order.cityId?._id) ? "bold" : "300"  }}>{makeRiderFullName(option)}</Typography>
                      </Badge>                     
                    </p>
                    <p style={{color: "grey", marginTop:5, marginBottom:0}}>
                      <ShoppingBag size={14} /> {option.numberOfOrders}
                    </p>                                    
                    <p style={{color: "grey", marginTop:5, marginBottom:0}}>
                      <MapPin size={14} /> {getCityName(option.city)} 
                    </p>                                    
                  </div>
                </React.Fragment>
              )}
              isOptionEqualToValue={(option, value) => option._id == value._id}
              onChange={(e, value) => onChangeRider(value)}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Livreur"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          {currRider && currRider.phone && <span style={{color:"grey"}} >{currRider?.firstName} {currRider?.lastName} - Tél: {currRider.phone}</span>}
        </>
    )
}
