import React, {useEffect, useState} from 'react'
import List from 'core.ui/List'
import { cityLog, city } from 'core.services/api'
import {useParams} from "react-router-dom"
import { makeDateString } from 'core.utils/parser/time'

const CityLogs = () => {
    const {id} = useParams();
    const [logs, setLogs] = useState([]);
    const [currentCity, setCurrentCity] = useState(null)
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
      currentPage: 1,
      numberPerPage: 20,
    })

    useEffect(() => {
      // getCity();
      getLogs();

      async function getLogs () {
          let parameters = { cityId: id, page: state.currentPage, limit: state.numberPerPage }

          setLoading(true)

          const data = await cityLog.get(parameters)
          setLogs(data)
          setLoading(false)
      }

      async function getCity() {
        if(id) {
          const data = await city.getCityById(id)

          setCurrentCity(data)
        }
        
      }
    }, [state.currentPage])

    const makeRows = (log, logIndex) => {
        const {
            by,
            adminFirstName,
            adminLastName,
            message,
            created_at
        } = log;

        return [
            makeDateString(created_at),
            by === "cron" ? "Auto" : adminFirstName + " " + adminLastName,
            message
        ]
    }

    const handlePageChange = page => {
      setState({...state, currentPage: page }, () => {
        this.getRestaurantRequests()
      })
    }

    const parsedLogs = logs.map(makeRows)
    const ids = logs.map((log) => log._id)

    return (
        <React.Fragment>
          {/* <div style={{display: "flex", justifyContent: "center"}}>
            <h1>Ville: {currentCity?.name}</h1>
          </div> 
          <hr />*/}
          <List
            list={parsedLogs}
            columns={[
              'Date de création',
              'Auteur',
              'Message',
            ]} 
            ids={ids}
            actions={null}
            emptyMessage={loading ? "Chargement des données..." : "Aucun log disponible"}
            customPage={state.currentPage}
            numberPerPage={state.numberPerPage}
            onPageChange={handlePageChange}
          />
          
        </React.Fragment>
  )
}

export default CityLogs;
