import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import MuiAlert from '@material-ui/lab/Alert';
import { requestStates } from 'core.utils/requestStates'
import classnames from 'classnames'
import Snackbar from 'core.ui/Snackbar'
import { makeStyles } from '@material-ui/core/styles';

import { Button, Checkbox, FormControl, FormControlLabel, MenuItem, TextField, Typography } from '@material-ui/core'
import { createPromoCode, selectors as createPromoSelector } from '../../core.modules/promoCode/reducer'
import { promoCode } from '../../core.services/api'
import Autocomplete from '@material-ui/lab/Autocomplete';


const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  buttons: {
    display: 'flex',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class promoCodeForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
        code:undefined,
        title: "Un petit plaisir pour nous faire pardonner",
        type:'standard',
        startDate: null,
        endDate: null,
        discount:undefined,
        users:[], 
        minAmount:0,
        maxUsage: 1,
        maxUsagePerUser: 1,
        isPaidByRestaurant:false,
        waitingForRequest: false, 
        openSnackBar:false,
        snackBarSeverity: '',
        codeCheckBox: -1,
        titleCheckBox: -1,
        maxUsageCheckBox:0,
        maxUsagePerUserCheckBox:0,
        open:false, 
        setOpen:false, 
        emailError:'',
        dateError:'',
        titleIsEmpty:'', 
        discountIsEmpty:'',
        codeError:'',
        maxUsageError:'',
        maxUsagePerUserError:'',
        userPromoCode:true,
        cityPromoCode:false,
        restaurantPromoCpde:false,
        cityWhiteList:[],
        cityName:''


    }
  }
  

  componentDidUpdate(prevProps) {
    const { currentRequestState, errorMessage } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      // this.props.history.goBack()
      this.initState()
      this.setState({ waitingForRequest: false, openSnackBar: true, snackBarSeverity: 'success' })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {

      this.setState({ waitingForRequest: false, openSnackBar: true, snackBarSeverity: 'error' })
    }
  }

  handleCodeChange = e => {
    this.setState({ code: e.target.value })
  }

  handleTypeChange = e => {
    this.setState({ type: e.target.value })
  }
  handleTitleChange = e => {
    if (this.state.titleCheckBox ===0)
    this.setState({ title: e.target.value })
  
  }

  handleIsPaidParRestaurantChange = e => {
    this.setState({isPaidByRestaurant: e.target.value})
  }
  
  handleEmailChange = e => {
    let users = []
    users.push(e.target.value)
    this.setState({users: users})
  }
  handleRestaurantEmailChange = e => {
    let restaurantWhiteList = []
    restaurantWhiteList.push(e.target.value)
    this.setState({restaurantWhiteList: restaurantWhiteList})
  }
  handleDiscountChange = e => {
    this.setState({discount: e.target.value})
  }
  handleMaxUsageChange = e => {
    this.setState({maxUsage: e.target.value})
  }
  handleMaxUsagePerUserChange = e => {
    this.setState({maxUsagePerUser: e.target.value})
  }
  handleMinAmountChange = e => {
    this.setState({minAmount: e.target.value})
  }
  handleStartDateChange = e => {
    this.setState({startDate: e.target.value})
  }
  handleEndDateChange = e => {
    this.setState({endDate: e.target.value})
  }
  handleCityChange = (e, value) => {
    let cityWhiteList= []
    cityWhiteList.push(value.id)
    this.setState({cityWhiteList: cityWhiteList, title:`Profitez de Lyveat sur ${value.name}`})
  
  }
  
  initState = () => {
    this.setState ({
      code:'',
      title:'',
      type:'standard',
      startDate: null,
      endDate: null,
      discount:'',
      users:[],
      cityWhiteList:[],
      restaurantWhiteList:[],
      minAmount:0,
      maxUsage: 1,
      maxUsagePerUser:1,
      isPaidByRestaurant:false,
      waitingForRequest: false, 
      openSnackBar:false,
      codeCheckBox:1,
      titleCheckBox:1,
      maxUsageCheckBox:0,
      maxUsagePerUserCheckBox:0,
      emailError:'',
      dateError:'',
      maxUsageError:'',
      maxUsagePerUserError:'',
      titleIsEmpty:'', 
      discountIsEmpty:'',
      userPromoCode:true,
      cityPromoCode:false,
      restaurantPromoCpde:false 
    })
  }

  onPromoCodeSelect = (e) => {
   
    if(e.target.value == "Promo Code Utilisateur") 
      this.setState({userPromoCode:true, cityPromoCode:false, restaurantPromoCpde:false, title:`Un petit plaisir pour nous faire pardonner`})
    
    if(e.target.value == "Promo Code Restaurant") 
      this.setState({titleCheckBox:0 ,userPromoCode:false, cityPromoCode:false, restaurantPromoCpde:true, code:'', codeCheckBox:0, title:``,maxUsagePerUser:undefined, maxUsage:undefined})

    if(e.target.value == "Promo Code Ville") 
      this.setState({userPromoCode:false, cityPromoCode:true, restaurantPromoCpde:false, title:`Profitez de Lyveat sur ville`, code:'',  codeCheckBox:0, maxUsagePerUser:undefined, maxUsage:undefined})
    
    
     
  }

  
  validate = (type) => {

    let emailError = "" 
    let dateError= "" 
    let titleIsEmpty = ""
    let discountIsEmpty = "" 
    let codeError= ""
    let maxUsageError=""
    let maxUsagePerUserError=""
    if (type == "user") {  
      if (!this.state.users.length || !this.state.users[0].includes("@")) {
      emailError = "*Email invalide"
    }
  }
  if(type == "restaurant") {
    if (!this.state.restaurantWhiteList.length || !this.state.restaurantWhiteList[0].includes("@")) {
      emailError = "*Email invalide"
    }
  }

  if(type == "city") {
    if (!this.state.cityWhiteList.length) {
      emailError = "*Aucune ville sélectionnée"
    }
  }

    if (!this.state.startDate) {
        dateError= "Date de début est requise"
    }
    if(this.state.endDate < this.state.startDate){
        dateError= "*Date de fin est supérieure à date de début"
    }
    if(!this.state.title && this.state.titleCheckBox == 0){
      titleIsEmpty= "*Vous devez entrer un titre ou choisir le titre par défaut"
     
    }
    if(!this.state.discount){
      discountIsEmpty= "*Remise ne peut pas  être vide " 
    }
    if(!this.state.code && this.state.codeCheckBox == 0)
    {
      codeError = "*Vous devez entrer un code ou choisir un code aléatoire"
    }
    
    if( emailError || dateError || titleIsEmpty || discountIsEmpty || codeError ){
      this.setState({dateError, titleIsEmpty, discountIsEmpty, codeError, emailError})
      return false 
    }
    return true

  }

  handleAddPromoCode = (type) => {
    const isValid = this.validate(type)
    if (isValid) { 
      this.setState({ waitingForRequest: true })
      const { code, title, type, startDate, endDate, discount, restaurantWhiteList, restaurantBlackList, cityWhiteList, cityBlackList, users, minAmount, maxUsage, maxUsagePerUser, isPaidByRestaurant} = this.state
      const promoCodeInfos = { 
        code, type, title, startDate: new Date(startDate), endDate: endDate && new Date(endDate), discount, 
        restaurantWhiteList, restaurantBlackList, cityWhiteList, cityBlackList, users, minAmount, maxUsage, maxUsagePerUser, isPaidByRestaurant
      }

      this.props.createPromoCode(promoCodeInfos)
      if(type == "user")  this.setState({userPromoCode:true, cityPromoCode:false, restaurantPromoCpde:false})
      if(type == "restaurant")  this.setState({userPromoCode:false, cityPromoCode:false, restaurantPromoCpde:true})
      if(type == "city")  this.setState({userPromoCode:false, cityPromoCode:true, restaurantPromoCpde:false})
  
    }
  }

  handleCloseSnackbar = (event, reason) =>  {
    this.setState({ openSnackBar: false,open: false })
      if (reason === 'clickaway') {
        return;
      }
  
      
  }

  handleSubmitForm = codePromoInfos => {
    this.setState({ waitingForRequest: true})
  }

  handleCancel = () => {
    this.props.history.goBack()
  }
  toggleCheckboxCode = () => this.setState({ codeCheckBox: this.state.codeCheckBox === 0 ? -1 : 0 , code: this.state.codeCheckBox === -1 ? undefined : undefined });
  toggleCheckboxTitle = () => this.setState({ titleCheckBox: this.state.titleCheckBox === 0 ? -1 : 0  , title: this.state.titleCheckBox === -1 ? undefined :'Un petit plaisir pour nous faire pardonner' });
  toggleCityCheckboxTitle = () => this.setState({ titleCheckBox: this.state.titleCheckBox === 0 ? -1 : 0  , title: this.state.titleCheckBox === -1 ? undefined : this.state.title});
  toggleCheckboxMaxUsagePerUser = () => this.setState({ maxUsagePerUserCheckBox: this.state.maxUsagePerUserCheckBox === 0 ? -1 : 0 })
  toggleCheckboxMaxUsage = () => this.setState({ maxUsageCheckBox: this.state.maxUsageCheckBox === 0 ? -1 : 0 })

  render() {
    const trueOrFalse = [
      {
        value: false,
        label: 'Faux'
      },
      {
        value: true,
        label: 'Vrai'
      }
    
    ]
    const { classes, allCities } = this.props
    //const { code, users, title, isPaidByRestaurant, startDate, endDate, discount, maxUsage, maxUsagePerUser, minAmount } = this.state
    return (
      <>
      <div align="center" >
        <div>
          <span  align="center" style={{fontSize:25}}>Select Promo Code type</span> :
         
            <div align="center" >

              <select onChange={this.onPromoCodeSelect}>
              
                 <option>Promo Code Utilisateur</option>;
                 <option>Promo Code Ville</option>;
                 <option>Promo Code Restaurant</option>;
            
              </select>
            </div>
        
        </div>
      </div>
      <br></br>
    { this.state.userPromoCode && (<React.Fragment>
      <div style={styles.mainListContainer}>
        <Typography
        style={{ textAlign: 'center' }}
        variant="h5"
        component="h5"
        >
         
          Ajouter un code promo utilisateur
        </Typography>
        <div style={styles.header}>
        <div><strong>Email d'utilisateur: </strong></div>
        <TextField
          className={classes.rows}
          required={true}
          value={this.state.users}
          label="Email d'utilisateur"
          
          onChange={this.handleEmailChange}
        />
        <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.emailError}
        </div>
       <div style={{display:"flexDirection", justifyContent:"space-between" }}>
       <br></br>
       <div><strong>Code promo: </strong></div>
        <TextField
              placeholder="Code promo"
              value={this.state.code}
              onChange={this.handleCodeChange}
              disabled= {this.state.codeCheckBox === -1}
              fullWidth
            />
           

        <FormControlLabel
        
     control={
    <Checkbox
     checked={this.state.codeCheckBox === -1}
      onChange={this.toggleCheckboxCode}
      name="Code promo" 
      color="primary"
    />}
  label="Générer un code aléatoire"
/>  

  </div>   
  <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.codeError}
        </div>
  <br></br>
  <div style={{display:"flexDirection", justifyContent:"space-between" }}> 
  <div><strong>Titre: </strong></div>         
        <TextField
              
              placeholder="Titre"
              value={this.state.title}
              onChange={this.handleTitleChange}
              disabled={this.state.titleCheckBox === -1}
              fullWidth
              required
            />
       <FormControlLabel
        
        control={
       <Checkbox
        checked={this.state.titleCheckBox === -1}
         onChange={this.toggleCheckboxTitle}
         name="Titre"
         color="primary"
       />}
     label="Utiliser titre par défaut"
   />  
   <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.titleIsEmpty}
        </div>
   
     </div>  
     
          
            <br></br>
            <div><strong>Payé par restaurant: </strong></div>
            <TextField
              placeholder="Payé par restaurant"
              value={this.state.isPaidByRestaurant}
              onChange={this.handleIsPaidParRestaurantChange}
              fullWidth
              select
            >
             {trueOrFalse.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
          <br></br>
          <div><strong>Date de début: </strong></div>
          <TextField
             id="datetime-local"
            placeholder="Date de début"
            type="datetime-local"
            value={this.state.startDate}
            className={classes.textField}
            onChange={ this.handleStartDateChange}
            InputLabelProps={{
            shrink: true,
            }}
      />
                <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.dateError}
        </div>
      <br></br>
      <div><strong>Date de fin: </strong></div>
           <TextField
              id="datetime-local"
              placeholder="Date de fin"
              type="datetime-local"
              value={this.state.endDate}
              className={classes.textField}
              onChange={this.handleEndDateChange}
              InputLabelProps={{
                shrink: true,
             }}
          />
      <br></br>
      <div><strong>Remise: </strong></div>
      <TextField
              required
              placeholder="Remise"
              type="number"
              InputProps={{ inputProps: { min: 1 }}}
              value={this.state.discount}
              onChange={this.handleDiscountChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.discountIsEmpty}
        </div>
            <br></br>
            
        <div style={{display:"flexDirection", justifyContent:"space-between" }}>  
        <div><strong>Nombre d'utilisation maximal: </strong></div>
        <TextField
              placeholder="Nombre d'utilisation maximal"
              type="number"
              value={this.state.maxUsage}
              InputProps={{ inputProps: { min: 1 }}}
              onChange={this.handleMaxUsageChange}
              disabled={true}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.maxUsageError}
        </div>
            
             {/* <FormControlLabel
        
       control={
       <Checkbox
        checked={this.state.maxUsageCheckBox === -1}
         onChange={this.toggleCheckboxMaxUsage}
         name="Max usage "
         color="primary"
       />}
     label="Illimité"
   />   */}
            </div>
            <br></br>
           
            <div style={{display:"flexDirection", justifyContent:"space-between" }}> 
            <div><strong>Nombre d'utilisation maximal par utilisateur: </strong></div> 
            <TextField
              placeholder="Nombre d'utilisation maximal par utilisateur"
              type="number"
              InputProps={{ inputProps: { min: 1 }}}
              value={this.state.maxUsagePerUser}
              onChange={this.handleMaxUsagePerUserChange}
              disabled={true}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
             {this.state.maxUsagePerUserError}
        </div>
             {/* <FormControlLabel
        
        control={
       <Checkbox
        checked={this.state.maxUsagePerUserCheckBox === -1}
         onChange={this.toggleCheckboxMaxUsagePerUser}
         name="Max usage par utilisateur"
         color="primary"
       />}
     label="Illimité"
   />   */}
   
     </div>
            <br></br>
            <div><strong>Montant minimum: </strong></div>
            <TextField
              placeholder="montant minimum"
              type="number"
              InputProps={{ inputProps: { min: 0 }}}
              value={this.state.minAmount}
              onChange={this.handleMinAmountChange}
              fullWidth
            />
            <br></br>
        </div>
        <div style={styles.buttons}>
       
        <Button
          variant="contained"
          color="primary"
          onClick={() => {this.handleAddPromoCode("user")}}
        >
          Ajouter un code promo
        </Button>
        <Button onClick={this.handleCancel} variant="contained" color="secondary">
          Annuler
        </Button>
        </div>
      </div>
      <Snackbar 
        open={this.state.openSnackBar} 
        autoHideDuration={6000}
        severity={this.state.snackBarSeverity}
        message={this.state.snackBarSeverity === 'success' ? 'Code ajouté avec succès' : this.props.errorMessage} 
        onClose={this.handleCloseSnackbar}
      >
      </Snackbar>
    </React.Fragment>
    )} 
    { this.state.restaurantPromoCpde && (<React.Fragment>
      <div style={styles.mainListContainer}>
        <Typography
        style={{ textAlign: 'center' }}
        variant="h5"
        component="h5"
        >
          Ajouter un code promo pour restaurant
        </Typography>
        <div style={styles.header}>
        <div><strong>Email restaurant: </strong></div>
        <TextField
          className={classes.rows}
          required={true}
          value={this.state.restaurantWhiteList}
          label="Email restaurant"
          
          onChange={this.handleRestaurantEmailChange}
        />
        <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.emailError}
        </div>
       <div style={{display:"flexDirection", justifyContent:"space-between" }}>
       <br></br>
       <div><strong>Code promo: </strong></div>
        <TextField
              placeholder="Code promo"
              value={this.state.code}
              onChange={this.handleCodeChange}
              fullWidth
            />

  </div>   
  <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.codeError}
        </div>
  <br></br>
  <div style={{display:"flexDirection", justifyContent:"space-between" }}> 
  <div><strong>Titre: </strong></div>         
        <TextField
              
              placeholder="Titre"
              value={this.state.title}
              onChange={this.handleTitleChange}
              fullWidth
              required
            />  
   <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.titleIsEmpty}
        </div>
   
     </div>  
     
          
            <br></br>
            <div><strong>Payé par restaurant: </strong></div>
            <TextField
              placeholder="Payé par restaurant"
              value={this.state.isPaidByRestaurant}
              onChange={this.handleIsPaidParRestaurantChange}
              fullWidth
              select
            >
             {trueOrFalse.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
          <br></br>
          <div><strong>Date de début: </strong></div>
          <TextField
             id="datetime-local"
            placeholder="Date de début"
            type="datetime-local"
            value={this.state.startDate}
            className={classes.textField}
            onChange={ this.handleStartDateChange}
            InputLabelProps={{
            shrink: true,
            }}
      />
                <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.dateError}
        </div>
      <br></br>
      <div><strong>Date de fin: </strong></div>
           <TextField
              id="datetime-local"
              placeholder="Date de fin"
              type="datetime-local"
              value={this.state.endDate}
              className={classes.textField}
              onChange={this.handleEndDateChange}
              InputLabelProps={{
                shrink: true,
             }}
          />
      <br></br>
      <div><strong>Remise: </strong></div>
      <TextField
              required
              placeholder="Remise"
              type="number"
              InputProps={{ inputProps: { min: 1 }}}
              value={this.state.discount}
              onChange={this.handleDiscountChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.discountIsEmpty}
        </div>
            <br></br>
            
        <div style={{display:"flexDirection", justifyContent:"space-between" }}>  
        <div><strong>Nombre d'utilisation maximal: </strong></div>
        <TextField
              placeholder="Nombre d'utilisation maximal"
              type="number"
              value={this.state.maxUsage}
              InputProps={{ inputProps: { min: 1 }}}
              onChange={this.handleMaxUsageChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
             {this.state.maxUsageError}
        </div>
            
             {/* <FormControlLabel
        
       control={
       <Checkbox
        checked={this.state.maxUsageCheckBox === -1}
         onChange={this.toggleCheckboxMaxUsage}
         name="Max usage "
         color="primary"
       />}
     label="Illimité"
   />   */}
            </div>
            <br></br>
           
            <div style={{display:"flexDirection", justifyContent:"space-between" }}> 
            <div><strong>Nombre d'utilisation maximal par utilisateur: </strong></div> 
            <TextField
              placeholder="Nombre d'utilisation maximal par utilisateur"
              type="number"
              InputProps={{ inputProps: { min: 1 }}}
              value={this.state.maxUsagePerUser}
              onChange={this.handleMaxUsagePerUserChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
             {this.state.maxUsagePerUserError}
        </div>
             {/* <FormControlLabel
        
        control={
       <Checkbox
        checked={this.state.maxUsagePerUserCheckBox === -1}
         onChange={this.toggleCheckboxMaxUsagePerUser}
         name="Max usage par utilisateur"
         color="primary"
       />}
     label="Illimité"
   />   */}
   
     </div>
            <br></br>
            <div><strong>Montant minimum: </strong></div>
            <TextField
              placeholder="montant minimum"
              type="number"
              InputProps={{ inputProps: { min: 0 }}}
              value={this.state.minAmount}
              onChange={this.handleMinAmountChange}
              fullWidth
            />
            <br></br>
        </div>
        <div style={styles.buttons}>
       
        <Button
          variant="contained"
          color="primary"
          onClick={() => {this.handleAddPromoCode("restaurant")}}
        >
          Ajouter un code promo
        </Button>
        <Button onClick={this.handleCancel} variant="contained" color="secondary">
          Annuler
        </Button>
        </div>
      </div>
      <Snackbar 
        open={this.state.openSnackBar} 
        autoHideDuration={6000}
        severity={this.state.snackBarSeverity}
        message={this.state.snackBarSeverity === 'success' ? 'Code ajouté avec succès' : this.props.errorMessage} 
        onClose={this.handleCloseSnackbar}
      >
      </Snackbar>
    </React.Fragment>
    )}
    { this.state.cityPromoCode && (<React.Fragment>
      <div style={styles.mainListContainer}>
        <Typography
        style={{ textAlign: 'center' }}
        variant="h5"
        component="h5"
        >
          Ajouter un code promo pour ville
        </Typography>
        <div style={styles.header}>
        <div><strong>Ville: </strong></div>
        <Autocomplete
        id="combo-box-demo"
        options={allCities}
        getOptionLabel={(city) => city.name}
        style={{ width: 300 }}
        onChange={this.handleCityChange}
        renderInput={(params) => (
          <TextField {...params} label="Villes" variant="outlined" />
        )}
      />
       <div style={{display:"flexDirection", justifyContent:"space-between" }}>
       <br></br>
       <div><strong>Code promo: </strong></div>
        <TextField
              placeholder="Code promo"
              value={this.state.code}
              onChange={this.handleCodeChange}
              fullWidth
            />

  </div>   
  <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.codeError}
        </div>
  <br></br>
  <div style={{display:"flexDirection", justifyContent:"space-between" }}> 
  <div><strong>Titre: </strong></div>         
        <TextField
              
              placeholder="Titre"
              value={this.state.title}
              onChange={this.handleTitleChange}
              disabled={this.state.titleCheckBox === -1}
              fullWidth
              required
            />
       <FormControlLabel
        
        control={
       <Checkbox
        checked={this.state.titleCheckBox === -1}
         onChange={this.toggleCityCheckboxTitle}
         name="Titre"
         color="primary"
       />}
     label="Utiliser titre par défaut"
   />  
   <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.titleIsEmpty}
        </div>
   
     </div>  
     
          
            <br></br>
            <div><strong>Payé par restaurant: </strong></div>
            <TextField
              placeholder="Payé par restaurant"
              value={this.state.isPaidByRestaurant}
              onChange={this.handleIsPaidParRestaurantChange}
              fullWidth
              select
            >
             {trueOrFalse.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
          <br></br>
          <div><strong>Date de début: </strong></div>
          <TextField
             id="datetime-local"
            placeholder="Date de début"
            type="datetime-local"
            value={this.state.startDate}
            className={classes.textField}
            onChange={ this.handleStartDateChange}
            InputLabelProps={{
            shrink: true,
            }}
      />
                <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.dateError}
        </div>
      <br></br>
      <div><strong>Date de fin: </strong></div>
           <TextField
              id="datetime-local"
              placeholder="Date de fin"
              type="datetime-local"
              value={this.state.endDate}
              className={classes.textField}
              onChange={this.handleEndDateChange}
              InputLabelProps={{
                shrink: true,
             }}
          />
      <br></br>
      <div><strong>Remise: </strong></div>
      <TextField
              required
              placeholder="Remise"
              type="number"
              InputProps={{ inputProps: { min: 1 }}}
              value={this.state.discount}
              onChange={this.handleDiscountChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
          {this.state.discountIsEmpty}
        </div>
            <br></br>
            
        <div style={{display:"flexDirection", justifyContent:"space-between" }}>  
        <div><strong>Nombre d'utilisation maximal: </strong></div>
        <TextField
              placeholder="Nombre d'utilisation maximal"
              type="number"
              value={this.state.maxUsage}
              InputProps={{ inputProps: { min: 1 }}}
              onChange={this.handleMaxUsageChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
             {this.state.maxUsageError}
        </div>
            
             {/* <FormControlLabel
        
       control={
       <Checkbox
        checked={this.state.maxUsageCheckBox === -1}
         onChange={this.toggleCheckboxMaxUsage}
         name="Max usage "
         color="primary"
       />}
     label="Illimité"
   />   */}
            </div>
            <br></br>
           
            <div style={{display:"flexDirection", justifyContent:"space-between" }}> 
            <div><strong>Nombre d'utilisation maximal par utilisateur: </strong></div> 
            <TextField
              placeholder="Nombre d'utilisation maximal par utilisateur"
              type="number"
              InputProps={{ inputProps: { min: 1 }}}
              value={this.state.maxUsagePerUser}
              onChange={this.handleMaxUsagePerUserChange}
              fullWidth
            />
            <div style={{fontSize: 16 , color: 'red'}}>
             {this.state.maxUsagePerUserError}
        </div>
             {/* <FormControlLabel
        
        control={
       <Checkbox
        checked={this.state.maxUsagePerUserCheckBox === -1}
         onChange={this.toggleCheckboxMaxUsagePerUser}
         name="Max usage par utilisateur"
         color="primary"
       />}
     label="Illimité"
   />   */}
   
     </div>
            <br></br>
            <div><strong>Montant minimum: </strong></div>
            <TextField
              placeholder="montant minimum"
              type="number"
              InputProps={{ inputProps: { min: 0 }}}
              value={this.state.minAmount}
              onChange={this.handleMinAmountChange}
              fullWidth
            />
            <br></br>
        </div>
        <div style={styles.buttons}>
       
        <Button
          variant="contained"
          color="primary"
          onClick={() => {this.handleAddPromoCode("city")}}
        >
          Ajouter un code promo
        </Button>
        <Button onClick={this.handleCancel} variant="contained" color="secondary">
          Annuler
        </Button>
        </div>
      </div>
      <Snackbar 
        open={this.state.openSnackBar} 
        autoHideDuration={6000}
        severity={this.state.snackBarSeverity}
        message={this.state.snackBarSeverity === 'success' ? 'Code ajouté avec succès' : this.props.errorMessage} 
        onClose={this.handleCloseSnackbar}
      >
      </Snackbar>
    </React.Fragment>
    )}</>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: createPromoSelector.getRequestState(state),
  errorMessage: createPromoSelector.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  createPromoCode: (promoCodeInfos) => {
    dispatch(createPromoCode(promoCodeInfos))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(promoCodeForm)