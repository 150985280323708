import {
  ADD_TEMPLATE,
  GET_TEMPLATE,
  TEMPLATE_ERROR,
  CHECK_TEMPLATE,
  DISCARD_TEMPLATE,
  GET_TEMPLATE_PRODUCTS,
  GET_TEMPLATE_SUPP,
  GET_TEMPLATE_OPTIONS,
  CLEAR,
  DUPLICATE_PRODUCTS,
  DUPLICATE_TEMPLATE,
  DELETE_OPTION,
  CONFIRM_DELETE_OPTION,
  SYNC_SUPPLEMENTS,
  SYNC_PRODUCTS,
  SYNC_OPTIONS,
  ASSIGN_RESTAURANT,
  DELETE_SUPPLEMENT,
  TEMPLATE_DRAG_DROP_SUCCEEDED
} from './Types'
import { product, template } from '../../core.services/api'
import { createRequestActions } from '../../core.utils/requestActions'

export const getTemplates = () => async (disptach) => {
  try {
    const res = await template.getTemplate()
    disptach({
      type: GET_TEMPLATE,
      payload: res
    })
  } catch (error) {
    disptach({
      type: TEMPLATE_ERROR,
    })
  }
}

export const fetchTemplateDragAndDropRequested = (ProductList, templateId) => async (dispatch) =>{
  await template.updateProductlist(templateId, ProductList.map((product, index) => ({ _id: product._id, order: index+1 })))
    //setup all products in redux State
    try {
      dispatch({
        type: TEMPLATE_DRAG_DROP_SUCCEEDED,
        payload: { data: ProductList }
      })
    } catch (error) {
      dispatch({ type: TEMPLATE_ERROR })
    }
  }

export const addTemplate = (formData) => async (dispatch) => {
  try {
    const res = await template.addTemplate(formData)
    dispatch({
      type: ADD_TEMPLATE,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}
export const checkifTemplate = (id) => (dispatch) => {
  dispatch({
    type: CHECK_TEMPLATE,
    payload: id 
  })
}
export const discardTemplate = () => (dispatch) => {
  dispatch({
    type: DISCARD_TEMPLATE
  })
}
export const getproducts = (id) => async (dispatch) => {
  try {
    const res = await template.getproductsBytemplateId(id)
    dispatch({ type: GET_TEMPLATE_PRODUCTS, payload: res })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}

export const getsupplements = (id) => async (dispatch) => {
  try {
    const res = await template.getSupplementsBytemplateId(id)
    dispatch({ type: GET_TEMPLATE_SUPP, payload: res.supplements })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}

export const getoptions = (id) => async (dispatch) => {
  try {
    const res = await template.getOptionBytemplateId(id)
    dispatch({ type: GET_TEMPLATE_OPTIONS, payload: res.options })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}
export const ClearTemplates = () => (dispatch) => {
  dispatch({
    type: CLEAR
  })
}
export const duplicateProducts = (id) => async (dispatch) => {
  try {
    const res = await product.duplicate(id)
    dispatch({
      type: DUPLICATE_PRODUCTS,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}
export const duplicateTemplate = (id, newName) => async (dispatch) => {
  try {
    const res = await template.duplicateTemplate(id, newName)
    dispatch({
      type: DUPLICATE_TEMPLATE,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}
export const deleteOption = (templateId, optionId) => async (dispatch) => {
  try {
    const res = await template.deleteOption(templateId, optionId)
    dispatch({
      type: DELETE_OPTION,
      payload:optionId
    })
  } catch (error) {
    dispatch({
      type: TEMPLATE_ERROR
    })
  }
}
export const confirmDeleteOption =
  (templateId, optionId) => async (dispatch) => {
    try {
      const res = await template.confirmDeleteOption(templateId, optionId)
      dispatch({ type: CONFIRM_DELETE_OPTION, payload: res.data })
    } catch (error) {
      dispatch({ type: TEMPLATE_ERROR })
    }
  }
export const syncProducts = (templateId) => async (dispatch) => {
  try {
    const res = await template.syncProducts(templateId)
    dispatch({ type: SYNC_PRODUCTS, payload: res.data })
  } catch (error) {
    dispatch({ type: TEMPLATE_ERROR ,payload:'Erreur survenue'})
  }
}
export const syncOptions = (templateId) => async (dispatch) => {
  try {
    const res = await template.syncOptions(templateId)
    dispatch({ type: SYNC_OPTIONS, payload: res.data })
  } catch (error) {
    dispatch({ type: TEMPLATE_ERROR,payload:'Erreur survenue' })
  }
}
export const syncSupplements = (templateId) => async (dispatch) => {
  try {
    const res = await template.syncSupplements(templateId)
    dispatch({ type: SYNC_SUPPLEMENTS, payload: res.data })
  } catch (error) {
    dispatch({ type: TEMPLATE_ERROR })
  }
}
export const assignRestaurants =(templateId,body)=>async (dispatch)=>{
  try {
    const res = await template.restaurantsGroup(templateId,body)

    dispatch({
      type:ASSIGN_RESTAURANT,
      payload:res.data
    })
  } catch (error) {
   
    dispatch({ type: TEMPLATE_ERROR, payload:'Erreur survenue' })
  }
}
export const deleteSupplement =(templateId,supplementId)=>async dispatch=>{
  try {
    const res = await template.deleteSupplement(templateId,supplementId)
    dispatch({
      type:DELETE_SUPPLEMENT,
      payload:res.data.supplements
    })
  } catch (error) {
    dispatch({ type: TEMPLATE_ERROR, payload:'Erreur survenue' })
  }
}