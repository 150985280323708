import { planningAPI } from 'core.services/api'
import {
  createRequestActions,
  parseErrorMessage
} from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { findIndex } from 'lodash'

export const {
  FETCH_ALL_PLANNINGS_REQUESTED,
  FETCH_ALL_PLANNINGS_SUCCEEDED,
  FETCH_ALL_PLANNINGS_FAILED,
  fetchAllPlanningsSucceeded,
  fetchAllPlanningsFailed,
  fetchAllPlanningsRequestStateReducer
} = createRequestActions('FETCH_ALL_PLANNINGS')

export const fetchAllPlanningsRequested = () => (dispatch) => {
  dispatch({
    type: FETCH_ALL_PLANNINGS_REQUESTED
  })
  planningAPI
    .getAll()
    .then((data) => {
      dispatch(fetchAllPlanningsSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchAllPlanningsFailed(error))
    })
}

export const {
  FETCH_CITY_PLANNINGS_REQUESTED,
  FETCH_CITY_PLANNINGS_SUCCEEDED,
  FETCH_CITY_PLANNINGS_FAILED,
  fetchCityPlanningsSucceeded,
  fetchCityPlanningsFailed,
  fetchCityPlanningsRequestStateReducer
} = createRequestActions('FETCH_CITY_PLANNINGS')

export const fetchCityPlanningsRequested = (cityId, startWeek) => (dispatch) => {
  dispatch({
    type: FETCH_CITY_PLANNINGS_REQUESTED,
    payload: {
      cityId,
      startWeek
    }
  })
  planningAPI
    .getCityPlannings(cityId, startWeek)
    .then((data) => {
      dispatch(fetchCityPlanningsSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchCityPlanningsFailed(error))
    })
}

export const {
  UPDATE_PLANNING_REQUESTED,
  UPDATE_PLANNING_SUCCEEDED,
  UPDATE_PLANNING_FAILED,
  updatePlanningSucceeded,
  updatePlanningFailed,
  updatePlanningRequestStateReducer
} = createRequestActions('UPDATE_PLANNING')

export const updatePlanningRequested = (
  planningId,
  startWeekDateParams,
  week,
  isCurrentWeek
) => (dispatch) => {
  let startWeekDate = new Date(Date.parse(startWeekDateParams))

  if (!!startWeekDate && !startWeekDate.toUTCString().includes('00:00:00')) {
    startWeekDate.setUTCHours(0, 0, 0, 0)
  }
  if (!!startWeekDate && startWeekDate.toUTCString().includes('Tue')) {
    startWeekDate.setUTCDate(startWeekDate.getUTCDate() - 1)
  }
  if (!!startWeekDate && startWeekDate.toUTCString().includes('Sun')) {
    startWeekDate.setUTCDate(startWeekDate.getUTCDate() + 1)
  }

  dispatch({
    type: UPDATE_PLANNING_REQUESTED,
    payload: {
      planningId,
      startWeekDate,
      week
    }
  })
  
  return planningAPI
    .updatePlanning(planningId, startWeekDate, week)    
}

export const {
  CREATE_PLANNING_REQUESTED,
  CREATE_PLANNING_SUCCEEDED,
  CREATE_PLANNING_FAILED,
  createPlanningSucceeded,
  createPlanningFailed,
  createPlanningRequestStateReducer
} = createRequestActions('CREATE_PLANNING')

export const createPlanningRequested = (
  riderId,
  startWeekDateParams,
  week,
  isCurrentWeek
) => (dispatch) => {
  let startWeekDate = new Date(Date.parse(startWeekDateParams))

  if (!!startWeekDate && !startWeekDate.toUTCString().includes('00:00:00')) {
    startWeekDate.setUTCHours(0, 0, 0, 0)
  }
  if (!!startWeekDate && startWeekDate.toUTCString().includes('Tue')) {
    startWeekDate.setUTCDate(startWeekDate.getUTCDate() - 1)
  }
  if (!!startWeekDate && startWeekDate.toUTCString().includes('Sun')) {
    startWeekDate.setUTCDate(startWeekDate.getUTCDate() + 1)
  }

  dispatch({
    type: CREATE_PLANNING_REQUESTED,
    payload: {
      riderId,
      startWeekDate,
      week
    }
  })
  planningAPI
    .createPlanning(riderId, startWeekDate, week)
    .then((data) => {
      dispatch(createPlanningSucceeded(data, isCurrentWeek))
    })
    .catch((error) => {
      alert(error?.response?.data?.errors[0]?.message)
      dispatch(createPlanningFailed(error))
    })
}

export const {
  UPDATE_PLANNING_STATUS_ACCEPT_REQUESTED,
  UPDATE_PLANNING_STATUS_ACCEPT_SUCCEEDED,
  UPDATE_PLANNING_STATUS_ACCEPT_FAILED,
  updatePlanningStatusAcceptSucceeded,
  updatePlanningStatusAcceptFailed,
  updatePlanningStatusAcceptRequestStateReducer
} = createRequestActions('UPDATE_PLANNING_STATUS_ACCEPT')

export const updatePlanningStatusAcceptRequested = (
  planningId,
  startWeekDate,
  week,
  isCurrentWeek
) => (dispatch) => {
  console.log("updatePlanningStatusAcceptRequested, planning", planningId,
  startWeekDate,
  week,
  isCurrentWeek)

  dispatch({
    type: UPDATE_PLANNING_STATUS_ACCEPT_REQUESTED,
    payload: {
      planningId,
      startWeekDate,
      week
    }
  })
  planningAPI
    .updatePlanningStatusAccept(planningId, startWeekDate, week)
    .then((data) => {
      dispatch(updatePlanningStatusAcceptSucceeded(data, isCurrentWeek))      
    })
    .catch((error) => {
      dispatch(updatePlanningStatusAcceptFailed(error))
    })
}

export const {
  UPDATE_PLANNING_STATUS_RESERVE_REQUESTED,
  UPDATE_PLANNING_STATUS_RESERVE_SUCCEEDED,
  UPDATE_PLANNING_STATUS_RESERVE_FAILED,
  updatePlanningStatusReserveSucceeded,
  updatePlanningStatusReserveFailed,
  updatePlanningStatusReserveRequestStateReducer
} = createRequestActions('UPDATE_PLANNING_STATUS_RESERVE')

export const updatePlanningStatusReserveRequested = (
  planningId,
  startWeekDate,
  week,
  isCurrentWeek
) => (dispatch) => {
  dispatch({
    type: UPDATE_PLANNING_STATUS_RESERVE_REQUESTED,
    payload: {
      planningId,
      startWeekDate,
      week
    }
  })
  planningAPI
    .updatePlanningStatusReserve(planningId, startWeekDate, week)
    .then((data) => {
      dispatch(updatePlanningStatusReserveSucceeded(data, isCurrentWeek))
    })
    .catch((error) => {
      dispatch(updatePlanningStatusReserveFailed(error))
    })
}

export const {
  UPDATE_PLANNING_STATUS_DENY_REQUESTED,
  UPDATE_PLANNING_STATUS_DENY_SUCCEEDED,
  UPDATE_PLANNING_STATUS_DENY_FAILED,
  updatePlanningStatusDenySucceeded,
  updatePlanningStatusDenyFailed,
  updatePlanningStatusDenyRequestStateReducer
} = createRequestActions('UPDATE_PLANNING_STATUS_DENY')

export const updatePlanningStatusDenyRequested = (planningId, planning) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_PLANNING_STATUS_DENY_REQUESTED,
    payload: {
      planningId,
      planning
    }
  })
  planningAPI
    .updatePlanningStatusDeny(planningId, planning)
    .then((data) => {
      dispatch(updatePlanningStatusDenySucceeded(data))
    })
    .catch((error) => {
      dispatch(updatePlanningStatusDenyFailed(error))
    })
}

export const {
  DELETE_PLANNING_REQUESTED,
  DELETE_PLANNING_SUCCEEDED,
  DELETE_PLANNING_FAILED,
  deletePlanningSucceeded,
  deletePlanningFailed,
  deletePlanningRequestStateReducer
} = createRequestActions('DELETE_PLANNING')

export const deletePlanningRequested = (planningId) => (dispatch) => {
  dispatch({
    type: DELETE_PLANNING_REQUESTED,
    payload: {
      planningId
    }
  })
  planningAPI
    .deletePlanning(planningId)
    .then((data) => {
      dispatch(deletePlanningSucceeded(data))
    })
    .catch((error) => {
      dispatch(deletePlanningFailed(error))
    })
}

export const {
  DELETE_PLANNING_SESSION_REQUESTED,
  DELETE_PLANNING_SESSION_SUCCEEDED,
  DELETE_PLANNING_SESSION_FAILED,
  deletePlanningSessionSucceeded,
  deletePlanningSessionFailed,
  deletePlanningSessionRequestStateReducer
} = createRequestActions('DELETE_PLANNING_SESSION')

export const deletePlanningSessionRequested = (planningId, day, session) => (
  dispatch
) => {
  console.log("deletePlanningSessionRequested deletePlanningSessionRequested ", planningId, day, session)
  dispatch({
    type: DELETE_PLANNING_SESSION_REQUESTED,
    payload: {
      planningId,
      day,
      session
    }
  })
  planningAPI
    .deletePlanningSession(planningId, day, session)
    .then((data) => {
      console.log("Success deletePlanningSessionRequested ", data)
      dispatch(deletePlanningSessionSucceeded(data))
    })
    .catch((error) => {
      console.log("Error deletePlanningSessionRequested ", error)
      dispatch(deletePlanningSessionFailed(error))
    })
}

const mergeCurrentCity = (data, isCurrentWeek) => {
  const updatedRow = []
}

export const defaultState = {
  allPlannings: [],
  currentCityPlannings: {},
  requestState: {}
}
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_ALL_PLANNINGS_REQUESTED:
      return {
        ...state,
        requestState: fetchAllPlanningsRequestStateReducer(state, action),
        errorMessage: ''
      }
    case FETCH_ALL_PLANNINGS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        allPlannings: data,
        requestState: fetchAllPlanningsRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    // case FETCH_ALL_PLANNINGS_FAILED: {
    //   return {
    //     ...state,
    //     requestState: fetchAllPlanningsRequestStateReducer(state, action),
    //     errorMessage: parseErrorMessage(action)
    //   }
    // }

    case FETCH_CITY_PLANNINGS_REQUESTED:
      return {
        ...state,
        requestState: fetchCityPlanningsRequestStateReducer(state, action),
        errorMessage: ''
      }
    case FETCH_CITY_PLANNINGS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        currentCityPlannings: data,
        requestState: fetchCityPlanningsRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case FETCH_CITY_PLANNINGS_FAILED: {
      return {
        ...state,
        currentCityPlannings: {},
        requestState: fetchCityPlanningsRequestStateReducer(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case UPDATE_PLANNING_FAILED: {
      return {
        ...state,
        requestState: updatePlanningFailed(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case CREATE_PLANNING_SUCCEEDED: {
      return {
        ...state,
        requestState: createPlanningSucceeded(state, action),
        errorMessage: ''
      }
    }
    case CREATE_PLANNING_FAILED: {
      return {
        ...state,
        requestState: createPlanningFailed(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case UPDATE_PLANNING_STATUS_ACCEPT_FAILED: {
      return {
        ...state,
        requestState: updatePlanningStatusAcceptFailed(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case UPDATE_PLANNING_STATUS_DENY_FAILED: {
      return {
        ...state,
        requestState: updatePlanningStatusDenyFailed(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case DELETE_PLANNING_FAILED: {
      return {
        ...state,
        requestState: deletePlanningFailed(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case DELETE_PLANNING_SESSION_FAILED: {
      return {
        ...state,
        requestState: deletePlanningSessionFailed(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }

    default:
      return state
  }
}

const getState = (state) => state.plannings
const getAllPlannings = (state) => getState(state).allPlannings
const getCurrentCityPlannings = (state) => getState(state).currentCityPlannings
const getRequestState = (state) => getState(state).requestState
const getErrorMessage = (state) => getState(state).errorMessage

export const selectors = {
  getState,
  getAllPlannings,
  getCurrentCityPlannings,
  getRequestState,
  getErrorMessage
}
