import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControlLabel,
  Switch as MuiSwitch,
  TextField
} from '@material-ui/core'
import { get } from 'lodash'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'
import SearchBar from 'core.ui/SearchBar'

import { Link, Route, Switch } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'
import {
  fetchRestaurantProductsRequested,
  searchProductsRequested
} from 'core.modules/currentRestaurant/products/reducer'
import {
  selectors as currentRestaurantProductsSelectors,
  deleteRestaurantProductRequested,
  duplicateRestaurantProductRequested
} from 'core.modules/currentRestaurant/products/reducer'
import SelectRestaurant from 'components/SelectRestaurant'
import ProductCreate from 'components/ProductCreate'
import ProductEdit from 'components/ProductEdit'
import Dialog from 'core.ui/Dialog'
import ListDraggable from 'core.ui/DraggableList'
import List from 'core.ui/List'
import { requestStates } from '../../core.utils/requestStates'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Select from 'react-select'
import Select1 from '@material-ui/core/Select'
import { fetchRestaurantDragAndDropRequested } from '../../core.modules/currentRestaurant/products/reducer'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  headerAutocomplete: {
    paddingTop: '10px'
  }
}

class Products extends React.Component {
  constructor(props) {
    super(props)
    const searchParams = new URLSearchParams(props.location.search)
    const selectedPage = searchParams.get('page')
    const page = selectedPage ? parseInt(selectedPage) - 1 : 0
    this.limit = 10
    this.state = {
      isArchived: false,
      limit: this.limit,
      page: page,
      displayingSearchResult: false,
      searchedProducts: [],
      pageStart: page * this.limit,
      header: null
    }
  }

  componentDidMount = () => {
    const {
      fetchAllRestaurants,
      fetchRestaurantProducts,
      currentRestaurantId,
    } = this.props
    const cityId = localStorage.getItem('selectedCity')
     fetchAllRestaurants(cityId)
    if (currentRestaurantId) {
     fetchRestaurantProducts(currentRestaurantId)

    }
  
  }

  componentDidUpdate(prevProps) {

    const { currentRequestState, currentRestaurant } = this.props
    const { waitingForRequest } = this.state

    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })

    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })

    }
  }

  makeRows = (products) => {
    return products.map((p) => [p.name, p.category, p.price])
  }

  makeIds = (products) => {
    return products.map((p) => {
      return get(p, 'id', get(p, '_id', ''))
    })
  }

  handleArchivedChange = (e) => {
    const { checked } = e.target
    this.setState({ isArchived: checked })
  }

  handleEdit = (id) => {
    const { history } = this.props
    history.push(`/produits/edit?id=${id}`)
  }


  handleDuplicate = (productId) => {
    const { currentRestaurantId } = this.props
    this.props.duplicateProduct(productId, currentRestaurantId)
  }

  handleDelete = (id) => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: id
    })
  }

  confirmDelete = () => {
    this.props.deleteProduct(this.state.idToDelete)
    this.closeDeleteDialog()
  }
  searchProducts = (query) => {
    const { currentRestaurantId, searchProducts } = this.props
    const fullQuery = { ...query }
    this.setState({ displayingSearchResult: true })
    const restaurantId = currentRestaurantId || localStorage.getItem('selectedRes')
    searchProducts(fullQuery, restaurantId)
  }

  clearSearch = () => {
    const { currentRestaurantId, fetchRestaurantProducts } = this.props
    const restaurantId = currentRestaurantId || localStorage.getItem('selectedRes')
    fetchRestaurantProducts(restaurantId)
    this.setState({ displayingSearchResult: false })
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  onHeaderSelect = (e, restaurantId) => {
    this.setState({ header: e.target.value })
  }

  pagePaginationNumber = (e) => {
    this.setState({
      limit: e.target.value
    })
  }
  productsList = () => {
    const {
      isArchived,
      pageStart,
      errorMessage,
      displayingSearchResult,
      header
    } = this.state

    const {
      currentRestaurantProducts,
      location,
      classes,
      searchedProducts,
      currentRestaurant
    } = this.props

    const displayedProducts = displayingSearchResult
      ? searchedProducts
      : currentRestaurantProducts

    const unArchivedProducts = displayedProducts.filter((product) => {
      const filterBool = header ? product.header === header : true

      
      return !product.isArchived && filterBool
    })
    const archivedProducts = displayedProducts.filter((product) => {
      const filterBool = header ? product.header === header : true
      return product.isArchived && filterBool
    })

    return !isArchived ? (
      <React.Fragment>
        <div className={classes.topBarControls}>
          <SearchBar
            searchFields={{
              name: 'Nom'
              // category:'Categorie'
            }}
            onSearch={this.searchProducts}
            onClearSearch={this.clearSearch}
            displayClearSearch={displayingSearchResult}
          />
        </div>

        {currentRestaurant?.headers?.length && (
          <div style={styles.headerAutocomplete}>
            <Autocomplete
              options={currentRestaurant?.headers?.filter(header => header !== null)}
              getOptionLabel={(header) => header}
              style={{ width: 300 }}
              onChange={this.onHeaderChange}
              onSelect={(e) => this.onHeaderSelect(e, currentRestaurant.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Header"
                  variant="outlined"
                  defaultValue={currentRestaurant?.headers[0]}
                />
              )}
            />
          </div>
        )}
        {header ? (
          <div>
            <div style={{ marginLeft: '40%' }}>
              Nombre de produit Par Page:{' '}
              <Select1
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={10}
                onChange={this.pagePaginationNumber}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select1>
            </div>
            <ListDraggable
              headers={header}
              key="list-of-products"
              list={this.makeRows(unArchivedProducts).slice(
                pageStart,
                pageStart + this.state.limit
              )}
              columns={['Nom', 'Catégories', 'Prix']}
              ids={this.makeIds(unArchivedProducts).slice(
                pageStart,
                pageStart + this.state.limit
              )}
              productList={currentRestaurantProducts}
              actions={{
                handleEdit: this.handleEdit,
                handleDelete: this.handleDelete,
                handleDuplicate: this.handleDuplicate
              }}
              onDragEnd={(productsByHeaders) =>
                fetchRestaurantDragAndDropRequested(
                  productsByHeaders,
                  currentRestaurantProducts[0]?.restaurantId
                )
              }
              emptyMessage="Ce restaurant n'a aucun produit"
              customPage={this.state.page + 1}
              onPageChange={(p) => {
                const page = p - 1
                this.setState({ page, pageStart: page * this.state.limit })
                this.props.history.push({
                  pathname: location.pathname,
                  search:
                    '?' + new URLSearchParams({ page: page + 1 }).toString()
                })
              }}
            />
          </div>
        ) : (
          <List
            key="list-of-products"
            list={this.makeRows(unArchivedProducts).slice(
              pageStart,
              pageStart + this.state.limit
            )}
            columns={['Nom', 'Catégories', 'Prix']}
            ids={this.makeIds(unArchivedProducts).slice(
              pageStart,
              pageStart + this.state.limit
            )}
            productList={currentRestaurantProducts}
            actions={{
              handleEdit: this.handleEdit,
              handleDelete: this.handleDelete,
              handleDuplicate: this.handleDuplicate
            }}
            emptyMessage="Ce restaurant n’a aucun produit"
            customPage={this.state.page + 1}
            onPageChange={(p) => {
              const page = p - 1
              this.setState({ page, pageStart: page * this.state.limit })
              this.props.history.push({
                pathname: location.pathname,
                search: '?' + new URLSearchParams({ page: page + 1 }).toString()
              })
            }}
          />
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={classes.topBarControls}>
          <SearchBar
            searchFields={{
              name: 'Nom'
              // category:'Categorie'
            }}
            onSearch={this.searchProducts}
            onClearSearch={this.clearSearch}
            displayClearSearch={displayingSearchResult}
          />
        </div>
        {currentRestaurant?.headers?.length && (
          <div style={styles.headerAutocomplete}>
            <Autocomplete
              options={currentRestaurant?.headers?.filter(header => header !== null)}
              getOptionLabel={(header) => header}
              style={{ width: 300 }}
              onChange={this.onHeaderChange}
              onSelect={(e) => this.onHeaderSelect(e, currentRestaurant.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Header"
                  variant="outlined"
                  defaultValue={currentRestaurant?.headers[0]}
                />
              )}
            />
          </div>
        )}

        <List
          key="list-of-products"
          list={this.makeRows(archivedProducts)}
          columns={['Nom', 'Catégories', 'Prix']}
          ids={this.makeIds(archivedProducts)}
          emptyMessage="Ce restaurant n’a aucun produit archivé"
        />
      </React.Fragment>
    )
  }

  render() {
    const { openDeleteDialog, isArchived } = this.state
    const { classes, city, currentRestaurantProducts, history } = this.props
    return (
      <Switch>
        <Route path="/produits/new" exact>
          <ProductCreate onSuccess={this.handleSuccess} history={history} />
        </Route>
        <Route
          path="/produits/edit"
          render={(props) => {
            const id = props.location.search.split('=')[1]
            const data = currentRestaurantProducts.find(
              (p) => p._id === id || p.id === id
            )
            return (
              <ProductEdit
                data={data}
                onSuccess={this.handleSuccess}
                history={history}
              />
            )
          }}
        />
        <Route path="/produits" exact>
          <div key="list-main-container" className={classes.mainListContainer}>
            <div className={classes.header}>
              <SelectRestaurant key="select-restaurants" city={city} />
              <Link className={classes.addButton} to="/produits/new">
                <Button variant="contained" color="secondary">
                  Ajouter un produit
                </Button>
              </Link>
            </div>
            <FormControlLabel
              control={
                <MuiSwitch
                  color="primary"
                  size="small"
                  checked={isArchived}
                  onChange={this.handleArchivedChange}
                />
              }
              label="Produits Archivés"
            />
            {this.productsList()}
            <Dialog
              open={openDeleteDialog}
              dialogText="Confirmer la suppression"
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={this.closeDeleteDialog}
              onValidation={this.confirmDelete}
            />
          </div>
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  currentRestaurantProducts:
    currentRestaurantProductsSelectors.getProducts(state),
  displayingSearchResult:
    currentRestaurantProductsSelectors.getDisplayingSearchResult(state),
  searchedProducts:
    currentRestaurantProductsSelectors.getSearchedProducts(state),
  errorMessage: currentRestaurantProductsSelectors.getErrorMessage(state),
  currentRestaurantId:
    currentRestaurantProductsSelectors.getRestaurantId(state),
  currentRestaurant: state.currentRestaurant.infos,
  currentRequestState: currentRestaurantProductsSelectors.getRequestState(state),

})

const mapDispatchToProps = (dispatch) => ({
  fetchAllRestaurants: (cityId) => {
    dispatch(fetchAllRestaurantsRequested(cityId))
  },
  fetchRestaurantProducts: (restaurantId) => {
    dispatch(fetchRestaurantProductsRequested(restaurantId))
  },
  deleteProduct: (id) => {
    dispatch(deleteRestaurantProductRequested(id))
  },
  duplicateProduct: (productId, currentRestaurantId) => {
    dispatch(
      duplicateRestaurantProductRequested(productId, currentRestaurantId)
    )
  },
  searchProducts: (query, currentRestaurantId) => {
    dispatch(searchProductsRequested(query, currentRestaurantId))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Products)
