import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'
import { selectors as currentRestaurantInfosSelectors } from 'core.modules/currentRestaurant/infos/reducer'
import {
  selectors as currentRestaurantSupplementsSelectors,
  deleteRestaurantSupplementRequested,
  fetchRestaurantSupplementRequested
} from 'core.modules/currentRestaurant/supplements/reducer'
import { selectors as currentRestaurantSupplementGroupsSelectors } from 'core.modules/currentRestaurant/supplementGroups/reducer'

import SelectRestaurant from 'components/SelectRestaurant'
import Snackbar from 'core.ui/Snackbar'
import Dialog from 'core.ui/Dialog'
import List from 'core.ui/List'
import Select from 'core.ui/Select'
import { assignSupplementRequested } from '../../core.modules/currentRestaurant/supplements/reducer'
import { requestStates } from '../../core.utils/requestStates'

import ModalNoteComponent from './components/ModalForm'

import { supplement as SupplementApi } from 'core.services/api'
import { supplement } from '../../core.services/api'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  }
}

class Options extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false,
      modalFormVisible: false,
      modalFormItem: null,
      open:false,
      snackBarMessage: '',
      snackBarSeverity: 'error',
      supplementErrorMessage: '',
      openDeleteDialog: false,
      openConfirmDeleteDialog: false
    }
  }

  componentDidMount() {
    this.props.fetchAllRestaurants()
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleGroupChange = (supplementGroupIndex) => (e) => {
    const {
      currentRestaurantSupplements,
      currentRestaurantSupplementGroups,
      assignSupplement,
      currentRestaurantId
    } = this.props

    this.setState({ waitingForRequest: true })
    const { value: groupName } = e.target
    const supplementId =
      currentRestaurantSupplements[supplementGroupIndex].id ||
      currentRestaurantSupplements[supplementGroupIndex]._id
    const group = currentRestaurantSupplementGroups.find(
      (supplementGroup) => supplementGroup.name === groupName
      
    )
    const body = {supplementGroupId:group?._id || null,  restaurantId:currentRestaurantId}
    assignSupplement(supplementId, body)
  }

  makeRows = (supplements) => {
    return supplements?.map((s, index) => [
      s.name,
      s.price,
      s.checkoutSystemId,
      this.makeGroupSelect(s, index)
    ])
  }

  makeIds = (supplements) => {
    return supplements.map((s) => get(s, '_id', get(s, 'id', '')))
  }
 
  makeGroupSelect = (supplement, index) => {
    const { currentRestaurantSupplementGroups } = this.props
    return (
      <Select
        value={supplement.group?.name || 'Non attribué'}
        options={currentRestaurantSupplementGroups?.map((supplementGroup) => supplementGroup.name)
          .concat([null])}
        disabled={['']}
        captions={currentRestaurantSupplementGroups?.map((supplementGroup) => supplementGroup.name)
          .concat(['Non attribué'])}
        autoFill={false}
        onChange={this.handleGroupChange(index)}
      />
    )
  }

  handleEdit = (itemId) => {
    const { currentRestaurantSupplements } = this.props
    const item = currentRestaurantSupplements.find((i) => i._id === itemId)
    if (!item) return
    this.setState({ modalFormVisible: true, modalFormItem: item })
  }

  handleEditSubmit = async (item) => {
    const { currentRestaurantId, fetchRestaurantSupplements } = this.props

    try {
      await SupplementApi.update(currentRestaurantId, item._id, {
        name: item.name,
        price: item.price,
        isAvailable: item.isAvailable,
        checkoutSystemId: item.checkoutSystemId?.trim() || undefined,
        reference:item.reference?.trim() 
      })
      await fetchRestaurantSupplements(currentRestaurantId)
      this.setState({
        modalFormVisible: false,
        modalFormItem: null
      })
    } catch (err) {
      this.setState({
        open: true,
        snackBarMessage: err?.response?.data?.errors[0].message || 'Erreur lors de la création',
      })
    }
  }

  handleDelete = (supplementId) => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: supplementId
    })
  }

  deleteSupplement = () => {
    const { fetchRestaurantSupplements, currentRestaurantId } = this.props
    const { idToDelete } = this.state
    supplement.delete(currentRestaurantId, idToDelete)
    .then(()=> {
      this.closeDeleteDialog()
      fetchRestaurantSupplements(currentRestaurantId)
    })
    .catch((err)=>{
      this.setState({ supplementErrorMessage: err.response.data.errors[0].message })
      this.setState({ openDeleteDialog: false })
      this.setState({ openConfirmDeleteDialog: true })
    })
  }

  confirmDelete = () => {
    const { idToDelete } = this.state
    const { currentRestaurantId, deleteRestaurantSupplement } = this.props
    this.setState({ waitingForRequest: true })
    deleteRestaurantSupplement(currentRestaurantId, idToDelete)
    this.closeConfirmDeleteDialog()
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  closeConfirmDeleteDialog = () => {
    this.setState({
      openConfirmDeleteDialog: false,
      idToDelete: null
    })
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false,open:false })
  }

  render() {
    const { 
      openDeleteDialog, modalFormVisible, modalFormItem, openConfirmDeleteDialog, 
      supplementErrorMessage, open, openSnackBar, snackBarMessage, snackBarSeverity 
    } = this.state
    const {
      classes,
      city,
      currentRestaurantSupplements,
      errorMessage,
      currentRestaurant
    } = this.props
    return (
      <Switch>
        <Route path="/restaurants/supplements" exact>
          <>
            <div
              key="list-main-container"
              className={classes.mainListContainer}
            >
              <div className={classes.header}>
                <SelectRestaurant key="select-restaurants" city={city} />
                {/* <Link className={classes.addButton} to="/produits/new">
                <Button variant="contained" color="secondary">
                  Ajouter un supplément
                </Button>
              </Link> */}
              </div>

              <List
                key="list-of-options"
                list={this.makeRows(currentRestaurantSupplements)}
                columns={['Nom', 'prix', 'id systeme de caisse','Groupe']}
                ids={this.makeIds(currentRestaurantSupplements)}
                actions={{
                  handleEdit: this.handleEdit,
                  handleDelete: this.handleDelete
                  // handleDuplicate: this.handleDuplicate
                }}
                emptyMessage="Ce restaurant n’a aucun supplément"
              />
              <Dialog
                open={openDeleteDialog}
                dialogText="continuer ?"
                dialogTitle="Supprimer le supplément"
                cancelButtonCaption="Annuler"
                confirmButtonCaption="Ok"
                onCancel={this.closeDeleteDialog}
                onValidation={this.deleteSupplement}
              />

              <Dialog
                open={openConfirmDeleteDialog}
                dialogText={supplementErrorMessage}
                dialogTitle="Confirmer la suppression"
                cancelButtonCaption="Annuler"
                confirmButtonCaption="Ok"
                onCancel={this.closeConfirmDeleteDialog}
                onValidation={this.confirmDelete}
              />

              <Snackbar
                open={openSnackBar}
                onClose={this.handleCloseSnackbar}
                severity="error"
                message={errorMessage}
              />
               <Snackbar
                open={open}
                onClose={this.handleCloseSnackbar}
                severity={snackBarSeverity}
                message={snackBarMessage}
              />

              {modalFormVisible && (
                <ModalNoteComponent
                currentRestaurant={currentRestaurant}
                  item={modalFormItem}
                  onCancel={() => {
                    this.setState({
                      modalFormVisible: false,
                      modalFormItem: null
                    })
                  }}
                  onSubmit={this.handleEditSubmit}
                />
              )}
            </div>
          </>
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRestaurant:state.currentRestaurant.infos,
  currentRestaurantSupplements: currentRestaurantSupplementsSelectors.getSupplements(
    state
  ),
  currentRestaurantSupplementGroups: currentRestaurantSupplementGroupsSelectors.getSupplementGroups(
    state
  ),
  currentRequestState: currentRestaurantSupplementsSelectors.getRequestState(
    state
  ),
  errorMessage: currentRestaurantSupplementsSelectors.getErrorMessage(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchAllRestaurants: () => {
    dispatch(fetchAllRestaurantsRequested())
  },
  fetchRestaurantSupplements: (restaurantId) => {
    dispatch(fetchRestaurantSupplementRequested(restaurantId))
  },
  deleteRestaurantSupplement: (restaurantId, supplementId) => {
    dispatch(deleteRestaurantSupplementRequested(restaurantId, supplementId))
  },
  assignSupplement: (restaurantId, supplementId) => {
    dispatch(assignSupplementRequested(restaurantId, supplementId))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Options)
