import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Button from '@material-ui/core/Button'
import * as icons from 'react-feather'

const useStyles = makeStyles({
  button: props => ({
    width: props.iconSize,
    minWidth: 0,
    height: props.iconSize,
    minHeight: props.iconSize,
    padding: 0,
    backgroundColor: props.backgroundColor
  })
})

const ButtonWithIcon = ({
  className,
  onClick,
  featherIcon,
  iconColor,
  backgroundColor,
  iconSize = 18,
  style = {}
}) => {
  const classes = useStyles({ iconSize, backgroundColor, ...style })
  const Icon = icons[featherIcon]
  return (
    <Button
      className={classnames(classes.button, className)}
      classes={{ label: classes.buttonLabel }}
      onClick={onClick}
      size="small"
    >
      <Icon size={iconSize} color={iconColor} />
    </Button>
  )
}

export default ButtonWithIcon
