import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { rider } from '../../core.services/api';

export default function SecondRiderInput({ updateNewRegulation, newRegulation, cityId }) {
	const [ riders, setriders ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		fetchRiders();
	}, [cityId]);

	const fetchRiders = () => {
		rider
			.getCityRiders(cityId)
			.then((data) => {                
				setriders(data);
                setLoading(false)
			})
			.catch((error) => {
                setLoading(false)
				console.log('Error fetching riders', error);
			});
	};

	const handleChange = (e, value) => {
		const newValue = {...newRegulation}
        const valuee = e?.target?.value || value;
        
        const idx = riders.map(r => r.firstName + " " + r.lastName).indexOf(valuee)
        if (idx > -1) {
            newValue["riderId"] = riders[idx]._id || riders[idx].id;
            updateNewRegulation(newValue);
        }    
    };

    if (loading) {
        return <div></div>
    }

    const defaultRider = riders.filter(r => r._id === newRegulation.riderId)
    const name = defaultRider.length > 0 ? `${defaultRider[0].firstName} ${defaultRider[0].lastName}` : ""
    
    return (
		<Autocomplete
            freeSolo={true}
            defaultValue={name}
			options={riders.map(r => r.firstName + " " + r.lastName)}
			style={{ width: '100%' }}
			onChange={handleChange}
			renderInput={(params) => <TextField {...params} label="Deuxième livreur" variant="outlined" />}
		/>
	);
}
