import React, { useState, useEffect } from 'react'
import validator from 'validator';
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from 'core.ui/Select'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'

import AutoCompleteTextField from "./AutoCompleteTextField"

import { restaurant } from 'core.services/api'
import { setFilters } from './../../../src/pages/OrderPage/OrderList/ui-slice'

import frLocale from 'date-fns/locale/fr'
import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import {
  Grid,
  Box,
  FormControl,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { compose } from 'redux'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  searchBarContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    margin: '0 15px 0 0'
  },
  textFieldInput: {
    paddingRight: '32px'
  },
  select: {
    height: '40px'
  },
  selectSelect: {
    height: '40px',
    padding: '0 10px'
  },
  searchIcon: {
    position: 'absolute',
    right: '10px',
    height: '18px'
  },
  clearButton: {
    padding: '1px',
    height: '15px'
  },
  clearButtonLabel: {
    fontSize: '9px'
  }
}))

function SearchBarCustom({
  searchFields = {},
  booleanFields = [],
  onSearch,
  onClearSearch,
  displayClearSearch = true,
  filters
}) {
  
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [type, setType] = useState('')
  const [restaurantId, setRestaurantId] = useState(null)

  const [isLoading, setIsLoading] = useState(false);
  const [searchDatas, setSearchDatas] = useState([])
  const [searckKey, setSearckKey] = useState("key")

  const [isValid, setIsValid] = useState(true)
  const [isValidOrderNumber, setIsValidOrderNumber] = useState(true)

  useEffect(() => {
    if((type === "rider" || type === "client")) {
      setIsValid(validator.isEmail(value))
    }
    else {
      setIsValid(true)
    }
  }, [value, type])

  useEffect(() => {
    setRestaurantId(null)
  }, [type])

  const classes = useStyles()

  const handleSubmit = () => {
    // if((type === "rider" || type === "client") && !isValid) {
    //   return
    // }

    if(type === "order_number" && isEmpty(value)) {
      setIsValidOrderNumber(false)
      return
    }
    else {
      setIsValidOrderNumber(true)
    }

    const filterDate = {}

    if(filters.byDate) {
      if (filters.startDate) filterDate.startDate = filters.startDate
      if (filters.endDate) filterDate.endDate = filters.endDate
    }
    
    if (restaurantId !== null) {
      onSearch({ type, value, restaurantId, ...filterDate })
    }
    else {
      onSearch({ type, value, ...filterDate })
    }
    
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const onClear = () => {
    setValue('')
    onClearSearch()
  }

  const handleSearchSelect = value => {
    try {
      if (value) {
        setRestaurantId(value._id)
        if (type !== "order_number" && type !== "restaurant") setValue(value.nom)
      }
      else {
        setRestaurantId(null)
      }
    }
    catch(err) {
      setIsLoading(false);
    }
  }

  const getAsyncDatas = () => {
    try {
      setIsLoading(true);

      const cityId = localStorage.getItem('selectedCity')

      restaurant.getAll(cityId).then(restos => {
        setSearckKey("nom")
        setSearchDatas(restos)
        setIsLoading(false);
      })
    }
    catch(err) {
      setIsLoading(false);
    }
  }

  return (
    <>
    <div className={classes.mainContainer}>
      <div className={classes.searchBarContainer}>
        <Select
          className={classes.select}
          classes={{
            select: classes.selectSelect
          }}
          options={Object.keys(searchFields)}
          captions={Object.values(searchFields)}
          value={type}
          onChange={(e) => {
            setType(e.target.value)
            setValue('')
          }}
        />
        {booleanFields.includes(type) ? (
          <Select
            className={classes.select}
            classes={{
              select: classes.selectSelect
            }}
            options={['true', 'false']}
            captions={['existe', "n'existe pas"]}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : type === "order_number" ? <AutoCompleteTextField
            isOptionEqualToValueKey={"_id"} 
            getOptionLabelKey={searckKey}
            datas={searchDatas} 
            loading={isLoading} 
            getAsyncDatas={getAsyncDatas}
            onChange={handleSearchSelect}
            width={228}
          /> : (
          (type !== "restaurant") ? <>
            <TextField
              type={(type === "rider" || type === "client") ? "email" : "text"}
              placeholder="Recherche…"
              size="small"
              variant="outlined"
              onKeyPress={handleEnterPress}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              inputProps={{
                'aria-label': 'Rechercher',
                className: classes.textFieldInput
              }}
            />
          </>
          : 
          <AutoCompleteTextField
            isOptionEqualToValueKey={"id"} 
            getOptionLabelKey={searckKey}
            datas={searchDatas} 
            loading={isLoading} 
            getAsyncDatas={getAsyncDatas}
            onChange={handleSearchSelect}
          />
        )}
        
        {type !== "order_number" && <ButtonWithIcon
          className={classes.searchIcon}
          featherIcon="Search"
          iconSize="18"
          onClick={handleSubmit}
        />}
      </div>
      {!isValid && <small style={{color: "red"}}>Veuillez entrer une adresse email valide</small>}
      
      <div className={classes.searchBarContainer}>
        <br />
          {type === "order_number" && restaurantId !== null &&
          <div style={{marginRight: 0}} className={classes.searchBarContainer}>
            <TextField
              //type={(type === "rider" || type === "client") ? "email" : "number"}
              placeholder="Entrer le numéro de commande..."
              size="small"
              variant="outlined"
              onKeyPress={handleEnterPress}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              inputProps={{
                'aria-label': 'Rechercher',
                className: classes.textFieldInput,
                style: {
                  width: 347
                }
              }}
              fullWidth
            />
            <ButtonWithIcon
            className={classes.searchIcon}
            featherIcon="Search"
            iconSize="18"
            onClick={handleSubmit}
          />
        </div>
        }
      </div>
      {type === "order_number" && !isValidOrderNumber && <small style={{color: "red"}}>Veuillez entrer un numéro de commande</small>}
      {displayClearSearch && (
        <Button
          className={classes.clearButton}
          classes={{ label: classes.clearButtonLabel }}
          // variant="contained"
          onClick={onClear}
        >
          Effacer la recherche
        </Button>
      )}
    </div>
    {filters.byDate && 
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{ paddingLeft: '210px' }}
      >
        <Grid item>
          <Box display="flex" direction="row" my={2} style={{ minWidth: "300px" }} >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <FormControl fullWidth style={{ minWidth: "150px" }}>
                <DateTimePicker
                  ampm={false}
                  maxDate={filters.endDate}
                  onChange={(date) => {
                    dispatch(setFilters({ startDate: date }))
                  }}
                  label="Date de début"
                  value={filters.startDate}
                  format="dd MMM yyyy à HH:mm"
                  inputVariant="outlined"
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth style={{ minWidth: "150px" }} >
                <DateTimePicker
                  ampm={false}
                  minDate={filters.startDate}
                  label="Date de fin"
                  onChange={(date) => {
                    dispatch(setFilters({ endDate: date }))
                  }}
                  inputVariant="outlined"
                  value={filters.endDate}
                  format="dd MMM yyyy à HH:mm"
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
            
          </Box>
        </Grid>
      </Grid>
        
      }
    </>
  )
}

const mapStateToProps = state => ({
  filters: state.uiOrderList.filters
})

export default compose(
connect(
  mapStateToProps
),
)(SearchBarCustom)


