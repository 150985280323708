import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { get, isEqual, uniqueId } from 'lodash'

import Select from 'core.ui/Select'

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto'
  },
  listItem: {
    padding: '4px 12px'
  },
  checkBoxes: {
    minWidth: '42px'
  },
  optionMainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  optionCheckboxAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  optionsDetails: {
    fontSize: '10px',
    marginLeft: '15px',
    minWidth: '15px'
  },
  optionDetailsList: {
    width: '100%',
    margin: 0,
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  maxField: {
    height: '30px',
    width: '30px'
  },
  maxFieldIcon: { right: '-2px' },
  maxFieldLabel: { paddingLeft: '4px', fontSize: '12px' },
  maxFieldSelect: {
    fontSize: '12px',
    height: '30px',
    width: '12px',
    padding: '0 6px !important'
  }
}))

export function intersection(a, b) {
  return a.filter(
    value => b.find(bValue => bValue.name === value.name) !== undefined
  )
}

export function difference(a, b) {
  return a.filter(
    value => b.find(bValue => bValue.name === value.name) === undefined
  )
}

export default function TransferList({
  list = [],
  currentRight = [],
  onChange,
  withMax = false,
  withOptionsDetails = false
}) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState([])
  const [left, setLeft] = React.useState([])
  const [right, setRight] = React.useState([])
  React.useEffect(() => {
    if (withMax) {
      const listWithMaxes = list.map(el => {
        const maxNumber = get(el, 'maxNumber', 1)
        return { ...el, maxNumber }
      })
      const rightWithMaxes = currentRight.map(el => {
        const maxNumber = get(el, 'maxNumber', 1)
        return { ...el, maxNumber }
      })
      const listMinusCurrentRight = difference(listWithMaxes, rightWithMaxes)
      setLeft(listMinusCurrentRight)
    } else {
      const listMinusCurrentRight = difference(list, currentRight)
      setLeft(listMinusCurrentRight)
    }
  }, [list, currentRight])
  React.useEffect(() => {
    if (!isEqual(right, currentRight)) {
      if (withMax) {
        const rightWithMaxes = currentRight.map(el => {
          const maxNumber = get(el, 'maxNumber', 1)
          return { ...el, maxNumber }
        })
        setRight(rightWithMaxes)
      } else {
        setRight(currentRight)
      }
    }
  }, [currentRight])
  React.useEffect(() => {
    if (!isEqual(right, currentRight)) {
      updateList()
    }
  }, [right])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleMaxChange = index => e => {
    const { value } = e.target
    const newRight = [
      ...right.slice(0, index),
      { ...right[index], maxNumber: Number(value) },
      ...right.slice(index + 1)
    ]
    setRight(newRight)
  }

  const updateList = () => {
    const event = { target: { value: right } }
    onChange(event)
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(difference(left, leftChecked))
    setChecked(difference(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(difference(right, rightChecked))
    setChecked(difference(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  const customList = (items, withMax = false, withOptionsDetails = false) => (
    <Paper className={classes.paper} variant="outlined">
      <List dense component="div" role="list">
        {items.map((item, index) => {
          const labelId = `transfer-list-item-${item.name}-label`
          const supplement = (
            <div className={classes.optionMainContainer}>
              <ListItemText id={labelId} primary={item.name} />
              {item?.group && item?.group?.name ?
                (
                  <ul className={classes.optionDetailsList}>
                  <li className={classes.optionsDetails}>
                    {item?.group?.name}
                  </li>
                </ul>
                ) :
                null
              }
            </div>
          )
          const checkBox = (
            <ListItemIcon className={classes.checkBoxes}>
              <Checkbox
                checked={checked.indexOf(item) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
          )
          return (
            <ListItem
              className={classes.listItem}
              key={uniqueId(item.name)}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              {withOptionsDetails ? (
                <div className={classes.optionMainContainer}>
                  <div className={classes.optionCheckboxAndLabel}>
                    {checkBox}
                    <ListItemText id={labelId} primary={item.name} />
                  </div>
                  <ul className={classes.optionDetailsList}>
                    {get(item, 'optionTypes', []).map(ot => (
                      <li className={classes.optionsDetails}>{ot.type}: {ot.price} €</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <React.Fragment key={uniqueId('fragments')}>
                  {checkBox}
                  {withMax ? (
                  <>
                    {supplement}
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.maxFieldLabel}>
                        max
                      </InputLabel>
                      <Select
                        className={classes.maxField}
                        classes={{
                          icon: classes.maxFieldIcon,
                          root: classes.maxFieldSelect
                        }}
                        value={item.maxNumber}
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                        captions={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                        onClick={e => e.stopPropagation()}
                        onChange={handleMaxChange(index)}
                      />
                    </FormControl>
                    </>
                  ) : 
                    <div>
                      {supplement}
                    </div>
                    }
                 </React.Fragment>
              )}
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(left, false, withOptionsDetails)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, withMax, withOptionsDetails)}</Grid>
    </Grid>
  )
}
