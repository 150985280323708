import { restaurant } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { get, isEmpty } from 'lodash'
import { requestStates } from 'core.utils/requestStates'
import { findIndex } from 'lodash'


export const {
  SELECT_CURRENT_RESTAURANT_REQUESTED,
  SELECT_CURRENT_RESTAURANT_SUCCEEDED,
  SELECT_CURRENT_RESTAURANT_FAILED,
  selectCurrentRestaurantSucceeded,
  selectCurrentRestaurantFailed
} = createRequestActions('SELECT_CURRENT_RESTAURANT')

export const selectCurrentRestaurantRequested = id => dispatch => {
  dispatch({
    type: SELECT_CURRENT_RESTAURANT_REQUESTED,
    restaurantId: id
  })
  restaurant
    .getById(id)
    .then(data => {
      dispatch(selectCurrentRestaurantSucceeded(data))
    })
    .catch(error => {
      dispatch(selectCurrentRestaurantFailed(error))
    })
}

export const {
  EDIT_CURRENT_RESTAURANT_REQUESTED,
  EDIT_CURRENT_RESTAURANT_SUCCEEDED,
  EDIT_CURRENT_RESTAURANT_FAILED,
  editCurrentRestaurantSucceeded,
  editCurrentRestaurantFailed
} = createRequestActions('EDIT_CURRENT_RESTAURANT')

export const editCurrentRestaurantRequested = (
  id,
  restaurantInfos
) => dispatch => {
  dispatch({
    type: EDIT_CURRENT_RESTAURANT_REQUESTED,
    data: restaurantInfos
  })
  restaurant
    .edit(id, restaurantInfos)
    .then(data => {
      if (isEmpty(data)) {
        dispatch(
          editCurrentRestaurantSucceeded({ restaurant: restaurantInfos })
        )
      } else {
        dispatch(editCurrentRestaurantSucceeded({...data, ...restaurantInfos}))
      }
    })
    .catch(error => {
      dispatch(editCurrentRestaurantFailed(error))
    })
}

export const {
  UPDATE_RESTAURANT_HEADERS_REQUESTED,
  UPDATE_RESTAURANT_HEADERS_SUCCEEDED,
  UPDATE_RESTAURANT_HEADERS_FAILED,
  updateRestaurantHeadersSucceeded,
  updateRestaurantHeadersFailed
} = createRequestActions('UPDATE_RESTAURANT_HEADERS')

export const updateRestaurantHeadersRequested = (id,newHeaders, oldHeader, newHeader) => dispatch => {
  dispatch({
    type: UPDATE_RESTAURANT_HEADERS_REQUESTED,
  })
  restaurant
    .updateHeaders(id, newHeaders, oldHeader, newHeader)
    .then((data) => {
      dispatch(updateRestaurantHeadersSucceeded({newHeaders}))
    })
    .catch((error) => {
      dispatch(updateRestaurantHeadersFailed(error))
    })
}

export const defaultState = {}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case UPDATE_RESTAURANT_HEADERS_REQUESTED :
      return { ...state, requestState : requestStates.PENDING}
    case SELECT_CURRENT_RESTAURANT_SUCCEEDED: {
      const { data } = action.payload
      return { ...data.restaurant }
    }
    case EDIT_CURRENT_RESTAURANT_SUCCEEDED: {
      const { data } = action.payload
      
      return { ...state, ...data.restaurant, headers: [...data.headers], requestState: requestStates.SUCCESS }
    }
    case SELECT_CURRENT_RESTAURANT_FAILED: {
      return  {...state, requestState: requestStates.FAILED }
    }
    case UPDATE_RESTAURANT_HEADERS_SUCCEEDED: {
      const { newHeaders } = action.payload.data
      return {
        ...state,
        headers: newHeaders
      }
    }
    default:
      return state
  }
}

const getState = state => state.currentRestaurant.infos
const getHeaders = state => get(getState(state), 'headers', [])
const getId = state => get(getState(state), '_id', '')
const getRequestState = (state) => getState(state).requestState


export const selectors = {
  getState,
  getHeaders,
  getId,
  getRequestState
}