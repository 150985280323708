import {
  set,
  getMinutes,
  getHours,
  format,
  formatDistanceStrict
} from 'date-fns'
import { fr } from 'date-fns/locale'

export function parseHourString(date) {
  const hours = String(getHours(date)).padStart(2, '0')
  const minutes = String(getMinutes(date)).padStart(2, '0')
  return `${hours}:${minutes}`
}

export function parseDate(hourString) {
  const time = hourString.split(':')
  const date = new Date()
  return set(date, { hours: time[0], minutes: time[1] })
}

export function makeDateString(dateString) {
  const date = new Date(dateString)
  const isValid = !isNaN(date.getDate())
  return dateString
    ? isValid
      ? format(date, 'd MMMM yyyy HH:mm', { locale: fr })
      : 'Date invalide'
    : ''
}

export function makeFriendlyDateString(dateString, nowString) {
  const date = new Date(dateString)
  const nowDate = new Date(nowString)
  return `Il y a ${formatDistanceStrict(date, nowDate, { locale: fr })}`
}
