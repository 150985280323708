import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import { requestStates } from 'core.utils/requestStates'

import Snackbar from 'core.ui/Snackbar'
import { Button, TextField, Typography } from '@material-ui/core'
import { createRequestCityRequested, selectors as requestCitiesSelector } from '../../core.modules/requests/reducer'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  buttons: {
    display: 'flex',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}

class RequestCityCreate extends Component {
  constructor(props) {
    super(props)
    this.nameValidator = /\d/g
    this.state = {
      name: null,
      waitingForRequest: false,
      latitude: null,
      longitude: null,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handleLatitudeChange = e => {
    this.setState({ latitude: e.target.value })
  }
  handleLongitudeChange = e => {
    this.setState({ longitude: e.target.value })
  }

  handleAddRequestCity = () => {
    this.setState({ waitingForRequest: true })
    const { name, latitude, longitude } = this.state
    if (!name || this.nameValidator.test(name) || name?.trim().length === 0) {
      alert('Nom invalide')
      return
    }
    if (!latitude || isNaN(latitude) || latitude < -90 || latitude > 90) return alert('Latitude invalide')
    if (!longitude || isNaN(longitude) || longitude < -180 || latitude > 180) return alert('Longitude invalide')
    const cityInfos = { name: name?.trim(), latitude, longitude }
    this.props.createRequestCity(cityInfos)
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmitForm = productInfos => {
    this.setState({ waitingForRequest: true })
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const { name, latitude, longitude } = this.state
    return (
      <React.Fragment>
        <div style={styles.mainListContainer}>
          <Typography
          style={{ textAlign: 'center' }}
          variant="h5"
          component="h5"
          >
            Ajouter une ville pour les candidatures
          </Typography>
          <div style={styles.header}>
            <TextField
              label="Nom de la ville"
              value={name}
              onChange={this.handleNameChange}
              fullWidth
            />
            <TextField
              label="Latitude"
              value={latitude}
              onChange={this.handleLatitudeChange}
              fullWidth
            />
            <TextField
              label="Longitude"
              value={longitude}
              onChange={this.handleLongitudeChange}
              fullWidth
            />
          </div>
          <div style={styles.buttons}>
          <Button onClick={this.handleCancel} variant="contained" color="default">
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {this.handleAddRequestCity()}}
          >
            Ajout la ville pour les candidatures
          </Button>
          </div>
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={this.props.errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: requestCitiesSelector.getRequestState(state),
  errorMessage: requestCitiesSelector.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  createRequestCity: (cityInfos) => {
    dispatch(createRequestCityRequested(cityInfos))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(RequestCityCreate)
