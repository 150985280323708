export const GET_TEMPLATE='GET_TEMPLATE'
export const ADD_TEMPLATE='ADD_TEMPLATE'
export const CHECK_TEMPLATE='CHECK_TEMPLATE'
export const DISCARD_TEMPLATE='DISCARD_TEMPLATE'
export const GET_TEMPLATE_PRODUCTS='GET_TEMPLATE_PRODUCTS'
export const GET_TEMPLATE_SUPP='GET_TEMPLATE_SUPP'
export const GET_TEMPLATE_OPTIONS='GET_TEMPLATE_OPTIONS'
export const DUPLICATE_PRODUCTS='DUPLICATE_PRODUCTS'
export const DUPLICATE_TEMPLATE='DUPLICATE_TEMPLATE'
export const DELETE_OPTION='DELETE_OPTION'
export const CONFIRM_DELETE_OPTION='CONFIRM_DELETE_OPTION'
export const SYNC_PRODUCTS='SYNC_PRODUCTS'
export const SYNC_SUPPLEMENTS='SYNC_SUPPLEMENTS'
export const SYNC_OPTIONS='SYNC_OPTIONS'
export const ASSIGN_RESTAURANT='ASSIGN_RESTAURANT'
export const DELETE_SUPPLEMENT ='DELETE_SUPPLEMENT'
export const TEMPLATE_DRAG_DROP_SUCCEEDED='TEMPLATE_DRAG_DROP_SUCCEEDED'
export const CLEAR='CLEAR'
export const TEMPLATE_ERROR='TEMPLATE_ERROR'
