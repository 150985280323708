/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import Dialog from 'core.ui/Dialog'
import Select from 'core.ui/Select'
import { planningAPI } from 'core.services/api'

const getColor = (status) => {
  switch(status){
    case "pending":
      return "#FCD299"
    case "accepted":
      return "#5C985C"
    case "denied":
      return "#b70000"
    case "reserve":
      return "#8D97FC"
    default:
      return "white"  
  }
}

const styles = (theme) => ({
  newTableCell: {
    paddingLeft: 12, //theme.spacing(1),
    // paddingRight: theme.spacing(1)
  }
})

const NewTableCell = ({
  tableColumn,
  value,
  classes,
  style,
  planningId,
  isPreviousWeek,
  entity,
  session,
  refetch
}) => {
  const [state, setState] = React.useState({
    isPending: true,
    status: false
  })
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  React.useEffect(() => {
    setState({
      ...state,
      isPending: !entity?.status || entity?.status === 'pending' ? true : false,
      status: entity?.status
    })
  }, [ entity ])

  const handleChangeStatus = async (event) => {    
    const planning = {
      day: entity.day,
      session: session === "morningSession" ? "morning" : "night"
    }
    
    const value = event.target?.value

    try {
        if (value === "accepted") {
          await planningAPI.updatePlanningStatusAccept(planningId, planning)
          setState({ ...state, status: value })
        } else if (value === "denied") {
          await planningAPI.updatePlanningStatusDeny(planningId, planning)
          setState({ ...state, status: value })
        } else if (value === "reserve") {
          await planningAPI.updatePlanningStatusReserve(planningId, planning)
          setState({ ...state, status: value })
        }
    } catch(e) {
        console.log("Error in handleChangeStatus", e)
        alert(e.response?.data?.errors[0]?.message || "Erreur lors du changement de status")
    }
  }

  const onDeleteSession = async () => {    
    const day = entity.day
    const currentSession = session === "morningSession" ? "morning" : "night"
    
    try {
        await planningAPI.deletePlanningSession(planningId, day, currentSession)
        refetch()
        setIsModalVisible(false)
    } catch(e) {
        alert(e.response?.data?.errors[0]?.message || "Erreur lors de la suppression")
    }
  }

  const onOpenDeleteSession = () => {
    setIsModalVisible(true)
  }

  const onCloseDeleteDialog = () => {
    setIsModalVisible(false)
  }

  return (
    <TableCell
      className={classes.newTableCell}
      style={{
        backgroundColor: getColor(state?.status),
        textAlign: tableColumn.align,
        borderRight: '1px solid lightgrey',
        ...style
      }}
      align="center"
    >
      <FormGroup row>        
          <div style={{display:"flex", alignItems:"center"}} >
             <p style={{fontWeight:"bold", margin: 0, fontSize:18}}>{value}</p> 
            {!isPreviousWeek && <IconButton aria-label="delete">
                <DeleteIcon disabled={isPreviousWeek} onClick={onOpenDeleteSession} />
            </IconButton>}
          </div>
        
          <Select
            value={state?.status}
            options={["pending", "accepted", "denied", "reserve"]}
            captions={["", "Accepté", "Refusé", "Réserviste"]}
            autoFill={false}
            onChange={id => handleChangeStatus(id)}          
          />                  
      </FormGroup>
      <Dialog
        open={isModalVisible}
        dialogText="Confirmer la suppression"
        dialogTitle="Confirmation"
        cancelButtonCaption="Annuler"
        confirmButtonCaption="Ok"
        onCancel={onCloseDeleteDialog}
        onValidation={onDeleteSession}
      />
    </TableCell>
  )
}

NewTableCell.propTypes = {
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  tableColumn: PropTypes.object,
  children: PropTypes.node
}
NewTableCell.defaultProps = {
  style: {},
  tableColumn: {},
  children: undefined
}

export const NewTableCellSession = withStyles(styles, { name: 'newTableCell' })(
  NewTableCell
)

