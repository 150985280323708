import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { Autocomplete } from '@material-ui/lab'

import { isEmpty } from 'lodash';

import { issue } from 'core.services/api'

export default function FormDialog({open, onClose, riderId, setReload, reload}) {
  const cityId = localStorage.getItem('selectedCity');

  const [state, setState] = React.useState({
    reason: "",
    type: "",
    description: "",
    concernedParty: "rider",
    status: "opened",
    riderId: riderId,
    cityId: cityId,
    origin: 'rider'
  })

  const [reasons, setReasons] = React.useState(null)
  const [types, setTypes] = React.useState([])

  React.useEffect(() => {
    issue.getConfig().then(data => {
      setTypes(data.types)
      setReasons(data.reasons)
    })
  }, [])

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.value});
  };

  const handleSubmit = () => {
    const body = {...state}

    issue.createIssue(body).then(data => {
      setReload(r => !r)
      onClose();
    }).catch((err) => alert(err?.response?.data?.errors[0]?.message))
  }

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Ajouter un ticket</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="type">Type</InputLabel>
            <Select
              fullWidth
              labelId="type"
              name='type'
              value={state.type}
              label="Type"
              onChange={handleChange}
            >
              {types.map((type) => (<MenuItem value={type.value}>{type.text}</MenuItem>))}
            </Select>
          </FormControl>
          <br/><br/>
          {!isEmpty(state.concernedParty) && reasons !== null && <FormControl fullWidth>
            <Autocomplete
                required
                options={reasons?.riderReasons}
                getOptionLabel={(option) => option?.text}
                getOptionSelected={(option) => option?.text}
                onChange={(event, value) =>
                  setState({...state, reason: value?.value})
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Titre"
                    variant="outlined"
                  />
                )}
              />
          </FormControl>}
          <br/>
          <TextField
            style={{ fontColor: "green" }}
            autoFocus
            margin="dense"
            id="description"
            name="description"
            onChange={handleChange}
            label="Commentaire"
            multiline
            rows={3}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
        <Button  style={{color:"black"}} onClick={onClose}>ANNULER</Button>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
  );
}
