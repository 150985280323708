import React from 'react'
import { connect } from 'react-redux'

import { selectors as restaurantOptionsSelectors } from 'core.modules/currentRestaurant/options/reducer'
import TransferList from 'core.ui/TransferList'

class ChooseOption extends React.Component {
  render() {
    const { onChange, allOptions, selectedOptions } = this.props
    return (
      <div>
        <TransferList
          list={allOptions.options}
          currentRight={selectedOptions}
          onChange={onChange}
          withOptionsDetails
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allOptions: restaurantOptionsSelectors.getState(state)
})
export default connect(mapStateToProps)(ChooseOption)
