import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography
} from '@material-ui/core'

import {
  selectors as currentRestaurantInfosSelectors,
  editCurrentRestaurantRequested
} from 'core.modules/currentRestaurant/infos/reducer'

import { createRestaurantOptionRequested,createTemplateOptionRequested } from 'core.modules/currentRestaurant/options/reducer'
import { createRestaurantSupplementRequested,createTemplateSupplementRequested } from 'core.modules/currentRestaurant/supplements/reducer'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'

import SwitchWithLabel from 'core.ui/SwitchWithLabel'
import CreateOption from 'components/CreateOption'
import CreateSupplement from 'components/CreateSupplement'
import CreateCustomCategory from 'components/CreateCustomCategory'
import Snackbar from 'core.ui/Snackbar'
import { requestStates } from 'core.utils/requestStates'

const styles = {
  paper: {
    padding: '16px',
    marginBottom: '16px'
  }
}

class ProductGeneralOption extends Component {
  constructor(props){
    super(props)
    this.state={
      open:false,
      snackBarMessage: '',
      snackBarSeverity: 'error'
    }
  }

  componentDidUpdate(prevProps){
    
    const { currentsupplment, currentOption } = this.props
    if (prevProps.currentsupplment.requestState === requestStates.PENDING && currentsupplment.requestState === requestStates.FAILED) {
        this.setState({
          open: true,
          snackBarMessage: currentsupplment.errorMessage || 'Erreur lors de la création du supplement',
        })
    }
    if (prevProps.currentOption.requestState === requestStates.PENDING && currentOption.requestState === requestStates.FAILED) {
      this.setState({
        open: true,
        snackBarMessage: currentOption.errorMessage || "Erreur lors de la création de l'option",
      })
  }
  }

  handleCloseSnackbar = () => {
    this.setState({ open: false })
  }

  handleCreateItem = field => e => {
    const {
      dispatch,
      currentRestaurantHeaders,
      currentRestaurantId,
      currentsupplment
    } = this.props
    const { value } = e.target
    switch (field) {
       case 'options':
        if (this.props.templates.templateid !== null) {
          // await template.add(this.props.templates.templateid, {option:value})
          dispatch(createTemplateOptionRequested(this.props.templates.templateid,value))
        } else {
          dispatch(createRestaurantOptionRequested(currentRestaurantId, value))
        }
       
        break
      case 'supplements':
        if(this.props.templates.templateid !== null) {
          dispatch(createTemplateSupplementRequested(this.props.templates.templateid,value))
        }else {
          dispatch(
            createRestaurantSupplementRequested(currentRestaurantId, value)
          )    
        }
        
        break
      case 'customCategory':
        const newHeaders = [...currentRestaurantHeaders, value]
        dispatch(
          editCurrentRestaurantRequested(currentRestaurantId, {
            headers: newHeaders
          })
        )
        break
      default:
        return
    }
  }

  render() {

    const {
      className,
      classes,
      tva,
      onChange,
      onSwitchChange,
      haveStock,
      haveOptions,
      haveSupplements,
      haveCustomCategory,
      currentRestaurant,
      templates,
      currentSelectedCity
    } = this.props

    return (
      <div className={className}>
        <Typography
          style={{ textAlign: 'center' }}
          variant="h4"
          component="h2"
          gutterBottom
        >
          Option général
        </Typography>

        {/* TVA */}
        <Paper className={classNames(classes.paper)} elevation={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">TVA</FormLabel>
            <RadioGroup
              aria-label="TVA"
              name="TVAGroup"
              value={tva}
              onChange={onChange('tva')}
            >
            {currentSelectedCity && currentSelectedCity.pricing && [<FormControlLabel value={0} control={<Radio />} label={`${0} %`} />, ...currentSelectedCity?.pricing?.taxValues?.map(tax => <FormControlLabel value={tax} control={<Radio />} label={`${tax} %`} />)]}
            </RadioGroup>
          </FormControl>
        </Paper>

        {/* STOCK */}
        <Paper className={classNames(classes.paper)} elevation={3}>
          <FormControl>
            <FormLabel component="legend">Stock</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={haveStock}
                  onChange={onSwitchChange('haveStock')}
                />
              }
              label="En stock"
            />
          </FormControl>
        </Paper>

        {/* CREATE OPTIONS */}
        <SwitchWithLabel
          checked={haveOptions}
          onChange={onSwitchChange('haveOptions')}
          label="Créer des options"
        />
        {haveOptions && (
          <Paper className={classNames(classes.paper)} elevation={3}>
            <CreateOption restaurantCity={currentSelectedCity} onSubmit={this.handleCreateItem('options')} currentRestaurant={currentRestaurant} templates={templates}/>
          </Paper>
        )}

        {/* CREATE SUPPLEMENTS */}
        <FormControlLabel
          control={
            <Switch
              checked={haveSupplements}
              onChange={onSwitchChange('haveSupplements')}
            />
          }
          label="Créer des suppléments"
        />
        {haveSupplements && (
          <Paper className={classNames(classes.paper)} elevation={3}>
            <CreateSupplement onSubmit={this.handleCreateItem('supplements')} currentRestaurant={currentRestaurant} templates={templates}/>
          </Paper>
        )}

        {/* CREATE CUSTOM CATEGORY */}
        <FormControlLabel
          control={
            <Switch
              checked={haveCustomCategory}
              onChange={onSwitchChange('haveCustomCategory')}
            />
          }
          label="Créer des catégories personnalisées"
        />
        {haveCustomCategory && (
          <Paper className={classNames(classes.paper)} elevation={3}>
            <CreateCustomCategory
              onSubmit={this.handleCreateItem('customCategory')}
            />
          </Paper>
        )}
        <Snackbar
          open={this.state.open}
          onClose={this.handleCloseSnackbar}
          severity={this.state.snackBarSeverity}
          message={this.state.snackBarMessage}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRestaurantHeaders: currentRestaurantInfosSelectors.getHeaders(state),
  currentsupplment:state.currentRestaurant.supplements,
  currentOption:state.currentRestaurant.options,
  currentSelectedCity: citiesSelectors.getCity(state)
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ProductGeneralOption)
