import React from 'react'
import List from 'core.ui/List'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect, useDispatch } from 'react-redux'
import { Button, TextField } from '@material-ui/core'
import {getoptions ,checkifTemplate,deleteOption, confirmDeleteOption ,ClearTemplates,syncOptions} from "../../core.modules/Template/Actions";
import { fetchRestaurantOptionsFailed, fetchRestaurantOptionsSucceeded, fetchTemplateOptionsRequested } from '../../core.modules/currentRestaurant/options/reducer'
import { template } from '../../core.services/api'
import Dialog from 'core.ui/Dialog'
import SyncIcon from '@material-ui/icons/Sync';
import Snackbar from 'core.ui/Snackbar'

const style = {
    h3: { textAlign: 'center' },
    autocmplete: { marginLeft: '40%' },
    sync:{position:'absolute',marginLeft: '7%'}
  }

const OptionsTemplate =({templates:{templates,options,err},getoptions,history,checkifTemplate,deleteOption,ClearTemplates,syncOptions})=>{
  const dispatch = useDispatch()
  const [templateId, setTemplateId] = React.useState(null)
  const [openDeleteDialog,setopenDeleteDialog]=React.useState(false)
  const [optionId,setoptionId]=React.useState(null)
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState('Succes synchronisation')
  const [snackBarSeverity, setsnackBarSeverityMessage] = React.useState('success')
  const [openSyncDialog,setOpenSyncDialog]=React.useState(false)
  const ClosesyncDialog =()=>{
    setOpenSyncDialog(false)
  }
  const syncOption =()=>{
    syncOptions(templateId)
    setOpen(true);
    setOpenSyncDialog(false)
  }
 
  const handleClick = () => {
   setOpenSyncDialog(true)
  };

  React.useEffect(()=>{
 
    if(err) {
      setSnackbarMessage(err)
      setsnackBarSeverityMessage('error')
    } else {
      setSnackbarMessage('Succes synchronisation ')
      setsnackBarSeverityMessage('success')
    }
  
})


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(()=>{
    if(!templateId)  {
      ClearTemplates()
    }
    
  },[templateId,ClearTemplates])
  const handleDelete=id=>{
    setoptionId(id)
    setopenDeleteDialog(true)
  }
  const closeDeleteDialog =()=>{
    setopenDeleteDialog(false)
  }
  const DeleteOption =()=>{
   
    deleteOption(templateId,optionId)
    setopenDeleteDialog(false)
  }
  
 
  const handleTemplateChange = (e, value) => {
    if(value?._id){
      template
      .getOptionBytemplateId(value?._id)
      .then(data => {
        dispatch(fetchRestaurantOptionsSucceeded(data))
      })
      .catch(error => {
        dispatch(fetchRestaurantOptionsFailed(error))
      })
      getoptions(value?._id)
     
    }
    setTemplateId(value?._id)
    checkifTemplate(value?._id)
   
  }
  
const  handleEdit = id => {
   
    history.push(`/restaurants/options/edit?id=${id}`)
  }
const renderList=(option)=>{

    return [
      option.name,
      <p>{option.tax}%</p>,
      option.optionTypes.map(optionTypes=>optionTypes.type),
      option.order,
      ''
    ]
  
 
}
    return (
        <div>
             <h3 style={style.h3}>Liste des templates options</h3>
             <div style={style.autocmplete}>
        <Autocomplete
          id="combo-box-demo"
          options={templates}
          getOptionLabel={(templates) => templates.name}
          style={{ width: 300 }}
          onChange={handleTemplateChange}
          renderInput={(params) => (
            <TextField {...params} label="Template" variant="outlined" />
          )}
        />
     {templateId ?<div style={style.sync}>  <Button onClick={handleClick}> <SyncIcon/> </Button></div>:<div></div>
      } 
      </div>
        <List
          ids={options.map((option) => option._id || option.id)}
          columns={['Nom', 'TVA', 'Choix', 'Ordre', '']}
          actions={{ handleEdit: handleEdit,handleDelete:handleDelete }}
          emptyMessage="Aucune template"
           list={options.map((option) => {
            return renderList(option)
          })} 
        />
        <Dialog
              open={openDeleteDialog}
              dialogText="continuer ?"
              dialogTitle="Supprimer l'option"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={closeDeleteDialog}
              onValidation={DeleteOption}
            />

              <Dialog
              open={openSyncDialog}
              dialogText={`
              Synchronisation des options: prix, image, id systeme de caisse, menu...`}
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={ClosesyncDialog}
              onValidation={syncOption}
            />
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}   severity={snackBarSeverity}
          message={snackBarMessage} />
      </div>
    )
}

const mapStateToProps = (state) => ({
  templates: state.templates,
  
})
export default connect(mapStateToProps,{getoptions,checkifTemplate,deleteOption,confirmDeleteOption,ClearTemplates,syncOptions}) (OptionsTemplate)