import { red } from '@material-ui/core/colors'

export default {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    display: 'flex',
    flex: 1,
    width: '65%'
  },
  filtersMainContainer: {
    margin: '0 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  filtersSpecialContainer: {
    marginLeft: '6px',
    display: 'flex',
    flexDirection: 'column'
  },
  filtersLineContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  filtersButtons: {
    borderRadius: '2px',
    border: '0.5px solid black',
    fontSize: '13px',
    minHeight: '15px',
    cursor: 'pointer',
    padding: '2px 5px',
    margin: '1px'
  },
  topBarControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  notifSendButton: {
    backgroundColor: ' #33cc99',
    color: 'white'
  },
  green: {
    color: '#33cc99'
  },
  tabsContainer: {
    // display: 'flex',
    width: '100%'
  },
  orderStatusFiltersContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  },
  topFiltersContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
  },
  leftSideFilters: {
    display: 'flex',
    flexDirection: 'column'
  },
  rightSideFilters: {
    display: 'flex'
  },
  allCitiesSwitchContainer: {
    display: 'flex',
    marginTop: 5,
    marginLeft: 5
  },
  rushSwitchContainer: {
    display: 'flex',
    marginTop: 5,
    marginLeft: 5
  },
  currentFilter: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 25
  },
  orange: {
    backgroundColor: 'orange'
  },
  yellow: {
    backgroundColor: 'yellow'
  },
  skyBlue: {
    backgroundColor: 'skyBlue'
  },
  red: {
    backgroundColor: 'red'
  },
  violet: {
    backgroundColor: 'violet'
  },
  grey: {
    backgroundColor: 'grey'
  },
  darkGreen: {
    backgroundColor: 'darkGreen'
  },
  green: {
    backgroundColor: 'green'
  },
  priorityOrdersColor: {
    backgroundColor: red[900]
  },
  black: {
    color: 'black'
  },
  white: {
    color: 'white'
  },
  searchContainer: {
    display: 'flex',
    alignSelf: 'flex-start'
  },
  searchBarContainer: {
    display: 'flex',
    alignSelf: 'center'
  },
  searchRefreshContainer: {
    display: 'flex',
    alignSelf: 'center'
  },
  countersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid',
    borderWidth: 0.5,
    borderColor: 'gray',
    justifyContent: 'center',
    width: '10%'
  },
  counterTopContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 40
  },
  counterTopContainerBlack: {
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    backgroundColor: 'black'
  },
  counterValue: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: 25,
    fontWeight: '700'
  },
  counterValueWhite: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: 25,
    fontWeight: '700',
    color: 'white'
  },
  counterValueRed: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: 25,
    fontWeight: '700',
    color: 'red'
  },
  counterBottomContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 40
  },
  counterTitle: {
    display: 'flex',
    textAlign: 'center',
    alignSelf: 'center'
  },
  countDownText: {
    fontSize: 30,
    fontWeight: 'bold'
  },

  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  },
  progressBar: {
    marginBottom: '10px',
    height: '10px',
    width: '100%'
  },
  linearProgress: {
    width: '100%'
  }
}
