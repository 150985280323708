import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Paper,
  ClickAwayListener,
  Button,
  Grow,
  Popper
} from '@material-ui/core'

const styles = theme => ({
  root: {
    position: 'relative',
    zIndex: '100',
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  button: { padding: 0, minWidth: 0 }
})

// using https://material-ui.com/components/menus/#menulist-composition (tweaked a bit)
class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
    this.anchorRef = React.createRef()
  }

  handleToggle = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleClose = event => {
    const { onClose } = this.props
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return
    }
    this.setState({ open: false })
    onClose()
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open)
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     this.anchorRef.current.focus()
  //   }
  //   prevOpen.current = open
  // }, [open])
  render() {
    const { classes, displayedComponent, button } = this.props
    const { open } = this.state
    const menu = ({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin:
            placement === 'bottom' ? 'center top' : 'center bottom'
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={this.handleClose}>
            {displayedComponent}
          </ClickAwayListener>
        </Paper>
      </Grow>
    )
    return (
      <div className={classes.root}>
        <div>
          <Button
            className={classes.button}
            ref={this.anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            {button}
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {menu}
          </Popper>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Dropdown)
