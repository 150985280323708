import {
  SINGLE_CLAIM,
  GET_ALL_CLAIMS,
  CLAIM_ERROR,
  CLAIMS_ORDER_GROUP,
  ORDER_CLAIM,
  BACK_TO_PREVIEW_PAGE,
  GET_CLAIMED_ORDER,
  ADD_CLAIM,
  RESPOND_CLAIM,
  GET_CONFIG,
  RESET_ERROR,
  CLAIM_PAGINATION
} from './Types'
import { claims, order } from '../../core.services/api'

export const getAllClaims = () => async (dispatch) => {
  try {
    const res = await claims.getAllClaims()
    dispatch({
      type: GET_ALL_CLAIMS,
      payload: res
    })
  } catch (error) {
    const errors = error.response.data.errors[0].message

    dispatch({
      type: CLAIM_ERROR,
      payload: errors
    })
  }
}
export const ClaimDetail = (id) => async (dispatch) => {
  try {
    const res = await claims.getClaimDetails(id)
    dispatch({
      type: SINGLE_CLAIM,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: CLAIM_ERROR
    })
  }
}
export const getClaimsOrderGroup =
  (paginationOptions, city, email, restaurantId, startDate, endDate, closed) => async (dispatch) => {
    try {
      let closedClaim
      if (closed === true) { closedClaim = true  } else {closedClaim = '' }
      const res = await claims.getClaimsOrderGroup(paginationOptions, city, email, restaurantId, startDate, endDate, closedClaim)

      let filter = {
        pagination: { page: paginationOptions.page, limit: 20 }, city: city,
        email: email,
        restaurantId: restaurantId,
        startDate: startDate,
        endDate: endDate,
        closed: closedClaim
      }

      if (closedClaim === true) { filter.closed = true } else { filter.closed = '' }

      dispatch({
        type: CLAIMS_ORDER_GROUP,
        payload: { res: res, filter: filter }
      })
    } catch (error) {
      dispatch({
        type: CLAIM_ERROR
      })
    }
  }
export const getOrderClaim = (id) => async (dispatch) => {
  try {
    const res = await claims.getOrderClaim(id)
    dispatch({
      type: ORDER_CLAIM,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: CLAIM_ERROR
    })
  }
}
export const getOrder = (id) => async (dispatch) => {
  try {
    const res = await order.getOrder(id)
    dispatch({
      type: GET_CLAIMED_ORDER,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: CLAIM_ERROR
    })
  }
}
export const addClaim =
  (userId, formData, history, image) => async (dispatch) => {
    await claims
      .addClaim(userId, formData)
      .then(async (res) => {
        dispatch({
          type: ADD_CLAIM,
          payload: res
        })
        if (image) {
          await claims.uploadClaimImage(res.id || res._id, formData.image)
        }

        history.push('/Claims/ordergroup')
      })
      .catch((error) => {
        const errors = error.response.data.errors[0].message

        dispatch({
          type: CLAIM_ERROR,
          payload: errors
        })
      })
  }
export const goback = () => (dispatch) => {
  dispatch({
    type: BACK_TO_PREVIEW_PAGE
  })
}
export const RespondToClaim = (claimId, formData) => async (dispatch) => {
  if (formData.response === 'refunded') {
    formData.refundedAmount = 0
  }
  await claims
    .AcceptRejectClaim(claimId, formData)
    .then(async (data) => {
      if (formData.image) {
        await claims.uploadClaimResponseImage(
          data.data.id || data.data._id,
          formData.image
        )
      }
      dispatch({
        type: RESPOND_CLAIM,
        payload: data.data
      })
    })
    .catch((err) => {
      const errors = err.response.data.errors[0].message
      dispatch({
        type: CLAIM_ERROR,
        payload: errors
      })
    })
}
export const getConfig = () => async (dispatch) => {
  try {
    const res = await claims.config()
    dispatch({
      type: GET_CONFIG,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: CLAIM_ERROR
    })
  }
}
export const resetError = () => async (dispatch) => {
  dispatch({
    type: RESET_ERROR
  })
}
export const onPaginationClaimChange = (page) => async (dispatch) => {
  dispatch({
    type: CLAIM_PAGINATION,
    payload: page
  })
}
