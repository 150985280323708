import * as yup from 'yup'

export const schema = {
  editSpendGet: yup.object().shape({
    type: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    days: yup
      .array('Selectionner au moins un jour')
      .min(1, 'Selectionner au moins un jour')
      .of(yup.string())
      .required(),
    hour: yup.object({
      start: yup.string().required(),
      end: yup.string().required()
    }),
    discountType: yup.string().required(),
    discountValue: yup.number().when('discountType', {
      is: 'value',
      then: yup.number().positive().required(),
      otherwise: yup
        .number()
        .positive()
        .min(0, 'Le nombre entrer est incorrect')
        .max(1, 'Le nombre entrer est incorrect')
        .transform((value) => value / 100)
        .required()
    }),
    minAmount: yup
      .number()
      .positive()
      .min(0, "la valeur minimum c'est 0")
      .required()
  }),
  editBuyGet: yup.object().shape({
    type: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    days: yup
      .array()
      .min(1, 'Selectionner au moins un jour')
      .of(yup.string())
      .required(),
    hour: yup.object({
      start: yup.string().required(),
      end: yup.string().required()
    }),
    // buy_x_get_y
    // spend_x_get_y
    grantedProducts: yup.array().when('type', (type, schema) => type === "buy_x_get_y" ? schema.min(1, 'Selectionner au moins un produit')
    .of(
      yup.object().shape({
        productId: yup.string().required(),
        value: yup.number().default(1).required()
      })
    )
    .required('Ce champs est obligatoire') : schema),
    grantingProducts: yup.array().when('type', (type, schema) => type === "buy_x_get_y" ? schema.min(1, 'Selectionner au moins un produit')
    .of(
      yup.object().shape({
        productId: yup.string().required(),
        value: yup.number().default(1).required()
      })
    )
    .required('Ce champs est obligatoire') : schema) 
  })
}
