import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import {
  selectors as usersSelectors,
  fetchAllUsersRequested
} from 'core.modules/users/reducer'

import List from 'core.ui/List'
import UserCreate from '../UserCreate'
import UserEdit from '../UserEdit'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'
import SearchBar from 'core.ui/SearchBar'
import Snackbar from 'core.ui/Snackbar'
import Dialog from 'core.ui/Dialog'
import { searchUsersRequested, deleteUserRequested } from '../../core.modules/users/reducer'
import { requestStates } from '../../core.utils/requestStates'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  filterLabel: {
    marginLeft: '7px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  }
}

class UserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      displayingSearchResult: false,
      idToDelete: null,
      currentPage: 1,
      numberPerPage: 20,
      searchedUsers: [],
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }


  handlePageChange = page => {
    this.setState({ currentPage: page }, () => {
      this.getUsers()
    })
  }

  handleEdit = (id) => {
    const { history } = this.props
    history.push(`/utilisateurs/edit?id=${id}`)
  }

  handleDelete = userId => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: userId
    })
  }

  confirmDelete = () => {
    const { deleteUser } = this.props
    this.setState({ waitingForRequest: true })
    deleteUser(this.state.idToDelete)
    this.closeDeleteDialog()
  }

  getUsers = () => {
    const { currentPage, numberPerPage } = this.state
    const { fetchAllUsers } = this.props
    fetchAllUsers(currentPage, numberPerPage);
    this.setState({ displayingSearchResult: false })
  }

  parseUser = ({
    nom,
    prenom,
    email,
    telephone,
    appVersion
  }) => {
    return [
      prenom, 
      nom, 
      email, 
      telephone, 
      appVersion ? appVersion : '< 3.0.3'
    ]
  }

  searchUsers = (query) => {
    const { searchUsers } = this.props
    const fullQuery = { ...query }
    searchUsers(fullQuery)
  }

  clearSearch = () => {
    this.getUsers()
    this.setState({ displayingSearchResult: false })
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  render() {
    const { classes, users, displayingSearchResult, searchedUsers, errorMessage } = this.props
    const { openDeleteDialog } = this.state
    const displayedUsers = displayingSearchResult ? searchedUsers.map(this.parseUser) : users.map(this.parseUser)
    const ids = displayingSearchResult ? searchedUsers.map((user) => user.id) : users.map((user) => user.id)
    return (
      <div className={classes.mainContainer}>
        <Switch>
          <Route
            path="/utilisateurs/new"
            exact
            render={props => <UserCreate {...props} />}
          />
          <Route
            path="/utilisateurs/edit"
            render={(props) => {
              const id = props.location.search.split('=')[1]
              const data = displayingSearchResult 
                ? searchedUsers.find((user) => user.id === id)
                : users.find((user) => user.id === id)
              return <UserEdit id={id} data={data} {...props} />
            }}
          />
          <Route
            path="/utilisateurs"
            exact
            render={props => (
              <React.Fragment>
                <div className={classes.topBarControls}>
                  <SearchBar
                    searchFields={{
                      email: 'Email',
                      prenom: 'Prenom',
                      nom: 'Nom',
                      telephone: 'Telephone',
                    }}
                    onSearch={this.searchUsers}
                    onClearSearch={this.clearSearch}
                    displayClearSearch={displayingSearchResult}
                  />
                  <ButtonWithIcon
                    featherIcon="RefreshCw"
                    size={20}
                    onClick={this.fetchOrders}
                  />
                </div>
                <div>
                  <Link className={classes.addButton} to="/utilisateurs/new">
                    <Button variant="contained" color="secondary">
                      Ajouter un utilisateur
                    </Button>
                  </Link>
                </div>
                <List
                  list={displayedUsers}
                  columns={[
                    'Prénom',
                    'Nom',
                    'Email',
                    'Téléphone',
                    'Version'
                  ]}
                  ids={ids}
                  actions={{
                    handleEdit: this.handleEdit,
                    handleDelete: this.handleDelete
                  }}
                  onPageChange={this.handlePageChange}
                  emptyMessage="Aucun Utilisateur"
                />
                <Dialog
                  open={openDeleteDialog}
                  dialogText="Confirmer la suppression"
                  dialogTitle="Confirmation"
                  cancelButtonCaption="Annuler"
                  confirmButtonCaption="Ok"
                  onCancel={this.closeDeleteDialog}
                  onValidation={this.confirmDelete}
                />
                <Snackbar
                  open={this.state.openSnackBar}
                  onClose={this.handleCloseSnackbar}
                  severity="error"
                  message={errorMessage}
                />
              </React.Fragment>
            )}
          />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: usersSelectors.getAllUsers(state),
  displayingSearchResult: usersSelectors.getDisplayingSearchResult(state),
  searchedUsers: usersSelectors.getSearchedUsers(state),
  currentRequestState: usersSelectors.getRequestState(state),
  errorMessage: usersSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  fetchAllUsers: (page, limit) => {
    dispatch(fetchAllUsersRequested(page, limit))
  },
  searchUsers: (query) => {
    dispatch(searchUsersRequested(query))
  },
  deleteUser: (userId) => {
    dispatch(deleteUserRequested(userId))
  }
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(UserList)
