import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

function SwitchWithLabel({ checked, onChange, label }) {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  )
}

export default SwitchWithLabel
