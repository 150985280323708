import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Link, Route, Switch } from 'react-router-dom'
import RequestCityList from '../RequestCityList'
import RestaurantRequestsList from '../RestaurantRequestsList'
import RiderRequestsList from '../RiderRequestsList'
import { FinancesPrivateRoute } from '../../core.ui/PrivateRoute/PrivateRoute'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}


class Requests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      indexToDelete: null,
      currentId: null
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes, history } = this.props
    return (
      <Switch>
        <FinancesPrivateRoute
          path="/candidatures/villes"
          component={() => <RequestCityList history={history} />}
        />
        <Route path="/candidatures/restaurants" exact>
          <RestaurantRequestsList history={history} />
        </Route>
        <Route path="/candidatures/livreurs" exact>
          <RiderRequestsList history={history} />
        </Route>
        <Route path="/candidatures" exact>
          <div>
            <div style={styles.mainListContainer}>
              <Link className={classes.addButton} to="/candidatures/villes">
                <Button variant="contained" color="secondary">
                  Gerer les ville pour les candidatures
                </Button>
              </Link>
            </div>
            <div style={styles.mainListContainer}>
              <Link className={classes.addButton} to="/candidatures/restaurants">
                <Button variant="contained" color="secondary">
                  Gerer les candidatures des restaurants
                </Button>
              </Link>
            </div>

            <div style={styles.mainListContainer}>
              <Link className={classes.addButton} to="/candidatures/livreurs">
                <Button variant="contained" color="secondary">
                  Gerer les candidatures des livreurs
                </Button>
              </Link>
            </div>
          </div>
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Requests)
