import React, { useState } from 'react';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { planningAPI } from 'core.services/api'
import DialogPlanningForm from './DialogPlanningForm';
import DialogPlanningActions from './DialogPlanningActions';
// import { NEXT_WEEK } from '../planningsConstant';

export default function NewEditPlanning({ session, refetch, selectedWeek }) {
	const [ isVisible, setisVisible ] = useState(false);
	const [ isLoading, setisLoading ] = useState(false);
	const [ newPlanning, setnewPlanning ] = useState([]);

	const onSavePlanning = async () => {
		const planningId = session.planning[0]?.id || session.planning[0]?._id
		try {
			setisLoading(true)
			if (!planningId) {
				const riderId = session.rider._id	
				await planningAPI.createPlanning(riderId, selectedWeek, newPlanning)
			} else {
				await planningAPI.updatePlanning(planningId, selectedWeek, newPlanning)
			} 
			refetch()
			setisVisible(false);
		} catch(e) {
				alert(e?.response?.data?.errors[0]?.message)
		} finally {
				setisLoading(false)
		} 
	}	

	return (
		<React.Fragment>
			<IconButton
				onClick={() => {
					setisVisible(true);
				}}
			>
				<EditIcon />
			</IconButton>
			<Dialog open={isVisible} fullWidth={true} maxWidth="md" id="planning-form-main-container">
				<DialogTitle style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize:25 }}>
					<h2>Planning pour {session.rider.firstName} {session.rider.lastName}</h2>
				</DialogTitle>
				<DialogContent>
					<DialogPlanningForm isVisible={isVisible} currentPlanning={session?.week} isEmpty={session?.isEmpty} updateNewPlanning={(pl) => setnewPlanning(pl)} />
				</DialogContent>
				<DialogPlanningActions
					handleSubmit={onSavePlanning}
					onCancel={() => setisVisible(false)}
					showLoading={isLoading}
					session={session}
				/>
			</Dialog>
		</React.Fragment>
	);
}
