import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  OutlinedInput
} from '@material-ui/core'
import dayjs from 'dayjs'
import { schema } from '../validations'
import { Alert } from '@material-ui/lab'
import { Save } from '@material-ui/icons/'
import frLocale from 'date-fns/locale/fr'
import DateFnsUtils from '@date-io/date-fns'
import { deal } from '../../../core.services/api'
import Snackbar from '@material-ui/core/Snackbar'
import React, { useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import { useForm, Controller } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const SpendGetForm = ({data}) => {
  const {
    state: { restaurantID }
  } = useLocation()
  const [notifier, setNotifier] = useState({
    open: false,
    type: '',
    content: []
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setNotifier({
      open: false,
      type: '',
      content: []
    })
  }

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema.editSpendGet)
  })
  const history = useHistory()
  const watchStartDate = watch('startDate')
  const watchDiscountType = watch('discountType')
  const watchEndDate = watch('endDate')
  const days = [
    { value: 'monday', text: 'Lundi' },
    { value: 'tuesday', text: 'Mardi' },
    { value: 'wednesday', text: 'Mercredi' },
    { value: 'thursday', text: 'Jeudi' },
    { value: 'friday', text: 'Vendredi' },
    { value: 'saturday', text: 'Samedi' },
    { value: 'sunday', text: 'Dimanche' }
  ]

  useEffect(() => {
    if (data) {
      reset({...data, discountValue: data?.discount?.value})
    }
  }, [data])

  const onSubmit = async (promotion) => {
    if(data){
      try {
        const formatedData = {
          ...promotion,
          discount: {
            [promotion.discountType]: promotion.discountValue
          }
        }
        delete formatedData.discountType
        delete formatedData.discountValue
  
        await deal.edit(data._id, formatedData)
        reset()
        setNotifier({
          open: true,
          type: 'success',
          content: [{ message: 'Votre offre a bien été modifiée' }]
        })
        setTimeout(() => {
          history.push('/marketing/deals')
        }, 1000)
      } catch (error) {
        if (error.response) {
          const { errors } = error.response.data
          setNotifier({
            open: true,
            type: 'error',
            content: errors
          })
        }
      }
    }else{
      try {
        const formatedData = {
          ...promotion,
          discount: {
            [promotion.discountType]: promotion.discountValue
          }
        }
        delete formatedData.discountType
        delete formatedData.discountValue
  
        await deal.add(
          formatedData,
          restaurantID || localStorage.getItem('selectedRes')
        )
        reset()
        setNotifier({
          open: true,
          type: 'success',
          content: [{ message: 'Votre offre a bien été créée' }]
        })
        setTimeout(() => {
          history.push('/marketing/deals')
        }, 1000)
      } catch (error) {
        if (error.response) {
          const { errors } = error.response.data
          setNotifier({
            open: true,
            type: 'error',
            content: errors
          })
        }
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('type')} value="spend_x_get_y" />

        <Box display="flex" direction="row" my={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
            <FormControl fullWidth>
              <Controller
                name="startDate"
                control={control}
                defaultValue={dayjs()}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    ampm={false}
                    minDate={dayjs()}
                    maxDate={watchEndDate}
                    disablePast={true}
                    error={!!errors.startDate}
                    onChange={onChange}
                    label="Date de début"
                    value={value || dayjs()}
                    format="dd MMMM yyyy HH:mm"
                    inputVariant="outlined"
                    helperText={errors.startDate && errors.startDate.message}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="endDate"
                control={control}
                defaultValue={watchStartDate}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    ampm={false}
                    error={!!errors.endDate}
                    minDate={watchStartDate}
                    label="Date de fin"
                    onChange={onChange}
                    inputVariant="outlined"
                    value={value}
                    format="dd MMMM yyyy HH:mm"
                    helperText={errors.endDate && errors.endDate.message}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Box>

        <FormControl variant="outlined" fullWidth error={!!errors.days}>
          <InputLabel id="days">Jours</InputLabel>
          <Select
            multiple
            id="days"
            labelId="days"
            variant="outlined"
            defaultValue={data?.days || []}
            {...register('days')}
            input={<OutlinedInput label="days" />}
          >
            {days.map((day) => (
              <MenuItem key={day.value} value={day.value}>
                {day.text}
              </MenuItem>
            ))}
          </Select>
          {errors.days && (
            <FormHelperText>{errors.days.message}</FormHelperText>
          )}
        </FormControl>

        <Box display="flex" direction="row" my={2}>
          <FormControl fullWidth>
            <TextField
              required
              type="time"
              variant="outlined"
              label="Heure de début"
              id="outlined-disabled"
              error={!!errors['hour.start']}
              helperText={errors['hour.start'] && errors['hour.start'].message}
              {...register('hour.start')}
            />
            <ErrorMessage
              errors={errors}
              name={`hour.start`}
              render={({ message }) => (
                <FormHelperText>{message}</FormHelperText>
              )}
            />
          </FormControl>
          <FormControl fullWidth error={!!errors['hour.end']}>
            <TextField
              required
              type="time"
              variant="outlined"
              label="Heure de fin"
              id="outlined-disabled"
              error={!!errors['hour.end']}
              {...register('hour.end')}
            />
            <ErrorMessage
              errors={errors}
              name={`hour.end`}
              render={({ message }) => (
                <FormHelperText>{message}</FormHelperText>
              )}
            />
          </FormControl>
        </Box>

        <Box display="flex" direction="row" my={2}>
          <FormControl
            variant="outlined"
            fullWidth
            error={!!errors.discountType}
          >
            <InputLabel id="discountType" shrink>
              Type de remise
            </InputLabel>
            <Select
              required
              id="discountType"
              variant="outlined"
              labelId="discountType"
              error={!!errors.discountType}
              {...register('discountType')}
              label="Type de promotion"
              defaultValue="value"
            >
              <MenuItem value="value">Euro</MenuItem>
              <MenuItem value="percentage">Pourcentage</MenuItem>
            </Select>

            {errors.discountType && (
              <FormHelperText>{errors.discountType.message}</FormHelperText>
            )}
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <TextField
              required
              type="number"
              inputProps={{
                min: 0,
                max: watchDiscountType === 'value' ? '1000' : '100'
              }}
              id="value"
              variant="outlined"
              labelId="value"
              error={!!errors['discountValue']}
              {...register('discountValue')}
              label="Valeur"
              helperText={errors.discountValue && errors.discountValue.message}
            />
          </FormControl>
        </Box>

        <Box my={2}>
          <FormControl fullWidth>
            <TextField
              required
              type="number"
              inputProps={{
                min: 0
              }}
              variant="outlined"
              id="outlined-disabled"
              label="Montant minimum"
              {...register('minAmount')}
            />

            <ErrorMessage
              errors={errors}
              name={`minAmount`}
              render={({ message }) => <small>{message}</small>}
            />
          </FormControl>
        </Box>

        <Button
          fullWidth
          size="large"
          type="submit"
          disableElevation
          variant="contained"
          startIcon={<Save />}
          disabled={isSubmitting}
        >
          {data ? "Modifier l'offre" : "Enrégistrer l'offre"}
        </Button>
      </form>

      {notifier.open && (
        <Snackbar
          open={notifier.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity={notifier.type} onClose={handleClose}>
            {notifier.content.map((data) => data?.message)}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default SpendGetForm
