import { planningConfigAPI } from 'core.services/api'
import {
  createRequestActions,
  parseErrorMessage
} from 'core.utils/requestActions'
import { selectors as citiesSelectors } from '../cities/reducer'
import { requestStates } from 'core.utils/requestStates'
import { findIndex } from 'lodash'

export const {
  GET_PLANNING_CONFIG_BY_CITY_REQUESTED,
  GET_PLANNING_CONFIG_BY_CITY_SUCCEEDED,
  GET_PLANNING_CONFIG_BY_CITY_FAILED,
  getPlanningConfigByCitySucceeded,
  getPlanningConfigByCityFailed,
  getPlanningConfigByCityRequestStateReducer
} = createRequestActions('GET_PLANNING_CONFIG_BY_CITY')

export const getPlanningConfigByCityRequested = (cityId) => (dispatch) => {
  dispatch({
    type: GET_PLANNING_CONFIG_BY_CITY_REQUESTED,
    payload: {
      cityId
    }
  })
  planningConfigAPI
    .getPlanningConfigByCity(cityId)
    .then((data) => {
      dispatch(getPlanningConfigByCitySucceeded(data))
    })
    .catch((error) => {
      dispatch(getPlanningConfigByCityFailed(error))
    })
}

export const {
  UPDATE_PLANNING_CONFIG_BY_CITY_REQUESTED,
  UPDATE_PLANNING_CONFIG_BY_CITY_SUCCEEDED,
  UPDATE_PLANNING_CONFIG_BY_CITY_FAILED,
  updatePlanningConfigByCitySucceeded,
  updatePlanningConfigByCityFailed,
  updatePlanningConfigByCityRequestStateReducer
} = createRequestActions('UPDATE_PLANNING_CONFIG_BY_CITY')

export const updatePlanningConfigByCityRequested = (planningConfigId, planningConfig) => (dispatch) => {
  dispatch({
    type: UPDATE_PLANNING_CONFIG_BY_CITY_REQUESTED,
    payload: {
      planningConfigId,
      planningConfig,
    }
  })
  planningConfigAPI
    .updatePlanningConfigByCity(planningConfigId, planningConfig)
    .then((data) => {
      dispatch(updatePlanningConfigByCitySucceeded(data))
    })
    .catch((error) => {
      dispatch(updatePlanningConfigByCityFailed(error))
    })
}

export const {
  GET_WARNINGS_BY_CITY_REQUESTED,
  GET_WARNINGS_BY_CITY_SUCCEEDED,
  GET_WARNINGS_BY_CITY_FAILED,
  getWarningsByCitySucceeded,
  getWarningsByCityFailed,
  getWarningsByCityRequestStateReducer
} = createRequestActions('GET_WARNINGS_BY_CITY')

export const getWarningsByCityRequested = (cityId) => (dispatch) => {
  dispatch({
    type: GET_WARNINGS_BY_CITY_REQUESTED,
    payload: {
      cityId
    }
  })
  planningConfigAPI
    .getWarningsByCity(cityId)
    .then((data) => {
      dispatch(getWarningsByCitySucceeded(data))
    })
    .catch((error) => {
      dispatch(getWarningsByCityFailed(error))
    })
}

export const defaultState = {
  currentCityPlanningConfig: {},
  warnings: [],
  requestState: ''
}
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    /* case EDIT_RIDER_REQUESTED:
      return { ...state, requestState: requestStates.PENDING } */
    /* case EDIT_RIDER_SUCCEEDED:
      const { currentCityPlannings } = state
      const { riderId, riderInfos, currentCity } = action.payload.data
      const index = findIndex(
        currentCityPlannings,
        (r) => r.id === riderId || r.id === riderId
      )
      if(riderInfos.city !== currentCity){
        return {
          ...state,
          currentCityPlannings: [
            ...currentCityPlannings.slice(0, index),
            ...currentCityPlannings.slice(index + 1)
          ],
          requestState: requestStates.SUCCESS,
          errorMessage: ''
        }
      }else{
        return {
          ...state,
          currentCityPlannings: [
            ...currentCityPlannings.slice(0, index),
            { ...currentCityPlannings[index], ...riderInfos },
            ...currentCityPlannings.slice(index + 1)
          ],
          requestState: requestStates.SUCCESS,
          errorMessage: ''
        }
      } */
      

    /* case EDIT_RIDER_FAILED: {
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    } */

    /* case DELETE_RIDER_REQUESTED:
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      } */

    /* case DELETE_RIDER_SUCCEEDED: {
      const { currentCityPlannings } = state
      const { riderId } = action.payload.data
      const index = findIndex(currentCityPlannings, (r) => r.id === riderId)
      return {
        ...state,
        currentCityPlannings: [
          ...currentCityPlannings.slice(0, index),
          ...currentCityPlannings.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    } */

    /* case DELETE_RIDER_FAILED:
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      } */

    case GET_PLANNING_CONFIG_BY_CITY_REQUESTED:
    case GET_WARNINGS_BY_CITY_REQUESTED:
      return {
        ...state,
        requestState: getPlanningConfigByCityRequestStateReducer(state, action),
        errorMessage: ''
      }
    case GET_WARNINGS_BY_CITY_SUCCEEDED: {
      const { warnings } = state
      const { data } = action.payload
      const index = warnings.findIndex((warnings) => warnings.city === data.city)
      if (index === -1) {
        return {
          ...state,
          warnings: [ ...warnings, { ...data } ],
          requestState: getPlanningConfigByCityRequestStateReducer(state, action),
        } 
      } else {
        return {
          ...state,
          warnings: [
            ...warnings.slice(0, index),
            { ...data },
            ...warnings.slice(index + 1)
          ],
          requestState: getPlanningConfigByCityRequestStateReducer(state, action),
          errorMessage: ''
        }
      }
    }
    case GET_PLANNING_CONFIG_BY_CITY_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        currentCityPlanningConfig: data,
        requestState: getPlanningConfigByCityRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case UPDATE_PLANNING_CONFIG_BY_CITY_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        currentCityPlanningConfig: data,
        requestState: getPlanningConfigByCityRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case GET_WARNINGS_BY_CITY_FAILED:
    case UPDATE_PLANNING_CONFIG_BY_CITY_FAILED:
    case GET_PLANNING_CONFIG_BY_CITY_FAILED: {
      return {
        ...state,
        requestState: getPlanningConfigByCityRequestStateReducer(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }

    /* case CREATE_CITY_RIDER_REQUESTED:
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      }
    case CREATE_CITY_RIDER_SUCCEEDED: {
      const { data: newRider } = action.payload
      return {
        ...state,
        currentCityPlannings: [...state.currentCityPlannings, newRider],
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case CREATE_CITY_RIDER_FAILED: {
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: parseErrorMessage(action)
      }
    } */
    /* case GENERATE_PLANNINGS_RECAP_REQUESTED:
    case GENERATE_RIDER_RECAP_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case GENERATE_PLANNINGS_RECAP_SUCCEEDED:
    case GENERATE_RIDER_RECAP_SUCCEEDED:
      return {
        ...state,
        requestState: requestStates.SUCCESS
      }
    case GENERATE_PLANNINGS_RECAP_FAILED:
    case GENERATE_RIDER_RECAP_FAILED:
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      } */
    default:
      return state
  }
}

const getState = (state) => state.plannings
const getPlanningConfigState = (state) => state.planningConfigs
const getPlanningConfigByCity = (state) => getPlanningConfigState(state).currentCityPlanningConfig
const getWarningsByCity = (state, cityId) => {
  const city = citiesSelectors.getAllCities(state).find((city) => city._id === cityId)
  return getPlanningConfigState(state).warnings.filter((warnings) => warnings.city === city?.name)
}
const getRequestState = (state) => getState(state).requestState
const getErrorMessage = (state) => getState(state).errorMessage

export const selectors = {
  getState,
  getPlanningConfigState,
  getPlanningConfigByCity,
  getWarningsByCity,
  getRequestState,
  getErrorMessage
}
