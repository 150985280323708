import React from 'react';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';

export default function RegulationActions({ handleSubmit, onCancel, showLoading, newRegulation }) {
	return (
		<DialogActions>
			<div
				style={{ width:"100%", display: 'flex', justifyContent: 'center', paddingBottom:15 }}				  
			>
				<Button onClick={onCancel} variant="contained" color="default">
					Annuler
				</Button>
				<div
				    style={{ marginLeft: 15 }}
				>
					<Button onClick={handleSubmit} variant="contained" color="primary" disabled={showLoading}>
						{newRegulation?._id ? 'Modifier' : 'Ajouter'}
					</Button>
					{showLoading && (
						<CircularProgress
							size={30}
						/>
					)}
				</div>
			</div>
		</DialogActions>
	);
}
