import React from 'react'
import { connect } from 'react-redux'

import { requestStates } from 'core.utils/requestStates'
import {
  selectors as usersSelectors
} from 'core.modules/users/reducer'

import Snackbar from 'core.ui/Snackbar'
import UserForm from '../UserForm'
import { createUserRequested } from '../../core.modules/users/reducer'

class UserCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmit = userInfo => {
    const { createUser } = this.props
    this.setState({ waitingForRequest: true })
    createUser(userInfo)
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <React.Fragment>
        <UserForm
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          isPasswordRequired={true}
          showLoading={this.state.waitingForRequest}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={this.props.errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: usersSelectors.getRequestState(state),
  errorMessage: usersSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  createUser: userInfos => {
    dispatch(createUserRequested(userInfos))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreate)
