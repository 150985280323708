import React from 'react'
import PropTypes from 'prop-types'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { orderStatus } from 'core.utils/parser/orderStatus'

const FilterOrderStatus = (props) => {
  const { classes, filterValue, onChange } = props

  const openPriorityOrders = () => window.open('/newprio', '_blank')

  return (
    <div className={classes.orderStatusFiltersContainer}>
      <ToggleButtonGroup
        value={filterValue}
        exclusive
        onChange={(e, value) => value !== null && onChange(value)}
        aria-label="text alignment"
      >
        <ToggleButton value="" aria-label="left aligned">
          <span>Toute</span>
        </ToggleButton>
        <ToggleButton value={orderStatus.COMPLETED} aria-label="centered">
          <span>Livré</span>
        </ToggleButton>
        <ToggleButton value={orderStatus.CANCELED} aria-label="right aligned">
          <span>Annulée</span>
        </ToggleButton>
        <ToggleButton value={orderStatus.FAILED} aria-label="justified">
          <span>Échouée</span>
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButton
        style={{ marginLeft: 5 }}
        value="priorityOrders"
        aria-label="justified"
        onClick={openPriorityOrders}
      >
        <span>Prioritaire</span>
      </ToggleButton>
    </div>
  )
}

FilterOrderStatus.propTypes = {
  classes: PropTypes.object,
  filterValue: PropTypes.string,
  onChange: PropTypes.func
}

export default FilterOrderStatus
