import { LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Snackbar from 'core.ui/Snackbar'
import Dialog from 'core.ui/Dialog'

import FiltersComponent from './components/Filters'
import RushComponent from './components/Rush'
import ListComponent from './components/List'
import PrioritiesComponent from './components/Priorities'
import ModalNoteComponent from './components/ModalNote'

import {
  selectCity,
  setOrderStatus,
  setOrderRider,
  setOrderNote,
  deleteOrder,
  forceRefreshAll,
  getAllRiders,
  setFilters
} from './ui-slice'

import {
  order as OrderApi,
  restaurant as RestaurantApi
} from 'core.services/api'

import { connect } from 'react-redux'
import { compose } from 'redux'

const OrderListComponent = function (props) {
  const { city, classes, filters } = props
  const dispatch = useDispatch()

  const {
    isLoading,
    selectedCity,
    rushMode,
    priorityOrders,
    snackbarMsg,
  } = useSelector((state) => state.uiOrderList)

  const [snackObj, setSnackObj] = useState({ visible: false })
  const [confirmDelete, setConfirmDelete] = useState({
    visible: false,
    orderId: null
  })
  const [modalNote, setModalNote] = useState({
    visible: false,
    orderId: null,
    note: null
  })

  const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

  // On mount

  let intervalRush

  useEffect(() => {
    clearTimeout(intervalRush)

    if (rushMode.active) {
      intervalRush = setInterval(() => {
        if (!isLoading) dispatch(forceRefreshAll())
      }, 1000 * 15) // refresh all data every 15s
    }

    return () => {
      clearTimeout(intervalRush)
    }
  }, [null, rushMode.active])

  // on snackbar change
  useEffect(() => {
    if (snackbarMsg.message) setSnackObj({ ...snackbarMsg, visible: true })
  }, [snackbarMsg])

  // on selected city change
  useEffect(() => {
    onSelectCity()
  }, [city])

  const onSelectCity = async () => {
    if (!city || city === selectedCity) return

    try {
      await dispatch(selectCity(city))
    } catch (e) {
      console.error(e)
      setSnackObj({
        visible: true,
        severity: 'error',
        message: 'Une erreur est survenue lors du chargement'
      })
    }
  }

  const forceRefreshOrder = async (orderId) => {
    try {
      await RestaurantApi.forceRestaurantOrdersRefresh(orderId)
      setSnackObj({
        visible: true,
        message: 'Refresh effectué',
        severity: 'success'
      })
    } catch (e) {
      console.log(e)
      setSnackObj({
        visible: true,
        message: 'Refresh échoué',
        severity: 'error'
      })
    }
  }

  const sendNewOrderNotifToRestaurant = async (orderId) => {
    try {
      await OrderApi.sendNewOrderNotifToRestaurant(orderId)
      setSnackObj({
        visible: true,
        message: 'Notification envoyée',
        severity: 'success'
      })
    } catch (e) {
      console.log(e)
      setSnackObj({
        visible: true,
        message: 'Notification échouée',
        severity: 'error'
      })
    }
  }

  return (
    <React.Fragment>
      <div className={classes.progressBar}>
        {isLoading && <LinearProgress className={classes.linearProgress} />}
      </div>

      <div className={classes.topBarControls}>
        <div className={classes.column}>
          {(!permissions.order || permissions.order?.displayAll === true) && <FiltersComponent classes={classes} />}
          <RushComponent classes={classes} />
        </div>
      </div>

      {rushMode.active && rushMode.selectedStatus === 'priorityOrders' ? (
        <PrioritiesComponent classes={classes} orders={priorityOrders} />
      ) : (
        <ListComponent
          classes={classes}
          forceRestaurantOrdersRefresh={forceRefreshOrder}
          sendNewOrderNotifToRestaurant={sendNewOrderNotifToRestaurant}
          handleStatusChange={(orderId, status) =>
            dispatch(setOrderStatus(orderId, status))
          }
          handleRiderChange={(orderId, riderId) =>
            dispatch(setOrderRider(orderId, riderId))
          }
          openNoteDialog={(orderId, note) => () => {
            orderId &&
              setModalNote({ visible: true, orderId, note: note || '' })
          }}
          handleDelete={(orderId) =>
            orderId && setConfirmDelete({ visible: true, orderId })
          }
          isRushActive={rushMode.active}
        />
      )}

      <Snackbar
        open={snackObj.visible}
        severity={snackObj.severity}
        message={snackObj.message}
        onClose={() => setSnackObj({ visible: false })}
      />

      <ModalNoteComponent
        open={modalNote.visible}
        note={modalNote.note}
        onCancel={() => setModalNote({ visible: false, orderId: null })}
        onSubmit={(note) => {
          if (modalNote.orderId && note) {
            dispatch(setOrderNote(modalNote.orderId, note))
            setModalNote({ visible: false })
          }
        }}
      />

      <Dialog
        open={confirmDelete.visible}
        dialogText="Confirmer la suppression"
        dialogTitle="Confirmation"
        cancelButtonCaption="Annuler"
        confirmButtonCaption="Ok"
        onCancel={() => setConfirmDelete({ visible: false, orderId: null })}
        onValidation={() => {
          if (confirmDelete.orderId) {
            dispatch(deleteOrder(confirmDelete.orderId))
            setConfirmDelete({ visible: false, orderId: null })
          }
        }}
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
    filters: state.uiOrderList.filters
})

export default compose(
  connect(
    mapStateToProps
  ),
)(OrderListComponent)
