import { AppBar, Button, Tab, Tabs, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import TabPanel from 'core.ui/TabPanel'
import { connect } from 'react-redux'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useEffect } from 'react'
import { getAds, delete_ad, getAdByCity } from '../../core.modules/Ads/Actions'
import List from 'core.ui/List'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Adaction = {
  WEB: 'web',
  REFERRAL_CODE: 'referral_code',
  PROMO_CODE: 'promo_code',
  UPDATE: 'update',
  PICKUP: 'pickup',
  RESTAURANT: 'restaurant',
  NONE: 'null'
}

const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

const Ads = ({
  ads: { Ads, CityAds },
  allCities,
  city,
  getAds,
  delete_ad,
  getAdByCity,
  history
}) => {
  useEffect(() => {
    permissions?.ad?.listGeneral && getAds()
  }, [getAds])
  useEffect(() => {
   
    permissions?.ad?.listByCity && getAdByCity(city)
  }, [getAdByCity,city])

  const [selectedCity, setSelectedCity] = useState(null)
  /*const handleCityChange = (e, value) => {
   // setSelectedCity(value)
    getAdByCity(city)
  }
 
  const renderCityAutoComplete = () => {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={allCities}
        getOptionLabel={(city) => city.name}
        style={{ width: 300 }}
        onChange={handleCityChange}
        renderInput={(params) => (
          <TextField {...params} label="Villes" variant="outlined" />
        )}
      />
    )
  }*/
  const [tabValue, setTabValue] = useState('Ville')
  const handleChangeTab = (event, tabValue) => {
    setTabValue(tabValue)
  }
  const getTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }
  const handleDelete = (id) => {
    delete_ad(id)
  }
  const handleEdit = (id) => {
    history.push(`/marketing/ads/${id}`)
  }
  const lang = (ad) => {
    const fr =
      ad.action === Adaction.NONE
        ? 'Aucune action'
        : ad.action === Adaction.PICKUP
        ? 'Emporter'
        : ad.action === Adaction.PROMO_CODE
        ? 'Code Promo'
        : ad.action == Adaction.REFERRAL_CODE
        ? 'Parrainage'
        : ad.action === Adaction.RESTAURANT
        ? 'Restaurant'
        : ad.action === Adaction.UPDATE
        ? 'Mise à jour'
        : 'Web'

    return fr
  }
  const parseSettings = (ad) => {
    return (
      <div>
        <li>
          <b>Début:</b>{' '}
          {moment(ad.startDate).locale('fr').format('Do MMMM YYYY HH:mm')}
        </li>
        <li>
          <b>Fin:</b> {moment(ad.endDate).locale('fr').format('Do MMMM YYYY HH:mm')}
        </li>
        <li>
          <b>Action:</b> {lang(ad)}
        </li>
        {ad?.cities.length > 0 ? (
          <li>
            <b>Cible:</b>{' '}
            {ad?.cities?.map((city) => {
              return <ul key={city._id}> {city.name}</ul>
            })}
          </li>
        ) : (
          <div></div>
        )}
        {ad?.url ? (
          <li>
            <b>Url:</b> <a href={ad.url} target='_blank'>{ad.url}</a>
          </li>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
  const status = (ad) => {
    const Actif={
      height: '25px',
      width: '25px',
      backgroundColor: '#00FF00',
      borderRadius: '50%',
      display: 'inline-block'
    }
    const Disabled={
      height: '25px',
      width: '25px',
      backgroundColor: '#FF0000',
      borderRadius: '50%',
      display: 'inline-block'
    }
    const status = ad.isEnabled ? (
      <span
        style={Actif}
      ></span>
    ) : (
      <span
        style={Disabled}
      ></span>
    )
    return status
  }
  const renderList = (ad) => {
    return [
      <img
        src={`${process.env.REACT_APP_IMAGES_URL}${ad.imagePath}`}
        width="200px"
        alt="Lyveat Ads"
      />,
      parseSettings(ad),
      status(ad),
      ad.order,
      ''
    ]
  }
  const renderGeneralAds = () => {
    return (
      <div>
        <List
          ids={Ads.map((ad) => ad._id || ad.id)}
          columns={['Image', 'Settings', 'Statut', 'Ordre', '']}
          actions={{ handleDelete: handleDelete, handleEdit: handleEdit }}
          emptyMessage="aucun Ad"
          list={Ads.map((ad) => {
            return renderList(ad)
          })}
        />
      </div>
    )
  }

  const renderVilleAds = () => {
    return (
      <div>
        {' '}
        <List
          ids={CityAds.map((ad) => ad._id || ad.id)}
          columns={['Image', 'Settings', 'Statut','Ordre', '']}
          actions={{ handleDelete: handleDelete, handleEdit: handleEdit }}
          emptyMessage="aucun Ad trouvé sur cette ville"
          list={CityAds.map((ad) => {
            return renderList(ad)
          })}
        />
      </div>
    )
  }
  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="ads tabs">
          { permissions?.ad?.listByCity && <Tab value="Ville" label="Ville" {...getTabProps('Ville')} /> }
          { permissions?.ad?.listGeneral && <Tab value="Generale" label="Generale" {...getTabProps('Generale')} /> }
          <div style={{ paddingTop: '7px', paddingLeft: '60%' }}>
            <Link to={`/marketing/ads/add/${tabValue}`}  >
              <Button variant="contained" color="secondary">
                Ajouter une annonce
              </Button>
            </Link>
          </div>
        </Tabs>
      </AppBar>
      { permissions?.ad?.listByCity && <TabPanel value={tabValue} index={'Ville'}>
          {' '}
          {renderVilleAds()}{' '}
        </TabPanel>
      }
      { permissions?.ad?.listGeneral && <TabPanel value={tabValue} index={'Generale'}>
          {' '}
          {renderGeneralAds()}{' '}
        </TabPanel> 
      }
    </div>
  )
}
Ads.propTypes = {
  ads: PropTypes.object,
  getAds: PropTypes.func,
  delete_ad: PropTypes.func,
  getAdByCity: PropTypes.func
}
const mapStateToProps = (state) => ({
  allCities: citiesSelectors.getAllCities(state),
  ads: state.ads
})
export default connect(mapStateToProps, { getAds, delete_ad, getAdByCity })(Ads)
