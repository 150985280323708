import {
  ADD_AD,
  DELETE_AD,
  GET_ALL_ADS,
  UPDATE_AD,
  AD_ERROR,
  GET_AD_BY_CITY,
  SINGLE_AD
} from './types'
const initialState = {
  Ads: [],
  CityAds: [],
  ad: null,
  loading:true,
  err:null

}
export default function (state = initialState, action) {
  const { payload, type } = action
  switch (type) {
    case ADD_AD:
      return {
        ...state,
        Ads: [payload, ...state.Ads],
        adId:payload.id,
        err:null
      }
    case SINGLE_AD:
      return {
        ...state,
        ad: payload,
        loading:false,
       
      }
    case GET_ALL_ADS:
      return {
        ...state,
        Ads: payload,
        loading:true
      }
    case UPDATE_AD:
      return {
        ...state,
        CityAds: [],
        loading:true
      }
    case DELETE_AD:
      return {
        ...state,
        Ads: state.Ads.filter((ad) => ad.id !== payload),
        CityAds: state.CityAds.filter((ad) => ad._id !== payload)
      }
    case GET_AD_BY_CITY:
      return {
        ...state,
        CityAds: payload,
        loading:true
      }
    case AD_ERROR:
      return {
        ...state,
        err:payload
       
      }
    default:
      return state
  }
}
