import axios from 'axios'

let axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  async request => {
    const token = await localStorage.getItem('token')
    if (token) {
      request.headers['Authorization'] = 'Bearer ' + token
    }
    return request
  },
  error => {
    Promise.reject(error)
  },
)

export default axiosInstance
