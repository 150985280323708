import { orange, lightGreen, red, yellow, blue, grey, purple } from "@material-ui/core/colors"

export const riderTransport = {
  CAR: 'car',
  SCOOTER: 'scooter',
  BIKE: 'bike'
}

export const riderRequestStatus = {
  WAITING: 'waiting',
  MESSAGE: 'message',
  APPOINTMENT: 'appointment',
  DOC_SENT: 'doc_sent',
  VALIDATED: 'validated',
  WAITING_LIST: 'waiting list',
  NOT_INTERESTED:'not_interested',
}

export const riderRequestStatusTranslated = {
  [riderRequestStatus.WAITING]: 'Nouvelle candidature',
  [riderRequestStatus.MESSAGE]: 'Message vocal',
  [riderRequestStatus.APPOINTMENT]: 'RDV',
  [riderRequestStatus.DOC_SENT]: 'DocuSign envoyé',
  [riderRequestStatus.VALIDATED]: 'DocuSign signé',
  [riderRequestStatus.WAITING_LIST]: "DocuSign signé - Liste d'attente",
  [riderRequestStatus.NOT_INTERESTED]: 'Plus intéressé',
  
}

export function parseRiderRequestRole(transport) {
  const rolesTranslated = {
    CAR: 'Voiture',
    SCOOTER: 'Scooter',
    BIKE: 'Vélo'
  }
  switch (transport) {
    case riderTransport.CAR:
      return rolesTranslated.CAR
    case riderTransport.SCOOTER:
      return rolesTranslated.SCOOTER
    case riderTransport.BIKE:
      return rolesTranslated.BIKE
    default:
     return transport
  }
}

export function getTranslatedStatus(status) {
  const translatedStatus = riderRequestStatusTranslated[status] 
    ? riderRequestStatusTranslated[status]
    : status 
  return translatedStatus
}

export const statusColorMapping = {
  [riderRequestStatus.WAITING]: red[700],
  [riderRequestStatus.APPOINTMENT]: orange[700],
  [riderRequestStatus.DOC_SENT]:  blue[700],
  [riderRequestStatus.MESSAGE]: yellow[700],
  [riderRequestStatus.VALIDATED]: lightGreen[400],
  [riderRequestStatus.WAITING_LIST]: purple[700],
  [riderRequestStatus.NOT_INTERESTED]: grey[800],
}

export const statusFontColorMapping = {
  [riderRequestStatus.WAITING]: red[700],
  [riderRequestStatus.APPOINTMENT]: orange[700],
  [riderRequestStatus.DOC_SENT]:  blue[700],
  [riderRequestStatus.MESSAGE]: yellow[700],
  [riderRequestStatus.VALIDATED]: lightGreen[400],
  [riderRequestStatus.WAITING_LIST]: purple[700],
  [riderRequestStatus.NOT_INTERESTED]: grey[800],
}
