import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Used for logged user
export function PrivateRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				localStorage.getItem('token') !== null ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				)}
		/>
	);
}

// Used for SUPER_ADMIN role
export function FinancesPrivateRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				1 || localStorage.getItem('role') === 'SUPER_ADMIN' ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/commandes',
							state: { from: props.location }
						}}
					/>
				)}
		/>
	);
}
