import AddDeal from './AddDeal'
import { deal } from '../../core.services/api'
import { Box, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import DealDataGrid from './components/DealDataGrid'
import SelectRestaurant from 'components/SelectRestaurant'
import { useHistory, Switch, Route } from 'react-router-dom'
import {
  selectors as currentRestaurantInfosSelectors
} from 'core.modules/currentRestaurant/infos/reducer'

import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'

const Deals = ({ fetchAllRestaurants, city, currentRestaurantId }) => {
  const history = useHistory()
  const [restaurants, setRestaurants] = useState([])

  useEffect(() => {
    try {
      const cityId = localStorage.getItem('selectedCity')

      Promise.resolve(fetchAllRestaurants(cityId)).then((value) => {
        
        if (currentRestaurantId) {
          Promise.resolve(deal.getRestaurantDeals(currentRestaurantId)).then(
            (value) => {
              setRestaurants(value)
            }
          )
        }
      })
    } catch (error) {
      console.log(error)
    }
  }, [currentRestaurantId, fetchAllRestaurants])

  return (
    <Switch>
      <Route path="/marketing/deals" exact>
        <Box paddingX="3rem">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            my="1rem"
          >
            <SelectRestaurant key="select-restaurants" city={city} />
            {currentRestaurantId && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                onClick={() =>
                  history.push({
                    pathname: '/marketing/deals/new',
                    state: {
                      restaurantID: currentRestaurantId
                    }
                  })
                }
              >
                Ajouter une offre
              </Button>
            )}
          </Box>
          <section>
            <DealDataGrid data={restaurants} />
          </section>
        </Box>
      </Route>
      <Route path="/marketing/deals/new" component={AddDeal} />
      <Route path="/marketing/deals/:ID/edit" render={(props) => <AddDeal {...props} restaurants={restaurants} setRestaurants={setRestaurants} />} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchAllRestaurants: (cityId) => {
    dispatch(fetchAllRestaurantsRequested(cityId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Deals)
