import React from 'react'
import PropTypes from 'prop-types'
import { red } from '@material-ui/core/colors'
import { orderStatus } from 'core.utils/parser/orderStatus'

const RushCounters = (props) => {
  const {
    classes,
    statusCounts,
    intervalCounts,
    rushMode,
    priorityOrdersCount,
    renderOrdersByStatus,
    renderOrdersByInterval
  } = props

  const statusCounters = [
    {
      title: 'Toutes',
      type: 'current',
      color: 'white'
    },
    {
      title: 'Prioritaires',
      type: 'priorityOrders',
      color: red[900]
    },
    {
      title: 'Attente paiement',
      type: orderStatus.WAITING_FOR_PAYMENT,
      color: 'orange'
    },
    {
      title: 'En attente',
      type: orderStatus.WAITING_RESTAURANT,
      color: 'yellow'
    },
    {
      title: 'En attente (Plus tard)',
      type: orderStatus.LATER,
      color: 'yellow'
    },
    {
      title: 'En cours de préparation',
      type: orderStatus.COOKING,
      color: 'skyBlue'
    },
    {
      title: 'Recherche livreur',
      type: orderStatus.WAITING_FOR_RIDER,
      color: 'red'
    },
    {
      title: 'Pré assigné',
      type: 'pre assigned',
      color: 'violet'
    },
    {
      title: 'En route restaurant',
      type: orderStatus.DRIVER_ASSIGNED,
      color: 'grey'
    },
    {
      title: 'En route client',
      type: orderStatus.COLLECTED,
      color: 'darkGreen'
    }
  ]
  const intervalCounters = [
    {
      title: '40-50 min',
      type: '40-50',
      color: 'green || #ffba08'
    },
    {
      title: '50-60 min',
      type: '50-60',
      color: 'green || #f48c06'
    },  
    {
      title: '1H-1H30',
      type: '60-90',
      color: 'green || #e85d04'
    },
    {
      title: '1H30-2H',
      type: '90-120',
      color: 'green || #dc2f02'
    },
    {
      title: '>2H',
      type: '>120',
      color: 'green || #d00000'
    },              
  ]
  const invertedTypes = [
    orderStatus.WAITING_FOR_PAYMENT,
    orderStatus.WAITING_RESTAURANT,
    orderStatus.WAITING_FOR_RIDER
  ]
  const criticalTypes = [orderStatus.WAITING_FOR_PAYMENT]
  const statusCountersValue = statusCounts.map(({ status, count }) => ({
    type: status,
    value: count
  }))
  const intervalCountersValue = intervalCounts.map(({ interval, count }) => ({
    type: interval,
    value: count
  }))
  const priorityOrdersCounter = {
    type: 'priorityOrders',
    value: priorityOrdersCount
  }

  const counters = [...statusCounters, ...intervalCounters]
  const values = [
    priorityOrdersCounter,
    ...statusCountersValue,
    ...intervalCountersValue
  ]

  const getCounterValue = (type) => {
    const counterValue = values.find((el) => el.type === type)
    return counterValue?.value
  }

  if (!rushMode) {
    return null
  }

  return (
    <div className={classes.countersContainer}>
      {counters.map((counter, index) => {
        const countValue = getCounterValue(counter.type)
        const isInvertedType = invertedTypes.includes(counter.type)
        const isCriticalType = criticalTypes.includes(counter.type)
        const isStatus = [
          'current',
          'pre assigned',
          'priorityOrders',
          ...Object.values(orderStatus)
        ].includes(counter.type)
        let [noOrdersColors, ordersColor] = counter.color.split('||')
        ordersColor = !ordersColor ? noOrdersColors : ordersColor

        return (
          <div className={classes.counterContainer} index={index} key={index}>
            <div
              className={
                isInvertedType
                  ? classes.counterTopContainerBlack
                  : classes.counterTopContainer
              }
            >
              {isCriticalType && countValue > 0 ? (
                <span className={classes.counterValueRed}>{countValue}</span>
              ) : (
                <span
                  className={
                    isInvertedType
                      ? classes.counterValueWhite
                      : classes.counterValue
                  }
                >
                  {countValue}
                </span>
              )}
            </div>
            <div
              className={classes.counterBottomContainer}
              style={{
                backgroundColor: countValue ? ordersColor : noOrdersColors
              }}
            >
              <span
                className={classes.counterTitle}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  isStatus ? renderOrdersByStatus(counter.type) : renderOrdersByInterval(counter.type)
                }
              >
                {counter.title}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

RushCounters.propTypes = {
  classes: PropTypes.object,
  statusCounts: PropTypes.array,
  intervalCounts: PropTypes.array,
  rushMode: PropTypes.bool,
  priorityOrdersCount: PropTypes.number,
  renderOrdersByStatus: PropTypes.func
}

export default RushCounters
