import {
  ADD_AD,
  DELETE_AD,
  GET_ALL_ADS,
  UPDATE_AD,
  AD_ERROR,
  GET_AD_BY_CITY,
  SINGLE_AD,
  UPLOAD_IMAGE
} from './types'
import { ads } from '../../core.services/api'

export const add_Ad = (formData,history) => async (dispatch) => {
  
    const res = await ads.addAd(formData)
    await ads.uploadAdImage(res.data.id || res.data._id,formData.imagePath)
   if(res.status !== 201){
    return
   } else {
    dispatch({ type: ADD_AD, payload: res.data })
    history.push('/marketing/ads')
   }
   
  
}
export const getAds = () => async (dispatch) => {
  try {
    const res = await ads.getAds()
    dispatch({ type: GET_ALL_ADS, payload: res })
  } catch (error) {
    dispatch({ type: AD_ERROR })
  }
}
export const update_Ad = (id, formdata,history,checkimage) => async (dispatch) => {
 
   
      const res = await ads.updateAds(id, formdata)
      if(checkimage==="IMAGE"){
        await ads.uploadAdImage(id,formdata.imagePath)
      }
      
      if(res.status === 204){
        return
       } else { 
      dispatch({ type: UPDATE_AD, payload: res })
      history.push('/marketing/ads')
       }
    
  
}
export const delete_ad = (id) => async (dispatch) => {
  if (window.confirm("Confirmer la suppression de l'ad ?")) {
    try {
      await ads.deleteAd(id)
      dispatch({ type: DELETE_AD, payload: id })
    } catch (error) {
      dispatch({ type: AD_ERROR })
    }
  }
}
export const getAdByCity = (id) => async (dispatch) => {
  try {
    const res = await ads.getAdByCity(id)
    dispatch({
      type: GET_AD_BY_CITY,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: AD_ERROR
    })
  }
}
export const getAd = (id) => async (dispatch) => {
  try {
    const res = await ads.getSingleAd(id)
    dispatch({
      type: SINGLE_AD,
      payload: res
    })
  } catch (error) {
    dispatch({
      type: AD_ERROR
    })
  }
}
export const uploadAdImage=(adId, file)=>async dispatch=> {
  try {
     await ads.uploadAdImage(adId, file)
    dispatch({
      type: UPLOAD_IMAGE
    })
  } catch (error) {
    dispatch({
      type: AD_ERROR
    })
  }
}
