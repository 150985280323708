import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { get, isEmpty } from 'lodash'

import ButtonWithIcon from 'core.ui/ButtonWithIcon'
import Select from 'core.ui/Select'
import Créneau from 'components/Créneau'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  insideContainer: {
    width: '450px',
    margin: '10px',
    padding: '5px',
    borderRadius: '5px',
    border: 'lightgrey 1px solid',
    backgroundColor: '#FBFEFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  jour: {
    height: '40px',
    margin: '11px 5px',
    width: '120px'
  },
  creneaux: {
    display: 'flex',
    flexDirection: 'column'
  },
  ajouter: {
    backgroundColor: 'lightgreen',
    alignSelf: 'flex-start'
  }
}

export class Horaire extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jour: 'Lundi',
      statut: true,
      creneaux: []
    }
  }

  componentDidMount() {
    this.setState({ ...this.props.value })
  }

  componentDidUpdate(prevProps, prevState) {
    const { value, onChange } = this.props
    const { jour, creneaux } = this.state
    if (value !== prevProps.value) {
      this.setState({ ...this.props.value })
    } else if (
      (jour !== prevState.jour && jour !== value.jour) ||
      (creneaux !== prevState.creneaux &&
        creneaux !== get(value, 'creneaux', {}))
    ) {
      onChange(this.state)
    }
  }

  handleJourChange = e => {
    const { value } = e.target
    this.setState({ jour: value })
  }

  handleCreneauxChange = index => value => {
    const { creneaux } = this.state
    this.setState({
      creneaux: [
        ...creneaux.slice(0, index),
        value,
        ...creneaux.slice(index + 1)
      ]
    })
  }

  handleAddCreneau = () => {
    const { creneaux } = this.state
    const newCreneau = { hDebut: '00:00', hFin: '00:00' }
    this.setState({ creneaux: [...creneaux, newCreneau] })
  }

  handleDeleteCreneau = index => () => {
    const { creneaux } = this.state
    this.setState({
      creneaux: [...creneaux.slice(0, index), ...creneaux.slice(index + 1)]
    })
  }

  render() {
    const { classes, onDelete } = this.props
    const { jour, creneaux } = this.state
    return (
      <div className={classes.mainContainer}>
        <div
          className={classNames(
            'horaire-main-container',
            classes.insideContainer
          )}
        >
          <Select
            className={classes.jour}
            native
            options={[
              'Lundi',
              'Mardi',
              'Mercredi',
              'Jeudi',
              'Vendredi',
              'Samedi',
              'Dimanche'
            ]}
            captions={[
              'Lundi',
              'Mardi',
              'Mercredi',
              'Jeudi',
              'Vendredi',
              'Samedi',
              'Dimanche'
            ]}
            value={jour}
            onChange={this.handleJourChange}
          />
          {/* {this.renderCreneaux()} */}
          <div className="creneaux">
            {isEmpty(creneaux) ? <p>Aucun créneau</p> : this.renderCreneaux()}
          </div>
          <ButtonWithIcon
            className={classes.ajouter}
            featherIcon="Plus"
            iconSize="24"
            onClick={this.handleAddCreneau}
          />
        </div>
        <ButtonWithIcon featherIcon="Trash2" iconSize="22" onClick={onDelete} />
      </div>
    )
  }

  renderCreneaux = () => {
    const { creneaux } = this.state
    return creneaux.map((creneau, index) => {
      return (
        <Créneau
          key={index}
          value={creneau}
          onChange={this.handleCreneauxChange(index)}
          onDelete={this.handleDeleteCreneau(index)}
        />
      )
    })
  }
}

export default withStyles(styles)(Horaire)
