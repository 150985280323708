import React from 'react'
import { connect } from 'react-redux'

import { requestStates } from 'core.utils/requestStates'
import {
  createRestaurantRequested,
  selectors as restaurantSelectors
} from 'core.modules/restaurants/reducer'

import Snackbar from 'core.ui/Snackbar'
import RestaurantForm from 'components/RestaurantForm'

class RestaurantCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmit = restaurantInfos => {
    const { createRestaurant } = this.props
    this.setState({ waitingForRequest: true })
    createRestaurant(restaurantInfos, localStorage.getItem('selectedCity'))
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const {
      errorMessage
    } = this.props
    return (
      <React.Fragment>
        <RestaurantForm
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          isPasswordRequired={true}
          showLoading={this.state.waitingForRequest}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: restaurantSelectors.getRequestState(state),
  errorMessage: restaurantSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  createRestaurant: (restaurantInfos, selectedCity) => {
    dispatch(createRestaurantRequested(restaurantInfos, selectedCity))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantCreate)
