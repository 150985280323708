import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';

import { planningConfigAPI } from 'core.services/api';
import { frToEnDays } from '../../core.utils/utils';
import { CURRENT_WEEK } from './planningsConstant';

const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const daysToFr = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

export default function PlanningConfig({ city, selectedWeek }) {
	const [ planningConfig, setPlanningConfig ] = useState([]);
    const [ currentCityPlanningConfig, setcurrentCityPlanningConfig ] = useState([]);
    
	useEffect(
		() => {
			fetchPlanningConfig();
		},
		[ city ]
	);

	const fetchPlanningConfig = async () => {
		if (!city) {
			return;
		}
        const currentCityPlConfig = await planningConfigAPI.getPlanningConfigByCity(city);        
        setPlanningConfig(getPlanningConfig(currentCityPlConfig))
        setcurrentCityPlanningConfig(currentCityPlConfig)
	};

    const getPlanningConfig = (currentCityPlConfig) => {
        return days.map((d, index) => ({
                day: daysToFr[index],
                ...currentCityPlConfig[d][selectedWeek === CURRENT_WEEK ? "currentWeek" : "nextWeek" ]
            }))
    }

	const updatePlanningConfig = async (newData) => {
		let updatedPlanning = {
			...currentCityPlanningConfig,
			isDisplayWarning: undefined
        };
        
		updatedPlanning[frToEnDays(newData.day)][selectedWeek === CURRENT_WEEK ? "currentWeek" : "nextWeek"] = {
			morningSessionMax: newData.morningSessionMax,
			nightSessionMax: newData.nightSessionMax
		};
        
        try {
            const newConfig = await planningConfigAPI.updatePlanningConfigByCity(
                currentCityPlanningConfig.id,
                updatedPlanning
            )
            setPlanningConfig(getPlanningConfig(newConfig))
            setcurrentCityPlanningConfig(newConfig)
        } catch(e) {
            alert("Erreur lors de la mise à jour.", e)
        }

	};

	const columns = [
		{ field: 'day', title: 'Jour', editable: true },
		{ field: 'morningSessionMax', title: 'Max midi', type: 'numeric' },
		{ field: 'nightSessionMax', title: 'Max soir', type: 'numeric' }
	];
	// https://material-table.com/#/docs/all-props
	return (
		<div
			style={{
				width: '100%',
				marginTop: 5,
				marginBottom: 5,
				border: '1px solid grey'
			}}
		>
			<MaterialTable
				title="Max semaine"
				columns={columns}
				data={planningConfig}
				editable={{
					onRowUpdate: (newData, oldData) => {
						return updatePlanningConfig(newData);
					}
				}}
				localization={{
					toolbar: { searchPlaceholder: 'Rechercher' },
					pagination: {
						labelDisplayedRows: 'De {from} à {to} sur {count}',
						labelRowsSelect: 'lignes'
					}
				}}
				actions={[]}
				options={{ pageSize: 7, paging: false, search: false }}
			/>
		</div>
	);
}
