import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch
} from '@material-ui/core'

import TextField from 'core.ui/TextField'

const ModalFormComponent = function ({ item, onCancel, onSubmit,currentRestaurant,Temp }) {
  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)
  const [isAvailable, setIsAvailable] = useState(false)
  const [checkoutSystemId, setcheckoutSystemId] = useState('')
  const [reference,setreference]=useState('')
  
  useEffect(() => {
    if (item?._id) {
      setName(item.name || '')
      setPrice(item.price || 0)
      setIsAvailable(item.isAvailable || false)
      setcheckoutSystemId(item.checkoutSystemId || '')
      setreference(item.reference || '')
    }
  }, [item])

  return (
    <Dialog open={true} onClose={onCancel} fullWidth={true} maxWidth="md">
      <DialogTitle>Modifier un supplément</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nom"
          type="text"
          value={name}
          onChangeComplete={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          label="Prix"
          type="number"
          value={price}
          onChangeComplete={(e) => setPrice(e.target.value)}
          fullWidth
        />
        Disponible
        <Switch
          checked={isAvailable}
          onChange={(e) => setIsAvailable(e.target.checked)}
        />
        {(currentRestaurant?.posInfo?.type || Temp === true) ? <TextField
          autoFocus
          margin="dense"
          label="id systeme de caisse"
          type="text"
          value={checkoutSystemId}
          // helperText={(checkoutSystemId.length!=6 && checkoutSystemId.length!=0)?"le code doit contenir 6 caractères": ""}
          // error={(checkoutSystemId.length!=6 && checkoutSystemId.length!=0)}
          onChangeComplete={(e) => setcheckoutSystemId(e.target.value.trim())}
          fullWidth
        />: <div></div>}
        {(currentRestaurant?.posInfo?.type || Temp === true) ? <TextField
          autoFocus
          margin="dense"
          label="reference"
          type="text"
          value={reference}
          onChangeComplete={(e) => setreference(e.target.value.trim())}
          fullWidth
        />: <div></div>}
        
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          Annuler
        </Button>
        <Button
          color="primary"
          onClick={() => onSubmit({ ...item, name, price, isAvailable, checkoutSystemId, reference })}
        >
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalFormComponent
