import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { getAd, update_Ad } from '../../core.modules/Ads/Actions'
import UploadButtonJPEG from 'components/UploadimageJPEG'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Typography
} from '@material-ui/core'
import Select1 from '@material-ui/core/Select/Select'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import PropTypes from 'prop-types'
import moment from 'moment'
import Switch from '@material-ui/core/Switch'
import { ads } from '../../core.services/api'
import { parseImage } from 'core.utils/parser/image'
import classNames from 'classnames'
import { isEmailValid } from 'core.utils/formValidation.js'
import Snackbar from 'core.ui/Snackbar'
import Select from 'react-select'
const styles = {
  mainContainer: {
    margin: '20px auto',
    width: '562px',
    padding: '8px 20px ',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  rows: {
    marginBottom: '12px',
    paddingTop: '50px'
  },
  lines: {
    display: 'flex',
    flexDirection: 'row'
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewImage: { width: '200px' },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative',
    marginLeft: 20
  },
  buttonLoadingAnimation: {
    //color: blue[500],
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  },
  select: { margin: '6px 0' },
  selectLabel: {
    margin: '6px 0'
  }
}
const Adaction = {
  WEB: 'web',
  REFERRAL_CODE: 'referral_code',
  PROMO_CODE: 'promo_code',
  UPDATE: 'update',
  PICKUP: 'pickup',
  RESTAURANT: 'restaurant',
  NONE: 'null'
}
const EditAd = ({
  match,
  getAd,
  allCities,
  ads: { ad, loading },
  update_Ad,
  history
}) => {
  useEffect(() => {
    getAd(match.params.id)
  }, [getAd, match.params.id])
  const classes = styles
  const [open, setOpen] = React.useState(false)

  const [snackBarMessage, setSnackbarMessage] = useState('')
  const [snackBarSeverity, setsnackBarSeverityMessage] = useState('error')

  const handleCloseSnackbar = () => {
    setOpen(false)
  }
  const [imagePreview, setImagePreview] = useState(null)
  const [formData, setFormData] = useState({
    action: '',
    description: '',
    order: 0,
    url: '',
    cities: [],
    restaurantmail: null,
    startDate: '',
    endDate: '',
    isArchived: false,
    imagePath: '',
    isEnabled: false
  })
  
  const {
    action,
    description,
    order,
    url,
    cities,
    restaurantmail,
    startDate,
    endDate,
    isArchived,
    isEnabled,
    imagePath
  } = formData
  useEffect(() => {
    ads
      .getImage(match.params.id)
      .then((image) => {
        return parseImage(image)
      })
      .then((result) => {
        setImagePreview(result)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [match.params.id])
  const [value, setValue] = useState({
    cityValue: []
  })
  const { cityValue } = value
  useEffect(() => {
    setFormData({
      action: loading || !ad.action ? 'null' : ad.action,
      description: loading || !ad.description ? '' : ad.description,
      url: loading || !ad.url ? '' : ad.url,
      order: loading || !ad.order ? '' : ad.order,
      cities: loading || !ad.cities ? [] : ad.cities,
      startDate:
        loading || !ad.startDate
          ? ''
          : moment(ad.startDate).format('yyyy-MM-DDTHH:mm'),
      endDate:
        loading || !ad.endDate
          ? ''
          : moment(ad.endDate).format('yyyy-MM-DDTHH:mm'),
      isEnabled: loading || !ad.isEnabled ? false : ad.isEnabled,
      imagePath: loading || !ad.imagePath ? '' : ad.imagePath,
      restaurantmail: loading || ad.restaurantmail ? null : ad.restaurantId?.email
    })
    
    setValue({
      cityValue:
        loading || !ad.cities
          ? []
          : ad.cities.map((city) => ({
              value: city._id,
              label: city.name
            }))
    })
  }, [loading])

  const handleMultiselect = (e) => {
    setValue({ cityValue: e })
    setFormData({
      ...formData,
      cities: e.map((e) => {
        return e.value
      })
    })
  }
  const handleChangeSwitch = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked })
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    
  }
  const [checkimage,setcheckimage]=useState('NOIMAGE')
  const handleImageSelect = (e) => {
    const { files } = e.target
    if(e.target.files){
      setcheckimage('IMAGE')
    }
    parseImage(files[0]).then((result) => {
      setFormData({ ...formData, imagePath: files[0] })
      setImagePreview(result)
    })
  }
  const submit = (e) => {
    e.preventDefault()
    if (!imagePath) {
      setOpen(true)
      setSnackbarMessage('Veuillez entrer une image')
    } else if (!description) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir une description valide')
    } else if (order === 0 || !order) {
      setOpen(true)
      setSnackbarMessage('Veuillez entrer un ordre supérieur à 0')
    } else if (!startDate) {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir une date de debut')
    } else if (!endDate) {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir une date de fin')
    } else if (cities.length === 0 && match.params.tabValue === 'Ville') {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir une/plusieurs ville')
    } else if (
      (!restaurantmail || !isEmailValid(restaurantmail)) &&
      action === Adaction.RESTAURANT
    ) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir un email valide')
    } else if (!url && action === Adaction.WEB) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir un url valide')
    }
    else if (action !== Adaction.WEB && url) {
      setOpen(true)
      setSnackbarMessage('Veuillez supprimez URL')
    }
    else if (action !== Adaction.RESTAURANT && restaurantmail) {
      setOpen(true)
      setSnackbarMessage('Veuillez supprimez email restaurant ')
    }
     else {
       if(!restaurantmail){
        setFormData({...formData,restaurantmail:null})  
      }
      const startDate = new Date(formData.startDate)
      const endDate = new Date(formData.endDate)

      update_Ad(match.params.id, { ...formData, startDate, endDate }, history, checkimage)
      .catch((err)=>{
        setOpen(true)
        setSnackbarMessage(err?.response?.data?.errors[0]?.message)
      })
    }
  }
  return (
    <Fragment>
      <div id="restaurant-form-form-container" style={classes.mainContainer}>
        <h1>Modifier une publicité</h1>
        <div className={classNames(classes.rows, classes.imageWrapper)}>
          {imagePath && (
            <img
              className={classNames(classes.rows, classes.previewImage)}
              src={imagePreview}
              style={{ width: '300px' }}
              alt="ad"
            />
          )}
          <UploadButtonJPEG title="Photo" onFileSelect={handleImageSelect} />
        </div>
        <TextField
          style={{ paddingBottom: '20px' }}
          className={classes.rows}
          name="description"
          value={description}
          onChange={(e) => handleChange(e)}
          fullWidth
          label="Description"

          //error={showErrors && !validFields.nom}
          /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
        />
        {
          <FormControl>
            <InputLabel shrink>Ville</InputLabel>
            <Select
              name="cities"
              isMulti
              value={cityValue}
              onChange={(e) => handleMultiselect(e)}
              options={allCities.map((city) => ({
                value: city._id,
                label: city.name
              }))}
            />
          </FormControl>
        }
        <FormControl style={{ paddingBottom: '20px' }}>
          <InputLabel shrink>Actions</InputLabel>
          <Select1
            className={classes.rows}
            name="action"
            value={action}
            onChange={(e) => handleChange(e)}
          >
            <option value={Adaction.NONE}>Pas d'action</option>
            <option value={Adaction.WEB}>Web</option>
            <option value={Adaction.PICKUP}>Emporter</option>
            <option value={Adaction.PROMO_CODE}>Code Promo</option>
            <option value={Adaction.REFERRAL_CODE}>Parrainage</option>
            <option value={Adaction.RESTAURANT}>{Adaction.RESTAURANT}</option>
            <option value={Adaction.UPDATE}>Mise à jour</option>
          </Select1>
        </FormControl>
        {action === Adaction.WEB ? (
          <TextField
            style={{ paddingBottom: '20px' }}
            className={classes.rows}
            name="url"
            value={url}
            onChange={(e) => handleChange(e)}
            fullWidth
            label="Url"
            placeholder="https://www.lyveat.com"
            //error={showErrors && !validFields.nom}
            /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
          />
        ) : (
          <div></div>
        )}
        {action === Adaction.RESTAURANT ? (
          <TextField
            style={{ paddingBottom: '20px' }}
            className={classes.rows}
            name="restaurantmail"
            value={restaurantmail}
            onChange={(e) => handleChange(e)}
            fullWidth
            label="Restaurant"
            //error={showErrors && !validFields.nom}
            /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
          />
        ) : (
          <div></div>
        )}

        <FormLabel> Publicité active</FormLabel>

        <Typography component="div">
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{ paddingLeft: '210px' }}
          >
            <Grid item>inactive</Grid>
            <Grid item>
              <Switch
                checked={isEnabled}
                value={isEnabled}
                onChange={handleChangeSwitch}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>active</Grid>
          </Grid>
        </Typography>

        <TextField
          style={{ paddingBottom: '20px' }}
          className={classes.rows}
          name="order"
          value={order}
          type="number"
          min="1"
          onChange={(e) => handleChange(e)}
          fullWidth
          label="Ordre"
          //error={showErrors && !validFields.nom}
          /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
        />
        <TextField
          className={classes.rows}
          style={{ paddingBottom: '20px' }}
          id="datetime-local"
          label="Début"
          type="datetime-local"
          defaultValue={Date.now()}
          name="startDate"
          value={startDate}
          onChange={(e) => handleChange(e)}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          style={{ paddingBottom: '20px' }}
          className={classes.rows}
          id="datetime-local"
          label="Fin"
          type="datetime-local"
          name="endDate"
          value={endDate}
          defaultValue={Date.now()}
          onChange={(e) => handleChange(e)}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => submit(e)}
        >
          modifier la Pub
        </Button>
        <Snackbar
          open={open}
          onClose={handleCloseSnackbar}
          severity={snackBarSeverity}
          message={snackBarMessage}
        />
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  allCities: citiesSelectors.getAllCities(state),
  ads: state.ads
})

export default connect(mapStateToProps, { getAd, update_Ad })(EditAd)
