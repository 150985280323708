import React, { useState } from 'react';
import { FormControl } from '@material-ui/core';

import NewPlanningsList from './NewPlanningsList';

export default function PlanningsListContainer({ city }) {

	const [startWeek, setStartWeek] = useState(JSON.stringify(new Date(Date.now())).substring(0,11));

	return (
		<React.Fragment>
			{/* <h2 style={{marginBottom:5}} >Planning des livreurs</h2>
			<Link to="/livreurs"  style={{marginBottom:25}}>
				<span>Voir les livreurs</span>
			</Link> */}
			<div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
				<FormControl>
				
				</FormControl>
			</div>
			<NewPlanningsList city={city} selectedWeek={startWeek} />			
		</React.Fragment>
	);
}
