import React from 'react'
import List from 'core.ui/List'
import {
  getTemplates,
  addTemplate,
  duplicateTemplate
} from '../../core.modules/Template/Actions'
import { connect, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from '@material-ui/core'
import Dialog1 from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Dialog from 'core.ui/Dialog'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from 'core.ui/Snackbar'

const style = {
  h3: { textAlign: 'center' }
}
function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
      ref.current = value;
  });
  return ref.current;
}
const Template = ({
  addTemplate,
  templates: { templates, loading },
  duplicateTemplate
}) => {
  const [openSnack, setOpenSnack] = React.useState(true);
  const [snackBarMessage, setSnackbarMessage] = React.useState('error')
  const [snackBarSeverity, setsnackBarSeverityMessage] = React.useState('error')
  const {err} =useSelector((state)=>state.templates)
  const previousValue = usePrevious(err);
/*   React.useEffect(()=>{
      if(err===previousValue && err!==null && previousValue!==null){
        setOpenSnack(true)
        setSnackbarMessage('erreur survenue')
        setsnackBarSeverityMessage('error')
      } else if(err===null && err){
        setOpenSnack(true)
        setSnackbarMessage('success')
        setsnackBarSeverityMessage('success')
      }
    
    
  },[err,previousValue]) */
  const [openDeleteDialog, setopenDeleteDialog] = React.useState(false)
  const [formData, setformData] = React.useState({
    name: '',
    restaurantEmails: [{ email:'' }]
  })
  const { name, restaurantEmails, restaurantGroupIds } = formData
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleEmailChange = (e,index) => {
    const list =[...restaurantEmails]
    list[index]=e.target.value
    setformData({ ...formData, restaurantEmails: list })
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const [templateId, setTemplateId] = React.useState('')
  const [newName,setNewname]=React.useState('')
  const closeDuplicateDialog = () => {
    setopenDeleteDialog(false)
    setTemplateId(null)
  }
  const handleDuplicate = (id) => {
    setTemplateId(id)
    setopenDeleteDialog(true)
  }
  const confirmDuplication = () => {
    duplicateTemplate(templateId,newName)
    setopenDeleteDialog(false)
  }
  const handleAddClick = () => {
    setformData({...formData, restaurantEmails:[...restaurantEmails,{ email:'' }] });
  };
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...restaurantEmails];
    list.splice(index, 1);
    setformData({ ...formData, restaurantEmails: list })
  };
  const renderList = (template) => {
    return [template.name, <p>{template.restaurants.map((restaurant) =>{return( <p><b>Nom: </b>{restaurant.nom} <br/> <b>Email: </b>{restaurant.email}</p>)} )}</p>]
  }
  const handleCloseSnackbar = () => {
    setOpenSnack(false)
  }
  const addForm =()=> {

       addTemplate(formData)
       setOpen(false)
       setOpenSnack(true)

  }
  return (
    <div>
      <h3 style={style.h3}>
        Liste des templates
        <br />
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          Ajouter une template
        </Button>
      </h3>
      {loading ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <List
            ids={templates.map((template) => template._id || template.id)}
            columns={['Nom', 'Restaurant']}
            actions={{
              /*  handleDelete: handleDelete, handleEdit: handleEdit */ handleDuplicate:
                handleDuplicate
            }}
            emptyMessage="Aucune template"
            list={templates.map((template) => {
              return renderList(template)
            })}
          />
          <Dialog1
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Ajouter une template'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TextField
                  style={{ paddingBottom: '20px' }}
                  name="name"
                  value={name}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  label="Nom"
                />
                {restaurantEmails.map((x,i)=>{
                  return (
                 <div> <TextField
                  style={{ paddingBottom: '20px' }}
                  // name="restaurantEmails"
                  value={x.email}
                  onChange={(e) => handleEmailChange(e,i)}
                  fullWidth
                  placeholder="Email restaurant"
                />
                 {restaurantEmails.length - 1 === i &&<Button onClick={()=>handleAddClick()}><AddIcon/> </Button>}
                {restaurantEmails.length !== 1 &&<Button onClick={()=>handleRemoveClick(i)}><DeleteIcon/></Button>}</div>
                  )
                })}
                
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Annuler
              </Button>
              <Button
                onClick={(e) => {
                 addForm()
                }}
                color="primary"
                autoFocus
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog1>
          <Dialog
            open={openDeleteDialog}
            dialogText={<TextField

              //value={newName}
              onChange={(e) => setNewname(e.target.value)}
              fullWidth
              label="Nouveau Nom"
            />}
            dialogTitle="Confirmer la duplication"
            cancelButtonCaption="Annuler"
            confirmButtonCaption="Ok"
            onCancel={closeDuplicateDialog}
            onValidation={confirmDuplication}
          />
        </div>
      )}
      {err && <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnackbar}   severity={snackBarSeverity}
          message={snackBarMessage} />}
    </div>
  )
}
const mapStateToProps = (state) => ({
  templates: state.templates
})
export default connect(mapStateToProps, { addTemplate, duplicateTemplate })(
  Template
)
