import React from 'react';
import { Button } from '@material-ui/core'

export default function StripeAction({ buttons }) {
	if (!buttons.includes('stripe')) {
		return null;
	}

	return (
		<a 
			href="https://dashboard.stripe.com/payments?status%5B%5D=successful"
			target="_blank"
			style={{ color:"green", marginRight:10, fontSize:14 }} 
		>
			Stripe
		</a>
	);
}
