import React, { useState } from 'react';
import { TextareaAutosize } from '@material-ui/core';

import { order } from 'core.services/api'

export default function NewNoteAction({currentOrder, refreshOrders}) {
    const [note, setNote] = useState("")
    const [isVisible, setIsVisible] = useState(false)

    const onAddNote = async () => {
        if (note === "") {
            return 
        }
        
        try {
           const data = await order.addPriorityOrdersNote(currentOrder._id, note)           
           setNote("")
           refreshOrders()
        } catch(e) {
            console.log("Error ", e)
        } finally {
            setIsVisible(false)
        }
    }

  return (
    <>
        {isVisible ? 
        <React.Fragment>
            <a 
                href="#"
                style={{ color:"red", marginRight:10 }} 
                onClick={() => setIsVisible(false)}
            >
                Fermer note
            </a> 
            <div style={{marginTop:10}}>
                <TextareaAutosize style={{width:"100%"}} rowsMin={2} value={note} onChange={(e) => setNote(e.target?.value)} ></TextareaAutosize>
                <button onClick={onAddNote} style={{ color:"green", cursor:"pointer"}} >Ajouter</button>
            </div>
        </React.Fragment>
        :
        <a 
			href="#"
            style={{ color:"green", marginRight:10, fontSize:14 }} 
            onClick={() => setIsVisible(true)}
		>
			Ajouter Note
		</a>}         
    </>
  );
}
