

import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { noteAdmin } from '../../core.services/api'

export const {
    GENERATE_NOTE_RECAP_REQUESTED,
    GENERATE_NOTE_RECAP_SUCCEEDED,
    GENERATE_NOTE_RECAP_FAILED,
    generateNoteAdminRecapSucceeded,
    generateNoteAdminRecapFailed
  } = createRequestActions('GENERATE_NOTE_RECAP')
  
  export const generateNoteAdminRecapRequested = (startDate, endDate) => (dispatch) => {
    dispatch({
      type: GENERATE_NOTE_RECAP_REQUESTED
    })
    noteAdmin
      .generateRecap(startDate, endDate)
      .then((data) => {
        dispatch(generateNoteAdminRecapSucceeded(data))
      })
      .catch((error) => {
        //dispatch(generateNoteAdminRecapFailed(error))
      })
  }
  
export const defaultState = { allNotes: [], allNoteStatuses: [], requestState: '' }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case GENERATE_NOTE_RECAP_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }   
    case GENERATE_NOTE_RECAP_SUCCEEDED:
      return {
        ...state,
        requestState: requestStates.SUCCESS
      }
    case GENERATE_NOTE_RECAP_FAILED:
      return { ...state, requestState: requestStates.FAILED }
    default:
      return state
  }
}
