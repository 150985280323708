import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, FormControl } from '@material-ui/core';
import Dialog from 'core.ui/Dialog';
import { CheckCircle, XCircle } from 'react-feather'

import RiderCreate from 'components/RiderCreate';
import RiderEdit from 'components/RiderEdit';

import {
	selectors as ridersSelectors,
	fetchCityRidersRequested,
	updateArchiveRiderRequested,
	updateDisableRiderRequested,
	deleteRiderRequested
} from 'core.modules/riders/reducer';

import List from 'core.ui/List';
import { parseToSwitch } from '../../core.utils/parser/order';
import PlanningsListContainer from '../PlanningsList/PlanningsListContainer';

const styles = {
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	filterLabel: {
		marginLeft: '7px'
	},
	addButton: {
		textDecoration: 'none !important',
		marginLeft: '10px',
		fontSize: '10px'
	}
};

class RidersList extends React.Component {
	constructor(props) {
		super(props);
		this.state = { filterStatus: 'online' };
	}

	componentDidMount() {
		const { city, fetchCityRiders } = this.props;
		if (city) {
			fetchCityRiders(city, true);
		}
	}

	componentDidUpdate(prevProps) {
		const { city, fetchCityRiders } = this.props;
		if (city && city !== prevProps.city) {
			fetchCityRiders(city, true);
		}
	}

	handleDisableChange = (riderId) => (isDisable) => {
		const { currentCityRiders, updateDisableRider } = this.props;
		// const riderRequestId = currentCityRiders[index].id || currentCityRiders[index]._id
		updateDisableRider(riderId, isDisable);
	};

	handleArchiveChange = (riderId) => (isArchived) => {
		const { currentCityRiders, updateArchiveRider } = this.props;
		updateArchiveRider(riderId, isArchived);
	};

	parseRider = (
		{ id, _id, firstName, lastName, email, phone, numberOfOrders, transport, pushToken, socketId, isDisabled, isArchived },
		index
	) => {
		const trueValue = <CheckCircle size={24} color={'green'} />
    const falseValue = <XCircle size={24} color={'red'} />
	const translateTransport = (transport) => {
		switch(transport) {
			case "car":
				return "🚗 Voiture"
			case "bike":
				return "🚴🏻‍♀️ Vélo"
				case "scooter":
				return "🛵 Scooteur"
			default:
				
		}
	 };

		return [
			firstName,
			lastName,
			email,
			phone,
			translateTransport(transport),
			numberOfOrders,
			pushToken ? trueValue : falseValue,
			socketId ? trueValue : falseValue,
			parseToSwitch(!!isDisabled, this.handleDisableChange(id || _id)),
			parseToSwitch(!!isArchived, this.handleArchiveChange(id || _id))
		];
	};

	handleFilterSelectChange = (filterStatus) => {
		//const { value } = e.target
		this.setState({ filterStatus });
	};

	handleEdit = (id) => {
		const { history } = this.props;
		history.push(`/livreurs/edit?id=${id}`);
	};

	handleDelete = (id) => {
		this.setState({
			openDeleteDialog: true,
			indexToDelete: id
		});
	};

	closeDeleteDialog = () => {
		this.setState({
			openDeleteDialog: false,
			indexToDelete: null
		});
	};

	makeIds = (riders) => {
		return riders.map((r) => r.id || r._id);
	};

	confirmDelete = () => {
		const { indexToDelete } = this.state;
		const { deleteRider } = this.props;
		deleteRider(indexToDelete);
		this.closeDeleteDialog();
	};

	render() {
		const { filterStatus, openDeleteDialog } = this.state;
		const { classes, currentCityRiders, city } = this.props;

		const filteredRiders = currentCityRiders.filter(
			(r) => (filterStatus === 'archived' ? r.isArchived : r.status === filterStatus)
		);
		const displayedRiders = filteredRiders.map(this.parseRider);

		return (
			<div className={classes.mainContainer}>
				<Switch>
					<Route path="/livreurs/new" exact render={(props) => <RiderCreate {...props} />} />
					<Route
						path="/livreurs/edit"
						render={(props) => {
							const id = props.location.search.split('=')[1];
							const data = currentCityRiders.find((rider) => rider.id === id || rider._id === id);
							return <RiderEdit id={id} currentCity={city} data={data} {...props} />;
						}}
					/>
					<Route
						path="/livreurs"
						exact
						render={(props) => (
							<React.Fragment>								
                {/* <h2 style={{marginBottom:5}} >Liste des livreurs</h2>
                <Link to="/livreurs/plannings"  style={{marginBottom:25}}>
                  <span>Voir les plannings</span>
                </Link> */}
								<div style={{ display: 'flex', alignItems: 'center', marginTop:30 }}>
									<FormControl>
										<ToggleButtonGroup
											value={filterStatus}
											exclusive
											onChange={(e, value) => {
												value !== null && this.handleFilterSelectChange(value);
											}}
											aria-label="text alignment"
										>
											<ToggleButton value="online" aria-label="left aligned">
												<span>En ligne</span>
											</ToggleButton>
											<ToggleButton value={'offline'} aria-label="centered">
												<span>Hors ligne</span>
											</ToggleButton>
											<ToggleButton value={'archived'} aria-label="right aligned">
												<span>Archivé</span>
											</ToggleButton>
										</ToggleButtonGroup>
									</FormControl>
									<Link className={classes.addButton} to="/livreurs/new">
										<Button variant="contained" color="secondary">
											Ajouter un livreur
										</Button>
									</Link>
								</div>

								<List
									list={displayedRiders}
									columns={[
										'Prénom',
										'Nom',
										'Email',
										'Téléphone',
										'Type de véhicule',
										'Nombre de livraisons',
										'Push Token',
										'Socket',
										'Désactivé',
										'Archivé'
									]}
									ids={this.makeIds(filteredRiders)}
									actions={{
										handleView: (id) => props.history.push(`/livreurs-tracking/${id}`),
										handleDelete: this.handleDelete,
										handleEdit: this.handleEdit
									}}
									emptyMessage="Aucun livreur"
								/>
								<Dialog
									open={openDeleteDialog}
									dialogText="Confirmer la suppression du livreur"
									dialogTitle="Confirmation"
									cancelButtonCaption="Annuler"
									confirmButtonCaption="Ok"
									onCancel={this.closeDeleteDialog}
									onValidation={this.confirmDelete}
								/>
                <PlanningsListContainer city={city} {...props} />
							</React.Fragment>
						)}
					/>
					<Route path="/livreurs/plannings" exact render={(props) => <PlanningsListContainer city={city} {...props} />} />
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	currentCityRiders: ridersSelectors.getCurrentCityRiders(state)
});

const mapDispatchToProps = (dispatch) => ({
	fetchCityRiders: (city, includeInactive) => {
		dispatch(fetchCityRidersRequested(city, includeInactive));
	},
	updateDisableRider: (riderId, isDisabled) => {
		dispatch(updateDisableRiderRequested(riderId, isDisabled));
	},
	updateArchiveRider: (riderId, isArchived) => {
		dispatch(updateArchiveRiderRequested(riderId, isArchived));
	},
	deleteRider: (id) => {
		dispatch(deleteRiderRequested(id));
	}
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(RidersList);
