import React from 'react'
import { AppBar, Button, Tab, Tabs, TextField, withStyles } from '@material-ui/core'
import { generateRestaurantsRecapRequested, fetchAllRestaurantsRequested, selectors as restaurantsSelectors, generateRestaurantOrdersRecapRequested, generateRestaurantInvoiceRequested  } from '../../core.modules/restaurants/reducer'
import { generateRegulationRecapRequested } from '../../core.modules/regulation/reducer';
import { generateNoteAdminRecapRequested } from '../../core.modules/note/reducer';
import { generateRidersRecapRequested, fetchCityRidersRequested,fetchCityRidersCompletedRequested, selectors as ridersSelectors, generateRiderRecapRequested} from '../../core.modules/riders/reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isRecapDateValid, reverseDate } from '../../core.utils/formValidation'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import { File } from 'react-feather'
import Select from 'core.ui/Select'
import Snackbar from 'core.ui/Snackbar'
import { requestStates } from 'core.utils/requestStates'
import { city, invoiceAPI, restaurant } from '../../core.services/api'
import TabPanel from '../../core.ui/TabPanel/TabPanel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import List from 'core.ui/List'
import { monthsTranslated, selectableYears } from '../../core.utils/utils'

class FinancialManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      restaurantRecapStartDate: null,
      restaurantRecapEndDate: null,
      ridersRecapStartDate: null,
      ridersRecapEndDate: null,
      riderRecapStartDate: null,
      riderRecapEndDate: null,
      ordersRecapStartDate: null,
      ordersRecapEndDate: null,
      regulationsRecapStatDate: null,
      regulationsRecapEndDate: null,
      notesRecapStartDate: null,
      notesRecapEndDate: null,
      city: null,
      previousCity: null,
      previousRestaurantCity: null,
      restaurantCity: null,
      selectedRider: null,
      currentCityRestaurants: [],
      currentCityRiders: [],
      selectedRestaurant: null,
      openSnackBar: false,
      snackBarMessage: '',
      waitingForRequest: false,
      tabValue: "recaps",
      selectedInvoiceMonth: new Date().getUTCMonth(),
      selectedInvoiceYear: selectableYears[0]
    }
  }

  componentDidMount() {
    const { city } = this.props
    const { fetchCityRiders, fetchCityRidersCompleted } = this.props
    this.setState({city}, () => {
      fetchCityRiders(this.state.city)
      fetchCityRidersCompleted(this.state.city)
      this.getRestaurantsByCity(this.state.city)
    })
  }

  componentDidUpdate(prevProps) {
    const { previousCity, city, previousRestaurantCity, restaurantCity, waitingForRequest } = this.state
    const { fetchCityRiders, currentRequestState, errorMessage, fetchCityRidersCompleted} = this.props
    if (city !== previousCity) {
      fetchCityRiders(this.state.city)
      fetchCityRidersCompleted(this.state.city)
      this.setState({previousCity: city})
    }
    if (restaurantCity !== previousRestaurantCity) {
      this.getRestaurantsByCity(this.state.restaurantCity)
      this.setState({previousRestaurantCity: restaurantCity})
    }
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })
    } else if ( 
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true, snackBarMessage: errorMessage })
    }
  }

  getRestaurantsByCity = (cityId) => {
    invoiceAPI.getRestaurantInvoiceInfos(cityId)
    .then((data) => this.setState({ currentCityRestaurants: data, selectedRestaurant: (data[0]?.id || data[0]?._id) }))
    .catch((err) => {
      this.setState({ snackBarMessage: err?.response?.data?.errors[0]?.message, openSnackBar: true })
    })
  }

  setSelectedCityRestaurants = (cityId) => {
    const { allRestaurants } = this.props
    const currentCityRestaurants = allRestaurants.filter((restaurants) => restaurants.city === cityId) 
    this.setState({ currentCityRestaurants })
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }
  //Restaurant
  handleGenerateRestaurantRecap() {
    if (!isRecapDateValid(this.state.restaurantRecapStartDate, this.state.restaurantRecapEndDate)) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Entrez des dates valides'
      })
      return
    }

    const reversed = reverseDate(this.state.restaurantRecapStartDate, this.state.restaurantRecapEndDate);
    if (reversed.errorMessage) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: reversed.errorMessage
      })
    }
    const { startDateFormatted, endDateFormatted } = reversed

    this.setState({ waitingForRequest: true })
    this.props.generateRestaurantsRecap(startDateFormatted, endDateFormatted)
  }
  //Regulation
  handleGenerateRegulationRecap() {
    if (!isRecapDateValid(this.state.regulationsRecapStatDate, this.state.regulationsRecapEndDate)) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Entrez des dates valides'
      })
      return
    }

    const reversed = reverseDate(this.state.regulationsRecapStatDate, this.state.regulationsRecapEndDate);
    if (reversed.errorMessage) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: reversed.errorMessage
      })
    }
    const { startDateFormatted, endDateFormatted } = reversed

    this.setState({ waitingForRequest: true })
    this.props.generateRegulationRecap(startDateFormatted, endDateFormatted)
  }
  //Note admin
  handleGenerateNoteRecap() {
    if (!isRecapDateValid(this.state.notesRecapStartDate, this.state.notesRecapEndDate)) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Entrez des dates valides'
      })
      return
    }

    const reversed = reverseDate(this.state.notesRecapStartDate, this.state.notesRecapEndDate);
    if (reversed.errorMessage) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: reversed.errorMessage
      })
    }
    const { startDateFormatted, endDateFormatted } = reversed

    this.setState({ waitingForRequest: true })
    this.props.generateNoteAdminRecap(startDateFormatted, endDateFormatted)
  }
  //Rider
  handleGenerateRidersRecap() {
    if (!isRecapDateValid(this.state.ridersRecapStartDate, this.state.ridersRecapEndDate)) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Entrez des dates valides'
      })
      return
    }

    const reversed = reverseDate(this.state.ridersRecapStartDate, this.state.ridersRecapEndDate);
    if (reversed.errorMessage) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: reversed.errorMessage
      })
    }
    const { startDateFormatted, endDateFormatted } = reversed

    this.setState({ waitingForRequest: true })
    this.props.generateRidersRecap(startDateFormatted, endDateFormatted)
  }

  handleGenerateRiderRecap() {
    const { currentCityRiders } = this.props
    const rider = currentCityRiders.find((rider) => rider._id == this.state.selectedRider)
    if (!rider) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Selectionnez un livreur'
      })
      return
    }

    if (!isRecapDateValid(this.state.riderRecapStartDate, this.state.riderRecapEndDate)) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Entrez des dates valides'
      })
      return
    }

    const reversed = reverseDate(this.state.riderRecapStartDate, this.state.riderRecapEndDate);
    if (reversed.errorMessage) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: reversed.errorMessage
      })
    }
    const { startDateFormatted, endDateFormatted } = reversed; 

    this.setState({ waitingForRequest: true })
    this.props.generateRiderRecap(rider, startDateFormatted, endDateFormatted)
  }

  handleGenerateOrdersRecap() {
    const { currentCityRestaurants, selectedRestaurant, ordersRecapStartDate , ordersRecapEndDate } = this.state
    const restaurant = currentCityRestaurants.find((restaurant) => (restaurant.id || restaurant._id)  === selectedRestaurant)
    if (!restaurant) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Selectionnez un Restaurant'
      })
      return
    }

    if (!isRecapDateValid(ordersRecapStartDate, ordersRecapEndDate)) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Entrez des dates valides'
      })
      return
    }

    const reversed = reverseDate(ordersRecapStartDate, ordersRecapEndDate);
    if (reversed.errorMessage) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: reversed.errorMessage
      })
    }
    const { startDateFormatted, endDateFormatted } = reversed; 

    this.setState({ waitingForRequest: true })
    this.props.handleGenerateOrdersRecap(restaurant, startDateFormatted, endDateFormatted)
  }

  handleGenerateRestaurantInvoice(restaurantId) {
    const nowDate = new Date()
    const { currentCityRestaurants, selectedInvoiceMonth, selectedInvoiceYear } = this.state
    const restaurant = currentCityRestaurants.find((restaurant) => (restaurant.id || restaurant._id) === restaurantId)

    if (
      (!selectedInvoiceMonth || !selectedInvoiceYear) ||
      selectedInvoiceMonth > nowDate.getUTCMonth() + 1 && selectedInvoiceYear >= nowDate.getUTCFullYear()
    ) {
      return this.setState({ openSnackBar: true, snackBarMessage: 'Entrez une date valide' })
    }

    this.setState({ waitingForRequest: true })
    this.props.handleGenerateRestaurantInvoice(restaurant, selectedInvoiceMonth, selectedInvoiceYear)
  }

  handleGenerateRiderInvoice(riderId) {

    const nowDate = new Date()
    const { selectedInvoiceMonth, selectedInvoiceYear } = this.state
    const {cityRidersWithCompletedOrders} = this.props
    const rider = cityRidersWithCompletedOrders.find((rider) => (rider.id || rider._id) === riderId)

    if (
      (!selectedInvoiceMonth || !selectedInvoiceYear) ||
      selectedInvoiceMonth > nowDate.getUTCMonth() + 1 && selectedInvoiceYear >= nowDate.getUTCFullYear()
    ) {
      return this.setState({ openSnackBar: true, snackBarMessage: 'Entrez une date valide' })
    }

    this.setState({ waitingForRequest: true })
    invoiceAPI.generateRiderInvoice(rider?.rider, selectedInvoiceMonth, selectedInvoiceYear)
  }

  handleCityChange = e => {
    const { city } = this.state
    this.setState({ 
      previousCity: city,
      city: e.target.value,
      selectedRider: null
    })
  }

  handleRestaurantCityChange = e => {
    const { restaurantCity } = this.state
    this.getRestaurantsByCity(e.target.value)
    this.setState({
      previousCity: restaurantCity,
      restaurantCity: e.target.value ,
      selectedRestaurant: null
    })
  }

  handleCityAutoCompleteChange = (e, value) => {
    if (!value) return 

    const { allCities } = this.props
    const { restaurantCity } = this.state

    const i = allCities.map(c => c.name).indexOf(value)
    const cityId = allCities[i]?.id
    if (!cityId) return
    
    this.getRestaurantsByCity(cityId)
    this.props.fetchCityRidersCompleted(cityId)
    this.setState({ previousCity: restaurantCity, restaurantCity: cityId, selectedRestaurant: null })
  }

  handleRiderChange = e => {
    this.setState({ selectedRider: e.target.value })
  }

  handleRestaurantChange = e => {
    this.setState({ selectedRestaurant: e.target.value })
  }

  handleInvoiceMonthChange = e => {
    this.setState({ selectedInvoiceMonth: e.target.value })
  }

  handleInvoiceYearChange = e => {
    this.setState({ selectedInvoiceYear: e.target.value })
  }

  handleChangeTab = (event, tabValue) => {
    this.setState({ tabValue })
  }

  getTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  onExtractButtonClick() {
    restaurant.extractCsv()
   }
   onExtractCityCSVButtonClick() {
    city.extractCsv()
   }


  renderTabs = () => {
    const { classes } = this.props
    const { tabValue } = this.state
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={this.handleChangeTab}
            aria-label="Financial tabs"
          >
            <Tab
              value="recaps"
              label="recaps"
              {...this.getTabProps('recaps')}
            />
            <Tab value="invoices" label="Factures Restos" {...this.getTabProps('invoices')} />
            <Tab value="riders" label="Factures Livreurs" {...this.getTabProps('riders')} />
            <Tab value="extraction" label="extraction" {...this.getTabProps('extraciton')} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={'recaps'}>
          {this.renderRecaps()}
        </TabPanel>
        <TabPanel value={tabValue} index={'invoices'}>
          {this.renderInvoices()}
        </TabPanel>
        <TabPanel value={tabValue} index={'riders'}>
          {this.renderRiders()}
        </TabPanel>
        <TabPanel value={tabValue} index={'extraction'}>
          {this.renderExtraction()}
        </TabPanel>
      </div>
    )
  }

  makeGenerateInvoiceColumn = (restaurantId) => (
    <Button 
      variant="contained"
      onClick={() => {this.handleGenerateRestaurantInvoice(restaurantId)}}
    >
      <File color={'red'} />
    </Button>
  )
  makeGenerateRiderInvoiceColumn = (riderId) => (
    <Button 
      variant="contained"
      onClick={() => {this.handleGenerateRiderInvoice(riderId)}}
    >
      <File color={'red'} />
    </Button>
  )

  renderInvoices = () => {
    const { currentCityRestaurants = [], restaurantCity, selectedInvoiceMonth, selectedInvoiceYear } = this.state
    const { allCities } = this.props

    const restaurants = currentCityRestaurants.map((r) =>
      [
        r.nom, 
        r.currentInvoiceCount,
        this.makeGenerateInvoiceColumn(r.id || r._id)
      ]
    )
    return (
      <>
        <Autocomplete
          options={allCities.map((c) => c.name).sort()}
          defaultValue={allCities.filter((c) => (c.id || c._id) === restaurantCity)[0]?.name}
          style={{ width: '30%' }}
          onChange={this.handleCityAutoCompleteChange}
          renderInput={(params) => <TextField {...params} label="Ville" variant="outlined" />}
        />
        <Select
          options={monthsTranslated.map((m, i) => i + 1)}
          captions={monthsTranslated}
          value={selectedInvoiceMonth}
          style={styles.inputForm}
          onChange={this.handleInvoiceMonthChange}
        ></Select>
        <Select
          options={selectableYears}
          captions={selectableYears}
          value={selectedInvoiceYear}
          style={styles.inputForm}
          onChange={this.handleInvoiceYearChange}
        ></Select>
        <List
          list={restaurants}
          columns={[
            'Nom',
            'Compteur actuel',
            'Générer'
          ]}
          ids={currentCityRestaurants.map((r) => r.id || r._id)}
          emptyMessage="Aucun restaurant"
        />

      </>
    )
  }

  renderRecaps = () => {
    const { city, restaurantCity , selectedRider, selectedRestaurant, currentCityRestaurants } = this.state
    const { allCities, currentCityRiders } = this.props
    return (

      <div>
        <div style={styles.mainListContainer}>
          <div style={styles.formTitle}> 
            Generation du recap des restaurants
          </div>
          <div style={styles.header}>
            Du
            <input
              style={styles.inputForm}
              type="date"
              onChange={(event) =>
                this.setState({ restaurantRecapStartDate: event.target.value })
              }
            />
            Au
            <input
              style={{ marginRight: '20px', marginLeft: '20px' }}
              type="date"
              onChange={(event) => this.setState({ restaurantRecapEndDate: event.target.value })}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleGenerateRestaurantRecap()}
          >
            Generer le rapport des restaurants
          </Button>
        </div>

        <div style={styles.mainListContainer}>
          <div style={styles.formTitle}> 
            Generation du recap des livreurs 
          </div>
          <div style={styles.header}>
            Du
            <input
              style={styles.inputForm}
              type="date"
              onChange={(event) =>
                this.setState({ ridersRecapStartDate: event.target.value })
              }
            />
            Au
            <input
              style={styles.inputForm}
              type="date"
              onChange={(event) => this.setState({ ridersRecapEndDate: event.target.value })}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleGenerateRidersRecap()}
          >
            Generer le rapport des livreurs
          </Button>
        </div>
        <div style={styles.mainListContainer}>
          <div style={styles.formTitle}> 
            Generation du recap des livraisons d'un livreur
          </div>
          <div style={styles.header}>
            Tri par ville:
            <Select
              options={allCities.map(c => c.id)}
              captions={allCities.map(c => c.name)}
              value={city}
              style={styles.inputForm}
              onChange={this.handleCityChange}
            ></Select>
              <Select
              options={currentCityRiders.map(c => c._id)}
              captions={currentCityRiders.map(c =>`${c.firstName} ${c.lastName}`)}
              value={selectedRider}
              style={styles.inputForm}
              onChange={this.handleRiderChange}
            ></Select>
              Du
              <input
                style={styles.inputForm}
                type="date"
                onChange={(event) =>
                  this.setState({ riderRecapStartDate: event.target.value })
                }
              />
              Au
              <input
                style={styles.inputForm}
                type="date"
                onChange={(event) => this.setState({ riderRecapEndDate: event.target.value })}
              />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleGenerateRiderRecap()}
          >
            Generer le rapport du livreur
          </Button>
        </div>
        <div style={styles.mainListContainer}>
          <div style={styles.formTitle}> 
            Generation du recap des commandes d'un restaurant
          </div>
          <div style={styles.header}>
            Tri par ville:
            <Select
              options={allCities.map(c => c.id || c._id)}
              captions={allCities.map(c => c.name)}
              value={restaurantCity}
              style={styles.inputForm}
              onChange={this.handleRestaurantCityChange}
            ></Select>
              <Select
              options={currentCityRestaurants.map(c => c.id || c._id)}
              captions={currentCityRestaurants.map(c => c.nom)}
              value={selectedRestaurant}
              style={styles.inputForm}
              onChange={this.handleRestaurantChange}
            ></Select>
          Du
              <input
                style={styles.inputForm}
                type="date"
                onChange={(event) =>
                  this.setState({ ordersRecapStartDate: event.target.value })
                }
                />
              Au
              <input
                style={styles.inputForm}
                type="date"
                onChange={(event) => this.setState({ ordersRecapEndDate: event.target.value })}
                />
                </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleGenerateOrdersRecap()}
            >
            Generer le rapport des commandes d'un restaurant
          </Button>
        </div>
        <div style={styles.mainListContainer}>
          <div style={styles.formTitle}> 
            Generation du recap des Regulations
          </div>
          <div style={styles.header}>
            Du
            <input
              style={styles.inputForm}
              type="date"
              onChange={(event) =>
                this.setState({ regulationsRecapStatDate: event.target.value })
              }
            />
            Au
            <input
              style={{ marginRight: '20px', marginLeft: '20px' }}
              type="date"
              onChange={(event) => this.setState({ regulationsRecapEndDate: event.target.value })}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleGenerateRegulationRecap()}
          >
            Generer le rapport des Regulations
          </Button>
        </div>
        <div style={styles.mainListContainer}>
          <div style={styles.formTitle}> 
            Generation du recap des notes admin
          </div>
          <div style={styles.header}>
            Du
            <input
              style={styles.inputForm}
              type="date"
              onChange={(event) => 
                this.setState({ notesRecapStartDate: event.target.value })
              }
            />
            Au
            <input
              style={{ marginRight: '20px', marginLeft: '20px' }}
              type="date"
              onChange={(event) => this.setState({ notesRecapEndDate: event.target.value })}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleGenerateNoteRecap()}
          >
            Generer le rapport des notes admin
          </Button>
        </div>
      </div>
    )
  }
  renderRiders = () => {
    const {  restaurantCity, selectedInvoiceMonth, selectedInvoiceYear } = this.state
    const { allCities, currentCityRiders, cityRidersWithCompletedOrders } = this.props
   
   

    const riders = cityRidersWithCompletedOrders.map((r) =>
      [
        <p>{r.rider.firstName} {r.rider.lastName}</p>, 
        this.makeGenerateRiderInvoiceColumn(r.id || r._id)
      ]
    )
    return (
      <>
        <Autocomplete
          options={allCities.map((c) => c.name).sort()}
          defaultValue={allCities.filter((c) => (c.id || c._id) === restaurantCity)[0]?.name}
          style={{ width: '30%' }}
          onChange={this.handleCityAutoCompleteChange}
          renderInput={(params) => <TextField {...params} label="Ville" variant="outlined" />}
        />
        <Select
          options={monthsTranslated.map((m, i) => i + 1)}
          captions={monthsTranslated}
          value={selectedInvoiceMonth}
          style={styles.inputForm}
          onChange={this.handleInvoiceMonthChange}
        ></Select>
        <Select
          options={selectableYears}
          captions={selectableYears}
          value={selectedInvoiceYear}
          style={styles.inputForm}
          onChange={this.handleInvoiceYearChange}
        ></Select>
        <List
          list={riders}
          columns={[
            'Nom',
            'Générer'
          ]}
          ids={cityRidersWithCompletedOrders.map((r) => r.id || r._id)}
          emptyMessage="Aucun livreur"
        />

      </>
    )
  }
  renderExtraction = () => {
    return (
      <div style={styles.mainListContainer}>
        <h3>Extraction Restaurant</h3>
        <Button variant="contained" color="secondary" onClick={this.onExtractButtonClick}>
         Extraire csv 
        </Button>
        <h3>Extraction Ville</h3>
        <Button variant="contained" color="secondary" onClick={this.onExtractCityCSVButtonClick}>
         Extraire csv 
        </Button>
  
    </div>
    )
  }

  render() {
    const { openSnackBar, snackBarMessage } = this.state
    return (
      <>
        {this.renderTabs()}
        <Snackbar
            open={openSnackBar}
            onClose={this.handleCloseSnackbar}
            severity="error"
            message={snackBarMessage}
        />
      </>
    )
  }
}

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}

const mapStateToProps = (state, ownProps) => ({
  allCities: citiesSelectors.getAllCities(state),
  currentCityRiders: ridersSelectors.getCurrentCityRiders(state),
  allRestaurants: restaurantsSelectors.getAllRestaurants(state),
  currentRequestState: ridersSelectors.getRequestState(state),
  cityRidersWithCompletedOrders: ridersSelectors.getCityRidersWithCompltedOrders(state),
  errorMessage: ridersSelectors.getErrorMessage(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchRestaurants: () => {
    dispatch(fetchAllRestaurantsRequested())
  },
  generateRestaurantsRecap: (startDate, endDate) => {
    dispatch(generateRestaurantsRecapRequested(startDate, endDate))
  },
  generateRegulationRecap: (startDate, endDate) => {
    dispatch(generateRegulationRecapRequested(startDate, endDate))
  },
  generateNoteAdminRecap: (startDate, endDate) => {
    dispatch(generateNoteAdminRecapRequested(startDate, endDate))
  },
  generateRidersRecap: (startDate, endDate) => {
    dispatch(generateRidersRecapRequested(startDate, endDate))
  },
  generateRiderRecap: (rider, startDate, endDate) => {
    dispatch(generateRiderRecapRequested(rider, startDate, endDate))
  },
  handleGenerateOrdersRecap: (restaurant, startDate, endDate) => {
    dispatch(generateRestaurantOrdersRecapRequested(restaurant, startDate, endDate))
  },
  handleGenerateRestaurantInvoice: (restaurant, startDate, endDate) => {
    dispatch(generateRestaurantInvoiceRequested(restaurant, startDate, endDate))
  },
  fetchCityRiders: city => {
    dispatch(fetchCityRidersRequested(city))
  }, 
  fetchCityRidersCompleted: city => {
    dispatch(fetchCityRidersCompletedRequested(city))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FinancialManagement)
