import React from 'react'
import PropTypes from 'prop-types'
import SearchBarCustom from 'core.ui/SearchBarCustom'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'

const FilterSearchBarCustom = (props) => {
  const { classes, onClear, onSubmit, showClearBtn, refreshAll } = props
  const searchFields = {
    order_number: 'N° de commande',
    client: 'Client',
    restaurant: 'Restaurant',
    rider: 'Livreur',
    total_price: 'Prix total',
    note_admin: 'Note admin'
  }

  return (
    <>
    <div className={classes.searchContainer}>
      <div style={{display: "flex", flexDirection: "column"}}>
        <SearchBarCustom
          searchFields={searchFields}
          booleanFields={['note_admin']}
          onSearch={onSubmit}
          onClearSearch={onClear}
          displayClearSearch={showClearBtn}
        />
      </div>
      <div className={classes.searchRefreshContainer}>
        <ButtonWithIcon
          featherIcon="RefreshCw"
          size={60}
          onClick={refreshAll}
        />
      </div>
    </div>
    </>
  )
}

FilterSearchBarCustom.propTypes = {
  classes: PropTypes.object,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  displayClearSearch: PropTypes.bool
}

export default FilterSearchBarCustom
