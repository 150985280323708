import store from './../../store'
import { messaging } from 'core.services/pushNotifications/firebase'
import { notificationReceived } from './reducer'
import { getCookie } from 'core.utils/parser/cookies'
import alert from 'assets/alert.ogg'

const audio = new Audio(alert)
export const listenToNotifications = () => {
  if (messaging) {
    messaging.onMessage(handleNotification)
  }
}

const handleNotification = (notification) => {
  if (getCookie('notifications') === 'true') {
    audio.play()
  }
  const action = notificationReceived(notification)
  store.dispatch(action)
}
