import React, { useRef, useState, useEffect } from 'react';

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

import { statusColorMapping } from '../../core.utils/parser/orderStatus';
import PriorityOrderCard from './PriorityOrderCard';
import { priorityOrdersColumns } from "./NewPriorityOrders"

import "./NewPriorityOrders.css"

const ITEMS_PER_PAGE = 15;

export default function PriorityOrderColumn({ data, refreshOrders, currentKey, title }) {
	const [ currentPage, setcurrentPage ] = useState(1);
	const [ totalPages, setTotalPages ] = useState();
	const [ items, setItems ] = useState();
    
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    
    const handleScroll = () => {
        if (ref.current) {
            console.log("ref.current.getBoundingClientRect().top", ref.current.getBoundingClientRect().top)
            setSticky(ref.current.getBoundingClientRect().top <= 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

	const onNextPage = () => {
        if (currentPage === totalPages) {
            return 
        }
        setcurrentPage(curr => curr + 1)
    };

	const onPreviousPage = () => {
        if (currentPage === 1) {
            return 
        }
        setcurrentPage(curr => curr - 1)
    };
    
    useEffect(() => {        
        let start = ((currentPage - 1) * ITEMS_PER_PAGE)
        let end = currentPage * ITEMS_PER_PAGE        
        setItems(data.slice(start, end))
    }, [ currentPage ])
    
    useEffect(() => {        
        setTotalPages(Math.ceil(data.length/ITEMS_PER_PAGE))
        setItems(data.slice(0, ITEMS_PER_PAGE))
        setcurrentPage(1)
    }, [ data ])

	return (
		<div style={{ borderRight: '3px solid black', minWidth: 250, minHeight: '75vh' }}            
        >
			<div
				style={{
					textAlign: 'center',
					backgroundColor: isSticky ? "red" : statusColorMapping[currentKey],					
					padding: 4,                                   
				}}  
			>  
				<h4 style={{margin:0, marginTop:4}}>
					{!!title ? title : `${priorityOrdersColumns[currentKey]} (${data?.length})`}
				</h4>
                <div 
                    style={{
                        textAlign: 'center',
                        // padding: 4,                        
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",  
                        marginBottom:4,                  
                        marginTop:4,                  
                    }}    
                >
                    <div onClick={onPreviousPage} style={{marginRight:10, border:"2px solid black", padding:2, width:18, height:18, borderRadius:18, textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <ChevronLeft size={30} />
                    </div>
                    <p style={{margin:0, fontWeight:"bold"}} >{currentPage}/{totalPages}</p>
                    <div onClick={onNextPage} style={{marginLeft:10, border:"2px solid black", padding:2, width:18, height:18, borderRadius:18, textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <ChevronRight size={30} />
                    </div>
                </div>
			</div>            
			{items?.map((order, idx) => (
				<PriorityOrderCard key={idx.toString()} order={order} refreshOrders={refreshOrders} />
			))}
		</div>
	);
}
