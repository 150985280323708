import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'

import Snackbar from 'core.ui/Snackbar'

const styles = {
  root: {},
  fileInput: {
    display: 'none'
  }
}

class UploadButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayWarning: false
    }
  }

  handleFileSelect = e => {
    const { files } = e.target
    if (files[0].type === 'image/png' || files[0].type ==='image/jpeg' || files[0].type === 'image.jpg') {
      this.props.onFileSelect(e)
    } else {
      this.setState({ displayWarning: true })
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ displayWarning: false })
  }

  render() {
    const { classes, className, title } = this.props
    const { displayWarning } = this.state
    return (
      <div className={classnames(classes.root, className)}>
        <input
          accept={"image/jpg" || 'image/jpeg'|| 'image/png'}
          className={classes.fileInput}
          id="contained-button-file"
          type="file"
          onChange={this.handleFileSelect}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            {title}
          </Button>
        </label>
        <Snackbar
          open={displayWarning}
          onClose={this.handleCloseSnackbar}
          severity="warning"
          message="Sélectionner un fichier png , jpeg ou jpg"
        />
      </div>
    )
  }
}

export default withStyles(styles)(UploadButton)
