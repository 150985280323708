import { isNil } from 'lodash'

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const telephoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*/
// const postcodeRegex = /^(([0-8][0-9])|(9[0-5])|(2[ab]))[0-9]{3}$/
const postcodeRegex = /^\d{5}$/

export const isPostcodeValid = postcode => postcodeRegex.test(postcode)
export const isEmailValid = email => emailRegex.test(email)
export const isTelephoneValid = number => telephoneRegex.test(number)
export const isNumberValid = (number) => {
  return number >= 0
}
export const isNumberWithinRange = (min, max) => number =>
  !isNil(number) && min <= parseInt(number) && parseInt(number) <= max

export function idAddressValid(address) {
  return !Object.values(address).some(f => f.length === 0)
}

export function isRecapDateValid(startDate, endDate) {
  return (
    startDate &&
    endDate &&
    new Date() > new Date(startDate) &&
    new Date() > new Date(endDate) &&
    new Date(startDate) <= new Date(endDate)
  )
}

export function reverseDate(startDate, endDate) {
  try {
    const [startYear, startMonth, startDay] = startDate.split('-')
    const startDateFormatted = `${startDay}-${startMonth}-${startYear}`
  
    const [endYear, endMonth, endDay] = endDate.split('-')
    const endDateFormatted = `${endDay}-${endMonth}-${endYear}` 

    return {startDateFormatted, endDateFormatted}
  } catch (error) {
    return {errorMessage: 'Entrez des dates valides'}
  }
}

export function formatDateToYYYYMMDD(date) {
  if(date && date.toLocaleDateString) {
    const [day, month, year] = date.toLocaleDateString().split('/')
    return (`${year}-${month}-${day}`)
  }
}