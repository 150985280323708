import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export default function PromoCodeSerch({isOptionEqualToValueKey = "id", getOptionLabelKey, type, datas = [], loading, getAsyncDatas, onChange, width = 393}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    setOptions([...datas]);
  }, [datas, open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    else {
      getAsyncDatas(type)
    }  
  }, [open]);

  return (
    <Autocomplete alignItems="center"
      id="asynchronous-demo"
      sx={{ width: width }}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?.[isOptionEqualToValueKey] === value?.[isOptionEqualToValueKey]}
      getOptionLabel={(option) => option?.[getOptionLabelKey]}
      options={options}
      loading={loading}
      value={value}
      onChange={(event, newValue, reason) => {
        if(reason !== "clear") {
          setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue)
        }
        else {
          setValue(null);
          onChange(null)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choisissez un restaurant"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {/* {params.InputProps.endAdornment} */}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
