import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { get } from 'lodash'

import ProductGeneralOption from 'components/ProductGeneralOption'
import ProductPersonalization from 'components/ProductPersonalization'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  left: { width: '550px', marginRight: '150px' },
  buttonBar: {
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonLoadingAnimation: {
    color: blue[500],
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  }
}

class ProductForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasImageChanged: false,
      haveOptions: false,
      haveSupplements: false,
      haveCustomCategory: true,

      //Information générale
      tva: 10,
      haveStock: true,

      //les options choisies
      optionIds: [],
      supplementIds: [],

      imagePath: '',
      //Information produit
      productInformation: {
        name: '',
        order: undefined,
        description: '',
        category: '', // catégorie du produit
        price: 0,
        image: '',
        header: '',  // catégorie personnalisée
        checkoutSystemId: undefined,
        children: (this.props.data?.children === undefined)?[]: this.props.data?.children
      }
      ,isMenu:false
    }
  }

  componentDidMount() {
    this.copyDataInState()
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.copyDataInState()
     
    }
  }

  copyDataInState = () => {
    const { productInformation } = this.state
    const {
      name = '',
      description = '',
      category = '',
      price = 0,
      image = '',
      header = '',
      checkoutSystemId = undefined,
      order = undefined,
      reference='',
      children = (this.props.data?.children === undefined)?[]: this.props.data?.children,
      isMenu=false,
      options = [],
      templateId=this.props.templates.templateid,
      optionIds,
      supplementIds,
      supplements = [],
      imagePath,
      tax = 10,
      haveStock = true,
      _id,
      id
    } = get(this.props, 'data', {})

    const optionsUpdated = options.map((option) => {
      const optionIdObj = optionIds.find((optionIdElem) => optionIdElem.optionId == option._id)
      return { ...option, csInfo: optionIdObj && optionIdObj.csInfo }
    })

    const supplementsUpdated = supplements.map((supplement) => {
      const supplementIdObj = supplementIds.find((supplementIdElem) => supplementIdElem.supplementId == supplement._id)
      return { ...supplement, maxNumber: supplementIdObj?.maxNumber, csInfo: supplementIdObj && supplementIdObj.csInfo }
    })

    this.setState({
      productId: _id ? _id : id,
      imagePath,
      productInformation: {
        ...productInformation,
        name,
        description,
        category,
        price,
        image,
        header,
        checkoutSystemId,
        children,  
        reference,
        order
      },
      isMenu,
      optionIds: optionsUpdated,
      templateId,
      supplementIds: supplementsUpdated,
      tva: tax,
      haveStock
    })
  }
  onDeleteCode = (chipToDelete) =>()=> {
    const {productInformation} = this.state
    const arr = productInformation.children.filter((e) => e.checkoutSystemId !== chipToDelete) 
    this.setState(prevState => {
      let productInformation = Object.assign({}, prevState.productInformation);  
      productInformation.children = arr;                   
      return { productInformation };                             
    })
  
  };
  
  handleChange = field => e => {
    const { value } = e.target
    const parsedValue = field === 'tva' ? Number(value) : value
    const fields = field.split('.')
    if (fields.length === 1) {
      this.setState({ [field]: parsedValue })
    } else {
      this.setState({
        [fields[0]]: { ...this.state[fields[0]], [fields[1]]: parsedValue }
      })
    }
  }

  handleSwitchChange = field => e => {
    const { checked } = e.target
    this.setState({ [field]: checked })
  }

  handleImageSelect = e => {
    const { files } = e.target
    const { productInformation } = this.state
    this.setState({
      hasImageChanged: true,
      productInformation: { ...productInformation, image: files[0] }
    })
  }

  handleRemoveImage = () => {
    const { productInformation } = this.state
    this.setState({ productInformation: { ...productInformation, image: undefined, removeImage: true } })
  }
 
  handleSubmitForm = () => {
    const { onSubmitForm } = this.props
    const {
      productInformation,
      optionIds,
      supplementIds,
      tva: tax,
      haveStock,
      isMenu,
      templateId,
      hasImageChanged
    } = this.state

    const {
      name,
      description,
      category,
      price,
      header,
      order,
      image,
      checkoutSystemId,
      children,
      removeImage,
      reference
    } = productInformation

    onSubmitForm({
      name,
      description,
      category,
      price,
      supplementIds,
      optionIds,
      header,
      tax,
      order,
      haveStock,
      image,
      checkoutSystemId: checkoutSystemId?.trim(),
      reference: reference?.trim(),
      children,
      isMenu,
      templateId,
      removeImage,
      order,
      hasImageChanged
    })
  }

  render() {
    const {
      classes,
      buttonLabel,
      onCancel,
      showLoading,
      mainTitle,
      currentRestaurant,
      templates
    } = this.props
    const {
      productId,
      tva,
      optionIds,
      supplementIds,
      haveSupplements,
      haveOptions,
      haveCustomCategory,
      haveStock,
      productInformation,
      imagePath,
      order,
      isMenu
    } = this.state
    return (
      <div>
        <Typography
          style={{ textAlign: 'center' }}
          variant="h2"
          component="h2"
          gutterBottom
        >
          {mainTitle}
        </Typography>
        <div className={classes.mainContainer}>
          <ProductGeneralOption
            className={classes.left}
            onChange={this.handleChange}
            onSwitchChange={this.handleSwitchChange}
            tva={tva} 
            supplementIds={supplementIds}
            onDeleteCode={this.onDeleteCode}
           
            optionIds={optionIds}
            haveOptions={haveOptions}
            haveStock={haveStock}
            haveSupplements={haveSupplements}
            haveCustomCategory={haveCustomCategory}
            currentRestaurant={currentRestaurant}
            templates={templates}
          />
          <ProductPersonalization
            currentRestaurant={currentRestaurant}
            templates={templates}
            productId={productId}
            imagePath={this.props?.data?.image?.url}
            onDeleteCode ={this.onDeleteCode}
            productInformation={productInformation}
            onChange={this.handleChange}
            onImageSelect={this.handleImageSelect}
            onImageRemove={this.handleRemoveImage}
            optionIds={optionIds}
            supplementIds={supplementIds}
            onSwitchChange={this.handleSwitchChange}
            isMenu={isMenu}
            
          />
        </div>
        <div className={classes.buttonBar}>
          <Button onClick={onCancel} variant="contained" color="default">
            Annuler
          </Button>
          <div className={classes.loadingButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={showLoading}
              onClick={this.handleSubmitForm}
            >
              {buttonLabel}
            </Button>
            {showLoading && (
              <CircularProgress
                size={30}
                className={classes.buttonLoadingAnimation}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurant:state.currentRestaurant.infos,
  templates:state.templates
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ProductForm)