import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'
import { selectors as currentRestaurantInfosSelectors } from 'core.modules/currentRestaurant/infos/reducer'
import {
  selectors as currentRestaurantSupplementGroupsSelectors
} from 'core.modules/currentRestaurant/supplementGroups/reducer'

import { Button, Typography, TextField } from '@material-ui/core'
import Snackbar from 'core.ui/Snackbar'
import { requestStates } from '../../core.utils/requestStates'
import { editRestaurantSupplementGroupRequested } from '../../core.modules/currentRestaurant/supplementGroups/reducer'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}

class SupplementGroupEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      maxSupplementNumber: null,
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidMount() {
    const { id, data } = this.props
    this.setState({
      name: data?.name,
      maxSupplementNumber: data?.maxSupplementNumber,
    })
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handleMaxSupplementNumber = e => {
    this.setState({ maxSupplementNumber: e.target.value })
  }

  handleEditSupplementGroup = () => {
    this.setState({ waitingForRequest: true })
    const { name, maxSupplementNumber } = this.state
    const { editSupplementGroup, id, currentRestaurantId } = this.props
    if (!name || name?.trim().length === 0) {
      alert('Nom invalide')
      return
    }
    if (maxSupplementNumber && isNaN(maxSupplementNumber)) {
      alert('Nombre max invalide')
      return
    }
    let supplementGroupInfos = { 
      name: name?.trim(),
      restaurantId: currentRestaurantId
    }
    if (maxSupplementNumber) {
      supplementGroupInfos = {
        ...supplementGroupInfos,
        maxSupplementNumber
      }
    }
    editSupplementGroup(id, supplementGroupInfos)
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const { name, maxSupplementNumber } = this.state
    return (
      <React.Fragment>
        <div style={styles.mainListContainer}>
          <Typography
          style={{ textAlign: 'center' }}
          variant="h5"
          component="h5"
          >
            Ajouter un groupe de supplement
          </Typography>
          <div style={styles.header}>
            <TextField
              label="Nom du groupe"
              value={name}
              onChange={this.handleNameChange}
              fullWidth
            />
          </div>
          <div style={styles.header}>
            <TextField
              label="Nombre de supplement max"
              type="number"
              value={maxSupplementNumber}
              onChange={this.handleMaxSupplementNumber}
              fullWidth
            />
          </div>
          <div style={styles.header}>
          <Button onClick={this.handleCancel} variant="contained" color="default">
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleEditSupplementGroup}
          >
            Modifier le groupe de supplement
          </Button>
          </div>
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={this.props.errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRestaurantSupplementGroups: currentRestaurantSupplementGroupsSelectors.getSupplementGroups(
    state
  ),
  currentRequestState: currentRestaurantSupplementGroupsSelectors.getRequestState(state),
  errorMessage: currentRestaurantSupplementGroupsSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  fetchAllRestaurants: () => {
    dispatch(fetchAllRestaurantsRequested())
  },
  editSupplementGroup: (supplementGroupId, supplementGroupInfos) => {
    dispatch(editRestaurantSupplementGroupRequested(supplementGroupId, supplementGroupInfos))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SupplementGroupEdit)
