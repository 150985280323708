import restaurants from 'test_utils/data/restaurantENG.json'
import orders from 'test_utils/data/orders.json'
import options from 'test_utils/data/options.json'
import supplements from 'test_utils/data/supplements.json'
import users from 'test_utils/data/users.json'
import riders from 'test_utils/data/riders.json'
import products from 'test_utils/data/products.json'

const categories = [
  {
    name: 'test',
    id: '5e8d8fb593f29b393984ef4b'
  },
  {
    name: 'Tictic',
    id: '5e8d900693f29b393984ef4c'
  },
  {
    name: 'Pizza',
    id: '5e8db21f93f29b393984ef4d'
  }
]

const cities = [
  {
    name: 'Valbelle',
    id: '5e8d8fb593f29b393984ef4b'
  },
  {
    name: 'Oyonnax',
    id: '5e8d8fb593f29b393984ef4c'
  },
  {
    name: 'Lyon',
    id: '5e8d8fb593f29b393984ef4d'
  }
]

const makeFactory = data => n => data.slice(0, n)

export const restaurantsFactory = makeFactory(restaurants)
export const ordersFactory = makeFactory(orders)
export const categoriesFactory = makeFactory(categories)
export const citiesFactory = makeFactory(cities)
export const optionsFactory = makeFactory(options)
export const supplementsFactory = makeFactory(supplements)
export const ridersFactory = makeFactory(riders)
export const usersFactory = makeFactory(users)
export const productsFactory = makeFactory(products)
