import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControlLabel,
  TextField,
  Typography,
  Switch as MuiSwitch,
  FormControl
} from '@material-ui/core'
import dayjs from 'dayjs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import 'date-fns'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DataGrid } from '@material-ui/data-grid'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers'
import Snackbar from 'core.ui/Snackbar'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import { requestStates } from '../../core.utils/requestStates'
import { formatDateToYYYYMMDD } from '../../core.utils/formValidation'
import { marketing, region, city } from 'core.services/api'
import Dialog from 'core.ui/Dialog'
import TabPanel from 'core.ui/TabPanel'
import PromoCodeForm from '../promoCodeForm'


import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'

import { Routes } from '../../core.utils/routes'

import {
	Checkbox
} from '@material-ui/core';

import moment from 'moment'
import List from 'core.ui/List'
import frLocale from 'date-fns/locale/fr'

import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  buttonContainer: {
    padding: '30px'
  },
  button: {
    textDecoration: 'none !important',
    marign: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold'
  },
  inputForm: {
    marginRight: '20px',
    marginLeft: '20px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px'
  },
  dateContainer: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  allCitiesContainer: {},
  validateStatsContainer: {
    margin: '10px'
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    width: '80%'
  },
  statsNbContainer: {
    margin: '10px',
    backgroundColor: '#212121'
  },
  statsTitle: {
    fontSize: 14,
    color: 'white'
  },
  statsValue: {
    fontSize: 35,
    textAlign: 'center'
  }
}

const ORDER_STATS_TYPES = {
  totalOrders: 'totalOrders',
  deliveryOrders: 'deliveryOrders',
  pickupOrders: 'pickupOrders',
  totalTurnover: 'totalTurnover',
  totalPromoCodeReductions: 'totalPromoCodeReductions',
  totalPromoCodesUsed: 'totalPromoCodesUsed',
  totalDealReductions: 'totalDealReductions',
  totalDealsUsed: 'totalDealsUsed',
  manuallyAssignedOrders: 'manuallyAssignedOrders',
  totalCommission: 'totalCommission',
  totalHTCommission: 'totalHTCommission',
  totalTVACommission: 'totalTVACommission',
  averageOrderDuration: 'averageOrderDuration',
  averageDeliveryDuration: 'averageDeliveryDuration',
  lessThan30: 'lessThan30',
  between30And40: 'between30And40',
  between40And50: 'between40And50',
  between60And90: 'between60And90',
  between90And120: 'between90And120',
  moreThan120: 'moreThan120',
  laterOrders:'laterOrders',
  priorityOrders:'priorityOrders',
  totalReductions:'totalReductions',
  averageLaterDuration:'averageLaterDuration'

}

class Marketing extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isAllUsers: false,
      selectedCity: null,
      selectedCities: [],
      notificationTitle: '',
      notificationBody: '',
      isNotificationPlanified: true,
      datePlanified: new Date(),
      waitingForRequest: false,
      openSnackBar: false,
      openSendNotificationDialog: false,
      errorMessage: null,
      snackBarState: null,
      tabValue: this.props.match.params.tabValue,
      selectedStartDate: null,
      selectedEndDate: null,
      isAllCities: false,
      orderStats: null,
      riderStats: null,
      userStats: null,
      isStatsLoading: false,
      typeNotification: 'new',
      notificationHistory: [],
      regions: [],
      cities: [],
      loadingNotificationHistory: false,
      loadingPlanifiedNotifications: false,
      isSubmitting: false,
      planifiedNotifications: [],
      byCity: true,
      byRegion: false,
      selectedFilters: [],
      searchPlanifiedNotifications: {
        startDate: dayjs(new Date()).startOf('month').toDate(),
        endDate:  dayjs(new Date()).endOf('month').toDate(),
        cities: "",
        regions: ""
      },
      openDeleteDialog: false,
      indexToDelete: null,
      currentPage: 1
    }
  }

  getSentTimezone = (selectedFilters, datePlanified, isNotificationPlanified) => {
    if (isNotificationPlanified && selectedFilters && selectedFilters.length) {
      return <div>
        {
          selectedFilters.map(cityElem => (
            <p>Heure d'envoi: { cityElem.timezone } { !isNotificationPlanified ? this.getTimeZoneHour(cityElem.timezone) : format(utcToZonedTime(datePlanified, cityElem.timezone), 'dd MMMM yyyy HH:mm')}</p>
          ))
        }
      </div>
    }
  }
  
  componentDidMount() {
    // if(this.state.byCity)
    // {
    //   this.fetchCities()
    // }
    // else {
    //   this.fetchRegions()
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.typeNotification !== this.state.typeNotification && this.state.typeNotification === 'history') {
      this.fetchNotificationHistory({targetType: this.state.byCity ? "by_city" : "by_region", page: this.state.currentPage});
    }

    if (this.state.typeNotification === 'history' && (prevState.byCity !== this.state.byCity)) {
      this.fetchNotificationHistory({targetType: this.state.byCity ? "by_city" : "by_region", page: this.state.currentPage});
    }

    if(this.state.currentPage !== prevState.currentPage) {
      this.fetchNotificationHistory({targetType: this.state.byCity ? "by_city" : "by_region", page: this.state.currentPage});
    }

    if(this.state.byCity && prevState.cities.length < 1) {
      this.fetchCities()
    }

    if(this.state.byRegion && prevState.regions.length < 1) {
      this.fetchRegions()
    }
  }

  handleAllUsersSwitchChange = (e) => {
    const { checked } = e.target
    console.log("checked ===>", checked)
    this.setState({ isAllUsers: checked })
  }

  handleAllCitiesSwitchChange = (e) => {
    const { checked } = e.target
    this.setState({ isAllCities: checked })
  }

  handleStartDateChange = (selectedStartDate) => {
    this.setState({ selectedStartDate })
  }

  handleEndDateChange = (selectedEndDate) => {
    this.setState({ selectedEndDate })
  }

  handleGetOrderStats = async () => {
    const { isAllCities, selectedCity, selectedStartDate, selectedEndDate } = this.state
    const startDate = formatDateToYYYYMMDD(selectedStartDate)
    const endDate = formatDateToYYYYMMDD(selectedEndDate)
    this.setState({ isStatsLoading: true })
    const cityId = !isAllCities ? selectedCity && selectedCity._id : undefined
    const orderStats = await marketing.getOrderStats(
      startDate,
      endDate,
      cityId
    )
    this.setState({
      orderStats,
      riderStats: null,
      userStats: null,
      isStatsLoading: false
    })
  }

  handleGetRiderStats = async () => {
    const { isAllCities, selectedCity, selectedStartDate, selectedEndDate } = this.state
    const startDate = formatDateToYYYYMMDD(selectedStartDate)
    const endDate = formatDateToYYYYMMDD(selectedEndDate)
    this.setState({ isStatsLoading: true })
    const cityId = !isAllCities ? selectedCity && selectedCity._id : undefined
    const riderStats = await marketing.getRiderStats(
      startDate,
      endDate,
      cityId
    )
    this.setState({
      orderStats: null,
      riderStats,
      userStats: null,
      isStatsLoading: false
    })
  }

  handleGetUserStats = async () => {
    const { isAllCities, selectedCity, selectedStartDate, selectedEndDate } = this.state
    const startDate = formatDateToYYYYMMDD(selectedStartDate)
    const endDate = formatDateToYYYYMMDD(selectedEndDate)
    this.setState({ isStatsLoading: true })
    const cityId = !isAllCities ? selectedCity && selectedCity._id : undefined
    const userStats = await marketing.getUserStats(
      startDate,
      endDate,
      cityId
    )
    this.setState({
      orderStats: null,
      riderStats: null,
      userStats,
      isStatsLoading: false
    })
  }

  handleGetOrderStatsExport = async () => {
    const { isAllCities, selectedCity, selectedStartDate, selectedEndDate } = this.state
    const startDate = formatDateToYYYYMMDD(selectedStartDate)
    const endDate = formatDateToYYYYMMDD(selectedEndDate)
    const cityId = !isAllCities ? selectedCity && selectedCity._id : undefined
    await marketing.getOrderStatsExport(
      startDate,
      endDate,
      cityId
    )
  }

  handleGetRiderStatsExport = async () => {
    const { isAllCities, selectedCity, selectedStartDate, selectedEndDate } = this.state
    const startDate = formatDateToYYYYMMDD(selectedStartDate)
    const endDate = formatDateToYYYYMMDD(selectedEndDate)
    const cityId = !isAllCities ? selectedCity && selectedCity._id : undefined
    await marketing.getRiderStatsExport(
      startDate,
      endDate,
      cityId
    )
  }

  handleGetUserStatsExport = async () => {
    const { isAllCities, selectedCity, selectedStartDate, selectedEndDate } = this.state
    const startDate = formatDateToYYYYMMDD(selectedStartDate)
    const endDate = formatDateToYYYYMMDD(selectedEndDate)
    const cityId = !isAllCities ? selectedCity && selectedCity._id : undefined
    await marketing.getUserStatsExport(
      startDate,
      endDate,
      cityId
    )
  }

  fetchNotificationHistory = async ({targetType, page}) => {
    try {
      this.setState({loadingNotificationHistory: true, notificationHistory: []})
      const data = await marketing.getNotificationHistory({targetType, page, limit: 20})

      this.setState({notificationHistory: data})
    } catch (error) {
      console.error("Erreur lors de l'appel API : ", error);
    } finally {
      this.setState({loadingNotificationHistory: false})
    }
  }

  fetchRegions = async () => {
    try {
      const data = await region.getRegions()

      this.setState({regions: data})
    } catch (error) {
      console.error("Erreur lors de l'appel API : ", error);
    }
  }

  fetchCities = async () => {
    try {
      const data = await city.getAll()

      this.setState({cities: data})
    } catch (error) {
      console.error("Erreur lors de l'appel API : ", error);
    }
  }

  handleCityChange = (e, value) => {
    this.setState({ selectedCity: value })
  }

  handleCitiesChange = (e, value) => {
    this.setState({ selectedCities: value })
  }

  handleNotificationTitleChange = (e) => {
    this.setState({ notificationTitle: e.target.value })
  }

  handleNotificationBodyChange = (e) => {
    this.setState({ notificationBody: e.target.value })
  }

  handleSendSearchPlanifiedNotifications = async () => {
    const {
      searchPlanifiedNotifications,
      byCity
    } = this.state

    const userId = localStorage.getItem('userId')
    const token = localStorage.getItem('token')
    const firstName = localStorage.getItem('firstName')
    const lastName = localStorage.getItem('lastName')
    

    try {
      this.setState({
        loadingPlanifiedNotifications: true
      })

      let data = null;

      if(byCity) {
        data = await marketing.searchPlanifiedNotifications({
          startDate: searchPlanifiedNotifications.startDate,
          endDate: searchPlanifiedNotifications.endDate,
          cityId: searchPlanifiedNotifications.cities ? searchPlanifiedNotifications.cities.id : undefined,
          token,
          userName: `${firstName} ${lastName}`,
          userId: userId
        })
      }
      else {
        data = await marketing.searchPlanifiedNotifications({
          startDate: searchPlanifiedNotifications.startDate,
          endDate: searchPlanifiedNotifications.endDate,
          regionId:  searchPlanifiedNotifications.regions ? searchPlanifiedNotifications.regions.id : undefined,
          token,
          userName: `${firstName} ${lastName}`,
          userId: userId
        })
      }

      if(data !== null) {
        this.setState({
          planifiedNotifications: data.response.notifications
        })
      }

    } catch (error) {
      console.error("Erreur lors de l'appel API : ", error);
    } finally {
      this.setState({
        loadingPlanifiedNotifications: false
      })
    }
  }

  confirmDelete = async () => {
		const { indexToDelete, planifiedNotifications, notificationHistory } = this.state;

    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('userId')

    try {
      await marketing.deleteNotification({
        notificationId: indexToDelete,
        token,
        userId: userId
      })

      this.setState({
        planifiedNotifications: planifiedNotifications.filter(not => not._id !== indexToDelete),
        notificationHistory: notificationHistory.filter(not => not._id !== indexToDelete)
      })

    } catch (error) {
      console.error("Erreur lors de l'appel API : ", error);
    } finally {
      this.closeDeleteDialog();
    }
	};

  handleSendNotification = async () => {
    const {
      isAllUsers,
      notificationTitle,
      notificationBody,
      isNotificationPlanified,
      datePlanified,
      selectedFilters,
      byCity,
    } = this.state

    const token = localStorage.getItem('token')
    const firstName = localStorage.getItem('firstName')
    const lastName = localStorage.getItem('lastName')
    const userId = localStorage.getItem('userId')

    if (
      (!isAllUsers && !selectedFilters?.length) ||
      !notificationTitle ||
      !notificationBody
    ) {
      return alert('Veuillez vérifier les informations')
    }

    const sentTimezone = (selectedFilters && selectedFilters.length === 1) ? selectedFilters[0].timezone : "Europe/Paris"
    const sentDate = isNotificationPlanified ? datePlanified : new Date()

    try {
      this.setState({isSubmitting: true})
      if(byCity) {
        const data = await marketing.createNotificationByCity({
          title: notificationTitle,
          body: notificationBody,
          sentDate,
          cityId: selectedFilters.map(filter => filter.id),
          cityName: selectedFilters.map(filter => filter.name),
          token,
          userName: `${firstName} ${lastName}`,
          userId: userId,
          timezone: sentTimezone
        })
      }
      else {
        const data = await marketing.createNotificationByRegion({
          title: notificationTitle,
          body: notificationBody,
          sentDate,
          regionId: selectedFilters.map(filter => filter._id),
          regionName: selectedFilters.map(filter => filter.name),
          token,
          userName: `${firstName} ${lastName}`,
          userId: userId,
          timezone: sentTimezone
        })
      }

      this.setState({
        openSnackBar: true,
        errorMessage: 'Notification envoyée avec succès',
        snackBarState: 'success',
        notificationTitle: '',
        notificationBody: '',
        isNotificationPlanified: false,
        datePlanified: new Date(),
        selectedFilters: [],
        isAllUsers: false
      })

    } catch (error) {
      console.error("Erreur lors de l'appel API : ", error);
    } finally {
      this.setState({isSubmitting: false})
    }
    
  }

  confirmSendNotification = () => {
    const {
      notificationBody,
      notificationTitle,
      isAllUsers,
      selectedCities
    } = this.state
    this.setState({ notificationBody: '', notificationTitle: '' })
    if (isAllUsers) {
      marketing
        .sendNotificationsToAllUsers(notificationBody, notificationTitle)
        .then(() =>
          this.setState({
            openSnackBar: true,
            errorMessage: 'Notifications envoyées avec succès',
            snackBarState: 'success',
            notificationTitle: '',
            notificationBody: ''
          })
        )
        .catch(() =>
          this.setState({
            openSnackBar: true,
            errorMessage: "Une erreur s'est produite",
            snackBarState: 'error'
          })
        )
      this.closeSendNotificationDialog()
      return
    }

    marketing
      .sendNotificationsToUsersByCity(
        selectedCities,
        notificationBody,
        notificationTitle
      )
      .then(() =>
        this.setState({
          openSnackBar: true,
          notificationTitle: '',
          notificationBody: '',
          errorMessage: 'Notifications envoyées avec succès',
          snackBarState: 'success'
        })
      )
      .catch(() =>
        this.setState({
          openSnackBar: true,
          errorMessage: "Une erreur s'est produite",
          snackBarState: 'error'
        })
      )
    this.closeSendNotificationDialog()
  }

  closeSendNotificationDialog = () => {
    this.setState({ openSendNotificationDialog: false })
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  handleChangeTab = (event, tabValue) => {
    this.props.history.push(`${Routes.MarketingRoutes}/tabs/${tabValue}`)
  }

  getTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  orderStatsToFr = (type) => {
    switch (type) {
      case ORDER_STATS_TYPES.totalOrders:
        return 'Total commandes'
      case ORDER_STATS_TYPES.deliveryOrders:
        return 'Commandes en livraison'
      case ORDER_STATS_TYPES.pickupOrders:
        return 'Commandes à emporter'
      case ORDER_STATS_TYPES.laterOrders:
        return 'Commandes plus tard'
        case ORDER_STATS_TYPES.priorityOrders:
        return 'Commandes prioritaires'
      case ORDER_STATS_TYPES.totalTurnover:
        return 'Total turnover'
      case ORDER_STATS_TYPES.totalReductions:
        return 'Total reduction'    
      case ORDER_STATS_TYPES.totalPromoCodeReductions:
        return 'Total réductions code promo'
      case ORDER_STATS_TYPES.totalPromoCodesUsed:
        return 'Total code promo utilisés'
      case ORDER_STATS_TYPES.totalDealReductions:
        return 'Total réductions offres'
      case ORDER_STATS_TYPES.totalDealsUsed:
        return 'Total offres utilisées'
      case ORDER_STATS_TYPES.manuallyAssignedOrders:
        return 'Commandes assignées manuellement'
      case ORDER_STATS_TYPES.totalCommission:
        return 'Total commission'
      case ORDER_STATS_TYPES.totalHTCommission:
        return 'Total HT commission'
      case ORDER_STATS_TYPES.totalTVACommission:
        return 'Total TVA commission'
      case ORDER_STATS_TYPES.averageOrderDuration:
        return 'Temps moyen commande'
      case ORDER_STATS_TYPES.averageDeliveryDuration:
        return 'Temps moyen livraison'
      case ORDER_STATS_TYPES.averageLaterDuration:
        return 'Temps moyen en plus tard'
      case ORDER_STATS_TYPES.lessThan30:
        return 'Moins de 30 minutes'
      case ORDER_STATS_TYPES.between30And40:
        return 'Entre 30 et 40 minutes'
      case ORDER_STATS_TYPES.between40And50:
        return 'Entre 40 et 50 minutes'
      case ORDER_STATS_TYPES.between60And90:
        return 'Entre 60 et 90 minutes'
      case ORDER_STATS_TYPES.between90And120:
        return 'Entre 90 et 120 minutes'
      case ORDER_STATS_TYPES.moreThan120:
        return 'Plus de 120 minutes'

      default:
        break
    }
  }

  renderCityAutoComplete = (isMultiple) => {
    const { allCities } = this.props
    const { isAllUsers, isAllCities } = this.state
    if (isAllUsers || isAllCities) return
    return (
      
      <Autocomplete
        multiple={isMultiple}
        id="combo-box-demo"
        options={allCities}
        getOptionLabel={(city) => city.name}
        style={{ width: 300 }}
        onChange={isMultiple ? this.handleCitiesChange : this.handleCityChange}
        renderInput={(params) => (
          <TextField {...params} label="Villes" variant="outlined" />
        )}
      />
      
    )
  }

  getTimeZoneDate = (timeZone, date = new Date()) => {
    // Convertir cette date en date UTC
    const utcDate = zonedTimeToUtc(date, timeZone);

    // Convertir cette date UTC en date en France
    const dateInFrance = utcToZonedTime(utcDate, 'Europe/Paris');

    return dateInFrance;
  }

  getTimeZoneHour = (timeZone, date = new Date()) => {
    const dateInTimeZone = utcToZonedTime(date, timeZone);

    return format(dateInTimeZone, 'HH:mm', { timeZone })
  }
  
  getUTCZonedate = (timeZone, date = new Date()) => {
    const dateUtc = zonedTimeToUtc(date, 'Europe/Paris');
    const convertDate = utcToZonedTime(dateUtc, timeZone);

    return convertDate
  }

  renderNotificationInfos = () => {
    const { notificationTitle, 
            notificationBody, 
            isNotificationPlanified, 
            datePlanified,
            selectedFilters
    } = this.state

    return (
      <div>
        <TextField
          label="Titre de la notification"
          value={notificationTitle}
          onChange={this.handleNotificationTitleChange}
          fullWidth
        />
        <TextField
          label="Contenu de la notification"
          value={notificationBody}
          onChange={this.handleNotificationBodyChange}
          fullWidth
          multiline
        />
        <FormControlLabel 
          checked={isNotificationPlanified} 
          onChange={(_, checked) => this.setState({isNotificationPlanified: checked})} 
          control={<Checkbox value={isNotificationPlanified} color="primary" />} 
          label="Souhaitez-vous planifier cette notification ?" 
        />

        { this.getSentTimezone(selectedFilters, datePlanified, isNotificationPlanified) }
        {isNotificationPlanified && <FormControl fullWidth>
          <DateTimePicker
            locale={frLocale}
            required
            ampm={false}
            minDate={new Date()}
            label="Jour et heure de notification"
            onChange={(date) => this.setState({datePlanified: date})}
            inputVariant="outlined"
            value={datePlanified}
            format="dd MMMM yyyy HH:mm"
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>}
      </div>
    )
  }

  renderSearchPlanifiedNotification = () => {
    const { searchPlanifiedNotifications, byCity, cities, regions} = this.state

    return (
      <Box display="flex" direction="column" my={2}>
        <Autocomplete
          multiple={false}
          options={byCity ? cities : regions}
          getOptionLabel={(item) => item.name}
          style={{ width: 300 }}
          onChange={(_, value) => {
            if(byCity) {
              this.setState({searchPlanifiedNotifications: {
                ...searchPlanifiedNotifications,
                cities: value
              }})
            }
            else {
              this.setState({searchPlanifiedNotifications: {
                ...searchPlanifiedNotifications,
                regions: value
              }})
            }
              
          }}
          value={byCity ? searchPlanifiedNotifications.cities : searchPlanifiedNotifications.regions}
          renderInput={(params) => (
            <TextField {...params} label={byCity ? "Villes" : "Régions"} variant="outlined" />
          )}
        />
        <FormControl>
          <DateTimePicker
            required
            ampm={false}
            maxDate={searchPlanifiedNotifications.endDate}
            label="Date et heure de début"
            onChange={(date) => this.setState({searchPlanifiedNotifications: {
              ...searchPlanifiedNotifications,
              startDate: date
            }})}
            inputVariant="outlined"
            value={searchPlanifiedNotifications.startDate}
            format="dd MMMM yyyy HH:mm"
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <FormControl>
          <DateTimePicker
            required
            ampm={false}
            minDate={searchPlanifiedNotifications.startDate}
            label="Date et heure de fin"
            onChange={(date) => this.setState({searchPlanifiedNotifications: {
              ...searchPlanifiedNotifications,
              endDate: date
            }})}
            inputVariant="outlined"
            value={searchPlanifiedNotifications.endDate}
            format="dd MMMM yyyy HH:mm"
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
      </Box>
    )
  }

  renderTabs = () => {
    const { classes } = this.props
    const { tabValue } = this.props.match.params

    return (
      <div className={classes.root}>
        {/* <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={this.handleChangeTab}
            aria-label="marketing tabs"
          >
            <Tab
              value="notifications"
              label="Notifications"
              {...this.getTabProps('notifications')}
            />
            <Tab value="stats" label="Stats" {...this.getTabProps('stats')} />
            <Tab value="promoCode" label="Code Promo" {...this.getTabProps('promoCode')} />
           
          </Tabs>
        </AppBar> */}
        <TabPanel value={tabValue} index={'notifications'}>
          {this.renderNotifications()}
        </TabPanel>
        <TabPanel value={tabValue} index={'stats'}>
          {this.renderStats()}
        </TabPanel>
        <TabPanel value={tabValue} index={'promoCode'}>
          {this.renderPromoCodeForm()}
        </TabPanel>
      </div>
    )
  }

  handleDelete = (id) => {
		this.setState({
			openDeleteDialog: true,
			indexToDelete: id
		});
	};

  closeDeleteDialog = () => {
		this.setState({
			openDeleteDialog: false,
			indexToDelete: null
		});
	};

  handlePageChange = page => {
    this.setState({currentPage: page})
  }

  renderNotifications = () => {
    const { isAllUsers, 
            typeNotification, 
            notificationHistory, 
            loadingNotificationHistory, 
            byCity, 
            byRegion, 
            cities, 
            regions, 
            selectedFilters,
            loadingPlanifiedNotifications,
            planifiedNotifications,
            openDeleteDialog, isSubmitting } = this.state

    const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

    return (
      <div style={styles.mainListContainer}>
        <div style={{display: "flex", justifyContent: !isAllUsers ? "space-between" : "center", alignItems: "center", width: "100%"}}>
          <ToggleButtonGroup
            value={typeNotification}
            exclusive
            aria-label="text alignment"
            onChange={(_, value) => {
              this.setState({
                typeNotification: value
              })
            }}
          >
            <ToggleButton value="new" aria-label="left aligned">
              <span>Nouvelle notification</span>
            </ToggleButton>
            <ToggleButton value={"planified"} aria-label="centered">
              <span>Notification programmée</span>
            </ToggleButton>
            <ToggleButton value={"history"} aria-label="right aligned">
              <span>Historique des notifications</span>
            </ToggleButton>
          </ToggleButtonGroup>


          <div>
            <label style={{marginRight: "1rem"}}>Filter par: </label>
            <FormControlLabel
              control={
                <MuiSwitch
                  color="primary"
                  size="small"
                  checked={byCity}
                  onChange={(_, checked) => {
                    this.setState({
                      byCity: checked,
                      byRegion: !checked,
                      selectedFilters: []
                    })
                  }}
                />
              }
              label="Villes
              "
            />

            <FormControlLabel
              control={
                <MuiSwitch
                  color="primary"
                  size="small"
                  checked={byRegion}
                  onChange={(_, checked) => {
                    this.setState({
                      byCity: !checked,
                      byRegion: checked,
                      selectedFilters: []
                    })
                  }}
                />
              }
              label="Régions"
            />
          </div>
        </div>

        {typeNotification === "new" && <>
          { permissions?.marketing?.notification?.sendToAll ? 
            <div>
              <FormControlLabel
                control={
                  <MuiSwitch
                    color="primary"
                    size="small"
                    checked={isAllUsers}
                    onChange={this.handleAllUsersSwitchChange}
                  />
                }
                label="Tous les utilisateurs"
              />
            </div>
            : ''
          }

          {/* <br />

          <div>
            <FormControlLabel
              control={
                <MuiSwitch
                  color="primary"
                  size="small"
                  checked={isAllUsers}
                  onChange={this.handleAllUsersSwitchChange}
                />
              }
              label="Tous les utilisateurs"
            />
          </div> */}

          <Typography style={styles.header}>
            <b>{isAllUsers 
              ? `Envoyer des notifications à tous les utilisateurs`
              : `Envoyer des notifications aux utilisateurs par ${byCity ? "ville" : "région"}`}</b>
          </Typography>
            
          {!isAllUsers && <div style={{display: "flex", gap: "1rem"}}>
            {/* {this.renderCityAutoComplete(true)} */}
            <Autocomplete
              multiple={true}
              options={byCity ? cities : regions}
              getOptionLabel={(item) => item.name}
              style={{ width: 300 }}
              onChange={(_, value) => {
                  this.setState({selectedFilters: value})
              }}
              value={selectedFilters}
              renderInput={(params) => (
                <TextField {...params} label={byCity ? "Villes" : "Régions"} variant="outlined" />
              )}
            />
            {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Tous les utilisateurs" /> */}
          </div>}

          {this.renderNotificationInfos()}
          <br />

          

          {/* <DateTimePicker label="Date et heure" /> */}

          <div style={styles.buttonContainer}>
            <Button
              onClick={this.handleCancel}
              variant="contained"
              color="default"
              style={{ marginRight: '10px' }}
              disabled={isSubmitting}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleSendNotification}
              disabled={isSubmitting}
            >
              Envoyer les notifications
            </Button>
          </div>
          <p> Pour ajouter des émojis dans les notifications copier coller l'emoji depuis ce site <a href='https://emojipedia.org/party-popper/' target="_blank"> <strong> ici </strong> </a> uniquement </p>
        </>}

        {typeNotification === "planified" && <>
            {this.renderSearchPlanifiedNotification()}
            <div style={styles.buttonContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleSendSearchPlanifiedNotifications}
              >
                Rechercher des notifications programmées
              </Button>
            </div>
            <br /><br />
            <List
              list={planifiedNotifications.map((not) => [
                not.title,
                not.body,
                not.regionName ? not.regionName.join(",") : not.cityName ? not.cityName.join(",") : "?",
                format(not["Created Date"], 'dd MMMM yyyy HH:mm', { locale: frLocale }),
                format(this.getUTCZonedate(not["timezone"], not["sentDate"]), 'dd MMMM yyyy HH:mm', { locale: frLocale }),
                not.userName
              ])}
              columns={[
                'Titre',
                'Contenu',
                'Cible',
                'Date de création',
                "Date d'envoie",
                'Nom du créateur'
              ]} 
              ids={planifiedNotifications.map((not) => not._id)}
              actions={{
                //handleView: (id) => props.history.push(`/livreurs-tracking/${id}`),
                handleDelete: this.handleDelete,
                //handleEdit: this.handleEdit
              }}
              numberPerPage={20}
              emptyMessage={loadingPlanifiedNotifications ? "Chargement..." : "Aucune notification pour le moment"}
            />
        </>}

        {typeNotification === "history" && <>
            <List
              list={notificationHistory.map((not) => [
                not.notificationTitle,
                not.notificationBody,
                not.target?.value?.length > 0 ? not.target?.value.map(value => value?.name).join(",") : "Tous les utilisateurs",
                moment(not.created_at)
                .locale('fr')
                .format('Do MMMM YYYY HH:mm ')
              ])}
              columns={[
                'Titre',
                'Contenu',
                'Cible',
                "Date d'envoie",
              ]} 
              ids={notificationHistory.map((not) => not._id)}
              actions={{
                //handleView: (id) => props.history.push(`/livreurs-tracking/${id}`),
                handleDelete: this.handleDelete,
                //handleEdit: this.handleEdit
              }}
              customPage={this.state.currentPage}
              onPageChange={this.handlePageChange}
              numberPerPage={20}
              emptyMessage={loadingNotificationHistory ? "Chargement..." : "Aucune notification pour le moment"}
            />
        </>}

        <Dialog
          open={openDeleteDialog}
          dialogText="Confirmer la suppression de la notification"
          dialogTitle="Confirmation"
          cancelButtonCaption="Annuler"
          confirmButtonCaption="Ok"
          onCancel={this.closeDeleteDialog}
          onValidation={this.confirmDelete}
        />

        
      </div>
    )
  }

  renderStats = () => {
    const { classes } = this.props
    const { isStatsLoading } = this.state
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="center" direction="column" alignItems="center">
          <div className={classes.row}>{this.renderDatesPicker()}</div>
          {this.renderAllCitiesSwitch()}
          {this.renderCityAutoComplete(false)}
          <div className={classes.row}>
            {this.renderOrderStatsButton()}
            {this.renderRiderStatsButton()}
            {this.renderUserStatsButton()}
          </div>
          {isStatsLoading && <CircularProgress color="primary" />}
          {!isStatsLoading && (
            <Grid
              className={classes.statsContainer}
              container
              justify="center"
              alignItems="center"
            >
              {this.renderOrderStats()}
              {this.renderRiderStats()}
              {this.renderUserStats()}
            </Grid>
          )}
          <div className={classes.row}>
            {this.renderOrderStatsExportButton()}
            {this.renderRiderStatsExportButton()}
            {this.renderUserStatsExportButton()}
          </div>
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }

  renderPromoCodeForm = () => {
    return (
      <PromoCodeForm {...this.props} />
    )
  }


  renderDatesPicker = () => {
    const { classes } = this.props
    const { selectedStartDate, selectedEndDate } = this.state
    return (
      <>
        <div className={classes.dateContainer}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="De"
            format="dd/MM/yyyy"
            value={selectedStartDate}
            onChange={this.handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </div>
        <div className={classes.dateContainer}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="À"
            format="dd/MM/yyyy"
            value={selectedEndDate}
            onChange={this.handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </div>
      </>
    )
  }

  renderAllCitiesSwitch = () => {
    const { isAllCities } = this.state
    return (
      <div style={{ marginBottom: '10px' }}>
        <FormControlLabel
          control={
            <MuiSwitch
              color="primary"
              size="small"
              checked={isAllCities}
              onChange={this.handleAllCitiesSwitchChange}
            />
          }
          label="Toutes les villes"
        />
      </div>
    )
  }

  renderOrderStatsButton = () => {
    const {
      isAllCities,
      isStatsLoading,
      selectedStartDate,
      selectedEndDate,
      selectedCity
    } = this.state
    const { classes } = this.props
    const isDate = !!selectedStartDate && !!selectedEndDate
    const isCity = isAllCities || selectedCity
    return (
      <div className={classes.validateStatsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleGetOrderStats}
          disabled={!isDate || !isCity || isStatsLoading}
        >
          Stats Commandes
        </Button>
      </div>
    )
  }

  renderRiderStatsButton = () => {
    const {
      isAllCities,
      isStatsLoading,
      selectedStartDate,
      selectedEndDate,
      selectedCity
    } = this.state
    const { classes } = this.props
    const isDate = !!selectedStartDate && !!selectedEndDate
    const isCity = isAllCities || selectedCity
    return (
      <div className={classes.validateStatsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleGetRiderStats}
          disabled={!isDate || !isCity || isStatsLoading}
        >
          Stats Livreurs
        </Button>
      </div>
    )
  }

  renderUserStatsButton = () => {
    const {
      isAllCities,
      isStatsLoading,
      selectedStartDate,
      selectedEndDate,
      selectedCity
    } = this.state
    const { classes } = this.props
    const isDate = !!selectedStartDate && !!selectedEndDate
    const isCity = isAllCities || selectedCity
    return (
      <div className={classes.validateStatsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleGetUserStats}
          disabled={!isDate || !isCity || isStatsLoading}
        >
          Stats Utilisateurs
        </Button>
      </div>
    )
  }

  renderOrderStats = () => {
    const { classes } = this.props
    const { orderStats } = this.state
    const stats = []

    if (!orderStats) {
      return null
    }

    for (const stat in orderStats) {
      if (orderStats.hasOwnProperty(stat)) {
        const label = this.orderStatsToFr(stat)
        const value = orderStats[stat]
        stats.push({ label, value })
      }
    }

    return stats.map((stat, index) => {
      return (
        <Card key={index} className={classes.statsNbContainer}>
          <CardContent>
            <Typography
              className={classes.statsTitle}
              color="secondary"
              gutterBottom
            >
              {stat.label}
            </Typography>
            <Typography
              className={classes.statsValue}
              color="primary"
              variant="h2"
              component="h2"
            >
              {stat.value}
            </Typography>
          </CardContent>
        </Card>
      )
    })
  }
  renderRiderStats = () => {
    const { classes } = this.props
    const { riderStats } = this.state
    const columnWidth = (window.innerWidth * 0.8) / 5

    const columns = [
      { field: 'rider', headerName: 'Livreur', width: columnWidth },
      {
        field: 'totalOrders',
        headerName: 'Total commandes',
        width: columnWidth
      },
      {
        field: 'totalTurnover',
        headerName: 'Total turnover',
        width: columnWidth
      },
      {
        field: 'percentageOfTotalOrders',
        headerName: 'Pourcentages total Commandes',
        width: columnWidth
      },
      {
        field: 'percentageOfTotalTurnover',
        headerName: 'Pourcentages total turnover',
        width: columnWidth
      }
    ]

    if (!riderStats) {
      return null
    }
    return (
      <>
        <div className={classes.row}>
          {!!riderStats.total?.totalOrders && (
            <Card key={0} className={classes.statsNbContainer}>
              <CardContent>
                <Typography
                  className={classes.statsTitle}
                  color="secondary"
                  gutterBottom
                >
                  Total commandes
                </Typography>
                <Typography
                  className={classes.statsValue}
                  color="primary"
                  variant="h2"
                  component="h2"
                >
                  {riderStats.total?.totalOrders}
                </Typography>
              </CardContent>
            </Card>
          )}
          {!!riderStats.total?.totalTurnover && (
            <Card key={1} className={classes.statsNbContainer}>
              <CardContent>
                <Typography
                  className={classes.statsTitle}
                  color="secondary"
                  gutterBottom
                >
                  Total turnover
                </Typography>
                <Typography
                  className={classes.statsValue}
                  color="primary"
                  variant="h2"
                  component="h2"
                >
                  {riderStats.total?.totalTurnover}
                </Typography>
              </CardContent>
            </Card>
          )}
        </div>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid rows={riderStats.stats} columns={columns} />
        </div>
      </>
    )
  }
  renderUserStats = () => {
    const { classes } = this.props
    const { userStats } = this.state
    const columnWidth = (window.innerWidth * 0.8) / 2
    const columns = [
      { field: '_id', headerName: 'Version', width: columnWidth },
      {
        field: 'userCount',
        headerName: 'Total utilisateurs',
        width: columnWidth
      }
    ]

    if (!userStats) {
      return null
    }

    const enhancedArray = userStats.countByGroup?.map((el) => ({
      ...el,
      id: el._id
    }))

    return (
      <>
        <div className={classes.row}>
          {userStats.userCount != null && (
            <Card key={0} className={classes.statsNbContainer}>
              <CardContent>
                <Typography
                  className={classes.statsTitle}
                  color="secondary"
                  gutterBottom
                >
                  Total utilisateurs
                </Typography>
                <Typography
                  className={classes.statsValue}
                  color="primary"
                  variant="h2"
                  component="h2"
                >
                  {userStats.userCount}
                </Typography>
              </CardContent>
            </Card>
          )}
          {userStats.newUsersCount != null && (
            <Card key={1} className={classes.statsNbContainer}>
              <CardContent>
                <Typography
                  className={classes.statsTitle}
                  color="secondary"
                  gutterBottom
                >
                  Total nouveaux utilisateurs
                </Typography>
                <Typography
                  className={classes.statsValue}
                  color="primary"
                  variant="h2"
                  component="h2"
                >
                  {userStats.newUsersCount}
                </Typography>
              </CardContent>
            </Card>
          )}
          {userStats.activeUsersCount != null && (
            <Card key={1} className={classes.statsNbContainer}>
              <CardContent>
                <Typography
                  className={classes.statsTitle}
                  color="secondary"
                  gutterBottom
                >
                  Nouveaux clients
                </Typography>
                <Typography
                  className={classes.statsValue}
                  color="primary"
                  variant="h2"
                  component="h2"
                >
                  {userStats.activeUsersCount}
                </Typography>
              </CardContent>
            </Card>
          )}
        </div>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={enhancedArray} columns={columns} />
        </div>
      </>
    )
  }

  renderOrderStatsExportButton = () => {
    const {
      isAllCities,
      isStatsLoading,
      selectedStartDate,
      selectedEndDate,
      selectedCity
    } = this.state
    const { classes } = this.props
    const isDate = !!selectedStartDate && !!selectedEndDate
    const isCity = isAllCities || selectedCity
    return (
      <div className={classes.validateStatsContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleGetOrderStatsExport}
          disabled={!isDate || !isCity || isStatsLoading}
        >
          Export Stats Commandes
        </Button>
      </div>
    )
  }

  renderRiderStatsExportButton = () => {
    const {
      isAllCities,
      isStatsLoading,
      selectedStartDate,
      selectedEndDate,
      selectedCity
    } = this.state
    const { classes } = this.props
    const isDate = !!selectedStartDate && !!selectedEndDate
    const isCity = isAllCities || selectedCity
    return (
      <div className={classes.validateStatsContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleGetRiderStatsExport}
          disabled={!isDate || !isCity || isStatsLoading}
        >
          Export Stats Livreurs
        </Button>
      </div>
    )
  }

  renderUserStatsExportButton = () => {
    const {
      isAllCities,
      isStatsLoading,
      selectedStartDate,
      selectedEndDate,
      selectedCity
    } = this.state
    const { classes } = this.props
    const isDate = !!selectedStartDate && !!selectedEndDate
    const isCity = isAllCities || selectedCity
    return (
      <div className={classes.validateStatsContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleGetUserStatsExport}
          disabled={!isDate || !isCity || isStatsLoading}
        >
          Export Stats Utilisateurs
        </Button>
      </div>
    )
  }

  render() {
    const {
      isAllUsers,
      openSnackBar,
      errorMessage,
      snackBarState,
      openSendNotificationDialog,
      notificationBody,
      notificationTitle
    } = this.state
    return (
      <>
        {this.renderTabs()}
        <Dialog
          open={openSendNotificationDialog}
          dialogText="Confirmer l'envoie des notifications"
          dialogTitle="Confirmation"
          cancelButtonCaption="Annuler"
          confirmButtonCaption="Ok"
          onCancel={this.closeSendNotificationDialog}
          onValidation={this.confirmSendNotification}
        />
        <Snackbar
          open={openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity={snackBarState}
          message={errorMessage}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allCities: citiesSelectors.getAllCities(state)
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Marketing)