import { findIndex } from 'lodash'

import { supplement } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from '../../../core.utils/requestStates'
import { parseErrorMessage } from '../../../core.utils/requestActions'
import { template } from '../../../core.services/api'

export const {
  FETCH_RESTAURANT_SUPPLEMENT_REQUESTED,
  FETCH_RESTAURANT_SUPPLEMENT_SUCCEEDED,
  FETCH_RESTAURANT_SUPPLEMENT_FAILED,
  fetchRestaurantSupplementSucceeded,
  fetchRestaurantSupplementFailed,
  fetchRestaurantSupplementStateReducer
} = createRequestActions('FETCH_RESTAURANT_SUPPLEMENT')

export const fetchRestaurantSupplementRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_SUPPLEMENT_REQUESTED,
    data: {
      restaurantId: id
    }
  })
  supplement
    .getRestaurantSupplements(id)
    .then(data => {
      dispatch(fetchRestaurantSupplementSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantSupplementFailed(error))
    })
}
export const fetchTemplateSupplementRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_SUPPLEMENT_REQUESTED,
    data: {
      templateId: id
    }
  })
  template
    .getSupplementsBytemplateId(id)
    .then(data => {
      dispatch(fetchRestaurantSupplementSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantSupplementFailed(error))
    })
}

export const {
  CREATE_RESTAURANT_SUPPLEMENT_REQUESTED,
  CREATE_RESTAURANT_SUPPLEMENT_SUCCEEDED,
  CREATE_RESTAURANT_SUPPLEMENT_FAILED,
  createRestaurantSupplementSucceeded,
  createRestaurantSupplementFailed,
  createRestaurantSupplementStateReducer
} = createRequestActions('CREATE_RESTAURANT_SUPPLEMENT')

export const createRestaurantSupplementRequested = (
  restaurantId,
  supplementInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_SUPPLEMENT_REQUESTED,
    data: {
      restaurantId,
      supplement: { ...supplementInfos }
    }
  })
  supplement
    .add(restaurantId, supplementInfos)
    .then(data => {
      dispatch(createRestaurantSupplementSucceeded(data))
    })
    .catch(error => {
      dispatch(createRestaurantSupplementFailed(error))
    })
}
export const createTemplateSupplementRequested = (
  templateId,
  supplementInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_SUPPLEMENT_REQUESTED,
    data: {
      templateId,
      supplement: { ...supplementInfos }
    }
  })
  template
    .addSupplements(templateId, supplementInfos)
    .then(data => {
      dispatch(createRestaurantSupplementSucceeded(data))
    })
    .catch(error => {
      dispatch(createRestaurantSupplementFailed(error))
    })
}

export const {
  ASSIGN_SUPPLEMENT_REQUESTED,
  ASSIGN_SUPPLEMENT_SUCCEEDED,
  ASSIGN_SUPPLEMENT_FAILED,
  assignSupplementSucceeded,
  assignSupplementFailed,
  assignSupplementStateReducer
} = createRequestActions('ASSIGN_SUPPLEMENT')

export const assignSupplementRequested = (
  supplementId,
  body
) => dispatch => {
  dispatch({
    type: ASSIGN_SUPPLEMENT_REQUESTED,
    data: {
      supplementId,
      body
    }
  })
  supplement
    .assignToGroup(supplementId, body)
    .then(data => {
      dispatch(assignSupplementSucceeded(data))
    })
    .catch(error => {
      dispatch(assignSupplementFailed(error))
    })
}

export const {
  DELETE_RESTAURANT_SUPPLEMENT_REQUESTED,
  DELETE_RESTAURANT_SUPPLEMENT_SUCCEEDED,
  DELETE_RESTAURANT_SUPPLEMENT_FAILED,
  deleteRestaurantSupplementSucceeded,
  deleteRestaurantSupplementFailed,
  deleteRestaurantSupplementRequestStateReducer
} = createRequestActions('DELETE_RESTAURANT_SUPPLEMENT')

export const deleteRestaurantSupplementRequested = (
  restaurantId,
  supplementId
) => dispatch => {
  dispatch({
    type: DELETE_RESTAURANT_SUPPLEMENT_REQUESTED,
    data: {
      restaurantId,
      supplementId
    }
  })
  supplement
    .confirmDelete(restaurantId, supplementId)
    .then(data => {
      dispatch(
        deleteRestaurantSupplementSucceeded({
          restaurantId,
          supplementId
        })
      )
    })
    .catch(error => {
      dispatch(deleteRestaurantSupplementFailed(error))
    })
}

export const defaultState = { supplements: [], requestState: '' ,}
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_RESTAURANT_SUPPLEMENT_REQUESTED:
    case CREATE_RESTAURANT_SUPPLEMENT_REQUESTED:
    case ASSIGN_SUPPLEMENT_REQUESTED:
    case DELETE_RESTAURANT_SUPPLEMENT_REQUESTED: {
      return { ...state, requestState: requestStates.PENDING }
    }
    case FETCH_RESTAURANT_SUPPLEMENT_SUCCEEDED: {
      const { supplements } = action.payload.data
      return {
        ...state,
        supplements: [...supplements],
        requestState: requestStates.SUCCESS,
        
      }
    }
    case CREATE_RESTAURANT_SUPPLEMENT_SUCCEEDED: {
      const supplement = action.payload.data
      return {
        ...state,
        supplements: [...state.supplements, { ...supplement, _id: supplement?.id || supplement?._id }],
        requestState: requestStates.SUCCESS,
        
      }
    }
    case DELETE_RESTAURANT_SUPPLEMENT_SUCCEEDED:
      const { supplementId } = action.payload.data
      const index = findIndex(state.supplements, s => s._id === supplementId)
      return {
        ...state,
        supplements: [
          ...state.supplements.slice(0, index), ...state.supplements.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS,
      }
    case ASSIGN_SUPPLEMENT_SUCCEEDED:
      const supplementInfo = action.payload.data
      const supplementIndex = state.supplements.findIndex((supplement) => {
        return supplement.id === supplementInfo.id || supplement._id === supplementInfo.id
      })
      return {
        ...state,
        supplements: [
        ...state.supplements.slice(0, supplementIndex),
        { ...state.supplements[supplementIndex], ...supplementInfo },
        ...state.supplements.slice(supplementIndex + 1)
        ],
        requestState: requestStates.SUCCESS,
      }
      case CREATE_RESTAURANT_SUPPLEMENT_FAILED:
        return {
          ...state,
          requestState: requestStates.FAILED,
          errorMessage: parseErrorMessage(action)
        }
    case FETCH_RESTAURANT_SUPPLEMENT_FAILED:
    case ASSIGN_SUPPLEMENT_FAILED:
      return { 
        ...state, 
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    default:
      return state
  }
}

const getState = state => state.currentRestaurant.supplements
const getSupplements = state => getState(state).supplements
const getRequestState = state => getState(state).requestState
const getErrorMessage = state => getState(state).errorMessage

export const selectors = {
  getState,
  getSupplements,
  getRequestState,
  getErrorMessage
}
