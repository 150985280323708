export const GET_ALL_CLAIMS='GET_ALL_CLAIMS'
export const ACCEPTCLAIM ='ACCEPTCLAIM'
export const DECLINE_CLAIM='DECLINE_CLAIM'
export const CLAIM_ERROR='CLAIM_ERROR'
export const SINGLE_CLAIM='SINGLE_CLAIM'
export const CLAIMS_ORDER_GROUP='CLAIMS_ORDER_GROUP'
export const ORDER_CLAIM='ORDER_CLAIM'
export const BACK_TO_PREVIEW_PAGE='BACK_TO_PREVIEW_PAGE'
export const GET_CLAIMED_ORDER='GET_CLAIMED_ORDER'
export const ADD_CLAIM='ADD_CLAIM'
export const RESPOND_CLAIM='RESPOND_CLAIM'
export const GET_CONFIG='GET_CONFIG'
export const RESET_ERROR='RESET_ERROR'
export const CLAIM_PAGINATION='CLAIM_PAGINATION'