import React from 'react';

export default function OrderNoteTile({note}) {
    if (!note || !note?.adminId) {
        return <div></div>
    }

    const  prettyDate2 = (time) =>{
        var date = new Date(parseInt(time));
        return date.toLocaleTimeString("fr", {
            hour: '2-digit',
            minute:'2-digit'
        });
    }

    if (note?.note === "-") {
        return <p style={{ margin:0}}>Vu {!!note?.date && `le ${prettyDate2(new Date(note?.date).getTime())}`} par {!!note.adminFirstName ? note.adminFirstName[0].toUpperCase() : ""}.{!!note.adminLastName ? note.adminLastName[0].toUpperCase() : ""}.</p>
    }

  return (
    <div>
        <p style={{ marginBottom:0, marginTop:3}}>{note?.note}</p>
        <p style={{ margin:0}}>{!!note?.date && `À ${prettyDate2(new Date(note?.date).getTime())}`} de {!!note.adminFirstName ? note.adminFirstName[0].toUpperCase() : ""}.{!!note.adminLastName ? note.adminLastName[0].toUpperCase() : ""}.</p>
    </div>
  );
}
