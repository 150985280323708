import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import Dialog from 'core.ui/Dialog'
import { selectors as requestsSelectors } from 'core.modules/requests/reducer'
import List from 'core.ui/List'
import Select from 'core.ui/Select'
import TextField from 'core.ui/TextField'
import MatUiTextField from '@material-ui/core/TextField';
import { makeDateString } from 'core.utils/parser/time'

import { fetchRiderRequestsRequested, deleteRiderRequestRequested, editRiderRequestRequested, fetchRequestCityRequested } from '../../core.modules/requests/reducer'
import { parseRiderRequestRole, riderRequestStatus, getTranslatedStatus, statusColorMapping, statusFontColorMapping } from '../../core.utils/parser/riderRequest'
import { Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const styles = {
  list: {
    width: '1400px'
  },
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  },
  topBarControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}


class RiderRequestsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      idToDelete: null,
      currentPage: 1,
      numberPerPage: 20,
      requestCity: null,
      previousRequestCity: null,
    }
  }

  componentDidMount() {
    const { fetchRequestCities } = this.props
    fetchRequestCities()
    this.setState({requestCity: 'All'}, () => {
      this.getRiderRequests()
    })
  }

  componentDidUpdate() {
    const { previousRequestCity, requestCity } = this.state
    if (requestCity !== previousRequestCity) {
      this.getRiderRequests()
      this.setState({previousRequestCity: requestCity})
    }
  }

  handleStatusChange = (riderRequestIndex) => (e) => {
    const { allRiderRequests, editRiderRequest } = this.props
    const { value: status } = e.target
    const riderRequestId = allRiderRequests[riderRequestIndex].id || allRiderRequests[riderRequestIndex]._id
    const riderRequestInfos = {...allRiderRequests[riderRequestIndex], status}
    editRiderRequest(riderRequestId, riderRequestInfos)
  }

  handleRegisterChange = (riderRequestIndex) => (register) => {
    const { allRiderRequests, editRiderRequest } = this.props
    const riderRequestId = allRiderRequests[riderRequestIndex].id || allRiderRequests[riderRequestIndex]._id
    const riderRequestInfos = {...allRiderRequests[riderRequestIndex], register}
    editRiderRequest(riderRequestId, riderRequestInfos)
  }

  handleContractChange = (riderRequestIndex) => (contract) => {
    const { allRiderRequests, editRiderRequest } = this.props
    const riderRequestId = allRiderRequests[riderRequestIndex].id || allRiderRequests[riderRequestIndex]._id
    const riderRequestInfos = {...allRiderRequests[riderRequestIndex], contract}
    editRiderRequest(riderRequestId, riderRequestInfos)
  }

  handleBagChange = (riderRequestIndex) => (bag) => {
    const { allRiderRequests, editRiderRequest } = this.props
    const riderRequestId = allRiderRequests[riderRequestIndex].id || allRiderRequests[riderRequestIndex]._id
    const riderRequestInfos = {...allRiderRequests[riderRequestIndex], bag}
    editRiderRequest(riderRequestId, riderRequestInfos)
  }

  handleNoteChange = (riderRequestIndex) => (e) => {
    const { allRiderRequests, editRiderRequest } = this.props
    const { value: note } = e.target
    const riderRequestId = allRiderRequests[riderRequestIndex].id || allRiderRequests[riderRequestIndex]._id
    const riderRequestInfos = {...allRiderRequests[riderRequestIndex], note}
    editRiderRequest(riderRequestId, riderRequestInfos)
  }

  makeOrderNoteTextfield = (note = '', riderRequestIndex) => {
    return (
    <TextField
      className={this.props.classes.noteTextField}
      value={note}
      onChangeComplete={this.handleNoteChange(riderRequestIndex)}
    />
  )}

  handleTextFieldChange = (field, riderRequestIndex) => (e) => {
    const { allRiderRequests, editRiderRequest } = this.props    
    const riderRequestId = allRiderRequests[riderRequestIndex].id || allRiderRequests[riderRequestIndex]._id
    const riderRequestInfos = {...allRiderRequests[riderRequestIndex], [field]: e.target?.value}
    editRiderRequest(riderRequestId, riderRequestInfos)
  }

  makeTextfield = (value = '', field, handleChange, riderRequestIndex) => {
    return (
    <TextField
      className={this.props.classes.noteTextField}
      style={{width:200}}
      value={value}
      onChangeComplete={handleChange(field, riderRequestIndex)}
    />
  )}

  makeRows = (riderRequests, riderRequestIndex) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      city,
      transport,
      status,
      register,
      contract,
      bag,
      note,
      created_at,
      updated_at,
      submittedCity,
      legalStatus = "",
      siret = "",
      address = ""
    } = riderRequests

    return [ 
        lastName + " " + firstName,
        email,
        phoneNumber,
        makeDateString(created_at),
        makeDateString(updated_at),
        city?.name || 'Ville Supprimée',
        submittedCity,
        parseRiderRequestRole(transport),
        this.displayCheckBox(register, this.handleRegisterChange(riderRequestIndex)),
        this.displayCheckBox(contract, this.handleContractChange(riderRequestIndex)),
        this.displayCheckBox(bag, this.handleBagChange(riderRequestIndex)),
        this.makeStatusSelect(status, riderRequestIndex),
        this.makeTextfield(note, "note", this.handleTextFieldChange, riderRequestIndex),
      ]
  }

  makeStatusSelect = (status = '', orderIndex) => {
    const statuses = Object.values(riderRequestStatus)
    return (
      <Select
        style={{ backgroundColor: statusColorMapping[status] }}
        value={status}
        options={statuses}
        disabled={['']}
        captions={statuses.map((status) => getTranslatedStatus(status))}
        autoFill={false}
        onChange={this.handleStatusChange(orderIndex)}
        colors={statusFontColorMapping}
      />
    )
  }

  displayCheckBox = (value = false, fct) => {
    return (
      <Checkbox
        checked={value}
        onChange={() => {
          fct(!value)
        }}
      />
    )
  }

  getRiderRequests = () => {
    const { currentPage, numberPerPage, requestCity } = this.state
    let requestCityValue = requestCity
    if (requestCity === 'All') {
      requestCityValue = null
    }
    this.props.fetchRiderRequests(currentPage, numberPerPage, requestCityValue);
  }

  handlePageChange = page => {
    this.setState({ currentPage: page }, () => {
      this.getRiderRequests()
    })
  }

  handleRequestCityChange = e => {
    const { requestCity } = this.state
    let value = e.target.value
    this.setState({ 
      previousRequestCity: requestCity,
      requestCity: value
    })
  }

  handleDelete = id => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: id
    })

  }
  
  confirmDelete = () => {
    const { deleteRiderRequest } = this.props
    const { idToDelete } = this.state

    deleteRiderRequest(idToDelete)
    this.closeDeleteDialog()
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  handleRequestCityAutoCompleteChange = (e, value) => {
    if (value?.id) {
      const {  allRequestCities } = this.props
      const newRequestCity = allRequestCities.find(requestCityElem => requestCityElem.id === value?.id)
      if (newRequestCity) this.setState({ requestCity: newRequestCity?.id })
    } else this.setState({ requestCity: null }) 
  }

  render() {
    const { classes, allRiderRequests, allRequestCities } = this.props
    const { openDeleteDialog, requestCity } = this.state
    const ids = allRiderRequests.map((riderRequest) => riderRequest.id)
    const parsedRiderRequests = allRiderRequests.map(this.makeRows)

    return (
      <React.Fragment>
        <div style={styles.mainListContainer}>
          Candidatures de livreurs
        </div>
        <div className={classes.topBarControls}>
          Tri par ville:

        <Autocomplete
          options={[{ name: 'Toutes les villes' }].concat(allRequestCities)}
          getOptionLabel={(requestCity) => requestCity?.name}
          defaultValue=""
          value={allRequestCities[0]?.name}
          style={{ width: '30%',marginBottom:'1%' }}
          onChange={this.handleRequestCityAutoCompleteChange}
          renderInput={(params) => <MatUiTextField {...params} label="Ville" variant="outlined" />}
          
        />      
        </div>
        <List
          className={classes.list}
          list={parsedRiderRequests}
          columns={[
            'Nom Prénom',
            'email',
            'Telephone',
            'Création',
            'Mise à jour',
            'Ville',
            'Ville soumise',
            'Transport',
            'Inscription',
            'Contrat',
            'Sac',
            'Status',
            'Note'
          ]}
          ids={ids}
          actions={{
            handleDelete: this.handleDelete,
          }}
          onPageChange={this.handlePageChange}
          emptyMessage="Aucune demande de livreur"
        />
        <Dialog
          open={openDeleteDialog}
          dialogText="Confirmer la suppression"
          dialogTitle="Confirmation"
          cancelButtonCaption="Annuler"
          confirmButtonCaption="Ok"
          onCancel={this.closeDeleteDialog}
          onValidation={this.confirmDelete}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  allRequestCities: requestsSelectors.getAllRequestCities(state),
  allRiderRequests: requestsSelectors.getAllRiderRequests(state)
})

const mapDispatchToProps = dispatch => ({
  fetchRequestCities: () => {
    dispatch(fetchRequestCityRequested())
  },
  fetchRiderRequests: (page, limit, requestCity = null) => {
    dispatch(fetchRiderRequestsRequested(page, limit, requestCity))
  },
  editRiderRequest: (riderRequestId, riderRequestInfos) => {
    dispatch(editRiderRequestRequested(riderRequestId, riderRequestInfos))
  },
  deleteRiderRequest: (riderRequestId) => {
    dispatch(deleteRiderRequestRequested(riderRequestId))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(RiderRequestsList)
