import { findIndex } from 'lodash'
import { restaurant } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { invoiceAPI, order } from '../../core.services/api'
import { parseErrorMessage } from '../../core.utils/requestActions'

export const {
  FETCH_ALL_RESTAURANTS_REQUESTED,
  FETCH_ALL_RESTAURANTS_SUCCEEDED,
  FETCH_ALL_RESTAURANTS_FAILED,
  fetchAllRestaurantsSucceeded,
  fetchAllRestaurantsFailed
} = createRequestActions('FETCH_ALL_RESTAURANTS')

export const fetchAllRestaurantsRequested = (cityId) => (dispatch) => {
  dispatch({
    type: FETCH_ALL_RESTAURANTS_REQUESTED
  })

  restaurant
    .getAll(cityId)
    .then((data) => {
      dispatch(fetchAllRestaurantsSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchAllRestaurantsFailed(error))
    })
}

export const {
  FETCH_RESTAURANTS_INFO_REQUESTED,
  FETCH_RESTAURANTS_INFO_SUCCEEDED,
  FETCH_RESTAURANTS_INFO_FAILED,
  fetchRestaurantsInfoSucceeded,
  fetchRestaurantsInfoFailed
} = createRequestActions('FETCH_RESTAURANTS_INFO')

export const fetchRestaurantsInfoRequested = (cityId, page, perPage) => (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANTS_INFO_REQUESTED
  })

  restaurant
    .getAllPaginated(cityId, page, perPage)
    .then((data) => {
      dispatch(fetchRestaurantsInfoSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchRestaurantsInfoFailed(error))
    })
}

export const {
  SEARCH_RESTAURANT_REQUESTED,
  SEARCH_RESTAURANT_SUCCEEDED,
  SEARCH_RESTAURANT_FAILED,
  searchRestaurantSucceeded,
  searchRestaurantFailed,
} = createRequestActions('SEARCH_RESTAURANT')

export const searchRestaurantRequested = (restaurantId) => (dispatch) => {
  dispatch({
    type: SEARCH_RESTAURANT_REQUESTED
  })

  restaurant
    .getById(restaurantId)
    .then((data) => {
      dispatch(searchRestaurantSucceeded(data.restaurant))
    })
    .catch((error) => {
      dispatch(searchRestaurantFailed(error))
    })
}

export const {
  FETCH_RESTAURANTS_BY_CITY_REQUESTED,
  FETCH_RESTAURANTS_BY_CITY_SUCCEEDED,
  FETCH_RESTAURANTS_BY_CITY_FAILED,
  fetchRestaurantsByCitySucceeded,
  fetchRestaurantsByCityFailed
} = createRequestActions('FETCH_RESTAURANTS_BY_CITY')

export const fetchRestaurantsByCityRequested = (cityId) => (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANTS_BY_CITY_REQUESTED
  })
  restaurant
    .getByCity(cityId)
    .then((data) => {
      dispatch(fetchRestaurantsByCitySucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchRestaurantsByCityFailed(error))
    })
}

export const {
  FETCH_RESTAURANTS_STATUS_REQUESTED,
  FETCH_RESTAURANTS_STATUS_SUCCEEDED,
  FETCH_RESTAURANTS_STATUS_FAILED,
  fetchRestaurantsStatusSucceeded,
  fetchRestaurantsStatusFailed
} = createRequestActions('FETCH_RESTAURANTS_STATUS')

export const fetchRestaurantsStatusRequested = () => (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANTS_STATUS_REQUESTED
  })
  restaurant
    .getAllStatus()
    .then((data) => {
      dispatch(fetchRestaurantsStatusSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchRestaurantsStatusFailed(error))
    })
}

export const fetchRestaurantsState = () => {
  return restaurant.getRestaurantStatus()
}

export const {
  CREATE_RESTAURANT_REQUESTED,
  CREATE_RESTAURANT_SUCCEEDED,
  CREATE_RESTAURANT_FAILED,
  createRestaurantSucceeded,
  createRestaurantFailed
} = createRequestActions('CREATE_RESTAURANT')

export const createRestaurantRequested = (restaurantInfos, selectedCity) => (dispatch) => {
  dispatch({
    type: CREATE_RESTAURANT_REQUESTED,
    payload: {
      restaurantInfos
    }
  })
  const { image, ...restaurantInfosWithoutImage } = restaurantInfos
  restaurant
    .add(restaurantInfosWithoutImage)
    .then((res) => {
      if (image) {
        return restaurant.uploadRestaurantImage(res.id || res._id, image)
      } else {
        return res
      }
    })
    .then((data) => {
      dispatch(createRestaurantSucceeded(data))
      selectedCity && dispatch(fetchAllRestaurantsRequested(selectedCity))
    })
    .catch((error) => {
      dispatch(createRestaurantFailed(error))
    })
}

export const {
  EDIT_RESTAURANT_REQUESTED,
  EDIT_RESTAURANT_SUCCEEDED,
  EDIT_RESTAURANT_FAILED,
  editRestaurantSucceeded,
  editRestaurantFailed,
  editRestaurantStateReducer
} = createRequestActions('EDIT_RESTAURANT')

export const editRestaurantRequested =
  (restaurantId, restaurantInfos) => (dispatch) => {
    dispatch({
      type: EDIT_RESTAURANT_REQUESTED,
      payload: {
        restaurantId,
        restaurantInfos
      }
    })
    const { image, hasImageChanged, ...restaurantInfosWithoutImage } = restaurantInfos
    restaurant
      .edit(restaurantId, restaurantInfosWithoutImage)
      .then((res) => {
        if (hasImageChanged && image) {
          return restaurant.uploadRestaurantImage(restaurantId, image)
        } else {
          return res
        }
      })
      .then((data) => {
        dispatch(
          editRestaurantSucceeded({
            restaurantId,
            restaurantInfos: { ...restaurantInfosWithoutImage, ...data },
          })
        )
      })
      .catch((error) => {
        console.log(error.response.data)
        dispatch(editRestaurantFailed(error))
      })
  }

export const {
  UPDATE_DELIVERY_RESTAURANT_REQUESTED,
  UPDATE_DELIVERY_RESTAURANT_SUCCEEDED,
  UPDATE_DELIVERY_RESTAURANT_FAILED,
  updateDeliveryRestaurantSucceeded,
  updateDeliveryRestaurantFailed,
  updateDeliveryRestaurantStateReducer
} = createRequestActions('UPDATE_DELIVERY_RESTAURANT')

export const updateDeliveryRestaurantRequested =
  (restaurantId, deliveryValue) => (dispatch) => {
    dispatch({
      type: UPDATE_DELIVERY_RESTAURANT_REQUESTED,
      payload: {
        restaurantId,
        deliveryValue
      }
    })
    restaurant
      .updateDelivery(restaurantId, deliveryValue)
      .then(() => {
        dispatch(
          updateDeliveryRestaurantSucceeded({
            restaurantId,
            deliveryValue
          })
        )
      })
      .catch((error) => {
        dispatch(updateDeliveryRestaurantFailed(error))
      })
  }

export const {
  UPDATE_PICKUP_RESTAURANT_REQUESTED,
  UPDATE_PICKUP_RESTAURANT_SUCCEEDED,
  UPDATE_PICKUP_RESTAURANT_FAILED,
  updatePickupRestaurantSucceeded,
  updatePickupRestaurantFailed,
  updatePickupRestaurantStateReducer
} = createRequestActions('UPDATE_PICKUP_RESTAURANT')

export const updatePickupRestaurantRequested =
  (restaurantId, pickupValue) => (dispatch) => {
    dispatch({
      type: UPDATE_PICKUP_RESTAURANT_REQUESTED,
      payload: {
        restaurantId,
        pickupValue
      }
    })
    restaurant
      .updatePickup(restaurantId, pickupValue)
      .then(() => {
        dispatch(
          updatePickupRestaurantSucceeded({
            restaurantId,
            pickupValue
          })
        )
      })
      .catch((error) => {
        dispatch(updatePickupRestaurantFailed(error))
      })
  }

export const {
  UPDATE_DISABLE_RESTAURANT_REQUESTED,
  UPDATE_DISABLE_RESTAURANT_SUCCEEDED,
  UPDATE_DISABLE_RESTAURANT_FAILED,
  updateDisableRestaurantSucceeded,
  updateDisableRestaurantFailed,
  updateDisableRestaurantStateReducer
} = createRequestActions('UPDATE_DISABLE_RESTAURANT')

export const updateDisableRestaurantRequested =
  (restaurantId, disableValue) => (dispatch) => {
    dispatch({
      type: UPDATE_DISABLE_RESTAURANT_REQUESTED,
      payload: {
        restaurantId,
        disableValue
      }
    })
    restaurant
      .updateDisable(restaurantId, disableValue)
      .then(() => {
        dispatch(
          updateDisableRestaurantSucceeded({
            restaurantId,
            disableValue
          })
        )
      })
      .catch((error) => {
        dispatch(updatePickupRestaurantFailed(error))
      })
  }

export const {
  DELETE_RESTAURANT_REQUESTED,
  DELETE_RESTAURANT_SUCCEEDED,
  DELETE_RESTAURANT_FAILED,
  deleteRestaurantSucceeded,
  deleteRestaurantFailed,
  deleteRestaurantStateReducer
} = createRequestActions('DELETE_RESTAURANT')

export const deleteRestaurantRequested = (restaurantId) => (dispatch) => {
  dispatch({
    type: DELETE_RESTAURANT_REQUESTED,
    payload: {
      restaurantId
    }
  })
  restaurant
    .delete(restaurantId)
    .then((data) => {
      dispatch(
        deleteRestaurantSucceeded({
          restaurantId
        })
      )
    })
    .catch((error) => {
      dispatch(deleteRestaurantFailed(error))
    })
}

export const {
  DUPLICATE_RESTAURANT_REQUESTED,
  DUPLICATE_RESTAURANT_SUCCEEDED,
  DUPLICATE_RESTAURANT_FAILED,
  duplicateRestaurantSucceeded,
  duplicateRestaurantFailed,
  duplicateRestaurantStateReducer
} = createRequestActions('DUPLICATE_RESTAURANT')
export const duplicateRestaurantRequested =
  (restaurantId, cityId, email, adress, comission, telephone) => (dispatch) => {
    dispatch({
      type: DUPLICATE_RESTAURANT_REQUESTED,
      payload: {
        restaurantId,
        cityId,
        email,
        adress,
        comission,
        telephone
      }
    })
    restaurant
      .duplicateRestaurant(
        restaurantId,
        cityId,
        email,
        adress,
        comission,
        telephone
      )
      .then((data) => {
        dispatch(
          duplicateRestaurantSucceeded({
            restaurantId,
            cityId,
            email,
            adress,
            comission,
            telephone
          })
        )
      })
      .catch((error) => {
        dispatch(duplicateRestaurantFailed(error))
      })
  }

export const {
  GENERATE_RESTAURANTS_RECAP_REQUESTED,
  GENERATE_RESTAURANTS_RECAP_SUCCEEDED,
  GENERATE_RESTAURANTS_RECAP_FAILED,
  generateRestaurantsRecapSucceeded,
  generateRestaurantsRecapFailed
} = createRequestActions('GENERATE_RESTAURANTS_RECAP')

export const generateRestaurantsRecapRequested =
  (startDate, endDate) => (dispatch) => {
    dispatch({
      type: GENERATE_RESTAURANTS_RECAP_REQUESTED
    })
    restaurant
      .generateRecap(startDate, endDate)
      .then((data) => {
        dispatch(generateRestaurantsRecapSucceeded(data))
      })
      .catch((error) => {
        dispatch(generateRestaurantsRecapFailed(error))
      })
  }

export const {
  GENERATE_RESTAURANT_ORDERS_RECAP_REQUESTED,
  GENERATE_RESTAURANT_ORDERS_RECAP_SUCCEEDED,
  GENERATE_RESTAURANT_ORDERS_RECAP_FAILED,
  generateRestaurantOrdersRecapSucceeded,
  generateRestaurantOrdersRecapFailed
} = createRequestActions('GENERATE_RESTAURANT_ORDERS_RECAP')

export const generateRestaurantOrdersRecapRequested =
  (restaurant, startDate, endDate) => (dispatch) => {
    dispatch({
      type: GENERATE_RESTAURANT_ORDERS_RECAP_REQUESTED
    })
    order
      .generateOrdersRecapByRestaurant(restaurant, startDate, endDate)
      .then((data) => {
        dispatch(generateRestaurantOrdersRecapSucceeded(data))
      })
      .catch((error) => {
        dispatch(generateRestaurantOrdersRecapFailed(error))
      })
  }

export const {
  GENERATE_RESTAURANT_INVOICE_REQUESTED,
  GENERATE_RESTAURANT_INVOICE_SUCCEEDED,
  GENERATE_RESTAURANT_INVOICE_FAILED,
  generateRestaurantInvoiceSucceeded,
  generateRestaurantInvoiceFailed
} = createRequestActions('GENERATE_RESTAURANT_INVOICE')

export const generateRestaurantInvoiceRequested = (restaurant, month, year) => (dispatch) => {
    dispatch({
      type: GENERATE_RESTAURANT_INVOICE_REQUESTED
    })
    invoiceAPI
      .generateInvoice(restaurant, month, year)
      .then((data) => {
        dispatch(generateRestaurantInvoiceSucceeded(data))
      })
      .catch((error) => {
        dispatch(generateRestaurantInvoiceFailed(error))
      })
}

export const {
  FETCH_RESTAURANT_LOGS_REQUESTED,
  FETCH_RESTAURANT_LOGS_SUCCEEDED,
  FETCH_RESTAURANT_LOGS_FAILED,
  fetchRestaurantLogsSucceeded,
  fetchRestaurantLogsFailed
} = createRequestActions('FETCH_RESTAURANT_LOGS')

export const fetchRestaurantLogsRequested = (restaurantId) => (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANT_LOGS_REQUESTED
  })
  restaurant
    .fetchRestaurantLogs(restaurantId)
    .then((data) => {
      dispatch(fetchRestaurantLogsSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchRestaurantLogsFailed(error))
    })
}

export const defaultState = {
  restaurantsInfo: {
    restaurants: [],
    paginationInfo: {
      count: 0,
      hasNext: false,
      page: 0,
      perPage: 20
    }
  },
  allRestaurants: [],
  allRestaurantStatuses: [],
  requestState: ''
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case CREATE_RESTAURANT_REQUESTED:
    case EDIT_RESTAURANT_REQUESTED:
    case UPDATE_DELIVERY_RESTAURANT_REQUESTED:
    case UPDATE_PICKUP_RESTAURANT_REQUESTED:
    case UPDATE_DISABLE_RESTAURANT_REQUESTED:
    case DELETE_RESTAURANT_REQUESTED:
    case DUPLICATE_RESTAURANT_REQUESTED:
    case GENERATE_RESTAURANTS_RECAP_REQUESTED:
    case GENERATE_RESTAURANT_ORDERS_RECAP_REQUESTED:
    case GENERATE_RESTAURANT_INVOICE_REQUESTED:
    case FETCH_RESTAURANTS_STATUS_REQUESTED:
    case FETCH_RESTAURANT_LOGS_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case FETCH_ALL_RESTAURANTS_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        allRestaurants: [...data],
        requestState: requestStates.SUCCESS,
      }
    }
    case FETCH_RESTAURANTS_INFO_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        restaurantsInfo: data,
        requestState: requestStates.SUCCESS,
      }
    }
    case SEARCH_RESTAURANT_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        restaurantsInfo: {
          restaurants: [ { ...data } ],
          paginationInfo: {
            ...state.restaurantsInfo.paginationInfo,
            count: 1
          },
        },
        requestState: requestStates.SUCCESS,
      }
    }
    case FETCH_RESTAURANTS_STATUS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        allRestaurantStatuses: [...data],
        requestState: requestStates.SUCCESS
      }
    }
    case CREATE_RESTAURANT_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        // allRestaurants: [...state.allRestaurants, data],
        requestState: requestStates.SUCCESS
      }
    }
    case EDIT_RESTAURANT_SUCCEEDED: {
      const { allRestaurants } = state
      const { restaurantId, restaurantInfos } = action.payload.data
      const index = findIndex(allRestaurants, (r) => r.id === restaurantId)
      return {
        ...state,
        allRestaurants: [
          ...allRestaurants.slice(0, index),
          { ...allRestaurants[index], ...restaurantInfos },
          ...allRestaurants.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_DELIVERY_RESTAURANT_SUCCEEDED: {
      const { restaurantsInfo } = state
      const { restaurantId, deliveryValue } = action.payload.data
      const index = findIndex(restaurantsInfo.restaurants, (r) => r.id === restaurantId || r._id === restaurantId )
      return {
        ...state,
        restaurantsInfo: {
          ...restaurantsInfo,
          restaurants: [
            ...restaurantsInfo.restaurants.slice(0, index),
            { ...restaurantsInfo.restaurants[index], isDelivery: deliveryValue },
            ...restaurantsInfo.restaurants.slice(index + 1)
          ],
        },
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_PICKUP_RESTAURANT_SUCCEEDED: {
      const { restaurantsInfo } = state
      const { restaurantId, pickupValue } = action.payload.data
      const index = findIndex(restaurantsInfo.restaurants, (r) => r.id === restaurantId || r._id === restaurantId)
      return {
        ...state,
        restaurantsInfo: {
          ...restaurantsInfo,
          restaurants: [
            ...restaurantsInfo.restaurants.slice(0, index),
            { ...restaurantsInfo.restaurants[index], isPickup: pickupValue },
            ...restaurantsInfo.restaurants.slice(index + 1)
          ],
        },
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_DISABLE_RESTAURANT_SUCCEEDED: {
      const { restaurantsInfo } = state
      const { restaurantId, disableValue } = action.payload.data
      const index = findIndex(restaurantsInfo.restaurants, (r) => r.id === restaurantId || r._id === restaurantId)
      return {
        ...state,
        restaurantsInfo: {
          ...restaurantsInfo,
          restaurants: [
            ...restaurantsInfo.restaurants.slice(0, index),
            { ...restaurantsInfo.restaurants[index], isDisabled: disableValue },
            ...restaurantsInfo.restaurants.slice(index + 1)
          ],
        },
        requestState: requestStates.SUCCESS
      }
    }
    case DELETE_RESTAURANT_SUCCEEDED: {
      const { restaurantsInfo } = state
      const { restaurantId } = action.payload.data
      const index = findIndex(restaurantsInfo.restaurants, (r) => r.id === restaurantId)
      return {
        ...state,
        restaurantsInfo: {
          ...restaurantsInfo,
          restaurants: [
            ...restaurantsInfo.restaurants.slice(0, index),
            ...restaurantsInfo.restaurants.slice(index + 1)
          ],
        },
        requestState: requestStates.SUCCESS
      }
    }
    case FETCH_RESTAURANT_LOGS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        currentRestaurantLogs: [...data],
        requestState: requestStates.SUCCESS
      }
    }
    case GENERATE_RESTAURANTS_RECAP_SUCCEEDED:
    case GENERATE_RESTAURANT_ORDERS_RECAP_SUCCEEDED:
    case GENERATE_RESTAURANT_INVOICE_SUCCEEDED:
      return {
        ...state,
        requestState: requestStates.SUCCESS
      }
    case FETCH_ALL_RESTAURANTS_FAILED:
    case CREATE_RESTAURANT_FAILED:
    case EDIT_RESTAURANT_FAILED:
    case UPDATE_DELIVERY_RESTAURANT_FAILED:
    case UPDATE_PICKUP_RESTAURANT_FAILED:
    case UPDATE_DISABLE_RESTAURANT_FAILED:
    case DELETE_RESTAURANT_FAILED:
    case DUPLICATE_RESTAURANT_FAILED:
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    case GENERATE_RESTAURANTS_RECAP_FAILED:
    case GENERATE_RESTAURANT_ORDERS_RECAP_FAILED:
    case GENERATE_RESTAURANT_INVOICE_FAILED:
    case FETCH_RESTAURANTS_STATUS_FAILED:
    case FETCH_RESTAURANT_LOGS_FAILED:
      return { ...state, requestState: requestStates.FAILED }
    default:
      return state
  }
}

const getState = (state) => state?.restaurants
const getAllRestaurants = (state) => getState(state).allRestaurants
const getRestaurantsInfo = (state) => getState(state).restaurantsInfo
const getAllRestaurantStatuses = (state, cityId = null) => {
  if (!cityId) {
    return getState(state).allRestaurantStatuses
  }
  return getState(state).allRestaurantStatuses.filter((r) => r.city === cityId)
}

const getCityRestaurants = (state, cityId) =>{
  return getAllRestaurants(state)
}
const getRequestState = (state) => getState(state).requestState
const getCurrentRestaurantLogs = (state) =>
  getState(state).currentRestaurantLogs
const getErrorMessage = (state) => getState(state)?.errorMessage

export const selectors = {
  getState,
  getAllRestaurants,
  getRestaurantsInfo,
  getAllRestaurantStatuses,
  getCityRestaurants,
  getRequestState,
  getCurrentRestaurantLogs,
  getErrorMessage
}
