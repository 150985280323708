import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as icons from 'react-feather';
import { isAuthorized } from '../../../core.utils/utils';
import { Routes } from '../../../core.utils/routes';
import { useDispatch } from 'react-redux';
import { discardTemplate } from '../../../core.modules/Template/Actions';

export const useStyles = makeStyles({
	navigationBar: {
		width: '100%',
		boxSizing: 'border-box',
		padding: '15px 60px',
		backgroundColor: '#F2FFF9',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly'
	},
	item: {
		listStyle: 'none'
	},
	link: {
		color: 'black',
		textDecoration: 'none !important',
		'&:hover': {
			fontWeight: '500'
		}
	}
});

function DashboardNavigation() {
	const profiles = JSON.parse(localStorage.getItem('profiles') || null)
	const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

	const [ showSubMenu, setshowSubMenu ] = useState(false);
	const [ showRestaurantSubMenu, setRestaurantshowSubMenu ] = useState(false);
	const [ showRidersShowSubMenu, setRidersShowSubMenu ] = useState(false);
	const [ showMarketingSubMenu, setMarketingSubMenu ] = useState(false);
	const [ showCandidatureSubMenu, setCandidatureSubMenu ] = useState(false);
	const [ showTemplateSubMenu, setTemplateSubMenu ] = useState(false);

	const RestaurantIcon = icons[showRestaurantSubMenu ? 'ChevronUp' : 'ChevronDown'];
	const RiderIcon = icons[showRidersShowSubMenu ? 'ChevronUp' : 'ChevronDown'];
	const CandidatureIcon = icons[showCandidatureSubMenu ? 'ChevronUp' : 'ChevronDown'];
	const MarketingIcon = icons[showMarketingSubMenu ? 'ChevronUp' : 'ChevronDown'];
	const TemplateIcon = icons[showTemplateSubMenu ? 'ChevronUp' : 'ChevronDown'];
	const Icon = icons[showSubMenu ? 'ChevronUp' : 'ChevronDown'];
	const classes = useStyles();
	const dispatch = useDispatch()
	return (
		<ul className={classes.navigationBar}>
			{permissions?.restaurant?.list && <li className={classes.item}>
				<Link className={classes.link} to="/">
				Home
				</Link>
			</li>}
			{ isAuthorized(profiles, Routes.OrderRoutes) && <li className={classes.item}>
				<Link className={classes.link} to={Routes.OrderRoutes}>
					Commandes
				</Link>
			</li>}
			{isAuthorized(profiles, Routes.RiderRoutes) && (
			<li
				className={classes.item}
				style={{ position: 'relative' }}
				onMouseEnter={() => {
					setRidersShowSubMenu(true);
				}}
				onMouseLeave={() => {
					setRidersShowSubMenu(false);
				}}
			>
				<Link onClick={() => setRidersShowSubMenu(false)} className={classes.link} to={Routes.RiderRoutes}>
					Livreurs <RiderIcon size={14} color={'black'} />
				</Link>

				{showRidersShowSubMenu && (
					<ul
						style={{
							position: 'absolute',
							top: 17,
							zIndex: 100,
							width: 200,
							backgroundColor: 'white',
							paddingLeft: 10,
							paddingRight: 10,
							boxShadow: '2px 2px 1px 1px rgba(0, 0, 0, .2)',
							listStyle: 'none'
						}}
					>
						<li onClick={() => setRidersShowSubMenu(false)}>
							<Link className={classes.link} to="/livreurs">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Livreurs</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setRidersShowSubMenu(false)}>
							<Link className={classes.link} to="/livreurs/plannings">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Plannings</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setRidersShowSubMenu(false)}>
							<Link className={classes.link} to="/livreurs/positions">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Tracking</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setRidersShowSubMenu(false)}>
							<Link className={classes.link} to="/livreurs-tracking">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Tracking / Suivi</span>
								</div>
							</Link>
						</li>
					</ul>
				)}
			</li>
			)}
			
			{ permissions?.restaurant?.list && <li
				className={classes.item}
				style={{ position: 'relative' }}
				onMouseEnter={() => {
					setRestaurantshowSubMenu(true);
				}}
				onMouseLeave={() => {
					setRestaurantshowSubMenu(false);
				}}
			>
				<Link onClick={() => setRestaurantshowSubMenu(false)} className={classes.link} to={Routes.RestaurantRoutes}>
					Restaurants <RestaurantIcon size={14} color={'black'} />
				</Link>

				{showRestaurantSubMenu && (
					<ul
						style={{
							position: 'absolute',
							top: 17,
							zIndex: 100,
							width: 200,
							backgroundColor: 'white',
							paddingLeft: 10,
							paddingRight: 10,
							boxShadow: '2px 2px 1px 1px rgba(0, 0, 0, .2)',
							listStyle: 'none'
						}}
					>
						<li onClick={() => setRestaurantshowSubMenu(false)}>
							<Link className={classes.link} to={Routes.RestaurantRoutes}>
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Restaurants</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setRestaurantshowSubMenu(false)}>
							<Link className={classes.link} to="/restaurants-status">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Status_Resto</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setRestaurantshowSubMenu(false)}>
							<Link className={classes.link} to="/restaurants-tracking">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span> Tracking / Suivi </span>
								</div>
							</Link>
						</li>
					</ul>
				)}
			</li>
			}

			{permissions?.user?.list && isAuthorized(profiles, Routes.UserRoutes) && (
				<li className={classes.item}>
					<Link className={classes.link} to={Routes.UserRoutes}>
						Utilisateurs
					</Link>
				</li>
			)}
			{isAuthorized(profiles, Routes.CityRoutes) && (
				<li className={classes.item}>
					<Link className={classes.link} to={Routes.CityRoutes}>
						Villes
					</Link>
				</li>
			)}
			{ permissions?.product?.list && <li
				className={classes.item}
				style={{ position: 'relative' }}
				onMouseEnter={() => {
					setshowSubMenu(true);
				}}
				onMouseLeave={() => {
					setshowSubMenu(false);
				}}
			>
				<Link className={classes.link} to={Routes.ProductRoutes} onClick={()=>dispatch(discardTemplate())}>
					Produits <Icon size={14} color={'black'} />
				</Link>
				{showSubMenu && (
					<ul
						style={{
							position: 'absolute',
							top: 17,
							zIndex: 100,
							width: 200,
							backgroundColor: 'white',
							paddingLeft: 10,
							paddingRight: 10,
							boxShadow: '2px 2px 1px 1px rgba(0, 0, 0, .2)',
							listStyle: 'none'
						}}
					>
						<li onClick={() => {setshowSubMenu(false) 
							dispatch(discardTemplate())}}>
							<Link className={classes.link} to={Routes.ProductRoutes}>
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Produits</span>
								</div>
							</Link>
						</li>
						<li onClick={() => {setshowSubMenu(false)
						dispatch(discardTemplate())
						}}>
							<Link className={classes.link} to="/restaurants/supplements-group">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Groupe de Suppléments</span>
								</div>
							</Link>
						</li>
						<li onClick={() => {setshowSubMenu(false)
						dispatch(discardTemplate())}}>
							<Link className={classes.link} to="/restaurants/supplements">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Suppléments</span>
								</div>
							</Link>
						</li>
						<li onClick={() =>{ setshowSubMenu(false)
						dispatch(discardTemplate())}}>
							<Link className={classes.link} to="/restaurants/options">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
										borderBottom: 'solid 1px lightgrey'
									}}
								>
									<span>Options</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setshowSubMenu(false)}>
							<Link className={classes.link} to="/restaurants/headers">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8
									}}
								>
									<span>Catégories personnalisées</span>
								</div>
							</Link>
						</li>
						<li onClick={() => setRestaurantshowSubMenu(false)}>
							<Link className={classes.link} to="/restaurant-augmentation">
								<div
									style={{
										width: '100%',
										paddingTop: 8,
										paddingBottom: 8,
									}}
								>
									<span>Mise a jour prix</span>
								</div>
							</Link>
						</li>
					</ul>
				)}
			</li>
			}
			{permissions?.application?.list && isAuthorized(profiles, Routes.CandidatureRoutes) &&  
				<li className={classes.item}
					style={{ position: 'relative' }}
					onMouseEnter={() => {
						setCandidatureSubMenu(true);
					}}
					onMouseLeave={() => {
						setCandidatureSubMenu(false);
					}}
				>
					<Link className={classes.link} to={Routes.CandidatureRoutes}>
						Candidatures <CandidatureIcon size={14} color={'black'} />
					</Link>
					{showCandidatureSubMenu && (
						<ul
							style={{
								position: 'absolute',
								top: 17,
								zIndex: 100,
								width: 200,
								backgroundColor: 'white',
								paddingLeft: 10,
								paddingRight: 10,
								boxShadow: '2px 2px 1px 1px rgba(0, 0, 0, .2)',
								listStyle: 'none'
							}}
						>
							<li onClick={() => setCandidatureSubMenu(false)}>
								<Link className={classes.link} to="/candidatures/villes">
									<div
										style={{
											width: '100%',
											paddingTop: 8,
											paddingBottom: 8,
											borderBottom: 'solid 1px lightgrey'
										}}
									>
										<span>Gérer les villes pour les candidatures</span>
									</div>
								</Link>
							</li>
							<li onClick={() => setCandidatureSubMenu(false)}>
								<Link className={classes.link} to="/candidatures/restaurants">
									<div
										style={{
											width: '100%',
											paddingTop: 8,
											paddingBottom: 8,
											borderBottom: 'solid 1px lightgrey'
										}}
									>
										<span>Gérer les candidatures des restaurants</span>
									</div>
								</Link>
							</li>
							<li onClick={() => setCandidatureSubMenu(false)}>
								<Link className={classes.link} to="/candidatures/livreurs">
									<div
										style={{
											width: '100%',
											paddingTop: 8,
											paddingBottom: 8,
											borderBottom: 'solid 1px lightgrey'
										}}
									>
										<span>Gérer les candidatures des livreurs</span>
									</div>
								</Link>
							</li>
						</ul>
					)}
				</li>
			}
			{ permissions?.marketing?.list && <li className={classes.item}
				style={{ position: 'relative' }}
				onMouseEnter={() => {
					setMarketingSubMenu(true);
				}}
				onMouseLeave={() => {
					setMarketingSubMenu(false);
				}}>
				{isAuthorized(profiles, Routes.MarketingRoutes) && (
					<Link className={classes.link} to={`${Routes.MarketingRoutes}/tabs/notifications`}>
						Marketing <MarketingIcon size={14} color={'black'} />
					</Link>
				)}
					{showMarketingSubMenu && (
						
						<ul
						style={{
							position: 'absolute',
							top: 18,
							zIndex: 100,
							width: 200,
							backgroundColor: 'white',
							paddingLeft: 10,
							paddingRight: 10,
							boxShadow: '2px 2px 1px 1px rgba(0, 0, 0, .2)',
							listStyle: 'none'
						}}
					>
						<li onClick={() => setMarketingSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.MarketingRoutes}/tabs/notifications`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Notification</span>
						</div>
					</Link>
				</li>
				<li onClick={() => setMarketingSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.MarketingRoutes}/tabs/stats`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Statistiques</span>
						</div>
					</Link>
				</li>
				<li onClick={() => setMarketingSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.MarketingRoutes}/tabs/promoCode`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Code Promo</span>
						</div>
					</Link>
				</li>
				{(permissions?.ad?.listGeneral || permissions?.ad?.listByCity) && <li onClick={() => setMarketingSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.MarketingRoutes}/ads`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Ads </span>
						</div>
					</Link>
				</li>}
				
				<li onClick={() => setMarketingSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.MarketingRoutes}/deals`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Offres</span>
						</div>
					</Link>
				</li>
				</ul>
					
					)}
			</li>
			}
			{/* <li className={classes.item}>
        <Link className={classes.link} to="/options">
          Options
        </Link>
      </li> */}
			{isAuthorized(profiles, Routes.FinancialRoutes) && 
			<li className={classes.item}>
				<Link className={classes.link} to={Routes.FinancialRoutes}>
					Gestion Financière
				</Link>
			</li>}
			{ permissions?.reclamation?.list && <li className={classes.item}>
				<Link className={classes.link} to="/Claims/ordergroup">
					Gestion Réclamation
				</Link>
			</li>
			}
			{permissions?.template?.list && isAuthorized(profiles, Routes.FinancialRoutes) && 
			<li className={classes.item}
			style={{position:'relative'}}
			onMouseEnter={() => {
				setTemplateSubMenu(true);
			}}
			onMouseLeave={() => {
				setTemplateSubMenu(false);
			}}
			>
				<Link className={classes.link} to={Routes.TemplatesRoutes}>
					Gestion Templates <TemplateIcon size={14} color={'black'} />
				</Link>
				{showTemplateSubMenu && (
						
						<ul
						style={{
							position: 'absolute',
							top: 18,
							zIndex: 100,
							width: 200,
							backgroundColor: 'white',
							paddingLeft: 10,
							paddingRight: 10,
							boxShadow: '2px 2px 1px 1px rgba(0, 0, 0, .2)',
							listStyle: 'none'
						}}
					>
						<li onClick={() => setTemplateSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.TemplatesRoutes}`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Templates</span>
						</div>
					</Link>
				</li>
				<li onClick={() => setTemplateSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.TemplatesRoutes}/Products`} >
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Produits</span>
						</div>
					</Link>
				</li>
				<li onClick={() => setTemplateSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.TemplatesRoutes}/Group`} >
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Groupe de restaurants</span>
						</div>
					</Link>
				</li>
				<li onClick={() => setTemplateSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.TemplatesRoutes}/supplements`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Supplements</span>
						</div>
					</Link>
				</li>
						<li onClick={() => setTemplateSubMenu(false)}>
					<Link className={classes.link} to={`${Routes.TemplatesRoutes}/options`}>
						<div
							style={{
								width: '100%',
								paddingTop: 8,
								paddingBottom: 8,
								borderBottom: 'solid 1px lightgrey'
							}}
						>
							<span>Options </span>
						</div>
					</Link>
				</li></ul>
					)}
			</li>}
		</ul>
	);
}

export default DashboardNavigation;
