import axios from 'core.services/interceptor/tokenInterceptor'
import { admin } from './api'

export const loginService = async (email, password) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/admin/login`,
      headers: { 'Content-Type': 'application/json' },
      data: { email, password, pushToken: localStorage.pushToken }
    })
    if (response.status === 200 && response.data.token) {
      localStorage.setItem('userId',response.data.admin.id)
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('role', response.data.admin.role)
      localStorage.setItem('firstName',response.data.admin.firstName)
      localStorage.setItem('lastName',response.data.admin.lastName)
      response.data.admin.profiles && localStorage.setItem('profiles', JSON.stringify(response.data.admin.profiles))
      localStorage.setItem('permissions', JSON.stringify(response.data.admin.permissions))
    }
    return response
  } catch (e) {
    console.log("Error loginService", e)
  }
}

export const getInfo = async () => {
  try {
    const info = await admin.getInfo()
    return info
  } catch (e) {
    localStorage.removeItem('token');
		localStorage.removeItem('role');
		localStorage.removeItem('selectedCity'); 
		localStorage.removeItem('selectedRes');
		localStorage.removeItem('firstName')
		localStorage.removeItem('lastName')
    localStorage.removeItem('userId')
  }
}
