export const REGULATION_TYPE_PRODUCT = "product"
export const REGULATION_TYPE_SUPPLEMENTS = "supplements"
export const REGULATION_TYPE_AMOUNT = "amount"

export const getDefaultRegulation = () => ({
  type: null,
  concernedParty: null,
  value: null,
  products:[],
  reason: null,
  paidTo: "",
  toPay: true,
  withoutCommission: false
})

export const ConcernedParty = {
  RESTAURANT: 'restaurant',
  RIDER: 'rider',
  USER: 'user'
}

export const PaidToAray = [
  'lyveat',
  'restaurant',
  'rider',
  'second_rider',
  'Utilisateur'
]

export const PaidTo = {
  LYVEAT: 'lyveat',
  RESTAURANT: 'restaurant',
  RIDER: 'rider',
  SECOND_RIDER: 'second_rider',
  USER: 'user'
}

export const DefaultReasons = {
  all: [
    "Livraison à payer au restaurant",
  ],
  [ConcernedParty.RESTAURANT]: [ 
    "Erreur prix article",
    "Rajout d'un produit à payer", 
    "Manque produit",
    "Commande préparée, mais pas livrée",
    "50% de la 2ème livraison suite oubli de produit",
  ],
  [ConcernedParty.RIDER]: [
    "Commande annulée",
    "Rajout suite mauvaise adresse",
    "Livraison payer double suite oubli de produit",
    "Course à ne pas payer"
  ]
}

const ConcernedPartyTranslated = {
  [ConcernedParty.RESTAURANT]: 'Restaurant',
  [ConcernedParty.RIDER]: 'Livreur',
  [ConcernedParty.USER]: 'Utilisateur',
}

const PaidToTranslated = {
  [PaidTo.LYVEAT]: 'Lyveat',
  [PaidTo.RESTAURANT]: 'Restaurant',
  [PaidTo.RIDER]: 'Livreur',
  [PaidTo.SECOND_RIDER]: 'Deuxième livreur',
  [PaidTo.USER]: 'Utilisateur'
}

export const getConcernedPartyTranslated = (concernedParty) => {
  return ConcernedPartyTranslated[concernedParty] || concernedParty
}

export const getPaidToTranslated = (paidTo) => {
  return PaidToTranslated[paidTo] || paidTo
}