import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Bell, Edit3, RefreshCw, Check, BellOff, Send, X,AlertTriangle  } from 'react-feather'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import List from 'core.ui/List'
import Select from 'core.ui/Select'
import TablePagination from '@material-ui/core/TablePagination';
import {
  orderStatus,
  pickupOrderStatus,
  getFrenchStatus,
  statusColorMapping,
  statusFontColorMapping
} from 'core.utils/parser/orderStatus'
import { makeUserFullName } from 'core.utils/parser/user'
import { renderDeliveryIcon } from 'core.utils/parser/order'
import { makeDateString } from 'core.utils/parser/time'

import styles from './../../styles'
import RiderAutcomplete from './RiderAutcomplete'
import WarningIcon from '@material-ui/icons/Warning';
import { Routes } from '../../../../core.utils/routes'
import { updatePagination } from '../ui-slice'

const ListComponent = ({
    classes,
    handleDelete,
    forceRestaurantOrdersRefresh,
    sendNewOrderNotifToRestaurant,
    handleStatusChange,
    handleRiderChange,
    openNoteDialog,
    isRushActive
  }) => {
  const dispatch = useDispatch()
  const { isLoading, ordersInfo, riders, selectedCity, filters } = useSelector(
    (state) => state.uiOrderList
  ) 

  const [parsedOrders, setParsedOrders] = useState([])

  useEffect(() => {
    setParsedOrders(parseOrders())
  }, [ordersInfo, riders])

  const parseOrders = () => {
    return ordersInfo.orders
    .map((order, idx) => {
      const {
        created_at,
        updated_at,
        status,
        restaurantId: restaurant = {},
        riderId: rider = {},
        deliveryType,
        totalPrice,
        deliveryPrice,
        userId: client,
        noteForAdmin
      } = order

      const orderId = (order?.billNumber || '').split('-').slice(1).join('-')
      const user = makeUserFullName(client)
      const cityName = order?.cityId?.name || ''
      const appVersion = !client
        ? 'Utilisateur Supprimé'
        : order?.appVersion
        ? order?.appVersion
        : 'Web'     
      const noteIconColor = noteForAdmin ? 'darkGreen' : 'lightGrey'

      return [
        makeForceRefreshColumn(
          order.restaurantId?.id || order.restaurantId?._id
        ),
        makeNotifColumn(order),
        renderDeliveryIcon(deliveryType),
        makeOrderDetailsLink(`#${orderId} — ${user}`, order),
        makeDateString(created_at),
        makeDateString(updated_at),
        makeStatusSelect(status, order.id || order._id, deliveryType),
        restaurant?.nom + `\n(${cityName}) \n(${restaurant?.telephone})`,
        <RiderAutcomplete order={order} rider={rider} handleRiderChange={handleRiderChange} selectedCity={filters.allCities ? null : selectedCity} />,
        (totalPrice.text),
        order?.deliveryPrice ? (order?.deliveryPrice?.text) : "",
        <Edit3
          size="18"
          color={noteIconColor}
          style={{ marginTop: 5, marginRight: '10' }}
          onClick={openNoteDialog(order.id || order._id, noteForAdmin)}
        />,
        appVersion, 
        order?.claim ? <Link to={`${Routes.ClaimsRoutes}/${order?.id || order._id}`}><WarningIcon style={{color:'red'}}/></Link> :<div></div>
      ]
    })
  } 

  const makeForceRefreshColumn = (id) => (
    <Button
      variant="contained"
      onClick={() => forceRestaurantOrdersRefresh(id)}
    >
      <RefreshCw />
    </Button>
  )

  const makeNotifColumn = (order) => {
    return order.isNewOrderNotificationConfirmed !== undefined ? (
      order.isNewOrderNotificationConfirmed ? (
        <Check size="23" style={styles.green} />
      ) :order.status === 'processing' ? (
        <Button
          variant="contained"
          style={styles.notifSendButton}
          onClick={() => sendNewOrderNotifToRestaurant(order._id)}
        >
          <Send />
        </Button>
      ) : (
        <X size="23" style={{ color: 'red' }} />
      )
    ) : (order.isCheckoutSystemResendEnabled ===true ? <AlertTriangle size='23' color={'red'} />:
      <BellOff size="23" color={'gray'} alt="Ancienne commande" />
    )
  }

  const makeOrderDetailsLink = (label, order) => {
    const orderId = order.id || order._id
    return <Link to={`/commandes/${orderId}`}>{label}</Link>
  }

  const makeStatusSelect = (
    status = '',
    orderId,
    deliveryType = 'delivery'
  ) => {
    const statuses =
      deliveryType === 'delivery'
        ? Object.values(orderStatus)
        : Object.values(pickupOrderStatus)
    return (
      <Select
        style={{ backgroundColor: statusColorMapping[status] }}
        value={status}
        options={statuses}
        disabled={['driver assigned']}
        captions={statuses.map((s) => getFrenchStatus(s, deliveryType))}
        autoFill={false}
        onChange={(e) => handleStatusChange(orderId, e.target.value)}
        colors={statusFontColorMapping}
      />
    )
  }

  if (!isRushActive && isLoading) {
    return <div><p>Chargement...</p></div>
  }

  const rowsPerPageOptions = [5, 10, 20]

  const handleChangePage = (event, newPage) => {
    dispatch(updatePagination({ page: newPage + 1 }))
  }

  const handleChangeRowsPerPage = event => {
    dispatch(updatePagination({ page: 1, perPage: event.target.value }))
  }

  const getPageIndex = () => {
    return ordersInfo.paginationInfo.page - 1 > -1 ? ordersInfo.paginationInfo.page - 1 : 0
  }

  return (
    <>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={ordersInfo.paginationInfo?.count || 0}
        rowsPerPage={ordersInfo.paginationInfo.perPage}
        page={getPageIndex()}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <List
        className={classes.list}
        columns={[
          'Actualiser',
          <Bell size="18" style={{ marginTop: 5, marginRight: '10' }} />,
          'Type de commande',
          'Commande',
          'Création',
          'Mise à jour',
          'État',
          'Restaurant',
          'Livreur',
          'Total',
          'Tarif livraison',
          'Note admin',
          "Version de l'utilisateur",
          "Réclamation",
        ]}
        list={parsedOrders}
        ids={parsedOrders.map((o) => o.id || o._id)}
        actions={{
          handleDelete: handleDelete
        }}
        withPagination={false}
      />
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={ordersInfo.paginationInfo?.count || 0}
        rowsPerPage={ordersInfo.paginationInfo.perPage}
        page={getPageIndex()}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
    
  )
}

ListComponent.propTypes = {
  classes: PropTypes.object,
  handleDelete: PropTypes.func,
  forceRestaurantOrdersRefresh: PropTypes.func,
  sendNewOrderNotifToRestaurant: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleRiderChange: PropTypes.func,
  openNoteDialog: PropTypes.func
}

export default ListComponent
