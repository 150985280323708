import { city } from 'core.services/api'
import { requestStates } from '../../core.utils/requestStates'
import {
  parseErrorMessage,
  createRequestActions
} from '../../core.utils/requestActions'
import { findIndex } from 'lodash'

export const CITY_INFO_FETCH_REQUESTED = 'CITY_INFO_FETCH_REQUESTED'
export const CITY_INFO_FETCH_SUCCEEDED = 'CITY_INFO_FETCH_SUCCEEDED'
export const CITY_INFO_FETCH_FAILED = 'CITY_INFO_FETCH_FAILED'

export const CITIES_FETCH_REQUESTED = 'CITIES_FETCH_REQUESTED'
export const CITIES_FETCH_SUCCEEDED = 'CITIES_FETCH_SUCCEEDED'
export const CITIES_FETCH_FAILED = 'CITIES_FETCH_FAILED'

export const CITIES_INFO_FETCH_REQUESTED = 'CITIES_INFO_FETCH_REQUESTED'
export const CITIES_INFO_FETCH_SUCCEEDED = 'CITIES_INFO_FETCH_SUCCEEDED'
export const CITIES_INFO_FETCH_FAILED = 'CITIES_INFO_FETCH_FAILED'

export const CITY_SEARCH_REQUESTED = 'CITY_SEARCH_REQUESTED'
export const CITY_SEARCH_SUCCEEDED = 'CITY_SEARCH_SUCCEEDED'
export const CITY_SEARCH_FAILED = 'CITY_SEARCH_FAILED'

export const cityInfoFetchRequested = (cityId) => (dispatch) => {
  dispatch({
    type: CITY_INFO_FETCH_REQUESTED
  })
  city
    .getCityById(cityId)
    .then((data) => {
      dispatch(cityInfoFetchSucceeded(data))
    })
    .catch((error) => dispatch(cityInfoFetchFailed(error)))
}

export const cityInfoFetchSucceeded = (cityInfo) => {
  return {
    type: CITY_INFO_FETCH_SUCCEEDED,
    payload: {
      cityInfo
    }
  }
}

export const cityInfoFetchFailed = (error) => {
  return {
    type: CITIES_FETCH_FAILED,
    payload: {
      error
    }
  }
}

export const citiesFetchRequested = () => (dispatch) => {
  dispatch({
    type: CITIES_FETCH_REQUESTED
  })
  city
    .getAll()
    .then((data) => {
      dispatch(citiesFetchSucceeded(data))
    })
    .catch((error) => dispatch(citiesFetchFailed(error)))
}

export const citiesFetchSucceeded = (cities) => {
  return {
    type: CITIES_FETCH_SUCCEEDED,
    payload: {
      cities
    }
  }
}

export const citiesFetchFailed = (error) => {
  return {
    type: CITIES_FETCH_FAILED,
    payload: {
      error
    }
  }
}

export const citySearchRequested = (cityId) => (dispatch) => {
  dispatch({
    type: CITY_SEARCH_REQUESTED
  })
  city
    .getCityById(cityId)
    .then((data) => {
      dispatch(citySearchSucceeded(data))
    })
    .catch((error) => dispatch(citySearchFailed(error)))
}

export const citySearchSucceeded = (city) => {
  return {
    type: CITY_SEARCH_SUCCEEDED,
    payload: {
      city
    }
  }
}

export const citySearchFailed = (error) => {
  return {
    type: CITY_SEARCH_FAILED,
    payload: {
      error
    }
  }
}

export const citiesInfoFetchRequested = (page, perPage) => (dispatch) => {
  dispatch({
    type: CITIES_INFO_FETCH_REQUESTED
  })
  city
    .getAllPaginated(page, perPage)
    .then((data) => {
      dispatch(citiesInfoFetchSucceeded(data))
    })
    .catch((error) => dispatch(citiesInfoFetchFailed(error)))
}

export const citiesInfoFetchSucceeded = (citiesInfo) => {
  return {
    type: CITIES_INFO_FETCH_SUCCEEDED,
    payload: {
      citiesInfo
    }
  }
}

export const citiesInfoFetchFailed = (error) => {
  return {
    type: CITIES_INFO_FETCH_FAILED,
    payload: {
      error
    }
  }
}



export const {
  UPDATE_DELIVERY_CITY_REQUESTED,
  UPDATE_DELIVERY_CITY_SUCCEEDED,
  UPDATE_DELIVERY_CITY_FAILED,
  updateDeliveryCitySucceeded,
  updateDeliveryCityFailed,
  updateDeliveryCityStateReducer
} = createRequestActions('UPDATE_DELIVERY_CITY')

export const updateDeliveryCityRequested =
  (cityId, deliveryValue) => (dispatch) => {
    dispatch({
      type: UPDATE_DELIVERY_CITY_REQUESTED,
      payload: {
        cityId,
        deliveryValue
      }
    })
    city
      .updateDelivery(cityId, deliveryValue)
      .then(() => {
        dispatch(
          updateDeliveryCitySucceeded({
            cityId,
            deliveryValue
          })
        )
      })
      .catch((error) => {
        dispatch(updateDeliveryCityFailed(error))
      })
  }

export const {
  UPDATE_PICKUP_CITY_REQUESTED,
  UPDATE_PICKUP_CITY_SUCCEEDED,
  UPDATE_PICKUP_CITY_FAILED,
  updatePickupCitySucceeded,
  updatePickupCityFailed,
  updatePickupCityStateReducer
} = createRequestActions('UPDATE_PICKUP_CITY')

export const updatePickupCityRequested =
  (cityId, pickupValue) => (dispatch) => {
    dispatch({
      type: UPDATE_PICKUP_CITY_REQUESTED,
      payload: {
        cityId,
        pickupValue
      }
    })
    city
      .updatePickup(cityId, pickupValue)
      .then(() => {
        dispatch(
          updatePickupCitySucceeded({
            cityId,
            pickupValue
          })
        )
      })
      .catch((error) => {
        dispatch(updatePickupCityFailed(error))
      })
  }
export const {
  UPDATE_IsOpen_CITY_REQUESTED,
  UPDATE_IsOpen_CITY_SUCCEEDED,
  UPDATE_IsOpen_CITY_FAILED,
  updateIsOpenCitySucceeded,
  updateIsOpenCityFailed,
  updateIsOpenCityStateReducer
} = createRequestActions('UPDATE_IsOpen_CITY')

export const updateIsOpenAllDay = (cityId, isOpenAllDay) => (dispatch) => {
  dispatch({
    type: UPDATE_IsOpen_CITY_REQUESTED,
    payload: {
      cityId,
      isOpenAllDay
    }
  })
  city
    .updateIsOpenAllDay(cityId, isOpenAllDay)
    .then(() => {
      dispatch(
        updateIsOpenCitySucceeded({
          cityId,
          isOpenAllDay
        })
      )
    })
    .catch((error) => {
      dispatch(updateIsOpenCityFailed(error))
    })
}

export const {
  UPDATE_CITY_KMS_RADIUS_REQUESTED,
  UPDATE_CITY_KMS_RADIUS_SUCCEEDED,
  UPDATE_CITY_KMS_RADIUS_FAILED,
  updateCityKmsRadiusSucceeded,
  updateCityKmsRadiusFailed,
  updateCityKmsRadiusStateReducer
} = createRequestActions('UPDATE_CITY_KMS_RADIUS')

export const updateCityKMSRadius5 = (cityId, is5KmLimited) => (dispatch) => {
  dispatch({
    type: UPDATE_CITY_KMS_RADIUS_REQUESTED,
    payload: {
      cityId,
      is5KmLimited
    }
  })

  city
    .updateRadiusTo5(cityId, is5KmLimited)
    .then(() => {
      dispatch(
        updateCityKmsRadiusSucceeded({
          cityId,
          is5KmLimited
        })
      )
    })
    .catch((error) => {
      dispatch(updateCityKmsRadiusFailed(error))
    })
}
export const updateCityKMSRadius10 = (cityId, is10KmLimited) => (dispatch) => {
  dispatch({
    type: UPDATE_CITY_KMS_RADIUS_REQUESTED,
    payload: {
      cityId,
      is10KmLimited
    }
  })

  city
    .updateRadiusTo10(cityId, is10KmLimited)
    .then(() => {
      dispatch(
        updateCityKmsRadiusSucceeded({
          cityId,
          is10KmLimited
        })
      )
    })
    .catch((error) => {
      dispatch(updateCityKmsRadiusFailed(error))
    })
}

export const {
  CREATE_CITY_REQUESTED,
  CREATE_CITY_SUCCEEDED,
  CREATE_CITY_FAILED,
  createCitySucceeded,
  createCityFailed
} = createRequestActions('CREATE_CITY')

export const createCity = (cityInfos) => (dispatch) => {
  dispatch({
    type: CREATE_CITY_REQUESTED
  })
  city
    .createCity(cityInfos)
    .then((data) => {
      dispatch(createCitySucceeded(cityInfos))
    })
    .catch((error) => {
      dispatch(createCityFailed(error))
    })
}

export const {
  GET_CITY_REQUESTED,
  GET_CITY_SUCCEEDED,
  GET_CITY_FAILED,
  getCitySucceeded,
  getCityFailed
} = createRequestActions('GET_CITY')

export const getCityRequested = (cityId) => (dispatch) => {
  dispatch({
    type: GET_CITY_REQUESTED,
    payload: { cityId }
  })
  city
    .getCity(cityId)
    .then((data) => {
      dispatch(getCitySucceeded(cityId))
    })
    .catch((error) => {
      dispatch(getCityFailed(error))
    })
}

export const defaultState = {
  citiesInfo: {
    cities: [],
    paginationInfo: {
      count: 0,
      hasNext: false,
      page: 0,
      perPage: 20
    }
  },
  allCities: [],
  selectedCity: {}
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case CITIES_FETCH_REQUESTED:
    case CITY_SEARCH_REQUESTED:
    case CITIES_INFO_FETCH_REQUESTED:
    case UPDATE_DELIVERY_CITY_REQUESTED:
    case UPDATE_PICKUP_CITY_REQUESTED:
    case UPDATE_IsOpen_CITY_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case CITIES_FETCH_SUCCEEDED:
      const { cities } = action.payload
      return { ...state, allCities: cities, requestState: requestStates.SUCCESS }
    case CITY_SEARCH_SUCCEEDED:
      const newCitiesInfo = {
        cities: [ { ...action.payload.city } ],
        paginationInfo: {
          ...state.citiesInfo.paginationInfo,
          count: 1
        }
      }
      return { ...state, requestState: requestStates.SUCCESS, citiesInfo: newCitiesInfo }
    case CITIES_INFO_FETCH_SUCCEEDED:
      const { citiesInfo } = action.payload
      return { ...state, citiesInfo, requestState: requestStates.SUCCESS  }
    case CITY_INFO_FETCH_SUCCEEDED:
      const { cityInfo } = action.payload
      return { ...state, selectedCity: cityInfo }
    case UPDATE_DELIVERY_CITY_SUCCEEDED: {
      const { citiesInfo } = state
      const { cityId, deliveryValue } = action.payload.data
      const index = findIndex(citiesInfo.cities, (r) => r.id === cityId || r._id === cityId)

      return {
        ...state,
        citiesInfo: {
          ...citiesInfo,
          cities: [
            ...citiesInfo.cities.slice(0, index),
            { ...citiesInfo.cities[index], isDelivery: deliveryValue },
            ...citiesInfo.cities.slice(index + 1)
          ]
        },
        requestState: requestStates.SUCCESS
      }
    }
    case GET_CITY_SUCCEEDED: {
      const { allCities } = state
      const { cityId } = action.payload.data
      return {
        ...state,
        selectedCity: [...allCities.find((c) => c._id == cityId)],
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_PICKUP_CITY_SUCCEEDED: {
      const { citiesInfo } = state
      const { cityId, pickupValue } = action.payload.data
      const index = findIndex(citiesInfo.cities, (r) => r.id === cityId || r._id === cityId)
      return {
        ...state,
        citiesInfo: {
          ...citiesInfo,
          cities: [
            ...citiesInfo.cities.slice(0, index),
            { ...citiesInfo.cities[index], isPickup: pickupValue },
            ...citiesInfo.cities.slice(index + 1)
          ]
        },
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_IsOpen_CITY_SUCCEEDED: {
      const { citiesInfo } = state
      const { cityId, isOpenAllDay } = action.payload.data
      const index = findIndex(citiesInfo.cities, (r) => r.id === cityId || r._id === cityId)
      return {
        ...state,
        citiesInfo: {
          ...citiesInfo,
          cities: [
            ...citiesInfo.cities.slice(0, index),
            { ...citiesInfo.cities[index], isOpenAllDay: isOpenAllDay },
            ...citiesInfo.cities.slice(index + 1)
          ]
        },
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_CITY_KMS_RADIUS_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case UPDATE_CITY_KMS_RADIUS_SUCCEEDED: {
      const { citiesInfo } = state
      const { cityId, is5KmLimited, is10KmLimited } = action.payload.data
      const index = findIndex(citiesInfo.cities, (r) => r.id === cityId || r._id === cityId)
      return {
        ...state,
        citiesInfo: {
          ...citiesInfo,
          cities: [
            ...citiesInfo.cities.slice(0, index),
            { ...citiesInfo.cities[index], is5KmLimited, is10KmLimited },
            ...citiesInfo.cities.slice(index + 1)
          ]
        },
        requestState: requestStates.SUCCESS
      }
    }
    case UPDATE_CITY_KMS_RADIUS_FAILED:
    case CITIES_FETCH_FAILED:
    case CITY_SEARCH_FAILED:
    case CITIES_INFO_FETCH_FAILED:
    case UPDATE_DELIVERY_CITY_FAILED:
    case UPDATE_PICKUP_CITY_FAILED:
    case UPDATE_IsOpen_CITY_FAILED:
    case GET_CITY_FAILED:
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    case CREATE_CITY_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case CREATE_CITY_SUCCEEDED:
      const { data: newCity } = action.payload
      return {
        ...state,
        allCities: [...state.allCities, newCity],
        requestState: requestStates.SUCCESS
      }

    case CREATE_CITY_FAILED:
      return { ...state, requestState: requestStates.FAILED, errorMessage: parseErrorMessage(action) }

    default:
      return state
  }
}

const getState = (state) => state.cities
const getCity = (state) => getState(state).selectedCity
const getAllCities = (state) => getState(state).allCities
const getCitiesInfo = (state) => getState(state).citiesInfo
const getErrorMessage = (state) => getState(state).errorMessage
const getRequestState = (state) => getState(state).requestState

export const selectors = {
  getState,
  getAllCities,
  getCitiesInfo,
  getErrorMessage,
  getCity,
  getRequestState
}
