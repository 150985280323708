import React from 'react';

import Divider from '@material-ui/core/Divider'
import PriorityOrderCardActions from './PriorityOrderCardActions';
import OrderNoteContainer from './OrderNoteContainer';

export default function PriorityOrderCard({order, refreshOrders}) {            
  return (
      <div style={{paddingRight:5, paddingLeft:5, margin:5, border: order?.isUrgent ? "5px solid #b70000":"1px solid grey", borderRadius:5}}>
        <p style={{ fontWeight: 'bold', fontSize:18, margin:0, marginTop:5 }}>{order?.restaurantId?.nom} - {order?.city?.name} - #{order?.billNumber}</p>
            <div style={{display:"flex", justifyContent:"space-around", marginTop:5, marginBottom:5}}>
              <div className="">
                    <p style={{margin:0}}>
                        {order?.elapsedTime} min
                    </p>
                    <p style={{margin:0}}>
                        <span style={{fontWeight:"bold"}}>C</span> {order?.created_at}
                    </p>
                    <p style={{margin:0}}>
                        <span style={{fontWeight:"bold"}}>M</span> {order?.lastStatusStartDate}
                    </p>
                </div>
                <div className="">
                    <p style={{margin:0}}><span style={{fontWeight:"bold"}}>TR:</span> {order?.restaurantId?.telephone}</p>
                    {!!order?.riderId?.phone && <p style={{margin:0}}><span style={{fontWeight:"bold"}}>TL:</span> {order?.riderId?.phone}</p>}
                    <p style={{ fontWeight: 'bold', margin:0 }}>{`P: ${order?.orderPrice.text}`}</p>
                    <p style={{ fontWeight: 'bold', margin:0 }}>{`L: ${order?.deliveryPrice?.text || ''}`}</p>
                    <p style={{ fontWeight: 'bold', margin:0 }}>{`T: ${order?.totalPrice.text}`}</p>
                </div>
            </div>
            <Divider />
            <OrderNoteContainer order={order} />                                           
            <PriorityOrderCardActions currentOrder={order} refreshOrders={refreshOrders} />              
      </div>
  );
}
