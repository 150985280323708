import React from 'react'
import Button from '@material-ui/core/Button'
import MuiDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function Dialog({
  open,
  dialogText,
  dialogTitle,
  cancelButtonCaption,
  confirmButtonCaption,
  secondaryButtonCaption,
  onCancel,
  onValidation,
  onSecondaryButtonClick,
}) {
  const secondayButton = secondaryButtonCaption ? (
    <Button onClick={onSecondaryButtonClick} color="secondary">
      {secondaryButtonCaption}
    </Button>
  ) : null
  return (
    <MuiDialog open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="secondary">
          {cancelButtonCaption}
        </Button>
        {secondayButton}
        <Button onClick={onValidation} color="secondary">
          {confirmButtonCaption}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}
