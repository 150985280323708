import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { order } from 'core.services/api'
import OrderNoteTile from './OrderNoteTile';

export default function OrderNotesDialogs({ isOpen, toggleNotes, currentOrder }) {
    const [notes, setnotes] = React.useState([])

    React.useEffect(() => {
        fetchNotes()
    }, [])

    const fetchNotes = async () => {
        try {
            const data = await order.getPriorityOrdersNotes(currentOrder._id)            
            setnotes(data.orderPriorityLogs.sort((a, b) => a.updated_at < b.updated_at))
        } catch(e) {
                console.log("error", e)
        }
    }

  return (
     <Dialog
			open={isOpen}
			fullWidth={true}
			maxWidth="sm"
			id="restaurant-form-main-container"
		>
			<DialogTitle style={{ width:"100%", display: 'flex', justifyContent: 'center' }}>
                Notes : {currentOrder?.restaurantId?.nom} - {currentOrder?.city?.name} - #{currentOrder?.billNumber}
			</DialogTitle>
			<DialogContent>
                {notes.map(n => (
                <div style={{marginTop:10}}>
                    <OrderNoteTile note={n} />
                </div>))}
			</DialogContent>

			<DialogActions>			
				<button onClick={toggleNotes}>
					Fermer
				</button>				
		    </DialogActions>
		</Dialog>
  );
}
