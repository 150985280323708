import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import ButtonWithIcon from 'core.ui/ButtonWithIcon'

const styles = {
  mainContainer: {
    width: '100%',
    margin: '0',
    padding: '0',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  items: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  itemWithDeleteButton: {
    marginRight: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  ajouter: {
    backgroundColor: 'lightgreen',
    alignSelf: 'flex-start'
  }
}

export class ItemList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: []
    }
  }

  componentDidMount() {
    const { list = [] } = this.props
    this.setState({ items: list })
  }

  componentDidUpdate(prevProps, prevState) {
    const { list, onChange } = this.props
    const { items } = this.state
    if (list !== prevProps.list) {
      this.setState({ items: list })
    } else if (onChange && items !== prevState.items) {
      onChange({ target: { value: items } })
    }
  }

  handleItemsChange = index => value => {
    const { items } = this.state
    this.setState({
      items: [...items.slice(0, index), value, ...items.slice(index + 1)]
    })
  }

  handleAddItem = () => {
    const { items } = this.state
    const { itemTemplate } = this.props
    this.setState({ items: [...items, itemTemplate] })
  }

  handleDeleteItem = index => () => {
    const { items } = this.state
    this.setState({
      items: [...items.slice(0, index), ...items.slice(index + 1)]
    })
  }

  render() {
    const { className, classes, emptyLabel } = this.props
    const { items } = this.state
    return (
      <div
        className={classNames(
          'itemList-main-container',
          classes.mainContainer,
          className
        )}
      >
        <div className={classNames('itemList-items', classes.items)}>
          {isEmpty(items) ? <p>{emptyLabel}</p> : this.renderItems()}
          <div style={{marginLeft: 500}}>
          <ButtonWithIcon
          className={classes.ajouter}
          featherIcon="Plus"
          iconSize="24"
          onClick={this.handleAddItem}
        />
        </div>
        </div>
        
      </div>
    )
  }

  renderItems = () => {
    const { classes, componentToRender: Component, showStockSwitch,currentRestaurant,templates} = this.props
    const { items } = this.state
    return items.map((item, index) => {
      return (
        <div className={classes.itemWithDeleteButton}>
          <Component
            showStockSwitch={showStockSwitch}
            key={index}
            onChange={this.handleItemsChange(index)}
            {...this.makeItemProps(item, index)}
            currentRestaurant={currentRestaurant}
            templates={templates}
          />
          <ButtonWithIcon
            featherIcon="Trash2"
            iconSize="22"
            onClick={this.handleDeleteItem(index)}
          />
      
        </div>
      )
    })
  }

  makeItemProps = (item, index) => {
    const { propsToGiveToEachItem = [] } = this.props
    const itemProps = propsToGiveToEachItem.reduce(
      (acc, propName) => ({ ...acc, [propName]: item[propName] }),
      {}
    )
    return itemProps
  }
}

export default withStyles(styles)(ItemList)
