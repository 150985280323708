import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getOrder, addClaim, getConfig, resetError } from '../../core.modules/Claims/Actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { useState } from 'react'
import { parseImage } from 'core.utils/parser/image'
import UploadButton from 'components/UploadButton'
import classNames from 'classnames'
import Snackbar from 'core.ui/Snackbar'



const styles = {
  mainContainer: {
    margin: '20px auto',
    width: '562px',
    padding: '8px 20px ',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  rows: {
    marginBottom: '12px',
    paddingTop: '50px'
  },
  lines: {
    display: 'flex',
    flexDirection: 'row'
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewImage: { width: '200px' },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative',
    marginLeft: 20
  },
  buttonLoadingAnimation: {
    //color: blue[500],
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  },
  select: { margin: '6px 0' },
  selectLabel: {
    margin: '6px 0'
  }
}

const Addclaim = ({
  match,
  getOrder,
  Claims: { order, loading,config,err },
  addClaim,
  history,
  getConfig,
  resetError
}) => {
  const classes = styles
  const [open, setOpen] = React.useState(false)

  const [snackBarMessage, setSnackbarMessage] = useState('')
  const [snackBarSeverity, setsnackBarSeverityMessage] = useState('error')
  const [selectedItem, setSelectedItem] = useState('') 

  const handleCloseSnackbar = () => {
    setOpen(false)
  }
  React.useEffect(() => {
    getOrder(match.params.orderId)
  }, [match.params.orderId, getOrder])
  React.useEffect(()=>{
    getConfig()
  },[getConfig])
  React.useEffect(()=>{
    if(err){
      window.alert(err)
    }
  },[err])
  const [formData, setFormData] = useState({
    type: '',
    subType: '',
    message: '',
    orderId: match.params.orderId,
    cart: [],
    image: null
  })

  const { type, image, message, subType } = formData
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (e.target.name === 'products') {
      setSelectedItem(e.target.value)
      setFormData({
        ...formData,
        cart: [
          {
            _id: e.target.value
          }
        ]
      })
    }
  }

  const [imagePreview, setImagePreview] = useState(null)
  const handleImageSelect = (e) => {
    const { files } = e.target
    parseImage(files[0]).then((result) => {
      setFormData({ ...formData, image: files[0] })
      setImagePreview(result)
    })
  }
  const dispatch = useDispatch()
  const submit = (e) => {
  
    e.preventDefault()
    let productTypes
    productTypes = config.types.find((types) => types.value === type)
    if (!message || message.length <= 10) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir un message / minimum 10 caractére')
    } else if (!type) {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir un type')
    } else if(productTypes.value ===type && productTypes.requiresProduct===true && formData.cart.length==0){
      setOpen(true)
    setSnackbarMessage('Produit obligatoire pour ce type ') 
    } else {
      dispatch(resetError)
     
      addClaim(order.userId._id || order.userId.id, formData, history,imagePreview)
    }
  }
  return (
    <div style={{ textAlign: 'center' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={classes.mainContainer}>
          <h1>
            Ajouter une réclamation sur la commande #
            {order && order.billNumber.split('-')[4]}
          </h1>
          <div className={classNames(classes.rows, classes.imageWrapper)}>
            {image && (
              <img
                className={classNames(classes.rows, classes.previewImage)}
                src={imagePreview}
                style={{ width: '300px' }}
                alt="Claim"
              />
            )}
            <UploadButton title="Photo" onFileSelect={handleImageSelect} />
          </div>
          <TextField
            style={{ paddingBottom: '20px' }}
            className={classes.rows}
            name="message"
            value={message}
            onChange={(e) => handleChange(e)}
            fullWidth
            label="Message"
          />
          {
            (order.cart && order.cart.length)
              ?
                <FormControl style={{ paddingBottom: '20px' }}>
                  <InputLabel shrink>Produits</InputLabel>
                  <Select
                    name="products"
                    onChange={(e) => handleChange(e)}
                    value={selectedItem}
                  >
                    {order &&
                      order.cart.map((itemElem) => (
                        <MenuItem key={itemElem._id} value={itemElem._id}>
                          {`${itemElem.title} - ${itemElem.price.totalPrice.text}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              :
              <FormControl style={{ paddingBottom: '20px' }}>
                <InputLabel shrink>Produits</InputLabel>
                <Select
                  name="products"
                  onChange={(e) => handleChange(e)}
                  value={selectedItem}
                >
                  {order &&
                    order.products.map((productElem) => (
                      <MenuItem key={productElem._id} value={productElem._id}>
                        {`${productElem.name} - ${productElem.totalPrice.text}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
          }
          
          <FormControl style={{ paddingBottom: '20px' }}>
            <InputLabel shrink>Type</InputLabel>
            <Select name="type" value={type} onChange={(e) => handleChange(e)}>
              {!loading && config.types.map((type)=>
                <MenuItem
                value={ type.value}
              >
                {' '}
                { type.label}
              </MenuItem>
              )}
              
              
            </Select>
          </FormControl>
          {type === config.types[0].value ? 
            <FormControl style={{ paddingBottom: '20px' }}>
            <InputLabel shrink>Sous-Type</InputLabel>
            <Select
              name="subType"
              value={subType}
              onChange={(e) => handleChange(e)}
            >
              {config.types[0].subTypes.map((subtype)=>
              <MenuItem
              value={
                subtype.value
              }
            >
              {
                subtype.label
              }
            </MenuItem>
              )}
                   
            </Select>
          </FormControl>
         :type === config.types[1].value ?
          <FormControl style={{ paddingBottom: '20px' }}>
            <InputLabel shrink>Sous-Type</InputLabel>
            <Select
              name="subType"
              value={subType}
              onChange={(e) => handleChange(e)}
            >
                  {config.types[1].subTypes.map((subtype)=>
              <MenuItem
              value={
                subtype.value
              }
            >
              {
                subtype.label
              }
            </MenuItem>
              )}   
            </Select>
          </FormControl>
         :  <FormControl style={{ paddingBottom: '20px' }}>
            <InputLabel shrink>Sous-Type</InputLabel>
            <Select
              name="subType"
              value={subType}
              onChange={(e) => handleChange(e)}
            >
                  {config.types[2].subTypes.map((subtype)=>
              <MenuItem
              value={
                subtype.value
              }
            >
              {
                subtype.label
              }
            </MenuItem>
              )}
                  
            </Select>
          </FormControl>}
          
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => submit(e)}
          >
            Créer la réclamation
          </Button>
          <Snackbar
            open={open}
            onClose={handleCloseSnackbar}
            severity={snackBarSeverity}
            message={snackBarMessage}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToprops = (state) => ({
  Claims: state.Claims
})
export default connect(mapStateToprops, { getOrder, addClaim,getConfig,resetError })(Addclaim)
