import React, { Component } from 'react'
import { Typography, Paper, Divider } from '@material-ui/core'
import List from 'core.ui/List'
import { order } from 'core.services/api'
import { makeDateString } from 'core.utils/parser/time'
import CircularProgress from '@material-ui/core/CircularProgress';



class OrderTracking extends Component {
  state = {
    riders: [],
    logs: [],
    loading:true
  }

  fetchLogs(){
      const {orderId} = this.props
      order.getTracking(orderId).then((tracking)=>{
          this.setState({riders:tracking.riders,logs:tracking.logs,loading:false})
      })
      .catch((err)=>{
        this.setState({loading:false})  
        console.log(err.response)})
  }

  componentDidMount(){
      this.fetchLogs()
  }

  makeRidersList = () => {
    const riders = this.state.riders
    return riders.map(r => {
      const { firstName, lastName, email } = r
      return [
        firstName,
        lastName,
        email,
      ]
    })
  }

  makeLogsList = () => {
    const logs = this.state.logs
    return logs.map(r => {
      const { note, type, timestamp } = r
      return [
        note,
        type,
        makeDateString(timestamp),
      ]
    })
  }

  render() {
    const { classes } = this.props
    const {loading} = this.state
    if(loading) {
        return(
            <CircularProgress style={{marginLeft:'50%',marginRight:'50%'}} color="secondary" />

        )
    } return (
      <div>
       <Typography variant="h4">Logs Commande</Typography>
        <Typography variant="h6">Notes</Typography>
        <List
          className={classes.productsTable}
          columns={['Note', 'Type', 'Date']}
          list={this.makeLogsList()}
          emptyMessage="Cette commande n'a pas de notes"
        />
        <Typography variant="h6">Livreurs</Typography>
        <List
          className={classes.productsTable}
          columns={['Nom', 'Prénom', 'Email']}
          list={this.makeRidersList()}
          emptyMessage="Cette commande n'a pas de livreurs"
        />
      </div>
    )
  }
}

export default OrderTracking
