import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from "date-fns/locale/fr";

import Login from './components/Login/Login'
import Dashboard from './components/Dashboard/Dashboard'
import { PrivateRoute } from 'core.ui/PrivateRoute/PrivateRoute'

import 'App.css'
import { useAuth } from './contexts/AuthContext';

import moment from "moment"
import 'moment/locale/fr'
moment.locale('fr')

function App() {
  const { state: {token, user}, setUser, setUserToken } = useAuth()

  const storedToken = localStorage.getItem('token')

  if (storedToken !== null && !token) {    
    setUserToken(storedToken)
    setUser(user)
  } 

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <div className="App">
        <header className="App-header">
          <Switch>
            {storedToken === null && <Route path="/login" exact component={Login} />}
            <PrivateRoute path="/*" component={Dashboard} />
          </Switch>
        </header>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default App
