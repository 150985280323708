import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Route, Switch, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'
import { selectors as currentRestaurantInfosSelectors } from 'core.modules/currentRestaurant/infos/reducer'
import {
  selectors as currentRestaurantSupplementGroupsSelectors
} from 'core.modules/currentRestaurant/supplementGroups/reducer'

import SelectRestaurant from 'components/SelectRestaurant'
import Dialog from 'core.ui/Dialog'
import List from 'core.ui/List'
import { Button } from '@material-ui/core'

import SupplementGroupCreate from '../SupplementGroupCreate/SupplementGroupsCreate'
import SupplementGroupEdit from '../SupplementGroupEdit'
import { supplementGroup } from '../../core.services/api'
import { fetchRestaurantSupplementGroupsRequested, deleteRestaurantSupplementGroupRequested } from '../../core.modules/currentRestaurant/supplementGroups/reducer'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    marginTop: '10px',
    fontSize: '10px'
  }
}

class SupplementGroupsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog:false,
      openConfirmDeleteDialog:false,
      usedOptionErrorMessage:''
    }
  }

  componentDidMount() {
    const { fetchAllRestaurants } = this.props
    fetchAllRestaurants()
  }

  makeRows = supplementGroups => {
    return supplementGroups.map(sg => [
      sg.name, 
      sg.maxSupplementNumber ? sg.maxSupplementNumber : `Non spécifié`
    ])
  }

  makeIds = supplementGroups => {
    return supplementGroups.map(s => get(s, '_id', get(s, 'id', '')))
  }

  handleEdit = id => {
    const { history } = this.props
    history.push(`/restaurants/supplements-group/edit?id=${id}`)
  }
  //
  // handleDuplicate = id => {
  //   this.props.duplicateProduct(id)
  // }

  handleDelete = supplementId => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: supplementId
    })
  }

  deleteSupplementGroup = () => {
    const { fetchRestaurantSupplementGroups, currentRestaurantId } = this.props
    supplementGroup.deleteSupplementGroup(this.state.idToDelete, currentRestaurantId)
    .then(() => {
      this.closeDeleteDialog()
      fetchRestaurantSupplementGroups(currentRestaurantId)
    })
    .catch((err)=> {
      console.log(err)
      this.setState({ usedOptionErrorMessage: err.response.data.errors[0].message })
      this.setState({ openDeleteDialog: false })
      this.setState({ openConfirmDeleteDialog: true })
    })
  }

  confirmDelete = (deleteSupplements) => () => {
    const { deleteRestaurantSupplementGroups, currentRestaurantId,  fetchRestaurantSupplementGroups } = this.props
    supplementGroup.confirmDeleteSupplementGroup(this.state.idToDelete, deleteSupplements, currentRestaurantId)
    .then(()=> {
      this.closeConfirmDeleteDialog()
      fetchRestaurantSupplementGroups(currentRestaurantId)
    })
    
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  closeConfirmDeleteDialog = () => {
    this.setState({
      openConfirmDeleteDialog: false,
      idToDelete: null
    })
  }


  render() {
    const { openDeleteDialog, openConfirmDeleteDialog, usedOptionErrorMessage } = this.state
    const { classes, city, currentRestaurantSupplementGroups, history } = this.props
    return (
      <Switch>
        <Route
          path="/restaurants/supplements-group/new"
          render={props => {
            return (
              <SupplementGroupCreate
                history={history}
              />
            )
          }}
        />
        <Route
            path="/restaurants/supplements-group/edit"
            render={(props) => {
              const id = props.location.search.split('=')[1]
              const data = currentRestaurantSupplementGroups.find((supplementGroup) => supplementGroup._id === id)
              return <SupplementGroupEdit id={id} data={data} {...props} />
            }}
          />
        <Route path="/restaurants/supplements-group" exact>
          <div key="list-main-container" className={classes.mainListContainer}>
            <div className={classes.header}>
              <SelectRestaurant key="select-restaurants" city={city} />
              <Link className={classes.addButton} to="/restaurants/supplements-group/new">
                <Button variant="contained" color="secondary">
                  Ajouter un Groupe de supplément
                </Button>
              </Link>
            </div>
            <List
              key="list-of-options"
              list={this.makeRows(currentRestaurantSupplementGroups)}
              columns={['Nom', 'Nombre de supplément Max']}
              ids={this.makeIds(currentRestaurantSupplementGroups)}
              actions={{
                handleEdit: this.handleEdit,
                handleDelete: this.handleDelete
              }}
              emptyMessage="Ce restaurant n’a aucun supplément"
            />
            <Dialog
              open={openDeleteDialog}
              dialogText="Confirmer la suppression"
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={this.closeDeleteDialog}
              onValidation={this.deleteSupplementGroup}
            />
            <Dialog
              open={openConfirmDeleteDialog}
              dialogText={usedOptionErrorMessage}
              dialogTitle="Confirmer la suppression"
              cancelButtonCaption="Annuler"
              secondaryButtonCaption="Supprimer le groupe uniquement"
              confirmButtonCaption="Supprimer le groupe + tous ses supplements"
              onCancel={this.closeConfirmDeleteDialog}
              onSecondaryButtonClick={this.confirmDelete(false)}
              onValidation={this.confirmDelete(true)}
            />
          </div>
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRestaurantSupplementGroups: currentRestaurantSupplementGroupsSelectors.getSupplementGroups(
    state
  )
})

const mapDispatchToProps = dispatch => ({
  fetchAllRestaurants: () => {
    dispatch(fetchAllRestaurantsRequested())
  },
  fetchRestaurantSupplementGroups: restaurantId => {
    dispatch(fetchRestaurantSupplementGroupsRequested(restaurantId))
  },
  deleteRestaurantSupplementGroups: (restaurantId, deleteSupplements)  => {
    dispatch(deleteRestaurantSupplementGroupRequested(restaurantId, deleteSupplements))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SupplementGroupsList)
