import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Switch } from '@material-ui/core'

import RushCurrentStatus from './RushCurrentStatus'
import RushCounters from './RushCounters'

import { toggleRushMode, selectRushStatus, selectRushInterval, setFilters } from './../ui-slice'

const RushComponent = ({ classes }) => {
  const dispatch = useDispatch()

  const { isLoading, rushMode, priorityOrders } = useSelector(
    (state) => state.uiOrderList
  )
  const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

  React.useEffect(() => {
    if (permissions.order && permissions.order?.displayAll === false) {
      dispatch(setFilters({ orderStatus: 'current' }))
    }
  }, [])

  return (
    <>
      <div className={classes.rushSwitchContainer}>
        <FormControlLabel
          control={
            <Switch
              size="small"
              color="primary"
              checked={rushMode.active}
              onChange={(e) =>
                !isLoading && dispatch(toggleRushMode(e.target.checked, permissions.order && permissions.order?.displayAll === false))
              }
            />
          }
          label="Rush"
        />
      </div>
      <div className={classes.rushSwitchContainer}>
        <RushCurrentStatus
          classes={classes}
          isActive={rushMode.active}
          filterValue={rushMode.selectedStatus || rushMode.selectedInterval}
        />
      </div>
      <RushCounters
        classes={classes}
        rushMode={rushMode.active}
        statusCounts={rushMode.statusCounts}
        intervalCounts={rushMode.intervalCounts}
        priorityOrdersCount={priorityOrders.length}
        renderOrdersByStatus={(status) =>
          !isLoading && status && dispatch(selectRushStatus(status))
        }
        renderOrdersByInterval={(interval) =>
          !isLoading && interval && dispatch(selectRushInterval(interval))
        }
      />
    </>
  )
}

export default RushComponent
