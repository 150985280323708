import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import TextField from 'core.ui/TextField'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textFields: {
    width: '170px',
    margin: '10px 10px'
  }
}

class ProductOptionChoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      price: '',
      isAvailable: true,
      type: '',
      isHidden: true,
      checkoutSystemId: null,
      isAbstract: false, 

    }
  }

  componentDidMount() {
    const { price, isAvailable, type, isHidden,checkoutSystemId, isAbstract } = this.props
    this.setState({ price, isAvailable, type, isHidden,checkoutSystemId, isAbstract })

  }

  componentDidUpdate(prevProps) {
    const { price, isAvailable, type, isHidden ,checkoutSystemId, isAbstract } = this.props
    if (
      price !== prevProps.price ||
      isAvailable !== prevProps.isAvailable ||
      type !== prevProps.type ||
      isHidden !== prevProps.isHidden ||
      checkoutSystemId !== prevProps.checkoutSystemId || 
      isAbstract !== prevProps.isAbstract 

    ) {
      this.setState({ price, isAvailable, type, isHidden ,checkoutSystemId, isAbstract })
    }
  }

  handleChange = field => e => {
    const { value } = e.target
    const { onChange } = this.props
    this.setState({ [field]: value }, () => {
      onChange(this.state)
    })
  }

  handleSwitchChange = field => e => {
    const { checked } = e.target
    const { onChange } = this.props
    this.setState({ [field]: checked }, () => {
      onChange(this.state)
    })
  }
  handleSwitchHiddenChange = field => e => {
    const { checked } = e.target
    const { onChange } = this.props
    this.setState({ [field]: checked }, () => {
      onChange(this.state)
    })
  }
  render() {
    const { classes, price, type,isAvailable, showStockSwitch, isHidden ,checkoutSystemId,isAbstract,currentRestaurant,templates} = this.props
    return (
      <div className={classes.mainContainer}>
        <TextField
          className={classes.textFields}
          value={type}
          onChangeComplete={this.handleChange('type')}
          label="Nom"
        />
        <TextField
          className={classes.textFields}
          value={price}
          type="number"
          onChangeComplete={this.handleChange('price')}
          label="Prix TTC"
        />
        {showStockSwitch && (<FormControlLabel
              control={
                <Switch
                  checked={isAvailable}
                  onChange={this.handleSwitchChange('isAvailable')}
                />
              }
              label="En stock"
            />)}
             {(currentRestaurant?.posInfo?.type || templates?.checkTemplate) ?
       <div> <TextField
          label="Id système de caisse"
          value={checkoutSystemId}
          onChangeComplete={this.handleChange('checkoutSystemId')}
          type="text"
        />
        </div>
        : <div></div> }
        <div>
        {(currentRestaurant?.posInfo?.type || templates?.checkTemplate) ?
          <FormControlLabel
            control={
              <Switch
                checked={isAbstract}
                onChange={this.handleSwitchChange('isAbstract')}
              />
            }
            label="Abstract"
          />
          : <div></div> }
            <FormControlLabel
              control={
                <Switch
                  checked={isHidden}
                  onChange={this.handleSwitchHiddenChange('isHidden')}
                />
              }
              label="caché (ticket caisse)"
            /></div>
      </div>
    )
  }
}

export default withStyles(styles)(ProductOptionChoice)
