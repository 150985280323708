import {
  ADD_CLAIM,
  BACK_TO_PREVIEW_PAGE,
  CLAIMS_ORDER_GROUP,
  CLAIM_ERROR,
  RESPOND_CLAIM,
  GET_ALL_CLAIMS,
  GET_CLAIMED_ORDER,
  ORDER_CLAIM,
  GET_CONFIG,
  RESET_ERROR,
  CLAIM_PAGINATION
} from './Types'

const initialState = {
  Claims: [],
  order: null,
  loading: true,
  ClaimGroup: [],
  OrderClaims: [],
  config: [],
  err: null,
  filter: {
    pagination: { page: 1, limit: 20 },
    city: '',
    email: '',
    restaurantId: '',
    startDate: '',
    endDate: '',
    closed: false
  }

}
export default function (state = initialState, action) {
  const { payload, type } = action
  switch (type) {
    case ADD_CLAIM:
      return { ...state, loading: false, err: null }
    case GET_ALL_CLAIMS:
      return { ...state, Claims: payload, err: null }
    case ORDER_CLAIM:
      return { ...state, OrderClaims: payload, loading: false, err: null }
    case GET_CLAIMED_ORDER:
      return { ...state, order: payload, loading: false, err: null }
    case CLAIMS_ORDER_GROUP:
      return {
        ...state, ClaimGroup: payload.res,
        filter: {
          pagination: { page: payload.filter.pagination.page, limit: 20 },
          city: payload.filter.city,
          email: payload.filter.email,
          restaurantId: payload.filter.restaurantId,
          startDate: payload.filter.startDate,
          endDate: payload.filter.endDate,
          closed: payload.filter.closed
        },
        loading: false, err: null
      }
    case RESPOND_CLAIM:
      return {
        ...state,
        OrderClaims: state.OrderClaims.map((claim) =>
          (claim._id || claim.id) === payload.id ? payload : claim
        ),
        loading: false,
        err: null
      }
    case GET_CONFIG:
      return {
        ...state,
        config: payload,
        err: null
      }
    case CLAIM_PAGINATION:
      return {
        ...state,
        filter: { ...state.filter, pagination: { page: payload, limit: 20 } }
      }
    case BACK_TO_PREVIEW_PAGE:
    case CLAIM_ERROR:
      return { ...state, err: payload }
    case RESET_ERROR:
      return { ...state, err: null }
    default:
      return state
  }
}
