import { option } from 'core.services/api'
import {findIndex} from 'lodash'
import { createRequestActions } from 'core.utils/requestActions'
import { template } from '../../../core.services/api'
import { requestStates } from '../../../core.utils/requestStates'
import { parseErrorMessage } from '../../../core.utils/requestActions'
export const {
  FETCH_RESTAURANT_OPTIONS_REQUESTED,
  FETCH_RESTAURANT_OPTIONS_SUCCEEDED,
  FETCH_RESTAURANT_OPTIONS_FAILED,
  fetchRestaurantOptionsSucceeded,
  fetchRestaurantOptionsFailed,
  fetchRestaurantOptionsStateReducer
} = createRequestActions('FETCH_RESTAURANT_OPTIONS')

export const fetchRestaurantOptionsRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_OPTIONS_REQUESTED,
    data: {
      restaurantId: id
    }
  })
  option
    .getRestaurantOptions(id)
    .then(data => {
      dispatch(fetchRestaurantOptionsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantOptionsFailed(error))
    })
}
export const fetchTemplateOptionsRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_OPTIONS_REQUESTED,
    data: {
      templateId: id
    }
  })
  template
    .getOptionBytemplateId(id)
    .then(data => {
      dispatch(fetchRestaurantOptionsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantOptionsFailed(error))
    })
}

export const {
  CREATE_RESTAURANT_OPTION_REQUESTED,
  CREATE_RESTAURANT_OPTION_SUCCEEDED,
  CREATE_RESTAURANT_OPTION_FAILED,
  createRestaurantOptionSucceeded,
  createRestaurantOptionFailed
} = createRequestActions('CREATE_RESTAURANT_OPTION')

export const createRestaurantOptionRequested = (
  restaurantId,
  optionInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_OPTION_REQUESTED,
    data: {
      restaurantId,
      option: optionInfos
    }
  })
  option
    .add(restaurantId, optionInfos)
    .then(data => {
      dispatch(createRestaurantOptionSucceeded(data))
    })
    .catch(error => {
      dispatch(createRestaurantOptionFailed(error))
    })
}
export const createTemplateOptionRequested = (
  templateId,
  optionInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_OPTION_REQUESTED,
    data: {
      templateId,
      option: optionInfos
    }
  })
  template
    .add(templateId, optionInfos)
    .then(data => {
      dispatch(createRestaurantOptionSucceeded(data))
    })
    .catch(error => {
      dispatch(createRestaurantOptionFailed(error))
    })
}

export const {
  DELETE_RESTAURANT_OPTION_REQUESTED,
  DELETE_RESTAURANT_OPTION_SUCCEEDED,
  DELETE_RESTAURANT_OPTION_FAILED,
  deleteRestaurantOptionSucceeded,
  deleteRestaurantOptionFailed,
  deleteRestaurantSupplementRequestStateReducer
} = createRequestActions('DELETE_RESTAURANT_OPTION')

export const deleteRestaurantOptionRequested = (
  restaurantId,
  optionId
) => dispatch => {
  dispatch({
    type: DELETE_RESTAURANT_OPTION_REQUESTED,
    data: {
      restaurantId,
      optionId
    }
  })
  return option
    .deleteOption(restaurantId, optionId)
    .then(data => {
      dispatch(
        deleteRestaurantOptionSucceeded({
          restaurantId,
          optionId
        })
      )
    })
    .catch((err,next) => {
      dispatch(deleteRestaurantOptionFailed(err))
    })
}

export const {
  CONFIRM_DELETE_RESTAURANT_OPTION_REQUESTED,
  CONFIRM_DELETE_RESTAURANT_OPTION_SUCCEEDED,
  CONFIRM_DELETE_RESTAURANT_OPTION_FAILED,
  confirmDeleteRestaurantOptionSucceeded,
  confirmDeleteRestaurantOptionFailed,
  confirmDeleteRestaurantSupplementRequestStateReducer
} = createRequestActions('CONFIRM_DELETE_RESTAURANT_OPTION')

export const confirmDeleteRestaurantOptionRequested = (
  restaurantId,
  optionId
) => dispatch => {
  dispatch({
    type: CONFIRM_DELETE_RESTAURANT_OPTION_REQUESTED,
    data: {
      restaurantId,
      optionId
    }
  })
  option
    .confirmDeleteOption(restaurantId, optionId)
    .then(data => {
      dispatch(
        confirmDeleteRestaurantOptionSucceeded({
          restaurantId,
          optionId
        })
      )
    })
    .catch(error => {
      dispatch(confirmDeleteRestaurantOptionFailed(error))
      throw new Error(error)
    })
}

export const defaultState ={ options:[],requestState: ''}
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_RESTAURANT_OPTIONS_REQUESTED:
    case CREATE_RESTAURANT_OPTION_REQUESTED:
    return { ...state, requestState: requestStates.PENDING }
    case FETCH_RESTAURANT_OPTIONS_SUCCEEDED: {
      const { options } = action.payload.data
      return {
        ...state,
        options: [...options],
        requestState: requestStates.SUCCESS
      }
    }
    case CREATE_RESTAURANT_OPTION_SUCCEEDED:
      const data = action.payload.data
      return {
        ...state,
        options: [...state.options, { ...data, _id: data?.id || data?._id }],
        requestState: requestStates.SUCCESS
      }
    case DELETE_RESTAURANT_OPTION_SUCCEEDED:
      let { optionId } = action.payload.data
      let index = findIndex(state, o => o._id === optionId)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    case CONFIRM_DELETE_RESTAURANT_OPTION_SUCCEEDED:
       optionId  = action.payload.data
       index = findIndex(state, o => o._id === optionId)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    case CREATE_RESTAURANT_OPTION_FAILED:
      return { 
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    default:
      return state
  }
}

const getState = state => state.currentRestaurant.options

export const selectors = {
  getState
}
