import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import {
  Button,
  CircularProgress
} from '@material-ui/core'
import classnames from 'classnames'
import { isEmpty, isNumber } from 'lodash'

import {
  isEmailValid,
  isTelephoneValid
} from 'core.utils/formValidation.js'

import TextField from 'core.ui/TextField'

const styles = {
  mainContainer: {
    margin: '20px auto',
    width: '562px',
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'column'
  },
  rows: {
    marginBottom: '12px'
  },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonLoadingAnimation: {
    color: blue[500],
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  }
}

export const requiredFields = [
  'prenom',
  'nom',
  'email',
  'telephone',
  'password'
]

export const defaultState = {
  form: {
    prenom: '',
    nom: '',
    email: '',
    telephone: '',
    password: '',
  },
  showErrors: false,
  validFields: {}
}
class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const { nom, prenom, email, password, telephone } = data
      this.setState(
        {
          form: {
            ...this.state.form,
            nom,
            prenom,
            email,
            password,
            telephone
          }
        },
        () => {
          const validFields = this.getValidFields()
          this.setState({
            validFields
          })
        }
      )
    }
  }

  isFieldValid = (field, value) => {
    const { isPasswordRequired = true } = this.props
    return field === 'prenom'
      ? !isEmpty(value)
      : field === 'nom'
      ? !isEmpty(value)
      : field === 'email'
      ? isEmailValid(value)
      : field === 'telephone'
      ? isTelephoneValid(value)
      : field === 'password'
      ? isPasswordRequired
        ? !isEmpty(value)
        : true
      : isNumber(value) || !isEmpty(value)
  }

  getValidFields = () => {
    const { form } = this.state
    const validFields = requiredFields.map(field =>
      this.isFieldValid(field, form[field])
    )
    return Object.fromEntries(requiredFields.map((f, i) => [f, validFields[i]]))
  }

  handleChange = field => e => {
    const { value } = e.target
    const { form, validFields } = this.state
    const fields = field.split('.')
    if (fields.length === 1) {
      this.setState({
        form: { ...form, [field]: value },
        validFields: {
          ...validFields,
          [field]: this.isFieldValid(field, value)
        }
      })
    } else {
      this.setState({
        form: {
          ...form,
          [fields[0]]: { ...form[fields[0]], [fields[1]]: value }
        }
      })
    }
  }

  handleSubmit = () => {
    const { id, onSubmit } = this.props
    const { validFields, form } = this.state
    if (Object.values(validFields).length !== Object.values(form).length || Object.values(validFields).some(f => !f)) {
      this.setState({ validFields, showErrors: true })
    } else {
      onSubmit(this.state.form, id)
    }
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  render() {
    const { form, showErrors, validFields } = this.state
    const {
      prenom,
      nom,
      email,
      password,
      telephone
    } = form
    const { classes, showLoading, id } = this.props
    return (
      <div
        className={classnames(classes.mainContainer)}
        id="restaurant-form-main-container"
      >
        <div
          id="restaurant-form-form-container"
          className={classes.mainContainer}
        >
          {/* PRENOM */}
          <TextField
            className={classes.row}
            value={prenom}
            onChangeComplete={this.handleChange('prenom')}
            fullWidth
            label="Prenom"
            error={showErrors && !validFields.prenom}
            helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }
          />

          {/* NOM */}
          <TextField
            className={classes.row}
            value={nom}
            onChangeComplete={this.handleChange('nom')}
            fullWidth
            label="Nom"
            error={showErrors && !validFields.nom}
            helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }
          />

          {/* EMAIL */}
          <TextField
            className={classes.rows}
            value={email}
            onChangeComplete={this.handleChange('email')}
            label="Email"
            error={showErrors && !validFields.email}
            helperText={
              showErrors && !validFields.email
                ? 'Entrez une adresse mail valide'
                : ''
            }
          />

          {/* MOT DE PASSE */}
          <TextField
            className={classes.rows}
            value={password}
            type="text"
            inputProps={{
              autoComplete: 'new-password'
            }}
            onChangeComplete={this.handleChange('password')}
            label="Mot de passe"
            error={showErrors && !validFields.password}
            helperText={
              showErrors && !validFields.password
                ? 'Entrez un mot de passe'
                : ''
            }
          />

          {/* TÉLÉPHONE */}
          <TextField
            className={classes.rows}
            value={telephone}
            onChangeComplete={this.handleChange('telephone')}
            label="Téléphone"
            error={showErrors && !validFields.telephone}
            helperText={
              showErrors && !validFields.telephone
                ? 'Entrez un téléphone valide'
                : ''
            }
          />

        </div>
        <div className={classes.formButtonsContainer}>
          <Button
            onClick={this.handleCancel}
            variant="contained"
            color="default"
          >
            Annuler
          </Button>
          <div className={classes.loadingButtonWrapper}>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              disabled={showLoading}
            >
              {!id ? 'Ajouter' : 'Modifier'}
            </Button>
            {showLoading && (
              <CircularProgress
                size={30}
                className={classes.buttonLoadingAnimation}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps)(withStyles(styles)(UserForm))
