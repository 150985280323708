import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Paper, Divider, Button, InputLabel, Select, MenuItem, FormControl, Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { get, uniqueId, isEmpty } from 'lodash'

import { selectors as restaurantsSelectors } from 'core.modules/restaurants/reducer'
import { selectors as ridersSelectors } from 'core.modules/riders/reducer'

import { getFrenchStatus } from 'core.utils/parser/orderStatus'
import { makeDateString } from 'core.utils/parser/time'
import { makeUserFullName } from 'core.utils/parser/user'
import { makeRiderFullName } from 'core.utils/parser/rider'
import { parsePrice } from 'core.utils/parser/price'
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom'

import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Chip,
  ListItem,
  ListItemText,
} from "@mui/material";

import Dialog from 'core.ui/Dialog'

import { order as OrderApi } from 'core.services/api'

import OrderTracking from 'components/OrderTracking'
import ModalAddTracking from "./Addtracking"

import List from 'core.ui/List'
import { orderStatus } from '../../core.utils/parser/orderStatus'
import { renderDeliveryIcon } from '../../core.utils/parser/order'
import RegulationList from '../RegulationList'
import { order, restaurant } from '../../core.services/api'
import { resetError } from '../../core.modules/Claims/Actions'
import { Routes } from '../../core.utils/routes'

export const orderReductionType = {
  DELIVERY: 'delivery',
  BALANCE: 'balance',
  REFERRAL: 'referral',
  PROMO: 'promo',
  DEAL: 'deal'
}


const styles = {
  mainContainer: { width: '70%', margin: '15px', padding: '15px' },
  horizontalContainer: {
    marginTop: '12px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  productsTable: {
    width: '100%',
    margin: 'auto'
  },
  subSection: {
    flex: '1 1 auto'
  },
  smallLists: {
    listStyle: 'none'
  },
  infoElement: {
    margin: '10px 0'
  },
  label: {
    color: '#202090',
    fontSize: '13px',
    marginBottom: '3px',
    textDecoration: 'underline'
  }
}

class OrderDetails extends React.Component {
  state = {
    order: null,
    loading: true,
    openDeleteDialog: false,
    openSnackBar: false,    
    regulations: [],
    displayRegulationForm: false,
    disable:false,
    products:[],
    supplements:[],
    reductions:[],
    openTrackingModal: false,
    expanded: []
  }

  handleExpandClick = () => {
    const {order, expanded} = this.state;
    
    if(order && order.cart && order.cart.length > 0) {
      if(expanded.length === 0 ) {
        const nodeIds = []

        order.cart.forEach(item => {
          this.getNodeIds(item, "selectedModifierGroups", nodeIds)
        })

        this.setState({
          expanded: nodeIds
        })
      }
      else {
        this.setState({
          expanded: []
        })
      }
    }
    
  }

  handleToggle = (_,nodeIds) => {
    this.setState({
      expanded: nodeIds
    })
  }

  getNodeIds = (item, type, nodeIds) => {
    if(item[type].length > 0) {
      nodeIds.push(item._id)

      item[type].forEach(subItem => {
        this.getNodeIds(subItem, type === "selectedModifierGroups"
        ? "selectedItems"
        : "selectedModifierGroups", nodeIds)
      })
    }
  }

  handleClickOpen = () => {
    this.setState({openTrackingModal: true});
  };

  handleClose = () => {
    this.setState({openTrackingModal: false});
  };

  componentDidMount() {
    const { orderId } = this.props.match.params

    if (!this.props.order) {
      OrderApi.getOrder(orderId)
        .then((order) => {
          this.setState({ order, loading: false }, () => {
            this.handleExpandClick()
          })
          this.parseReductions(order)
        }).then(()=>{
            if (this.state.order?.restaurantId._id || this.state.order?.restaurantId.id) {
              this.fetchProducts(this.state.order?.restaurantId._id || this.state.order?.restaurantId.id)
            }
          }
        )
        .catch(() => this.setState({ loading: false }))
    } else {
      this.setState({ loading: false })
    }
   
    this.fetchRegulations()
   
  }

  fetchProducts = async (id) => {
   const products = await restaurant.getRestaurantProducts(id)
   this.setState({ products })
  }

  checkoutsystem = async () => {
    const { orderId } = this.props.match.params
    try {
     
     this.setState({ disable: true })
     await order.isCheckoutSystemResendEnabled(orderId)

    } catch (error) {
      console.error(error)
    }
  }

  renderInfo = (label, ...info) => {
    const { classes } = this.props
    return (
      <div className={classes.infoElement}>
        <div className={classes.label}>{label}</div>
        {info.map((i) => (
          <div key={uniqueId(i)}>
            {label === 'Téléphone:' ? '#31#' : ''}
            {i}
          </div>
        ))}
      </div>
    )
  }

  displayOptions = (options) => {
    const list = options.map((option, index) => (
      <li key={uniqueId(index)}>{`${option.name} : ${
        option.value
      } — ${(option.price.text)}`}</li>
    ))
    return <ul className={this.props.classes.smallLists}>{list}</ul>
  }

  displaySupplements = (supplements) => {
    const list = supplements.map((supplement, index) => {
      const { name, number, price } = supplement
      return (
        <li key={uniqueId(index)}>{`${name} × ${number} — ${price.text}`}</li>
      )
    })
    return <ul className={this.props.classes.smallLists}>{list}</ul>
  }

  displayProduct = (product, index) => {
    const { options, supplements, productId: productDetails } = product
    return (
      <div key={uniqueId(index)}>
        {this.renderInfo('Nom :', productDetails?.name)}
        {!isEmpty(options) &&
          this.renderInfo('Options :', this.displayOptions(options))}
        {!isEmpty(supplements) &&
          this.renderInfo('Supplements:', this.displaySupplements(supplements))}
      </div>
    )
  }

  makeProductList = () => {
    const order = this.props.order || this.state.order || {}
    const displayIncreasedPrice = !!order.increasedTotalPrice

    const products = get(
      this.props,
      'order.products',
      get(this.state, 'order.products', [])
    )
    return products.map((p, index) => {
      const { productId: productDetails } = p

      const priceText = displayIncreasedPrice && p?.increasedPrice && p?.price.value !== p?.increasedPrice?.value 
        ? `🍴: ${p?.price?.text} 👨‍🦰:${p?.increasedPrice?.text}` 
        : `${p?.price?.text}`
      const totalPriceText = displayIncreasedPrice && p?.increasedTotalPrice && p?.totalPrice.value !== p?.increasedTotalPrice?.value 
        ? `🍴: ${p?.totalPrice?.text} 👨‍🦰:${p?.increasedTotalPrice?.text}` 
        : `${p?.totalPrice?.text}`

      return [
        this.displayProduct(p, index),
        productDetails?.header ? productDetails?.header : 'Aucune Catégorie',
        priceText,
        `${productDetails?.tax} %`,
        totalPriceText,
      ]
    })
  }

  fetchRegulations = async () => {
    const { orderId } = this.props.match.params
    try {
      const regulations = await order.getRegulations(orderId)
      this.setState({ regulations, loading: false })
    } catch (error) {
      this.setState({ loading: false })
    }
  }
 

  deleteRegulation = async (id) => {
    try {
      await order.deleteRegulation(id)
      this.fetchRegulations()      
    } catch (error) {      
    }
  }

  updateRegulation = async (id, payload) => {
    try {
      await order.updateRegulation(id, payload)
      this.fetchRegulations()      
    } catch (error) {      
    }
  }

  handleRegulationSubmit = async () => {
    this.setState({ loading: true })
    await this.fetchRegulations()
  }

  handleAlert = () => {
    this.setState({
      openDialog: true
    })
  }

  confirmAlert = () => {
    const { orderId } = this.props.match.params
    this.setState({ loading: true })
    OrderApi.activateOrder(orderId)
      .then(() => OrderApi.getOrder(orderId))
      .then((order) => {
        this.setState({ order, loading: false })
      })
      .catch(() => this.setState({ loading: false }))

    this.closeDialog()
  }

  closeDialog = () => {
    this.setState({
      openDialog: false,
      idToDelete: null
    })
  }
parseReductions = (order) => {
   order.reductions.map((reduc) => {
    if (reduc.type === orderReductionType.DELIVERY) {
      reduc.typeFormatted = "Livraison"
      this.state.reductions.push(reduc)
    } else if (reduc.type === orderReductionType.DEAL) {
      reduc.typeFormatted = "Offre"
      this.state.reductions.push(reduc)
    } else if (reduc.type === orderReductionType.PROMO) {
      reduc.typeFormatted = "Code promo"
      this.state.reductions.push(reduc)
    } else if (reduc.type === orderReductionType.REFERRAL) {
      reduc.typeFormatted = "Parrainage"
      this.state.reductions.push(reduc)
    } else if (reduc.type === orderReductionType.BALANCE) {
      reduc.typeFormatted = "Solde"
      this.state.reductions.push(reduc)
    }
    // return <div key={reduc.typeFormatted}>Type: {reduc.type} - montant: {reduc.amount} €</div>
  })
}
  render() {
    const { classes, history } = this.props
    if (this.state.loading && !this.state.order) {
      return (
        <CircularProgress
          style={{ marginLeft: '50%', marginRight: '50%' }}
          color="secondary"
        />
      )
    } else {
      const { classes } = this.props
      const { openDialog } = this.state
      const order = this.props.order || this.state.order || {}
      const restaurant = order?.restaurantId
      const rider = order?.riderId
      const {
        deliveryAdress,
        facturationAdress,
        userId: client
      } = order
      let billNumber = order?.billNumber?.split('-')[4] || ''
      billNumber = billNumber ? `#${billNumber}` : ''

      let deliveryReduction = 0
      if (order.cart?.length) {
        deliveryReduction = order.promotions?.reduce((acc, elem) => elem.type?.value === orderReductionType.DELIVERY ? acc + elem.promoDiscountValue?.value : acc, 0)
      } else if (order?.products?.length) {
        deliveryReduction = order.reductions?.reduce((acc, elem) => elem.type === orderReductionType.DELIVERY ? acc + elem.amount?.value : acc, 0)
      }

      let userFeesPaid = order.payment?.charges?.fees.reduce((acc, elem) => acc + elem.amount, 0)

      if (userFeesPaid && deliveryReduction) {
        userFeesPaid -= deliveryReduction
      }
      userFeesPaid = +(userFeesPaid / 100).toFixed(2)
      const restaurantAppVersion = !order?.restaurantId
        ? 'Restaurant Supprimé'
        : order?.restaurantId?.appVersion
        ? order?.restaurantId?.appVersion
        : '< 1.3.0'

      const appVersion = !client
        ? 'Utilisateur Supprimé'
        : order?.appVersion
        ? order?.appVersion
        : 'Web'

      const activateOrderButton =
        order?.status &&
        order?.status === orderStatus.WAITING_FOR_PAYMENT ? (
          <Button
            onClick={this.handleAlert}
            variant="contained"
            color="secondary"
          >
            Forcer l'activation
          </Button>
        ) : null

        const { orderId } = this.props.match.params

      const displayIncreasedPrice = !!order.increasedTotalPrice
      const orderPrice = displayIncreasedPrice ? `🍴: ${order.orderPrice?.text} 👨‍🦰:${order.increasedOrderPrice?.text}` : `${order.orderPrice?.text}`
      const totalPrice = displayIncreasedPrice ? `🍴: ${order.totalPrice?.text} 👨‍🦰:${order.increasedTotalPrice?.text}` : `${order.totalPrice?.text}`

      return (
        <React.Fragment>          
          <Paper className={classes.mainContainer} elevation={3}>
            <Typography variant="h4">Détails Commande {billNumber}</Typography>
            {activateOrderButton}
            <div className={classes.horizontalContainer}>
              {/* INFOS GÉNÉRALES */}
              <div className={classes.subSection}>
                <Typography variant="h6">Général</Typography>
                {this.renderInfo(
                  'Type de commande:',
                  renderDeliveryIcon(order.deliveryType)
                )}
                {this.renderInfo(
                  'Date de création:',
                  makeDateString(order.created_at)
                )}
                {this.renderInfo('Restaurant:', restaurant?.nom)}
                {this.renderInfo('Livreur:', rider ? makeRiderFullName(rider) : 'Pas de livreur')}
                {order.riderValidationCode ? this.renderInfo(
                  'Code livreur:',
                  (order.riderValidationCode)
                ) : null}
                {this.renderInfo('État:', getFrenchStatus(order?.status))}
                {order?.noteForRestaurant
                  ? this.renderInfo(
                      'Note Pour le Restaurant:',
                      order?.noteForRestaurant
                    )
                  : null}
                {order?.noteForRider
                  ? this.renderInfo(
                      'Note Pour le Livreur:',
                      order?.noteForRider
                    )
                  : null}
                {order?.noteForAdmin
                  ? this.renderInfo("Note Pour l'admin:", order?.noteForAdmin)
                  : null}
              </div>

              {/* PRIX */}
              <div className={classes.subSection}>
                <Typography variant="h6"> </Typography>
                {this.renderInfo('Ancien Numéro Commande:', (order?.counter ? `#${order?.counter}` : billNumber))}
                {this.renderInfo(
                  "Nombre d'articles:",
                  order?.products?.length || order?.cart?.length || 0
                )}
                {this.renderInfo('Sous-total:', orderPrice)}
                {userFeesPaid ? this.renderInfo(
                  'Frais de livraison Client:',
                  (`${userFeesPaid} €`)
                ) : null}
                {this.renderInfo(
                  'Frais de livraison Livreur:',
                  (order.deliveryPrice?.text)
                )}
                
                {this.renderInfo('Total:', totalPrice)}
                {order.riderTipAmount ? this.renderInfo(
                  'Pourboire livreur:',
                  (order.riderTipAmount?.text)
                ) : null}
                {this.renderInfo(
                  'Version du restaurant:',
                  restaurantAppVersion
                )}
              </div>

              {/* INFOS CLIENT */}
              <div className={classes.subSection}>
                <Typography variant="h6">Client</Typography>
                {this.renderInfo('Nom:', makeUserFullName(client))}
                {this.renderInfo('Téléphone:', get(client, 'telephone'))}
                {this.renderInfo('Email:', get(client, 'email'))}
                {this.renderInfo(
                  'Adresse de facturation',
                  facturationAdress?.streetAdress,
                  facturationAdress?.zipCode,
                  facturationAdress?.city
                )}
                {this.renderInfo(
                  'Version utilisateur:',
                  appVersion
                )}
              </div>

              <div className={classes.subSection}>
                <Typography variant="h6"></Typography>
                {deliveryAdress && this.renderInfo(
                  'Adresse de livraison',
                  deliveryAdress?.streetAdress,
                  deliveryAdress?.zipCode,
                  deliveryAdress?.city
                )}


                {/* Payment details */}
                {(order.reducedTotalPrice || order.totalPrice) && order?.mealVoucherInfo?.amount 
                  ? this.renderInfo('Montant payé par CB:', ((((order.reducedTotalPrice?.value || order.totalPrice?.value) - order?.mealVoucherInfo?.amount + (order.totalPriceIncrease?.value || 0)) / 100).toFixed(2)))
                  : this.renderInfo('Montant payé par CB:', (((order.reducedTotalPrice?.value || order.totalPrice?.value) + (order.totalPriceIncrease?.value || 0)) / 100).toFixed(2))
                }
                {order?.mealVoucherInfo?.amount ?this.renderInfo('Montant payé par titre restaurant:',((order?.mealVoucherInfo?.amount).toFixed(2))) : null}
                {order?.reductions?.length > 0 ? this.renderInfo('Reductions:',this.state.reductions.map((reduc)=>{
                 return <div key={reduc.type}>Type: {reduc.typeFormatted} - montant: {reduc.amount.text || reduc.amount}</div>
                })) : null}

                {/* Refund Staff */}
                {/* refundedAmount.value.toFixed(2) */}
                {order.refundedAmount ?this.renderInfo('Total remboursement:',(order.refundedAmount.text)) : null}
                {order.cbRefundedAmount ?this.renderInfo('Remboursement CB:',(order.cbRefundedAmount.text)) : null}
                {order.ticketRefundedAmount ?this.renderInfo('Remboursement titre restaurant:',(order.ticketRefundedAmount.text)) : null}
                {order.mealVoucherInfo ? this.renderInfo('Type ticket:',order.mealVoucherInfo.paymentType ): null}
                 <Link to={`${Routes.ClaimsRoutes}/add/${this.props.match.params.orderId}`} style={{textDecoration:'none'}}>
                 <Button variant="contained" color="secondary" onClick={this.props.discard()}>
                 <AddIcon/>Ajouter une reclamation</Button></Link>
                 {/* <p>
                 <Link to={`${Routes.ClaimsRoutes}/addd/${this.props.match.params.orderId}`} style={{textDecoration:'none'}}>
                 </Link></p> */}
                 <p>
                  <Button variant="contained" color="secondary" onClick={this.handleClickOpen}>
                      <AddIcon/> Ajouter un ticket
                  </Button> 
                 </p>
              </div>

            </div>
            <div style={{marginLeft :'400px'}} >
            {this.state.order?.isCheckoutSystemResendEnabled === true ? <Button disabled={this.state.disable} onClick={this.checkoutsystem}>Renvoyer la commande a la caisse</Button>
            :<Button disabled={true}>Renvoyer la commande a la caisse</Button>}
                      
            </div>
            <Divider></Divider>
            {order?.products?.length > 0 ? <List
              className={classes.productsTable}
              columns={[
                'Article',
                'Categorie Personalisée',
                'Prix TTC',
                'TVA',
                'Total TTC'
              ]}
              list={this.makeProductList()}
            /> : 
            <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 1 }}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Détails du panier
              </Typography>
              <Button onClick={this.handleExpandClick}>
                {this.state.expanded.length === 0 ? 'Ouvrir tout' : 'Fermer tout'}
              </Button>
            </Box>

            <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            expanded={this.state.expanded}
            onNodeToggle={this.handleToggle}
            sx={{
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            {order?.cart?.map((item) => (
              <MultiSelectTreeView
                item={item}
                type="selectedModifierGroups"
                isFirsLevel 
              />
            ))}
          </TreeView></>}

            <OrderTracking
              classes={classes}
              orderId={this.props.match.params.orderId}
            />

            <RegulationList deleteRegulation={this.deleteRegulation} updateRegulation={this.updateRegulation} onSubmitSuccess={this.handleRegulationSubmit} order={order} orderId={this.props.match.params.orderId} regulations={this.state.regulations} products={this.state.products} classes={classes} />          
          </Paper>

          {openDialog && (
            <Dialog
              open={true}
              dialogText="Je certifie que le paiement est bien passé et pris en compte sur stripe, je force la création de la commande"
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Confirmer"
              onCancel={this.closeDialog}
              onValidation={this.confirmAlert}
            />
          )}
          {this.state.openTrackingModal && 
          <ModalAddTracking open={this.state.openTrackingModal} onClose={this.handleClose} order={this.props.order || this.state.order || {}} restaurant={restaurant} />
          }
        </React.Fragment>
      )
    }
  }
}

function MultiSelectTreeView({ item, type, isFirsLevel }) {
  return (
    <TreeItem
      nodeId={item._id}
      label={
        <ListItem>
          <ListItemText

            primary={type === "selectedItems" || "selctedItems" && isFirsLevel ? <strong style={{fontSize: isFirsLevel ? 22 : 16}}>{item.title}</strong> : <span style={{fontSize:16}}>{item.title}</span> }

            secondary={
              item.price ? (
               <>
                  <Chip
                    size="small"
                    label={`Prix unitaire ${(item.price.baseUnitPrice.text)}`}
                  />{" "}
                  x {<strong style={{display: "inline-block", marginRight: "24rem"}}>{item.quantity}</strong>} { isFirsLevel &&   <strong style={{display: "inline-flex", flexDirection: "column"}}><span>Catégorie Personnalisée:</span><span>{item.header}</span></strong>} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <Box display="inline-flex" flexDirection="column"><strong>Prix TTC:</strong>  <Chip
                   size="small"
                     label={`${(item.price.totalPrice.text)}`} 
                   /></Box> <Box display="inline-flex" flexDirection="column" alignItems={"flex-start"}  marginLeft={"7rem"}><strong>TVA :</strong>  <Chip
                     size="small"
                     label={`${(item.taxInfo.totalTaxCost.text)}`}
                   /></Box>

               </>
              ) : null
            }
          />
        </ListItem>
      }
    >
      {item[type].map((selectedModifierGroup) => (
        <MultiSelectTreeView
          item={selectedModifierGroup}
          type={
            type === "selectedModifierGroups"
              ? "selectedItems"
              : "selectedModifierGroups"
          }
        />
      ))}
    </TreeItem>
  );
}

const mapDispatchToProps = dispatch=>({
  discard:()=>{
    dispatch(resetError())
  }
})
const mapStateToProps = (state) => ({
  allRiders: ridersSelectors.getAllRiders(state),
  allRestaurants: restaurantsSelectors.getAllRestaurants(state)
})

export default compose(
  connect(mapStateToProps,mapDispatchToProps),
  withStyles(styles)
)(OrderDetails)