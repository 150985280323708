import cookie from 'cookie'

export function getCookie(key) {
  const cookies = cookie.parse(document.cookie)
  return cookies[key]
}

export function setCookie(key, value) {
  document.cookie = cookie.serialize(key, value)
}
