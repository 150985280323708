import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Button, ButtonGroup, TextField } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination';

import {
  selectors as restaurantsSelectors,
  fetchAllRestaurantsRequested,
  fetchRestaurantsInfoRequested
} from 'core.modules/restaurants/reducer'
import { selectors as categoriesSelectors } from 'core.modules/categories/reducer'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'

import RestaurantCreate from 'components/RestaurantCreate'
import RestaurantEdit from 'components/RestaurantEdit'
import Dialog from 'core.ui/Dialog'
import DuplicateRestaurantDialog from 'core.ui/DuplicateRestaurantDialog'
import List from 'core.ui/List'

import Snackbar from 'core.ui/Snackbar'
import { parseRestaurant } from 'core.utils/parser/restaurant'
import { restaurant } from 'core.services/api'
import {
  duplicateRestaurantRequested,
  updateDeliveryRestaurantRequested,
  updatePickupRestaurantRequested,
  updateDisableRestaurantRequested,
  searchRestaurantRequested
} from '../../core.modules/restaurants/reducer'
import { requestStates } from '../../core.utils/requestStates'
import Autocomplete from '@material-ui/lab/Autocomplete'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  headerAutocomplete: {
    paddingTop: '10px'
  }
}

class RestaurantsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      indexToDelete: null,
      openDuplicateDialog: false,
      currentId: null,
      openSnackBar: false,
      snackBarMessage: '',
      snackBarseverity:'error',
      waitingForRequest: false,
      filterResto: null,
      page: 0,
      perPage: 20
    }
  }

  async componentDidMount() {
    const cityId = localStorage.getItem('selectedCity')
    this.props.fetchRestaurants(cityId)
    this.props.fetchRestaurantsInfo(cityId, this.state.page, this.state.perPage)
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentRequestState } = this.props
    const { waitingForRequest, page, perPage } = this.state

    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }

    if (
      !this.state.filterResto &&
      (prevState.page !== page || prevState.perPage !== perPage)
    ) {
      const cityId = localStorage.getItem('selectedCity')
      this.props.fetchRestaurantsInfo(cityId, this.state.page, this.state.perPage)
    }
  }

  handleDeliveryChange = (restaurantIndex) => (isDelivery) => {
    const { currentCityRestaurantsInfo, updateDeliveryRestaurant } = this.props

    const restaurantId = currentCityRestaurantsInfo.restaurants[restaurantIndex].id || currentCityRestaurantsInfo.restaurants[restaurantIndex]._id
    updateDeliveryRestaurant(restaurantId, isDelivery)
  }

  handlePickupChange = (restaurantIndex) => (isPickup) => {
    const { currentCityRestaurantsInfo, updatePickupRestaurant } = this.props
    
    const restaurantId = currentCityRestaurantsInfo.restaurants[restaurantIndex].id || currentCityRestaurantsInfo.restaurants[restaurantIndex]._id
    updatePickupRestaurant(restaurantId, isPickup)
  }

  handleDisableChange = (restaurantIndex) => (isDisable) => {
    const { currentCityRestaurantsInfo, updateDisableRestaurant } = this.props
    
    const restaurantId = currentCityRestaurantsInfo.restaurants[restaurantIndex].id || currentCityRestaurantsInfo.restaurants[restaurantIndex]._id
    updateDisableRestaurant(restaurantId, isDisable)
  }

  handleEdit = (id) => {
    const { history } = this.props
    history.push(`/restaurants/edit?id=${id}`)
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      indexToDelete: null
    })
  }

  confirmDelete = () => {
    const cityId = localStorage.getItem('selectedCity')

    restaurant.delete(this.state.indexToDelete).then((res) => {
      this.props.fetchRestaurantsInfo(cityId, 0, 20)
    })
    this.closeDeleteDialog()
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleDelete = (id) => {
    this.setState({
      openDeleteDialog: true,
      indexToDelete: id
    })
  }

  closeDuplicateDialog = () => {
    this.setState({
      openDuplicateDialog: false,
      indexToDelete: null
    })
  }

  handleDuplicate = (id) => {
    this.setState({ openDuplicateDialog: true, indexToDelete: id })
  }

  confirmDuplicate = (cityId, email, telephone, comission, adress) => {
    this.setState({ waitingForRequest: true })

    const idCity = localStorage.getItem('selectedCity')

    this.props.duplicateRestaurant(
      this.state.indexToDelete,
      cityId,
      email,
      telephone,
      comission,
      adress
    )

    this.props.fetchRestaurantsInfo(idCity, 0, 20)
    this.closeDuplicateDialog()
  }


  render() {
    const { openSnackBar, snackBarMessage, snackBarseverity } = this.state
    const { openDeleteDialog, openDuplicateDialog, filterResto, page, perPage } = this.state
    const {
      currentCityRestaurants,
      allRestos,
      allCategories,
      classes,
      allCities,
      errorMessage,
      city,
      currentCityRestaurantsInfo
    } = this.props

    const filterRestaurant = (e, value) => {
      if (value && value._id) {
        this.props.searchRestaurant(value?._id)
      } else {
        const cityId = localStorage.getItem('selectedCity')
        this.props.fetchRestaurantsInfo(cityId, this.state.page, this.state.perPage)
      }
      
      this.setState({ filterResto: value?.id }) 
    }

    const rowsPerPageOptions = [5, 10, 20]

    const handleChangePage = (event, newPage) => {
      this.setState({ page: newPage })
    }
  
    const handleChangeRowsPerPage = event => {
      this.setState({ perPage: event.target.value })
      this.setState({ page: 0 })
    }

    const restaurants = currentCityRestaurantsInfo.restaurants.map((r, index) =>
      parseRestaurant(
        r,
        allCategories,
        this.handleDeliveryChange(index),
        this.handlePickupChange(index),
        this.handleDisableChange(index)
      )
    )

    const ids = currentCityRestaurantsInfo.restaurants.map((r) => r._id)

    return (
      <div className={classes.mainContainer}>
        <Switch>
          <Route
            path="/restaurants/new"
            exact
            render={(props) => (
              <RestaurantCreate onSuccess={this.handleSuccess} {...props} />
            )}
          />
          <Route
            path="/restaurants/edit"
            render={(props) => {
              const id = props.location.search.split('=')[1]
              const data = currentCityRestaurantsInfo.restaurants.find(
                (resto) => resto._id === id
              )
              return <RestaurantEdit id={id} data={data} {...props} />
            }}
          />
          <Route
            path="/restaurants"
            exact
            render={(props) => (
              <React.Fragment>
                <Link to="/restaurants/new">Ajouter un restaurant</Link>
                {currentCityRestaurants?.length > 0 && (
                  <div style={styles.headerAutocomplete}>
                    <Autocomplete
                      options={currentCityRestaurants}
                      getOptionLabel={(resto) => resto.nom}
                      style={{ width: 300 }}
                      onChange={filterRestaurant}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filtre restaurant"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                )}
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={currentCityRestaurantsInfo.paginationInfo.count}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <List
                  list={restaurants}
                  columns={[
                    'Nom',
                    'Catégories',
                    'Adresse',
                    'Horaires',
                    'Note',
                    'Commission',
                    'Livraison',
                    'A emporter',
                    'Désactivé',
                    "Version de l'app",
                    'Version menus'
                  ]}
                  withPagination={false}
                  ids={ids}
                  actions={{
                    handleView: (id) => props.history.push(`/restaurants-tracking/${id}`),
                    handleDelete: this.handleDelete,
                    handleEdit: this.handleEdit,
                    handleDuplicate: this.handleDuplicate
                  }}
                  emptyMessage="Aucun restaurant"
                />
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={currentCityRestaurantsInfo.paginationInfo.count}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Dialog
                  open={openDeleteDialog}
                  dialogText="Confirmer la suppression"
                  dialogTitle="Confirmation"
                  cancelButtonCaption="Annuler"
                  confirmButtonCaption="Ok"
                  onCancel={this.closeDeleteDialog}
                  onValidation={this.confirmDelete}
                />
                <DuplicateRestaurantDialog
                  open={openDuplicateDialog}
                  dialogText=""
                  dialogTitle="Dupliquer le restaurant"
                  allCities={allCities}
                  classes={classes}
                  cancelButtonCaption="Annuler"
                  confirmButtonCaption="Ok"
                  onCancel={this.closeDuplicateDialog}
                  onValidation={this.confirmDuplicate}
                />
              </React.Fragment>
            )}
          />
        </Switch>
        <Snackbar
          open={openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={errorMessage}
        />
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => ({
  currentCityRestaurants: restaurantsSelectors.getAllRestaurants(state),
  currentCityRestaurantsInfo: restaurantsSelectors.getRestaurantsInfo(state),
  allCities: citiesSelectors.getAllCities(state),
  allCategories: categoriesSelectors.getState(state),
  currentRequestState: restaurantsSelectors.getRequestState(state),
  errorMessage: restaurantsSelectors.getErrorMessage(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchRestaurants: (cityId) => {
    dispatch(fetchAllRestaurantsRequested(cityId))
  },
  fetchRestaurantsInfo: (cityId, page, perPage) => {
    dispatch(fetchRestaurantsInfoRequested(cityId, page, perPage))
  },
  searchRestaurant: (restaurantId) => {
    dispatch(searchRestaurantRequested(restaurantId))
  },
  duplicateRestaurant: (
    restaurantId,
    cityId,
    email,
    adress,
    comission,
    telephone
  ) => {
    return dispatch(
      duplicateRestaurantRequested(
        restaurantId,
        cityId,
        email,
        adress,
        comission,
        telephone
      )
    )
  },
  updateDeliveryRestaurant: (restaurantId, deliveryValue) => {
    dispatch(updateDeliveryRestaurantRequested(restaurantId, deliveryValue))
  },
  updatePickupRestaurant: (restaurantId, pickupValue) => {
    dispatch(updatePickupRestaurantRequested(restaurantId, pickupValue))
  },
  updateDisableRestaurant: (restaurantId, disableValue) => {
    dispatch(updateDisableRestaurantRequested(restaurantId, disableValue))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(RestaurantsList)
