export const parseImage = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = e => {
      resolve(reader.result)
    }
    reader.onerror = e => {
      reject(e)
    }
  })
}
