import {
 CURRENT_CITY,
 CITY_ERROR
} from './types'

const initialState = {
 
  currentCity: null,
  loading:true,
  err:null

}
export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
   
    case CURRENT_CITY:
      return {
        ...state,
        currentCity: payload[0],
        loading:false,
       
      }
       default:
      return state
  }
}
