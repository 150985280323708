import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  OutlinedInput,
  IconButton
} from '@material-ui/core'
import dayjs from 'dayjs'
import { chain, zipObject } from 'lodash'
import { schema } from '../validations'
import frLocale from 'date-fns/locale/fr'
import DateFnsUtils from '@date-io/date-fns'
import Snackbar from '@material-ui/core/Snackbar'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import { Alert, Autocomplete } from '@material-ui/lab'
import { Add, Save, Delete } from '@material-ui/icons/'
import { useHistory, useLocation } from 'react-router-dom'
import { deal, restaurant } from '../../../core.services/api'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const EditDealForm = ({ data, restaurants, setRestaurants }) => {
  const {
    state: { restaurantID }
  } = useLocation()
  const [notifier, setNotifier] = useState({
    open: false,
    type: '',
    content: []
  })


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setNotifier({
      open: false,
      type: '',
      content: []
    })
  }

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema.editBuyGet),
    defaultValues: {
      grantingProducts: [
        {
          productId: '',
          value: 1
        }
      ],
      grantedProducts: [
        {
          productId: '',
          value: 1
        }
      ]
    }
  })
  const history = useHistory()
  const watchStartDate = watch('startDate')
  const watchEndDate = watch('endDate')

  const days = [
    { value: 'monday', text: 'Lundi' },
    { value: 'tuesday', text: 'Mardi' },
    { value: 'wednesday', text: 'Mercredi' },
    { value: 'thursday', text: 'Jeudi' },
    { value: 'friday', text: 'Vendredi' },
    { value: 'saturday', text: 'Samedi' },
    { value: 'sunday', text: 'Dimanche' }
  ]

  useEffect(() => {
    if (data) {
      reset({ ...data })
    }
  }, [])

  const onSubmit = async (promotion) => {
      const { startDate, endDate, days, hour} = promotion
      try {
         deal.edit(data._id, { startDate, endDate, days, hour})
         .then(res => { 

          setNotifier({
            open: true,
            type: 'success',
            content: [{ message: 'Votre offre a bien été modifiée' }]
          });

         setRestaurants(restaurants.map(restaurant => restaurant._id === data._id ? ({...restaurant, startDate, endDate, days, hour}) : restaurant))

          setTimeout(() => {
            history.push('/marketing/deals')
          }, 1000)
         })

         
      } catch (error) {
        if (error.response) {
          const { errors } = error.response.data
          setNotifier({
            open: true,
            type: 'error',
            content: errors
          })
        }
      }
  }
  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('type')} value="buy_x_get_y" />

        <Box display="flex" direction="row" my={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
            <FormControl fullWidth>
              <Controller
                name="startDate"
                control={control}
                defaultValue={dayjs()}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    ampm={false}
                    minDate={dayjs()}
                    maxDate={watchEndDate}
                    disablePast={true}
                    error={!!errors.startDate}
                    onChange={onChange}
                    label="Date de début"
                    value={value || dayjs()}
                    format="dd MMMM yyyy HH:mm"
                    inputVariant="outlined"
                    helperText={errors.startDate && errors.startDate.message}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="endDate"
                control={control}
                defaultValue={watchStartDate}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    required
                    ampm={false}
                    error={!!errors.endDate}
                    minDate={watchStartDate}
                    label="Date de fin"
                    onChange={onChange}
                    inputVariant="outlined"
                    value={value}
                    format="dd MMMM yyyy HH:mm"
                    helperText={errors.endDate && errors.endDate.message}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Box>

        <FormControl variant="outlined" fullWidth error={!!errors.days}>
          <InputLabel id="days">Jours</InputLabel>
          <Select
            multiple
            id="days"
            labelId="days"
            variant="outlined"
            defaultValue={data?.days || []}
            {...register('days')}
            input={<OutlinedInput label="days" />}
          >
            {days.map((day) => (
              <MenuItem key={day.value} value={day.value}>
                {day.text}
              </MenuItem>
            ))}
          </Select>
          {errors.days && (
            <FormHelperText>{errors.days.message}</FormHelperText>
          )}
        </FormControl>

        <Box display="flex" direction="row" my={2}>
          <FormControl fullWidth>
            <TextField
              required
              type="time"
              variant="outlined"
              label="Heure de début"
              id="outlined-disabled"
              error={!!errors['hour.start']}
              helperText={errors['hour.start'] && errors['hour.start'].message}
              {...register('hour.start')}
            />
            <ErrorMessage
              errors={errors}
              name={`hour.start`}
              render={({ message }) => (
                <FormHelperText>{message}</FormHelperText>
              )}
            />
          </FormControl>
          <FormControl fullWidth error={!!errors['hour.end']}>
            <TextField
              required
              type="time"
              variant="outlined"
              label="Heure de fin"
              id="outlined-disabled"
              error={!!errors['hour.end']}
              {...register('hour.end')}
            />
            <ErrorMessage
              errors={errors}
              name={`hour.end`}
              render={({ message }) => (
                <FormHelperText>{message}</FormHelperText>
              )}
            />
          </FormControl>
        </Box>

        <Button
          fullWidth
          size="large"
          type="submit"
          disableElevation
          variant="contained"
          startIcon={<Save />}
          disabled={isSubmitting}
        >
          Modifier l'offre
        </Button>
      </form>

      {notifier.open && (
        <Snackbar
          open={notifier.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity={notifier.type} onClose={handleClose}>
            {notifier.content.map((data) => data?.message)}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default EditDealForm
