import { CURRENT_CITY, CITY_ERROR } from './types'

import {
  fetchRestaurantsByCitySucceeded,
  fetchAllRestaurantsSucceeded
} from '../../core.modules/restaurants/reducer'

import { city } from '../../core.services/api'

export const getCity = (id) => async (dispatch) => {
  try {
    const res = await city.getCity(id)
    dispatch({
      type: CURRENT_CITY,
      payload: res
    })

    dispatch(fetchAllRestaurantsSucceeded(res))
  } catch (error) {
    dispatch({
      type: CITY_ERROR
    })
    dispatch(fetchAllRestaurantsSucceeded([]))
  }
}
