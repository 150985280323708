import React, { useReducer, createContext } from 'react';

export default (reducer, actions, initState) => {
	const Context = React.createContext();

	const Provider = ({ children }) => {
		const [ state, dispatch ] = useReducer(reducer, initState);

		let providerActions = {};
		for (let key in actions) {
			providerActions[key] = actions[key](dispatch);
		}

		return <Context.Provider value={{ state, ...providerActions }}>{children}</Context.Provider>;
	};

	return { Context, Provider };
};
