import React from 'react'
import { Switch } from '@material-ui/core'

export function parseCity(city, onCheckIsDelivery, onCheckIsPickup, onCheckIsLimited,onCheckIs10kmLimited,onCheckIsOpenAllDay) {

  return [
    city.name,
    parseToSwitch(city.isDelivery != null ? city.isDelivery : true, onCheckIsDelivery),
    parseToSwitch(city.isPickup != null ? city.isPickup : true, onCheckIsPickup),
    parseToSwitch(city.is5KmLimited != null ? city.is5KmLimited : false, onCheckIsLimited),
    parseToSwitch(city.is10KmLimited != null ? city.is10KmLimited : false, onCheckIs10kmLimited),
    parseToSwitch(city.isOpenAllDay != null ? city.isOpenAllDay : false, onCheckIsOpenAllDay)
  ]
}

export function parseToSwitch(value, fct) {
  return (
    <Switch
      checked={value}
      onChange={() => fct(!value)}
    />
  )
}