import React, { Component } from 'react';
import classNames from 'classnames'
import {
  Button,
  Divider,
  InputLabel,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Chip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import ItemList from 'core.ui/ItemList'
import ProductOptionChoice from 'components/ProductOptionChoice'
import TextField from 'core.ui/TextField'
import Select from 'core.ui/Select'
import {option, product} from 'core.services/api'
import Snackbar from 'core.ui/Snackbar'
import { sl } from 'date-fns/locale';
import { get } from 'lodash';
import List from 'core.ui/List'
const styles = {
  customTextField: {
    "& input::placeholder": {
      fontSize: "5px"
    }
  },
  mainContainer: {
    width: '100%'
  },
  nameTaxContainer: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labels: {
    marginBottom: '10px'
  },
  nameTextField: {
    width: '175px'
  },
  taxLabel: {
    marginLeft: '15px'
  },
  parentLabel: {
    marginLeft: '15px',
    width: '250px',
    
  },
  select: {
    height: '56px',
    minWidth: '100px',
    margin: '0 6px'
  },
  optionsList: {
    marginBottom: '15px'
  },
  divider: { height: '1px', marginBottom: '15px' },
  paper: {
    padding: '16px',
    marginBottom: '16px',
    width:'65%',
    position:'absolute',
    left:'25%',
    right:'25%'
  }
}

const stylesChips = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '5px',
    margin: 0,
  },
  chip: {
    margin: '5px',
  },
};
export const newOptionChoice = {
  price: 0,
  isAvailable: true,
  type: '',
  checkoutSystemId: undefined,
  isAbstract: false,
  isHidden:true
}



class OptionEdit extends Component {
   constructor(props){
       super(props)
       this.state={
        option: this.props.data,
        parents: (this.props.data?.parents === undefined)?[]: this.props.data?.parents ,
        selected : '',
        open:false,
        snackBarMessage: '',
        snackBarSeverity: 'error',
        optionProducts: [],
        showProducts: false
       }
       
       }
   
   ajouterParent = () => {
     
    const { selected, parents } = this.state
    if(selected !== '' && parents?.filter(e => e.checkoutSystemId === selected).length <= 0 )
     {
       const {parents} = this.state
      
       parents.push(
        {
          checkoutSystemId: selected?.trim()
      })
       this.setState({parents: parents})
       
     this.setState({selected: '' })
     }
   }
   removeCheckout = (chipToDelete) => () => {
    const {parents} = this.state;
    
    const arr = parents.filter((e) => e.checkoutSystemId !== chipToDelete) 
    this.setState({parents: arr});
    };
  


  componentDidMount = async() => {
    const { data, currentRestaurantId } = this.props

    if (!data) return 
    if (data) {
      const optionId = data._id || data.id
      let optionProducts = []
      try {
        if (currentRestaurantId) optionProducts = await option.getProductsByOption(optionId, this.props.currentRestaurantId)
        else if (data.templateId) optionProducts = await option.getTemplateProductsByOption(optionId, data.templateId)

        this.setState({ optionProducts })
      } catch (error) {
        this.setState({ open: true, snackBarMessage: error?.response?.data?.errors[0]?.message || "Erreur lors de l'affichage des produtis" })
      }
    }
  }

   handleChange = field => e => {
    const { value } = e.target
    const { option } = this.state
 
    if (field === 'optionTypes'){
      value.map(values => { values.type = values.type.trim() })
      this.setState({ option: { ...option, optionTypes:  value } }) 
    } else if (field === 'reference') {
      this.setState({ option: { ...option, reference: value.trim() } })
    } else { 
      this.setState({ option: { ...option, [field]: value } })
    }
   
  }
 
  handleCloseSnackbar = () => {
    this.setState({ open: false })
  }
  handleSubmit = () =>{
      this.state.option.parents = this.state.parents
      if(this.props.templates.templateid !== null){
        option.editTemplateOption(this.props.templates.templateid,this.state.option._id,this.state.option).then(()=>{
          this.props.onSuccess()
          this.props.history.goBack()
      })
      .catch((err)=>{
        this.setState({
          open: true,
          snackBarMessage: err?.response?.data?.errors[0].message || 'Erreur lors de la création',
        })
      })
      } else {
        option.editOption(this.props.currentRestaurantId,this.state.option._id,this.state.option)
        .then(()=>{
            this.props.onSuccess()
            this.props.history.goBack()
        })
        .catch((err)=>{
          this.setState({
            open: true,
            snackBarMessage: err?.response?.data?.errors[0].message || 'Erreur lors de la création',
          })
        })
      }
      
  }
  makeRows = (products) => {
    return products.map((p) => [p.name, p.header, p.price])
  }

  makeIds = (products) => {
    return products.map((p) => {
      return get(p, 'id', get(p, '_id', ''))
    })
  }
  
    render() {
    const { className, classes, currentRestaurant, templates, restaurantCity } = this.props
    const { option, optionProducts } = this.state
   
    return (
      <>
    <Paper className={classNames(classes.paper)} style={{width:'80%',marginLeft:'-15%'}} elevation={3}>
      <FormControl
        className={classNames(
          'main-container',
          classes.mainContainer,
          className
        )}
        component="fieldset"
      >
        <FormLabel className={classNames(classes.labels)} component="legend">
          Modifier l'option
        </FormLabel>
        <div className={classes.nameTaxContainer}>
          <TextField
            className={classNames(
              'create-option-name-textfield',
              classes.nameTextField
            )}
            value={option?.name}
            label="Nom"
            onChangeComplete={this.handleChange('name')}
          />
          <FormControl>
            <InputLabel className={classes.taxLabel}>TVA</InputLabel>
            {restaurantCity && <Select
              className={classes.select}
              options={[0, ...restaurantCity?.pricing?.taxValues?.map(tax => tax)]}
              value={option?.tax}
              captions={["0 %", ...restaurantCity?.pricing?.taxValues?.map(tax => `${tax} %`)]}
              onChange={this.handleChange('tax')}
            />}
          </FormControl>
          <TextField
            className={classNames(
              'create-option-name-textfield',
              classes.nameTextField
            )}
            value={option?.order}
            label="Ordre"
            type="number"
            onChangeComplete={this.handleChange('order')}
          />
          <FormControl style={(!currentRestaurant?.posInfo?.type) ? {display:'none'} : {marginLeft:'7px',marginRight:'7px',fontSize:'10px'}} >
            <TextField
              className={classes.nameTextField}
              value={this.state.selected}
              className={styles.customTextField}
              placeholder="Système de caisse"
              onChangeComplete={e => this.setState({selected : e.target.value.trim()})}
            />
          </FormControl>
         {(currentRestaurant?.posInfo?.type || templates.checkTemplate) ?
         <div><TextField
            className={classNames(
              'create-option-name-textfield',
              classes.nameTextField
            )}
            value={option?.reference}
            label="reference"
            onChangeComplete={this.handleChange('reference')}
          /></div>:<div></div>} 
          <FormControl>
          <Paper style={(!currentRestaurant?.posInfo?.type) ? { display: 'none' } : { marginLeft: '7px', padding: '10px' } } className={stylesChips.root}>
      {this.state.parents?.map((data) => {
      

        return (
          <li >
           
            <Chip style={{margin:'10px 1px 10px 0px'}}
              label={data.checkoutSystemId}
              onDelete={this.removeCheckout(data.checkoutSystemId)}
              className={stylesChips.chip}
            />
          </li>
        );
      })}
    </Paper>
    </FormControl>
    <Button
            className="create-option-submit-button"
            variant="contained"
            color="primary"
            onClick={this.ajouterParent}
            style={(!currentRestaurant?.posInfo?.type) ? { display: 'none' } : { marginLeft:'7px' } }
          >
           Ajouter Parent
          </Button>
        </div>

        <Divider className={classes.divider} />

        {/* LISTE DES CHOIX */}
        <FormLabel className={classNames(classes.labels)} component="legend">
          Choix disponibles
        </FormLabel>

        <ItemList
          className={classes.optionsList}
          list={option?.optionTypes}
          propsToGiveToEachItem={['price', 'isAvailable', 'type', 'checkoutSystemId','isAbstract', 'isHidden']}
          componentToRender={ProductOptionChoice}
          itemTemplate={newOptionChoice}
          emptyLabel="Aucun choix"
          currentRestaurant={currentRestaurant}
          onChange={this.handleChange('optionTypes')}
          showStockSwitch={true}
          templates={templates}
        />

        {/* BOUTON CRÉER L’OPTION */}
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Button
            className="create-option-submit-button"
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
          >
            Modifier l'option
          </Button>

        </Grid>
      
      </FormControl>
      <Snackbar
          open={this.state.open}
          onClose={this.handleCloseSnackbar}
          severity={this.state.snackBarSeverity}
          message={this.state.snackBarMessage}
        />
      {/* </Paper> */}
  {/* <div style={{marginTop:'1000', position:'absolute'}}> */}
        
      Produits affecté a l'option:
      <Grid>
    
          <List
        key="list-of-products"
        list={this.makeRows(optionProducts)}
        columns={['Nom', 'Catégorie Perso', 'Prix']}
        ids={this.makeIds(optionProducts)}
        emptyMessage="Cette option n’a aucun produit"
      
/> 
      </Grid>
      </Paper>
 </>
    )
    }
}

export default withStyles(styles)(OptionEdit);