import { deal } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { findIndex } from 'lodash'
import { parseErrorMessage } from '../../../core.utils/requestActions'

export const {
  FETCH_RESTAURANT_DEALS_REQUESTED,
  FETCH_RESTAURANT_DEALS_SUCCEEDED,
  FETCH_RESTAURANT_DEALS_FAILED,
  fetchRestaurantDealsSucceeded,
  fetchRestaurantDealsFailed,
  fetchRestaurantDealsStateReducer
} = createRequestActions('FETCH_RESTAURANT_DEALS')

export const fetchRestaurantDealsRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_DEALS_REQUESTED,
    data: {
      restaurantId: id
    }
  })
  deal
    .getRestaurantDeals(id)
    .then(data => {
      dispatch(fetchRestaurantDealsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantDealsFailed(error))
    })
}

export const {
  CREATE_RESTAURANT_DEAL_REQUESTED,
  CREATE_RESTAURANT_DEAL_SUCCEEDED,
  CREATE_RESTAURANT_DEAL_FAILED,
  createRestaurantDealSucceeded,
  createRestaurantDealFailed,
  createRestaurantDealStateReducer
} = createRequestActions('CREATE_RESTAURANT_DEAL')

export const createRestaurantDealRequested = (
  restaurantId,
  dealInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_DEAL_REQUESTED,
    data: {
      restaurantId,
      dealInfos
    }
  })
  deal
  .add(restaurantId, dealInfos)
  .then(data => {
      dispatch(createRestaurantDealSucceeded(data))
    })
    .catch(error => {
      dispatch(createRestaurantDealFailed(error))
    })
}

export const {
  EDIT_RESTAURANT_DEAL_REQUESTED,
  EDIT_RESTAURANT_DEAL_SUCCEEDED,
  EDIT_RESTAURANT_DEAL_FAILED,
  editRestaurantDealSucceeded,
  editRestaurantDealFailed
} = createRequestActions('EDIT_RESTAURANT_DEAL')

export const editRestaurantDealRequested = (
  dealId,
  dealInfos
) => dispatch => {
  dispatch({
    type: EDIT_RESTAURANT_DEAL_REQUESTED,
    data: {
      dealId,
      dealInfos
    }
  })
  deal.
  edit(dealId, dealInfos)
  .then(data => {
      dispatch(
        editRestaurantDealSucceeded(data)
      )
    })
    .catch(error => {
      dispatch(editRestaurantDealFailed(error))
      
    })
}

export const {
  DELETE_RESTAURANT_DEAL_REQUESTED,
  DELETE_RESTAURANT_DEAL_SUCCEEDED,
  DELETE_RESTAURANT_DEAL_FAILED,
  deleteRestaurantDealSucceeded,
  deleteRestaurantDealFailed
} = createRequestActions('DELETE_RESTAURANT_DEAL')

export const deleteRestaurantDealRequested = dealId => dispatch => {
  dispatch({
    type: DELETE_RESTAURANT_DEAL_REQUESTED,
    data: {
      dealId
    }
  })
  deal
    .delete(dealId)
    .then(data => {
      dispatch(
        deleteRestaurantDealSucceeded({
          dealId
        })
      )
    })
    .catch(error => {
      dispatch(deleteRestaurantDealFailed(error))
    })
}



export const defaultState = { list: [], requestState: '' }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_RESTAURANT_DEALS_REQUESTED:
    case EDIT_RESTAURANT_DEAL_REQUESTED:
    case CREATE_RESTAURANT_DEAL_REQUESTED:
    case DELETE_RESTAURANT_DEAL_REQUESTED:
   
      return { ...state, requestState: requestStates.PENDING }
      
    case FETCH_RESTAURANT_DEALS_SUCCEEDED:
      {
        const { data } = action.payload
  
        return {
          ...state,
          list: data,
          requestState: requestStates.SUCCESS
        }
      }
    case CREATE_RESTAURANT_DEAL_SUCCEEDED:
   
         {
      const { data } = action.payload
      return {
        ...state,
        list: [data,...state.list],
        requestState: requestStates.SUCCESS
      }
    }
    case EDIT_RESTAURANT_DEAL_SUCCEEDED: {
    const { list } = state
      const {  data } = action.payload
      const index = findIndex(list, (d) => d.id === data.id  || d._id === data.id)
      return {
        ...state,
        list: [
          ...list.slice(0, index),
          { ...list[index], ...data },
          ...list.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    }

    case DELETE_RESTAURANT_DEAL_SUCCEEDED: {
      const { dealId } = action.payload.data
      const index = findIndex(
        state.list,
        p => p.id === dealId || p._id === dealId
      )
  
      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          ...state.list.slice(index + 1)
        ],
  
        requestState: requestStates.SUCCESS
      }
    }
   
    
   
    case FETCH_RESTAURANT_DEALS_FAILED:
    case CREATE_RESTAURANT_DEAL_FAILED:
    case EDIT_RESTAURANT_DEAL_FAILED:
    case DELETE_RESTAURANT_DEAL_FAILED:
   
      return { 
        ...state, 
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
      
  
      
    default:
      return state
  }
}

const getState = state => state.currentRestaurant.deals
const getRestaurantId = state => state.currentRestaurant.infos.id
const getRestaurantDeals= state => getState(state).list
const getRequestState = state => getState(state).requestState
const getErrorMessage = state => getState(state).errorMessage

export const selectors = {
  getState, 
  getRestaurantId,
  getRestaurantDeals,
  getRequestState,
  getErrorMessage
}
