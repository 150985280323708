import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { NewTableCellSession } from './NewTableCell';
import DeletePlanning from './DeletePlanning';
import NewEditPlanning from './NewEditPlanning';
import PlanningConfig from "./PlanningConfig"

const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const daysToFr = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

const useStyles = makeStyles({
	table: {
		minWidth: 650
	}
});

export default function NewPlanningTable({
	rows,
	isPreviousWeek,
	selectedWeek,	
	refresh,	
	city,
}) {
	const classes = useStyles();



	const renderFirstRowCell = (title) => {
		return (
			<TableCell
				style={{
					borderRight: '1px solid lightgrey',
					borderTop: '1px solid lightgrey'
				}}
				align="center"
			>
				{title}
			</TableCell>
		);
	};

	const renderCell = (row, day, session) => {
		const cell = row?.isEmpty ? null : row?.week && row?.week[day] ? row?.week[day][session] : null

        if (!cell || !cell?.hours || cell?.hours?.length === 0) {
            return renderFirstRowCell("N/A");
        }

		return (
			<NewTableCellSession                
				refetch={refresh}
				isPreviousWeek={isPreviousWeek}
				value={`${cell.hours && cell.hours[0]?.start}-${cell.hours && cell.hours[0]?.end}`}
				entity={{...cell, day}}
				session={session}
				planningId={row.planning[0]?.id || row?.planning[0]?._id}
			/>
		);
    };

	const translateTransport = (transport) => {
		switch(transport) {
			case "car":
				return "🚗 Voiture"
			case "bike":
				return "🚴🏻‍♀️ Vélo"
				case "scooter":
				return "🛵 Scooter"
			default:
				
		}
	 };

	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table
					className={classes.table}
					style={{ borderColor: '#5C985C', borderWidth: 1, marginTop: 20, padding: 2 }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							<TableCell
								style={{
									position: '-webkit-sticky',
									position: 'sticky',
									background: '#fff',
									left: 0,
									zIndex: 1,
									borderRight: '1px solid lightgrey',
									borderTop: '1px solid lightgrey',
									borderLeft: '1px solid lightgrey'
								}}
							>
								Livreurs
							</TableCell>

                            {daysToFr.map((d, index) => (
                                <React.Fragment key={index.toString()}>
                                    {renderFirstRowCell(`${d} matin`)}
                                    {renderFirstRowCell(`${d} soir`)}
                                </React.Fragment>
                            ))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => {
							return (
								<TableRow key={index.toString()}>
									<TableCell
										style={{
											position: '-webkit-sticky',
											position: 'sticky',
											background: !!row.rider.isDummy ? "#FFFF00" : "#FFF",
											left: 0,
											zIndex: 1,
											borderRight: '1px solid lightgrey'
										}}
										component="th"
										scope="row"
									>
										<p style={{ fontWeight: 'bold', fontSize: 18, margin: 0 }}>{row.rider.firstName} {row.rider.lastName}</p>
										<p style={{ margin: 0 }}>{row.rider.email}</p>
										<p style={{ margin: 0 }}>{row.rider.phone}</p>
										<p style={{ margin: 0 }}>{translateTransport(row.rider.transport)}</p>

										<div style={{ display: 'flex' }}>
											{!isPreviousWeek && <NewEditPlanning
												refetch={refresh}
												session={row}
												selectedWeek={selectedWeek}
											/>
											}
											{row?.planning?.length > 0 && !isPreviousWeek && <DeletePlanning refresh={refresh} planningId={row?.planning[0]?.id || row?.planning[0]?._id} />}
										</div>
									</TableCell>

									{days.map((d, index) => (
										<React.Fragment key={index.toString()}>
											{ renderCell(row, d, "morningSession") }
											{ renderCell(row, d, "nightSession") }
										</React.Fragment>
									))}
								</TableRow>
							)}
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{!isPreviousWeek && <PlanningConfig city={city} selectedWeek={selectedWeek} />}
		</React.Fragment>
	);
}
