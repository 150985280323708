import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  Switch,
  Button,
  CircularProgress, 
  Box
} from '@material-ui/core'
//import Autocomplete from '@mui/material/Autocomplete'
import Autocomplete from '@material-ui/lab/Autocomplete'
//import TextFieldNative from '@mui/material/TextField'
import { TextField as TextFieldNative } from '@material-ui/core'
import classnames from 'classnames'
import { isEmpty, isNumber } from 'lodash'
import Select1 from '@material-ui/core/Select'
import Dialog from 'core.ui/Dialog'
import { restaurant } from 'core.services/api'
import { selectors as categoriesSelectors } from 'core.modules/categories/reducer'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import {
  isEmailValid,
  isTelephoneValid,
  isNumberWithinRange,
  isNumberValid
} from 'core.utils/formValidation.js'
import { parseImage } from 'core.utils/parser/image'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from 'date-fns/locale/fr'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Select, { parseMultipleSelection } from 'core.ui/Select'
import TextField from 'core.ui/TextField'
import Address from 'components/Address'
import Horaires from 'components/Horaires'
import UploadButton from 'components/UploadButton'

const styles = {
  mainContainer: {
    margin: '20px auto',
    width: '562px',
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'column'
  },
  rows: {
    marginBottom: '12px'
  },
  lines: {
    display: 'flex',
    flexDirection: 'row'
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewImage: { width: '200px' },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative',
    marginLeft: 20
  },
  buttonLoadingAnimation: {
    color: blue[500],
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  },
  select: { margin: '6px 0' },
  selectLabel: {
    margin: '6px 0'
  },
  commissionTextField: {
    width: '85%',
    marginRight: '10px'
  },
  zipCode: {
    width: '150px'
  },
  referantName: { marginLeft: '10px' }
}

export const distangeRange = [
  { range: 'Pas de limite', value: null },
  { range: '1 Km', value: 1000 },
  { range: '2 Km', value: 2000 },
  { range: '3 Km', value: 3000 },
  { range: '4 Km', value: 4000 },
  { range: '5 Km', value: 5000 },
  { range: '6 Km', value: 6000 },
  { range: '7 Km', value: 7000 },
  { range: '8 Km', value: 8000 },
  { range: '9 Km', value: 9000 },
  { range: '10 Km', value: 10000 },
  { range: '11 Km', value: 11000 },
  { range: '12 Km', value: 12000 },
  { range: '13 Km', value: 13000 },
  { range: '14 Km', value: 14000 },
  { range: '15 Km', value: 15000 },
  { range: '20 Km', value: 20000 },
  { range: '25 Km', value: 25000 }
]

export const requiredFields = [
  'nom',
  'email',
  'telephone',
  'horaires',
  'comission',
  'note',
  'siteNumber',
  'password',
  'web_password',
  'adress'
]

export const defaultState = {
  isConfirmDialogVisible: false,
  disabled: false,
  isConfigurated: false,
  isFranchise: false,
  isPosInfoVisible: false,
  integrationType: '',
  listFranchises: [],
  isSpecialSchedulesToggled: false,
  open: false,
  loading: false,
  form: {
    nom: '',
    headers: [],
    categories: [], // [{ id_ : "abc", "category" : "useMe" }]
    description: '',
    email: '',
    password: '',
    web_password: '',
    telephone: '',
    ambulant: false,
    actif: false,
    visible: false,
    permissions: {
      setInactive: true,
      postponeOrder: true
      
    },
    autoCook: false,
    autoSearch: false,
    posInfo: {
      enabled: false,
      type: '',
      url: ''
    },
    csInfo: {
      csId: ''
    },
    franchiseId: '',
    //pmt: '',
    city: '', // id
    adress: {
      streetAdress: '',
      zipCode: '',
      city: '',
      latitude: '',
      longitude: ''
    },
    adresses: {
      morning: {
        streetAdress: '',
        zipCode: '',
        city: ''
      },
      afternoon: {
        streetAdress: '',
        zipCode: '',
        city: ''
      },
      night: {
        streetAdress: '',
        zipCode: '',
        city: ''
      },
      horaires: [],
      comission: 0,
      note: 0
    },
    specialSchedules: [],
    horaires: [],
    comission: '25',
    comissionType: 'TTC',
    note: '',
    legalLink: undefined,
    image: null,
    referant: { nom: '', prenom: '', telephone: '' },
    siret: '',
    balanceDetails: {
      currentBalance: '',
      initialBalance: '',
      weeksBeforeDebit: ''
    },
    distanceLimit: '',
    companyName: undefined,
    siteNumber: undefined,
    mealVoucherTypes: [],
    hasImageChanged: false,
    usesV2Schema: undefined,
    config: {},
  },
  showErrors: false,
  validFields: {},
  imagePreview: null,
}
class RestaurantForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    const { data } = this.props

    const integrationTypeInitialValue = data?.posInfo?.type
      ? 'external' 
      : data?.posInfo?.enabled 
        ? 'internal' 
        : 'no_integration'

    if (data) {
      this.setState(
        {
          form: {
            ...this.state.form,
            ...data
          },
          isPosInfoVisible: data.posInfo?.enabled || !!data.posInfo?.type,
          integrationType: integrationTypeInitialValue,
          isSpecialSchedulesToggled: data.specialSchedules?.length ? true : false,
          isFranchise: !!data.franchiseId
        },
        () => {
          const validFields = this.getValidFields()
          this.setState({
            validFields
          })
        }
      )

      restaurant
        .getImage(data?.image?.url)
        .then((image) => {
          return parseImage(image)
        })
        .then((result) => {
          this.setState({
            imagePreview: result
          })
        })
        .catch((e) => {
          console.error(e)
          this.setState({
            imagePreview: null
          })
        })
    }

  }

  isFieldValid = (field, value) => {
    const { isPasswordRequired  = true, data } = this.props


    return field === 'email'
    ? isEmailValid(value)
    : field === 'telephone'
    ? isTelephoneValid(value)
    : field === 'comission'
    ? isNumberWithinRange(0, 100)(value)
    : field === 'note'
    ? (!isEmpty(value) ? isNumberWithinRange(0, 5)(value) : true)
    : field === 'siteNumber'
    ? (!isEmpty(value) ? isNumberValid(value) && value > 0 : true)
    : field === 'adress'
    ?  !((Number.isNaN(value.latitude) || value.latitude < -90 || value.latitude > 90) && (Number.isNaN(value.longitude) || value.longitude < -180 || value.longitude > 180))
    : field === 'password'
    ? isPasswordRequired
      ? !isEmpty(value)
      : true
    : field === 'web_password'
    ? isPasswordRequired
      ? !isEmpty(value)
      : true
    : field === 'image'
    ? true
    : isNumber(value) || Array.isArray(value) || !isEmpty(value)
  }

  getValidFields = () => {
    const { form } = this.state
    const validFields = requiredFields.map((field) =>
      this.isFieldValid(field, form[field])
    )
    return Object.fromEntries(requiredFields.map((f, i) => [f, validFields[i]]))
  }

  handleChange = (field) => (e) => {
    const { value } = e.target
    const { form, validFields, showErrors } = this.state
    const fields = field.split('.')
    const trimmedValue = typeof value === 'string' ? value?.trim() : value

    if (fields.length === 1) {
      // if (field === "checkoutSystem" && !form.hasCheckoutSystem) {
      //   this.setState({
      //     form: {
      //       ...form,
      //       [field]: null
      //     },
      //   })
      // }

      this.setState({
        form: {
          ...form,
          [field]: trimmedValue
        },
        validFields: {
          ...validFields,
          [field]: this.isFieldValid(field, value)
        }
      })
    } else {
      this.setState({
        form: {
          ...form,
          [fields[0]]: { ...form[fields[0]], [fields[1]]: trimmedValue }
        },
        validFields: {
          ...validFields,
          [fields[0]]: this.isFieldValid(fields[0], {
            ...form[fields[0]],
            [fields[1]]: value
          })
        }
      })
    }
  }

  handleSpecialSchedulesChange = (field) => (value) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        specialSchedules: [{ ...form.specialSchedules[0], [field]: value }]
      }
    })
  }

  handleLegalLinkChange = e => {
    const { value } = e.target
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        legalLink: value
      }
    })
  }

  handleCompanyNameChange = (e) => {
    const { value } = e.target
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        companyName: value ? value.trim() : undefined
      }
    })
  }

  handleSiteNumberChange = (e) => {
    const { value } = e.target
    const { form, validFields } = this.state
    this.setState({
      form: {
        ...form,
        siteNumber: value ? value.trim() : undefined
      }
    })
  }

  handleDistanceLimitChange = (e) => {
    const { value } = e.target
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        distanceLimit: value
      }
    })
  }

  handleSwitchChange = (field) => (e) => {
    const { checked } = e.target
    const { form } = this.state
    const fields = field.split('.')

    fields.length === 1 ? this.setState({
      form: {
        ...form,
        [field]: checked
      }
    }) : this.setState({
      form: {
        ...form,
        [fields[0]]: { ...form[fields[0]], [fields[1]]: checked }
      }
    })
  }

  handleMultipleSelectChange = (field) => (e) => {
    // TODO: specific for category, not good
    const { form } = this.state
    const value = parseMultipleSelection(e).map((cat) => ({
      category: cat
    }))
    this.setState({
      form: {
        ...form,
        [field]: value
      }
    })
  }

  handleImageSelect = (e) => {
    const { files } = e.target
    const { form } = this.state
    parseImage(files[0]).then((result) => {
      this.setState({
        form: { ...form, image: files[0], hasImageChanged: true },
        imagePreview: result
      })
    })
  }

  handleValidFieldChange = (field) => (value) => {
    const { validFields } = this.state
    this.setState({
      validFields: {
        ...validFields,
        [field]: value
      }
    })
  }

  handleOpenChange = async (value) => {
    const newState = { open: value }
    if (!value) newState.regions = []
    else newState.loading = true
    this.setState(newState)
    if (value) {
      try {
        const franchises = await restaurant.getListFranchises()
        this.setState({ listFranchises: franchises, loading: false })
      } catch (error) {
        this.setState({ listFranchises: [], loading: false })
      }
    }
  }

  handleIntegrationChange = (e) => {
    const { form } = this.state
    const integrationType = e.target.value
    const stateChange = { integrationType }

    switch (integrationType) {
      case 'no_integration':
        stateChange.form = { ...form, posInfo: {} }
        break;
      case 'internal':
        stateChange.form = { ...form, posInfo: { enabled: true } }
        break;
      case 'external':
        stateChange.form = { ...form, posInfo: { enabled: false } }
        break;
      default:
        break;
    }

    this.setState(stateChange)
  }

  handleSubmit = () => {
    const { id, onSubmit, data } = this.props
    const { form, isFranchise, isPosInfoVisible } = this.state

    const validFields = this.getValidFields();

    this.setState({
      validFields
    }, () => {
      if (
        Object.values(validFields).some((f) => !f) ||
        (!data &&
          (isEmpty(form.adress.latitude) || isEmpty(form.adress.longitude))) ||
        (form.posInfo?.type === 'pmt' && isEmpty(form.csInfo?.csId))
      ) {
        this.setState({ validFields, showErrors: true })
      } else {
        if (this.checkIfSiretOrBalanceModified()) {
          // show popup
          this.setState({ isConfirmDialogVisible: true })
        } else {

          const restaurantInfos = { ...form }
          const posInfo = isPosInfoVisible ? restaurantInfos.posInfo : null

          onSubmit(
            {
              ...restaurantInfos,
              posInfo,
              csInfo: {
                csId: !restaurantInfos.posInfo?.type || restaurantInfos.posInfo?.type === 'sequoia' ? '' : restaurantInfos.csInfo.csId
              },
              // checkoutSystem: restaurantInfos.hasCheckoutSystem ? restaurantInfos.checkoutSystem : null,
              franchiseId: isFranchise ? restaurantInfos.franchiseId?._id || restaurantInfos.franchiseId?.id : null
            },
            id
          )
        }
      }
    })
  }

  checkIfSiretOrBalanceModified = () => {
    const { data } = this.props
    const { form } = this.state
    return (
      !!data &&
      !!data.balanceDetails &&
      !!data.siret &&
      !!form &&
      (data.siret !== form.siret ||
        data.balanceDetails?.initialBalance !==
          form.balanceDetails?.initialBalance ||
        data.balanceDetails?.currentBalance !==
          form.balanceDetails?.currentBalance ||
        data.balanceDetails?.weeksBeforeDebit !==
          form.balanceDetails?.weeksBeforeDebit)
    )
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  handleMealVoucherTypesChange = (id) => (e) => {
    const { form } = this.state
    const { mealVoucherTypes } = form
    const { checked } = e.target
    const index = mealVoucherTypes.findIndex((m) => m._id === id)

    let types = [...mealVoucherTypes]
    const newItem = {
      ...types[index],
      isDisabled: !checked
    }
    types[index] = newItem
    const newArray = [
      ...mealVoucherTypes.slice(0, index),
      newItem,
      ...mealVoucherTypes.slice(index + 1)
    ]

    this.setState({
      form: {
        ...form,
        mealVoucherTypes: newArray
      }
    })
  }

  render() {

    const {
      form,
      showErrors,
      validFields,
      imagePreview,
      isConfirmDialogVisible,
      disabled,
      isConfigurated,
      isFranchise,
      listFranchises,
      isSpecialSchedulesToggled,
      open,
      loading,
      isPosInfoVisible,
      integrationType,
    } = this.state
  
    const {
      nom,
      categories,
      email,
      password,
      web_password,
      telephone,
      ambulant,
      description,
      autoCook,
      autoSearch,
      permissions,
      franchiseId,
      csInfo,
      actif,
      visible,
      posInfo,
      city,
      adress,
      adresses,
      horaires,
      comission,
      comissionType,
      note,
      referant,
      siret,
      specialSchedules,
      balanceDetails,
      distanceLimit,
      legalLink,
      companyName,
      siteNumber,
      mealVoucherTypes,
      usesV2Schema,
      config
    } = form

    const { classes, allCategories, allCities, showLoading, data } = this.props

    const posTypes = [{ value: 'sequoia', text: 'Sequoia Buffalo/BMB'}, { value: 'pmt', text: 'PMT: Courtepaille/Léon/3 Brasseurs'}]
    if (usesV2Schema) {
      posTypes.push({ value: 'mcdonalds', 'text': "Mcdonald's" })
    }

    const integrationTypes = [
      { value: 'no_integration', text: "Pas d'integration" },
      { value: 'external', text: 'Externe (partenaire)' },
    ]

    if (usesV2Schema) {
      integrationTypes.push({ value: 'internal', text: 'Interne (Api publique)' })
    }

    return (
      <div
        className={classnames(classes.mainContainer)}
        id="restaurant-form-main-container"
      >
        <div
          id="restaurant-form-form-container"
          className={classes.mainContainer}
        >
          {/* IMAGE */}
          <div className={classnames(classes.rows, classes.imageWrapper)}>
            {imagePreview && (
              <img
                className={classnames(classes.rows, classes.previewImage)}
                src={imagePreview}
                alt="Restaurant"
              />
            )}
            <UploadButton title="Photo" onFileSelect={this.handleImageSelect} />
          </div>
          {/* NOM */}
          <TextField
            className={classes.row}
            value={nom}
            onChangeComplete={this.handleChange('nom')}
            fullWidth
            label="Nom du restaurant"
            error={showErrors && !validFields.nom}
            helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }
          />
          <TextField
            id="outlined-multiline-static"
            multiline
            className={classes.description}
            value={description}
            rows={2}
            onChangeComplete={this.handleChange('description')}
            label="Description du restaurant"
          />

          {/* ACTIF VISIBLE */}
          <div className={classnames(classes.lines, classes.rows)}>
            <FormControlLabel
              control={
                <Switch
                  checked={actif}
                  onChange={this.handleSwitchChange('actif')}
                />
              }
              label="Actif"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={visible}
                  onChange={this.handleSwitchChange('visible')}
                />
              }
              label="Visible"
            />
          </div>
          {/* CATÉGORIES */}
          <FormControl>
            <InputLabel shrink>Catégories</InputLabel>
            <Select
              className={classes.rows}
              native={true}
              multiple
              options={allCategories.map((cat) => cat.id)}
              captions={allCategories.map((cat) => cat.name)}
              value={categories.map((cat) => cat.category)}
              onChange={this.handleMultipleSelectChange('categories')}
            />
          </FormControl>
          {/* distanceLimit*/}
          <FormControl>
            <InputLabel>Limite de distance</InputLabel>
            <Select1
              className={classes.rows}
              value={distanceLimit}
              onChange={this.handleDistanceLimitChange}
            >
              {distangeRange.map((d) => (
                <option value={d.value}>{d.range}</option>
              ))}
            </Select1>
          </FormControl>
          {/* EMAIL */}
          <TextField
            className={classes.rows}
            value={email}
            onChangeComplete={this.handleChange('email')}
            label="Email"
            error={showErrors && !validFields.email}
            helperText={
              showErrors && !validFields.email
                ? 'Entrez une adresse mail valide'
                : ''
            }
          />
          {/* MOT DE PASSE */}
          <TextField
            className={classes.rows}
            value={password}
            type="text"
            inputProps={{
              autoComplete: 'new-password'
            }}
            onChangeComplete={this.handleChange('password')}
            label="Mot de passe"
            error={showErrors && !validFields.password}
            helperText={
              showErrors && !validFields.password
                ? 'Entrez un mot de passe'
                : ''
            }
          />
          <TextField
            className={classes.rows}
            value={web_password}
            type="text"
            inputProps={{
              autoComplete: 'new-password'
            }}
            onChangeComplete={this.handleChange('web_password')}
            label="Mot de passe web"
            error={showErrors && !validFields.web_password}
            helperText={
              showErrors && !validFields.web_password
                ? 'Entrez un mot de passe'
                : ''
            }
          />
          {/* TÉLÉPHONE */}
          <TextField
            className={classes.rows}
            value={telephone}
            onChangeComplete={this.handleChange('telephone')}
            label="Téléphone"
            error={showErrors && !validFields.telephone}
            helperText={
              showErrors && !validFields.telephone
                ? 'Entrez un téléphone valide'
                : ''
            }
          />
          {/* RÉFÉRANT */}
          <div>Référant</div>
          <div className={classnames(classes.lines, classes.rows)}>
            <TextField
              value={referant.prenom}
              fullWidth
              onChangeComplete={this.handleChange('referant.prenom')}
              label="Prénom"
            />
            <TextField
              className={classes.referantName}
              value={referant.nom}
              fullWidth
              onChangeComplete={this.handleChange('referant.nom')}
              label="Nom"
            />
          </div>
          <div className={classnames(classes.lines, classes.rows)}>
            <TextField
              value={referant.telephone}
              fullWidth
              onChangeComplete={this.handleChange('referant.telephone')}
              label="Telephone du referant"
            />
          </div>
          {/* Company Name */}
          <TextField
            value={companyName}
            onChangeComplete={this.handleCompanyNameChange}
            fullWidth
            label="Nom de l'entreprise"
          />
          {/* SIRET */}
          <div className={classnames(classes.lines, classes.rows)}>
            <TextField
              value={siret}
              fullWidth
              onChangeComplete={this.handleChange('siret')}
              label="Siret"
            />
          </div>
          {/* Site Number */}
          <TextField
            value={siteNumber}
            onChangeComplete={this.handleSiteNumberChange}
            fullWidth
            label="Numéro du site"
            error={showErrors && !validFields.siteNumber}
            helperText={
              showErrors && !validFields.siteNumber
                ? 'Entrez un unméro > 0'
                : ''
            }
          />
          {/* NOTE */}
          <TextField
            value={note}
            onChangeComplete={this.handleChange('note')}
            fullWidth
            label="Note"
            error={showErrors && !validFields.note}
            helperText={
              showErrors && !validFields.note
                ? 'Entrez une valeur entre 0 et 5'
                : ''
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={isSpecialSchedulesToggled}
                onChange={(e, value) => 
                  this.setState({ isSpecialSchedulesToggled: !isSpecialSchedulesToggled, form: !value ? { ...form, specialSchedules: [] } : form })
                }
              />
            }
            label="Période de vacances"
          />
          {isSpecialSchedulesToggled && 
            <Box display="flex" direction="row" my={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <FormControl fullWidth>
              <DateTimePicker
                ampm={false}
                disablePast={false}
                onChange={this.handleSpecialSchedulesChange('startDate')}
                label="Date de début"
                value={specialSchedules[0]?.startDate || null}
                format="dd MMMM yyyy HH:mm"
                inputVariant="outlined"
                renderInput={(params) => <TextField {...params} />}
              />
              </FormControl>
              <FormControl fullWidth style={{ marginLeft: '10px' }}>
              <DateTimePicker
                ampm={false}
                minDate={specialSchedules[0]?.startDate}
                label="Date de fin"
                onChange={this.handleSpecialSchedulesChange('endDate')}
                inputVariant="outlined"
                value={specialSchedules[0]?.endDate || null}
                format="dd MMMM yyyy HH:mm"
                renderInput={(params) => <TextField {...params} />}
              />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Box>
        }

          {/* SOLDES */}
          <div>Soldes</div>
          <div className={classnames(classes.lines, classes.rows)}>
            <TextField
              // disabled={!!balanceDetails?.initialBalance}
              value={balanceDetails?.initialBalance}
              fullWidth
              onChangeComplete={this.handleChange(
                'balanceDetails.initialBalance'
              )}
              label="Solde initial"
            />
            <TextField
              // disabled={!!balanceDetails?.currentBalance}
              className={classes.referantName}
              value={balanceDetails?.currentBalance}
              fullWidth
              onChangeComplete={this.handleChange(
                'balanceDetails.currentBalance'
              )}
              label="Solde instantané"
            />

            <TextField
              className={classes.referantName}
              value={balanceDetails?.weeksBeforeDebit}
              fullWidth
              onChangeComplete={this.handleChange(
                'balanceDetails.weeksBeforeDebit'
              )}
              label="Semaines avant débit"
            />
          </div>
          {/* HORAIRES*/}
          <Horaires
            className={classes.rows}
            value={horaires}
            onChange={this.handleChange('horaires')}
            error={showErrors && !validFields.horaires}
            helperText={
              showErrors && !validFields.horaires
                ? 'Ajoutez au moins un horaire'
                : ''
            }
          />
          {/* CITY */}
          <FormControl>
            <InputLabel className={classes.selectLabel} shrink>
              Ville de référence
            </InputLabel>
            <Select
              className={classnames(classes.rows, classes.select)}
              native
              value={city}
              options={allCities.map((c) => c._id)}
              captions={allCities.map((c) => c.name)}
              onChange={this.handleChange('city')}
            />
          </FormControl>
          {/* AMBULANT */}
          <FormControlLabel
            control={
              <Switch
                checked={ambulant}
                onChange={this.handleSwitchChange('ambulant')}
              />
            }
            label="Ambulant"
          />
          {/* ADRESSE */}
          {ambulant ? (
            <div className={classes.rows}>
              <Address
                title="Adresse le matin"
                value={adresses.morning}
                onChange={this.handleChange('adresses.morning')}
                handleValidFieldChange={this.handleValidFieldChange(
                  'adresses.morning'
                )}
                showErrors={showErrors}
              />
              <Address
                title="Adresse l’après-midi"
                value={adresses.afternoon}
                onChange={this.handleChange('adresses.afternoon')}
                handleValidFieldChange={this.handleValidFieldChange(
                  'adresses.afternoon'
                )}
                showErrors={showErrors}
              />
              <Address
                title="Adresse le soir"
                value={adresses.night}
                onChange={this.handleChange('adresses.night')}
                handleValidFieldChange={this.handleValidFieldChange(
                  'adresses.night'
                )}
                showErrors={showErrors}
              />
            </div>
          ) : (
            <Address
              className={classes.rows}
              value={adress}
              onChange={this.handleChange('adress')}
              handleValidFieldChange={this.handleValidFieldChange('adress')}
              showErrors={showErrors}
            />
          )}
          {/* LAT / LONG */}
          {data && (
            <FormControlLabel
              control={
                <Switch
                  checked={disabled}
                  onChange={() => this.setState({ disabled: !disabled })}
                />
              }
              label="Lat/Long"
            />
          )}
          {/* LAT / LONG FORMS */}
          {(disabled || !data) ? (
            <div className={classnames(classes.lines, classes.rows)}>
              <TextField
                fullWidth
                value={adress.latitude}
                required={!data && isEmpty(adress.latitude)}
                onChangeComplete={this.handleChange('adress.latitude')}
                label="Latitude"
                error={showErrors && !validFields.adress}
                helperText={
                  showErrors && !validFields.adress
                  ? 'Entrez la latitude'
                  : ''
                }

              />
              <TextField
                fullWidth
                className={classes.referantName}
                value={adress.longitude}
                required={!data && isEmpty(adress.longitude)}
                onChangeComplete={this.handleChange('adress.longitude')}
                label="Longitude"
                error={showErrors && !validFields.adress?.longitude}
                helperText={
                  showErrors && !validFields.adress?.longitude
                  ? 'Longitude incorrecte'
                  : ''
                }

              />
            </div>
          ):(
            <div className={classnames(classes.lines, classes.rows)}>
              <TextField
                fullWidth
                value={adress.latitude}
                required={!data && isEmpty(adress.latitude)}
                onChangeComplete={this.handleChange('adress.latitude')}
                label="Latitude"
                error={showErrors && !validFields.adress}
                helperText={
                  showErrors && !validFields.adress
                    ? 'Latitude incorrecte'
                    : ''
                }
                disabled = { !disabled }
              />
              <TextField
                fullWidth
                className={classes.referantName}
                value={adress.longitude}
                required={!data && isEmpty(adress.longitude)}
                onChangeComplete={this.handleChange('adress.longitude')}
                label="Longitude"
                error={showErrors && !validFields.adress}
                helperText={
                  showErrors && !validFields.adress
                    ? 'Entrez la longitude'
                    : ''
                }
                disabled = { !disabled }
              />
            </div>
          )}
          {/* Configurations */}
          <FormControlLabel
            control={
              <Switch
                checked={isConfigurated}
                onChange={() =>
                  this.setState({ isConfigurated: !isConfigurated })
                     
                }
              />
            }
            label="Configurations"
          />
          {/* COMMISSION */}
          {isConfigurated && (
            <div className={classnames(classes.lines, classes.rows)}>
              <TextField
                title="Commission"
                className={classes.commissionTextField}
                value={comission}
                onChangeComplete={this.handleChange('comission')}
                fullWidth
                label="Commission (%)"
                error={showErrors && !validFields.comission}
                helperText={
                  showErrors && !validFields.comission
                    ? 'Entrez une valeur entre 0 et 100'
                    : ''
                }
              />
              <FormControl>
                <InputLabel className={classes.selectLabel} shrink>
                  type
                </InputLabel>
                <Select
                  title="commission"
                  className={classnames(
                    classes.select,
                    classes.commissionTypeSelect
                  )}
                  classes
                  native
                  value={comissionType}
                  options={['HT', 'TTC']}
                  captions={['HT', 'TTC']}
                  onChange={this.handleChange('comissionType')}
                />
              </FormControl>
            </div>
          )}
          {/* Legal Link */}
          {isConfigurated && (
            <TextField
              value={legalLink}
              onChangeComplete={this.handleLegalLinkChange}
              fullWidth
              label="Lien légal"
            />
          )}
          {isConfigurated && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <span style={{ marginRight: '15px' }}>Franchise: </span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isFranchise}
                    onChange={() =>
                      this.setState({ isFranchise: !isFranchise })
                    }
                  />
                }
                label=""
              />
            </div>
          )}
           {/* mealVoucherTypes */}
          
           {isConfigurated && (
            <>

            {isFranchise && (
              <FormControl>
                <Autocomplete
                  id="franchises"
                  value={franchiseId}
                  style={{ width: '100%' }}
                  freeSolo={true}
                  options={listFranchises}
                  getOptionLabel={(f) => f.franchiseName}
                  open={open}
                  onOpen={() => this.handleOpenChange(true)}
                  onClose={() => this.handleOpenChange(false)}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  onChange={(e, value) => {
                    if (!value) return
                    this.setState({
                      form: { ...this.state.form, franchiseId: value }
                    })
                  }}
                  loading={loading}
                  renderInput={(params) => (
                    <TextFieldNative
                      {...params}
                      label="Choisir une franchise"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
            </>
           )}
          {isConfigurated && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <span style={{ marginRight: '15px' }}>Intégration: </span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPosInfoVisible}
                    onChange={() =>
                      this.setState({ form: { ...form, posInfo: isPosInfoVisible ? {} : { ...form.posInfo, enabled: !form.posInfo?.enabled } }, isPosInfoVisible: !isPosInfoVisible })
                    }
                  />
                }
                label=""
              />
            </div>
          )}

         {isConfigurated && isPosInfoVisible && (
            <>
              <FormControl>
                {/* <InputLabel className={classes.selectLabel} shrink>
                </InputLabel> */}
                <div
                  className={classnames(classes.lines, classes.rows)}
                  style={{ alignItems: 'center' }}
                >
                <Select
                  className={classnames(
                    classes.select,
                    classes.commissionTypeSelect
                  )}
                  classes
                  native
                  value={integrationType}
                  options={integrationTypes.map((elem) => elem.value)}
                  captions={integrationTypes.map((elem) => elem.text)}
                  onChange={this.handleIntegrationChange}
                />
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={isInternalPosChecked}
                        onChange={() => this.setState({ isInternalPosChecked: !isInternalPosChecked })}
                      />
                    }
                    label=""
                  />
                  <span>Activer l'intégration interne (Api Publique)</span> */}
                </div>

                {integrationType === 'external' && (
                  <>
                    <div
                      className={classnames(classes.lines, classes.rows)}
                      style={{ alignItems: 'center' }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={posInfo?.enabled}
                            onChange={(e) => this.setState({ 
                              form: { ...form, posInfo: { ...form.posInfo, enabled: e.target.checked }}
                            })}
                          />
                        }
                        label=""
                      />
                      <span>Activer l'intégration avec un système de caisse externe</span>
                    </div>
                    <Select
                      className={classnames(
                        classes.select,
                        classes.commissionTypeSelect
                      )}
                      classes
                      native
                      value={posInfo?.type}
                      options={posTypes.map((posTypes) => posTypes.value)}
                      captions={posTypes.map((posTypes) => posTypes.text)}
                      onChange={this.handleChange('posInfo.type')}
                    />
                    {posInfo?.type === 'mcdonalds' && (
                      <div
                        className={classnames(classes.lines, classes.rows)}
                        style={{ alignItems: 'center' }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={posInfo.allowScheduleUpdate == null || posInfo.allowScheduleUpdate}
                              onChange={this.handleSwitchChange('posInfo.allowScheduleUpdate')}
                            />
                          }
                          label=""
                        />
                        <span>Activer l'envoi des horaires depuis la caisse (caisse Mcdonald's uniquement)</span>
                      </div>
                    )}
                    { posInfo?.type === 'pmt' && (
                    <TextField
                      value={csInfo.csId}
                      onChangeComplete={(e) => {
                        this.setState({
                          form: {
                            ...form,
                            csInfo: {
                              csId: e.target.value
                            }
                          }
                        })
                      }}
                      fullWidth
                      label="ID CAISSE PMT"
                      error={showErrors && csInfo?.csId === ''}
                      helperText={
                        showErrors && csInfo?.csId === '' ? "Entrez l'ID PMT" : ''
                      }
                    />
                  )}
                  </>
                )}

              </FormControl>

            </>
         )}
          {/* mealVoucherTypes */}
          {isConfigurated && (
            <>
              {mealVoucherTypes?.length ? (
                <>
                  <p>TRD:</p>
                  <div>Types de ticket restaurant: </div>
                  <br></br>
                  <div>
                    {mealVoucherTypes.map((m) => {
                      return (
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              size="small"
                              checked={!m.isDisabled}
                              onChange={this.handleMealVoucherTypesChange(
                                m._id
                              )}
                            />
                          }
                          label={m.type}
                        />
                      )
                    })}
                  </div>
                </>
              ) : (
                'Aucun titre restaurant configuré'
              )}
            </>
          )}

          {isConfigurated && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={autoCook}
                    onChange={this.handleSwitchChange('autoCook')}
                  />
                }
                label=""
              />
              <span>Accepter Automatiquement</span>
            </div>
          )}

          {isConfigurated && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={autoSearch}
                    onChange={this.handleSwitchChange('autoSearch')}
                  />
                }
                label=""
              />
              <span>Rechercher livreur Automatiquement</span>
            </div>
          )}
          {isConfigurated && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={!permissions.setInactive}
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...form,
                          permissions: {
                            ...form.permissions,
                            setInactive: !e.target.checked
                          }
                        }
                      })
                    }}
                  />
                }
                label=""
              />

              <span>Toujours Actif</span>
            </div>
          )}
           {isConfigurated && usesV2Schema && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={!!config?.disableOrderInstructions}
                    onChange={this.handleSwitchChange('config.disableOrderInstructions')}
                  />
                }
              />

              <span>Désactiver la note restaurant (restaurant v2 uniquement)</span>
            </div>
          )}
           {isConfigurated && (
            <div
              className={classnames(classes.lines, classes.rows)}
              style={{ alignItems: 'center' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={!permissions.postponeOrder}
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...form,
                          permissions: {
                            ...form.permissions,
                            postponeOrder: !e.target.checked
                          }
                        }
                      })
                    }}
                  />
                }
                label=""
              />

              <span>Ne pas repousser commande</span>
            </div>
          )}
        </div>

        <div className={classes.formButtonsContainer}>
          <Button
            onClick={this.handleCancel}
            variant="contained"
            color="default"
          >
            Annuler
          </Button>
          <div className={classes.loadingButtonWrapper}>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              disabled={showLoading}
            >
              Enregistrer
            </Button>
            {showLoading && (
              <CircularProgress
                size={30}
                className={classes.buttonLoadingAnimation}
              />
            )}
          </div>
        </div>

        <Dialog
          open={isConfirmDialogVisible}
          dialogTitle="Confirmation"
          dialogText="Vous avez changé le siret et/ou le solde du restaurant. Êtes-vous sûr de vouloir enregistrer ces changements ?"
          cancelButtonCaption="Annuler"
          confirmButtonCaption="Enregistrer"
          onCancel={() => this.setState({ isConfirmDialogVisible: false })}
          onValidation={() => {
            const restaurantInfos = {...this.state.form}

            this.props.onSubmit(restaurantInfos, this.props.id)
            this.setState({ isConfirmDialogVisible: false })
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  allCities: citiesSelectors.getAllCities(state),
  allCategories: categoriesSelectors.getState(state)
})

export default connect(mapStateToProps)(withStyles(styles)(RestaurantForm))
