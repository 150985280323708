import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import NotificationsIcon from '@material-ui/icons/Notifications'
import {
  Badge,
  MenuItem,
  MenuList,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import classnames from 'classnames'
import { get, isEmpty } from 'lodash'

import { getCookie, setCookie } from 'core.utils/parser/cookies'
import { makeFriendlyDateString } from 'core.utils/parser/time'
import { parsePrice } from 'core.utils/parser/price'
import { listenToNotifications } from 'core.modules/notifications/listener'
import {
  selectors as notificationsSelectors,
  allNotificationsRead
} from 'core.modules/notifications/reducer'

import Dropdown from 'core.ui/Dropdown'
import Snackbar from 'core.ui/Snackbar'

const styles = {
  notificationsListContainer: {
    maxHeight: '700px',
    overflowY: 'auto'
  },
  orderItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  unreadOrderItemContainer: {
    backgroundColor: blue[50]
  },
  notificationTitle: {
    fontWeight: '500'
  },
  orderTime: {
    fontSize: '12px'
  },
  switch: {
    margin: '5px 0 0 15px'
  },
  switchLabel: {
    paddingTop: '3px',
    fontSize: '10px'
  }
}

class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showNotifications: getCookie('notifications') === 'true',
      openSnackBar: false
    }
  }

  componentDidMount() {
    listenToNotifications()
  }

  componentDidUpdate(prevProps) {
    const { unreadNotifications } = this.props
    if (
      this.state.showNotifications &&
      unreadNotifications.length === prevProps.unreadNotifications.length + 1
    ) {
      this.setState({ openSnackBar: true })
    }
  }

  handleItemClick = orderId => () => {
    const { history } = this.props
    if (history.location.pathname !== '/commandes') {
      history.push(`/commandes`)
    } else {
      history.push(`/commandes/${orderId}`)
    }
  }

  makeNotificationItem = (notification, unread) => {
    const { classes } = this.props
    const order = get(notification, 'order')
    return (
      <MenuItem
        className={
          unread
            ? classnames(
                classes.orderItemContainer,
                classes.unreadOrderItemContainer
              )
            : classes.orderItemContainer
        }
        onClick={this.handleItemClick(order?.id)}
      >
        <div className={classes.notificationTitle}>Nouvelle commande</div>
        {order && (
          <React.Fragment>
            <div>{order.restaurantId.nom}</div>
            <div>{parsePrice(order.totalPrice)}</div>
            <div className={classes.orderTime}>
              {makeFriendlyDateString(order.created_at, new Date())}
            </div>
          </React.Fragment>
        )}
      </MenuItem>
    )
  }

  handleToggleNotifications = () => {
    const notifications = getCookie('notifications') === 'true'
    setCookie('notifications', !notifications)
    this.setState({ showNotifications: !this.state.showNotifications })
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  makeNotificationsList = () => {
    const { classes, unreadNotifications, readNotifications } = this.props
    const { showNotifications } = this.state
    const displayList =
      !isEmpty(unreadNotifications) || !isEmpty(readNotifications)
    return (
      <div className={classes.notificationsListContainer}>
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          control={
            <Switch
              className={classes.switch}
              size="small"
              checked={showNotifications}
              onChange={this.handleToggleNotifications}
            />
          }
          label="Activer les notifications"
        />
        {displayList && (
          <MenuList>
            {unreadNotifications.map((n, i) => {
              return (
                <React.Fragment key={`unread-${i}`}>
                  {this.makeNotificationItem(n, true)}
                </React.Fragment>
              )
            })}
            {readNotifications.map((n, i) => {
              return (
                <React.Fragment key={`read-${i}`}>
                  {this.makeNotificationItem(n, false)}
                </React.Fragment>
              )
            })}
          </MenuList>
        )}
      </div>
    )
  }

  render() {
    const { unreadNotifications, markAllRead } = this.props

    const count = unreadNotifications.length
    const notificationsList = this.makeNotificationsList()
    const snackbarMessage = this.makeNotificationItem(unreadNotifications[0])
    return (
      <div className="notifications-main-container">
        <Dropdown
          button={
            <Badge badgeContent={count} color="primary">
              <NotificationsIcon />
            </Badge>
          }
          displayedComponent={notificationsList}
          onClose={markAllRead}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="info"
          message={snackbarMessage}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  readNotifications: notificationsSelectors.getRead(state),
  unreadNotifications: notificationsSelectors.getUnread(state)
})

const mapDispatchToProps = dispatch => ({
  markAllRead: () => {
    dispatch(allNotificationsRead())
  }
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Notifications)
