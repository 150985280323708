import { findIndex } from 'lodash'

import { supplementGroup } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from '../../../core.utils/requestStates'
import { parseErrorMessage } from '../../../core.utils/requestActions'

export const {
  FETCH_RESTAURANT_SUPPLEMENT_GROUPS_REQUESTED,
  FETCH_RESTAURANT_SUPPLEMENT_GROUPS_SUCCEEDED,
  FETCH_RESTAURANT_SUPPLEMENT_GROUPS_FAILED,
  fetchRestaurantSupplementGroupsSucceeded,
  fetchRestaurantSupplementGroupsFailed,
  fetchRestaurantSupplementGroupsStateReducer
} = createRequestActions('FETCH_RESTAURANT_SUPPLEMENT_GROUPS')

export const fetchRestaurantSupplementGroupsRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_SUPPLEMENT_GROUPS_REQUESTED,
    data: {
      restaurantId: id
    }
  })
  supplementGroup
    .getSupplementGroupsByRestaurant(id)
    .then(data => {
      dispatch(fetchRestaurantSupplementGroupsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantSupplementGroupsFailed(error))
    })
}

export const {
  CREATE_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED,
  CREATE_RESTAURANT_SUPPLEMENT_GROUP_SUCCEEDED,
  CREATE_RESTAURANT_SUPPLEMENT_GROUP_FAILED,
  createRestaurantSupplementGroupSucceeded,
  createRestaurantSupplementGroupFailed,
  createRestaurantSupplementGroupStateReducer
} = createRequestActions('CREATE_RESTAURANT_SUPPLEMENT_GROUP')

export const createRestaurantSupplementGroupRequested = (
  restaurantId,
  supplementGroupInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED,
    data: {
      restaurantId,
      supplementGroup: { ...supplementGroupInfos }
    }
  })
  supplementGroup
    .add(restaurantId, supplementGroupInfos)
    .then(data => {
      dispatch(createRestaurantSupplementGroupSucceeded(data))
    })
    .catch(error => {
      dispatch(createRestaurantSupplementGroupFailed(error))
    })
}

export const {
  EDIT_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED,
  EDIT_RESTAURANT_SUPPLEMENT_GROUP_SUCCEEDED,
  EDIT_RESTAURANT_SUPPLEMENT_GROUP_FAILED,
  editRestaurantSupplementGroupSucceeded,
  editRestaurantSupplementGroupFailed
} = createRequestActions('EDIT_RESTAURANT_SUPPLEMENT_GROUP')

export const editRestaurantSupplementGroupRequested = (
  supplementGroupId,
  supplementGroupInfos
) => dispatch => {
  dispatch({
    type: EDIT_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED,
    data: {
      supplementGroupId,
      supplementGroupInfos
    }
  })
  supplementGroup
    .edit(supplementGroupId, supplementGroupInfos)
    .then(data => {
      dispatch(editRestaurantSupplementGroupSucceeded(data))
    })
    .catch(error => {
      dispatch(editRestaurantSupplementGroupFailed(error))
    })
}

export const {
  DELETE_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED,
  DELETE_RESTAURANT_SUPPLEMENT_GROUP_SUCCEEDED,
  DELETE_RESTAURANT_SUPPLEMENT_GROUP_FAILED,
  deleteRestaurantSupplementGroupSucceeded,
  deleteRestaurantSupplementGroupFailed
} = createRequestActions('DELETE_RESTAURANT_SUPPLEMENT_GROUP')

export const deleteRestaurantSupplementGroupRequested = (
  supplementGroupId,
  deleteSupplements,
  restaurantId
) => dispatch => {
  dispatch({
    type: DELETE_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED,
    data: {
      supplementGroupId,
      deleteSupplements,
      restaurantId
    }
  })
  supplementGroup
    .confirmDeleteSupplementGroup(supplementGroupId, deleteSupplements, restaurantId)
    .then(data => {
      dispatch(
        deleteRestaurantSupplementGroupSucceeded({ supplementGroupId })
      )
    })
    .catch(error => {
      dispatch(deleteRestaurantSupplementGroupFailed(error))
    })
}

export const defaultState = { supplementGroups: [], requestState: '' }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_RESTAURANT_SUPPLEMENT_GROUPS_REQUESTED:
    case CREATE_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED:
    case EDIT_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED:
    case DELETE_RESTAURANT_SUPPLEMENT_GROUP_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case CREATE_RESTAURANT_SUPPLEMENT_GROUP_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        supplementGroups: [...state.supplementGroups, data],
        requestState: requestStates.SUCCESS
      }
    }
    case FETCH_RESTAURANT_SUPPLEMENT_GROUPS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        supplementGroups: data,
        requestState: requestStates.SUCCESS
      }
    }
    case EDIT_RESTAURANT_SUPPLEMENT_GROUP_SUCCEEDED: {
      const { supplementGroups } = state
      const supplementGroupInfo = action.payload.data
      const supplementGroupIndex = supplementGroups.findIndex((supplementGroup) => {
        return supplementGroup.id === supplementGroupInfo.id || supplementGroup._id === supplementGroupInfo.id
      })
      return {
        ...state,
        supplementGroups: [
          ...supplementGroups.slice(0, supplementGroupIndex),
          { ...supplementGroups[supplementGroupIndex], ...supplementGroupInfo },
          ...supplementGroups.slice(supplementGroupIndex + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    }
    case DELETE_RESTAURANT_SUPPLEMENT_GROUP_SUCCEEDED:
      const { supplementGroupId } = action.payload.data
      const index = state.supplementGroups.findIndex((supplementGroup) => supplementGroup._id === supplementGroupId.toString())
      return {
        ...state,
        supplementGroups: [
          ...state.supplementGroups.slice(0, index), ...state.supplementGroups.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    case CREATE_RESTAURANT_SUPPLEMENT_GROUP_FAILED:
    case FETCH_RESTAURANT_SUPPLEMENT_GROUPS_FAILED:
    case EDIT_RESTAURANT_SUPPLEMENT_GROUP_FAILED:
    case DELETE_RESTAURANT_SUPPLEMENT_GROUP_FAILED:
      return { 
        ...state, 
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    default:
      return state
  }
}

const getState = state => state.currentRestaurant.supplementGroups
const getSupplementGroups = state => getState(state).supplementGroups
const getRequestState = state => getState(state).requestState
const getErrorMessage = state => getState(state).errorMessage

export const selectors = {
  getState,
  getSupplementGroups,
  getRequestState,
  getErrorMessage
}
