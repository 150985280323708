import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Paper } from '@material-ui/core'

import ProductInformation from 'components/ProductInformation'
import ChooseOption from 'components/ChooseOption'
import ChooseSupplement from 'components/ChooseSupplement'

const styles = {
  paper: {
    padding: '16px',
    marginBottom: '26px'
  },
  divider: { height: '1px', marginBottom: '15px' }
}

class ProductPersonalization extends Component {
  render() {
    const {
      classes,
      onChange,
      imagePath,
      onImageSelect,
      onImageRemove,
      productInformation,
      onDeleteCode,
      optionIds,
      supplementIds,
      productId,
      currentRestaurant,
      onSwitchChange,
      isMenu,
      templates
    } = this.props

    return (
      <div>
        <Typography
          style={{ textAlign: 'center' }}
          variant="h4"
          component="h2"
          gutterBottom
        >
          Personnalisation
        </Typography>

        <Paper className={classes.paper} elevation={3}>
          {/* INFORMATIONS */}
          <ProductInformation
            productId={productId}
            imagePath={imagePath}
            onChange={onChange}
            onImageSelect={onImageSelect}
            onImageRemove={onImageRemove}
            productInformation={productInformation}
            onDeleteCode={onDeleteCode}
            currentRestaurant={currentRestaurant}
            onSwitchChange={onSwitchChange}
            isMenu={isMenu}
            templates={templates}
          />
        </Paper>

        {/* OPTIONS */}
        <Paper className={classes.paper} elevation={3}>
          <Typography
            style={{ textAlign: 'center' }}
            variant="h5"
            component="h2"
            gutterBottom
          >
            Options du produit
          </Typography>
          <ChooseOption
            onChange={onChange('optionIds')}
            selectedOptions={optionIds}
          />
        </Paper>

        {/* SUPPLÉMENTS */}
        <Paper className={classes.paper} elevation={3}>
          <Typography
            style={{ textAlign: 'center' }}
            variant="h5"
            component="h2"
            gutterBottom
          >
            Suppléments du produit
          </Typography>
          <ChooseSupplement
            onChange={onChange('supplementIds')}
            selectedSupplements={supplementIds}
          />
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(ProductPersonalization)
