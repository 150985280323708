import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import TextField from '@material-ui/core/TextField'
import {
  FormControl,
  InputLabel,
  Button,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import Address from 'components/Address'

import Select from 'core.ui/Select'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import { createCityRiderRequested } from 'core.modules/riders/reducer'

const styles = {
  mainContainer: {
    margin: '20px auto',
    width: '562px',
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'column'
  },
  rows: {
    marginBottom: '12px'
  },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative',
    marginLeft: 20
  },
  buttonLoadingAnimation: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  }
}

const vehicleOptions = ['bike', 'scooter', 'car']
const vehicleCaptions = ['Vélo', 'Scooter', 'Voiture']

class RiderForm extends Component {
  constructor(props) {
    super(props)

    const { data } = props      
    this.state = {
      email: data?.email ||'',
      password: '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      phone: data?.phone || '',
      streetAddress: data?.streetAddress || '',
      zipCode: data?.zipCode || '',
      cityAddress: data?.cityAddress || "",
      city: data?.city || '',
      siret: data?.siret || "",
      legalStatus: data?.legalStatus || "",
      transport: data?.transport || '',
      status: data?.status || 'offline',
      isDummy: data?.isDummy || ""
    }
  }

  handleChange = (field) => (e) => {
    this.setState({ [field]: e.target.value })
  }

  handleSubmit = () => {
    const { id, handleSubmit } = this.props
    if(this.isPasswordValid()){
      handleSubmit(this.state, id)
    }
  }

  handleSwitchChange = (field) => (e) => {
    const { checked } = e.target

    switch (field) {
      case "status":
        const status = checked ? 'online' : 'offline'
        this.setState({ status })
      break;
    
      default:
        this.setState({ [field]: checked })
        break;
    }

    
  }

  isPasswordValid = () => {
    const {isPasswordRequired} = this.props
    if(!isPasswordRequired){
      return true
    }
    else{
      return this.props.isPasswordRequired && this.state.password !== ''
    }
  }

  render() {
    const { classes, allCities, showStatus } = this.props
    const {
      firstName,
      lastName,
      phone,
      city,
      email,
      transport,
      cityAddress,
      streetAddress,
      zipCode,
      siret,
      legalStatus,
      status,
      isDummy
    } = this.state
    return (
      <div className={classnames(classes.mainContainer)}>
        <TextField
          className={classes.rows}
          id="email"
          value={email}
          name="email"
          label="Email du livreur"
          variant="outlined"
          onChange={this.handleChange('email')}
        />

        <TextField
          className={classes.rows}
          id="password"
          name="password"
          type="password"
          label="Mot de Passe du livreur"
          variant="outlined"
          onChange={this.handleChange('password')}
          error={!this.isPasswordValid()}
          helperText={!this.isPasswordValid() ? 'Mot de passe requis' : ''}
        />

        <TextField
          className={classes.rows}
          value={firstName}
          id="firstName"
          name="firstName"
          label="Prénom du livreur"
          variant="outlined"
          onChange={this.handleChange('firstName')}
        />

        <TextField
          value={lastName}
          className={classes.rows}
          id="lastName"
          name="lastName"
          label="Nom du livreur"
          variant="outlined"
          onChange={this.handleChange('lastName')}
        />

        <TextField
          value={phone}
          className={classes.rows}
          id="phone"
          name="phone"
          label="Téléphone du livreur"
          variant="outlined"
          onChange={this.handleChange('phone')}
        />

         <TextField
            value={siret}
            className={classes.rows}
            id="siret"
            name="siret"
            label="SIRET"
            variant="outlined"
            onChange={this.handleChange('siret')}
          />

         <TextField
            value={legalStatus}
            className={classes.rows}
            id="legalStatus"
            name="legalStatus"
            label="Status légal"
            variant="outlined"
            onChange={this.handleChange('legalStatus')}
          />

        {/* ADDRESS */}
        {/* <Address
              className={classes.rows}
              value={adress}
              onChange={this.handleChange('adress')}
              // handleValidFieldChange={this.handleValidFieldChange('adress')}
              showErrors={showErrors}
            />
 */}
        <FormControl>
          <TextField
            value={streetAddress}
            className={classes.rows}
            id="streetAddress"
            name="streetAddress"
            label="Rue"
            variant="outlined"
            onChange={this.handleChange('streetAddress')}
          />
        </FormControl>

        <div style={{display:"flex", justifyContent:"space-between" }}>
          <FormControl style={{width:"20%"}} >
            <TextField
              value={zipCode}
              className={classes.rows}
              id="zipCode"
              name="zipCode"
              label="Zip"
              variant="outlined"
              onChange={this.handleChange('zipCode')}
            />
          </FormControl>

          <FormControl style={{width:"75%"}}>
            <TextField
              value={cityAddress}
              className={classes.rows}
              id="cityAddress"
              name="cityAddress"
              label="Ville (adresse du livreur)"
              variant="outlined"
              onChange={this.handleChange('cityAddress')}
            />
          </FormControl>
          
        </div>

        <FormControl>
          <InputLabel className={classes.selectLabel} shrink>
            Ville
          </InputLabel>
          <Select
            id="city"
            name="city"
            className={classnames(classes.rows, classes.select)}
            native
            value={city}
            options={allCities.map((c) => c._id)}
            captions={allCities.map((c) => c.name)}
            onChange={this.handleChange('city')}
          />
        </FormControl>

        {/* VÉHICULE */}
        <FormControl>
          <InputLabel className={classes.selectLabel} shrink>
            Type de véhicule
          </InputLabel>
          <Select
            className={classnames(classes.rows, classes.select)}
            native
            value={transport}
            options={vehicleOptions}
            captions={vehicleCaptions}
            onChange={this.handleChange('transport')}
          />
        </FormControl>
        

        {showStatus && (
          <FormControlLabel
            control={
              <Switch
                checked={status === 'online'}
                onChange={this.handleSwitchChange('status')}
              />
            }
            label="En ligne"
          />
        )}
         {showStatus ? (
          <FormControlLabel
            control={
              <Switch
                checked={isDummy}
                onChange={this.handleSwitchChange('isDummy')}
              />
            }
            label="Livreur Factice"
          />
        ): <FormControlLabel
          control={
            <Switch
              checked={isDummy}
              onChange={this.handleSwitchChange('isDummy')}
            />
          }
          label="Livreur Factice"
        />}

        <div className={classes.formButtonsContainer}>
          <Button
            onClick={this.props.onCancel}
            variant="contained"
            color="default"
          >
            Annuler
          </Button>
          <div className={classes.loadingButtonWrapper}>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  allCities: citiesSelectors.getAllCities(state)
})

const mapDispatchToProps = (dispatch) => ({
  createRider: (riderInfos) => {
    dispatch(createCityRiderRequested(riderInfos))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(RiderForm)
