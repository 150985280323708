import React from 'react'
import { get } from 'lodash'
import { Switch } from '@material-ui/core'

// from API
export function parseRestaurant(resto, allCategories, onCheckIsDelivery, onCheckIsPickup, onDisableCheck) {
  const restaurantAppVersion = !resto
  ? 'Restaurant Supprimé' 
  : resto?.appVersion
    ? resto?.appVersion
    : '< 1.3.0'

  // Nom, Catégories, adresse, horaires, notes, comission, switch livraison, switch emporter, app version, Version menus
  return [
    resto.nom,
    parseCategorie(resto.categories, allCategories),
    parseAddress(resto.adress),
    resto.horaires?.map(h => parseSingleHoraire(h)),
    resto.note,
    parseComission(resto.comission),
    parseToSwitch(resto.isDelivery != null ? resto.isDelivery : true, onCheckIsDelivery),
    parseToSwitch(resto.isPickup != null ? resto.isPickup : true, onCheckIsPickup),
    parseToSwitch(resto.isDisabled ? resto.isDisabled : false, onDisableCheck),
    restaurantAppVersion,
    resto.usesV2Schema ? "V2" : "V1"
  ]
}

export function parseCategorie(category = [], allCategories = []) {
  if (category.length === 0) {
    return ''
  }
  return category
    .map(cat => {
      const foundCategory = allCategories.find(
        candidateCat => cat.category === candidateCat.id
      )
      return get(foundCategory, 'name', '')
    })
    .join(', ')
}

export function parseAddress(address = {}) {
  const { streetAdress, zipCode, city } = address
  const isEmpty = !(streetAdress || zipCode || city)
  return isEmpty ? '' : `${streetAdress}, ${zipCode} ${city}`
}

export function parseSingleHoraire(horaire = {}) {
  const { jour, creneaux } = horaire
  const creneauxString = creneaux.map(c => `${c.hDebut} - ${c.hFin}`).join(', ')
  return `${jour} ${creneauxString}`
}

export function parseComission(number) {
  return number ? `${number} %` : ''
}

export function parseToSwitch(value, fct) {
  return (
    <Switch
      checked={value}
      onChange={() => fct(!value)}
    />
  )
}