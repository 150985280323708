import { createSlice } from '@reduxjs/toolkit'

import { order as OrderApi, rider as RiderApi } from 'core.services/api'
import { onPaginationClaimChange } from '../../../core.modules/Claims/Actions'

const initialState = {
  isLoading: false,
  selectedCity: null,
  filters: {
    orderStatus: '',
    orderInterval: "",
    search: { type: null, value: null },
    allCities: false,
    startDate: null,
    endDate: null
  },
  rushMode: {
    active: false,
    statusCounts: [],
    intervalCounts: [],
    selectedStatus: null,
    selectedInterval: null
  },
  riders: [],
  allRiders: [],
  ordersInfo: {
    orders: [],
    paginationInfo: {
      count: 0,
      hasNext: false,
      page: 1,
      perPage: 20
    }
  },
  priorityOrders: [],
  snackbarMsg: {
    severity: null,
    message: null
  },
}

const slice = createSlice({
  name: 'uiOrderList',
  initialState,
  reducers: {
    onToggleLoader: (state, action) => {
      state.isLoading = action.payload
    },
    onSelectCity: (state, action) => {
      state.selectedCity = action.payload
    },
    onSetFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    onPaginationChange: (state, action) => {
      state.ordersInfo = {
        ...state.ordersInfo,
        paginationInfo: {
          ...state,
          page: action.payload.page || state.ordersInfo.paginationInfo.page,
          perPage: action.payload.perPage || state.ordersInfo.paginationInfo.perPage,
        }
      }
    },
    onSetAllRiders: (state, action) => {
      state.allRiders = action.payload
    },
    onSetRiders: (state, action) => {
      state.riders = action.payload
    },
    onSetOrders: (state, action) => {
      state.ordersInfo = action.payload
    },
    onToggleRushMode: (state, action) => {
      state.rushMode = { ...initialState.rushMode, active: action.payload }
    },
    onSetRushData: (state, action) => {
      state.rushMode = { ...state.rushMode, ...action.payload }
    },
    onSetPriorityOrders: (state, action) => {
      state.priorityOrders = action.payload
    },
    onSetSnackbar: (state, action) => {
      state.snackbarMsg = {
        ...initialState.snackbarMsg,
        ...action.payload
      }
    }
  }
})
export default slice.reducer

export const {
  onToggleLoader,
  onSelectCity,
  onSetFilters,
  onPaginationChange,
  onSetRiders,
  onSetOrders,
  onToggleRushMode,
  onSetRushData,
  onSetPriorityOrders,
  onSetSnackbar,
  onSetAllRiders
} = slice.actions

export const getOrders = (withLoading = true, resetPagination = false) => async (dispatch, getState) => {
  const { selectedCity, ordersInfo, filters } = getState()[slice.name]
  
  try {    
    if (withLoading){
      dispatch(onToggleLoader(true))
    }

    if (!filters.allCities && !selectedCity) throw 'City unselected'

    let orders = []

    const searchValue = filters.search?.type === 'restaurant' ? filters.search?.restaurantId : filters.search?.value
    // Do a search
    if (filters.search?.type && searchValue) {

      let searchBody = {}

      if (filters.search?.type && searchValue) {
        searchBody = {
          cityId: filters.allCities ? null : selectedCity,
          type: filters.search?.type,
          value: searchValue,
          restaurantId: filters.search?.restaurantId
        }
      }

      const params = {
        status: filters.orderStatus,
        page: resetPagination ? 1 : ordersInfo.paginationInfo.page,
        limit: resetPagination ? 20 : ordersInfo.paginationInfo.perPage,
        interval: filters.orderInterval,
        startDate: filters.byDate ? filters.startDate : undefined,
        endDate: filters.byDate ? filters.endDate : undefined
      }

      orders = await OrderApi.search(searchBody, params)
    }
    // Get orders
    else {
      orders = await OrderApi.getAll({
        page: resetPagination ? 1 : ordersInfo.paginationInfo.page,
        limit: resetPagination ? 20 : ordersInfo.paginationInfo.perPage,
        status: filters.orderStatus,
        interval: filters.orderInterval,
        city: filters.allCities ? null : selectedCity,
        startDate: filters.byDate ? filters.startDate : undefined,
        endDate: filters.byDate ? filters.endDate : undefined,
      })
    }

    dispatch(onSetOrders(orders))
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    dispatch(onToggleLoader(false))
  }
}

export const updatePagination = (payload) => async (dispatch) =>  {
  dispatch(onPaginationChange(payload))
  dispatch(getOrders(true))
}

const getCityRiders = (city, includeInactive) => async (dispatch) => {
  try {
    const riders = await RiderApi.getCityRiders(city, includeInactive)
    await dispatch(onSetRiders(riders))
  } catch (e) {
    console.log('Error on get riders', e)
  }
}

export const getAllRiders = () => async (dispatch) => {
  try {
    const allRiders = await RiderApi.getAll()
    await dispatch(onSetAllRiders(allRiders))
  } catch (e) {
    console.log('Error on get riders', e)
  }
}

export const selectCity = (city) => async (
  dispatch,
  getState
) => {
  const { rushMode } = getState()[slice.name]

  await dispatch(onSelectCity(city))

  await dispatch(getCityRiders(city, true))

  await dispatch(getOrders(true, true))

  // refresh rush data
  if (rushMode.active) {
    await dispatch(refreshRushData())
  }
}

export const loadClaimPage = (page,filter) => async (dispatch)=>{
  await dispatch(onPaginationClaimChange(page))
}

// -------------------------
// Actions on data managment
// -------------------------

export const refreshRushData = () => async (dispatch, getState) => {
  const { selectedCity, filters } = getState()[slice.name]

  try {
    dispatch(onToggleLoader(true))
    if (!selectedCity) new Error('City unselected')

    const param = filters.allCities ? null : selectedCity

    const [overview, priorityOrders] = await Promise.all([
      OrderApi.getOrdersOverview(param),
      OrderApi.getPriorityOrders(param)
    ])

    await dispatch(onSetRushData(overview))
    await dispatch(onSetPriorityOrders(priorityOrders))
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Refresh échoué', severity: 'error' }))
  } finally {
    dispatch(onToggleLoader(false))
  }
}

export const toggleRushMode = (value, currentOrdersOnly) => async (dispatch, getState) => {
  const { selectedCity } = getState()[slice.name]

  try {
    if (!selectedCity) throw new Error('City unselected')

    await dispatch(onToggleRushMode(value))

    // enable rushMode
    if (value === true) {
      // set default orderStatus for rushmode
      await dispatch(setFilters({ orderStatus: 'current', orderInterval:"", byDate: false }))
      await dispatch(refreshRushData())
    } else {
      // reset default orderStatus
      await dispatch(
        setFilters({ orderStatus: currentOrdersOnly ? 'current' : initialState.filters.orderStatus, orderInterval: "" })
      )
    }
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Action échoué', severity: 'error' }))
  }
}

export const forceRefreshAll = () => async (dispatch, getState) => {
  const { rushMode } = getState()[slice.name]

  try {
    await dispatch(getOrders())
    if (rushMode.active) await dispatch(refreshRushData())
    // await dispatch(getAllRiders())
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Refresh échoué', severity: 'error' }))
  }
}

// ------------------------
//    Actions on filters
// ------------------------

export const setFilters = (filters) => async (dispatch, getState) => {
  const { rushMode, allRiders, selectedCity } = getState()[slice.name]

  await dispatch(onSetFilters(filters))
  await dispatch(getOrders(true, true))

  // when allcities is toggled
  if (filters?.allCities !== undefined) {
    // Replace displayed riders
    if (filters?.allCities === true) {
      await dispatch(onSetRiders(allRiders))
    }
    // refresh riders of selected city
    else {
      await dispatch(getCityRiders(selectedCity))
    }

    // refresh rush data
    if (rushMode.active) await dispatch(refreshRushData())
  }
}

export const selectRushStatus = (status) => async (dispatch) => {
  await dispatch(onSetRushData({ selectedStatus: status, selectedInterval: "" }))

  if (status && status !== 'priorityOrders') {
    await dispatch(setFilters({ orderStatus: status, orderInterval: "" }))
  }
}

export const selectRushInterval = (interval) => async (dispatch) => {
  await dispatch(onSetRushData({ selectedInterval: interval, selectedStatus: "" }))
  await dispatch(setFilters({ orderInterval: interval, orderStatus: "current" })) 
}

// ------------------------
// Actions on an order item
// ------------------------

export const setOrderStatus = (orderId, status) => async (
  dispatch,
  getState
) => {
  const { ordersInfo } = getState()[slice.name]

  try {
    await OrderApi.changeOrderStatus(orderId, status)

    await dispatch(
      onSetOrders(
        {
          ...ordersInfo,
          orders: ordersInfo.orders.map((orderElem) => {
            if (orderElem._id === orderId) return { ...orderElem, status }
            return orderElem
          })
        }
        
      )
    )

    dispatch(
      onSetSnackbar({ message: 'Traitement effectué', severity: 'success' })
    )
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Traitement échoué', severity: 'error' }))
  } finally {
    dispatch(onToggleLoader(false))
  }
}

export const setOrderRider = (orderId, riderId) => async (dispatch) => {
  try {    
    await OrderApi.assignRiderToOrder(orderId, riderId)
    await dispatch(getOrders(false))

    dispatch(onSetSnackbar({ message: 'Action effectué', severity: 'success' }))
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Action échouée', severity: 'error' }))
  } finally {
    dispatch(onToggleLoader(false))
  }
}

export const setOrderNote = (orderId, note) => async (dispatch, getState) => {
  const { ordersInfo } = getState()[slice.name]

  try {
    dispatch(onToggleLoader(true))
    const result = await OrderApi.editNote(orderId, note)

    await dispatch(
      
      onSetOrders(
        {
          ...ordersInfo,
          orders: ordersInfo.orders.map((orderElem) => {
            if (orderElem._id === orderId) return { ...orderElem, noteForAdmin: note }
            return orderElem
          })
        }
      )
    )

    dispatch(
      onSetSnackbar({ message: 'Traitement effectué', severity: 'success' })
    )
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Traitement échoué', severity: 'error' }))
  } finally {
    dispatch(onToggleLoader(false))
  }
}

export const deleteOrder = (orderId) => async (dispatch) => {
  try {
    // dispatch(onToggleLoader(true))
    await OrderApi.delete(orderId)
    await dispatch(getOrders())
    dispatch(
      onSetSnackbar({ message: 'Traitement effectué', severity: 'success' })
    )
  } catch (e) {
    console.error(e.message)
    dispatch(onSetSnackbar({ message: 'Traitement échoué', severity: 'error' }))
  } finally {
    dispatch(onToggleLoader(false))
  }
}
