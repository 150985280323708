import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import List from 'core.ui/List';
import { fetchRestaurantsState } from '../../core.modules/restaurants/reducer';

const styles = {
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	filterLabel: {
		marginLeft: '7px'
	},
	addButton: {
		textDecoration: 'none !important',
		marginLeft: '10px',
		fontSize: '10px'
	}
};

class RestaurantsStatus extends React.Component {
	state = {
		restaurants: []
	};

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		try {
			const res = await fetchRestaurantsState();
			this.setState({ restaurants: res });
		} catch (e) {
			console.log('!! Error in fetchData !!', e);
		}
	};

	parseRestaurant = (restaurant) => {
		return [
			restaurant.nom,
			restaurant.city.name,
			restaurant.inactiveDuration === 0
				? ''
				: restaurant.inactiveDuration > 60 ? 'Demain' : 
					restaurant.inactiveDuration != null ? restaurant.inactiveDuration + ' min' : '',
			restaurant.statusUpdatedAt
				? `${new Date(restaurant.statusUpdatedAt).toLocaleDateString()} ${new Date(
						restaurant.statusUpdatedAt
					).toLocaleTimeString()}`
				: ''
		];
	};

	render() {
		const { classes } = this.props;
		const { restaurants } = this.state;

		return (
			<Router>
				<Switch>
					<React.Fragment>
						{restaurants.map((restaurantsList) => (
							<React.Fragment>
								<div
									style={{
										width: '100%',
										backgroundColor: 'lightgrey',
										paddingTop: 5,
										paddingBottom: 5
									}}
								>
									<p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
										{restaurantsList.durationFormatted}
									</p>
								</div>
								<List
									className={classes.list}
									columns={[ 'Restaurant', 'Ville', 'Inactif pendant', 'Mise à jour' ]}
									list={restaurantsList.restaurants.map(this.parseRestaurant)}
									ids={restaurantsList.restaurants.map((o) => o.id || o._id)}
									emptyMessage="Aucune ville pour les demandes"
									ids={restaurantsList.restaurants.map((r) => r._id)}									
								/>
							</React.Fragment>
						))}
					</React.Fragment>
				</Switch>
			</Router>
		);
	}
}

export default withStyles(styles)(RestaurantsStatus);
