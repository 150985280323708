import React from 'react'

const ParseSettings = ({ days }) => {
  let selectedDays = []
  const weekdays = [
    { id: 'monday', name: 'lundi' },
    { id: 'tuesday', name: 'mardi' },
    { id: 'wednesday', name: 'mercredi' },
    { id: 'thursday', name: 'jeudi' },
    { id: 'friday', name: 'vendredi' },
    { id: 'saturday', name: 'samedi' },
    { id: 'sunday', name: 'dimanche' }
  ]

  days.map((day) =>
    selectedDays.push(weekdays.find((weekday) => weekday.id === day))
  )

  return selectedDays.map((day) => <li>{day.name}</li>)
}

export default ParseSettings
