import React, {useEffect, useState} from 'react';
import {
	TextField,
} from '@material-ui/core';

const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const daysToFr = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

const initState = (week, isEmpty) => {  
  return days.map(day => ({
      day,
      morningSession: {
        hours: [{
          start: isEmpty || !week ? null : week[day]?.morningSession?.hours[0]?.start,
          end: isEmpty || !week ? null :  week[day]?.morningSession?.hours[0]?.end
        }],
        isCustomHours: isEmpty || !week ? null :  week[day]?.morningSession?.isCustomHours,
        status: isEmpty || !week ? null :  week[day]?.morningSession?.status,
      },
      nightSession: {
        hours: [{
          start: isEmpty || !week ? null :  week[day]?.nightSession?.hours[0]?.start,
          end: isEmpty || !week ? null :  week[day]?.nightSession?.hours[0]?.end
        }],
        isCustomHours: isEmpty || !week ? null : week[day]?.nightSession?.isCustomHours,
        status:  isEmpty || !week ? null : week[day]?.nightSession?.status, 
      },
    }))
}

export default function DialogPlanningForm({ updateNewPlanning, isVisible, currentPlanning, isEmpty }) {
  const [ planning, setPlanning ] = useState(initState(currentPlanning, isEmpty));

  useEffect(() => {
    setPlanning(initState(currentPlanning, isEmpty))
  }, [ isVisible, currentPlanning ])
  
  const handleChange = (index, session, when) => e => {
    e.persist()
    setPlanning(currPlanning => {
      const copy = [...currPlanning]
      const curr = copy[index]      
      
      if (e.target?.value?.length < 6) {
        curr[session].hours[0][when] = e.target?.value?.length === 2 && curr[session].hours[0][when].length === 1 ? `${e.target?.value}:` : e.target?.value
        curr[session].isCustomHours = true
        curr[session].status = "accepted"
      } else if (e.target?.value?.length === 0) {
        curr[session].hours[0][when] = null
      }
      updateNewPlanning(copy)
      return copy
    })
  }
  
	return (
		<div style={{ width: '100%', padding:10 }}>
      {planning.map((pl, index) => (
        <div style={{width:"75%", margin:"auto"}}>
          <h3>{daysToFr[index]}</h3>
          <div style={{ display:"flex", justifyContent:"center" }} >
              <div>
                <p>Matin</p>
                <TextField
                    type="text"
                    style={{ marginBottom: 15, width: '25%' }}
                    value={pl["morningSession"].hours[0]["start"]}
                    onChange={handleChange(index, "morningSession", "start")}
                    label="Début"
                />			
                <TextField
                    type="text"
                    style={{ marginLeft: 15, width: '25%' }}
                    value={pl["morningSession"].hours[0]["end"]}
                    onChange={handleChange(index, "morningSession", "end")}
                    label="Fin"
                />
              </div>			

              <div >              
                  <p>Soir</p>
                  <TextField
                      type="text"
                      style={{ marginBottom: 15, width: '25%' }}
                      value={pl["nightSession"].hours[0]["start"]}
                      onChange={handleChange(index, "nightSession", "start")}
                      label="Début"
                  />			
                  <TextField
                      type="text"
                      style={{ marginLeft: 15, width: '25%' }}
                      value={pl["nightSession"].hours[0]["end"]}
                      onChange={handleChange(index, "nightSession", "end")}
                      label="Fin"
                  />	
              </div>		
          </div>
        </div>
      ))}
		</div>
	);
}
