import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import classnames from 'classnames';
import { isEmpty, isArray, uniqueId } from 'lodash';

import ButtonWithIcon from 'core.ui/ButtonWithIcon';
import Select from 'core.ui/Select';
import { orderStatus } from '../../core.utils/parser/orderStatus';

// import './List.css'
export const useStyles = makeStyles({
	mainContainer: {
		margin: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	pageButtons: {
		margin: 20
	},
	listMainContainer: { width: '1200px' },
	listTable: {},
	listTableHeader: {},
	listTableColumnsHeaders: {
		textAlign: 'center'
	},
	listTableActionColumnHeader: {
		width: '50px'
	},
	listTableBody: {},
	listEmptyListMessage: {
		padding: '10px',
		textAlign: 'center'
	},
	listTableRow: {},
	listTableCell: {
		textAlign: 'center'
	},
	listInCell: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	actionButton: {
		margin: '0 3px'
	}
});

function List({
	listMainContainerProp,
	className,
	list = [],
	columns = [],
	ids = [],
	emptyMessage,
	actions,
	customPage,
	numberPerPage = 10,
	filters = {},
	onPageChange,
	withPagination = true
}) {
	const [ page, setPage ] = useState(1);
	const classes = useStyles();
	const columnsHeaders = columns.map((c, i) => (
		<TableCell
			key={i}
			component="th"
			className={classnames('list-table-columns-headers', classes.listTableColumnsHeaders)}
		>
			{c}
		</TableCell>
	));
	const columnToFilter = Object.keys(filters)[0];
	const rowsWithId = list.map((data, index) => ({ id: ids[index], data }));
	const filteredRows =
		!isEmpty(filters) && !isEmpty(filters[columnToFilter])
			? rowsWithId.filter((el) => {
					if (isArray(filters[columnToFilter])) {
						return filters[columnToFilter].reduce((acc, f) => {
							if (el.data[columnToFilter].type === Select) {
								return acc || el.data[columnToFilter].props.value === f;
							} else {
								return acc || el.data[columnToFilter] === f;
							}
						}, false);
					} else {
						if (el.data[columnToFilter].type === Select) {
							return el.data[columnToFilter].props.value === filters[columnToFilter];
						} else {
							return el.data[columnToFilter] === filters[columnToFilter];
						}
					}
				})
			: rowsWithId;
	const currentPageRow =
		(!onPageChange && numberPerPage > 0 && withPagination)
			? filteredRows
					.slice((page - 1) * numberPerPage, page * numberPerPage)
					.map((row, rowIndex) => makeRow(row.data, rowIndex, classes, actions, row.id))
			: filteredRows.map((row, rowIndex) => makeRow(row.data, rowIndex, classes, actions, row.id));
	const handleLeftArrowClick = () => {
		if ((customPage || page) === 1) {
			return;
		}
		const newPage = (customPage || page) - 1;
		if (onPageChange) {
			onPageChange(newPage, numberPerPage);
		}
		setPage(newPage);
	};
	const handleRightArrowClick = () => {
		const newPage = (customPage || page) + 1;
		if (onPageChange) {
			onPageChange(newPage, numberPerPage);
		}
		setPage(newPage);
	};
	return (
		<div className={classes.mainContainer}>
			{withPagination && (
				<div className={classes.pageButtons}>
					<ButtonWithIcon
						className="list-left-arrow"
						onClick={handleLeftArrowClick}
						featherIcon="ArrowLeft"
					/>
					Page : {customPage || page}
					<ButtonWithIcon
						className="list-right-arrow"
						onClick={handleRightArrowClick}
						featherIcon="ArrowRight"
					/>
				</div>
			)}
			<TableContainer
				className={classnames('list-main-container', classes.listMainContainer, className)}
				style={!listMainContainerProp ? {} : listMainContainerProp}
			>
				<Table className={classnames('list-table', classes.listTable)}>
					<TableHead className={classnames('list-table-header', classes.listTableHeader)}>
						<TableRow>
							{columnsHeaders}
							{actions && (
								<TableCell
									component="th"
									className={classnames(
										'list-table-columns-headers',
										classes.listTableColumnsHeaders,
										classes.listTableActionColumnHeader
									)}
								/>
							)}
						</TableRow>
					</TableHead>
					{list.length > 0 && (
						<TableBody className={classnames('list-table-body', classes.listTableBody)}>
							{currentPageRow}
						</TableBody>
					)}
				</Table>
				{list.length === 0 && (
					<div className={classnames('list-empty-list-message', classes.listEmptyListMessage)}>
						{emptyMessage}
					</div>
				)}
			</TableContainer>
			{withPagination && (
				<div className={classes.pageButtons}>
					<ButtonWithIcon
						className="list-left-arrow"
						onClick={handleLeftArrowClick}
						featherIcon="ArrowLeft"
					/>
					Page : {customPage || page}
					<ButtonWithIcon
						className="list-right-arrow"
						onClick={handleRightArrowClick}
						featherIcon="ArrowRight"
					/>
				</div>
			)}
		</div>
	);
}

export function makeRow(row, rowIndex, classes, actions, id) {
	const cells = row.map((cellInput, i) => makeCell(cellInput, i, classes));
	return (
		<TableRow key={rowIndex} className={classnames('list-table-row', classes.listTableRow)}>
			{cells}
			{actions && makeActionsCell(id, actions, classes)}
		</TableRow>
	);
}

export function makeCell(input, inputKey, classes = {}) {
	if (Array.isArray(input)) {
		return (
			<TableCell key={inputKey}>
				<div className={classes.listInCell}>
					<ul>{input.map((el, i) => <li key={i}>{el}</li>)}</ul>
				</div>
			</TableCell>
		);
	}
	return (
		<TableCell className={classes.listTableCell} key={inputKey}>
			{input}
		</TableCell>
	);
}

export function makeActionsCell(id, actions, classes = {}) {
	const { handleEdit, handleDelete, handleDuplicate, showLogs, handleView, handleGetlog } = actions;
	return (
		<TableCell>
			<div className={classes.listInCell}>
				{handleView && (
					<ButtonWithIcon
					className={classes.actionButton}
					onClick={() => handleView(id)}
					featherIcon="BookOpen"
				/>
				)}
                {handleGetlog && (
					<ButtonWithIcon
						className={classes.actionButton}
						onClick={() => handleGetlog(id)}
						featherIcon="FileText"
					/>
				)}
				{handleEdit && (
					<ButtonWithIcon
						className={classes.actionButton}
						onClick={() => handleEdit(id)}
						featherIcon="Edit"
					/>
				)}
				{handleDuplicate && (
					<ButtonWithIcon
						className={classes.actionButton}
						onClick={() => handleDuplicate(id)}
						featherIcon="Copy"
					/>
				)}
				{handleDelete && (
					<ButtonWithIcon className={classes.actionButton} onClick={() => handleDelete(id)} featherIcon="X" />
				)}
				{showLogs && (
					<ButtonWithIcon
						className={classes.actionButton}
						onClick={() => showLogs(id)}
						featherIcon="FileText"
					/>
				)}
			</div>
		</TableCell>
	);
}

export default List;
