import React, { useState } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Link,
	Box,
	Typography,
	Container
} from '@material-ui/core';

import './Login.css';

import { loginService } from 'core.services/login';
import { useAuth } from '../../contexts/AuthContext';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="http://www.lyveat.com">
				Lyveat
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

function Alert(props) {
	return <MuiAlert className="errorMessage" elevation={6} variant="filled" {...props} />;
}

export default function Login({}) {
  const { setUser, setUserToken, setUserId } = useAuth()

	const [ email, setemail ] = useState('');
	const [ password, setpassword ] = useState('');
	const [ loginError, setloginError ] = useState(false);
	const [ errorMessage, seterrorMessage ] = useState('');

	const isEmailNOTValid = () => {
		return email.length > 0 && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
	};

	const isPasswordNOTValid = () => {
		return password.length > 0 && password.length < 7;
	};

	const onPressLogin = async (e) => {
		e.preventDefault();
		if (isEmailNOTValid() || isPasswordNOTValid()) {
			setloginError(true);
			seterrorMessage('Mauvaise Adresse Mail / Mot de passe');
			return;
		} else {
			loginService(email, password)
				.then((res) => {
					setUserToken(res.data.token);
					setUser(res.data.admin);
					setUserId(res.data.admin.id)
				})
				.catch((error) => {
					setloginError(true);
					seterrorMessage("Une erreur s'est survenue. Veuillez contacter votre support.");
				});
		}
	};

	return (
		<Container component="main" maxWidth="xs" className="container">
			<CssBaseline />
			<div className="paper">
				<Avatar className="avatar">
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Connexion
				</Typography>
				<form className="form" noValidate>
					{loginError && <Alert severity="error">{errorMessage}</Alert>}
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Adresse Mail"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={(e) => setemail(e.target.value)}
						error={isEmailNOTValid() ? true : false}
						helperText={isEmailNOTValid() ? 'Adresse mail invalide' : ''}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Mot de Passe"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={(e) => setpassword(e.target.value)}
						error={isPasswordNOTValid() ? true : false}
						helperText={isPasswordNOTValid() ? 'Au minimum 7 charactères' : ''}
					/>
					<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className="submit"
						onClick={onPressLogin}
					>
						Connexion
					</Button>
				</form>
			</div>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
}
