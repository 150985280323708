import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,Chip,Button,Paper
} from '@material-ui/core'
import { parseImage } from 'core.utils/parser/image'
import { product } from 'core.services/api'
import { selectors as currentRestaurantInfosSelectors } from 'core.modules/currentRestaurant/infos/reducer'
import { selectors as categoriesSelectors } from 'core.modules/categories/reducer'
import UploadButton from 'components/UploadButton'
import TextField from 'core.ui/TextField'
import Select from 'core.ui/Select'

const styles = {
  mainContainer: {
    maxWidth: '500px'
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewImage: { width: '200px' },
  titles: {
    marginBottom: '4px'
  },
  rows: {
    marginBottom: '10px'
  },
  selects: {
    width: '100%'
  }
}

const stylesChips = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '5px',
    margin: 0,
  },
  chip: {
    margin: '5px',
  },
};
class ProductInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imagePreview: null,
      selected:""
    }
  }

  componentDidMount() {
    this.fetchImage()
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.productId !== prevProps.productId) {
      this.fetchImage()
    }
  }

  fetchImage = () => {
    const { imagePath } = this.props
    if (imagePath) {
      product
        .getImage(imagePath)
        .then(image => {
          return parseImage(image)
        })
        .then(this.updateImagePreview)
        .catch(e => {
          console.error(e)
        })
    }
  }

  updateImagePreview = imagePreview => {
    this.setState({
      imagePreview
    })
  }
  
  ajouterParent = () => {
    const { productInformation }= this.props
    
    if (this.state.selected !== "" && productInformation.children?.filter(e => e.checkoutSystemId === this.state.selected).length <= 0) {
      productInformation.children = this.props.productInformation.children.concat({ checkoutSystemId: this.state.selected?.trim() })
      this.setState({ selected: "" })
    }
  }
 
  validateOrder = (orderNumber) =>{
    const reg = /^\d+$/
    if((String(orderNumber)?.match(reg)&&(orderNumber>0))||(orderNumber===undefined)){
      return true
    }
    
    return false
  } 
  handleImageSelect = e => {
    const { files } = e.target
    parseImage(files[0]).then(this.updateImagePreview)
    this.props.onImageSelect(e)
  }
  deletePhoto  = () => {
    this.setState({ imagePreview: null })
    this.props.onImageRemove()
  }
  render() {
   
    const { imagePreview } = this.state
    const {
      classes,
      productInformation,
      onChange,
      onDeleteCode,
      categories,
      customCategories,
      currentRestaurant,
      onSwitchChange,
      isMenu,
      templates
    } = this.props

    const { templateid, templates: currentTemplates } = templates
    const template = currentTemplates.find(template => template._id === templateid)
   
    return (
      <div className={classes.mainContainer}>
        <div className={classnames(classes.rows, classes.imageWrapper)}>
          {imagePreview && (
            <img
              className={classnames(classes.rows, classes.previewImage)}
              src={imagePreview}
              alt="Produit"
            />
          )}
          <div className={classes.buttonBar}>
          <UploadButton
            className={classes.rows}
            title="Photo"
            onFileSelect={this.handleImageSelect}
          />
            {imagePreview && (
          <Button
          variant="contained"
          title="delete"
          color="secondary"
          onClick= {this.deletePhoto}
         >
           supprimer la photo 
           </Button>
              )}
         </div>
        </div>
        <TextField
          className={classes.rows}
          label="Nom du produit"
          value={productInformation.name}
          onChangeComplete={onChange('productInformation.name')}
          fullWidth
        />
        <TextField
          label="Description"
          value={productInformation.description}
          onChangeComplete={onChange('productInformation.description')}
          multiline
          fullWidth
          rows={3}
        />
        <TextField
          label="Prix TTC"
          value={productInformation.price}
          onChangeComplete={onChange('productInformation.price')}
          type="number"
        />

        <div>
          <div className={classes.titles}>Catégorie du produit</div>
          <Select
            className={classnames(classes.rows, classes.selects)}
            options={categories}
            captions={categories}
            value={productInformation.category}
            onChange={onChange('productInformation.category')}
          />
        </div>
        <div>
          <div className={classes.titles}>
            Catégorie personnalisée du produit
          </div>
          <Select
            className={classnames(classes.rows, classes.selects)}
            options={!templateid ? customCategories : template ? template.headers : []}
            captions={!templateid ? customCategories : template ? template.headers : []}
            value={productInformation.header}
            onChange={onChange('productInformation.header')}
          />
        </div>
        <div>
          <div className={classes.titles}>
            numéro d'ordre
          </div>
          <TextField
          error={!this.validateOrder(productInformation.order)}
          className={classes.rows}
          label="Numéro"
          value={productInformation.order}
          onChangeComplete={onChange('productInformation.order')}
          fullWidth
          helperText={!(this.validateOrder(productInformation.order))?'donner un nombre > 0':''}
        />
        </div>
        {(currentRestaurant?.posInfo?.type || templates.checkTemplate) ?
       <div> 
         <TextField
          label="Id système de caisse"
          value={productInformation.checkoutSystemId}
          onChangeComplete={onChange('productInformation.checkoutSystemId')}
          type="text"
          // error={(productInformation.checkoutSystemId?.length!=6 && productInformation.checkoutSystemId?.length!=0)}
          // helperText={(productInformation.checkoutSystemId?.length!=6 && productInformation.checkoutSystemId?.length!=0)?"le code doit contenir 6 caractères": ""}
        />

        <FormControl style={(isMenu !==true) ?{display:'none'}:{} }>
            <TextField
              className={classes.nameTextField}
              label="Enfants"
              type="text"
              value={this.state.selected}
              // helperText={(this.state.selected.length!=6 && this.state.selected.length!=0)?"le code doit contenir 6 caractères": ""}
              // error={(this.state.selected.length!=6 && this.state.selected.length!=0)}
              onChangeComplete={e => this.setState({ selected: e.target.value.trim() })}
            />
          </FormControl>
          <TextField
          label="Reference"
          value={productInformation.reference}
          onChangeComplete={onChange('productInformation.reference')}
          type="text"
          
        />

          <Paper component="ul" style={(isMenu !==true) ?{display:'none'}:{} } className={stylesChips.root}>
      {this.props.productInformation.children?.map((data) => {
      
        return (
          <li>
            <Chip style={{marginLeft:'10px',marginRight:'10px'}}
              label={data.checkoutSystemId}
              onDelete={onDeleteCode(data.checkoutSystemId)}
            />
          </li>
        );
      })}
    </Paper>
    <Button
            className="create-option-submit-button"
            variant="contained"
            color="primary"
            onClick={this.ajouterParent}
            style={(isMenu!==true) ?{display:'none'}:{} }
          >
           Ajouter code
          </Button>
          <FormControl style={{marginLeft:'10px'}}>
            <FormLabel component="legend">Est un Menu </FormLabel>
            <FormControlLabel
              control={
                <Switch
                checked={isMenu}
                  value={isMenu}
                  onChange={onSwitchChange('isMenu')}
                />
              }
             
            />
          </FormControl>
       </div>
        : <div></div> }
      </div>
      
    )
  }
}

const mapStateToProps = state => ({
  categories: categoriesSelectors.getCategoriesNames(state),
  customCategories: currentRestaurantInfosSelectors.getHeaders(state),
  templates: state.templates
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ProductInformation)
