import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from 'core.ui/Select'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  searchBarContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    margin: '0 15px 0 0'
  },
  textFieldInput: {
    paddingRight: '32px'
  },
  select: {
    height: '40px'
  },
  selectSelect: {
    height: '40px',
    padding: '0 10px'
  },
  searchIcon: {
    position: 'absolute',
    right: '10px',
    height: '18px'
  },
  clearButton: {
    padding: '1px',
    height: '15px'
  },
  clearButtonLabel: {
    fontSize: '9px'
  }
}))

function SearchBar({
  searchFields = {},
  booleanFields = [],
  onSearch,
  onClearSearch,
  displayClearSearch = true
}) {
  const [value, setValue] = useState('')
  const [type, setType] = useState('')
  const classes = useStyles()

  const handleSubmit = () => {
    onSearch({
      type,
      value
    })
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const onClear = () => {
    setValue('')
    onClearSearch()
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.searchBarContainer}>
        <Select
          className={classes.select}
          classes={{
            select: classes.selectSelect
          }}
          options={Object.keys(searchFields)}
          captions={Object.values(searchFields)}
          value={type}
          onChange={(e) => {
            setType(e.target.value)
            setValue('')
          }}
        />
        {booleanFields.includes(type) ? (
          <Select
            className={classes.select}
            classes={{
              select: classes.selectSelect
            }}
            options={['true', 'false']}
            captions={['existe', "n'existe pas"]}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <TextField
            placeholder="Recherche…"
            size="small"
            variant="outlined"
            onKeyPress={handleEnterPress}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            inputProps={{
              'aria-label': 'Rechercher',
              className: classes.textFieldInput
            }}
          />
        )}
        <ButtonWithIcon
          className={classes.searchIcon}
          featherIcon="Search"
          iconSize="18"
          onClick={handleSubmit}
        />
      </div>
      {displayClearSearch && (
        <Button
          className={classes.clearButton}
          classes={{ label: classes.clearButtonLabel }}
          // variant="contained"
          onClick={onClear}
        >
          Effacer la recherche
        </Button>
      )}
    </div>
  )
}

export default SearchBar
