import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Typography, Paper, Divider } from '@material-ui/core'
import List from 'core.ui/List'
import { withStyles } from '@material-ui/core/styles'
import { makeDateString } from 'core.utils/parser/time'

import {
  fetchRestaurantLogsRequested,
  selectors as restaurantsSelectors
} from '../../core.modules/restaurants/reducer'

const styles = {
  mainContainer: { width: '80%', margin: '15px', padding: '15px' },
  Table: {
    width: '100%',
    margin: 'auto'
  }
}

class RestaurantLogs extends Component {

componentDidMount(){
    const { id, fetchRetaurantLogs } = this.props
    fetchRetaurantLogs(id)
}

  makeLogsList(currentRestaurantLogs) {
    return currentRestaurantLogs?.map((log) => {
      return [makeDateString(log.timestamp), log.type, this.getLogData(log)]
    })
  }

  getLogData(log) {
    return log.type === 'login' ? log.data.substring(0, 40) + '...' : log.data
  }

  render() {
    const { classes, currentRestaurantLogs } = this.props
    const logs = this.makeLogsList(currentRestaurantLogs)
        return (
            <Paper className={classes.mainContainer} elevation={3}>
              <Typography variant="h4">Logs Restaurant </Typography>
                  <List
                  className={classes.Table}
                  columns={['Date','Type', 'Data']}
                  list={logs}
                  emptyMessage="Aucun log"
                  />
        </Paper>
          )

  }
}

const mapStateToProps = (state) => ({
   currentRestaurantLogs: restaurantsSelectors.getCurrentRestaurantLogs(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchRetaurantLogs: (restaurantId) => {
    dispatch(fetchRestaurantLogsRequested(restaurantId))
  }
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(RestaurantLogs)