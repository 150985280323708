import { requestCity, restaurantRequest, riderRequest } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { parseErrorMessage } from '../../core.utils/requestActions'

export const {
  CREATE_REQUEST_CITY_REQUESTED,
  CREATE_REQUEST_CITY_SUCCEEDED,
  CREATE_REQUEST_CITY_FAILED,
  createRequestCitySucceeded,
  createRequestCityFailed
} = createRequestActions('CREATE_REQUEST_CITY')

export const createRequestCityRequested = cityInfos => dispatch => {
  dispatch({
    type: CREATE_REQUEST_CITY_REQUESTED
  })
  requestCity
    .addRequestCity(cityInfos)
    .then(data => {
      dispatch(createRequestCitySucceeded(data))
    })
    .catch(error => {
      dispatch(createRequestCityFailed(error))
    })
}

export const {
  EDIT_RESTAURANT_REQUEST_REQUESTED,
  EDIT_RESTAURANT_REQUEST_SUCCEEDED,
  EDIT_RESTAURANT_REQUEST_FAILED,
  editRestaurantRequestSucceeded,
  editRestaurantRequestFailed
} = createRequestActions('EDIT_RESTAURANT_REQUEST')

export const editRestaurantRequestRequested = (restaurantRequestId, restaurantRequestInfo) => dispatch => {
  dispatch({
    type: EDIT_RESTAURANT_REQUEST_REQUESTED
  })
  restaurantRequest
    .edit(restaurantRequestId, restaurantRequestInfo)
    .then(data => {
      dispatch(editRestaurantRequestSucceeded(data))
    })
    .catch(error => {
      dispatch(editRestaurantRequestFailed(error))
    })
}

export const {
  FETCH_REQUEST_CITY_REQUESTED,
  FETCH_REQUEST_CITY_SUCCEEDED,
  FETCH_REQUEST_CITY_FAILED,
  fetchRequestCitySucceeded,
  fetchRequestCityFailed
} = createRequestActions('FETCH_REQUEST_CITY')

export const fetchRequestCityRequested = () => dispatch => {
  dispatch({
    type: FETCH_REQUEST_CITY_REQUESTED
  })
  requestCity
    .getAll()
    .then(data => {
      dispatch(fetchRequestCitySucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRequestCityFailed(error))
    })
}

export const {
  DELETE_REQUEST_CITY_REQUESTED,
  DELETE_REQUEST_CITY_SUCCEEDED,
  DELETE_REQUEST_CITY_FAILED,
  deleteRequestCitySucceeded,
  deleteRequestCityFailed
} = createRequestActions('DELETE_REQUEST_CITY')

export const deleteRequestCityRequested = requestCityId => dispatch => {
  dispatch({
    type: DELETE_REQUEST_CITY_REQUESTED,
    data: {
      requestCityId
    }
  })
  requestCity
    .delete(requestCityId)
    .then(() => {
      dispatch(
        deleteRequestCitySucceeded({
          requestCityId
        })
      )
    })
    .catch(error => {
      dispatch(deleteRequestCityFailed(error))
    })
}

export const {
  FETCH_RESTAURANT_REQUESTS_REQUESTED,
  FETCH_RESTAURANT_REQUESTS_SUCCEEDED,
  FETCH_RESTAURANT_REQUESTS_FAILED,
  fetchRestaurantRequestsSucceeded,
  fetchRestaurantRequestsFailed
} = createRequestActions('FETCH_RESTAURANT_REQUESTS')

export const fetchRestaurantRequestsRequested = (page, limit, requestCity = null) => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_REQUESTS_REQUESTED
  })
  restaurantRequest
    .getAll(page, limit, requestCity)
    .then(data => {
      dispatch(fetchRestaurantRequestsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantRequestsFailed(error))
    })
}

export const {
  DELETE_RESTAURANT_REQUEST_REQUESTED,
  DELETE_RESTAURANT_REQUEST_SUCCEEDED,
  DELETE_RESTAURANT_REQUEST_FAILED,
  deleteRestaurantRequestSucceeded,
  deleteRestaurantRequestFailed
} = createRequestActions('DELETE_RESTAURANT_REQUEST')

export const deleteRestaurantRequestRequested = restaurantRequestId => dispatch => {
  dispatch({
    type: DELETE_RESTAURANT_REQUEST_REQUESTED,
    data: {
      restaurantRequestId
    }
  })
  restaurantRequest
    .delete(restaurantRequestId)
    .then(() => {
      dispatch(
        deleteRestaurantRequestSucceeded({
          restaurantRequestId
        })
      )
    })
    .catch(error => {
      dispatch(deleteRestaurantRequestFailed(error))
    })
}

export const {
  EDIT_RIDER_REQUEST_REQUESTED,
  EDIT_RIDER_REQUEST_SUCCEEDED,
  EDIT_RIDER_REQUEST_FAILED,
  editRiderRequestSucceeded,
  editRiderRequestFailed
} = createRequestActions('EDIT_RIDER_REQUEST')

export const editRiderRequestRequested = (riderRequestId, riderRequestInfo) => dispatch => {
  dispatch({
    type: EDIT_RIDER_REQUEST_REQUESTED
  })
  riderRequest
    .edit(riderRequestId, riderRequestInfo)
    .then(data => {
      dispatch(editRiderRequestSucceeded(data))
    })
    .catch(error => {
      dispatch(editRiderRequestFailed(error))
    })
}

export const {
  FETCH_RIDER_REQUESTS_REQUESTED,
  FETCH_RIDER_REQUESTS_SUCCEEDED,
  FETCH_RIDER_REQUESTS_FAILED,
  fetchRiderRequestsSucceeded,
  fetchRiderRequestsFailed
} = createRequestActions('FETCH_RIDER_REQUESTS')

export const fetchRiderRequestsRequested = (page, limit, requestCity = null) => dispatch => {
  dispatch({
    type: FETCH_RIDER_REQUESTS_REQUESTED
  })
  riderRequest
    .getAll(page, limit, requestCity)
    .then(data => {
      dispatch(fetchRiderRequestsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRiderRequestsFailed(error))
    })
}

export const {
  DELETE_RIDER_REQUEST_REQUESTED,
  DELETE_RIDER_REQUEST_SUCCEEDED,
  DELETE_RIDER_REQUEST_FAILED,
  deleteRiderRequestSucceeded,
  deleteRiderRequestFailed
} = createRequestActions('DELETE_RIDER_REQUEST')

export const deleteRiderRequestRequested = riderRequestId => dispatch => {
  dispatch({
    type: DELETE_RIDER_REQUEST_REQUESTED,
    data: {
      riderRequestId
    }
  })
  riderRequest
    .delete(riderRequestId)
    .then(() => {
      dispatch(
        deleteRiderRequestSucceeded({
          riderRequestId
        })
      )
    })
    .catch(error => {
      dispatch(deleteRiderRequestFailed(error))
    })
}

export const defaultState = { 
  requestCities: [],
  restaurantRequests: [],
  riderRequests: []
}
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case CREATE_REQUEST_CITY_REQUESTED:
    case FETCH_REQUEST_CITY_REQUESTED:
    case DELETE_REQUEST_CITY_REQUESTED:
    case EDIT_RIDER_REQUEST_REQUESTED:
    case EDIT_RESTAURANT_REQUEST_REQUESTED:
    case FETCH_RESTAURANT_REQUESTS_REQUESTED: 
    case DELETE_RESTAURANT_REQUEST_REQUESTED:
    case FETCH_RIDER_REQUESTS_REQUESTED:
    case DELETE_RIDER_REQUEST_REQUESTED:
      return {...state, requestState: requestStates.PENDING}
    case CREATE_REQUEST_CITY_SUCCEEDED: 
      const { data: requestCity } = action.payload
      return {
        ...state,
        requestCities: [...state.requestCities, requestCity],
        requestState: requestStates.SUCCESS
      }
    case EDIT_RESTAURANT_REQUEST_SUCCEEDED:
      const { restaurantRequests } = state
      const restaurantRequestInfo = action.payload.data
      const restaurantRequestIndex = restaurantRequests.findIndex((restaurantRequest) => {
        return restaurantRequest.id === restaurantRequestInfo.id || restaurantRequest._id === restaurantRequestInfo.id
      })
      return {
        ...state,
        restaurantRequests: [
          ...restaurantRequests.slice(0, restaurantRequestIndex),
          { ...restaurantRequests[restaurantRequestIndex], ...restaurantRequestInfo },
          ...restaurantRequests.slice(restaurantRequestIndex + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    case FETCH_REQUEST_CITY_SUCCEEDED: 
      let { data } = action.payload
      return {
        ...state,
        requestCities: data,
        requestState: requestStates.SUCCESS,
        errorMessage: ''
      }
    case DELETE_REQUEST_CITY_SUCCEEDED:
      const { requestCityId } = action.payload.data
      const index = state.requestCities.findIndex( requestCity => 
        requestCity._id === requestCityId || requestCity._id === requestCityId
      )
      return {
        ...state,
        requestCities: [...state.requestCities.slice(0, index), ...state.requestCities.slice(index + 1)],
        requestState: requestStates.SUCCESS
      }
    case EDIT_RIDER_REQUEST_SUCCEEDED:
      const { riderRequests } = state
      const riderRequestInfo = action.payload.data
      const riderRequestIndex = riderRequests.findIndex((riderRequest) => {
        return riderRequest.id === riderRequestInfo.id || riderRequest._id === riderRequestInfo.id
      })
      return {
        ...state,
        riderRequests: [
          ...riderRequests.slice(0, riderRequestIndex),
          { ...riderRequests[riderRequestIndex], ...riderRequestInfo },
          ...riderRequests.slice(riderRequestIndex + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    case FETCH_RESTAURANT_REQUESTS_SUCCEEDED: 
      const restaurantRequestsData = action.payload.data
      return {
        ...state,
        restaurantRequests: restaurantRequestsData,
        requestState: requestStates.SUCCESS,
        errorMessage: ''
      }
    case FETCH_RIDER_REQUESTS_SUCCEEDED: 
      const riderRequestsData = action.payload.data
      return {
        ...state,
        riderRequests: riderRequestsData,
        requestState: requestStates.SUCCESS,
        errorMessage: ''
      }
    case DELETE_RESTAURANT_REQUEST_SUCCEEDED: {
      const { restaurantRequestId } = action.payload.data
      const index = state.restaurantRequests.findIndex( restaurantRequest => 
        restaurantRequest.id === restaurantRequestId || restaurantRequest._id === restaurantRequestId
      )
      return {
        ...state,
        restaurantRequests: [...state.restaurantRequests.slice(0, index), ...state.restaurantRequests.slice(index + 1)],
        requestState: requestStates.SUCCESS
      }
    }
    case DELETE_RIDER_REQUEST_SUCCEEDED: {
      const { riderRequestId } = action.payload.data
      const index = state.riderRequests.findIndex( riderRequest => 
        riderRequest.id === riderRequestId || riderRequest._id === riderRequestId
      )
      return {
        ...state,
        riderRequests: [...state.riderRequests.slice(0, index), ...state.riderRequests.slice(index + 1)],
        requestState: requestStates.SUCCESS
      }
    }
      case CREATE_REQUEST_CITY_FAILED:
      case DELETE_REQUEST_CITY_FAILED:
      case EDIT_RIDER_REQUEST_FAILED:
      case EDIT_RESTAURANT_REQUEST_FAILED:
      case FETCH_REQUEST_CITY_FAILED:
      case DELETE_RESTAURANT_REQUEST_FAILED:
        return {
          ...state,
          requestState: requestStates.FAILED,
          errorMessage: parseErrorMessage(action)
        }
      case FETCH_RIDER_REQUESTS_FAILED:
        return {
          ...state,
          riderRequests: [],
          requestState: requestStates.FAILED,
          errorMessage: parseErrorMessage(action)
        }
      case FETCH_RESTAURANT_REQUESTS_FAILED:
        return {
          ...state,
          restaurantRequests: [],
          requestState: requestStates.FAILED,
          errorMessage: parseErrorMessage(action)
        }
    default:
      return state
  }
}

const getState = state => state.requests
const getAllRequestCities = state => getState(state).requestCities
const getAllRestaurantRequests = state => getState(state).restaurantRequests
const getAllRiderRequests = state => getState(state).riderRequests
const getErrorMessage = state => getState(state).errorMessage
const getRequestState = state => getState(state).requestState

export const selectors = {
  getState,
  getAllRequestCities,
  getAllRestaurantRequests,
  getAllRiderRequests,
  getErrorMessage,
  getRequestState
}
