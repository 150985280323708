import React from 'react';
import { useEffect, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';

import { LAST_WEEK, CURRENT_WEEK } from './planningsConstant';
import { planningAPI } from 'core.services/api';
import NewPlanningTable from './NewPlanningTable';
import { addDays, subDays, startOfWeek, format } from 'date-fns'
import { Autocomplete } from '@material-ui/lab';
import 'date-fns'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { FormControl } from '@material-ui/core';

const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

export default function NewPlanningsList({ city, selectedWeek }) {
	const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState();
  const [ rows, setrows ] = useState([]);

  const startCurrentWeekValue = startOfWeek(Date.now(), { weekStartsOn: 1 })
  const startNextWeekValue = startOfWeek(addDays(startOfWeek(Date.now(), { weekStartsOn: 1 }), 8), { weekStartsOn: 1 })

  const [startWeek, setStartWeek] = useState({ value: startCurrentWeekValue, formatted: format(startCurrentWeekValue, 'yyyy-MM-dd') });
  // const [startWeekFormatted, setStartWeek] = useState(format(startWeek, 'yyyy-MM-dd'));
  // const [startWeekValue, setStartWeekValue] = useState(startWeek);

  const lastWeekValue = startOfWeek(subDays(startOfWeek(Date.now(), { weekStartsOn: 1 }), 6), { weekStartsOn: 1 })
  const weekOptions = [{ id: lastWeekValue, name: 'Semaine précédente', formatted: format(lastWeekValue, 'yyyy-MM-dd') }]

  for (let i = 13; i < 60; i += 7) {
    const value = startOfWeek(subDays(Date.now(), i), { weekStartsOn: 1 })
    const name = format(value, 'yyyy-MM-dd')
    weekOptions.push({ id: value, name, formatted: name })
  }

  const handleWeekChange = (e, value) => {
    if (!value || !value.id) return

    setStartWeek({ value: value?.id, formatted: value?.formatted, name: value?.formatted });
  }

	useEffect(
		() => {
			fetchPlannings()
    },
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[city, startWeek]
	);

	const fetchPlannings = async () => {
        if (!city) return 
        setLoading(true)
        setError()
        try {
           /* const res = await planningAPI.getCityPlannings(city);
            let currentPlanning =
                selectedWeek === LAST_WEEK
                    ? res.previousWeek
                    : selectedWeek === CURRENT_WEEK ? res.currentWeek : res.nextWeek; */
            const res = await planningAPI.getCityPlannings(city, startWeek.formatted);
            let currentPlanning = res.weekPlanning


            currentPlanning.forEach((pl, index) => {                
                if (!currentPlanning[index].planning[0]) {
                    currentPlanning[index].isEmpty = true
                    return 
                }
                currentPlanning[index].startWeekDate = currentPlanning[index].planning[0]?.startWeekDate 
                currentPlanning[index].week = {}
                days.forEach(d => {
                    currentPlanning[index].planning[0].week.forEach(w => {
                        if (w.day === d) {
                            currentPlanning[index].week[d] = w
                        }
                    })
                })
                if (Object.entries(currentPlanning[index].week).length === 0) {
                    currentPlanning[index].week = null
                    currentPlanning[index].isEmpty = true
                }
            })
            setrows(currentPlanning);				
        } catch (e) {
            setrows([])
            setError(e.response?.data?.errors[0]?.message || "Une erreur est survenue")            
            console.log('ERROR !! fetch planning', e, e.response, city, selectedWeek);
        } finally {
            setLoading(false)
        }		
	};

	const refresh = () => {
		fetchPlannings();
    };
    
    if (loading) {
        return <CircularProgress
                    size={30}
                    style={{ marginTop: 30 }}
                />
    }

    if (!!error) {
        return <p style={{color:"#b70000", textAlign:"center", fontSize:20}}>{error}</p>
    }

    return (
		<> 
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
				<FormControl>
					<ToggleButtonGroup
						value={startWeek.value}
						exclusive
						onChange={(e, value) => {
							value !== null && setStartWeek({ value, formatted: format(value, 'yyyy-MM-dd') });;
						}}
						aria-label="text alignment"
					>
						<ToggleButton value={startCurrentWeekValue} selected={startWeek.value?.toString() === startCurrentWeekValue.toString()} aria-label="centered">
							<span>Semaine actuelle</span>
						</ToggleButton>
						<ToggleButton value={startNextWeekValue} selected={startWeek.value?.toString() === startNextWeekValue.toString()} aria-label="right aligned">
							<span>Semaine prochaine</span>
						</ToggleButton>
					</ToggleButtonGroup>
				</FormControl>
			</div>
      <br></br>
      <Autocomplete
          id="combo-box-demo"
          options={weekOptions}
          defaultValue={startWeek.value < startCurrentWeekValue ? startWeek : ''}
          getOptionLabel={(weekOptions) => weekOptions?.name || weekOptions?.formatted}
          getOptionSelected={(elem1, elem2) => elem1.formatted === elem2.formatted}
          style={{ width: 300 }}
          onChange={handleWeekChange}
          renderInput={(params) => (
              <TextField {...params} label="Semaines" variant="outlined" />
          )}
      />
      <NewPlanningTable
        city={city}
        rows={rows}
        isCurrentWeek={startWeek.value?.toString() === startCurrentWeekValue.toString()}
        isPreviousWeek={startWeek.value < startOfWeek(Date.now(), { weekStartsOn: 1 })}
        refresh={refresh}
        selectedWeek={startWeek.formatted}
      />
		</>
	);
}
