import { promoCode } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { parseErrorMessage } from '../../core.utils/requestActions'



export const {
    CREATE_PROMO_CODE_REQUESTED,
    CREATE_PROMO_CODE_SUCCEEDED,
    CREATE_PROMO_CODE_FAILED,
    createPromoCodeSucceeded,
    createPromoCodeFailed

} = createRequestActions('CREATE_PROMO_CODE')

export const createPromoCode = promoCodeInfos => dispatch => {

    dispatch({
        type: CREATE_PROMO_CODE_REQUESTED
    })
    promoCode
    .createPromoCode(promoCodeInfos)
    .then(data => {
        dispatch(createPromoCodeSucceeded(data))
    })
    .catch(error => {
        dispatch(createPromoCodeFailed(error))
    })
}

export const defaultState = {
    promoCodes: [],
}

export default (state = defaultState , action = {}) => {
    switch (action.type) {
        case CREATE_PROMO_CODE_REQUESTED:
            return { ...state, requestState: requestStates.PENDING }    
        case CREATE_PROMO_CODE_SUCCEEDED:
            const { data: promoCode} = action.payload
            return {
                ...state,
                promoCodes: [...state.promoCodes, promoCode],
                requestState: requestStates.SUCCESS
            }
        case CREATE_PROMO_CODE_FAILED:
            return {
                ...state,
                requestState: requestStates.FAILED,
                errorMessage: parseErrorMessage(action)
            }
        default: 
          return state
    }
}

 const getState = state => state.promoCode 
 const getErrorMessage = state => getState(state).errorMessage
 const getRequestState = state => getState(state).requestState

 export const selectors = {
     getState,
     getErrorMessage,
     getRequestState,
 }
