import React from 'react'
import Button from '@material-ui/core/Button'
import MuiDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Address from 'components/Address'
import { FormControl, InputLabel } from '@material-ui/core'
import classnames from 'classnames'
import Select from 'core.ui/Select'
import TextField from 'core.ui/TextField'
import isEmpty from 'core.utils/isEmpty.js'
import {
  isEmailValid,
  isTelephoneValid,
  isNumberWithinRange,
  isNumberValid
} from 'core.utils/formValidation.js'

export const requiredFields = [
  'email',
  'telephone',
  'comission',
  'adress',
  'city'
]
class DuplicateRestaurantDialog extends React.Component {
  state = {
    city: '', 
    email:'',
    comission: '25',
    adress: {
      streetAdress: '',
      zipCode: '',
      city: '',
      latitude: '',
      longitude:''
    },
    telephone: '',
    showErrors: false,
    validFields: {}
  }

  handleValidFieldChange = field => value => {
    const { validFields } = this.state
    this.setState({
      validFields: {
        ...validFields,
        [field]: value
      }
    })
  }

  handleChange = (field) => (e) => {
    //this.setState({ [field]: e.target.value })

    const { value } = e.target
    const fields = field.split('.')
    const trimmedValue = typeof value === 'string' ? value?.trim() : value

    if (fields.length === 1) {

      this.setState({
        [field]: trimmedValue,
        validFields: {
          ...this.state.validFields,
          [field]: this.isFieldValid(field, value)
        }
      })
    } else {
      this.setState({
        [fields[0]]: { ...this.state[fields[0]], [fields[1]]: trimmedValue },
        validFields: {
          ...this.state.validFields,
          [fields[0]]: this.isFieldValid(fields[0], {
            ...this.state[fields[0]],
            [fields[1]]: value
          })
        }
      })
    }
  }

  isFieldValid = (field, value) => {
    const latitude = parseFloat(value?.latitude, 10)
    const longitude = parseFloat(value?.longitude, 10)

    switch(field) {
      case "email":
        return isEmailValid(value);

      case "telephone":
        return isTelephoneValid(value);

      case 'comission':
        return isNumberWithinRange(0, 100)(value);

      case "adress":
        return !((Number.isNaN(latitude) || latitude < -90 || latitude > 90) || (Number.isNaN(longitude) || longitude < -180 || longitude > 180))

      default:
        return true
    }
  }

  getValidFields = () => {
    const validFields = requiredFields.map((field) =>
      this.isFieldValid(field, this.state[field])
    )
    return Object.fromEntries(requiredFields.map((f, i) => [f, validFields[i]]))
  }

  handleSubmit = () => {
    const validFields = this.getValidFields();

    this.setState({
      validFields
    }, () => {
      if (
        Object.values(validFields).some((f) => !f)
      ) {
        this.setState({ validFields, showErrors: true })
      } else {
        this.props.onValidation(this.state.city,this.state.email,this.state.adress,this.state.comission,this.state.telephone,this.state.latitude,this.state.longitude)
      }
    })
  }

  validateLatitude = (latitude) => {
   
    if(isEmpty(latitude)) 
      return 'Entrez la latitude';
    else if(Number.isNaN(latitude) || latitude < -90 || latitude > 90)
      return 'Latitude incorrecte';

    return ""
  }

  validateLongitude = (longitude) => {
   
    if(isEmpty(longitude)) 
      return 'Entrez la longitude';
    else if(Number.isNaN(longitude) || longitude < -180 || longitude > 180)
      return 'Longitude incorrecte';

    return ""
  }

  render() {
    const {
      open,
      dialogText,
      dialogTitle,
      cancelButtonCaption,
      confirmButtonCaption,
      onCancel,
      allCities,
      classes
    } = this.props
    
    const { city, email, comission, adress, telephone, validFields, showErrors } = this.state

    return (
      <MuiDialog open={open}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
          {allCities && (
            <FormControl>
              <InputLabel className={classes.selectLabel} shrink>
                Ville
              </InputLabel>
              <Select
                id="city"
                name="city"
                className={classnames(classes.rows, classes.select)}
                native
                value={city}
                options={allCities.map((c) => c.id)}
                captions={allCities.map((c) => c.name)}
                onChange={this.handleChange('city')}
              />
              <TextField 
              label="Email"
              value={email}
              onChangeComplete={this.handleChange('email')}
              error={showErrors && !validFields.email}
              helperText={
                showErrors && !validFields.email ? 'Ce champ est requis' : ''
              }
              />
              <TextField
              className={classes.rows}
              value={telephone}
              onChangeComplete={this.handleChange('telephone')}
              label="Téléphone"
              error={showErrors && !validFields.telephone}
              helperText={
                showErrors && !validFields.telephone
                  ? 'Entrez un téléphone valide'
                  : ''
              }
              />

               <Address
              className={classes.rows}
              value={adress}
              handleValidFieldChange={this.handleValidFieldChange('adress')}
              onChange={this.handleChange('adress')}
              showErrors={showErrors}
            />
              {/* COMMISSION */}
          <div className={classnames(classes.lines, classes.rows)}>
            <TextField
              className={classes.commissionTextField}
              value={comission}
              onChangeComplete={this.handleChange('comission')}
              fullWidth
              label="Commission (%)"
            />

          </div>

          <TextField
            type="number"
            InputProps={{ inputProps: { min: -90, max: 90 } }}
            fullWidth
            value={adress.latitude}
            onChangeComplete={this.handleChange('adress.latitude')}
            label="Latitude"
            required={!isEmpty(adress.latitude)}
            error={showErrors && !validFields.adress?.latitude}
            min
            helperText={
              showErrors && !validFields.adress?.latitude
                ? this.validateLatitude(adress.latitude)
                : ''
            }
          />
          <TextField
            type="number"
            InputProps={{ inputProps: { min: -180, max: 180 } }}
            fullWidth
            value={adress.longitude}
            onChangeComplete={this.handleChange('adress.longitude')}
            label="Longitude"
            required={!isEmpty(adress.longitude)}
            error={showErrors && !validFields.adress?.longitude}
            helperText={
              showErrors && !validFields.adress?.longitude
                ? this.validateLongitude(adress.longitude)
                : ''
            }
          />

            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCancel} color="secondary">
            {cancelButtonCaption}
          </Button>
          <Button onClick={this.handleSubmit} color="secondary">
            {confirmButtonCaption}
          </Button>
        </DialogActions>
      </MuiDialog>
    )
  }
  
}


export default DuplicateRestaurantDialog
