import { Grid, Paper, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import TimerIcon from '@material-ui/icons/Timer'
import { ReclamationEnums } from './ClaimList'
import { Link } from 'react-router-dom'
import EuroIcon from '@material-ui/icons/Euro'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import RateReviewIcon from '@material-ui/icons/RateReview'
import WarningIcon from '@material-ui/icons/Warning'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import UploadButton from 'components/UploadButton'
import { parseImage } from 'core.utils/parser/image'
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { getConfig, resetError } from '../../core.modules/Claims/Actions'
import { connect, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import RegulationContainer from '../../pages/OrderPage/OrderList/components/RegulationContainer'
import RegulationList from '../RegulationList/RegulationList'
import { getDefaultRegulation } from '../../core.utils/parser/regulationEnums'
const refundTypes = {
  promo_code: 'promo_code',
  monetary: 'monetary'
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  paperElements: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary
  },
  paperHeader: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '25px',
    fontWeight: 'bold'
  },
  spinner: {
    position: 'absolute',
    left: '50%'
  },
  alert: {
    textAlign: 'center',
    color: 'red',
    paddingBottom: '20px'
  }
}))

const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

const ClaimDetail = ({
  OrderClaims,
  loading,
  RespondToClaim,
  Claims: { config, err },
  resetError
}) => {
  const [pendingAlert, setAlert] = useState(false)


  const showPriceIncrease = permissions.order?.accessPriceIncrease

  React.useEffect(() => {
    !loading &&
      OrderClaims.find((claim) => {
        if (claim.status !== 'pending') {
          setAlert(true)
        }
      })
    if (pendingAlert) {
      window.alert('Attention une réponse a déjà été donnée !')
    }
  }, [loading, pendingAlert])
  React.useEffect(() => {
    if (err) {
      window.alert(err)
    }
  }, [err])

  const [status, setStatus] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [openDetails, setOpenDetails] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [claimId, setClaimId] = useState('')
  const descriptionElementRef = React.useRef(null)
  const [scroll, setScroll] = React.useState('body')
  const [open2, setOpen2] = React.useState(false)
  const [description, setDescription] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [formData, setFormData] = useState({
    response: ReclamationEnums.ReclamationStatuses.PARTIAL,
    message: null,
    refundedAmount: '',
    refundType: refundTypes.promo_code
  })
  const { response, message, refundedAmount, refundType } = formData
  const [rejectData, setRejectData] = useState({
    message: `Bonjour, Nous sommes désolés que votre commande ne vous ai pas apporté satisfaction. Après un examen attentif de votre réclamation, nous sommes au regret de vous informer que nous ne procéderons pas au reboursement des articles mentionnés. Nous nous excusons pour cette déconvenue et espérons vous revoir prochainement sur Lyveat! Cordialement`,
    response: ReclamationEnums.ReclamationStatuses.REJECTED,
    image: ''
  })
  const [closeData,setCloseData]=useState({
    message:'',
    response: ReclamationEnums.ReclamationStatuses.CLOSED,
  })
  const [template, setTemplate] = useState('Remboursement refusé')
  const [regulationToEdit, setRegulationToEdit] = useState(
    getDefaultRegulation()
  )
  const [openParialDialog,setOpenParialDialog]=useState(false)
  const [closeDialog,setCloseDialog]=useState(false)
  const handleMessageOpen = (scrollType, message) => () => {
    setOpen2(true)
    setDescription(message)
    setScroll(scrollType)
  }

  const handleMessageClose = () => {
    setOpen2(false)
  }
  const handledetailsClose = () => {
    setOpenDetails(false)
  }
  const [Details, setDetails] = useState('')
  const handledetailsOpen = (claim) => {
    setDetails(claim)
    setOpenDetails(true)
  }
  React.useEffect(() => {
    if (open2) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open2])
  const handlechange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleRejectChange = (e) => {
      setRejectData({ ...rejectData, [e.target.name]: e.target.value })
    
  }
  const handleCloseChange = (e) => {
    setCloseData({ ...closeData, [e.target.name]: e.target.value }) 
  }
  useEffect(() => {}, [claimId])
  const handleClickOpen = (claim) => {
    if (claim.products.length == 0) setDisabled(true)
    claim.status !== config.status.pending.value ? setStatus(true) : setStatus(false)

    setClaimId(claim.id || claim._id)
    setRegulationToEdit({
      ...regulationToEdit,
      products: claim.products.length > 0 ? [{ ...claim.products[0], _id: claim.products[0].productId }] : null,
      value:claim.products.length > 0 ?  claim.products[0].totalPrice : null,
      restaurantId: claim.restaurantId._id,
      type: 'product'
    })
    setOpen(true)
  }
  const handleClickOpenParial = (claim) => {
    if (claim.products.length == 0) setDisabled(true)
    claim.status !== config.status.pending.value ? setStatus(true) : setStatus(false)

    setClaimId(claim.id || claim._id)
    setRegulationToEdit({
      ...regulationToEdit,
     // products: claim.products.length > 0 ? [{ ...claim.products[0], _id: claim.products[0].productId }] : [],
      value:claim.products.length > 0 ?  claim.products[0].totalPrice : null,
      restaurantId: claim.restaurantId._id,
      type: 'amount'
    })
    setOpenParialDialog(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenParialDialog(false)
  }
 const  handleCloseParial =()=> {
   setOpenParialDialog(false)
 }
  const handleClickOpen1 = (claimid, status) => {
    status !== config.status.pending.value ? setStatus(true) : setStatus(false)
    setClaimId(claimid)
    setOpen1(true)
  }
  const OpenCloseDialog = (claimid, status) => {
    status !== config.status.pending.value ? setStatus(true) : setStatus(false)
    setClaimId(claimid)
    setCloseDialog(true)
  }
  const handleClose1 = () => {
    setOpen1(false)
    setCloseDialog(false)
  }

  const SubmitReject = (e) => {
    e.preventDefault()
    RespondToClaim(claimId, rejectData)
    setOpen1(false)
  }
  const submitCloseClaim=e=>{
    e.preventDefault()
    RespondToClaim(claimId,closeData)
    setCloseDialog(false)
  }
  const dispatch = useDispatch()
  const SubmitForm = (e) => {
    e.preventDefault()
    if(disabled) formData.response =  ReclamationEnums.ReclamationStatuses.PARTIAL
    if (
      (response === ReclamationEnums.ReclamationStatuses.PARTIAL &&
      !refundedAmount) || (disabled && !refundedAmount)
    ) {
      window.alert('entrez un montant à rembourser')
    } else {
      dispatch(resetError)

      if (formData.response === ReclamationEnums.ReclamationStatuses.REFUNDED) {
        setFormData({ ...formData, refundedAmount: '' })
      }
      RespondToClaim(claimId, formData)

      setOpen(false)
      setOpenParialDialog(false)
    }
  }

  const TranslateTypes = (claim) => {
    const fr =
      claim === ReclamationEnums.ReclamationTypes.MISSING_PRODUCT.VALUE
        ? 'Produit Manquant'
        : claim === ReclamationEnums.ReclamationTypes.DAMAGED_PRODUCT.VALUE
        ? 'Produit Endommagé '
        : 'Autres ...'

    return fr
  }
  const classes = useStyles()
  const CheckStatus = (claim) => {
    let Markstyle = { color: 'green', width: '10px', height: '10px' }
    let Markstyle1 = { color: 'orange', width: '10px', height: '10px' }
    const status = claim.find(
      (claim) => claim.status === config?.status?.pending?.value
    )
    const result = status ? (
      <span style={Markstyle1}>
        <b>
          <TimerIcon /> Ouvert
        </b>
      </span>
    ) : (
      <span style={Markstyle}>
        <b>
          <HighlightOffIcon /> Fermé
        </b>
      </span>
    )
    return result
  }
  const TranslateStatus = (claim) => {
    let Markstyle = { color: 'green', width: '10px', height: '10px' }
    let Markstyle1 = { color: 'orange', width: '10px', height: '10px' }
    let Markstyle2 = { color: 'red', width: '10px', height: '10px' }
    const fr =
      claim === ReclamationEnums.ReclamationStatuses.REFUNDED ? (
        <span style={Markstyle}>
          <b>
            <CheckCircleOutlineIcon /> Totalement Remboursée
          </b>
        </span>
      ) : claim === ReclamationEnums.ReclamationStatuses.PENDING ? (
        <span style={Markstyle1}>
          <b>
            <TimerIcon /> En attente
          </b>
        </span>
      ) : claim === ReclamationEnums.ReclamationStatuses.PARTIAL ? (
        <span style={Markstyle}>
          <b>
            <CreditCardIcon /> Pariallement Remboursée
          </b>
        </span>
      ) : claim === ReclamationEnums.ReclamationStatuses.REJECTED?(
        <span style={Markstyle2}>
          <b>
            <ErrorIcon /> Rejetée
          </b>
        </span>
      ):(<span style={Markstyle2}>
        <b>
          <ErrorIcon /> Fermée
        </b>
      </span>)

    return fr
  }
  const [imagePreview, setImagePreview] = useState(null)

  const handleImageSelect = (e) => {
    const { files } = e.target
    parseImage(files[0]).then((result) => {
      setRejectData({ ...rejectData, image: files[0] })
      setImagePreview(result)
    })
  }
  const translateClaim = (claimSubType) => {
    let translate
   !loading && config.types.map((type) => {
      type.subTypes.map((subtypes) => {
        if (claimSubType === subtypes.value) {
          translate = subtypes.label
        }
      })
    })

    return translate
  }
  return (
    <div>
      {loading ? (
        <p style={{ textAlign: 'center' }}>chargement ...</p>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paperHeader}>
                Détails Reclamation Commande N° #
                {OrderClaims[0]?.orderId?.billNumber?.split('-')[4]}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <h3 className={classes.paper}>Details</h3>
                <div className={classes.paperElements}>
                  <table style={{ width: '100%' }}>
                    <th></th>
                    <th></th>
                    <th></th>
                    <tr>
                      <td>
                        <b>Statut :</b> {CheckStatus(OrderClaims)} <br />
                        <b>Date Creation:</b>{' '}
                        {moment(OrderClaims[0]?.created_at)
                          .locale('fr')
                          .format('Do MMMM YYYY HH:mm')}
                        <br />
                        <b>Mise à jour :</b>{' '}
                        {moment(OrderClaims[0]?.updated_at)
                          .locale('fr')
                          .format('Do MMMM YYYY HH:mm')}
                      </td>
                      <td>
                        {' '}
                        <b>Date Commande :</b>{' '}
                        {moment(OrderClaims[0]?.orderId.created_at)
                          .locale('fr')
                          .format('Do MMMM YYYY HH:mm')}{' '}
                        <br />
                        <b>N° Commande:</b>{' '}
                        {OrderClaims[0]?.orderId?.billNumber?.split('-')[4]}
                        <br />
                        <b>Lien commande: </b>{' '}
                        <Link
                          to={`/commandes/${
                            OrderClaims[0]?.orderId?._id ||
                            OrderClaims[0]?.orderId?.id
                          }`}
                        >
                          Cliquez içi
                        </Link>
                        {OrderClaims[0]?.orderId?.noteForAdmin ? (
                          <div>
                            
                            <b>Note Admin :</b>{' '}
                            {OrderClaims[0]?.orderId?.noteForAdmin}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </td>
                      <td>
                        <b>Restaurant: </b> {OrderClaims[0]?.restaurantId?.nom}{' '}
                        <br />
                        <b>Nombre de reclamation: </b> {OrderClaims?.length}
                        <br />
                        {OrderClaims[0]?.orderId?.mealVoucherInfo ? (
                          <div>
                            <b>Montant payé par ticket restaurant</b>{' '}
                            {OrderClaims[0].orderId?.mealVoucherInfo.amount} €
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {OrderClaims[0]?.orderId?.mealVoucherInfo ? (
                          <div>
                            <b>Montant payé par carte bancaire: </b>{' '}
                            {
                              (
                                (((OrderClaims[0]?.orderId?.reducedTotalPrice 
                                  || OrderClaims[0]?.orderId?.totalPrice
                                  ) - OrderClaims[0]?.orderId?.mealVoucherInfo?.amount + (showPriceIncrease ? OrderClaims[0]?.orderId?.totalPriceIncrease : 0))
                                ).toFixed(2)
                              )
                            }
                            €{' '}
                          </div>
                        ) : (
                          <div>
                            <b>Montant payé par carte bancaire :</b>
                          {
                            (((OrderClaims[0]?.orderId?.reducedTotalPrice || OrderClaims[0]?.orderId?.totalPrice) + (showPriceIncrease ? OrderClaims[0]?.orderId?.totalPriceIncrease : 0))).toFixed(2)
                          }
                            €
                          </div>
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperElements}>
                <div>
                  <h4>
                    <u>Reclamations : </u>
                  </h4>
                  <table style={{ width: '100%' }}>
                    
                    <th>N° Reclamation</th>
                    <th>Nom Produit</th>
                    <th>Montant</th>
                    <th>Type</th>
                    <th>Message</th>
                    <th>Photo</th>
                    <th>Statut</th>
                    <th>Actions</th>
                    {OrderClaims.map((claim) =>

                      claim.cart && claim.cart.length 
                        ? 
                          claim.cart.map((cartElem) => (
                            <tr>
                              <td>{claim.reclamationNumber}</td>
                              <td>{cartElem.title}</td>
                              <td>{cartElem.price.totalPrice.text}</td>
                              <td>
                                {TranslateTypes(claim.type)}
                                <br />
                                {translateClaim(claim.subType)}
                              </td>
                              <td>
                                <Button
                                  onClick={handleMessageOpen('body', claim.message)}
                                >
                                  <RateReviewIcon />
                                </Button>
                                <Dialog
                                  open={open2}
                                  onClose={handleMessageClose}
                                  scroll={scroll}
                                  aria-labelledby="scroll-dialog-title"
                                  aria-describedby="scroll-dialog-description"
                                >
                                  <DialogTitle id="scroll-dialog-title">
                                    Message de la Reclamation
                                  </DialogTitle>
                                  <DialogContent dividers={scroll === 'paper'}>
                                    <DialogContentText
                                      id="scroll-dialog-description"
                                      ref={descriptionElementRef}
                                      tabIndex={-1}
                                    >
                                      {description}
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={handleMessageClose}
                                      color="primary"
                                    >
                                      Fermer
                                    </Button>
                                  </DialogActions>
                                </Dialog>{' '}
                              </td>
    
                              <td>
                                {claim.imagePath ?<img
                                  src={`${process.env.REACT_APP_IMAGES_URL}${claim.imagePath}`}
                                  width="350px"
                                  alt="Lyveat"
                                />:<div></div>}
                              </td>
                              <td>{TranslateStatus(claim.status)}</td>
                              <td>
                                {claim.status === config.status.pending.value ?<div><Button onClick={(e) => handleClickOpen(claim)}>
                                  <EuroIcon /> Rembourser
                                </Button>
                                <Button
                                  style={{ color: 'red' }}
                                  onClick={(e) =>
                                    handleClickOpen1(
                                      claim._id || claim.id,
                                      claim.status
                                    )
                                  }
                                >
                                  <CloseIcon style={{ color: 'red' }} /> Refuser
                                </Button>
                                <Button
                                  style={{ color: 'orange' }}
                                  onClick={(e) =>
                                    OpenCloseDialog(
                                      claim._id || claim.id,
                                      claim.status
                                    )
                                  }
                                >
                                  <CancelIcon style={{ color: 'orange' }} /> Fermer
                                </Button>
                                </div>:<Button
                                    style={{ color: 'blue' }}
                                    onClick={(e) => handledetailsOpen(claim)}
                                  >
                                    <HelpOutlineIcon /> Details
                                  </Button>}
                              
                                
                                {/********************Dialog Refund***************************** */}
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'Remboursement sur la Reclamation '}
                                  </DialogTitle>
                                  <DialogContent>
                                    {status ? (
                                      <p className={classes.alert}>
                                        <WarningIcon /> Attention cette reclamation
                                        est déja saisie
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                    <DialogContentText id="alert-dialog-description">
                                      <span style={{ paddingRight: '20px' }}>
                                        Remboursement :
                                      </span>
                                      <Select
                                        value={response}
                                        onChange={(e) => handlechange(e)}
                                        name="response"
                                      >
                                        <MenuItem
                                          value={
                                            !loading && config.status.refunded.value
                                          }
                                        >
                                          integral{' '}
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            !loading &&
                                            config.status.partially_refunded.value
                                          }
                                        >
                                          Partiel
                                        </MenuItem>
                                      </Select>
                                      {response ===
                                      ReclamationEnums.ReclamationStatuses
                                        .PARTIAL ? (
                                        <div>
                                          <div>
                                            <FormControl
                                              fullWidth
                                              className={classes.margin}
                                            >
                                              <InputLabel htmlFor="standard-adornment-amount">
                                                Montant
                                              </InputLabel>
                                              <Input
                                                id="standard-adornment-amount"
                                                type="number"
                                                value={refundedAmount}
                                                name="refundedAmount"
                                                onChange={(e) => handlechange(e)}
                                                startAdornment={
                                                  <InputAdornment position="start">
                                                    €
                                                  </InputAdornment>
                                                }
                                              />
                                            </FormControl>
                                            <div>
                                              <span
                                                style={{ paddingRight: '20px' }}
                                              >
                                                Mode :
                                              </span>
                                              <Select
                                                value={refundType}
                                                onChange={(e) => handlechange(e)}
                                                name="refundType"
                                              >
                                                <MenuItem
                                                  value={refundTypes.promo_code}
                                                >
                                                  Code Promo{' '}
                                                </MenuItem>
                                                <MenuItem
                                                  value={refundTypes.monetary}
                                                >
                                                  Monétaire
                                                </MenuItem>
                                              </Select>
                                              <br />
                                              Saisir une Regulation :{' '}
                                              <RegulationContainer
                                                order={claim.orderId}
                                                classes={classes}
                                                claim={true}
                                                regulationToEdit={regulationToEdit}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <span style={{ paddingRight: '20px' }}>
                                            Mode :
                                          </span>
                                          <Select
                                            value={refundType}
                                            onChange={(e) => handlechange(e)}
                                            name="refundType"
                                          >
                                            <MenuItem
                                              value={refundTypes.promo_code}
                                            >
                                              Code Promo{' '}
                                            </MenuItem>
                                            <MenuItem value={refundTypes.monetary}>
                                              Monétaire
                                            </MenuItem>
                                          </Select>
                                          <br />
                                          Saisir une Regulation :{' '}
                                          <RegulationContainer
                                            order={claim.orderId}
                                            classes={classes}
                                            claim={true}
                                            regulationToEdit={regulationToEdit}
                                          />
                                        </div>
                                      )}
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                      Annuler
                                    </Button>
                                    <Button
                                      onClick={(e) => SubmitForm(e)}
                                      color="primary"
                                      autoFocus
                                    >
                                      Comfirmer
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                {/* Dialog Reject */}
                                <Dialog
                                  open={open1}
                                  onClose={handleClose1}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'Information de rejection'}
                                  </DialogTitle>
                                  <DialogContent>
                                    {status ? (
                                      <p className={classes.alert}>
                                        <WarningIcon /> Attention cette reclamation
                                        est déja saisie
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                    {/*  <div style={{ paddingBottom: '20px' }}>
                                      {rejectData.image && (
                                        <img
                                          src={imagePreview}
                                          style={{ width: '300px' }}
                                          alt="Claim"
                                        />
                                      )}
                                      <div style={{ paddingLeft: '37%' }}>
                                        <UploadButton
                                          title="Photo"
                                          onFileSelect={handleImageSelect}
                                        />
                                      </div>
                                    </div> */}
                                    <DialogContentText id="alert-dialog-description">
                                      <span style={{ paddingRight: '20px' }}>
                                        Templates:{' '}
                                      </span>
                                      <Select
                                        //value={typeRemboursement}
                                        onChange={(e) => handleRejectChange(e)}
                                        name="template"
                                        value={template}
                                      >
                                        <MenuItem value={'Remboursement refusé'}>
                                        Remboursement refusé{' '}
                                        </MenuItem>
                                      {/*  <MenuItem value={'Problème lyveat : '}>
                                          Probleme lyveat
                                        </MenuItem> */}
                                      </Select>
    
                                      <TextField
                                        label="Description"
                                        fullWidth
                                        multiline
                                        value={rejectData.message}
                                        name="message"
                                        onChange={(e) => handleRejectChange(e)}
                                      />
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose1} color="primary">
                                      Annuler
                                    </Button>
                                    <Button
                                      onClick={(e) => SubmitReject(e)}
                                      color="primary"
                                      autoFocus
                                    >
                                      Comfirmer
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                {/* Close Dialog  */}
                                <Dialog
                                  open={closeDialog}
                                  onClose={handleClose1}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'Information de rejection'}
                                  </DialogTitle>
                                  <DialogContent>
                                    {status ? (
                                      <p className={classes.alert}>
                                        <WarningIcon /> Attention cette reclamation
                                        est déja saisie
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                    <DialogContentText id="alert-dialog-description">
    
                                      <TextField
                                        label="Message"
                                        fullWidth
                                        multiline
                                        value={closeData.message}
                                        name="message"
                                        onChange={(e) => handleCloseChange(e)}
                                      />
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose1} color="primary">
                                      Annuler
                                    </Button>
                                    <Button
                                      onClick={(e) => submitCloseClaim(e)}
                                      color="primary"
                                      autoFocus
                                    >
                                      Comfirmer
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </td>
                            </tr>
                          ))
                        : claim.products && claim.products.length 
                          ? claim.products.map((product) => (
                            <tr>
                              <td>{claim.reclamationNumber}</td>
                              <td>{product.name}</td>
                              <td>{showPriceIncrease && product.increasedTotalPrice && product.totalPrice?.value !== product?.increasedTotalPrice?.value
                                ? `🍴: ${product.totalPrice?.text} 👨‍🦰:${product?.increasedTotalPrice?.text}` 
                                : `${product.totalPrice?.text}`}
                              </td>
                              <td>
                                {TranslateTypes(claim.type)}
                                <br />
                                {translateClaim(claim.subType)}
                              </td>
                              <td>
                                <Button
                                  onClick={handleMessageOpen('body', claim.message)}
                                >
                                  <RateReviewIcon />
                                </Button>
                                <Dialog
                                  open={open2}
                                  onClose={handleMessageClose}
                                  scroll={scroll}
                                  aria-labelledby="scroll-dialog-title"
                                  aria-describedby="scroll-dialog-description"
                                >
                                  <DialogTitle id="scroll-dialog-title">
                                    Message de la Reclamation
                                  </DialogTitle>
                                  <DialogContent dividers={scroll === 'paper'}>
                                    <DialogContentText
                                      id="scroll-dialog-description"
                                      ref={descriptionElementRef}
                                      tabIndex={-1}
                                    >
                                      {description}
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={handleMessageClose}
                                      color="primary"
                                    >
                                      Fermer
                                    </Button>
                                  </DialogActions>
                                </Dialog>{' '}
                              </td>
    
                              <td>
                                {claim.imagePath ?<img
                                  src={`${process.env.REACT_APP_IMAGES_URL}${claim.imagePath}`}
                                  width="350px"
                                  alt="Lyveat"
                                />:<div></div>}
                              </td>
                              <td>{TranslateStatus(claim.status)}</td>
                              <td>
                                {claim.status === config.status.pending.value ?<div><Button onClick={(e) => handleClickOpen(claim)}>
                                  <EuroIcon /> Rembourser
                                </Button>
                                <Button
                                  style={{ color: 'red' }}
                                  onClick={(e) =>
                                    handleClickOpen1(
                                      claim._id || claim.id,
                                      claim.status
                                    )
                                  }
                                >
                                  <CloseIcon style={{ color: 'red' }} /> Refuser
                                </Button>
                                <Button
                                  style={{ color: 'orange' }}
                                  onClick={(e) =>
                                    OpenCloseDialog(
                                      claim._id || claim.id,
                                      claim.status
                                    )
                                  }
                                >
                                  <CancelIcon style={{ color: 'orange' }} /> Fermer
                                </Button>
                                </div>:<Button
                                    style={{ color: 'blue' }}
                                    onClick={(e) => handledetailsOpen(claim)}
                                  >
                                    <HelpOutlineIcon /> Details
                                  </Button>}
                              
                                
                                {/********************Dialog Refund***************************** */}
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'Remboursement sur la Reclamation '}
                                  </DialogTitle>
                                  <DialogContent>
                                    {status ? (
                                      <p className={classes.alert}>
                                        <WarningIcon /> Attention cette reclamation
                                        est déja saisie
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                    <DialogContentText id="alert-dialog-description">
                                      <span style={{ paddingRight: '20px' }}>
                                        Remboursement :
                                      </span>
                                      <Select
                                        value={response}
                                        onChange={(e) => handlechange(e)}
                                        name="response"
                                      >
                                        <MenuItem
                                          value={
                                            !loading && config.status.refunded.value
                                          }
                                        >
                                          integral{' '}
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            !loading &&
                                            config.status.partially_refunded.value
                                          }
                                        >
                                          Partiel
                                        </MenuItem>
                                      </Select>
                                      {response ===
                                      ReclamationEnums.ReclamationStatuses
                                        .PARTIAL ? (
                                        <div>
                                          <div>
                                            <FormControl
                                              fullWidth
                                              className={classes.margin}
                                            >
                                              <InputLabel htmlFor="standard-adornment-amount">
                                                Montant
                                              </InputLabel>
                                              <Input
                                                id="standard-adornment-amount"
                                                type="number"
                                                value={refundedAmount}
                                                name="refundedAmount"
                                                onChange={(e) => handlechange(e)}
                                                startAdornment={
                                                  <InputAdornment position="start">
                                                    €
                                                  </InputAdornment>
                                                }
                                              />
                                            </FormControl>
                                            <div>
                                              <span
                                                style={{ paddingRight: '20px' }}
                                              >
                                                Mode :
                                              </span>
                                              <Select
                                                value={refundType}
                                                onChange={(e) => handlechange(e)}
                                                name="refundType"
                                              >
                                                <MenuItem
                                                  value={refundTypes.promo_code}
                                                >
                                                  Code Promo{' '}
                                                </MenuItem>
                                                <MenuItem
                                                  value={refundTypes.monetary}
                                                >
                                                  Monétaire
                                                </MenuItem>
                                              </Select>
                                              <br />
                                              Saisir une Regulation :{' '}
                                              <RegulationContainer
                                                order={claim.orderId}
                                                classes={classes}
                                                claim={true}
                                                regulationToEdit={regulationToEdit}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <span style={{ paddingRight: '20px' }}>
                                            Mode :
                                          </span>
                                          <Select
                                            value={refundType}
                                            onChange={(e) => handlechange(e)}
                                            name="refundType"
                                          >
                                            <MenuItem
                                              value={refundTypes.promo_code}
                                            >
                                              Code Promo{' '}
                                            </MenuItem>
                                            <MenuItem value={refundTypes.monetary}>
                                              Monétaire
                                            </MenuItem>
                                          </Select>
                                          <br />
                                          Saisir une Regulation :{' '}
                                          <RegulationContainer
                                            order={claim.orderId}
                                            classes={classes}
                                            claim={true}
                                            regulationToEdit={regulationToEdit}
                                          />
                                        </div>
                                      )}
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                      Annuler
                                    </Button>
                                    <Button
                                      onClick={(e) => SubmitForm(e)}
                                      color="primary"
                                      autoFocus
                                    >
                                      Comfirmer
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                {/* Dialog Reject */}
                                <Dialog
                                  open={open1}
                                  onClose={handleClose1}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'Information de rejection'}
                                  </DialogTitle>
                                  <DialogContent>
                                    {status ? (
                                      <p className={classes.alert}>
                                        <WarningIcon /> Attention cette reclamation
                                        est déja saisie
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                    {/*  <div style={{ paddingBottom: '20px' }}>
                                      {rejectData.image && (
                                        <img
                                          src={imagePreview}
                                          style={{ width: '300px' }}
                                          alt="Claim"
                                        />
                                      )}
                                      <div style={{ paddingLeft: '37%' }}>
                                        <UploadButton
                                          title="Photo"
                                          onFileSelect={handleImageSelect}
                                        />
                                      </div>
                                    </div> */}
                                    <DialogContentText id="alert-dialog-description">
                                      <span style={{ paddingRight: '20px' }}>
                                        Templates:{' '}
                                      </span>
                                      <Select
                                        //value={typeRemboursement}
                                        onChange={(e) => handleRejectChange(e)}
                                        name="template"
                                        value={template}
                                      >
                                        <MenuItem value={'Remboursement refusé'}>
                                        Remboursement refusé{' '}
                                        </MenuItem>
                                      {/*  <MenuItem value={'Problème lyveat : '}>
                                          Probleme lyveat
                                        </MenuItem> */}
                                      </Select>
    
                                      <TextField
                                        label="Description"
                                        fullWidth
                                        multiline
                                        value={rejectData.message}
                                        name="message"
                                        onChange={(e) => handleRejectChange(e)}
                                      />
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose1} color="primary">
                                      Annuler
                                    </Button>
                                    <Button
                                      onClick={(e) => SubmitReject(e)}
                                      color="primary"
                                      autoFocus
                                    >
                                      Comfirmer
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                {/* Close Dialog  */}
                                <Dialog
                                  open={closeDialog}
                                  onClose={handleClose1}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'Information de rejection'}
                                  </DialogTitle>
                                  <DialogContent>
                                    {status ? (
                                      <p className={classes.alert}>
                                        <WarningIcon /> Attention cette reclamation
                                        est déja saisie
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                    <DialogContentText id="alert-dialog-description">
    
                                      <TextField
                                        label="Message"
                                        fullWidth
                                        multiline
                                        value={closeData.message}
                                        name="message"
                                        onChange={(e) => handleCloseChange(e)}
                                      />
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose1} color="primary">
                                      Annuler
                                    </Button>
                                    <Button
                                      onClick={(e) => submitCloseClaim(e)}
                                      color="primary"
                                      autoFocus
                                    >
                                      Comfirmer
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </td>
                            </tr>
                          ))
                          : <tr>
                          <td>{claim.reclamationNumber}</td>
                          <td>Aucun Produit</td>
                          <td>--</td>
                          <td> {TranslateTypes(claim.type)}
                            <br />
                            {translateClaim(claim.subType)}</td>
                         <td><Button
                              onClick={handleMessageOpen('body', claim.message)}
                            >
                              <RateReviewIcon />
                            </Button>
                            <Dialog
                              open={open2}
                              onClose={handleMessageClose}
                              scroll={scroll}
                              aria-labelledby="scroll-dialog-title"
                              aria-describedby="scroll-dialog-description"
                            >
                              <DialogTitle id="scroll-dialog-title">
                                Message de la Reclamation
                              </DialogTitle>
                              <DialogContent dividers={scroll === 'paper'}>
                                <DialogContentText
                                  id="scroll-dialog-description"
                                  ref={descriptionElementRef}
                                  tabIndex={-1}
                                >
                                  {description}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleMessageClose}
                                  color="primary"
                                >
                                  Fermer
                                </Button>
                              </DialogActions>
                            </Dialog>
                            </td>
                            <td></td>
                            <td>{TranslateStatus(claim.status)}</td>
                            <td>
                            {claim.status === config.status.pending.value ?<div><Button onClick={(e) => handleClickOpenParial(claim)}>
                              <EuroIcon /> Rembourser
                            </Button>
                            <Button
                              style={{ color: 'red' }}
                              onClick={(e) =>
                                handleClickOpen1(
                                  claim._id || claim.id,
                                  claim.status
                                )
                              }
                            >
                              <CloseIcon style={{ color: 'red' }} /> Refuser
                            </Button>
                            <Button
                              style={{ color: 'orange' }}
                              onClick={(e) =>
                                OpenCloseDialog(
                                  claim._id || claim.id,
                                  claim.status
                                )
                              }
                            >
                              <CancelIcon style={{ color: 'orange' }} /> Fermer
                            </Button></div>:<Button
                                style={{ color: 'blue' }}
                                onClick={(e) => handledetailsOpen(claim)}
                              >
                                <HelpOutlineIcon /> Details
                              </Button>}
                           
                            <Dialog open={openParialDialog}
                              onClose={handleCloseParial}>
                              <DialogContent>
                                 <DialogContentText id="alert-dialog-description">
                                  <span style={{ paddingRight: '20px' }}>
                                    Remboursement Partiel
                                  </span>
                                    <div>
                                      <div>
                                        <div hidden={true}>
                                        <Select
                                    value={response}
                                    onChange={(e) => handlechange(e)}
                                    name="response"
                                   
                                  >
                                  <MenuItem value={ReclamationEnums.ReclamationStatuses
                                      .PARTIAL}>partiel</MenuItem></Select>
                                        </div>
                                      
                                        <FormControl
                                          fullWidth
                                          className={classes.margin}
                                        >
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            Montant
                                          </InputLabel>
                                          <Input
                                            id="standard-adornment-amount"
                                            type="number"
                                            value={refundedAmount}
                                            name="refundedAmount"
                                            onChange={(e) => handlechange(e)}
                                            startAdornment={
                                              <InputAdornment position="start">
                                                €
                                              </InputAdornment>
                                            }
                                          />
                                        </FormControl>
                                        <div>
                                          <span
                                            style={{ paddingRight: '20px' }}
                                          >
                                            Mode :
                                          </span>
                                          <Select
                                            value={refundType}
                                            onChange={(e) => handlechange(e)}
                                            name="refundType"
                                          >
                                            <MenuItem
                                              value={refundTypes.promo_code}
                                            >
                                              Code Promo{' '}
                                            </MenuItem>
                                            <MenuItem
                                              value={refundTypes.monetary}
                                            >
                                              Monétaire
                                            </MenuItem>
                                          </Select>
                                          <br />
                                          Saisir une Regulation :{' '}
                                          <RegulationContainer
                                            order={claim.orderId}
                                            classes={classes}
                                            claim={true}
                                            regulationToEdit={regulationToEdit}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                   
                                </DialogContentText>
                                </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                  Annuler
                                </Button>
                                <Button
                                  onClick={(e) => SubmitForm(e)}
                                  color="primary"
                                  autoFocus
                                >
                                  Confirmer
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Dialog Reject */}
                            <Dialog
                              open={open1}
                              onClose={handleClose1}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {'Information de rejection'}
                              </DialogTitle>
                              <DialogContent>
                                {status ? (
                                  <p className={classes.alert}>
                                    <WarningIcon /> Attention cette reclamation
                                    est déja saisie
                                  </p>
                                ) : (
                                  <div></div>
                                )}
                                {/*  <div style={{ paddingBottom: '20px' }}>
                                  {rejectData.image && (
                                    <img
                                      src={imagePreview}
                                      style={{ width: '300px' }}
                                      alt="Claim"
                                    />
                                  )}
                                  <div style={{ paddingLeft: '37%' }}>
                                    <UploadButton
                                      title="Photo"
                                      onFileSelect={handleImageSelect}
                                    />
                                  </div>
                                </div> */}
                                <DialogContentText id="alert-dialog-description">
                                  <span style={{ paddingRight: '20px' }}>
                                    Templates:{' '}
                                  </span>
                                  <Select
                                    //value={typeRemboursement}
                                    onChange={(e) => handleRejectChange(e)}
                                    name="template"
                                    value={template}
                                  >
                                    <MenuItem value={'Remboursement refusé'}>
                                    Remboursement refusé{' '}
                                    </MenuItem>
                                    {/* <MenuItem value={'Problème lyveat : '}>
                                      Probleme lyveat
                                    </MenuItem> */}
                                  </Select>

                                  <TextField
                                    label="Description"
                                    fullWidth
                                    multiline
                                    value={rejectData.message}
                                    name="message"
                                    onChange={(e) => handleRejectChange(e)}
                                  />
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose1} color="primary">
                                  Annuler
                                </Button>
                                <Button
                                  onClick={(e) => SubmitReject(e)}
                                  color="primary"
                                  autoFocus
                                >
                                  Comfirmer
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Close Dialog  */}
                            <Dialog
                              open={closeDialog}
                              onClose={handleClose1}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {'Information de rejection'}
                              </DialogTitle>
                              <DialogContent>
                                {status ? (
                                  <p className={classes.alert}>
                                    <WarningIcon /> Attention cette reclamation
                                    est déja saisie
                                  </p>
                                ) : (
                                  <div></div>
                                )}
                                <DialogContentText id="alert-dialog-description">

                                  <TextField
                                    label="Message"
                                    fullWidth
                                    multiline
                                    value={closeData.message}
                                    name="message"
                                    onChange={(e) => handleCloseChange(e)}
                                  />
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose1} color="primary">
                                  Annuler
                                </Button>
                                <Button
                                  onClick={(e) => submitCloseClaim(e)}
                                  color="primary"
                                  autoFocus
                                >
                                  Comfirmer
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </td>
  
                          </tr>
                    )}

                  </table>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
      {/* Details Dialog */}
      <Dialog
        open={openDetails}
        onClose={handledetailsClose}
        aria-labelledby="alert-dialog-title1"
        aria-describedby="alert-dialog-description1"
      >
        <DialogTitle id="alert-dialog-title1">
          {'Details de Remboursement/rejection'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description1"
            style={{ color: 'black' }}
          >
            <b>Date mise à jour :</b>{' '}
            {moment(Details.updated_at)
              .locale('fr')
              .format('Do MMMM YYYY HH:mm')}{' '}
            <br />
            {!loading && Details.status !== config?.status?.rejected?.value && Details.status !== config?.status?.closed?.value ? (
              <div>
                <b>Action faite par:</b> {Details?.responseInfo?.adminFullName}{' '}
                <br />
                <b>Total remboursement:</b>{' '}
                {Details?.responseInfo?.refundedAmount} € <br />
                {Details?.responseInfo?.promoCodeId   ? (
                  <div>
                    <b>Type de remboursement :</b> Code promo crée <br />
                    {Details?.responseInfo?.refundType === 'monetary' ?<div><b style={{color:'red'}}>Ticket Restaurant expiré</b></div>:<div></div>}
                    {Details?.responseInfo?.cbRefundedAmount  ?<div><b>Remboursement CB :</b> {Details?.responseInfo?.cbRefundedAmount} €</div>:<div></div>} 
                  </div>
                ) :
                (
                  <div>
                    <b>Remboursement titre restaurant:</b>{' '}
                    {Details?.responseInfo?.ticketRefundedAmount} € <br />
                    {Details?.orderId?.mealVoucherInfo ? (
                      <b>Type ticket:</b>
                    ) : null}
                    {Details?.orderId?.mealVoucherInfo
                      ? Details?.orderId?.mealVoucherInfo.paymentType
                      : null}{' '}
                    <br />
                    <b>Remboursement CB:</b>{' '}
                    {Details?.responseInfo?.cbRefundedAmount} € <br />{' '}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <b>Message:</b> {Details?.responseInfo?.message} <br />
                <b>Action faite par un:</b>{' '}
                {Details?.responseInfo?.adminFullName} <br />
              </div>
            )}
            <br />
            {Details?.responseInfo?.imagePath ? (
              <div>
                <img
                  src={`${process.env.REACT_APP_IMAGES_URL}${Details?.responseInfo.imagePath}`}
                  alt="lyveat"
                  width="300px"
                />
              </div>
            ) : (
              <div></div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handledetailsClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
const mapStateToProps = (state) => ({
  Claims: state.Claims
})
export default connect(mapStateToProps, { getConfig, resetError })(ClaimDetail)
