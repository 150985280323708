import React from 'react'
import { orderDeliveryType } from './orderStatus'
import { Switch } from '@material-ui/core'

export const renderDeliveryIcon = (deliveryType, style = {}) => {
  style = { width: 37, height: 37, ...style }
  const deliveryImage = 'livraison_transparent.png'
  const pickupImage = 'emporter_transparent.png'
  if (deliveryType === orderDeliveryType.PICKUP) {
    return (
      <img
        src={require(`../../assets/${pickupImage}`)}
        style={style}
        alt="commande à emporter"
      />
    )
  }
  return (
    <img
      src={require(`../../assets/${deliveryImage}`)}
      style={style}
      alt="commande à livrer"
    />
  )
  }

  export function parseToSwitch(value, fct) {
    return (
      <Switch
        checked={value}
        onChange={() => fct(!value)}
      />
    )
  }