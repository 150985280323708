import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

import OrderListComponent from './OrderList/OrderList'
import OrderDetails from 'components/OrderDetails'

const OrderPageContainer = function ({ city, classes }) {
  return (
    <div className={classes.mainContainer}>
      <Switch>
        <Route
          path="/commandes"
          exact
          component={(props) =>
            city ? (
              <OrderListComponent city={city} classes={classes} {...props} />
            ) : (
              'Chargement ...'
            )
          }
        />
        <Route path="/commandes/:orderId" component={OrderDetails}></Route>
      </Switch>
    </div>
  )
}

export default withStyles(styles)(OrderPageContainer)
