import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { selectors as requestCitiesSelectors } from 'core.modules/requests/reducer'
import { Link, Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import RequestCityCreate from 'components/RequestCityCreate'
import { requestStates } from 'core.utils/requestStates'
import List from 'core.ui/List'
import Dialog from 'core.ui/Dialog'
import Snackbar from 'core.ui/Snackbar'
import { fetchRequestCityRequested, deleteRequestCityRequested } from '../../core.modules/requests/reducer'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}


class RequestCityList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      indexToDelete: null,
      currentId: null,
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidMount() {
    this.props.fetchRequestCities()
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleDelete = id => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: id
    })
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  confirmDelete = () => {
    this.setState({ waitingForRequest: true })
    this.props.deleteRequestCity(this.state.idToDelete)
    this.closeDeleteDialog()
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  render() {
    const { classes, history } = this.props
    const { openDeleteDialog } = this.state
    const { allRequestCities } = this.props
    const cities = allRequestCities.map((city) => [city.name])
    const ids = allRequestCities.map((city) => city._id)
    return (
      <Router>
        <Switch>
          <Route path={"/candidatures/villes/new"} exact>
            <RequestCityCreate onSuccess={this.handleSuccess} history={history} />
          </Route>
          <Route path="/candidatures/villes" exact>
            <React.Fragment>
              <div style={styles.mainListContainer}>
                <Link className={classes.addButton} to="/candidatures/villes/new">
                  <Button variant="contained" color="secondary">
                    Ajouter une ville pour les demandes
                  </Button>
                </Link>
              </div>
              <List
                list={cities}
                columns={['Nom']}
                ids={ids}
                actions={{
                  handleDelete: this.handleDelete,
                }}
                emptyMessage="Aucune ville pour les demandes"
              />
              <Dialog
                open={openDeleteDialog}
                dialogText="Confirmer la suppression"
                dialogTitle="Confirmation"
                cancelButtonCaption="Annuler"
                confirmButtonCaption="Ok"
                onCancel={this.closeDeleteDialog}
                onValidation={this.confirmDelete}
              />
              <Snackbar
              open={this.state.openSnackBar}
              onClose={this.handleCloseSnackbar}
              severity="error"
              message={this.props.errorMessage}
              />
            </React.Fragment>
          </Route>
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => ({
  allRequestCities: requestCitiesSelectors.getAllRequestCities(state),
  currentRequestState: requestCitiesSelectors.getRequestState(state),
  errorMessage: requestCitiesSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  fetchRequestCities: () => {
    dispatch(fetchRequestCityRequested())
  },
  deleteRequestCity: (requestCityId) => {
    dispatch(deleteRequestCityRequested(requestCityId))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(RequestCityList)
