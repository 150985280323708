import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import {
  selectors as currentRestaurantProductsSelectors,
  createRestaurantProductRequested
} from 'core.modules/currentRestaurant/products/reducer'
import { selectors as currentRestaurantSelectors } from 'core.modules/currentRestaurant/infos/reducer'
import { requestStates } from 'core.utils/requestStates'

import Snackbar from 'core.ui/Snackbar'
import ProductForm from 'components/ProductForm'
import { discardTemplate } from "../../core.modules/Template/Actions";
const styles = {}

class ProductCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmitForm = productInfos => {
    const { currentRestaurantId, createProduct,restoreTemplateValue } = this.props
    createProduct(currentRestaurantId, productInfos)
    this.setState({ waitingForRequest: true })
    restoreTemplateValue()
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const { currentRequestState } = this.props
    return (
      <React.Fragment>
        <ProductForm
          onSubmitForm={this.handleSubmitForm}
          onCancel={this.handleCancel}
          buttonLabel="Créer le produit"
          mainTitle="Créer un produit"
          showLoading={currentRequestState === requestStates.PENDING}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message="Erreur pendant la création du produit"
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurantId: currentRestaurantSelectors.getId(state),
  currentRequestState: currentRestaurantProductsSelectors.getRequestState(state)
})

const mapDispatchToProps = dispatch => ({
  createProduct: (restaurantId, productInfos) => {
    dispatch(createRestaurantProductRequested(restaurantId, productInfos))
  },
  restoreTemplateValue:()=>{
    dispatch(discardTemplate())
  }
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ProductCreate)
