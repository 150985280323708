import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { isEmpty, isEqual } from 'lodash'

import ButtonWithIcon from 'core.ui/ButtonWithIcon'
import Horaire from 'components/Horaire'

const styles = {
  mainContainer: {
    width: '550px',
    margin: '5px 0',
    padding: '5px',
    border: 'lightgrey 1px solid',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  jour: {
    height: '40px',
    margin: '11px 5px',
    width: '200px'
  },
  creneaux: {
    display: 'flex',
    flexDirection: 'column'
  },
  ajouter: {
    backgroundColor: 'lightgreen',
    alignSelf: 'flex-start'
  }
}

export class Horaires extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      horaires: []
    }
  }

  componentDidMount() {
    const { value = [] } = this.props
    this.setState({ horaires: value })
  }

  componentDidUpdate(prevProps, prevState) {
    const { value, onChange } = this.props
    const { horaires } = this.state
    if (value !== prevProps.value) {
      this.setState({ horaires: this.props.value })
    } else if (horaires !== prevState.horaires && !isEqual(horaires, value)) {
      onChange({
        target: {
          value: horaires
        }
      })
    }
  }

  handleJourChange = e => {
    const { value } = e.target
    this.setState({ jour: value })
    //   , () => {
    //   this.props.onChange({
    //     target: {
    //       value: this.state
    //     }
    //   })
    // })
  }

  handleHorairesChange = index => value => {
    const { horaires } = this.state
    this.setState({
      horaires: [
        ...horaires.slice(0, index),
        value,
        ...horaires.slice(index + 1)
      ]
    })
  }

  handleAddHoraire = () => {
    const { horaires } = this.state
    const newHoraire = {
      jour: 'Lundi',
      statut: 'true',
      creneaux: []
    }
    this.setState({ horaires: [...horaires, newHoraire] })
  }

  handleDeleteHoraire = index => () => {
    const { horaires } = this.state
    this.setState({
      horaires: [...horaires.slice(0, index), ...horaires.slice(index + 1)]
    })
  }

  render() {
    const { classes, className } = this.props
    const { horaires } = this.state
    return (
      <div
        className={classnames(
          'horaires-main-container',
          classes.mainContainer,
          className
        )}
      >
        <div className="creneaux">
          {isEmpty(horaires) ? <p>Aucun horaire</p> : this.renderHoraires()}
        </div>
        <ButtonWithIcon
          className={classes.ajouter}
          featherIcon="Plus"
          iconSize="32"
          onClick={this.handleAddHoraire}
        />
      </div>
    )
  }

  renderHoraires = () => {
    const { horaires } = this.state
    return horaires.map((horaire, index) => {
      return (
        <Horaire
          key={index}
          value={horaire}
          onChange={this.handleHorairesChange(index)}
          onDelete={this.handleDeleteHoraire(index)}
        />
      )
    })
  }
}

export default withStyles(styles)(Horaires)
