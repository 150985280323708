import React, { useState,useEffect } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import Dialog from 'core.ui/Dialog';
import List from 'core.ui/List';
import { getConcernedPartyTranslated, getDefaultRegulation, getPaidToTranslated } from '../../core.utils/parser/regulationEnums';
import RegulationDialog from '../RegulationForm';

export default function RegulationList({
	regulations,
	order,
	orderId,
	onSubmitSuccess,
	deleteRegulation,
	updateRegulation,
	products
}) {
	
	const [ modalPayload, setModalPayload ] = useState({ isVisible: false, regulationId: null });
	const [ isRegulationFormVisible, setIsRegulationFormVisible ] = useState(false);
	const [ regulationToEdit, setRegulationToEdit ] = useState(null);
	

	const makeRegulationList = () => {
		return regulations.map(({ concernedParty, value, reason, paidTo, products, cart }) => {

			let prods = [], options = [], supplements = []

			if (cart && cart.length) {
				prods = cart.map(item => item.title)
				options =cart.map(item => item.selections.map(selection => selection.title) )
			} else {
				options =products.map((p) => p.options.map((o)=>o.name));
				prods = products.map((p) => p.name);
				supplements = products.map((p) => p.supplements.map((o)=>o.name))
			}
			
			return [
				getConcernedPartyTranslated(concernedParty),
				`${value} €`,
				prods.join(prods.length > 1 ? ', ' : ' '),
				supplements.join(supplements.length >1 ?',' : ' '),
				options.join(options.length >1 ?', ' : ' '),
        reason,
        getPaidToTranslated(paidTo)
			];
		});
	};
	
	const handleDelete = (regulationId) => {
		setModalPayload((currentValue) => ({
			isVisible: !currentValue.isVisible,
			regulationId
		}));
	};

	const handleEdit = (regulationId) => {
		const index = regulations.map((r) => r._id || r.id).indexOf(regulationId);
		setRegulationToEdit(regulations[index]);
		setIsRegulationFormVisible(true);
	};

	const toggleRegulationDialog = () => {
    setRegulationToEdit(getDefaultRegulation());
		setIsRegulationFormVisible((currentValue) => !currentValue);
	};

	if (!regulations) {
		return <CircularProgress style={{ marginLeft: '50%', marginRight: '50%' }} color="secondary" />;
	}

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant="h4">Regulations</Typography>
				<Button onClick={toggleRegulationDialog} variant="contained" color="secondary" style={{ width: '250px' }}>
					Ajouter une régulation
				</Button>
				
				<RegulationDialog
					updateRegulation={updateRegulation}
					regulationToEdit={regulationToEdit}
					isVisible={isRegulationFormVisible}
					toggleDialog={toggleRegulationDialog}
					order={order}
					orderId={orderId}
					onSubmitSuccess={onSubmitSuccess}
					products={products}
				/>
			</div>

			<div style={{ width: '100%' }}>
				<List
					listMainContainerProp={{ width: '100%' }}
					columns={[ 'Parti Concerné', 'Montant', 'Produits','supplements','options', 'Motif', 'Payé à', '' ]}
					list={makeRegulationList()}
					emptyMessage="Cette commande n'a pas de regulations"
					ids={regulations.map((o) => o._id || o.id)}
					// TODO : remove comment from handle edit
					actions={{
						handleDelete,
						//handleEdit  
					}}
				/>
			</div>
			<Dialog
				open={modalPayload.isVisible}
				dialogText="Confirmez la suppression de la régulation"
				dialogTitle="Confirmation"
				cancelButtonCaption="Annuler"
				confirmButtonCaption="Ok"
				onCancel={() => handleDelete()}
				onValidation={() => {
					deleteRegulation(modalPayload.regulationId);
					handleDelete();
				}}
			/>
		</div>
	);
}
