import React from 'react';
import { FormLabel, Checkbox, FormControl, Select, InputLabel, MenuItem, TextField, InputAdornment, Button, Switch, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ButtonWithIcon from '../../core.ui/ButtonWithIcon';
import {
	DefaultReasons,
	getConcernedPartyTranslated,
	getPaidToTranslated,
	PaidTo,
	ConcernedParty,
	REGULATION_TYPE_AMOUNT,
	REGULATION_TYPE_PRODUCT,
	REGULATION_TYPE_SUPPLEMENTS
} from '../../core.utils/parser/regulationEnums';
import SecondRiderInput from "./SecondRiderInput"
import { product } from "../../core.services/api";
import { useState, useEffect } from 'react';

export default function RegulationForm(
	{ newRegulation,
		updateNewRegulation,
		order,
		errorMessage,
		products,
		claim
	}
) {

	const [mappingArray, setMappingArray] = useState('products')
	const [supplement, setSupplements] = useState([])
	const [options, setOptions] = useState([])
	const [supplements, setSupp] = useState([])
	const [OptionChoice, setOptionChoice] = useState([])
	const [optionName, setOptionName] = useState('')
	const [switchToRestaurant, setSwitchToRestaurant] = useState({
		checkedR: false
	})

	const handleChange = (key) => (e, value) => {
		const newValue = { ...newRegulation }
		const valuee = e?.target?.value || value;
		let isV2 = false

		if (order.cart && order.cart.length) {
			isV2 = true
			setMappingArray('cart')
		}

		newValue[key] = valuee;
		if (key === "type" && valuee === REGULATION_TYPE_PRODUCT) {
			if (isV2) {
				const newItemIndex = newRegulation.cart && newRegulation.cart.length || 0
				const newItem = order.cart[newItemIndex]
				newValue["cart"] = [...newValue.cart || [], newItem]
				newValue["value"] = (newValue.cart.reduce((total, current) => current.price.totalPrice.value + total, 0)) / 100
			} else {
				const newProdIndex = newRegulation.products && newRegulation.products.length || 0
				const newProduct = order.products[newProdIndex]
				newValue["products"] = [...newValue.products || [], newProduct]
				newValue["value"] = (newValue.products.reduce((total, current) => current.totalPrice.value + total, 0)) / 100
			}
		} else if (key === "type") {
			if (isV2) newValue["cart"] = []
			newValue["products"] = []
		} else if (key === "concernedParty") {
			if (newRegulation.paidTo !== PaidTo.SECOND_RIDER || valuee !== ConcernedParty.RIDER) {
				newValue[`${valuee}Id`] = order[`${valuee}Id`]?._id || order[`${valuee}Id`]?.id
			}
			/* if (valuee === ConcernedParty.RIDER) {
					newValue["type"] = REGULATION_TYPE_AMOUNT
			} */
		}
		updateNewRegulation(newValue);
	}

	const getDefaultRegulationReasons = () => {
		if (!newRegulation?.concernedParty) {
			return [];
		}

		const defaultConcernedPartyReasons = DefaultReasons[newRegulation?.concernedParty] || [];
		return [...DefaultReasons.all, ...defaultConcernedPartyReasons];
	}

	const onSwitchToRestoChange = (event) => {
		setSwitchToRestaurant({ ...switchToRestaurant, [event.target.name]: event.target.checked })
		updateNewRegulation({
			...newRegulation,
			productsFromRestaurant: event.target.checked,
			products: [],
			value: 0
		})
	}

	const onSelectProductRestaurant = (idx) => async (e, value) => {
		const valuee = e.target.value || value
		const index = products.map(p => p._id).indexOf(valuee)
		var newProducts = [...newRegulation.products]

		if (e.target.name === 'Products') {

			const suppProd = await product.PopulatedProducts(value.key.slice(2))
			setSupplements(suppProd)
			if (index > -1) {

				const { tax, price, _id, checkoutSystemId } = products[index]

				newProducts[idx] = {
					_id: _id,
					name: value.props.children[0],
					checkoutSystemInfo: { checkoutSystemId },
					price,
					supplements,
					options,
					tax,
					totalPrice: price

				}

			}

		}
		const newProd = [...newProducts]
		if (e.target.name === 'supplementsDetails') {
			var x = e.target.value


			var supp = { ...newProd[idx], supplements: [e.target.value] }

			newProducts[idx] = supp



		}
		const newopt = [...newProducts]
		if (e.target.name === 'optionsDetails') {

			setOptionChoice(supplement.optionsDetails.filter(o => o.name === e.target.value))
			setOptionName(e.target.value)
			let opt = { ...newopt[idx], options: [{ name: e.target.value }] }
			newProducts[idx] = opt

		}
		const newChoice = [...newProducts]
		if (e.target.name === 'optionsName') {
			var x = e.target.value
			let opt = {
				...newChoice[idx], options: [{
					name: optionName, _id: x._id, price: x.price,
					value: x.type,
					checkoutSystemInfo: {
						checkoutSystemId: x.checkoutSystemId,
						isAbstract: x.isAbstract
					}
				}]
			}
			newProducts[idx] = opt
		}

		updateNewRegulation({
			...newRegulation,
			products: newProducts,
			value: newProducts.reduce((total, current) => current.price + total, 0)
		})

	}

	const onSelectProduct = (idx) => (e) => {
		const product = order.products.find( prodElem => prodElem._id === e.target.value)
		if (product) {
			const newProducts = [...newRegulation.products]
			newProducts[idx] = { ...product }

			updateNewRegulation({
				...newRegulation,
				products: newProducts,
				restaurantId: order.restaurantId,
				value: (newProducts.reduce((total, current) => current.totalPrice.value + total, 0)) / 100
			})
		}

	}

	const onSelectItem = (idx) => (e, value) => {
		const valuee = e.target.value

		const item = order.cart.find( itemElem => itemElem._id === valuee)
		if (item) {
			const newCart = [...newRegulation.cart]
			newCart[idx] = { ...item }

			updateNewRegulation({
				...newRegulation,
				cart: newCart,
				restaurantId: order.restaurantId,
				value: (newCart.reduce((total, current) => current.price.totalPrice.value + total, 0)) / 100
			})
		}
	}

	const onAddProduct = async () => {
		if (order.cart && order.cart.length) {
			const newItemIndex = newRegulation.cart && newRegulation.cart.length || 0
			const newItem = order.cart[newItemIndex]
			const newCart = [...newRegulation.cart, newItem]
			updateNewRegulation({
				...newRegulation,
				cart: newCart,
				value: (newCart.reduce((total, current) => current.price.totalPrice.value + total, 0)) / 100
			})
		} else {
			
			if (switchToRestaurant.checkedR) {
				const newProduct = products.find( prodElem => prodElem.haveStock && !prodElem.isArchived)
				newProduct.totalPrice = newProduct.price
				const newProducts = [...newRegulation.products, newProduct]

				const suppProd = await product.PopulatedProducts(newProduct._id)
				setSupplements(suppProd)

				updateNewRegulation({
					...newRegulation,
					products: newProducts,
					value: newProducts.reduce((total, current) => current.price + total, 0)
				})
			} else {
				const newProdIndex = newRegulation.products && newRegulation.products.length || 0
				const newProduct = order.products[newProdIndex]
				const newProducts = [...newRegulation.products, newProduct]
				updateNewRegulation({
					...newRegulation,
					products: newProducts,
					value: (newProducts.reduce((total, current) => current.totalPrice.value + total, 0)) / 100
				})
			}

		}

	}

	const onDeleteSelectedProduct = idx => {
		let newProducts = [...newRegulation.products]
		newProducts.splice(idx, 1)

		updateNewRegulation({
			...newRegulation,
			type: REGULATION_TYPE_PRODUCT,
			products: newProducts,
			value: (newProducts.reduce((total, current) => current.totalPrice.value + total, 0)) / 100
		})
	}

	const onDeleteSelectedItem = idx => {
		let newCart = [...newRegulation.cart ]
		newCart.splice(idx, 1)

		updateNewRegulation({
			...newRegulation,
			type: REGULATION_TYPE_PRODUCT,
			cart: newCart,
			value: (newCart.reduce((total, current) => current.price.totalPrice.value + total, 0)) / 100
		})
	}

	const onDeleteSelectedProductRestaurant = idx => {
		let newProducts = [...newRegulation.products ]
		newProducts.splice(idx, 1)

		updateNewRegulation({
			...newRegulation,
			type: REGULATION_TYPE_PRODUCT,
			products: newProducts,
			value: newProducts.reduce((total, current) => current.price + total, 0)
		})
	}

	const onChangeToPay = () => {
		updateNewRegulation({
			...newRegulation,
			toPay: !newRegulation.toPay,
		})
	}

	const onChangeCommission = () => {
		updateNewRegulation({
			...newRegulation,
			withoutCommission: !newRegulation.withoutCommission
		})
	}

	const getSelectProductValue = (regulation, orderProducts, idx) => {
		let selectedProductId
		if (regulation && regulation.products && regulation.products[idx] && regulation.products[idx].productId) {
			selectedProductId = regulation.products[idx]._id
		} else selectedProductId = orderProducts[idx]._id

		return selectedProductId
	}

	const getSelectedRestaurantProductValue = (regulation, products, idx) => {
		let selectedProductId
		if (regulation && regulation.products && regulation.products[idx] && regulation.products[idx]._id) {
			selectedProductId = regulation.products[idx]._id
		} else selectedProductId = products[idx]._id

		return selectedProductId
	}

	const getSelectItemValue = (regulation, cart, idx) => {
		let selectedItemId
		if (regulation && regulation.cart && regulation.cart[idx] && regulation.cart[idx]._id) {
			selectedItemId = regulation.cart[idx]._id
		} else selectedItemId = cart[idx]._id

		return selectedItemId
	}

	return (
		<div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
			<div style={{ width: '75%' }}>

				<FormControl required style={{ width: '100%', marginBottom: 15 }}>
					<InputLabel id="demo-simple-select-label">Parti concerné</InputLabel>
					<Select value={newRegulation?.concernedParty} onChange={handleChange('concernedParty')}>
						{Object.values(ConcernedParty).map((value) => (
							<MenuItem value={value}> {getConcernedPartyTranslated(value)} </MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl required style={{ marginBottom: 15, width: '100%' }}>
					<InputLabel id="demo-simple-select-label">Type de regulation</InputLabel>
					<Select value={newRegulation?.type} onChange={handleChange('type')}>
						<MenuItem value={REGULATION_TYPE_AMOUNT}>Montant (non commissioné)</MenuItem>
						{newRegulation?.concernedParty !== ConcernedParty.RIDER && <MenuItem value={REGULATION_TYPE_PRODUCT}>Produits</MenuItem>}
						{/*newRegulation?.concernedParty !== ConcernedParty.RIDER && <MenuItem value={REGULATION_TYPE_SUPPLEMENTS}>supplements</MenuItem>*/}
					</Select>
				</FormControl>

				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<TextField
						type="number"
						style={{ marginBottom: 15, width: '25%' }}
						value={newRegulation?.value}
						onChange={handleChange('value')}
						InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
						label="Montant "
					/>

					<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
						<Checkbox
							checked={newRegulation.toPay}
							onChange={onChangeToPay}
							disableRipple
						/>
						<FormLabel>À payer</FormLabel>
					</div>

					<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
						<Checkbox
							checked={!newRegulation.toPay}
							onChange={onChangeToPay}
							disableRipple
						/>
						<FormLabel>À déduire</FormLabel>
					</div>
					{newRegulation.type !== REGULATION_TYPE_AMOUNT && <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
						<Checkbox
							checked={newRegulation.withoutCommission}
							onChange={onChangeCommission}
							disableRipple
						/>
						<FormLabel>Sans commission</FormLabel>
					</div>}
				</div>

				{!claim && newRegulation?.type === REGULATION_TYPE_PRODUCT ?
					//TODO : remove diplay none
					<div style={{ marginLeft: '180px', display: 'none' }}> <FormLabel style={{ paddingRight: '10px' }}>  Produits Commande  </FormLabel><FormControl>

						<FormControlLabel
							control={
								<Switch
									checked={switchToRestaurant.checkedR}
									value={switchToRestaurant.checkedR}
									name='checkedR'
									onClick={onSwitchToRestoChange}
								/>
							}

						/>
					</FormControl> <FormLabel>  Produits Restaurant </FormLabel></div> : <div></div>}

				{newRegulation?.type === REGULATION_TYPE_PRODUCT &&
					newRegulation[mappingArray].map((prRegulation, idx) => (
						<div>
							{errorMessage && (!prRegulation?.totalPrice) && <p style={{ color: "red" }}>Sélectionnez un produit</p>}

							{(switchToRestaurant.checkedR && order?.products?.length) ? <div><div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControl required style={{ width: '100%', marginBottom: '20px' }}>
									<InputLabel id="demo-simple-select-label">Produit</InputLabel>
									<Select
										value={getSelectedRestaurantProductValue(newRegulation, products, idx)}
										onChange={onSelectProductRestaurant(idx)}
										name='Products'
									>
										{
										
										products.map((pr) => (
											<MenuItem key={pr?._id} value={pr?._id} disabled={pr?.haveStock === false || pr?.isArchived}>
												{pr?.name} - {pr?.price}€
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{newRegulation.products.length > 1 && <ButtonWithIcon featherIcon="Trash2" iconSize="22" onClick={() => onDeleteSelectedProductRestaurant(idx)} />}
							</div>
								<div>

									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<FormControl required style={{ width: '100%', marginBottom: '20px' }}>
											<InputLabel id="demo-simple-select-label">Supplements</InputLabel>
											<Select
												onChange={onSelectProductRestaurant(idx)}
												name='supplementsDetails'

											>
												{supplement?.supplementsDetails?.map((pr) => (
													<MenuItem key={pr?._id} value={pr}>
														{pr?.name} - {pr?.price}€
													</MenuItem>
												))}
											</Select>
										</FormControl>

									</div>
									<div>

										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<FormControl required style={{ width: '100%', marginBottom: '20px' }}>
												<InputLabel id="demo-simple-select-label">Options</InputLabel>
												<Select
													//value={options}
													onChange={onSelectProductRestaurant(idx)}
													name='optionsDetails'
												>
													{supplement?.optionsDetails?.map((pr) =>


														<MenuItem key={pr?._id} value={pr?.name}>
															{pr?.name}
														</MenuItem>

													)}
												</Select>
											</FormControl>
											<FormControl required style={{ width: '100%', marginBottom: '20px' }}>
												<InputLabel id="demo-simple-select-label">Choix</InputLabel>
												<Select
													//value={options}
													onChange={onSelectProductRestaurant(idx)}
													name='optionsName'
												>
													{OptionChoice?.map((pr) =>
														pr?.optionTypes?.map((op) =>
														(

															<MenuItem key={op?._id} value={op}>
																{op?.type} - {op?.price}€
															</MenuItem>
														)
														))}
												</Select>
											</FormControl>

										</div>
									</div>
								</div>
							</div> : (order.cart && order.cart.length)
								?
									<div>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<FormControl required style={{ width: '100%', marginBottom: '20px' }}>
												<InputLabel id="demo-simple-select-label">Produit</InputLabel>
												<Select
													value={getSelectItemValue(newRegulation, order?.cart, idx)}
													onChange={onSelectItem(idx)}
												>
													{order?.cart.map((itemElem) => (
														<MenuItem value={itemElem._id}>
															{`${itemElem.title} - ${itemElem.price.totalPrice.text}`}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											{newRegulation.cart && newRegulation.cart.length > 1 && <ButtonWithIcon featherIcon="Trash2" iconSize="22" onClick={() => onDeleteSelectedItem(idx)} />}
										</div>
									</div>
								:
									<div>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<FormControl required style={{ width: '100%', marginBottom: '20px' }}>
												<InputLabel id="demo-simple-select-label">Produit</InputLabel>
												<Select
													value={getSelectProductValue(newRegulation, order?.products, idx)}
													onChange={onSelectProduct(idx)}
												>
													{order?.products.map((pr) => (
															<MenuItem value={pr._id}>
																{`${pr.productId.name || pr.name} - ${pr.totalPrice.text}`}															
															</MenuItem>
													))}
												</Select>
											</FormControl>
											{newRegulation.products.length > 1 && <ButtonWithIcon featherIcon="Trash2" iconSize="22"  onClick={() => onDeleteSelectedProduct(idx)} />}
										</div>
									</div>
								}

						</div>

					))}

				{newRegulation?.type === REGULATION_TYPE_PRODUCT &&
					<Button onClick={onAddProduct} variant="contained" color="secondary" style={{ width: 250, marginBottom: 20 }} >
						Ajouter un produit
					</Button>
				}

				<Autocomplete
					freeSolo={true}
					defaultValue={newRegulation?.reason}
					options={getDefaultRegulationReasons()}
					style={{ width: '100%' }}
					onChange={handleChange('reason')}
					onInputChange={handleChange('reason')}
					renderInput={(params) => <TextField {...params} label="Motif *" variant="outlined" />}
				/>

				<FormControl style={{ width: '100%', marginTop: 15, marginBottom: 20 }}>
					<InputLabel>Payer à</InputLabel>
					<Select
						fullWidth={true}
						value={newRegulation?.paidTo}
						onChange={handleChange('paidTo')}>
						<MenuItem value=""> Aucun </MenuItem>
						{Object.values(PaidTo).map((value) =>
							<MenuItem value={value}> {getPaidToTranslated(value)} </MenuItem>
						)}
					</Select>
				</FormControl>

				{newRegulation.paidTo === PaidTo.SECOND_RIDER && <SecondRiderInput cityId={order?.cityId} newRegulation={newRegulation} updateNewRegulation={updateNewRegulation} />}
			</div>
		</div>
	);
}
