import React from 'react'

import { Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect, useDispatch } from 'react-redux'
import { checkifTemplate,assignRestaurants } from '../../core.modules/Template/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from 'react-select'
import Snackbar from 'core.ui/Snackbar'
import {
  fetchAllRestaurantsRequested
} from 'core.modules/restaurants/reducer'
import { selectors as restaurantsSelectors } from 'core.modules/restaurants/reducer'

const style = {
  h3: { textAlign: 'center' },
  button: { paddingTop: '10px' },
  autocmplete: { marginLeft: '40%' }
}
 
const GroupTemplate = ({ templates: { templates,err }, checkifTemplate,currentCityRestaurants,assignRestaurants,city}) => {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState([])
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState('Affectaion avec succes ')
  const [snackBarSeverity, setsnackBarSeverityMessage] = React.useState('success')
  
  React.useEffect(()=>{
    dispatch(fetchAllRestaurantsRequested())
  },[fetchAllRestaurantsRequested])
  React.useEffect(()=>{

      if(err) {
        setSnackbarMessage(err)
        setsnackBarSeverityMessage('error')
      } 
    
  })
  const [restaurants,setRestaurants]=React.useState([])
  const handleMultiselect = (e) => {
    setValue(e)
    setRestaurants(e.map((e) => {
      return e.value
    }))
   
  }
  const [templateId, setTemplateId] = React.useState(null)
  const handleTemplateChange = (e, value) => {
    setTemplateId(value?._id)
    checkifTemplate(value?._id)
  }
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCloseSnackbar = () => {
    setOpenSnack(false)
  }
  const assign = () => {
    if (!restaurants.length) {
      setOpenSnack(true)
      setSnackbarMessage('selectionner un restaurant')
      setsnackBarSeverityMessage('error')
    } else {
      assignRestaurants(templateId,restaurants)
      setOpen(false)
      setOpenSnack(true)
    }
  }
  return (
    <div>
      <h3 style={style.h3}>
        Affecter un groupe de restaurants
        <br />
        <div style={style.button}>
         { templateId ?<Button variant="contained" color="secondary" onClick={handleClickOpen}>
            Ajouter un groupe de restaurants
          </Button>:<div></div>}
        </div>
      </h3>
      <div style={style.autocmplete}>
        <Autocomplete
          id="combo-box-demo"
          options={templates}
          getOptionLabel={(templates) => templates.name}
          style={{ width: 300 }}
          onChange={handleTemplateChange}
          renderInput={(params) => (
            <TextField {...params} label="Template" variant="outlined" />
          )}
        />
      </div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">{"Assigner des restaurants"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Select
           value={value}
           onChange={(e) => handleMultiselect(e)}
          options={currentCityRestaurants.map((restaurant) => ({
            value: restaurant.id,
            label: restaurant.nom
          }))} 
          isMulti />
         
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={assign} color="primary" autoFocus>
            Assigner
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnackbar}   severity={snackBarSeverity}
          message={snackBarMessage} />
    </div>
  )
}
const mapStateToProps = (state,ownProps) => ({
  templates: state.templates,
  currentCityRestaurants: restaurantsSelectors.getCityRestaurants(state,ownProps.city)

})
export default connect(mapStateToProps, { checkifTemplate,assignRestaurants })(GroupTemplate)
