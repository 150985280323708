import React from 'react'
import { connect } from 'react-redux'
import { get, isEqual, isEmpty } from 'lodash'

import { selectors as restaurantsSelectors } from 'core.modules/restaurants/reducer'
import {
  selectCurrentRestaurantRequested,
  selectors as currentRestaurantInfosSelectors
} from 'core.modules/currentRestaurant/infos/reducer'

import { fetchRestaurantProductsRequested } from 'core.modules/currentRestaurant/products/reducer'
import { fetchRestaurantOptionsRequested } from 'core.modules/currentRestaurant/options/reducer'
import { fetchRestaurantSupplementRequested } from 'core.modules/currentRestaurant/supplements/reducer'

import Select from 'core.ui/Select'
import { fetchRestaurantSupplementGroupsRequested } from '../../core.modules/currentRestaurant/supplementGroups/reducer'
import { fetchRestaurantDealsRequested } from '../../core.modules/currentRestaurant/deals/reducer'

class SelectRestaurant extends React.Component {

  componentDidMount() {
    const { currentRestaurantId, currentCityRestaurants } = this.props

    if (!currentRestaurantId && !isEmpty(currentCityRestaurants)) {
      this.selectFirstOfCurrentCityRestaurants()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentCityRestaurants } = this.props

    if (!isEqual(currentCityRestaurants, prevProps.currentCityRestaurants)) {
      this.selectFirstOfCurrentCityRestaurants()
    }
  }

  selectFirstOfCurrentCityRestaurants = () => {
    const { selectCurrentRestaurant, currentCityRestaurants, currentRestaurantId } = this.props

    const restaurantId = 
      currentRestaurantId || 
      localStorage.getItem('selectedRes') || 
      (currentCityRestaurants && currentCityRestaurants[0]?._id)

    if (restaurantId) {
      selectCurrentRestaurant(restaurantId)
      this.fetchCurrentRestaurantData(restaurantId)
    }
  }

  handleSelectRestaurant = (e) => {
    const { value: restaurantId } = e.target
    localStorage.setItem('selectedRes', restaurantId)
    this.props.selectCurrentRestaurant(restaurantId)
    this.fetchCurrentRestaurantData(restaurantId)
  }

  fetchCurrentRestaurantData = (restaurantId) => {
    const {
      fetchRestaurantOptions,
      fetchRestaurantProducts,
      fetchRestaurantSupplement,
      fetchRestaurantSupplementGroups,
      fetchRestaurantDeals
    } = this.props

    fetchRestaurantOptions(restaurantId)
    fetchRestaurantProducts(restaurantId)
    fetchRestaurantSupplement(restaurantId)
    fetchRestaurantSupplementGroups(restaurantId)
    fetchRestaurantDeals(restaurantId)
  }

  render() {
    const {
      currentCityRestaurants,
      currentRestaurantId,
      selectCurrentRestaurant
    } = this.props

    return (
      <Select
        style={{ width: '250px' }}
        value={currentRestaurantId || localStorage.getItem('selectedRes')}
        onChange={this.handleSelectRestaurant}
        options={currentCityRestaurants.map((r) => r._id)}
        captions={currentCityRestaurants.map((r) => r.nom)}
        autoFill={false}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentCityRestaurants: restaurantsSelectors.getCityRestaurants(
    state,
    ownProps.city
  )
})

const mapDispatchToProps = (dispatch) => ({
  selectCurrentRestaurant: (restaurantId) => {
    dispatch(selectCurrentRestaurantRequested(restaurantId))
  },
  fetchRestaurantOptions: (restaurantId) => {
    dispatch(fetchRestaurantOptionsRequested(restaurantId))
  },
  fetchRestaurantProducts: (restaurantId) => {
    dispatch(fetchRestaurantProductsRequested(restaurantId))
  },
  fetchRestaurantSupplement: (restaurantId) => {
    dispatch(fetchRestaurantSupplementRequested(restaurantId))
  },
  fetchRestaurantSupplementGroups: (restaurantId) => {
    dispatch(fetchRestaurantSupplementGroupsRequested(restaurantId))
  },
  fetchRestaurantDeals: (restaurantId) => {
    dispatch(fetchRestaurantDealsRequested(restaurantId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectRestaurant)
