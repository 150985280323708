const nowDate = new Date();
export const monthsTranslated = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

export const selectableYears = Array.from({ length: 5 }, (x, i) => nowDate.getUTCFullYear() - i);

export const frToEnDays = (day) => {
  switch (day) {
    case 'Lundi':
      return 'monday';
    case 'Mardi':
      return 'tuesday';
    case 'Mercredi':
      return 'wednesday';
    case 'Jeudi':
      return 'thursday';
    case 'Vendredi':
      return 'friday';
    case 'Samedi':
      return 'saturday';
    case 'Dimanche':
      return 'sunday';
  }
};

export const isAuthorized = (roles = [], page) => {
  const adminRole = 'admin_ixYCbq4crmzb'
  const assistanceRole = 'assistance_6Jv9IMFwYGoZ'
  const riderRole = 'rider_1tEWzT7UkBjA'
  const commercialRole = 'commercial_xoCU92RnPAFM'
  const financialRole = 'financial_RZUqvstp47yH'
  const marketingRole = 'marketing_MpxuDrZdyCI0'
  const managerRole = 'manager_Y3885FZlVArE'

  const authorizations = {
    '/commandes': [assistanceRole, financialRole],
    '/candidatures': [riderRole, commercialRole],
    '/marketing': [riderRole, financialRole, marketingRole, managerRole],
    '/villes': [assistanceRole],
    '/livreurs': [assistanceRole, riderRole, financialRole],
    '/finances': [financialRole],
    '/Claims':[assistanceRole]
  }

  if (
    !roles.includes(adminRole) &&
    (!authorizations[page] || (authorizations[page] && !roles.some((role) => authorizations[page].includes(role))))
  ) {
    return false
  }
  return true
}