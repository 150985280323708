import React from 'react'
import classNames from 'classnames'
import { Button, FormLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'
import TextField from 'core.ui/TextField'
import Snackbar from 'core.ui/Snackbar';
const styles = {
  mainContainer: {},
  labels: {
    marginBottom: '10px'
  },
  textFieldsContainer: {
    margin: '10px 5px 20px 5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  textFields: {
    width: '175px',
    margin: '0 10px'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

export const defaultState = {
  supplément: { name: '', price: 0, checkoutSystemId: undefined,reference:''}
}

class CreateSupplement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      supplément: { name: '', price: 0,checkoutSystemId: undefined,reference:'' },
      snackBarMessage: '',
      snackBarSeverity: 'error',
      openSnackBar: false
    }
    
  
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      this.setState({ supplément: value })
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.setState({ supplément: value })
    }
  }

  handleChange = field => e => {
    const { value } = e.target
    const { supplément } = this.state
    if(field==='reference') {
      this.setState({ supplément: { ...supplément, 'reference': value.trim() } })
    } else {
      this.setState({ supplément: { ...supplément, [field]: value } })
    }
   
  }

  handleSubmit = () => {
    const { supplément } = this.state
    if (supplément.price == null || supplément.price < 0) {
      this.setState({
        openSnackBar: true,
        snackBarSeverity: 'error',
        snackBarMessage: "Prix supplement invalide"
      })
    } else {
      this.props.onSubmit({ target: { value: supplément } })
      // this.setState({supplément:{ name: '', price: 0,checkoutSystemId: undefined,reference:'' }})
    }
    
  }
  
  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }
  render() {
    const { className, classes,currentRestaurant,templates } = this.props
    const { supplément, openSnackBar, snackBarMessage, snackBarSeverity } = this.state
    return (
      <div
        className={classNames(
          'main-container',
          classes.mainContainer,
          className
        )}
      >
        <FormLabel className={classNames(classes.labels)} component="legend">
          Ajouter un supplément
        </FormLabel>
        <div className={classes.textFieldsContainer}>
          <TextField
            className={classNames(
              'create-supplement-name-textfield',
              classes.textFields
            )}
            value={supplément.name}
            label="Nom"
            onChangeComplete={this.handleChange('name')}
          />
          <TextField
            className={classNames(
              'create-supplement-price-textfield',
              classes.textFields
            )}
            value={supplément.name}
            label="Prix TTC"
            type="number"
            onChangeComplete={this.handleChange('price')}
          />
        </div>
        {(currentRestaurant?.posInfo?.type || templates?.checkTemplate) ?
       <div style={{marginLeft:'70px'}}> <TextField
          label="Id système de caisse "
          value={supplément.checkoutSystemId}
          type="text"
          onChangeComplete={this.handleChange('checkoutSystemId')}
        />
        <TextField
          label="Reference"
          value={supplément.reference}
          type="text"
          onChangeComplete={this.handleChange('reference')}
        />

        </div>
        : <div></div> }
        {/* BOUTON CRÉER LE SUPPLÉMENT */}
        <div className={classes.buttonContainer}>
          <Button
            className="create-supplement-submit-button"
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
          >
            Ajouter
          </Button>
          <Snackbar
            open={openSnackBar}
            onClose={this.handleCloseSnackbar}
            severity={snackBarSeverity}
            message={snackBarMessage}
        />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CreateSupplement)
