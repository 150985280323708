import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { regulation } from '../../core.services/api';
import RegulationForm from './RegulationForm';
import RegulationActions from './RegulationActions';
import { REGULATION_TYPE_AMOUNT, REGULATION_TYPE_PRODUCT } from '../../core.utils/parser/regulationEnums';
import { omit } from 'lodash';
import { orderReductionType } from '../OrderDetails/OrderDetails';

export default function RegulationDialog({
	regulationToEdit,
	order,
	orderId,
	onSubmitSuccess,
	isVisible,
	showLoading,
	toggleDialog,
	updateRegulation,
	products,
	claim
}) {
	const [ newRegulation, setnewRegulation ] = useState({});
	const [ errorMessage, setErrorMessage ] = useState(null);

	let deliveryReduction = 0
	if (order.cart?.length) {
		deliveryReduction = order.promotions?.reduce((acc, elem) => elem.type?.value === orderReductionType.DELIVERY ? acc + elem.promoDiscountValue?.value : acc, 0)
	} else if (order?.products?.length) {
		deliveryReduction = order.reductions?.reduce((acc, elem) => elem.type === orderReductionType.DELIVERY ? acc + elem.amount?.value : acc, 0)
	}
	
	let userFeesPaid = order.payment?.charges?.fees.reduce((acc, elem) => acc + elem.amount, 0)

	if (userFeesPaid && deliveryReduction) {
		userFeesPaid -= deliveryReduction
	}
	userFeesPaid = +(userFeesPaid / 100).toFixed(2)


	useEffect(() => {
		if (regulationToEdit !== null) {
			setnewRegulation({...regulationToEdit, toPay: !(regulationToEdit.value < 0)});		
		}
	}, [ regulationToEdit ]);

	const handleSubmit = async () => {
		if (isFormComplete()) {
			setErrorMessage(null);
			let regulationData = {
				...newRegulation,				
				orderId,
				restaurantId: order.restaurantId._id || order.restaurantId	  
			}

			if (!regulationData.toPay && (regulationData.value > 0)) regulationData.value = regulationData.value * -1
			regulationData = omit(regulationData, ["toPay"])

			if (newRegulation.type === REGULATION_TYPE_AMOUNT) {
				regulationData = omit(regulationData, ["products"])        
			}

			try {
				if(regulationToEdit.restaurantId){
					regulationData = {
						...regulationData,
						restaurantId:regulationToEdit.restaurantId
					}
				}
				if (!newRegulation._id) {
					await regulation.add(regulationData)
				} else {
					await updateRegulation(newRegulation._id, newRegulation)
				}
					onSubmitSuccess();
					toggleDialog();
					setnewRegulation({});
			} catch (e) {
				setErrorMessage('Erreur avec la requête. Merci de réessayer.');
			}
		} else {
			setErrorMessage('Merci de compléter le formulaire correctement.');
		}
	};

	const isFormComplete = () => {
		if (Object.keys(newRegulation).length === 0 || !newRegulation.concernedParty || !newRegulation.reason || (!newRegulation.products && !newRegulation.cart) || !newRegulation.type) {
			return false;
		}

		if (newRegulation.type === REGULATION_TYPE_PRODUCT && !newRegulation.products.length && !newRegulation.cart && !newRegulation.cart.length ) {
			return false;
		}
		return true;
  	};

	const onCancel = () => {
		toggleDialog();
	};

	return (
		<Dialog
			open={isVisible}
			fullWidth={true}
			maxWidth="md"
			style={{overflowY:"hidden"}}
			id="restaurant-form-main-container"
		>
			<DialogTitle style={{ width:"100%", display: 'flex', justifyContent: 'center' }}>
				{`${newRegulation?._id ? "Modifier" : "Ajouter"} une régulation`}
			</DialogTitle>
			<DialogContent>
				<div style={{ width:"100%", textAlign: "center", paddingLeft: 20 }}>
				<p style={{ margin:0 }}>
					Frais de livraison Client: {`${userFeesPaid} €`}
				</p>
				<p style={{ margin:0 }}>
					Frais de livraison Livreur: {order?.deliveryPrice?.text}
				</p>
				<p style={{margin:0}} >
					Total: {order?.totalPrice?.text}
				</p>
				</div>
					<p style={{textAlign:"center"}} >
				{errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
				</p>
				<RegulationForm
					claim={claim}
					errorMessage={errorMessage}
					order={order}
					newRegulation={newRegulation}
					updateNewRegulation={(payload) => setnewRegulation(payload)}
					products={products}
				/> 
			</DialogContent>

			<RegulationActions
				newRegulation={newRegulation}
				showLoading={showLoading}
				handleSubmit={handleSubmit}
				onCancel={onCancel}
			/>
		</Dialog>
	);
}
