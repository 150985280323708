import React from 'react';

import { order } from 'core.services/api'
import { statusColorMapping, orderStatus } from '../../core.utils/parser/orderStatus';
import PriorityOrderCard from './PriorityOrderCard/index';
import { CircularProgress } from '@material-ui/core';
import PriorityOrderColumn from './PriorityOrderColumn';

export const priorityOrdersColumns = {
  [orderStatus.WAITING_FOR_PAYMENT]: 'Attente Paiement',
  [orderStatus.WAITING_RESTAURANT]: 'Attente Restaurant',
  [orderStatus.COOKING]: 'En Préparation',
  [orderStatus.WAITING_FOR_RIDER]: 'Recherche Livreur',
  [orderStatus.DRIVER_ASSIGNED]: 'Livreur en route Restaurant',
  [orderStatus.COLLECTED]: 'Livreur en route Client',
  [orderStatus.WAITING_FOR_CLIENT]: 'Attente Client'
}

export default function NewPriorityOrders() {
    let intervalID

    const [priorityOrders, setPriorityOrders] = React.useState([])
    const [isLoading, setisLoading] = React.useState(true)
    
    React.useEffect(() => {
        getPriorityOrders()

        return () => {
            if (intervalID) {
                clearTimeout(intervalID)
            }
        }
    }, [])

    const getPriorityOrders = async () => {    
        setisLoading(true)
        try {
            const res = await order.getPriorityOrders()            
            setPriorityOrders(res)            
        } catch (error) {
            console.debug('getPriorityOrders error: ', error)
            setPriorityOrders([])            
        } finally {
            setisLoading(false)
        }

        if (intervalID) {
            clearTimeout(intervalID)
        }
        intervalID = setTimeout(getPriorityOrders, 30000) 
    }


  return (
    <React.Fragment>
        <h2 style={{textAlign:"center"}} >Total commandes : {priorityOrders?.length} {isLoading && <CircularProgress size={30} />}</h2>
        
        <div style={{display:"flex", overflowY:"scroll"}}>
            {Object.keys(priorityOrdersColumns).map((key, index) => {                
                if (key !== orderStatus.WAITING_RESTAURANT) {
                    return <PriorityOrderColumn key={index.toString()} currentKey={key} data={priorityOrders.filter(o => o.status === key)} refreshOrders={getPriorityOrders} />                    
                }

                return (
                    <>
                        <PriorityOrderColumn key={index.toString()} currentKey={key} data={priorityOrders.filter(o => o.status === key).filter(o => !o.isRestaurantLater)} refreshOrders={getPriorityOrders} />
                        <PriorityOrderColumn key={index.toString()} currentKey={key} title={`${priorityOrdersColumns[key]} Plus Tard`} data={priorityOrders.filter(o => o.status === key).filter(o => o.isRestaurantLater)} refreshOrders={getPriorityOrders} />
                        
                        {/* <div key={index.toString()} style={{borderRight:"3px solid black", minWidth: 250, minHeight: "75vh"}}>
                            <div style={{textAlign:"center", backgroundColor: statusColorMapping[key], paddingTop:5, paddingBottom:5}}>
                                <h3>{priorityOrdersColumns[key]} ({priorityOrders.filter(o => o.status === key).filter(o => !o.isRestaurantLater)?.length})</h3>                
                            </div>
                            {priorityOrders.filter(o => o.status === key).filter(o => !o.isRestaurantLater).map((order, idx) => <PriorityOrderCard  key={idx.toString()} order={order} refreshOrders={getPriorityOrders} />)}
                        </div>
                        <div key={index.toString()} style={{borderRight:"3px solid black", minWidth: 250, minHeight: "75vh"}}>
                            <div style={{textAlign:"center", backgroundColor: statusColorMapping[key], paddingTop:5, paddingBottom:5}}>
                                <h3>{`${priorityOrdersColumns[key]} Plus Tard`} ({priorityOrders.filter(o => o.status === key).filter(o => o.isRestaurantLater)?.length})</h3>                
                            </div>
                            {priorityOrders.filter(o => o.status === key).filter(o => o.isRestaurantLater).map((order, idx) => <PriorityOrderCard  key={idx.toString()} order={order} refreshOrders={getPriorityOrders} />)}
                        </div> */}
                    </>
                )
            })}
        </div>

    </React.Fragment>
  );
}
