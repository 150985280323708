import { get } from 'lodash'

import { order } from 'core.services/api'

export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED'

export const notificationReceived = notification => dispatch => {
  const orderId = get(notification, 'data.orderId')
  order
    .getOrder(orderId)
    .then(order => {
      dispatch({
        type: NOTIFICATION_RECEIVED,
        payload: { notification, order }
      })
    })
    .catch(e => {
      console.error(e)
      dispatch({
        type: NOTIFICATION_RECEIVED,
        payload: { notification }
      })
    })
}

const ALL_NOTIFICATIONS_READ = 'ALL_NOTIFICATIONS_READ'

export const allNotificationsRead = () => ({
  type: ALL_NOTIFICATIONS_READ
})

export const defaultState = { unread: [], read: [] }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case NOTIFICATION_RECEIVED:
      const { notification, order: associatedOrder } = action.payload
      return {
        ...state,
        unread: [{ ...notification, order: associatedOrder }, ...state.unread]
      }
    case ALL_NOTIFICATIONS_READ:
      return {
        ...state,
        unread: [],
        read: [...state.unread, ...state.read]
      }
    default:
      return state
  }
}

const getState = state => state.notifications
const getUnread = state => getState(state).unread
const getRead = state => getState(state).read

export const selectors = {
  getState,
  getUnread,
  getRead
}
