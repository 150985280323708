import React, { useState } from 'react';

import { planningAPI } from 'core.services/api';
import Dialog from 'core.ui/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeletePlanning({ planningId, refresh }) {
	const [ isVisible, setisVisible ] = useState(false);

	const onDeletePlanning = () => {
        try {
            planningAPI.deletePlanning(planningId)
            refresh()
            setisVisible(false);
        } catch(e) {
            alert("Erreur lors de la suppression")
        }
	};

	return (
		<React.Fragment>
			<IconButton
				onClick={() => {
					setisVisible(true);
				}}
			>
				<DeleteIcon />
			</IconButton>
			<Dialog
				open={isVisible}
				dialogText="Confirmer la suppression de tous les créneaux de la semaines"
				dialogTitle="Confirmation"
				cancelButtonCaption="Annuler"
				confirmButtonCaption="Ok"
				onCancel={() => setisVisible(false)}
				onValidation={onDeletePlanning}
			/>
		</React.Fragment>
	);
}
