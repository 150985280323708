import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@material-ui/core'

const FilterByDateRange = (props) => {
  const { classes, checked, onChange } = props

  return (
    <div className={classes.allCitiesSwitchContainer}>
      <FormControlLabel
        control={
          <Switch
            size="small"
            color="primary"
            checked={checked === true ? true : false}
            onChange={onChange}
          />
        }
        label="Filtrer par date"
      />
    </div>
  )
}

FilterByDateRange.propTypes = {
  classes: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default FilterByDateRange
