import React from 'react'
import { connect } from 'react-redux'

import { requestStates } from 'core.utils/requestStates'
import {
  editRestaurantRequested,
  selectors as restaurantSelectors
} from 'core.modules/restaurants/reducer'

import Snackbar from 'core.ui/Snackbar'
import RestaurantForm from 'components/RestaurantForm'

class RestaurantEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmit = (restaurantInfos, restaurantId) => {
    const { editRestaurant } = this.props
    const { password, web_password, ...dataWithoutPassword } = restaurantInfos

    delete dataWithoutPassword.orderCounter

    this.setState({ waitingForRequest: true })

    if (password === '' && web_password == '') {
      editRestaurant(restaurantId, dataWithoutPassword)
    } else if (!!password && web_password == '') {
      editRestaurant(restaurantId, { ...dataWithoutPassword, password })
    } else if (password == '' && !!web_password) {
      editRestaurant(restaurantId, { ...dataWithoutPassword, web_password })
    } else {
      editRestaurant(restaurantId, restaurantInfos)
    }
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const { id, data = {}, errorMessage } = this.props
    const { password, web_password, ...dataWithoutPassword } = data
    return (
      <React.Fragment>
        <RestaurantForm
          id={id}
          data={dataWithoutPassword}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          isPasswordRequired={false}
          showLoading={this.state.waitingForRequest}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: restaurantSelectors.getRequestState(state),
  errorMessage: restaurantSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  editRestaurant: (restaurantId, restaurantInfos) => {
    dispatch(editRestaurantRequested(restaurantId, restaurantInfos))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantEdit)

