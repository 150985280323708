import { red, lightGreen } from "@material-ui/core/colors"

export const restaurantRequestRole = {
  RESTAURANT_OWNER: 'restaurant_owner',
  OPERATIONS_MANAGER: 'operations_manager',
  RESTAURANT_MANAGER: 'restaurant_manager',
  ASSISTANT_MANAGER: 'assistant_manager',
  CHEF: 'chef'
}

export const ordersPerWeek = {
  UNKNOWN: 'unkown',
  LESS_THAN_10: '1-10',
  LESS_THAN_25: '11-25',
  LESS_THAN_50: '26-50',
  LESS_THAN_100: '51-100',
  LESS_THAN_500: '101-500',
  MORE_THAN_500: '500+'
}

export const restaurantRequestStatus = {
  WAITING: 'waiting',
  VALIDATED: 'validated'
}

export const restaurantRequestStatusTranslated = {
  [restaurantRequestStatus.WAITING]: 'En attente',
  [restaurantRequestStatus.VALIDATED]: 'Pris en charge',
}

export function parseRestaurantRequestRole(role) {
  const rolesTranslated = {
    RESTAURANT_OWNER: 'Propriétaire du restaurant',
    OPERATIONS_MANAGER: 'Manager des opérations',
    RESTAURANT_MANAGER: 'Manager du restaurant',
    ASSISTANT_MANAGER: 'Assistant manager',
    CHEF: 'Chef cuisinier'
  }
  switch (role) {
    case restaurantRequestRole.RESTAURANT_OWNER:
      return rolesTranslated.RESTAURANT_OWNER
    case restaurantRequestRole.OPERATIONS_MANAGER:
      return rolesTranslated.OPERATIONS_MANAGER
    case restaurantRequestRole.RESTAURANT_MANAGER:
      return rolesTranslated.RESTAURANT_MANAGER
    case restaurantRequestRole.ASSISTANT_MANAGER:
      return rolesTranslated.ASSISTANT_MANAGER
    case restaurantRequestRole.CHEF:
     return rolesTranslated.CHEF    
    default:
     return role
  }
}

export function parseRestaurantRequestOrdersPerWeek(role) {
  const ordersPerWeekTranslated = {
    UNKNOWN: 'Inconnu',
    LESS_THAN_10: '1-10',
    LESS_THAN_25: '11-25',
    LESS_THAN_50: '26-50',
    LESS_THAN_100: '51-100',
    LESS_THAN_500: '101-500',
    MORE_THAN_500: '500+'
  }
  switch (role) {
    case ordersPerWeek.UNKNOWN:
      return ordersPerWeekTranslated.UNKNOWN
    case ordersPerWeek.LESS_THAN_10:
      return ordersPerWeekTranslated.LESS_THAN_10
    case ordersPerWeek.LESS_THAN_25:
      return ordersPerWeekTranslated.LESS_THAN_25
    case ordersPerWeek.LESS_THAN_50:
      return ordersPerWeekTranslated.LESS_THAN_50
    case ordersPerWeek.LESS_THAN_100:
     return ordersPerWeekTranslated.LESS_THAN_100    
    case ordersPerWeek.LESS_THAN_500:
    return ordersPerWeekTranslated.LESS_THAN_500    
    case ordersPerWeek.MORE_THAN_500:
      return ordersPerWeekTranslated.MORE_THAN_500    
    default:
     return role
  }
}

export function getTranslatedStatus(status) {
  const translatedStatus = restaurantRequestStatusTranslated[status] 
    ? restaurantRequestStatusTranslated[status]
    : status 
  return translatedStatus
}

export const statusColorMapping = {
  [restaurantRequestStatus.WAITING]: red[500],
  [restaurantRequestStatus.VALIDATED]: lightGreen[700],
}

export const statusFontColorMapping = {
  [restaurantRequestStatus.WAITING]: red[500],
  [restaurantRequestStatus.VALIDATED]: lightGreen[700],
}
