import { user } from 'core.services/api'
import { findIndex } from 'lodash'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from '../../core.utils/requestStates'
import { parseErrorMessage } from '../../core.utils/requestActions'

export const {
  CREATE_USER_REQUESTED,
  CREATE_USER_SUCCEEDED,
  CREATE_USER_FAILED,
  createUserSucceeded,
  createUserFailed
} = createRequestActions('CREATE_USER')

export const createUserRequested = (userInfos) => dispatch => {
  dispatch({
    type: FETCH_ALL_USERS_REQUESTED
  })
  user
    .register(userInfos)
    .then(data => {
      dispatch(createUserSucceeded(data))
    })
    .catch(error => {
      dispatch(createUserFailed(error))
    })
}

export const {
  EDIT_USER_REQUESTED,
  EDIT_USER_SUCCEEDED,
  EDIT_USER_FAILED,
  editUserSucceeded,
  editUserFailed
} = createRequestActions('EDIT_USER')

export const editUserRequested = (userId, userInfos) => dispatch => {
  dispatch({
    type: EDIT_USER_REQUESTED
  })
  user
    .edit(userId, userInfos)
    .then(data => {
      dispatch(editUserSucceeded(userId, data))
    })
    .catch(error => {
      dispatch(editUserFailed(error))
    })
}

export const {
  FETCH_ALL_USERS_REQUESTED,
  FETCH_ALL_USERS_SUCCEEDED,
  FETCH_ALL_USERS_FAILED,
  fetchAllUsersSucceeded,
  fetchAllUsersFailed
} = createRequestActions('FETCH_ALL_USERS')

export const fetchAllUsersRequested = (page, limit) => dispatch => {
  dispatch({
    type: FETCH_ALL_USERS_REQUESTED
  })
  user
    .getAll(page, limit)
    .then(data => {
      dispatch(fetchAllUsersSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchAllUsersFailed(error))
    })
}

export const {
  SEARCH_USERS_REQUESTED,
  SEARCH_USERS_SUCCEEDED,
  SEARCH_USERS_FAILED,
  searchUsersSucceeded,
  searchUsersFailed
} = createRequestActions('SEARCH_USERS')

export const searchUsersRequested = (query) => dispatch => {
  dispatch({
    type: SEARCH_USERS_REQUESTED
  })
  user
    .search(query)
    .then(searchedUsers => {
      dispatch(searchUsersSucceeded(searchedUsers))
    })
    .catch(error => {
      dispatch(searchUsersFailed(error))
    })
}

export const {
  DELETE_USER_REQUESTED,
  DELETE_USER_SUCCEEDED,
  DELETE_USER_FAILED,
  deleteUserSucceeded,
  deleteUserFailed
} = createRequestActions('DELETE_USER')

export const deleteUserRequested = (userId) => dispatch => {
  dispatch({
    type: DELETE_USER_REQUESTED
  })
  user
    .delete(userId)
    .then(() => {
      dispatch(deleteUserSucceeded({userId}))
    })
    .catch(error => {
      dispatch(deleteUserFailed(error))
    })
}

export const defaultState = { users: [], searchedUsers: [], displayingSearchResult: false }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case CREATE_USER_REQUESTED:
    case EDIT_USER_REQUESTED:
    case FETCH_ALL_USERS_REQUESTED:
    case DELETE_USER_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case SEARCH_USERS_REQUESTED:
      return {
        ...state,
        displayingSearchResult: true,
        requestState: requestStates.PENDING
      }
    case CREATE_USER_SUCCEEDED: 
      const { data: user } = action.payload
      return {
        ...state,
        users: [...state.users, user],
        requestState: requestStates.SUCCESS
      }
    case EDIT_USER_SUCCEEDED:
      const { users } = state
      const { userId, userInfos } = action.payload.data
      const index = findIndex(users, (user) => user.id === userId)
      return {
        ...state,
        users: [
          ...users.slice(0, index),
          { ...users[index], ...userInfos },
          ...users.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    case FETCH_ALL_USERS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        users: data,
        requestState: requestStates.SUCCESS,
        displayingSearchResult: false,
        errorMessage: ''
      }
    }
    case SEARCH_USERS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        searchedUsers: data,
        requestState: requestStates.SUCCESS,
        errorMessage: ''
      }
    }
    case DELETE_USER_SUCCEEDED: {
      const { userId } = action.payload.data
      const index = state.users.findIndex((user) => user.id === userId)
      const searchedUsersindex = state.searchedUsers.findIndex((user) => user.id === userId)
      return {
        ...state,
        users: [
          ...state.users.slice(0, index), 
          ...state.users.slice(index + 1)
        ],
        searchedUsers: [
          ...state.searchedUsers.slice(0, searchedUsersindex), 
          ...state.searchedUsers.slice(searchedUsersindex + 1)          
        ],
        requestState: requestStates.SUCCESS,
      }
    }
    case CREATE_USER_FAILED:
    case FETCH_ALL_USERS_FAILED:
    case EDIT_USER_FAILED:
      return {
        ...state,
        users: [],
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    case DELETE_USER_FAILED: {
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    }
    case SEARCH_USERS_FAILED:
      return {
        ...state,
        searchedUsers: [],
        displayingSearchResult: false,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    default:
      return state
  }
}

const getState = state => state.users
const getRequestState = state => getState(state).requestState
const getAllUsers = state => getState(state).users
const getDisplayingSearchResult = state => getState(state).displayingSearchResult
const getSearchedUsers = state => getState(state).searchedUsers
const getErrorMessage = state => getState(state).errorMessage

export const selectors = {
  getState,
  getRequestState,
  getDisplayingSearchResult,
  getSearchedUsers,
  getErrorMessage,
  getAllUsers
}
