import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

import { selectors as citiesSelectors } from 'core.modules/cities/reducer'

import Notifications from 'components/Notifications'

import './DashboardHeader.css'
import { useAuth } from '../../../contexts/AuthContext'

function DashboardHeader({ handleCityChange, city, cities = [], history }) {
  const { setUserToken } = useAuth()
  const [UserData, setUserData] = React.useState({
    firstName: localStorage.getItem('firstName'),
    lastName: localStorage.getItem('lastName')
  })
  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('selectedCity')
    localStorage.removeItem('selectedRes')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    setUserToken(null)
    // history.push('/login');
  }

  const onCityChange = (e, value) => {
    if (!value) return

    const idx = cities.map((c) => c.name).indexOf(value)
    if (cities[idx]) {
      handleCityChange(cities[idx].id || cities[idx]._id)
      localStorage.setItem('selectedCity', cities[idx].id || cities[idx]._id)
      localStorage.removeItem('selectedRes')
    }
  }

  if (!cities || cities.length === 0 || !city) {
    return <div></div>
  }

  const getCityValue = () => {
    const value = cities.find(el => el._id === city)
    if (value) return value.name || "choisir une ville"

  }

  return (
    <div id="dashboard-header-main-container">
      <div id="dashboard-header-city-select" style={{ width: '17%' }}>
        <Autocomplete
          freeSolo={true}
          defaultValue={cities.filter((c) => c.id === city)[0]?.name}
          options={cities.map((c) => c.name).sort()}
          style={{ width: '100%' }}
          onChange={onCityChange}
          renderInput={(params) => (
            <TextField {...params} label="Ville" variant="outlined" />
          )}
          value={getCityValue()}
        />
      </div>
      <h1 id="dashboard-header-title">Tableau de bord Lyveat</h1>
      <div id="dashboard-right-elements">
        <Notifications history={history} />
        <div id="dashboard-header-current-user">
          {UserData.firstName} {UserData.lastName}
        </div>
        <Button variant="contained" color="primary" onClick={handleLogout}>
          Se déconnecter
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  cities: citiesSelectors.getAllCities(state)
})

export default connect(mapStateToProps)(DashboardHeader)
