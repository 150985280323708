import {
  orange,
  yellow,
  red,
  lightBlue,
  grey,
  lightGreen
} from '@material-ui/core/colors'

export const orderStatus = {
  WAITING_FOR_PAYMENT: 'waiting for payment',
  FAILED: 'failed',
  CANCELED: 'canceled',
  WAITING_RESTAURANT: 'processing',
  LATER: 'later',
  COOKING: 'cooking',
  WAITING_FOR_RIDER: 'waiting for rider',
  DRIVER_ASSIGNED: 'driver assigned',
  COLLECTED: 'collected',
  COMPLETED: 'completed'
}

export const pickupOrderStatus = {
  WAITING_FOR_PAYMENT: 'waiting for payment',
  FAILED: 'failed',
  CANCELED: 'canceled',
  WAITING_RESTAURANT: 'processing',
  COOKING: 'cooking',
  WAITING_FOR_CLIENT:'waiting for client',
  COMPLETED: 'completed',
}

export const orderDeliveryType = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup'
}

const frenchOrderStatus = {
  [orderStatus.WAITING_FOR_PAYMENT]: 'En attente de paiement',
  [orderStatus.FAILED]: 'Échec',
  [orderStatus.CANCELED]: 'Annulée',
  [orderStatus.WAITING_RESTAURANT]: 'En attente du restaurant',
  [orderStatus.COOKING]: 'En préparation',
  [orderStatus.WAITING_FOR_RIDER]: 'Recherche d’un livreur',
  [orderStatus.DRIVER_ASSIGNED]: 'Livreur en route vers le restaurant',
  [orderStatus.COLLECTED]: 'Livreur en route vers le client',
  [orderStatus.COMPLETED]: 'La commande a été livrée',
  [orderStatus.WAITING_FOR_CLIENT]: 'En attente de client'
}

const frenchPickupOrderStatus = {
  [pickupOrderStatus.WAITING_FOR_PAYMENT]: 'En attente de paiement',
  [pickupOrderStatus.FAILED]: 'Échec',
  [pickupOrderStatus.CANCELED]: 'Annulée',
  [pickupOrderStatus.WAITING_RESTAURANT]: 'En attente du restaurant',
  [pickupOrderStatus.COOKING]: 'En préparation',
  [pickupOrderStatus.WAITING_FOR_CLIENT]: 'En attente de client',
  [pickupOrderStatus.COMPLETED]: 'Commande récuprérée'
}

export const statusColorMapping = {
  [orderStatus.WAITING_FOR_PAYMENT]: orange[300],
  [orderStatus.FAILED]: '',
  [orderStatus.CANCELED]: '',
  [orderStatus.WAITING_RESTAURANT]: yellow[300],
  [orderStatus.COOKING]: lightBlue[300],
  [orderStatus.WAITING_FOR_RIDER]: red[400],
  [orderStatus.DRIVER_ASSIGNED]: grey[400],
  [orderStatus.COLLECTED]: lightGreen[400],
  [orderStatus.COMPLETED]: '',
  [orderStatus.WAITING_FOR_CLIENT]:red[400]
}

export const statusFontColorMapping = {
  [orderStatus.WAITING_FOR_PAYMENT]: orange[700],
  [orderStatus.FAILED]: '',
  [orderStatus.CANCELED]: '',
  [orderStatus.WAITING_RESTAURANT]: yellow[700],
  [orderStatus.COOKING]: lightBlue[500],
  [orderStatus.WAITING_FOR_RIDER]: red[500],
  [orderStatus.DRIVER_ASSIGNED]: grey[500],
  [orderStatus.COLLECTED]: lightGreen[700],
  [orderStatus.COMPLETED]: ''
}

export const getFrenchStatus = (status,deliveryType='delivery') => {
  if(deliveryType==='delivery'){
    const french = frenchOrderStatus[status]
  return french ? french : status
  } else if(deliveryType==='pickup'){
    const french = frenchPickupOrderStatus[status]
  return french ? french : status
  }
}


export const statusTypes = {
  ALL: [],
  CURRENT: [
    'WAITING_FOR_PAYMENT',
    'WAITING_RESTAURANT',
    'COOKING',
    'WAITING_FOR_RIDER',
    'DRIVER_ASSIGNED',
    'COLLECTED'
  ],
  DONE: ['FAILED', 'CANCELED', 'COMPLETED']
}
