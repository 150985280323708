import React, { Fragment, useEffect, useState } from 'react'
import UploadButtonJPEG from 'components/UploadimageJPEG'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Switch,
  Typography
} from '@material-ui/core'
import Snackbar from 'core.ui/Snackbar'
import Select1 from '@material-ui/core/Select/Select'
import { add_Ad, uploadAdImage } from '../../core.modules/Ads/Actions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { parseImage } from 'core.utils/parser/image'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import classNames from 'classnames'
import { isEmailValid } from 'core.utils/formValidation.js'
import Select from 'react-select'
const styles = {
  mainContainer: {
    margin: '20px auto',
    width: '562px',
    padding: '8px 20px ',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  rows: {
    marginBottom: '12px',
    paddingTop: '50px'
  },
  lines: {
    display: 'flex',
    flexDirection: 'row'
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewImage: { width: '200px' },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative',
    marginLeft: 20
  },
  buttonLoadingAnimation: {
    //color: blue[500],
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '2.5px auto'
  },
  select: { margin: '6px 0' },
  selectLabel: {
    margin: '6px 0'
  }
}
const Adaction = {
  WEB: 'web',
  REFERRAL_CODE: 'referral_code',
  PROMO_CODE: 'promo_code',
  UPDATE: 'update',
  PICKUP: 'pickup',
  RESTAURANT: 'restaurant',
  NONE: 'null'
}

const AddAd = ({
  add_Ad,
  history,
  allCities,
  uploadAdImage,
  ads: { adId, err },
  match
}) => {
  const classes = styles
  const [open, setOpen] = React.useState(false)

  const [snackBarMessage, setSnackbarMessage] = useState('')
  const [snackBarSeverity, setsnackBarSeverityMessage] = useState('error')

  const handleCloseSnackbar = () => {
    setOpen(false)
  }
  const [formData, setFormData] = useState({
    action: '',
    description: '',
    order: 0,
    url: '',
    cities: [],
    restaurantmail: '',
    startDate: '',
    endDate: '',
    isArchived: false,
    imagePath: null,
    isEnabled: false
  })
  const {
    action,
    description,
    order,
    url,
    cities,
    restaurantmail,
    startDate,
    endDate,
    isArchived,
    imagePath,
    isEnabled
  } = formData
  const [imagePreview, setImagePreview] = useState(null)
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const [value, setValue] = useState([])
  const handleMultiselect = (e) => {
    setValue(e)
    setFormData({
      ...formData,
      cities: e.map((e) => {
        return e.value
      })
    })
  }
  const handleChangeSwitch = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked })
  }
  const handleImageSelect = (e) => {
    const { files } = e.target
    parseImage(files[0]).then((result) => {
      setFormData({ ...formData, imagePath: files[0] })
      setImagePreview(result)
    })
  }
  const submit = (e) => {
    e.preventDefault()
    if (!imagePath) {
      setOpen(true)
      setSnackbarMessage('Veuillez entrer une image')
    } else if (!description) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir une description valide')
    } else if (order === 0) {
      setOpen(true)
      setSnackbarMessage('Veuillez entrer un ordre supérieur à 0')
    } else if (!startDate) {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir une date de debut')
    } else if (!endDate) {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir une date de fin')
    } else if (cities.length === 0 && match.params.tabValue === 'Ville') {
      setOpen(true)
      setSnackbarMessage('Veuillez choisir une/plusieurs ville')
    } else if (
      (!restaurantmail || !isEmailValid(restaurantmail)) &&
      action === Adaction.RESTAURANT
    ) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir un email valide')
    } else if (!url && action === Adaction.WEB) {
      setOpen(true)
      setSnackbarMessage('Veuillez saisir un url valide')
    } else {
      const startDate = new Date(formData.startDate)
      const endDate = new Date(formData.endDate)

      add_Ad({ ...formData, startDate, endDate }, history)
      .catch((err)=>{
        setOpen(true)
        setSnackbarMessage(err?.response?.data?.errors[0]?.message)
      })
    }
  }
  return (
    <Fragment>
      <div style={classes.mainContainer}>
        <h1>Ajouter une publicité</h1>
        <div className={classNames(classes.rows, classes.imageWrapper)}>
          {imagePath && (
            <img
              className={classNames(classes.rows, classes.previewImage)}
              src={imagePreview}
              style={{ width: '300px' }}
              alt="ad"
            />
          )}
          <UploadButtonJPEG title="Photo" onFileSelect={handleImageSelect} />
        </div>
        <TextField
          style={{ paddingBottom: '20px' }}
          className={classes.rows}
          name="description"
          value={description}
          onChange={(e) => handleChange(e)}
          fullWidth
          label="Description"

          //error={showErrors && !validFields.nom}
          /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
        />
        {match.params.tabValue === 'Ville' ? (
          <FormControl>
            <InputLabel shrink>Ville</InputLabel>
            <Select
              name="cities"
              isMulti
              value={value}
              onChange={(e) => handleMultiselect(e)}
              options={allCities.map((city) => ({
                value: city._id,
                label: city.name
              }))}
            />
          </FormControl>
        ) : (
          <div></div>
        )}
        <FormControl style={{ paddingBottom: '20px' }}>
          <InputLabel shrink>Actions</InputLabel>
          <Select1
            className={classes.rows}
            name="action"
            value={action}
            onChange={(e) => handleChange(e)}
          >
            <option value={Adaction.NONE}>Pas d'action</option>
            <option value={Adaction.WEB}>Web</option>
            <option value={Adaction.PICKUP}>Emporter</option>
            <option value={Adaction.PROMO_CODE}>Code Promo</option>
            <option value={Adaction.REFERRAL_CODE}>Parrainage</option>
            <option value={Adaction.RESTAURANT}>{Adaction.RESTAURANT}</option>
            <option value={Adaction.UPDATE}>Mise à jour</option>
          </Select1>
        </FormControl>
        {action === Adaction.WEB ? (
          <TextField
            style={{ paddingBottom: '20px' }}
            className={classes.rows}
            name="url"
            value={url}
            onChange={(e) => handleChange(e)}
            fullWidth
            label="Url"
            placeholder="https://www.lyveat.com"
          />
        ) : (
          <div></div>
        )}
        {action === Adaction.RESTAURANT ? (
          <TextField
            style={{ paddingBottom: '20px' }}
            className={classes.rows}
            name="restaurantmail"
            value={restaurantmail}
            onChange={(e) => handleChange(e)}
            fullWidth
            label="Email Restaurant"
            //error={showErrors && !validFields.nom}
            /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
          />
        ) : (
          <div></div>
        )}

        <FormLabel> Publicité active</FormLabel>

        <Typography component="div">
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{ paddingLeft: '210px' }}
          >
            <Grid item>inactive</Grid>
            <Grid item>
              <Switch
                checked={isEnabled}
                value={isEnabled}
                onChange={handleChangeSwitch}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>active</Grid>
          </Grid>
        </Typography>
        <TextField
          style={{ paddingBottom: '20px' }}
          className={classes.rows}
          name="order"
          value={order}
          type="number"
          min="1"
          onChange={(e) => handleChange(e)}
          fullWidth
          label="Ordre"
          //error={showErrors && !validFields.nom}
          /*helperText={
              showErrors && !validFields.nom ? 'Ce champ est requis' : ''
            }*/
        />
        <TextField
          className={classes.rows}
          style={{ paddingBottom: '20px' }}
          id="datetime-local"
          label="Début"
          type="datetime-local"
          defaultValue={Date.now()}
          name="startDate"
          value={startDate}
          onChange={(e) => handleChange(e)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          style={{ paddingBottom: '20px' }}
          className={classes.rows}
          id="datetime-local"
          label="Fin"
          type="datetime-local"
          name="endDate"
          value={endDate}
          defaultValue={Date.now()}
          onChange={(e) => handleChange(e)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => submit(e)}
        >
          Créer la Pub
        </Button>
        <Snackbar
          open={open}
          onClose={handleCloseSnackbar}
          severity={snackBarSeverity}
          message={snackBarMessage}
        />
      </div>
    </Fragment>
  )
}
const mapStateToProps = (state) => ({
  allCities: citiesSelectors.getAllCities(state),
  ads: state.ads
})
export default compose(
  connect(mapStateToProps, { add_Ad, uploadAdImage }),
  withStyles(styles)
)(AddAd)
