import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

import { withStyles } from '@material-ui/core/styles'

import { parseHourString, parseDate } from 'core.utils/parser/time'

import MuiAlert from '@material-ui/lab/Alert';
import { requestStates } from 'core.utils/requestStates'
import { city } from 'core.services/api'
import classnames from 'classnames'
import Snackbar from 'core.ui/Snackbar'
import { makeStyles } from '@material-ui/core/styles';
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import { createCity, citiesFetchRequested, selectors as createCitySelector } from '../../core.modules/cities/reducer'
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem, TextField, Typography, Box  } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { region } from '../../core.services/api';



const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  buttons: {
    display: 'flex',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}

class CityForm extends Component {
  constructor(props) {
    super(props)
    this.nameValidator = /\d/g
    this.state = {
      name: null,
      launchDate: null,
      financialCode: null,
      waitingForRequest: false,
      latitude: null,
      longitude: null,
      regionId: '',
      isClosed: false, 
      unavailabilityRisk: false,
      openSnackBar: false,
      open: false,
      loading: false,
      isLoading: false,
      errorMessage: ""
    }
  }

  componentDidMount() {
    this.setState({ waitingForRequest: true, isLoading: true })
    const cityId = this.props.location.search.split('=')[1];

    city
    .getCityById(cityId)
    .then((data) => {
      
      if(data) {
        this.setState({
          name: data?.name,
          launchDate: data?.launchDate,
          financialCode: data?.financialCode,
          latitude: data?.position?.latitude,
          longitude: data?.position?.longitude,
          regionId: data?.regionId,
          waitingForRequest: false,
          isLoading: false,
          isClosed: data.isClosed != null ? data.isClosed : false,
          unavailabilityRisk: data.unavailabilityRisk != null ? data.unavailabilityRisk : false,
          config: data.config != null ? data.config : { filterByDeliveryDistanceAverage: false }
        }) 
       }
    })
    .catch((error) => {
        this.setState({
          openSnackBar: true,
          errorMessage: "Une erreur est survenue durant la récupération des données"
        })
    })
  }

  handleLaunchDateChange = e => {
    this.setState({ launchDate: e.target.value })
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handlefinancialCodeChange = e => {
    this.setState({ financialCode: e.target.value })
  }

  handleLatitudeChange = e => {
    this.setState({ latitude: e.target.value })
  }

  handleLongitudeChange = e => {
    this.setState({ longitude: e.target.value })
  }

  handleFilterByDeliveryDistanceAverageChange = () => {
    this.setState((prevState) => ({ config: { ...prevState.config, filterByDeliveryDistanceAverage: !prevState.config.filterByDeliveryDistanceAverage } }))
  }

  handleOpenChange = async (value) => {
    const newState = { open: value }
    if (!value) newState.regions = []
    else newState.loading = true
    this.setState(newState)
    if (value) {
      try {
        const regions = await region.getRegions()
        this.setState({ regions, loading: false })
      } catch (error) {
        this.setState({ regions: [], loading: false })
      }
    }
  }

  handleRegionChange = (e, value) => {
    this.setState({ regionId: value && value._id })
  }

  handleEditCity = () => {
    this.setState({ waitingForRequest: true })
    const { name, latitude, longitude, financialCode, regionId, launchDate, isClosed, unavailabilityRisk, config } = this.state
    if (!name || this.nameValidator.test(name) || name?.trim().length === 0) {
      alert('Nom invalide')
      return
    }

    if (!latitude || isNaN(latitude) || latitude < -90 || latitude > 90) return alert('Latitude invalide')
    if (!longitude || isNaN(longitude) || longitude < -180 || latitude > 180) return alert('Longitude invalide')

    const date = launchDate ? new Date(launchDate) : undefined

    const cityInfos = { 
      name: name?.trim(), 
      position: { latitude, longitude }, 
      financialCode: financialCode?.trim(), 
      regionId, 
      launchDate: date, 
      isClosed, 
      unavailabilityRisk, 
      config
    }

    const cityId = this.props.location.search.split('=')[1];

    if(cityId) {
      city
      .updateCity(cityId, cityInfos)
      .then((data) => {
        this.props.history.goBack()
      })
      .catch((error) => {
        this.setState({
          openSnackBar: true,
          errorMessage: "Une erreur est survenue durant la mise à jour"
        })
      })
    }

    
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmitForm = productInfos => {
    this.setState({ waitingForRequest: true })
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const { name, latitude, longitude, financialCode, launchDate, isClosed, unavailabilityRisk, config } = this.state

    return (
      <React.Fragment>
        <div style={styles.mainListContainer}>
          <Typography
          style={{ textAlign: 'center' }}
          variant="h5"
          component="h5"
          >
            Modifier une ville 
          </Typography>
          {!this.state.isLoading ? 
          <>
            <div style={styles.header}>
              <FormControl>
                <TextField
                  label="Nom de la ville"
                  value={name}
                  name="name"
                  onChange={this.handleNameChange}
                  fullWidth
                />
              </FormControl>
              <TextField
                label="Latitude"
                value={latitude}
                onChange={this.handleLatitudeChange}
                fullWidth
              />
              <TextField
                label="Longitude"
                value={longitude}
                onChange={this.handleLongitudeChange}
                fullWidth
              />
              <TextField
                label="Code financier"
                value={financialCode}
                onChange={this.handlefinancialCodeChange}
                fullWidth
              />
              <h4>Date de lancement : </h4>
              <div style={{margionTop: "10px"}}>
                <TextField
                  label={launchDate ? "Date de lancement" : undefined}
                  value={moment(launchDate).format('yyyy-MM-DDThh:mm')}
                  type="datetime-local"
                  onChange={this.handleLaunchDateChange}
                  fullWidth
                />
              </div>
              <FormControl component="fieldset">
             <FormGroup aria-label="position" row>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" checked={isClosed} onChange={() => this.setState({isClosed: !isClosed})} />}
              label="Ville fermée"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch color="primary" checked={unavailabilityRisk} onChange={() => this.setState({unavailabilityRisk: !unavailabilityRisk})} />}
              label="Ville à risque"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch color="primary" checked={!!config?.filterByDeliveryDistanceAverage} onChange={this.handleFilterByDeliveryDistanceAverageChange} />}
              label="Filtrer la selection des livreurs par moyenne de distance de livraison"
              labelPlacement="start"
            />
      </FormGroup>
    </FormControl>
            </div>
            <div style={styles.buttons}>
            <Button onClick={this.handleCancel} variant="contained" color="default">
              Annuler
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {this.handleEditCity()}}
            >
              Modifier la ville
            </Button>
            </div>
            </> 
            :
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>}
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={this.props.errorMessage || this.state.errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  allCities: citiesSelectors.getAllCities(state),
  currentRequestState: createCitySelector.getRequestState(state),
  errorMessage: createCitySelector.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  fetchCities: () => {
    dispatch(citiesFetchRequested())
  },
  createCity: (cityInfos) => {
    dispatch(createCity(cityInfos))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CityForm)
