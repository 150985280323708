import React from 'react'
import { connect } from 'react-redux'

import {
  createCityRiderRequested,
  selectors as ridersSelectors
} from 'core.modules/riders/reducer'
import { requestStates } from 'core.utils/requestStates'

import RiderForm from 'components/RiderForm'
import Snackbar from 'core.ui/Snackbar'

class RiderCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.push('/livreurs')
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }
  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmit = rider => {
    this.setState({ waitingForRequest: true })
    this.props.createRider(rider)
  }

  handleCancel = () => {
    this.props.history.push('/livreurs')
  }

  render() {
    return (
      <React.Fragment>
        <RiderForm
          onCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          isPasswordRequired={true}
          showLoading={this.state.waitingForRequest}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={this.props.errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: ridersSelectors.getRequestState(state),
  errorMessage: ridersSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  createRider: riderInfos => {
    dispatch(createCityRiderRequested(riderInfos))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiderCreate)
