import React from 'react'
import List from 'core.ui/List'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button, TextField } from '@material-ui/core'
import { connect } from 'react-redux'
import {getsupplements,checkifTemplate,syncSupplements,deleteSupplement  } from "../../core.modules/Template/Actions";
import { supplement, template } from '../../core.services/api'
import ModalNoteComponent from "../Supplements/components/ModalForm";
import SyncIcon from '@material-ui/icons/Sync';
import Snackbar from 'core.ui/Snackbar'
import Dialog from 'core.ui/Dialog'

const style = {
    h3: { textAlign: 'center' },
    autocmplete: { marginLeft: '40%' } ,sync:{position:'absolute',marginLeft: '7%'}
  }
const SupplementsTemplate =({templates:{templates,supplements,checkTemplate,err},getsupplements,checkifTemplate,syncSupplements,deleteSupplement})=> {
  const [templateId, setTemplateId] = React.useState(null)
  const [ modalFormVisible,setmodalFormVisible]=React.useState(false)
  const [modalFormItem,setmodalFormItem]=React.useState(null)
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState('Succes synchronisation')
  const [snackBarSeverity, setsnackBarSeverityMessage] = React.useState('success')
  const [openDeleteDialog,setopenDeleteDialog]=React.useState(false)
  const [supplementId,setSupplementId]=React.useState('')
  const [openSyncDialog,setOpenSyncDialog]=React.useState(false)
  const ClosesyncDialog =()=>{
    setOpenSyncDialog(false)
  }
  const syncSupplement =()=>{
    syncSupplements(templateId)
    setOpen(true);
    setOpenSyncDialog(false)
  }
 
  const handleClick = () => {
   setOpenSyncDialog(true)
  };
  React.useEffect(()=>{
    if(err) {
      setSnackbarMessage(err)
      setsnackBarSeverityMessage('error')
    }
})
  const closeDeleteDialog =()=>{
    setopenDeleteDialog(false)
  }
 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleTemplateChange = (e, value) => {
    checkifTemplate(value?._id)
    setTemplateId(value?._id)
    getsupplements(value?._id)
  }
  const handleEdit =itemId=>{
    const item = supplements.find((i) => i._id === itemId)
    if (!item) return
    setmodalFormVisible(true)
     setmodalFormItem(item)  
  }
 const handleEditSubmit = async (item) => {
  

    try {
      await supplement.updateTemplateSupp(templateId, item._id, {
        name: item.name,
        price: item.price,
        checkoutSystemId: item.checkoutSystemId?.trim() || undefined,
        reference:item.reference
      })
      getsupplements(templateId)
     setmodalFormVisible(false)
     setmodalFormItem(null)
     
    } catch (e) {
      setSnackbarMessage(e?.response?.data?.errors[0]?.message)
      setsnackBarSeverityMessage('error')
      setOpen(true)
      console.log(e?.response?.data?.errors)
    }
  }
  const handleDelete =id=>{
    setopenDeleteDialog(true)
    setSupplementId(id)
  }
  const DeleteSupplement =()=>{
    deleteSupplement(templateId,supplementId)
   setopenDeleteDialog(false)
   
  }
const renderList=(supp)=>{
  return[
    supp.name,
    supp.price,
    supp.checkoutSystemId,
    supp?.group ? supp.group :'aucun groupe',
    ''
  ]
}
    return (
        <div>
             <h3 style={style.h3}>Liste des templates supplements</h3>
             <div style={style.autocmplete}>
        <Autocomplete
          id="combo-box-demo"
          options={templates}
          getOptionLabel={(templates) => templates.name}
          style={{ width: 300 }}
          onChange={handleTemplateChange}
          renderInput={(params) => (
            <TextField {...params} label="Template" variant="outlined" />
          )}
        />
       {templateId ?<div style={style.sync}>  <Button onClick={handleClick}> <SyncIcon/> </Button></div>:<div></div>
      } 
      </div>
        <List
          ids={supplements.map((supplement) => supplement._id || supplement.id)}
          columns={['Nom', 'Prix', 'Id systeme de caisse', 'Groupe', '']}
          actions={{ handleEdit: handleEdit, handleDelete:handleDelete }}
          emptyMessage="Aucune template"
           list={supplements.map((supplement) => {
            return renderList(supplement)
          })} 
        />
        <Dialog
              open={openDeleteDialog}
              dialogText="continuer ?"
              dialogTitle="Supprimer supplement "
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={closeDeleteDialog}
              onValidation={DeleteSupplement}
            />
          <Dialog
              open={openSyncDialog}
              dialogText={`
              Synchronisation des supplements: prix, image, id systeme de caisse, menu...`}
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={ClosesyncDialog}
              onValidation={syncSupplement}
            />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}   severity={snackBarSeverity}
          message={snackBarMessage} />
        {modalFormVisible && (
                <ModalNoteComponent
                //currentRestaurant={currentRestaurant}
                Temp={checkTemplate}
                  item={modalFormItem}
                  onCancel={() => {
                    setmodalFormVisible(false)
                    setmodalFormItem(null)
                    
                  }}
                  onSubmit={handleEditSubmit}
                />
              )}
      </div>
    )
}
const mapStateToProps = (state) => ({
  templates: state.templates
})
export default connect(mapStateToProps,{getsupplements,checkifTemplate,syncSupplements,deleteSupplement}) (SupplementsTemplate)