import {
  Box,
  Select,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { useLocation} from 'react-router-dom'
import BuyGetForm from './components/BuyGetForm'
import React, { useState, useEffect } from 'react'
import SpendGetForm from './components/SpendGetForm'
import EditDealForm from './components/EditDealForm'


const AddDeal = ({restaurants, setRestaurants}) => {
  const {state: { data }} = useLocation()
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('spend_x_get_y')

  useEffect(() => {
    if (data) {
      setType(data.type)
    }
  }, [data])

  return (
    <Box my={10}>
      <center><h2> { data ?  "MODIFIER L'OFFRE" : 'AJOUTER UNE OFFRE' }</h2></center>
      <Container maxWidth="sm">
        {!data && <FormControl fullWidth>
          <InputLabel id="discount" shrink>
            Type de promotion
          </InputLabel>
          <Select
            open={open}
            value={type}
            id="discount"
            variant="outlined"
            labelId="discount"
            label="Type de promotion"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(event) => setType(event.target.value)}
          >
            <MenuItem value="spend_x_get_y">Dépensez X - Recevez Y</MenuItem>
            <MenuItem value="buy_x_get_y">X Acheté(s) = Y Offert</MenuItem>
          </Select>
        </FormControl>}

        {!data ? type === 'spend_x_get_y' ? <SpendGetForm data={data} /> : <BuyGetForm data={data} /> : <EditDealForm data={data} restaurants={restaurants} setRestaurants={setRestaurants} />}
      </Container>
    </Box>
  )
}

export default AddDeal
