import firebase from 'firebase/app'
import '@firebase/messaging'

import { firebaseConfig } from './config'

firebase.initializeApp(firebaseConfig)
export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null
Notification.requestPermission().then(permission => {
  if (permission === 'granted' && messaging) {
    messaging.getToken().then(token => {
      localStorage.pushToken = token
    })
  }
})

export default firebase
