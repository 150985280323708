import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { parsePrice } from 'core.utils/parser/price'
import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'
import {
  selectors as currentRestaurantOptionsSelectors,
  deleteRestaurantOptionRequested,
  fetchRestaurantOptionsRequested,
  confirmDeleteRestaurantOptionRequested,
} from 'core.modules/currentRestaurant/options/reducer'
import {
  selectors as currentRestaurantInfosSelectors,
} from 'core.modules/currentRestaurant/infos/reducer'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'

import SelectRestaurant from 'components/SelectRestaurant'
import OptionEdit from 'components/OptionEdit'
import Dialog from 'core.ui/Dialog'
import List from 'core.ui/List'
import {option} from 'core.services/api'
import { sortBy } from 'lodash';
import { fetchTemplateOptionsRequested } from '../../core.modules/currentRestaurant/options/reducer'
import { product } from '../../core.services/api'
const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  }
}

class Options extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog:false,
      openConfirmDeleteDialog:false,
      usedOptionErrorMessage:''
    }
  }

  componentDidMount() {
    this.props.fetchAllRestaurants()
  }

  parseChoices = choices => {
    return choices.map(c => (
      <li>{`${c.type} — ${parsePrice(c.price)}`}</li>
    ))
  }
  
  makeRows = options => {
    const opt = sortBy(options, "order")
    return opt.map(o => [o.name, o.tax+' %', this.parseChoices(o.optionTypes),o.order])
  }

  makeIds = options => {
    const opt = sortBy(options, "order")
    return opt.map(p => p._id)
  }

  handleEdit = id => {
    const { history } = this.props
    history.push(`/restaurants/options/edit?id=${id}`)
  }

  handleDuplicate = id => {
    this.props.duplicateProduct(id)
  }

  handleDelete = id => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: id
    })
  }

  deleteOption = () => {
    // this.props.deleteProduct(this.state.idToDelete)
    const {fetchRestaurantOptions,currentRestaurantId} = this.props
    option.deleteOption(currentRestaurantId,this.state.idToDelete)
    .then(()=>{
      this.closeDeleteDialog()
      fetchRestaurantOptions(currentRestaurantId)
    })
    .catch((err)=>{
      console.log(err.response.data.errors[0].message)
      this.setState({usedOptionErrorMessage:err.response.data.errors[0].message})
      this.setState({openDeleteDialog:false})
      this.setState({openConfirmDeleteDialog:true})
    })
  }

confirmDelete = () => {
  const {fetchRestaurantOptions,currentRestaurantId} = this.props
  option.confirmDeleteOption(currentRestaurantId,this.state.idToDelete)
  .then(()=>{
    fetchRestaurantOptions(currentRestaurantId)
    this.closeConfirmDeleteDialog()
  })
  .catch((err)=>{
    console.log(err)
    this.closeConfirmDeleteDialog()
  })
}

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  closeConfirmDeleteDialog = () => {
    this.setState({
      openConfirmDeleteDialog: false,
      idToDelete: null
    })
  }

  handleSuccess =() => {
    const {fetchRestaurantOptions,currentRestaurantId} = this.props
    fetchRestaurantOptions(currentRestaurantId)
  }

  render() {
    const { openDeleteDialog,openConfirmDeleteDialog } = this.state
    const { classes, city, currentRestaurantOptions, currentRestaurantId, history, currentRestaurant, templates, cities } = this.props

    const restaurantCityId = currentRestaurant.city && (currentRestaurant.city._id || currentRestaurant.city._id) 
    const restaurantCity = cities.find(city => city._id === restaurantCityId)

    return (

      <Switch>
        <Route
          path="/restaurants/options/edit"
          render={props => {
            const id = props.location.search.split('=')[1]
            const data = currentRestaurantOptions.options.find(
              p => p._id === id || p.id === id
            )
            /* console.log(data.name)
              option.getProductsByOption(data.name).then((p) => {p.map(item =>products.push(item))})
              console.log(products)*/
            return (
              <OptionEdit
             //  products= {products}
                data={data}
                currentRestaurant={currentRestaurant}
                currentRestaurantId={currentRestaurantId}
                onSuccess={this.handleSuccess}
                history={history}
                templates={templates}
                restaurantCity={restaurantCity}
              />
            )
          }}
        />
        <Route path="/restaurants/options" exact>
          <div key="list-main-container" className={classes.mainListContainer}>
            <div className={classes.header}>
              <SelectRestaurant key="select-restaurants" city={city} />
            </div>
            <List
              key="list-of-options"
              list={this.makeRows(currentRestaurantOptions.options)}
              columns={['Nom', 'TVA', 'Choix','Ordre']}
              ids={this.makeIds(currentRestaurantOptions.options)}
              actions={{
                handleEdit: this.handleEdit,
                handleDelete: this.handleDelete,
              }}
              emptyMessage="Ce restaurant n’a aucune option"
            />
            <Dialog
              open={openDeleteDialog}
              dialogText="continuer ?"
              dialogTitle="Supprimer l'option"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={this.closeDeleteDialog}
              onValidation={this.deleteOption}
            />

            <Dialog
              open={openConfirmDeleteDialog}
              dialogText={this.state.usedOptionErrorMessage}
              dialogTitle="Confirmer la suppression"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={this.closeConfirmDeleteDialog}
              onValidation={this.confirmDelete}
            />
          </div>
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurantOptions: currentRestaurantOptionsSelectors.getState(state),
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRestaurant:state.currentRestaurant.infos,
  templates:state.templates,
  cities: citiesSelectors.getAllCities(state)
})

const mapDispatchToProps = dispatch => ({
  fetchAllRestaurants: () => {
    dispatch(fetchAllRestaurantsRequested())
  },
  fetchRestaurantOptions: restaurantId => {
    dispatch(fetchRestaurantOptionsRequested(restaurantId))
  },
  fetchTemplateOptions:templateId=>{
    dispatch(fetchTemplateOptionsRequested(templateId))
  },
  deleteRestaurantOption: (restaurantId, optionId) => {
    dispatch(deleteRestaurantOptionRequested(restaurantId, optionId))
  },
  confirmDeleteRestaurantOption: (restaurantId, optionId) => {
    dispatch(confirmDeleteRestaurantOptionRequested(restaurantId, optionId))
  }
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Options)
