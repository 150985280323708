import React, { useEffect, useState } from 'react'

import PriorityOrders from './../../../../components/PriorityOrders'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'

const PrioritiesComponent = ({ classes, orders }) => {
  const [page, setPage] = useState(0)
  const [pageStart, setPageStart] = useState(0)
  const limit = 9

  const updatePage = (value) => {
    setPage(() => {
      const newValue = page + value
      return newValue >= 0 ? newValue : page
    })
  }

  useEffect(() => {
    setPageStart(page * limit)
  }, [page])

  return (
    <div style={{ width: '100%' }}>
      <div
        className={classes.pageButtons}
        style={{ marginBottom: '10px', textAlign: 'center' }}
      >
        <ButtonWithIcon
          className="list-left-arrow"
          onClick={() => updatePage(-1)}
          featherIcon="ArrowLeft"
        />
        Page : {page + 1}
        <ButtonWithIcon
          className="list-right-arrow"
          onClick={() => updatePage(1)}
          featherIcon="ArrowRight"
        />
      </div>
      <PriorityOrders
        orders={orders.slice(pageStart, pageStart + limit)}
        onRefreshOrders={() => {}}
      />
    </div>
  )
}

export default PrioritiesComponent
