import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Snackbar from 'core.ui/Snackbar'

import FilterOrderStatus from './FilterOrderStatus'
import FilterAllCities from './FilterAllCities'
import FilterSearchBarCustom from './FilterSearchBarCustom'
import FilterByDateRange from './FilterByDateRange';

import { setFilters, forceRefreshAll, toggleRushMode } from './../ui-slice'

const FiltersComponent = function ({ classes }) {
  const dispatch = useDispatch()
  const { isLoading, filters } = useSelector((state) => state.uiOrderList)
  const [searchActive, setSearchActive] = useState(false)

  const [open, setOpen] = React.useState(false)

  const [snackBarMessage, setSnackbarMessage] = useState('')

  const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

  // On search change
  useEffect(() => {
    const hasSearchFilters = filters.search?.type && filters.search?.value
    setSearchActive(hasSearchFilters)
  }, [filters.search])

  const dispatchFilters = async (filters) => {
    try {
      await dispatch(setFilters(filters))
    } catch (e) {
      console.log('Error on set filters', e)
      const errors = e?.response?.data?.errors

      if(Array.isArray(errors)) {
        setSnackbarMessage(errors[0]?.message)
        setOpen(true);
      }
    }
  }

  return (
    <>
    <div className={classes.topFiltersContainer}>
      <div className={classes.leftSideFilters}>
        <a style={{ marginBottom: '10px' }} href="https://docs.google.com/forms/d/e/1FAIpQLSfBQu8AS11DiWjFUk0tFLghxncgHkDHvsouAA_qA-rMREBiUA/viewform?usp=sf_link"
        target="_blank">
          Incident
        </a>
        <FilterOrderStatus
          classes={classes}
          filterValue={filters.orderStatus}
          onChange={(orderStatus) =>
            !isLoading && dispatchFilters({ orderStatus })
          }
        />
        <FilterAllCities
          classes={classes}
          checked={filters.allCities}
          onChange={(e) =>
            !isLoading && dispatchFilters({ ...filters ,allCities: e.target.checked })
          }
        />
        <FilterByDateRange
          classes={classes}
          checked={filters.byDate}
          onChange={(e) => {
            if(!isLoading) {
              dispatchFilters({ ...filters, byDate: e.target.checked })

              if(e.target.checked) {
                dispatch(toggleRushMode(false, permissions.order && permissions.order?.displayAll === false))
              }
            }
           }  
          }
        />
      </div>
      <div className={classes.rightSideFilters}>
        <div style={{marginBottom: '10px'}}></div>
        <FilterSearchBarCustom
          classes={classes}
          showClearBtn={searchActive}
          onSubmit={(search) => !isLoading && dispatchFilters({ search })}
          onClear={() => !isLoading && dispatchFilters({ search: {} })}
          refreshAll={() => !isLoading && dispatch(forceRefreshAll())}
        />
      </div>
    </div>
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      severity={'error'}
      message={snackBarMessage}
    />
    </>
  )
}

export default FiltersComponent
