import React from 'react'
import MuiSelect from '@material-ui/core/Select'

class Select extends React.Component {
  componentDidMount() {
    this.selectFirstElement()
  }

  componentDidUpdate(prevProps) {
    this.selectFirstElement()
  }

  selectFirstElement = () => {
    const { value, options, onChange, autoFill = true } = this.props
    if (autoFill && !value && value !== options[0] && onChange) {
      onChange({ target: { value: options[0] } })
    }
  }

  render() {
    const {
      options = [],
      captions = [],
      disabled,
      emphasized,
      colors,
      value,
      onChange,
      autoFill,
      ...props
    } = this.props
    return (
      <MuiSelect
        native
        variant="outlined"
        value={value}
        onChange={onChange}
        {...props}
      >
        {makeOptions(options, captions, disabled, emphasized, colors)}
      </MuiSelect>
    )
  }
}

export function makeOptions(
  options,
  captions,
  disabled,
  emphasized = [],
  colors
) {
  return options.map((o, i) => {
    const style = {
      ...(colors && { color: colors[o] }),
      ...(emphasized.includes(o) && { fontWeight: '500' })
    }
    const props = {
      key: i,
      value: o,
      ...(disabled && { disabled: disabled.includes(o) }),
      ...((colors || emphasized.includes(o)) && { style })
    }
    return <option {...props}>{captions[i]}</option>
  })
}

export function parseMultipleSelection(event) {
  const { options } = event.target
  return Array.from(options)
    .filter(o => o.selected)
    .map(o => o.value)
}

export default Select
