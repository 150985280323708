import React from 'react'
import PropTypes from 'prop-types'

const RushCurrentStatus = ({ classes, isActive, filterValue }) => {  
  const translatedStatus = {
    'waiting for payment': 'Attente paiement',
    processing: 'En attente',
    later: 'En attente (Plus tard)',
    cooking: 'En cours de préparation',
    'waiting for rider': 'Recherche livreur',
    'pre assigned': 'Pré assigné',
    'driver assigned': 'En route restaurant',
    collected: 'En route client',
    priorityOrders: 'Prioritaire',
    current: 'Aucun',
    "40-50": "40 - 50 min",
    "50-60": "50 - 60 min",
    "60-90": "1h - 1h30",
    "90-120": "1h30 - 2h",
    ">120": "> 2h",
    null: 'Aucun'
  }
  const blackText = ['current', 'processing', 'later', null]

  const statusColor = (status) => {
    switch (status) {
      case 'waiting for payment':
        return classes.orange

      case 'processing':
        return classes.yellow

      case 'later':
        return classes.yellow

      case 'cooking':
        return classes.skyBlue

      case 'waiting for rider':
        return classes.red

      case 'pre assigned':
        return classes.violet

      case 'driver assigned':
        return classes.grey

      case 'collected':
        return classes.darkGreen

      case 'priorityOrders':
        return classes.priorityOrdersColor
      
      case ">120":
        return classes.red

      case "current": 
        return classes.black
        
      default:
        return classes.green
    }
  }

  if (!isActive) {
    return null
  }

  return (
    <div className={(classes.currentFilter, statusColor(filterValue))} style={{padding: 8, borderRadius:5}}>
      <span
        className={
          blackText.includes(filterValue) ? classes.black : classes.white
        }
      >
        Filtre actuel:
      </span>
      <span
        className={
          blackText.includes(filterValue) ? classes.black : classes.white
        }
      >
        {' '}
        {translatedStatus[filterValue]}
      </span>
    </div>
  )
}

RushCurrentStatus.propTypes = {
  classes: PropTypes.object,
  rushMode: PropTypes.bool,
  filterValue: PropTypes.string
}

export default RushCurrentStatus
