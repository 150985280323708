import React, {useState} from 'react'
import { useDispatch } from 'react-redux'

import { getDefaultRegulation } from '../../../../core.utils/parser/regulationEnums'
import RegulationDialog from "../../../../components/RegulationForm/RegulationDialog"
import ButtonWithIcon from '../../../../core.ui/ButtonWithIcon'
import { onSetSnackbar } from "../ui-slice"

export default function RegulationContainer({ order, classes,claim,regulationToEdit }) {
    const dispatch = useDispatch()

    const [isVisible, setisVisible] = useState(false)

    const toggle = () => {
        setisVisible(val => !val)
    }

  return (
   <>          
        <ButtonWithIcon className={classes.actionButton} onClick={toggle} featherIcon="FilePlus" />
        <RegulationDialog
            regulationToEdit={regulationToEdit || getDefaultRegulation()}
            isVisible={isVisible}
            toggleDialog={toggle}
            order={order}
            claim={claim}
            orderId={order.id || order._id}
            onSubmitSuccess={() => {
                dispatch(onSetSnackbar({ message: 'Régulation enregistrée', severity: 'success' }))
            }}
        />
    </>      
  );
}
