import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getOrderClaim,
  goback,
  RespondToClaim
} from '../../core.modules/Claims/Actions'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'

import ClaimDetail from './ClaimDetail'

export const ReclamationEnums = {
  ReclamationTypes: {
    MISSING_PRODUCT: {
      VALUE: 'missing_product',
      SUB_TYPES: {
        SUB_TYPE_1: 'sub_type_1',
        SUB_TYPE_2: 'sub_type_2'
      }
    },
    DAMAGED_PRODUCT:{
      VALUE: 'damaged_product',
      SUB_TYPES: {
        SUB_TYPE_1: 'sub_type_1',
        SUB_TYPE_2: 'sub_type_2'
      }
    },
    OTHER: {
      VALUE: 'other',
      SUB_TYPES: {
        OTHER_SUB_TYPE: 'other_sub_type'
      }
    }
  },
  ReclamationStatuses: {
    PENDING: 'pending',
    REJECTED: 'rejected',
    CLOSED:'closed',
    REFUNDED: 'refunded',
    PARTIAL: 'partially_refunded'
  }
}
const ClaimList = ({
  getOrderClaim,
  Claims: { OrderClaims, loading },
  goback,
  match,
  RespondToClaim
}) => {
  useEffect(() => {
    getOrderClaim(match.params.id)
  }, [getOrderClaim, match.params.id])

  return (
    <div>
      <Link
        to="/Claims/ordergroup"
        style={{ textDecoration: 'none', color: 'black' }}
        onClick={goback}
      >
        <ArrowBackIcon /> Retour en arriere
      </Link>
      <ClaimDetail
        OrderClaims={OrderClaims}
        loading={loading}
        RespondToClaim={RespondToClaim}
      />
    </div>
  )
}
const mapStateToProps = (state) => ({
  Claims: state.Claims
})

export default connect(mapStateToProps, {
  getOrderClaim,
  goback,
  RespondToClaim
})(ClaimList)
