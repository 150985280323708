import { category } from 'core.services/api'

export const CATEGORIES_FETCH_REQUESTED = 'CATEGORIES_FETCH_REQUESTED'
export const CATEGORIES_FETCH_SUCCEEDED = 'CATEGORIES_FETCH_SUCCEEDED'
export const CATEGORIES_FETCH_FAILED = 'CATEGORIES_FETCH_FAILED'

export const categoriesFetchRequested = () => dispatch => {
  dispatch({
    type: CATEGORIES_FETCH_REQUESTED
  })
  category
    .getAll()
    .then(data => {
      dispatch(categoriesFetchSucceeded(data))
    })
    .catch(error => {
      console.error(error)
      dispatch(categoriesFetchFailed(error))
    })
}

export const categoriesFetchSucceeded = categories => {
  return {
    type: CATEGORIES_FETCH_SUCCEEDED,
    payload: {
      categories
    }
  }
}
export const categoriesFetchFailed = error => {
  return {
    type: CATEGORIES_FETCH_FAILED,
    payload: {
      error
    }
  }
}

export const defaultState = []
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case CATEGORIES_FETCH_SUCCEEDED:
      const { categories } = action.payload
      return categories
    case CATEGORIES_FETCH_FAILED:
      return defaultState
    default:
      return state
  }
}

const getState = state => state.categories
const getCategoriesNames = state => getState(state).map(c => c.name)

export const selectors = {
  getState,
  getCategoriesNames
}
