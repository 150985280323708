import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { Autocomplete } from '@material-ui/lab'

import { isEmpty } from 'lodash';

import { issue } from 'core.services/api'

export default function FormDialog({open, onClose, order, restaurant}) {
  const cityId = localStorage.getItem('selectedCity');
  const [state, setState] = React.useState({
    reason: "",
    type: "",
    description: "",
    concernedParty: "",
    status: "opened",
    riderId: order?.riderId,
    restaurantId: restaurant?._id,
    cityId: cityId,
    origin: "order"
  })

  const [types, setTypes] = React.useState([])
  const [reasons, setReasons] = React.useState(null)

  React.useEffect(() => {
    issue.getConfig().then(data => {
      setTypes(data.types)
      setReasons(data.reasons)
    })
  }, [])

  React.useEffect(() => {
    setState({ ...state, type: types.find((type) => !!type.inOrderDetails )?.value})
  }, [types])

  React.useEffect(() => {
    setState({...state, riderId: order?.riderId?._id, restaurantId: restaurant?.id})
  }, [order?.riderId, restaurant?._id])

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.value});
  };

  const handleSubmit = () => {
    const body = { ...state }

    if (state.concernedParty === "rider")
      delete body.restaurantId
    else
      delete body.riderId

    issue.createIssue(body).then(data => {
      onClose();
    }).catch((err) => alert(err?.response?.data?.errors[0]?.message))
  }

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Tracking :  {restaurant?.nom} </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="concernedParty">Partie concernée</InputLabel>
            <Select
              fullWidth
              labelId="concernedParty"
              name='concernedParty'
              value={state.concernedParty}
              label="Partie concernée"
              onChange={handleChange}
            >
              {restaurant && <MenuItem value={"restaurant"}>Restaurant</MenuItem>}
              {order?.riderId && <MenuItem value={"rider"}>Livreur</MenuItem>}
            </Select>
          </FormControl>
          <br/><br/>
          <FormControl fullWidth>
            <InputLabel id="type">Type</InputLabel>
            <Select
              fullWidth
              labelId="type"
              name='type'
              value={state.type}
              label="Type"
              onChange={handleChange}
            >
              {types.filter((type) => !!type.inOrderDetails).map((type) => (<MenuItem value={type.value}>{type.text}</MenuItem>))}
            </Select>
          </FormControl>
          <br/><br/>
          {!isEmpty(state.concernedParty) && reasons !== null && <FormControl fullWidth>
            <Autocomplete
                required
                options={state.concernedParty === "restaurant" ? reasons?.restaurantReasons : reasons?.riderReasons}
                getOptionLabel={(option) => option.text}
                getOptionSelected={(option) => option.text}
                onChange={(event, value) =>
                  setState({...state, reason: value.value})
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Titre"
                    variant="outlined"
                  />
                )}
              />
          </FormControl>}
          <br/>
          <TextField
            style={{ fontColor: "green" }}
            autoFocus
            margin="dense"
            id="description"
            name="description"
            onChange={handleChange}
            label="Commentaire"
            multiline
            rows={3}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
        <Button  style={{color:"black"}} onClick={onClose}>ANNULER</Button>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
  );
}