import React from 'react'
import { Chip } from '@material-ui/core'
import { Cancel, Check, PlayArrow } from '@material-ui/icons'

const Status = ({ state }) => {
  if (state === 'not_started') {
    return <Chip icon={<Cancel />} label="Pas débuté" />
  } else if (state === 'active') {
    return (
      <Chip
        icon={<PlayArrow />}
        label="En cours"
        style={{ backgroundColor: '#c8e6c9' }}
      />
    )
  } else {
    return (
      <Chip
        icon={<Check />}
        label="Terminé"
        style={{ backgroundColor: '#ffcdd2' }}
      />
    )
  }
}

export default Status
