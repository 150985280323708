import { combineReducers } from 'redux'
import infos from './infos/reducer'
import products from './products/reducer'
import options from './options/reducer'
import supplements from './supplements/reducer'
import supplementGroups from './supplementGroups/reducer'
import deals from './deals/reducer'

const reducers = {
  infos,
  products,
  options,
  supplements,
  supplementGroups,
  deals
}

export default combineReducers(reducers)

const getState = state => state.currentRestaurant
const getInfos = state => getState(state).infos
const getOptions = state => getState(state).options
const getSupplements = state => getState(state).supplements
const getproducts = state => getState(state).products
const getSupplementGroups = state => getState(state).supplementGroups
const getRestaurantDeals = state => getState(state).deals

export const selectors = {
  getState,
  getInfos,
  getOptions,
  getSupplements,
  getproducts,
  getSupplementGroups,
  getRestaurantDeals
}
