export const makeRiderFullName = (rider = {}) =>
  rider ? `${rider.firstName} ${rider.lastName}` : ''


  export const riderTransportTranslated = {
    car: 'Voiture',
    scooter: 'Scooter',
    bike: 'Vélo'
  }

const capitalize = str => str && str.toLowerCase().replace(/\b\w/g, function(l){ return l.toUpperCase() })