import React, {Component, useState, useEffect} from 'react'
import {
  useParams 
} from "react-router-dom";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormControlLabel, Switch, Button } from '@material-ui/core'
import List from 'core.ui/List'
import { withStyles } from '@material-ui/core/styles'
import Select from 'core.ui/Select'
import MuiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { isEmpty } from 'lodash';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeDateString } from 'core.utils/parser/time'
//import { issue } from 'core.services/api'

import { issue } from 'core.services/api'

import ModalAddTracking from "./Addtracking"

import styles from './styles'

import {
	selectors as ridersSelectors
} from 'core.modules/riders/reducer';
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'

const rushSwitchStyle = {
  marginTop: 40,
  marginLeft: 300,
  marginBottom: 40,
  alignItems: 'center'
}

const statusColorMapping = {opened: "#689f38", closed: "#f44336"}
const statuses = ['opened', 'closed']

const RidersTracking = ({classes, currentCityRiders, cities = []}) => {
  const { riderId } = useParams()
  const [checked, setChecked] = useState(false);
  const [parsedIssues, setParsedIssues] = useState([])
  const [trackings, setTrackings] = useState([])
  const [state, setState] = useState({
    currentPage: 1,
    numberPerPage: 20,
  })
  const [reload, setReload] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false)
  const [rider, setRider] = useState(null)
  const [cityRider, setCityRider] = useState(null);
  const [types, setTypes] = useState([])
  const [type, setType] = useState(null);
  const [reasons, setReasons] = useState([])

  React.useEffect(() => {
    issue.getConfig().then(data => {
      setReasons(data.reasons)
      setTypes(data.types)
    })
  }, [])

  useEffect(() => {
    let cityId = null;

    if(!checked) {
      cityId = localStorage.getItem('selectedCity')
    }

    issue.getIssues({cityId,
      concernedParty: "rider", 
      page: state.currentPage, 
      limit: state.numberPerPage,
      riderId,
      status: riderId ? null : "opened",
      type
    }).then(data => {
      setTrackings(data)
    })
    .catch(error => {
      console.error(error.message)
      setTrackings([])
    }) 
  }, [state.currentPage, checked, reload, type])


  useEffect(() => {
    setParsedIssues(parseIssues())
  }, [trackings])

  useEffect(() => {
    if(riderId) {
      const rider = currentCityRiders.find(rider => rider._id === riderId)
      setRider(rider)

      if(rider) {
        const hisCity = cities.find(city => city._id === rider?.city)

        setCityRider(hisCity)
      }
    }
  }, [riderId])

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  const translateReasonIssue = (reason) => {
    const findValue = reasons?.riderReasons.find(t => t.value === reason)

    if(findValue)
      return findValue.text;

    return null;
	 };
   
  const parseIssues = () => {
    return trackings
      .map((track) => {
        const {
          created_at,
          riderId: {
            firstName,
            lastName,
            phone
          },
          cityId,
          reason,
          description,
          status,
          updated_at,
          addedBy,
          _id
        } = track

        return [
          makeDateString(created_at),
          firstName,
          lastName,
          phone,
          cityId?.name,
          translateReasonIssue(reason),
          description,
          makeStatusSelect(status, _id),
          makeDateString(updated_at),
          `${addedBy?.userId?.firstName} ${addedBy?.userId?.lastName}`
        ]
      })
  }

  const makeStatusSelect = (
    status = 'opened',
    id
  ) => {
    return (
      <Select
        style={{ backgroundColor: statusColorMapping[status] }}
        value={status}
        options={statuses}
        disabled={[]}
        captions={['Ouvert', 'Fermer']}
        autoFill={false}
        onChange={handleChangeSelect(id)}
        colors={statusColorMapping}
      />
    )
  }

  const handleChangeSelect = (id) => e => {
    const value = e.target.value

    issue.changeStatus({id, status: value}).then(data => {
      setTrackings(trackings.map(track => track._id === id ? ({...track, status: value}) : track))
    })
  }

  const handlePageChange = page => {
    setState({...state, currentPage: page }, () => {
      this.getRestaurantRequests()
    })
  }

  const handleClose = () => {
    setOpenTrackingModal(false);
  };

  const handleChange = (value) => {
    setType(value);
  };

  const ids = trackings.map((t) => t._id)

  
  return    <div>
                <div style={{display: "flex", justifyContent: !riderId ? "space-between" : "flex-end", alignItems: "center", marginTop:30}}>
                {!riderId && <div style={rushSwitchStyle}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={checked}
                        onChange={switchHandler}
                      />
                    }
                    label="Toutes les villes"
                  />
                  <p> Ouverts:</p>	<FormControl style = {{alignItems:'center'}}>
										<ToggleButtonGroup
											value={type}
											exclusive
											onChange={(e, value) => {
												handleChange(value);
											}}
											aria-label="text alignment"
										>
                      <ToggleButton value={null} aria-label="centered">
												<span>TOUT</span>
											</ToggleButton>
                      {types?.map((type) => (
											<ToggleButton value={type?.value} aria-label="left aligned">
												<span>{type?.text}</span>
											</ToggleButton>
                      ))}
										</ToggleButtonGroup>
									</FormControl>
                </div>}
                </div>
                
             <div>
            {
              riderId && <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center', 
                fontSize: 20,
                width: '100%',
								backgroundColor: 'lightgrey',
								paddingTop: 5,
								paddingBottom:5,
              }}>

                Livreur : {rider && rider?.firstName} {rider && rider?.lastName} - {rider && cityRider?.name}
                
              </div>
            }
            <br></br>
            {
              riderId && <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>



<FormControl style = {{display: 'flex', marginRight:10 }}>
										<ToggleButtonGroup 
											value={type}
											exclusive
											onChange={(e, value) => {
												handleChange(value);
											}}
											aria-label="text alignment"
										>
                      <ToggleButton value={null} aria-label="centered">
												<span>TOUT</span>
											</ToggleButton>
                      {types?.map((type) => (
											<ToggleButton value={type?.value} aria-label="left aligned">
												<span>{type?.text}</span>
											</ToggleButton>
                      ))}
										</ToggleButtonGroup>
									</FormControl>

                  <Button variant="contained" color="secondary" onClick={() => setOpenTrackingModal(true)}>
                    Ajouter un ticket
                  </Button>

              </div>
            }

            <List 
              style={{
                width: '0%'
              }}
              columns={[
                'Création',
                'Nom',
                'Prenom',
                'Téléphone',
                'Ville',
                'Titre',
                'Commentaire',
                'Statut',                
                'Mise à jour',
                'Ajouté par'
              ]}
              list={parsedIssues}
              ids={ids}
              actions={{
                
              }}
              customPage={state.currentPage}
              numberPerPage={state.numberPerPage}
              onPageChange={handlePageChange}
            />
          </div>

          {openTrackingModal && riderId &&  
          <ModalAddTracking open={openTrackingModal} setReload={setReload} reload={reload} onClose={handleClose} riderId={riderId} restaurant={"nom"} />
          }       
        </div>
           
}

const mapStateToProps = (state) => ({
	currentCityRiders: ridersSelectors.getCurrentCityRiders(state),
  cities: citiesSelectors.getAllCities(state)
});

export default compose(connect(mapStateToProps), withStyles(styles))(RidersTracking);