import React from 'react'
import classNames from 'classnames'
import {
  Button,
  Divider,
  InputLabel,
  FormControl,
  FormLabel,
  Grid
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'
import ItemList from 'core.ui/ItemList'
import ProductOptionChoice from 'components/ProductOptionChoice'
import TextField from 'core.ui/TextField'
import Select from 'core.ui/Select'

const styles = {
  mainContainer: {
    width: '100%'
  },
  nameTaxContainer: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labels: {
    marginBottom: '10px'
  },
  nameTextField: {
    width: '175px'
  },
  taxLabel: {
    marginLeft: '15px'
  },
  select: {
    height: '56px',
    minWidth: '80px',
    margin: '0 6px'
  },
  optionsList: {
    marginBottom: '15px'
  },
  divider: { height: '1px', marginBottom: '15px' }
}

export const newOptionChoice = {
  price: 0,
  isAvailable: true,
  type: '',
  isAbstract: false

}



class CreateOption extends React.Component {
  constructor(props) {
    super(props)
    const defaultState = {
      option: { optionTypes: [], name: '', tax: 10,order:'',reference:'',templateId:this.props.templates.templateid }
    }
    this.state = defaultState
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      this.setState({ option: value })
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.setState({ option: value })
    }
  }

  handleChange = field => e => {
    const { value } = e.target
    const { option } = this.state
    if(field ==='reference'){
     
      this.setState({ option: { ...option, ['reference']: value.trim() } })
    } else {
      this.setState({ option: { ...option, [field]: value } })
    }
   
  }

  handleSubmit = () => {
    const { option } = this.state
    this.props.onSubmit({ target: { value: option } })
    // this.setState({option:{ optionTypes: [], name: '', tax: 10,order:'',reference:'',templateId:this.props.templates.templateid }})
  }

  render() {
    const { className, classes, currentRestaurant, templates, restaurantCity } = this.props
    const { option } = this.state

    return (
      <FormControl
        className={classNames(
          'main-container',
          classes.mainContainer,
          className
        )}
        component="fieldset"
      >
        <FormLabel className={classNames(classes.labels)} component="legend">
          Ajouter une option
        </FormLabel>
        <div className={classes.nameTaxContainer}>
          <TextField
            className={classNames(
              'create-option-name-textfield',
              classes.nameTextField
            )}
            value={option.name}
            label="Nom"
            onChangeComplete={this.handleChange('name')}
          />
          <FormControl>
            <InputLabel className={classes.taxLabel}>TVA</InputLabel>
            {restaurantCity && <Select
              className={classes.select}
              options={[0, ...restaurantCity?.pricing?.taxValues?.map(tax => tax)]}
              value={option.tax}
              captions={["0 %", ...restaurantCity?.pricing?.taxValues?.map(tax => `${tax} %`)]}
              onChange={this.handleChange('tax')}
            />}
          </FormControl>
          <TextField
            className={classNames(
              'create-option-name-textfield',
              classes.nameTextField
            )}
            type="number"
            value={option.order}
            label="Ordre"
            onChangeComplete={this.handleChange('order')}
          />
          {(currentRestaurant.posInfo?.type || templates.checkTemplate) ?
          <TextField
            className={classNames(
              'create-option-name-textfield',
              classes.nameTextField
            )}
            value={option.reference}
            label="Reference"
            onChangeComplete={this.handleChange('reference')}
          />:<div></div>} 
        </div>
        

        <Divider className={classes.divider} />

        {/* LISTE DES CHOIX */}
        <FormLabel className={classNames(classes.labels)} component="legend">
          Choix disponibles
        </FormLabel>

        <ItemList
          className={classes.optionsList}
          list={option.optionTypes}
          propsToGiveToEachItem={['price', 'isAvailable', 'type','isAbstract']}
          componentToRender={ProductOptionChoice}
          itemTemplate={newOptionChoice}
          emptyLabel="Aucun choix"
          onChange={this.handleChange('optionTypes')}
          showStockSwitch={false}
          currentRestaurant={currentRestaurant}
          templates={templates}
        />

        {/* BOUTON CRÉER L’OPTION */}
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Button
            className="create-option-submit-button"
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
          >
            Créer l'option
          </Button>
        </Grid>
      </FormControl>
    )
  }
}

export default withStyles(styles)(CreateOption)
