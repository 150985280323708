import React from 'react';

export default function DetailsAction({ buttons, orderId }) {
	if (!buttons.includes('details')) {
		return null;
	}

	return (
		<a 
			href={`https://admin.lyveat.com/commandes/${orderId}`}
			target="_blank"
			style={{ color:"green", marginRight:10, fontSize:14 }} 
		>
			Détails
		</a>
	);
}
