import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { CheckCircle, XCircle,RefreshCw } from 'react-feather'
import {
  selectors as restaurantsSelectors
} from 'core.modules/restaurants/reducer'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import { selectors as planningConfigsSelectors } from 'core.modules/planningConfigs/reducer'

import List from 'core.ui/List'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'
import SearchBar from 'core.ui/SearchBar'

import { fetchRestaurantsStatusRequested } from '../../core.modules/restaurants/reducer'
import RestaurantLogs from 'components/RestaurantLogs'
import { Button, FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Snackbar from 'core.ui/Snackbar'

import { restaurant } from 'core.services/api'
import { getWarningsByCityRequested, getPlanningConfigByCityRequested } from '../../core.modules/planningConfigs/reducer'
import { planningConfigAPI, rider } from '../../core.services/api'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  topBarControls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabs:{
    marginBottom: '20px',
    width:'80%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noAlertText:{
    padding:10,
    textAlign:'center',
  }
}

class RestaurantsStatusList extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      searchedRestaurants: null,
      displayingSearchResult: false,
      openSnackBar: false,
      snackBarMessage: '',
      snackBarSeverity: '',
      warning:{
        week:'current',
        day:0
      },
      isAllCities: false
    }

  }


  componentDidMount() {
    const { fetchRestaurantsStatus, fetchWarningsByCity, fetchPlanningConfigByCity, city } = this.props
    if (city) {
      fetchWarningsByCity(city)
      fetchPlanningConfigByCity(city)
    }
    fetchRestaurantsStatus()
  }

  forceRestaurantOrdersRefresh = id => {
    restaurant
    .forceRestaurantOrdersRefresh(id)
    .then(()=>{
      this.setState({openSnackBar: true , snackBarMessage: 'Refresh effectué' , snackBarSeverity: 'success'})
    })
    .catch(()=>{
      this.setState({openSnackBar: true , snackBarMessage: 'Refresh échoué' , snackBarSeverity: 'error'})
    })
  }

  makeForceRefreshColumn = (id)=>(
    <Button variant="contained" onClick={()=>this.forceRestaurantOrdersRefresh(id)}>
      <RefreshCw/>
    </Button>
  )

  parseRestaurant = (restaurant) => {
    // Nom, Actif, visible, pushToken, socketIO, Commandes en Cours
    const trueValue = <CheckCircle size={24} color={'green'} />
    const falseValue = <XCircle size={24} color={'red'} />
    return [
      this.makeForceRefreshColumn(restaurant.id),
      restaurant.nom,
      restaurant.actif ? trueValue : falseValue,
      restaurant.visible ? trueValue : falseValue,
      restaurant.open ? trueValue : falseValue,
      restaurant.pushToken ? trueValue : falseValue,
      restaurant.socketId ? trueValue : falseValue,
      restaurant.currentOrders,
      restaurant.unansweredOrders,
      restaurant.appVersion ? trueValue : falseValue
    ]
  }

  handleEdit = (id) => {
    const { history } = this.props
    history.push(`/restaurants/edit?id=${id}`)
  }

  searchRestaurants = (query) => {
    const { allRestaurantsStatuses, currentCityRestaurantsStatuses } = this.props
    const { isAllCities } = this.state
    const restaurants = isAllCities ? allRestaurantsStatuses : currentCityRestaurantsStatuses
    const searchedRestaurants = restaurants.filter((restaurant) => 
      restaurant.nom.trim().toLowerCase().includes(query.value.toLowerCase().trim())
      )
    this.setState({searchedRestaurants, displayingSearchResult: true})
  }

  clearSearch = () => {
    this.setState({ searchedRestaurants: null, displayingSearchResult: false })
  }

  showLogs = (id) => {
    const { history } = this.props
    history.push(`/restaurant/logs?id=${id}`)
  }

  getWarningMessagesByType = (weekWarnings, type) => {
    let selectedDay = this.getSelectedDay()
    if (!weekWarnings) {
      return []
    }
    const morningSessionWarningType = weekWarnings?.morningSession ? Object.keys(weekWarnings?.morningSession)
    .filter((key) => key === selectedDay && weekWarnings?.morningSession[key].type === type)
    .map((key) => ({
      day: key,  
      message: weekWarnings?.morningSession[key].message
    })) 
    : []

    const nightSessionWarningType = weekWarnings?.nightSession ? Object.keys(weekWarnings?.nightSession)
    .filter((key) => key === selectedDay && weekWarnings?.nightSession[key].type === type)
    .map((key) => ({
      day: key,
      message: weekWarnings?.nightSession[key].message
    }))
    : []

    return [ ...morningSessionWarningType, ...nightSessionWarningType ]
  }

  markWarningAsRead = (day, weekType) => {
    const { currentPlanningConfig, fetchWarningsByCity, city } = this.props
    planningConfigAPI.setDisplayWarning(currentPlanningConfig.id, day, weekType)
    .then(async() => {
      await fetchWarningsByCity(city)
      this.setState(state=>({openSnackBar:true,snackBarMessage:'Alertes marquées vu',snackBarSeverity:'success'}))
    })
  }

  displayWarnings(warnings, warningType) {
    const weekType = this.state.warning.week === 'current' ? 'currentWeek' : 'nextWeek'
    return warnings.map((warning,i) => (
      <Alert severity={warningType}
      key={`${weekType}-${this.state.warning.day}-${i}`}
      action={
        <Button color="inherit" size="small" onClick={() => { this.markWarningAsRead(warning.day, weekType) }}>
          MARQUER COMME LU
        </Button>
      }
    >
      {warning.message}
    </Alert>
    ))
  }

  handleTabChange = (newValue,type='week') => {
    if(type=='week'){
      let week = newValue === 0 ? 'current' : 'next'
    this.setState(state=>({warning:{...state.warning,week}}))
    } else if(type=='day'){
      this.setState(state=>({warning:{...state.warning,day:newValue}}))
    }
  }

  handleResetOrderCounter = () => {
    rider.resetCounter()
    .then(() => this.setState({ openSnackBar: true , snackBarMessage: 'Compteurs réinitialisés' , snackBarSeverity: 'success'} ))
    .catch((err) => this.setState({ openSnackBar: true , snackBarMessage: err.response.data.errors[0].message , snackBarSeverity: 'error'} ))
  }

  getSelectedDay = () => {
    switch(this.state.warning.day){
      case 0 : {
        return 'monday'
      }
      case 1 : {
        return 'tuesday'
      }
      case 2 : {
        return 'wednesday'
      }
      case 3 : {
        return 'thursday'
      }
      case 4 : {
        return 'friday'
      }
      case 5 : {
        return 'saturday'
      }
      case 6 : {
        return 'sunday'
      } 
    }
  }

  renderCityWarnings = () => {
    const { currentCityWarnings,classes } = this.props
    const { warning } = this.state
    let cityWarnings,alerts,warnings
    if(warning.week === 'current'){
      cityWarnings = currentCityWarnings ? currentCityWarnings[0]?.currentWeek : {}
    }else if(warning.week === 'next') {
      cityWarnings = currentCityWarnings ? currentCityWarnings[0]?.nextWeek : {}
    }
    warnings = this.getWarningMessagesByType(cityWarnings, 'warning')
    alerts = this.getWarningMessagesByType(cityWarnings, 'alert')

    if(!alerts.length && !warnings.length){
      return (
        <div className={classes.noAlertText}>Aucune alerte</div>
      )
    }

    return(
      <div>
        {this.displayWarnings(warnings, 'info')}
        {this.displayWarnings(alerts, 'error')}
      </div>
    )

  }

  toggleIsAllCities = () => this.setState(state => ({ isAllCities : !state.isAllCities }), () => this.props.fetchRestaurantsStatus())

  render() {
    const {
      allRestaurantsStatuses,
      currentCityRestaurantsStatuses,
      currentCityWarnings,
      classes,
      fetchRestaurantsStatus
    } = this.props
    const { displayingSearchResult, isAllCities, searchedRestaurants,warning } = this.state

    const selectedRestaurants = isAllCities ? allRestaurantsStatuses : currentCityRestaurantsStatuses
    const restaurants = displayingSearchResult ? searchedRestaurants.map(this.parseRestaurant) : selectedRestaurants.map(this.parseRestaurant)
    const ids = displayingSearchResult ? searchedRestaurants.map((r) => r.id) : selectedRestaurants.map((r) => r.id)
    
  
    return (

      

      <div className={classes.mainContainer}>
        <div >
        <Paper>
        <Tabs
          value={warning.week === 'current' ? 0: 1}
          onChange={(e,value)=>this.handleTabChange(value,'week')}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Semaine courante" />
          <Tab label="Semaine prochaine" />
        </Tabs>
       </Paper>
        </div>

        <div className={classes.tabs}>
        <Paper>
        <Tabs
          value={warning.day}
          onChange={(e,v)=>this.handleTabChange(v,'day')}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Lundi" />
          <Tab label="Mardi" />
          <Tab label="Mercredi" />
          <Tab label="Jeudi" />
          <Tab label="Vendredi" />
          <Tab label="Samedi" />
          <Tab label="Dimanche" />
        </Tabs>
        {this.renderCityWarnings()}
       </Paper>
        </div>
      
        
        <Switch>
          <Route
            path="/restaurant/logs"
            render={(props) => {
              const id = props.location.search.split('=')[1]
              return <RestaurantLogs id={id} {...props} />
            }}
          />
          <Route
            path="/"
            exact
            render={() => (
              <React.Fragment>
                <div className={classes.topBarControls}>
                  <div className={classes.header}>
                    <SearchBar
                      searchFields={{ nom: 'Nom' }}
                      onSearch={this.searchRestaurants}
                      onClearSearch={this.clearSearch}
                      displayClearSearch={displayingSearchResult}
                    />
                    <ButtonWithIcon
                      featherIcon="RefreshCw"
                      size={20}
                      onClick={fetchRestaurantsStatus}
                    />
                    <FormControlLabel
                      style={{marginLeft: 40}}
                      control={
                        <MuiSwitch
                          color='primary'
                          size="small"
                          checked={isAllCities}
                          onChange={this.toggleIsAllCities}
                        />
                      }
                      label="Toutes les villes"
                    />
                  </div>
                  {localStorage.getItem('role') === 'SUPER_ADMIN' ? (
                    <Button variant="contained" color="secondary" onClick={this.handleResetOrderCounter}>
                      Reset compteur des livreurs
                    </Button>
                  ) : null}
                </div>
                <List
                  list={restaurants}
                  columns={[
                    'Actualiser',
                    'Nom',
                    'Actif',
                    'Visible',
                    'Ouvert',
                    'pushToken',
                    'SocketIO',
                    'Commandes en Cours',
                    'Commandes en attente pendant plus de 5 minutes',
                    'Dernière version App Restaurant'
                  ]}
                  ids={ids}
                  emptyMessage="Aucun restaurant"
                  actions={{
                    showLogs: this.showLogs
                  }}
                  numberPerPage={20}
                />
                <Snackbar
                open={this.state.openSnackBar}
                onClose={()=>{this.setState({openSnackBar:false})}}
                severity={this.state.snackBarSeverity}
                message={this.state.snackBarMessage}
              />
              </React.Fragment>
            )}
          />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentCityRestaurants: restaurantsSelectors.getCityRestaurants(
    state,
    ownProps.city
  ),
  allCities: citiesSelectors.getAllCities(state),
  currentCityRestaurantsStatuses: restaurantsSelectors.getAllRestaurantStatuses(state, ownProps.city),
  allRestaurantsStatuses: restaurantsSelectors.getAllRestaurantStatuses(state),
  currentCityWarnings: planningConfigsSelectors.getWarningsByCity(state, ownProps.city),
  currentPlanningConfig: planningConfigsSelectors.getPlanningConfigByCity(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchRestaurantsStatus: () => {
    dispatch(fetchRestaurantsStatusRequested())
  },
  fetchWarningsByCity: (cityId) => {
    dispatch(getWarningsByCityRequested(cityId))
  },
  fetchPlanningConfigByCity: (cityId) => {
    dispatch(getPlanningConfigByCityRequested(cityId))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(RestaurantsStatusList)
