import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { isEmpty, isNumber } from 'lodash'

import { isPostcodeValid } from 'core.utils/formValidation'

import TextField from 'core.ui/TextField'

const styles = {
  mainContainer: {},
  lines: {
    display: 'flex',
    flexDirection: 'row'
  },
  zipCode: {
    width: '150px',
    marginRight: '10px'
  }
}

const requiredFields = ['streetAdress', 'zipCode', 'city']

export class Address extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: { streetAdress: '', zipCode: '', city: '' },
      validFields: {}
    }
  }

  componentDidMount() {
    this.setState({ form: { ...this.props.value } })
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { form } = this.state
    if (value !== prevProps.value) {
      this.setState({ form: { ...this.props.value } })
    } else if (form !== prevState.form) {
      const validFields = this.getValidFields()
      this.setState({ validFields })
      this.props.handleValidFieldChange(
        Object.values(validFields).every(f => f)
      )
    }
  }

  getValidFields = () => {
    const { form } = this.state
    const validFields = requiredFields.map(field => {
      return field === 'zipCode'
        ? isPostcodeValid(form[field])
        : isNumber(form[field]) || !isEmpty(form[field])
    })
    return Object.fromEntries(requiredFields.map((f, i) => [f, validFields[i]]))
  }

  isFormValid = () => {
    const validFields = this.getValidFields()
    return Object.values(validFields).every(f => f)
  }

  handleChangeComplete = field => e => {
    const { value } = e.target
    this.setState(
      {
        form: {
          ...this.state.form,
          [field]: value
        }
      },
      () => {
        this.props.onChange({
          target: {
            value: this.state.form
          }
        })
        this.props.handleValidFieldChange(this.isFormValid())
      }
    )
  }

  render() {
    const { ClassName, classes, title = '', showErrors } = this.props
    const { form, validFields } = this.state
    const { streetAdress, zipCode, city } = form
    return (
      <div
        className={classnames(
          'address-main-container',
          classes.mainContainer,
          ClassName
        )}
      >
        <div>{title ? title : 'Adresse'}</div>
        <div className={classes.lines}>
          <TextField
            value={streetAdress}
            fullWidth
            label="Rue"
            onChangeComplete={this.handleChangeComplete('streetAdress')}
            error={showErrors && !validFields.streetAdress}
            helperText={
              showErrors && !validFields.streetAdress
                ? 'Ce champ est requis'
                : ''
            }
          />
        </div>
        <div className={classes.lines}>
          <TextField
            value={zipCode}
            className={classes.zipCode}
            label="Code postal"
            onChangeComplete={this.handleChangeComplete('zipCode')}
            error={showErrors && !validFields.zipCode}
            helperText={
              showErrors && !validFields.zipCode ? 'Ce champ est requis' : ''
            }
          />
          <TextField
            value={city}
            fullWidth
            label="Ville"
            onChangeComplete={this.handleChangeComplete('city')}
            error={showErrors && !validFields.city}
            helperText={
              showErrors && !validFields.city ? 'Ce champ est requis' : ''
            }
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Address)
