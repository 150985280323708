import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { KeyboardTimePicker } from '@material-ui/pickers'

import { parseHourString, parseDate } from 'core.utils/parser/time'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'

const styles = {
  mainContainer: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  timePicker: {
    margin: '0 5px',
    width: '100px'
  }
}

export class Créneau extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hDebut: '11:00',
      hFin: '14:00'
    }
  }

  componentDidMount() {
    this.setState({ ...this.props.value })
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.setState({ ...this.props.value })
    }
  }

  handleChange = field => time => {
    this.setState({ [field]: parseHourString(time) }, () => {
      this.props.onChange(this.state)
    })
  }

  render() {
    const { classes, onDelete } = this.props
    const { hDebut, hFin } = this.state
    return (
      <div
        className={classNames('creneau-main-container', classes.mainContainer)}
      >
        <KeyboardTimePicker
          className={classes.timePicker}
          ampm={false}
          value={parseDate(hDebut)}
          onChange={this.handleChange('hDebut')}
        />
        <KeyboardTimePicker
          className={classes.timePicker}
          ampm={false}
          value={parseDate(hFin)}
          onChange={this.handleChange('hFin')}
        />
        <ButtonWithIcon featherIcon="Trash2" iconSize="16" onClick={onDelete} />
      </div>
    )
  }
}

export default withStyles(styles)(Créneau)
