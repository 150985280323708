import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import TextField from 'core.ui/TextField'

const ModalNoteComponent = function ({ open, note, onCancel, onSubmit }) {
  const [value, setValue] = useState()

  useEffect(() => {
    if (open && note) setValue(note)
  }, [open, note])

  return (
    <MaterialDialog
      open={open}
      onClose={onCancel}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>Note Admin</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Note"
          type="text"
          value={value}
          onChangeComplete={(e) => setValue(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          Annuler
        </Button>
        <Button color="primary" onClick={() => onSubmit(value)}>
          Modifier
        </Button>
      </DialogActions>
    </MaterialDialog>
  )
}

export default ModalNoteComponent
