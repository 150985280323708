import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Button } from '@material-ui/core'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { grey } from '@material-ui/core/colors'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { Checkbox } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

// reducers, api
import { order } from 'core.services/api'

// utils
import { makeUserFullName } from 'core.utils/parser/user'
import {
  makeRiderFullName,
  riderTransportTranslated
} from 'core.utils/parser/rider'
import {
  orderStatus,
  pickupOrderStatus,
  getFrenchStatus,
  statusColorMapping,
  statusFontColorMapping
} from 'core.utils/parser/orderStatus'

// components
import Select from 'core.ui/Select'
import Dialog from 'core.ui/Dialog'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  priorityOrdersContainer: {
    // backgroundColor: grey[200]
  },
  priorityOrderCard: {
    backgroundColor: grey[50],
    margin: 5
  },
  priorityOrderHeaderRight: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5
  },
  priorityOrderElapsedTime: {
    fontSize: 25,
    fontWeight: 'bold'
  },
  priorityOrderCreatedDate: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  priorityOrderUpdatedDate: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  priorityOrderStatusContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  priorityOrderStatus: {
    fontSize: 20,
    color: 'black'
  },
  priorityOrderInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: '5px !important',
    paddingBottom: '5px !important'
  },
  priorityOrderClientContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10
  },
  priorityOrderClientHeader: {
    fontWeight: 'bold'
  },
  priorityOrderClient: {
    // fontSize: 20
  },
  priorityOrderRiderContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10
  },
  priorityOrderRiderHeader: {
    fontWeight: 'bold'
  },
  priorityOrderRider: {
    // fontSize: 20
  },
  priorityOrderIssues: {
    display: 'flex',
    flexDirection: 'column'
  },
  priorityOrderActionsContainer: {
    paddingTop: '5px !important',
    paddingBottom: '5px !important'
  },
  priorityOrderActions: {
    display: 'flex',
    flexDirection: 'column'
  },
  priorityOrderAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 3,
    marginBottom: 3,
    // alignSelf: 'center',
    height: 25,
    color: 'black'
  },
  priorityOrderActionText: {
    marginLeft: 10
  },
  priorityOrderButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  priorityOrderButton: {
    marginLeft: 10,
    marginRight: 10
  },
  priorityOrderSelectButton: {
    width: 300,
    marginLeft: 10,
    marginRight: 10
  }
}

class PriorityOrders extends React.Component {
  intervalID

  constructor(props) {
    super(props)
    this.state = {
      priorityOrders: [],
      isDisplayForceOrderAlert: false,
      forceOrderId: null,
      checkedActions: {}
    }
  }

  componentDidMount() {
    this.getPriorityOrders()
  }

  componentWillUnmount() {
    clearTimeout(this.intervalID)
  }

  getPriorityOrders = () => {
    const { orders, onRefreshOrders } = this.props

    if (orders) {
      onRefreshOrders()
    } else {
      try {
        order.getPriorityOrders().then((priorityOrders) => {
          this.setState({ priorityOrders })
        })
      } catch (error) {
        console.debug('getPriorityOrders error: ', error)
        this.setState({ priorityOrders: [] })
      }

      if (this.intervalID) {
        clearTimeout(this.intervalID)
      }
      this.intervalID = setTimeout(this.getPriorityOrders.bind(this), 30000)
    }
  }

  checkPriorityOrderAction = (orderId, action) => {
    try {
      order.checkPriorityOrderAction(orderId, action)
    } catch (error) {
      console.debug('checkPriorityOrderAction error: ', error)
    }
  }

  handleCheckAction = (orderId, action) => {
    const key = `${orderId}-${action}`
    this.setState((state) => ({
      checkedActions: { ...state.checkedActions, [key]: true }
    }))
    this.checkPriorityOrderAction(orderId, action)
  }

  handleStatusChange = (orderId) => (e) => {
    const { value: status } = e.target
    order.changeOrderStatus(orderId, status).then(() => {
      this.getPriorityOrders()
    })
  }

  renderStatusSelect = (status = '', id, deliveryType = 'delivery') => {
    const statuses =
      deliveryType === 'delivery'
        ? Object.values(orderStatus)
        : Object.values(pickupOrderStatus)
    return (
      <Select
        style={{ backgroundColor: statusColorMapping[status] }}
        value={status}
        options={statuses}
        disabled={['driver assigned']}
        captions={statuses.map((s) => getFrenchStatus(s, deliveryType))}
        autoFill={false}
        onChange={this.handleStatusChange(id)}
        colors={statusFontColorMapping}
      />
    )
  }

  openForceOrderAlert = () => {
    this.setState({
      isDisplayForceOrderAlert: true
    })
  }

  confirmForceOrder = () => {
    const { forceOrderId } = this.state
    order.activateOrder(forceOrderId).then(() => this.getPriorityOrders())
    this.closeForceOrderAlert()
  }

  closeForceOrderAlert = () => {
    this.setState({
      isDisplayForceOrderAlert: false,
      forceOrderId: null
    })
  }

  handleForceOrder = (forceOrderId) => {
    this.setState({ forceOrderId })
    this.openForceOrderAlert()
  }

  getOrderIssues = (status, isRestaurantLater) => {
    const issues = {
      'waiting for payment': [
        {
          type: 'standard',
          text: "Le client a peut-être payé mais la commande n'est pas créée"
        }
      ],
      failed: [],
      canceled: [],
      processing: [
        {
          type: 'standard',
          text: "La tablette n'est pas allumée"
        },
        {
          type: 'standard',
          text: "La tablette n'est pas connectée à internet"
        },
        {
          type: 'standard',
          text: "Il n'a pas vu la commande"
        }
      ],
      later: [
        {
          type: 'standard',
          text: 'Le restaurant à cliquer sur plus tard il y a longtemps'
        }
      ],
      cooking: [],
      'waiting for rider': [
        {
          type: 'standard',
          text: 'Aucun livreur ne prend la commande en recherche de livreur'
        }
      ],
      'driver assigned': [
        {
          type: 'standard',
          text:
            'Le livreur est en route vers les restaurant depuis trop longtemps'
        }
      ],
      collected: [
        {
          type: 'standard',
          text: 'Le livreur est en route vers le client depuis trop longtemps'
        }
      ],
      completed: []
    }

    switch (status) {
      case 'waiting for payment':
        return issues['waiting for payment']
      case 'failed':
        return issues['failed']
      case 'canceled':
        return issues['canceled']
      case 'processing':
        if (isRestaurantLater) {
          return issues['later']
        }
        return issues['processing']
      case 'cooking':
        return issues['cooking']
      case 'waiting for rider':
        return issues['waiting for rider']
      case 'driver assigned':
        return issues['driver assigned']
      case 'collected':
        return issues['collected']
      case 'completed':
        return issues['completed']

      default:
        break
    }
  }

  getOrderActions = (status, isRestaurantLater) => {
    const actions = {
      'waiting for payment': [
        {
          type: `[${getFrenchStatus('waiting for payment')}] Stripe`,
          text: 'Vérifier sur stripe que le montant soit bien enregistré'
        },
        {
          type: `[${getFrenchStatus('waiting for payment')}] Force commande`,
          text: 'Si paiement ok, forcer la commande'
        },
        {
          type: `[${getFrenchStatus('waiting for payment')}] Annulation`,
          text: 'Sinon annuler la commande'
        }
      ],
      failed: [],
      canceled: [],
      processing: [
        {
          type: `[${getFrenchStatus('processing')}] Scale fusion`,
          text:
            'Vérifier si tablette allumée + connectée à internet dans scale fusion Groupe "ville"'
        },
        {
          type: `[${getFrenchStatus('processing')}] Appel client`,
          text:
            'Si tablette allumée appeler le client pour lui dire de regarder la tablette'
        },
        {
          type: `[${getFrenchStatus('processing')}] Appel restaurant`,
          text:
            "Si tablette éteinte appeler le restaurateur pour qu'il allume la tablette"
        },
        {
          type: `[${getFrenchStatus('processing')}] RDV technicien`,
          text:
            'Si problème de tablette lui donner la commande et prendre RDV avec technicien.'
        }
      ],
      later: [
        {
          type: `[Commande mise en plus tard] Appel restaurant`,
          text: 'Appeler le restaurant pour accélérer la commande'
        }
      ],
      cooking: [],
      'waiting for rider': [
        {
          type: `[${getFrenchStatus(
            'waiting for rider'
          )}] Vérification logs notification`,
          text:
            'Vérifier dans les logs de la commande que les livreurs ont bien recu la notification'
        },
        {
          type: `[${getFrenchStatus('waiting for rider')}] Maxime note`,
          text:
            "Si aucun livreur n'a recu de notification prévenir Maxime (note tableau)"
        },
        {
          type: `[${getFrenchStatus(
            'waiting for rider'
          )}] Choix livreur manuel`,
          text: 'Sinon choisir un livreur avec le moins de commande'
        }
      ],
      'driver assigned': [
        {
          type: `[${getFrenchStatus('driver assigned')}] Appel livreur`,
          text: 'Appeler le livreur pour lui demande ou il en est'
        },
        {
          type: `[${getFrenchStatus('driver assigned')}] Transfert commande`,
          text:
            'Si une autre livreur est libre transférer la commande à un autre livreur'
        }
      ],
      collected: [
        {
          type: `[${getFrenchStatus('collected')}] Appel livreur`,
          text: 'Appeler le livreur pour lui demander où il en est'
        }
      ],
      completed: []
    }

    switch (status) {
      case 'waiting for payment':
        return actions['waiting for payment']
      case 'failed':
        return actions['failed']
      case 'canceled':
        return actions['canceled']
      case 'processing':
        if (isRestaurantLater) {
          return actions['later']
        }
        return actions['processing']
      case 'cooking':
        return actions['cooking']
      case 'waiting for rider':
        return actions['waiting for rider']
      case 'driver assigned':
        return actions['driver assigned']
      case 'collected':
        return actions['collected']
      case 'completed':
        return actions['completed']

      default:
        break
    }
  }

  getOrderButtons = (status, isRestaurantLater) => {
    const buttons = {
      'waiting for payment': ['stripe', 'details', 'force'],
      failed: ['details'],
      canceled: ['details'],
      processing: ['details', 'scale'],
      later: ['details'],
      cooking: ['details'],
      'waiting for rider': ['details'],
      'driver assigned': ['details'],
      collected: ['details'],
      completed: ['details']
    }

    switch (status) {
      case 'waiting for payment':
        return buttons['waiting for payment']
      case 'failed':
        return buttons['failed']
      case 'canceled':
        return buttons['canceled']
      case 'processing':
        if (isRestaurantLater) {
          return buttons['later']
        }
        return buttons['processing']
      case 'cooking':
        return buttons['cooking']
      case 'waiting for rider':
        return buttons['waiting for rider']
      case 'driver assigned':
        return buttons['driver assigned']
      case 'collected':
        return buttons['collected']
      case 'completed':
        return buttons['completed']

      default:
        break
    }
  }

  openStripe = () =>
    window.open(
      'https://dashboard.stripe.com/payments?status%5B%5D=successful',
      '_blank'
    )

  renderStripeButton = (buttons) => {
    const { classes } = this.props

    if (!buttons.includes('stripe')) {
      return null
    }

    return (
      <Button
        className={classes.priorityOrderButton}
        variant="contained"
        onClick={this.openStripe}
        color="primary"
      >
        Stripe
      </Button>
    )
  }

  openOrderDetails = (id) =>
    window.open(`https://admin.lyveat.com/commandes/${id}`, '_blank')

  renderDetailsButton = (buttons, id) => {
    const { classes } = this.props

    if (!buttons.includes('details')) {
      return null
    }

    return (
      <Button
        className={classes.priorityOrderButton}
        variant="contained"
        onClick={() => this.openOrderDetails(id)}
        color="primary"
      >
        Détails commande
      </Button>
    )
  }

  renderForceButton = (buttons, id) => {
    const { classes } = this.props

    if (!buttons.includes('force')) {
      return null
    }

    return (
      <Button
        className={classes.priorityOrderButton}
        variant="contained"
        onClick={() => this.handleForceOrder(id)}
        color="primary"
      >
        Forcer la commande
      </Button>
    )
  }

  renderScaleButton = (buttons) => {
    const { classes } = this.props

    if (!buttons.includes('scale')) {
      return null
    }

    return (
      <Button
        className={classes.priorityOrderButton}
        variant="contained"
        onClick={() =>
          window.open(
            'https://scalefusion.com/cloud/dashboard/devices',
            '_blank'
          )
        }
        color="secondary"
      >
        Check tablette
      </Button>
    )
  }

  renderPriorityOrdersTab = () => {
    const { classes, orders } = this.props
    const { priorityOrders } = this.state

    const ordersToRender = orders || priorityOrders

    if (!ordersToRender || ordersToRender.length === 0) {
      return <div>Auncune commande en priorité pour le moment 🥳</div>
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        {ordersToRender.map((order) => {
          const {
            _id,
            totalPrice,
            orderPrice,
            deliveryPrice,
            status,
            deliveryType,
            restaurantId,
            userId,
            riderId,
            city,
            elapsedTime,
            billNumber,
            created_at,
            lastStatusStartDate,
            isRestaurantLater
          } = order
          const orderIssues = this.getOrderIssues(status, isRestaurantLater)
          const orderActions = this.getOrderActions(status, isRestaurantLater)
          const orderButtons = this.getOrderButtons(status, isRestaurantLater)
          return (
            <div key={_id} style={{ width: '33%' }}>
              <Card className={classes.priorityOrderCard}>
                <CardHeader
                  className={classes.priorityOrderActionsContainer}
                  action={
                    <div className={classes.priorityOrderHeaderRight}>
                      <span className={classes.priorityOrderElapsedTime}>
                        {elapsedTime} min
                      </span>
                      <span className={classes.priorityOrderCreatedDate}>
                        Création {created_at}
                      </span>
                      <span className={classes.priorityOrderUpdatedDate}>
                        Mise à jour {lastStatusStartDate}
                      </span>
                    </div>
                  }
                  title={`${restaurantId?.nom} - ${city?.name} - ${billNumber}`}
                  subheader={
                    <div className={classes.priorityOrderHeaderRight}>
                      <span>{restaurantId?.telephone}</span>
                      <span style={{ fontWeight: 'bold' }}>
                        {`Produits: ${orderPrice.text}`}
                      </span>
                      <span style={{ fontWeight: 'bold' }}>
                        {`Livraison: ${deliveryPrice ? deliveryPrice?.text : ''}`}
                      </span>
                      <span
                        style={{ fontWeight: 'bold' }}
                      >{`Total: ${totalPrice.text}`}</span>
                    </div>
                  }
                />
                <Divider />
                <CardContent className={classes.priorityOrderActionsContainer}>
                  <div
                    className={classes.priorityOrderStatusContainer}
                    style={{ backgroundColor: statusColorMapping[status] }}
                  >
                    <span className={classes.priorityOrderStatus}>
                      {getFrenchStatus(status, deliveryType)}
                    </span>
                  </div>
                </CardContent>
                <Divider />
                <CardContent className={classes.priorityOrderInfo}>
                  <div className={classes.priorityOrderClientContainer}>
                    <span className={classes.priorityOrderClientHeader}>
                      Client
                    </span>
                    <span className={classes.priorityOrderClient}>
                      {makeUserFullName(userId)}
                    </span>
                    <span className={classes.priorityOrderClient}>
                      #31# {userId.telephone}
                    </span>
                  </div>
                  {riderId && (
                    <div className={classes.priorityOrderRiderContainer}>
                      {/* <span>{getFrenchStatus(order.status, order.deliveryType)}</span> */}
                      <span className={classes.priorityOrderRiderHeader}>
                        Livreur
                      </span>
                      <span className={classes.priorityOrderRider}>
                        {makeRiderFullName(riderId)}
                      </span>
                      <span className={classes.priorityOrderRider}>
                        #31# {riderId.phone}
                      </span>
                      <span className={classes.priorityOrderRider}>
                        {riderTransportTranslated[riderId.transport]}
                      </span>
                    </div>
                  )}
                </CardContent>
                <Divider />
                <CardContent className={classes.priorityOrderActionsContainer}>
                  <div className={classes.priorityOrderIssues}>
                    {orderIssues.map((issue, index) => (
                      <span key={index}>{issue.text}</span>
                    ))}
                  </div>
                </CardContent>
                <Divider />
                <CardContent className={classes.priorityOrderActionsContainer}>
                  <div className={classes.priorityOrderActions}>
                    {orderActions.map((action, index) => {
                      const { checkedActions } = this.state
                      const key = `${_id}-${action.type}`
                      const isChecked = checkedActions[key]
                      return (
                        <Paper
                          key={index}
                          className={classes.priorityOrderAction}
                          elevation={3}
                        >
                          <span className={classes.priorityOrderActionText}>
                            {action.text}
                          </span>
                          <Checkbox
                            disabled={isChecked}
                            checked={isChecked}
                            onChange={() =>
                              this.handleCheckAction(_id, action.type)
                            }
                          />
                        </Paper>
                      )
                    })}
                  </div>
                </CardContent>
                <Divider />
                <CardActions>
                  <div className={classes.priorityOrderButtonsContainer}>
                    {this.renderStripeButton(orderButtons)}
                    {this.renderDetailsButton(orderButtons, _id)}
                    {this.renderForceButton(orderButtons, _id)}
                    {this.renderScaleButton(orderButtons)}
                    <FormControl
                      variant="outlined"
                      className={classes.priorityOrderSelectButton}
                    >
                      {this.renderStatusSelect(status, _id, deliveryType)}
                    </FormControl>
                  </div>
                </CardActions>
              </Card>
            </div>
          )
        })}
      </div>
    )
  }

  renderForceOrderAlert = () => {
    const { isDisplayForceOrderAlert } = this.state
    return (
      <Dialog
        open={isDisplayForceOrderAlert}
        dialogText="Je certifie que le paiement est bien passé et pris en compte sur stripe, je force la création de la commande"
        dialogTitle="Confirmation"
        cancelButtonCaption="Annuler"
        confirmButtonCaption="Confirmer"
        onCancel={this.closeForceOrderAlert}
        onValidation={this.confirmForceOrder}
      />
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.mainContainer}>
        {this.renderPriorityOrdersTab()}
        {this.renderForceOrderAlert()}
      </div>
    )
  }
}

// const mapStateToProps = (state) => ({
//   unreadNotifications: notificationsSelectors.getUnread(state),
// })

// const mapDispatchToProps = (dispatch) => ({
//   fetchAllRestaurants: () => {
//     dispatch(fetchAllRestaurantsRequested())
//   }
// })

// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   withStyles(styles)
// )(PriorityOrders)

export default withStyles(styles)(PriorityOrders)
