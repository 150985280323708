import React from 'react'
import classNames from 'classnames'
import { Button, FormLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import TextField from 'core.ui/TextField'

const styles = {
  mainContainer: {},
  labels: {
    marginBottom: '10px'
  },
  textFieldsContainer: {
    margin: '10px 5px 20px 5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  textFields: {
    width: '175px',
    margin: '0 10px'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

export const defaultState = {
  categoryName: ''
}

class CreateCustomCategory extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      this.setState({ categoryName: value })
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.setState({ categoryName: value })
    }
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({ categoryName: value })
  }

  handleSubmit = () => {
    const { categoryName } = this.state
    this.props.onSubmit({ target: { value: categoryName } })
    this.setState({categoryName: ""});
  }

  render() {
    const { className, classes } = this.props
    const { categoryName } = this.state
    return (
      <div
        className={classNames(
          'main-container',
          classes.mainContainer,
          className
        )}
      >
        <FormLabel className={classNames(classes.labels)} component="legend">
          Créer une catégorie personnalisée
        </FormLabel>
        <div className={classes.textFieldsContainer}>
          <TextField
            className={classNames(
              'create-custom-category-name-textfield',
              classes.textFields
            )}
            value={categoryName}
            label="Nom"
            onChangeComplete={this.handleChange}
          />
        </div>

        {/* BOUTON CRÉER LA CATÉGORIE PERSONNALISÉE */}
        <div className={classes.buttonContainer}>
          <Button
            className="create-custom-category-submit-button"
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
          >
            Ajouter
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CreateCustomCategory)