import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { compose } from 'redux'
import { Button, CircularProgress, withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { restaurant } from 'core.services/api'
import Snackbar from 'core.ui/Snackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class AugmentationRestaurant extends React.Component {
  
    constructor(props) {
      
      super(props);
      this.state={
        price: 0,
        openSnackBar: false,
        percentageType: 'augmenter',
        snackBarMessage: '',
        snackBarSeverity: 'error',
        restaurantId: '',
        open: false,
        loading: false,
        restaurants: []
      }
    }

    handlePrice = (e) => {
      this.setState({ price: e.target.value })
    }

    handleCloseSnackbar = () => {
      this.setState({ openSnackBar: false })
    }
   
    handleOptionChangeCheck = (event) =>{
      this.setState({
        percentageType: event.target.value
      });
    }

    handleChangeRestauPrice = async (event) => {
      const { restaurantId, price, percentageType } = this.state
      let percentage
      if (percentageType === 'augmenter') {
        percentage = Math.abs(price)
      } else {
        percentage = Math.abs(price) * -1
      }

      if (!restaurantId) {
        this.setState({ openSnackBar: true, snackBarSeverity: 'error', snackBarMessage: "Veuillez selectionner un restaurant" })
        return
      }
      if (!price) {
        this.setState({ openSnackBar: true, snackBarSeverity: 'error', snackBarMessage: "Veuillez verifier le pourcentage" })
        return
      } 

      try {
        await restaurant.updatePrices(restaurantId, percentage)
        this.setState({ openSnackBar: true, snackBarSeverity: 'success', snackBarMessage: 'Prix modifié avec succès', price: 0 })
      } catch(e) {
        console.log("error", e)
      }
    }

    handleOpenChange = async (value) => {
      const newState = { open: value }
      if (!value) newState.restaurants = []
      else newState.loading = true
      this.setState(newState)
      if (value) {
        try {
          const { city } = this.props
          const data = await restaurant.getAll(city)
          this.setState({ restaurants: data, loading: false })
        } catch (error) {
          this.setState({ restaurants: [], loading: false })
        }
      }
    }

    handleRestaurantChange = (e, value) => {
      this.setState({ restaurantId: value._id || value.id })
    }

render() {
  const { openSnackBar, snackBarMessage, snackBarSeverity, price, restaurants, loading, open } = this.state
      return (
        <React.Fragment> 
          <h1 style={{textAlign:'Center'}}>Changer les prix des produits :</h1>
          <div style={{paddingLeft:'40%'}}>
          <form noValidate autoComplete="off">

          <Autocomplete
            id="restaurants"
            style={{ width: '30%', marginBottom:'1%' }}
            open={open}
            onOpen={() => {
              this.handleOpenChange(true);
            }}
            onClose={() => {
              this.handleOpenChange(false);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.nom}
            options={restaurants}
            onChange={this.handleRestaurantChange}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Restaurant"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          <TextField
            type="number"
            value={price || ''}
            onChange={this.handlePrice}
            style={{ width: '30%' }}
            error={(price < 0)}
            InputProps={{ inputProps: { min: 1 }}}
            helperText={(price < 0) ? 'Veuillez entrer un pourcentage positif' : ' '}
            id="outlined-error-helper-text"
            label="Pourcentage"
            variant="outlined"
          />

          <div style={{marginTop:'2%',marginBottom:'2%'}}>
          <input type="radio" value="augmenter" name="pourcent"
          checked={this.state.percentageType === 'augmenter'} 
          onChange={this.handleOptionChangeCheck}
            /> Augmenter
          <input type="radio" value="reduire" name="pourcent" 
          checked={this.state.percentageType === 'reduire'} 
          onChange={this.handleOptionChangeCheck}/> Réduire
          </div>
          <Button 
            variant="contained"
            color="secondary"
            style={{ width: '30%' }}
            onClick={() => this.handleChangeRestauPrice()}
            >
           Confirmer
          </Button>

        </form> 
        </div>
        <Snackbar
            open={openSnackBar}
            onClose={this.handleCloseSnackbar}
            severity={snackBarSeverity}
            message={snackBarMessage}
        />
     </React.Fragment>
     
      );
    }
  }
  
const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }

}

export default compose(withStyles(styles))(AugmentationRestaurant)