import React from 'react';

import IconButton from '@material-ui/core/IconButton'
import ListAlt from '@material-ui/icons/ListAlt'
import OrderNotesDialogs from './OrderNotesDialogs';
import OrderNoteTile from './OrderNoteTile';

export default function OrderNoteContainer({order}) {
    const [isOpen, setIsOpen] = React.useState(false)
    
    if (!order?.lastPriorityLog || !order?.lastPriorityLog?.adminId) {
        return <div></div>
    }


    const toggleNotes = () => {
        setIsOpen(curr => !curr)
    }    

  return (
      <>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <OrderNoteTile note={order?.lastPriorityLog} />
                <IconButton aria-label="delete">
                    <ListAlt onClick={toggleNotes} />
                </IconButton>
                {isOpen && <OrderNotesDialogs currentOrder={order} isOpen={isOpen} toggleNotes={toggleNotes} />}        
            </div>
        </>
  );
}
