import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import { Switch, BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { requestStates } from 'core.utils/requestStates'
import List from 'core.ui/List'
import Snackbar from 'core.ui/Snackbar'
import { citiesFetchRequested, citiesInfoFetchRequested, citySearchRequested, updateDeliveryCityRequested, updatePickupCityRequested, updateCityKMSRadius5,updateCityKMSRadius10,updateIsOpenAllDay } from '../../core.modules/cities/reducer'
import { parseCity } from '../../core.utils/parser/city'
import { Button, TextField } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination';
import CityForm from '../CityCreate/CityForm'
import CityFormEdit from '../CityEdit/CityForm'
import CityLogs from "../CityLogs/CityLogs"
import Autocomplete from '@material-ui/lab/Autocomplete'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  },
  headerAutocomplete: {
    paddingTop: '10px'
  }
}

const permissions = JSON.parse(localStorage.getItem('permissions') || '{}')

class CityList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false,
      filterCity: null,
      page: 0,
      perPage: 20
    }
  }

  componentDidMount() {
    this.props.fetchCities()
    this.props.fetchCitiesInfo(this.state.page, this.state.perPage)
  }

  componentDidUpdate(prevProps, prevState) {
    window.onpopstate = e => this.props.fetchCitiesInfo(this.state.page, this.state.perPage)
    const { currentRequestState } = this.props
    const { waitingForRequest, page, perPage } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }

    if (
      !this.state.filterCity &&
      (prevState.page !== page || prevState.perPage !== perPage)
    ) {
      this.props.fetchCitiesInfo(page, perPage)
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleGetLog = (id) => {
		const { history } = this.props;
		history.push(`/villes/logs/${id}`);
	}

  handleEdit = (id) => {
		const { history } = this.props;
		history.push(`/villes/edit?id=${id}`);
	}

  filterCity = (e, value) => {
    if (value && value._id) {
      this.props.searchCity(value?._id)
    } else {
      this.props.fetchCitiesInfo(this.state.page, this.state.perPage)
    }
    
    this.setState({filterCity: value?.id}) 
  }

  render() {
    const { allCities, classes, citiesInfo } = this.props
    const { page, perPage } = this.state

    let cities = [...citiesInfo.cities]

    const handleDeliveryChange = (cityIndex) => (isDelivery) => {
      const { updateCityDelivery, citiesInfo } = this.props
      const cityId = citiesInfo.cities[cityIndex].id || citiesInfo.cities[cityIndex]._id
      updateCityDelivery(cityId, isDelivery)
    }
  
    const handlePickupChange = (cityIndex) => (isPickup) => {
      const { updateCityPickup, citiesInfo } = this.props
      const cityId = citiesInfo.cities[cityIndex].id || citiesInfo.cities[cityIndex]._id
      updateCityPickup(cityId, isPickup)
    }
  
    const handleKMSLimitChange = (cityIndex) => (is5KmLimited) => {
      const { citiesInfo, updateCityKMSRadius5 } = this.props
      const cityId = citiesInfo.cities[cityIndex].id || citiesInfo.cities[cityIndex]._id
      updateCityKMSRadius5(cityId, is5KmLimited)
    }
  
    const handle10KMSLimitChange = (cityIndex) => (is10KmLimited) => {
      const { citiesInfo, updateCityKMSRadius10 } = this.props
      const cityId = citiesInfo.cities[cityIndex].id || citiesInfo.cities[cityIndex]._id
      updateCityKMSRadius10(cityId, is10KmLimited)
    }
  
    const handleIsOpenAllDay = (cityIndex) => (isOpenAllDay) => {
      const { citiesInfo, updateIsOpenAllDay } = this.props
      const cityId = citiesInfo.cities[cityIndex].id || citiesInfo.cities[cityIndex]._id  
      updateIsOpenAllDay(cityId, isOpenAllDay)
    }

    const citiesParsed = cities.map((city, index) => 
      parseCity(city, handleDeliveryChange(index), handlePickupChange(index),  handleKMSLimitChange(index), handle10KMSLimitChange(index), handleIsOpenAllDay(index))
    )

    const ids = cities.map((city) => city._id)

    const actions = { handleGetlog: this.handleGetLog }
    if (permissions?.city?.update) {
      actions.handleEdit = this.handleEdit
    }

    const rowsPerPageOptions = [5, 10, 20]

    const handleChangePage = (event, newPage) => {
      this.setState({ page: newPage })
    }
  
    const handleChangeRowsPerPage = event => {
      this.setState({ perPage: event.target.value })
      this.setState({ page: 0 })
    }

    return (              
        <Switch>
        <Route
            path="/villes/new"
            exact
            render={props => <CityForm {...props} />}
          />
        <Route
						path="/villes/edit"
						exact
            render={props => <CityFormEdit {...props} />}
					/>
          <Route
						path="/villes/logs/:id?"
						exact
            render={props => <CityLogs {...props} />}
					/>
        
          <Route
						path="/villes"
						exact
						render={(props) => (
          <React.Fragment>
               <div style={{margin:"0 auto", display: "flex", flexDirection: "column", alignItems: "center"  }}>
                  <Link className={classes.addButton} to="/villes/new">
                    <Button variant="contained" color="secondary">
                      Ajouter une ville 
                    </Button>
                  </Link>
                  {citiesParsed.length > 0 && (
                  <div style={styles.headerAutocomplete}>
                    <Autocomplete
                      options={allCities}
                      getOptionLabel={(city) => city.name}
                      style={{ width: 300 }}
                      onChange={this.filterCity}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filtre des villes"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                )}
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={citiesInfo.paginationInfo.count}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                </div>
            
            <List
              list={citiesParsed}
              columns={[
                'Nom',
                'Livraison',
                'A emporter',
                'Limite 5 kms',
                'Limite 10 Kms',
                'Ouvert toute la journée'
              ]} 
              ids={ids}
              actions={actions}
              emptyMessage="Aucune ville pour les demandes"
              withPagination={false}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={citiesInfo.paginationInfo.count}
              rowsPerPage={perPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Snackbar
              open={this.state.openSnackBar}
              onClose={this.handleCloseSnackbar}
              severity="error"
              message={this.props.errorMessage}
            />
          </React.Fragment>)}
          />
        </Switch>
    )
  }
}

const mapStateToProps = state => ({
  allCities: citiesSelectors.getAllCities(state),
  citiesInfo: citiesSelectors.getCitiesInfo(state),
  currentRequestState: citiesSelectors.getRequestState(state),
  errorMessage: citiesSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  fetchCities: () => {
    dispatch(citiesFetchRequested())
  },
  fetchCitiesInfo: (page, perPage) => {
    dispatch(citiesInfoFetchRequested(page, perPage))
  },
  searchCity: (cityId) => {
    dispatch(citySearchRequested(cityId))
  },
  updateCityDelivery: (cityId, deliveryValue) => {
    dispatch(updateDeliveryCityRequested(cityId, deliveryValue))
  },
  updateCityPickup: (cityId, pickupValue) => {
    dispatch(updatePickupCityRequested(cityId, pickupValue))
  },
  updateCityKMSRadius5: (cityId, pickupValue) => {
    dispatch(updateCityKMSRadius5(cityId, pickupValue))
  },
  updateCityKMSRadius10: (cityId, is10KmLimited) => {
    dispatch(updateCityKMSRadius10(cityId, is10KmLimited))
  },
  updateIsOpenAllDay: (cityId, IsOpenAllDay) => {
    dispatch(updateIsOpenAllDay(cityId, IsOpenAllDay))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CityList)
