import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import MuiAlert from '@material-ui/lab/Alert';
import { requestStates } from 'core.utils/requestStates'
import classnames from 'classnames'
import Snackbar from 'core.ui/Snackbar'
import { makeStyles } from '@material-ui/core/styles';
import { createCity, selectors as createCitySelector } from '../../core.modules/cities/reducer'
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem, Switch, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { region } from '../../core.services/api';



const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  buttons: {
    display: 'flex',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  }
}

class CityForm extends Component {
  constructor(props) {
    super(props)
    this.nameValidator = /\d/g
    this.state = {
      name: null,
      launchDate: null,
      financialCode: null,
      waitingForRequest: false,
      latitude: null,
      longitude: null,
      regionId: '',
      openSnackBar: false,
      open: false,
      loading: false,
      config: {
        filterByDeliveryDistanceAverage: false
      },
      regions: []
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleLaunchDateChange = e => {
    this.setState({ launchDate: e.target.value })
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handlefinancialCodeChange = e => {
    this.setState({ financialCode: e.target.value })
  }

  handleLatitudeChange = e => {
    this.setState({ latitude: e.target.value })
  }

  handleLongitudeChange = e => {
    this.setState({ longitude: e.target.value })
  }

  handleFilterByDeliveryDistanceAverageChange = () => {
    this.setState((prevState) => ({ config: { ...prevState.config, filterByDeliveryDistanceAverage: !prevState.config.filterByDeliveryDistanceAverage } }))
  }

  handleOpenChange = async (value) => {
    const newState = { open: value }
    if (!value) newState.regions = []
    else newState.loading = true
    this.setState(newState)
    if (value) {
      try {
        const regions = await region.getRegions()
        this.setState({ regions, loading: false })
      } catch (error) {
        this.setState({ regions: [], loading: false })
      }
    }
  }

  handleRegionChange = (e, value) => {
    this.setState({ regionId: value && value._id })
  }

  handleAddCity = () => {
    this.setState({ waitingForRequest: true })
    const { name, latitude, longitude, financialCode, regionId, launchDate, config } = this.state
    if (!name || this.nameValidator.test(name) || name?.trim().length === 0) {
      alert('Nom invalide')
      return
    }

    if (!latitude || isNaN(latitude) || latitude < -90 || latitude > 90) return alert('Latitude invalide')
    if (!longitude || isNaN(longitude) || longitude < -180 || latitude > 180) return alert('Longitude invalide')
    const date = launchDate ? new Date(launchDate) : undefined
    const cityInfos = { 
      name: name?.trim(), 
      position: { latitude, longitude }, 
      financialCode: financialCode?.trim(), 
      regionId, 
      launchDate: date,
      config 
    }
    this.props.createCity(cityInfos)
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmitForm = productInfos => {
    this.setState({ waitingForRequest: true })
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    const { name, latitude, longitude, financialCode, open, regions, launchDate, loading, config } = this.state

    return (
      <React.Fragment>
        <div style={styles.mainListContainer}>
          <Typography
          style={{ textAlign: 'center' }}
          variant="h5"
          component="h5"
          >
            Ajouter une ville 
          </Typography>
          <div style={styles.header}>
            <TextField
              label="Nom de la ville"
              value={name}
              onChange={this.handleNameChange}
              fullWidth
            />
            <TextField
              label="Latitude"
              value={latitude}
              onChange={this.handleLatitudeChange}
              fullWidth
            />
            <TextField
              label="Longitude"
              value={longitude}
              onChange={this.handleLongitudeChange}
              fullWidth
            />
            <TextField
              label="Code financier"
              value={financialCode}
              onChange={this.handlefinancialCodeChange}
              fullWidth
            />
            <Autocomplete
              id="regions"
              sx={{ width: 300 }}
              open={open}
              onOpen={() => {
                this.handleOpenChange(true);
              }}
              onClose={() => {
                this.handleOpenChange(false);
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={regions}
              onChange={this.handleRegionChange}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Regions"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <h4>Date de lancement : </h4>
            <div style={{margionTop: "10px"}}>
              <TextField
                label={launchDate ? "Date de lancement" : undefined}
                value={launchDate}
                type="datetime-local"
                onChange={this.handleLaunchDateChange}
                fullWidth
              />
            </div>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" checked={config?.filterByDeliveryDistanceAverage} onChange={this.handleFilterByDeliveryDistanceAverageChange} />}
              label="Filtrer la selection des livreurs par moyenne de distance de livraison"
              labelPlacement="start"
            />
          </div>
          <div style={styles.buttons}>
          <Button onClick={this.handleCancel} variant="contained" color="default">
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {this.handleAddCity()}}
          >
            Ajouter la ville
          </Button>
          </div>
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={this.props.errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRequestState: createCitySelector.getRequestState(state),
  errorMessage: createCitySelector.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  createCity: (cityInfos) => {
    dispatch(createCity(cityInfos))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CityForm)
