import React from 'react'
import List from 'core.ui/List'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect, useDispatch, useSelector } from 'react-redux'
import { checkifTemplate ,getproducts,ClearTemplates, duplicateProducts,syncProducts, fetchTemplateDragAndDropRequested} from '../../core.modules/Template/Actions'
import { deleteRestaurantProductRequested,  fetchTemplateProductsRequested } from '../../core.modules/currentRestaurant/products/reducer'
import { fetchTemplateOptionsRequested } from '../../core.modules/currentRestaurant/options/reducer'
import { fetchTemplateSupplementRequested } from '../../core.modules/currentRestaurant/supplements/reducer'
import Dialog from 'core.ui/Dialog'
import SyncIcon from '@material-ui/icons/Sync';
import Snackbar from 'core.ui/Snackbar'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListDraggable from 'core.ui/DraggableList'

const style = {
  h3: { textAlign: 'center' },
  autocmplete: { marginLeft: '40%' },
  headerAutocomplete: { paddingTop: '10px' },
  header: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
}
const ProductsTemplate = ({ checkifTemplate, templates: { templates, products, err }, getproducts, history, ClearTemplates, duplicateProducts, syncProducts, location }) => {
  const dispatch = useDispatch()

  const searchParams = new URLSearchParams(location.search)
  const selectedPage = searchParams.get('page')

  const [templateId, setTemplateId] = React.useState(null)
  const [currentTemplate, setCurrentTemplate] = React.useState(null)
  const [filteredProducts, setFilteredProducts] = React.useState([])
  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(selectedPage ? parseInt(selectedPage) - 1 : 0)
  const [header, setHeader] = React.useState(null)
  const [pageStart, setPageStart] = React.useState(page * limit)
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState('Succes synchronisation')
  const [snackBarSeverity, setsnackBarSeverityMessage] = React.useState('success')
  const [openSyncDialog,setOpenSyncDialog]=React.useState(false)
  const ClosesyncDialog =()=>{
    setOpenSyncDialog(false)
  }
  const syncProduct =()=>{
    syncProducts(templateId)
    setOpen(true);
    setOpenSyncDialog(false)
  }
  React.useEffect(()=>{
    if(err) {
      setSnackbarMessage(err)
      setsnackBarSeverityMessage('error')
    } else {
      setSnackbarMessage('Succes synchronisation ')
      setsnackBarSeverityMessage('success')
    }
})

  React.useEffect(()=>{
    const filteredProducts = products.filter((product) => {
      const filterBool = header ? product.header === header : true 
      return !product.isArchived && filterBool
    })
    setFilteredProducts(filteredProducts)
  }, [products, header])

  const handleClick = () => {
   setOpenSyncDialog(true)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleHeaderChange = (e, value)=>{
    setHeader(value)
  }
 
  const handleHeaderSelect = (e, templateId) => {
    // dispatch(fetchTemplateProductsRequested(templateId))
    setHeader(e.target.value)
  }
  
  const handleTemplateChange = (e, value) => {
    setTemplateId(value?._id)
    setCurrentTemplate(value)
    checkifTemplate(value?._id)
    getproducts(value?._id)
  }
  React.useEffect(()=>{
    if(templateId) {
      dispatch(fetchTemplateProductsRequested(templateId))
      dispatch(fetchTemplateOptionsRequested(templateId))
      dispatch(fetchTemplateSupplementRequested(templateId))
    } else {
      ClearTemplates()
    }
    
  }, [templateId,ClearTemplates])
  const handleEdit =id=>{
   
    history.push(`/produits/edit?id=${id}`)
  }
  const [ProductId,setProductId]=React.useState('')
  const [openDeleteDialog,setopenDeleteDialog]=React.useState(false)
  const handleDelete =id=>{
    setopenDeleteDialog(true)
    setProductId(id)
  
  }
  const confirmDelete = () => {
     dispatch(deleteRestaurantProductRequested(ProductId))
    closeDeleteDialog()
    getproducts(templateId)
  }

  const closeDeleteDialog = () => {
    setopenDeleteDialog(false)
      setProductId(null)
  }
  const handleDuplicate =(id)=>{
   
   duplicateProducts(id)
  }
  const renderList=(products)=>{
    return [
      products.name,
      products.category,
      products.price,
      ''
    ]
  }

  const makeIds = (products) => {
    return products.map((p) => {
      return get(p, 'id', get(p, '_id', ''))
    })
  }

  return (
    <div>
      <h3 style={style.h3}>
        Liste des templates produits
        <br />
        {templateId ?
        <div><Link to="/produits/new" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="secondary" onClick={e=>checkifTemplate(templateId)}>
            Ajouter un produit
          </Button>
        </Link>
        <Button onClick={handleClick}><SyncIcon /></Button>
        </div>:<div></div>}
      </h3>
      <div style={style.autocmplete}>
        <Autocomplete
          id="combo-box-demo"
          options={templates}
          getOptionLabel={(templates) => templates.name}
          style={{ width: 300 }}
          onChange={handleTemplateChange}
          renderInput={(params) => (
            <TextField {...params} label="Template" variant="outlined" />
          )}
        />
      </div>

      { currentTemplate?.headers?.length && <div style={style.autocmplete}>
        <Autocomplete
          options={currentTemplate?.headers || []}
          getOptionLabel={(header) => header}
          style={{ width: 300 }}
          onChange={handleHeaderChange}
          onSelect={e => handleHeaderSelect(e, currentTemplate._id)}
          renderInput={(params) => (
            <TextField {...params} label="Header" variant="outlined" defaultValue={currentTemplate?.headers[0]} />
          )}
        />
      </div> }
        { header ? (  
        <div><div style={{marginLeft:'40%'}}>
        Nombre de produit Par Page:  
        < Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={10}
          onChange={(e) => {setLimit(e.target.value)}}
        >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      </div>
      <ListDraggable
        headers={header}
        key="list-of-products"
        list={filteredProducts.map((product) => renderList(product)).slice(
          pageStart,
          pageStart + limit
        )}
        columns={['Nom', 'Catégories', 'Prix']}
        ids={makeIds(filteredProducts).slice(
          pageStart,
          pageStart + limit
        )}
        productList = {filteredProducts}
        actions={{ handleEdit, handleDelete, handleDuplicate }}
        onDragEnd={(productsByHeaders) => fetchTemplateDragAndDropRequested(productsByHeaders, filteredProducts[0]?.templateId)}
        emptyMessage="Ce restaurant n’a aucun produit"
        customPage={page + 1}
        onPageChange={(p) => {
          const pageUpdated = p - 1
          setPage(pageUpdated)
          setPageStart(pageUpdated * limit)
          history.push({
            pathname: location.pathname,
            search: '?' + new URLSearchParams({ page: pageUpdated + 1 }).toString()
          })
        }}
      
      />
      </div>  
    )
    :
    <List
       ids={filteredProducts.map((product) => product._id || product.id).slice(
        pageStart,
        pageStart + limit
      )}
      columns={['Nom', 'Catégories', 'Prix', '']}
      actions={{ handleEdit: handleEdit,handleDuplicate: handleDuplicate,handleDelete: handleDelete  }}
      emptyMessage="Aucune template"
      list={filteredProducts.map((product) => {
        return renderList(product)
      }).slice(
        pageStart,
        pageStart + limit
      )}
      onPageChange={(p) => {
        const pageUpdated = p - 1
        setPage(pageUpdated)
        setPageStart(pageUpdated * limit)
        history.push({
          pathname: location.pathname,
          search: '?' + new URLSearchParams({ page: pageUpdated + 1 }).toString()
        })
      }}
    />
    }
      <Dialog
              open={openDeleteDialog}
              dialogText="Confirmer la suppression"
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={closeDeleteDialog}
              onValidation={confirmDelete}
            />
          <Dialog
              open={openSyncDialog}
              dialogText={`
              Synchronisation des produits: prix, image, id systeme de caisse, menu...`}
              dialogTitle="Confirmation"
              cancelButtonCaption="Annuler"
              confirmButtonCaption="Ok"
              onCancel={ClosesyncDialog}
              onValidation={syncProduct}
            />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}   severity={snackBarSeverity}
          message={snackBarMessage} />
      
  
    </div>
  )
}
const mapStateToProps = (state) => ({
  templates: state.templates
})
export default connect(mapStateToProps, {checkifTemplate,getproducts,ClearTemplates,duplicateProducts,syncProducts})(ProductsTemplate)
