import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  OutlinedInput,
  IconButton
} from '@material-ui/core'
import dayjs from 'dayjs'
import { chain, zipObject } from 'lodash'
import { schema } from '../validations'
import frLocale from 'date-fns/locale/fr'
import DateFnsUtils from '@date-io/date-fns'
import Snackbar from '@material-ui/core/Snackbar'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import { Alert, Autocomplete } from '@material-ui/lab'
import { Add, Save, Delete } from '@material-ui/icons/'
import { useHistory, useLocation } from 'react-router-dom'
import { deal, restaurant, v2header, item } from '../../../core.services/api'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {
  Grid,
  Tooltip
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import AddIcon from '@mui/icons-material/Add'
import { isNull } from 'lodash'
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import ReactSelect from 'react-select'

let selectDayKeyId = 0

const BuyGetForm = ({data}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isV2, setIsV2] = useState(false)
  const [restaurantDoc, setRestaurantDoc] = useState(null)

  const {
    state: { restaurantID }
  } = useLocation()

  useEffect(() => {
    restaurant.getById(restaurantID || localStorage.getItem('selectedRes')).then((value) => {
      setRestaurantDoc(value?.restaurant)
      setIsV2(value?.restaurant?.usesV2Schema)
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      {
        !isLoading && (
          isV2
            ? <BuyGetV2Form restaurant={restaurantDoc} />
            : <BuyGetV1Form data={data} />
        )
      }
    </>
  )

}

const BuyGetV1Form = ({data}) => {
  const [products, setProducts] = useState([])
  const {
    state: { restaurantID }
  } = useLocation()
  const [notifier, setNotifier] = useState({
    open: false,
    type: '',
    content: []
  })
  const [categories, setCategories] = useState([])
  const [grantingProductsCategories, setGrantingProductsCategories] = useState([])
  const [grantedProductsCategories, setGrantedProductsCategories] = useState(
    []
  )

  const regroupBy = (
    dataToGroupOn,
    fieldNameToGroupOn,
    fieldNameForGroupName,
    fieldNameForChildren
  ) => {
    var result = chain(dataToGroupOn)
      .groupBy(fieldNameToGroupOn)
      .toPairs()
      .map(function (currentItem) {
        return zipObject(
          [fieldNameForGroupName, fieldNameForChildren],
          currentItem
        )
      })
      .value()
    return result
  }

  const productsFiltering = (value, handler) => {
    if (!value) {
      setGrantedProductsCategories(products)
    } else {
      let filteredProducts = products.filter(
        (product) => product.header === value.name
      )
      handler(filteredProducts)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setNotifier({
      open: false,
      type: '',
      content: []
    })
  }

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema.editBuyGet),
    defaultValues: {
      grantingProducts: [
        {
          productId: '',
          value: 1
        }
      ],
      grantedProducts: [
        {
          productId: '',
          value: 1
        }
      ]
    }
  })
  const history = useHistory()
  const watchStartDate = watch('startDate')
  const watchEndDate = watch('endDate')

  const days = [
    { value: 'monday', text: 'Lundi' },
    { value: 'tuesday', text: 'Mardi' },
    { value: 'wednesday', text: 'Mercredi' },
    { value: 'thursday', text: 'Jeudi' },
    { value: 'friday', text: 'Vendredi' },
    { value: 'saturday', text: 'Samedi' },
    { value: 'sunday', text: 'Dimanche' }
  ]

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'grantingProducts'
  })

  const {
    fields: grantingFields,
    append: add,
    remove: erase
  } = useFieldArray({
    control,
    name: 'grantedProducts'
  })

  useEffect(() => {
    Promise.resolve(
      restaurant.getRestaurantProducts(
        restaurantID || localStorage.getItem('selectedRes')
      )
    ).then((value) => {
      value = value.filter((item) => !item.isArchived)
      const result = regroupBy(value, 'header', 'name', 'products')
      setCategories(result)
      return setProducts(value)
    })

    if (data) {
      reset({...data})
    }
  }, [])

  const onSubmit = async (promotion) => {
    if(data) {
      try {
        await deal.edit(data._id, promotion)
        reset()
        setNotifier({
          open: true,
          type: 'success',
          content: [{ message: 'Votre offre a bien été modifiée' }]
        })
        setTimeout(() => {
          history.push('/marketing/deals')
        }, 1000)
      } catch (error) {
        if (error.response) {
          const { errors } = error.response.data
          setNotifier({
            open: true,
            type: 'error',
            content: errors
          })
        }
      }
    }else {
      try {
        await deal.add(
          promotion,
          restaurantID || localStorage.getItem('selectedRes')
        )
        reset()
        setNotifier({
          open: true,
          type: 'success',
          content: [{ message: 'Votre offre a bien été créée' }]
        })
        setTimeout(() => {
          history.push('/marketing/deals')
        }, 1000)
      } catch (error) {
        if (error.response) {
          const { errors } = error.response.data
          setNotifier({
            open: true,
            type: 'error',
            content: errors
          })
        }
      }
    }
  }

  const updateTypePackage = (e) => {
    const value = e.target.value;

    const values = getValues();

    setValue("grantedProducts", values.grantedProducts.map(granted => (
      {
        ...granted,
        value
      }
    )))
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('type')} value="buy_x_get_y" />

        <Box display="flex" direction="row" my={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
            <FormControl fullWidth>
              <Controller
                name="startDate"
                control={control}
                defaultValue={dayjs()}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    ampm={false}
                    minDate={dayjs()}
                    maxDate={watchEndDate}
                    disablePast={true}
                    error={!!errors.startDate}
                    onChange={onChange}
                    label="Date de début"
                    value={value || dayjs()}
                    format="dd MMMM yyyy HH:mm"
                    inputVariant="outlined"
                    helperText={errors.startDate && errors.startDate.message}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="endDate"
                control={control}
                defaultValue={watchStartDate}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    required
                    ampm={false}
                    error={!!errors.endDate}
                    minDate={watchStartDate}
                    label="Date de fin"
                    onChange={onChange}
                    inputVariant="outlined"
                    value={value}
                    format="dd MMMM yyyy HH:mm"
                    helperText={errors.endDate && errors.endDate.message}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Box>

        <FormControl variant="outlined" fullWidth error={!!errors.days}>
          <InputLabel id="days">Jours</InputLabel>
          <Select
            multiple
            id="days"
            labelId="days"
            variant="outlined"
            defaultValue={data?.days || []}
            {...register('days')}
            input={<OutlinedInput label="days" />}
          >
            {days.map((day) => (
              <MenuItem key={day.value} value={day.value}>
                {day.text}
              </MenuItem>
            ))}
          </Select>
          {errors.days && (
            <FormHelperText>{errors.days.message}</FormHelperText>
          )}
        </FormControl>

        <Box display="flex" direction="row" my={2}>
          <FormControl fullWidth>
            <TextField
              required
              type="time"
              variant="outlined"
              label="Heure de début"
              id="outlined-disabled"
              error={!!errors['hour.start']}
              helperText={errors['hour.start'] && errors['hour.start'].message}
              {...register('hour.start')}
            />
            <ErrorMessage
              errors={errors}
              name={`hour.start`}
              render={({ message }) => (
                <FormHelperText>{message}</FormHelperText>
              )}
            />
          </FormControl>
          <FormControl fullWidth error={!!errors['hour.end']}>
            <TextField
              required
              type="time"
              variant="outlined"
              label="Heure de fin"
              id="outlined-disabled"
              error={!!errors['hour.end']}
              {...register('hour.end')}
            />
            <ErrorMessage
              errors={errors}
              name={`hour.end`}
              render={({ message }) => (
                <FormHelperText>{message}</FormHelperText>
              )}
            />
          </FormControl>
        </Box>

        <Box my={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="forfait" shrink>
              Type de forfait
            </InputLabel>
            <Select
              id="forfait"
              variant="outlined"
              labelId="forfait"
              label="Type de forfait"
              defaultValue="1"
              {...register('grantedProducts.0.value')}
              onChange={updateTypePackage}
            >
              <MenuItem value={1}>1 acheté 1 offert</MenuItem>
              <MenuItem value={2}>2 achetés 1 offert</MenuItem>
              <MenuItem value={3}>3 achetés 1 offert</MenuItem>
              <MenuItem value={4}>4 achetés 1 offert</MenuItem>
              <MenuItem value={5}>5 achetés 1 offert</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Box component="fieldset" mb={2} py={2}>
            <legend>Produits déclenchant l'offre</legend>

            <Box mb={2}>
              <Autocomplete
                required
                id="grantingProductsCategories"
                options={categories}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option) => option.name}
                onChange={(event, value) =>
                  productsFiltering(value, setGrantingProductsCategories)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Catégories de produits"
                    variant="outlined"
                  />
                )}
              />
            </Box>

            {fields.map((item, index) => (
              <Box key={item.id} display="flex" direction="row" mb={2}>
                <FormControl fullWidth>
                  <Controller
                    name={`grantingProducts.${index}.productId`}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        key={index}
                        required
                        id="grantingProducts"
                        options={grantingProductsCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option) => option._id}
                        onChange={(event, value) => onChange(value?._id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Produit acheté"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />

                  <ErrorMessage
                    errors={errors}
                    name={`grantingProducts[${index}].productId`}
                    render={({ message }) => (
                      <FormHelperText>{message}</FormHelperText>
                    )}
                  />
                </FormControl>
                {index > 0 && (
                  <IconButton aria-label="delete" onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              fullWidth
              size="small"
              type="submit"
              disableElevation
              variant="contained"
              startIcon={<Add />}
              onClick={() =>
                append({
                  productId: '',
                  value: 1
                })
              }
            >
              Ajouter un autre produit
            </Button>
          </Box>

          <Box component="fieldset" mb={2} py={2}>
            <legend>Produits offerts</legend>
            <Box mb={2}>
              <Autocomplete
                required
                id="grantedProductsCategories"
                options={categories}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option) => option.name}
                onChange={(event, value) =>
                  productsFiltering(value, setGrantedProductsCategories)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Catégories de produits"
                    variant="outlined"
                  />
                )}
              />
            </Box>

            {grantingFields.map((item, index) => (
              <Box key={item.id} display="flex" direction="row" mb={2}>
                <FormControl fullWidth>
                  <Controller
                    name={`grantedProducts.${index}.productId`}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        required
                        key={index}
                        id="grantedProducts"
                        options={grantedProductsCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option) => option._id}
                        onChange={(event, value) => onChange(value?._id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Produit offert"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />

                  <ErrorMessage
                    errors={errors}
                    name={`grantedProducts[${index}].productId`}
                    render={({ message }) => (
                      <FormHelperText>{message}</FormHelperText>
                    )}
                  />
                </FormControl>
                {index > 0 && (
                  <IconButton aria-label="delete" onClick={() => erase(index)}>
                    <Delete />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              fullWidth
              size="small"
              type="submit"
              disableElevation
              variant="contained"
              startIcon={<Add />}
              onClick={() =>
                add({
                  productId: '',
                  value: getValues('grantedProducts.0.value')
                })
              }
            >
              Ajouter un autre produit
            </Button>
          </Box>
        </Box>

        <Button
          fullWidth
          size="large"
          type="submit"
          disableElevation
          variant="contained"
          startIcon={<Save />}
          disabled={isSubmitting}
        >
          {data ? "Modifier l'offre" : "Enrégistrer l'offre"}
        </Button>
      </form>

      {notifier.open && (
        <Snackbar
          open={notifier.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity={notifier.type} onClose={handleClose}>
            {notifier.content.map((data) => data?.message)}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

const BuyGetV2Form = ({ restaurant }) => {

  const initialCustomisationDeal = {
    type: "buy_x_get_y",
    startDate: '',
    endDate: '',
    availabilityInfo: {
      hoursOfWeek: [
        {
          dayOfWeek: '',
          timePeriods: [
            {
              startTime: '',
              endTime: ''
            }
          ]
        }
      ]
    },
    grantingItems: [
      {
        value: 1,
        itemId: ''
      }
    ],
    grantedItems: [
      {
        value: 1,
        itemId: ''
      }
    ]
  }

  const history = useHistory()
  const [categories, setCategories] = useState([])
  const [customizationDeal, setCustomizationDeal] = useState(initialCustomisationDeal)
  const [selectedDays, setSelectedDays] = useState([])
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [snackBarInfo, setSnackBarInfo] = useState({ isOpen: false, message: '', severity: 'success' })

  const menuItemDays = [
    {
      value: 'monday',
      text: 'Lundi'
    },
    {
      value: 'tuesday',
      text: 'Mardi'
    },
    {
      value: 'wednesday',
      text: 'Mercredi'
    },
    {
      value: 'thursday',
      text: 'Jeudi'
    },
    {
      value: 'friday',
      text: 'Vendredi'
    },
    {
      value: 'saturday',
      text: 'Samedi'
    },
    {
      value: 'sunday',
      text: 'Dimanche'
    }
  ]

  useEffect(() => {
    v2header.getByRestaurant(restaurant._id).then(response => setCategories(response))
  }, [])

  useEffect(() => {
    const days = []
    customizationDeal.availabilityInfo.hoursOfWeek.map(element => {
      days.push(element.dayOfWeek)
    })
    setSelectedDays(days)
  }, [customizationDeal])

  const displayError = (message) => (
    <div style={{ display: 'flex', flexDirection: 'row', color: "#cc0000", margin: '15px' }}>
      <ErrorIcon />
      <div style={{ fontSize: 10, marginLeft: '10px' }}> {message} </div>
    </div>
  )

  const handleSelectDay = (value, index) => {
    const newDeal = { ...customizationDeal }
    newDeal.availabilityInfo.hoursOfWeek[index].dayOfWeek = value
    setCustomizationDeal(newDeal)
  }

  const handleAddPeriod = (dayIndex) => {
    const newDeal = { ...customizationDeal }
    newDeal.availabilityInfo.hoursOfWeek[dayIndex].timePeriods.push({startTime: '', endTime: ''})
    setCustomizationDeal(newDeal)
  }

  const handleAddDay = () => {
    selectDayKeyId++
    const newDeal = { ...customizationDeal }
    newDeal.availabilityInfo.hoursOfWeek.push({ dayOfWeek: undefined, timePeriods: [ {startTime: '', endTime: ''} ] })
    setCustomizationDeal(newDeal)
  }

  const handleDeleteTimePeriod = (dayIndex, timePeriodIndex) => {
    const newDeal = { ...customizationDeal }
    newDeal.availabilityInfo.hoursOfWeek[dayIndex].timePeriods.splice(timePeriodIndex, 1)
    setCustomizationDeal(newDeal)
  }

  const handleDeleteDay = (dayIndex) => {
    const newDeal = { ...customizationDeal }
    newDeal.availabilityInfo.hoursOfWeek.splice(dayIndex, 1)
    setCustomizationDeal(newDeal);
  }

  const handleAddProduct = (field) => {
    const newDeal = { ...customizationDeal }
    newDeal[field].push({ value: 1, itemId: '' })
    setCustomizationDeal(newDeal)
  }

  const handleDeleteProduct = (index, field) => {
    const newDeal = { ...customizationDeal }
    newDeal[field].splice(index, 1)
    setCustomizationDeal(newDeal);
  }

  const handleSelectItem = (newItem, index, field) => {
    const newDeal = { ...customizationDeal }
    newDeal[field][index] = newItem
    setCustomizationDeal(newDeal);
  }

  const handleSelectTime = (value, dayIndex, timePeriodIndex, field) => {
    const newDeal = { ...customizationDeal }
    newDeal.availabilityInfo.hoursOfWeek[dayIndex].timePeriods[timePeriodIndex][field] = value
    setCustomizationDeal(newDeal)
  }

  const checkHoursOfWeek = () => {
    for (let i = 0; i < customizationDeal.availabilityInfo.hoursOfWeek.length; i++) {
      const dayElem = customizationDeal.availabilityInfo.hoursOfWeek[i];
      if (!dayElem.dayOfWeek) return "Vous devez préciser le jour dans la disponibilité de l'offre"
      else {
        for (let i = 0; i < dayElem.timePeriods.length; i++) {
          const timePeriodElem = dayElem.timePeriods[i];
          if (!timePeriodElem.startTime) return `Vous devez préciser le temps de début pour ${translatedate(dayElem.dayOfWeek)}`
          if (!timePeriodElem.endTime) return `Vous devez préciser le temps de fin pour ${translatedate(dayElem.dayOfWeek)}`
        }
      }
    }
  }

  const checkItems = (items = []) => {
    for (let i = 0; i < items.length; i++) {
      const itemElem = items[i]
      if (!itemElem.itemId) return true
    }

    return false
  }

  const handleSubmitDealForm = (e) => {
    if (!customizationDeal.startDate) setFormErrorMessage("Vous devez préciser une date de début")
    else if (!customizationDeal.endDate) setFormErrorMessage("Vous devez préciser une date de fin")
    else {
      const errorMessage = checkHoursOfWeek()
      if (errorMessage) setFormErrorMessage(errorMessage)
      else {
        if (checkItems(customizationDeal.grantingItems)) setFormErrorMessage("Vous devez selectionner le produit à acheter pour bénéficier de l'offre")
        else if (checkItems(customizationDeal.grantedItems)) setFormErrorMessage("Vous devez selectionner le produit offert")
        else handleSendForm()
      }
    }
  }

  const handleSendForm = async () => {
    setFormErrorMessage('')

    customizationDeal.availabilityInfo = {
      ...customizationDeal.availabilityInfo,
      startDate: customizationDeal.startDate,
      endDate: customizationDeal.endDate
    }

    delete customizationDeal.created_at
    delete customizationDeal.status
    delete customizationDeal.discountType
    
    // createDeal
    try {
      await deal.add(customizationDeal, restaurant._id)
      setSnackBarInfo({ isOpen: true, message: 'Offre créée avec succés', severity: 'success' })

      setTimeout(() => {
        history.push('/marketing/deals')
      }, 1000)
    } catch (error) {
      setSnackBarInfo({ isOpen: true, message: error?.response?.data?.message, severity: 'error' })
    }

  }

  const translatedate = (dayOfWeek) => {
    switch (dayOfWeek) {
      case "monday":
        return "Lundi";
      case "tuesday":
        return "Mardi";
      case "wednesday":
        return "Mercredi";
      case "thursday":
        return "Jeudi";
      case "friday":
        return "Vendredi";
      case "saturday":
        return "samedi";
      case "sunday":
        return "Dimanche";

      default:
    }
  }

  const handleClose = () => {
    setSnackBarInfo({ isOpen: false, message: '' })
  }

  return (
    <div>
      <Snackbar open={snackBarInfo.isOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarInfo.severity}>
          {snackBarInfo.message}
        </Alert>
      </Snackbar>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} wrap="nowrap" style={{ marginTop: "2px" }}>
          <Grid item width="50%">
              <MobileDateTimePicker
                value={dayjs(customizationDeal.startDate)}
                locale="fr"
                format="DD/MM/YYYY HH:mm"
                ampm={false}
                minDate={dayjs().startOf("day")}
                maxDate={dayjs(customizationDeal.endDate)}
                onChange={(date) => {
                  setCustomizationDeal({ ...customizationDeal, startDate: dayjs(date).format() })
                }}
                textField={<TextField {...customizationDeal.startDate} />}
                label="Date de début"
                sx={{ width: "100%" }}
              />
          </Grid>
          <Grid item width="50%">
            <MobileDateTimePicker
              value={dayjs(customizationDeal.endDate)}
              locale="fr"
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              minDate={dayjs(customizationDeal.startDate)}
              onChange={(date) => {
                setCustomizationDeal({ ...customizationDeal, endDate: dayjs(date).format() })
              }}
              textField={<TextField {...customizationDeal.endDate} />}
              label="Date de fin"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        {
          customizationDeal.availabilityInfo.hoursOfWeek.map((dayElem, dayIndex) => (
            <div style={{ border: '1px #949494 solid', borderRadius: '10px', padding: '5px', marginTop: '5px', position: 'relative' }}>
              <FormControl fullWidth>
                <InputLabel id="promotion-type">
                  Jour de la semaine
                </InputLabel>
                <Select
                  key={selectDayKeyId}
                  value={dayElem.dayOfWeek}
                  onChange={(event) => handleSelectDay(event.target.value, dayIndex)}
                >
                  {
                    menuItemDays.map(menuItemElem => 
                      (<MenuItem value={menuItemElem.value} disabled={selectedDays.includes(menuItemElem.value)} >{menuItemElem.text}</MenuItem>)
                    )
                  }

                </Select>
                {
                  dayElem.timePeriods.map((timePeriodElem, timePeriodIndex) => (

                    <div style={{ border: '1px #949494 solid', borderRadius: '10px', marginTop: "10px", position: 'relative' }}>
                      <Grid container spacing={2} wrap="nowrap" style={{ marginTop: "2px", padding: '5px' }}>
                        <Grid item width="50%">
                          <MobileTimePicker
                            locale="fr"
                            value={dayjs(
                              timePeriodElem.startTime,
                              "HH:mm"
                            )}
                            maxTime={dayjs(timePeriodElem.endTime, "HH:mm")}
                            format="HH:mm"
                            ampm={false}
                            onChange={(date) => handleSelectTime(date.format("HH:mm"), dayIndex, timePeriodIndex, "startTime")}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            label="Heure de début"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item width="50%">
                          <MobileTimePicker
                            locale="fr"
                            value={dayjs(
                              timePeriodElem.endTime,
                              "HH:mm"
                            )}
                            format="HH:mm"
                            ampm={false}
                            minTime={dayjs(timePeriodElem.startTime, "HH:mm")}
                            onChange={(date) => handleSelectTime(date.format("HH:mm"), dayIndex, timePeriodIndex, "endTime")}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            label="Heure de fin"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    
                      {
                        dayElem.timePeriods.length > 1 && 
                        <Tooltip title="Supprimer cette période">
                          <IconButton
                            onClick={() => { handleDeleteTimePeriod(dayIndex, timePeriodIndex) }}
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              color: '#cc0000'
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      
                    </div>
                      
                  ))
                }

                <Tooltip title="Ajouter une période">
                  <IconButton 
                    onClick={() => handleAddPeriod(dayIndex)}
                    style={{
                      fontSize: 20,
                      color: 'black'
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                
              </FormControl>
              {
                customizationDeal.availabilityInfo.hoursOfWeek.length > 1 && 
                <Tooltip title="Supprimer ce jour">
                  <IconButton 
                    onClick={() => { handleDeleteDay(dayIndex) }}
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      color: '#cc0000'
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
              
            </div>
          ))
        }
      </LocalizationProvider>
      <Tooltip title="Ajouter un jour">
          <Button 
            onClick={() => handleAddDay()}
            style={{
              color: 'black',
              margin: '2px',
              border: '1px black solid'
            }}
          >
            ajouter un jour
          </Button>
        </Tooltip>
      {(customizationDeal.type === "buy_x_get_y") && (
        <div>
          <div style={{ border: '1px #949494 solid', borderRadius: '10px', padding: '5px', marginTop: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ alignSelf: 'flex-start' }}>Produits à acheter pour bénéficier de l'offre :</div>
            {
              customizationDeal.grantingItems.map((grantingItemElem, index) => (
                <FormControl fullWidth>
                  <SelectProductContainer key={grantingItemElem.itemId} categories={categories} restaurantId={restaurant._id} dealItem={grantingItemElem} index={index} handleDeleteProduct={handleDeleteProduct} handleSelectItem={handleSelectItem} field={"grantingItems"} itemsLength={customizationDeal?.grantingItems?.length || 0} />
                </FormControl>
                
              ))
            }
            <Tooltip title="Ajouter un produit">
              <IconButton 
                onClick={() => handleAddProduct("grantingItems")}
                style={{
                  color: 'black',
                  margin: '2px'
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      {(customizationDeal.type === "buy_x_get_y") && (
        <div>
          <div style={{ border: '1px #949494 solid', borderRadius: '10px', padding: '5px', marginTop: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ alignSelf: 'flex-start' }}>Produits offerts :</div>
            {
              customizationDeal.grantedItems.map((grantedItemElem, index) => (
                <FormControl fullWidth>
                  <SelectProductContainer key={grantedItemElem.itemId} categories={categories} restaurantId={restaurant._id} dealItem={grantedItemElem} index={index} handleDeleteProduct={handleDeleteProduct} handleSelectItem={handleSelectItem} field={"grantedItems"} itemsLength={customizationDeal?.grantedItems?.length || 0} />
                </FormControl>
              ))
            }
            <Tooltip title="Ajouter un produit">
              <IconButton 
                onClick={() => handleAddProduct("grantedItems")}
                style={{
                  color: 'black',
                  margin: '2px'
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      { formErrorMessage && displayError(formErrorMessage) }                

      <Button style={{ color: 'black', border: '1px black solid', marginTop: '10px', width: '100%' }} onClick={handleSubmitDealForm}>
        { "Enregistrer l'offre" }
      </Button>
    </div>
  )
}

const SelectProductContainer = ({ categories, restaurantId, dealItem, index, handleDeleteProduct, handleSelectItem, field, itemsLength }) => {

  const [selectedCategoryId, setSelectedCategoryId] = useState(dealItem?.headerId)
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const customStyles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      height: '48px',
    }),
    singleValue: provided => ({
      ...provided,
      color: 'black'
    })
  }

  useEffect(() => {
    if (selectedCategoryId) {
      item.getByHeader(restaurantId, selectedCategoryId).then((response) => {
        setItems(response)
        if (!dealItem.itemId) handleChange(response[0]._id)
      })
    }
  }, [selectedCategoryId]);

  const handleChange = (selectedItemId) => {
    if (selectedItemId) {
      const newItem = {
        itemId: selectedItemId,
        headerId: selectedCategoryId,
        value: dealItem.value
      }
      handleSelectItem(newItem, index, field)
    }
  }

  const handleChangeValue = (value) => {
    if (!isNaN(value) && !isNull(value)) {
      const newItem = {
        ...dealItem,
        value
      }
      handleSelectItem(newItem, index, field)
    }
  } 

  const getItemById = (itemId) => items.find(itemElem => itemElem._id === itemId)
  
  return (
    <div style={{ border: '1px #949494 solid', borderRadius: '10px', padding: '10px', marginTop: '5px', position: 'relative' }}>
      <FormControl fullWidth>
        <InputLabel id="category">
          Catégorie
        </InputLabel>
        <Select value={selectedCategoryId} onChange={(e) => setSelectedCategoryId(e.target.value)}>
          {categories.map((categoryElem) => (
            <MenuItem key={categoryElem._id} value={categoryElem._id}>
              {categoryElem.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Grid container spacing={2} wrap="nowrap" style={{ marginTop: "2px" }}>
          <Grid item width={ field === 'grantedItems' ? '80%' : '100%' } >
            <ReactSelect
              value={getItemById(dealItem.itemId)}
              onChange={(e) => {handleChange(e && e._id)}}
              options={items}
              isLoading={isLoading}
              loadingMessage={() => 'Chargement...'}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.title}
              isClearable
              isSearchable
              styles={customStyles}
              placeholder="Produit"
            />
          </Grid>
          {
            field === 'grantedItems' && 
            <Grid item width="20%">
              <Tooltip title="Quantité de produits achetés pour béneficier de ce produit">
                <TextField
                  label={'Qté'}
                  value={dealItem.value}
                  onChange={(e) => handleChangeValue(e.target.value)}
                />
              </Tooltip> 
            </Grid>
          }
        </Grid>
      </FormControl>

      {
        (itemsLength > 1) && (
          <Tooltip title="Supprimer ce produit">
            <IconButton
              onClick={() => { handleDeleteProduct(index, field) }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#cc0000'
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      }
    </div>
  );
}

export default BuyGetForm
