import React, { useState, useEffect} from 'react'
import {
  useParams
} from "react-router-dom";
import { FormControlLabel, Switch, Button } from '@material-ui/core'
import List from 'core.ui/List'
import { withStyles } from '@material-ui/core/styles'
import Select from 'core.ui/Select'
import { makeDateString } from 'core.utils/parser/time'
import { compose } from 'redux'
import { connect } from 'react-redux'
import MuiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { isEmpty } from 'lodash';

import { issue } from 'core.services/api'

import ModalAddTracking from "./Addtracking"

import {
  selectors as restaurantsSelectors
} from 'core.modules/restaurants/reducer'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'

import styles from './styles'

const rushSwitchStyle = {
  marginTop: 20,
  marginLeft: 300,
  marginBottom: 20
}

const statusColorMapping = {opened: "#689f38", closed: "#f44336"}
const statuses = ['opened', 'closed']

const RestaurantsTracking = ({classes, currentCityRestaurants, cities}) => {
  const { restaurantId } = useParams()
  const [checked, setChecked] = useState(false);
  const [parsedIssues, setParsedIssues] = useState([])
  const [trackings, setTrackings] = useState([])
  const [state, setState] = useState({
    currentPage: 1,
    numberPerPage: 20,
  })
  const [reload, setReload] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false)
  const [restaurant, setRestaurant] = useState(null);
  const [cityRider, setCityRider] = useState(null);
  const [types, setTypes] = useState([])
  const [type, setType] = useState(null);
  const [reasons, setReasons] = useState([])

  React.useEffect(() => {
    issue.getConfig().then(data => {
      setReasons(data.reasons)
      setTypes(data.types)
    })
  }, [])

  useEffect(() => {
    let cityId = null;

    if(!checked) {
      cityId = localStorage.getItem('selectedCity')
    }

    issue.getIssues({cityId,
      concernedParty: "restaurant", 
      page: state.currentPage, 
      limit: state.numberPerPage,
      restaurantId,
      status: restaurantId ? null : "opened",
      type
    }).then(data => {
      setTrackings(data)
    })
    .catch(error => {
      console.error(error.message)
      setTrackings([])
    }) 
  }, [state.currentPage, checked, reload, type])

  useEffect(() => {
    setParsedIssues(parseIssues())
  }, [trackings])

  useEffect(() => {
    if(restaurantId) {
      const restaurant = currentCityRestaurants.find(resto => resto.id === restaurantId)

      setRestaurant(restaurant)

      if(restaurant) {
        const hisCity = cities.find(city => city._id === restaurant?.city)

        setCityRider(hisCity)
      }
    }
  }, [restaurantId])

  const translateReasonIssue = (reason) => {
    const findValue = reasons?.restaurantReasons.find(t => t.value === reason)

    if(findValue)
      return findValue.text;

    return null;
	 };

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };


  const parseIssues = () => {
    return trackings
      .map((track) => {
        const {
          created_at,
          restaurantId: {
            nom,
            telephone
          },
          cityId,
          reason,
          description,
          status,
          updated_at,
          addedBy,
          _id
        } = track

        return [
          makeDateString(created_at),
          nom,
          telephone,
          cityId?.name,
          translateReasonIssue(reason),
          description,
          makeStatusSelect(status, _id),
          makeDateString(updated_at),
          `${addedBy?.userId?.firstName} ${addedBy?.userId?.lastName}`
          ]
      })
  }

  const makeStatusSelect = (
    status = 'opened',
    id
  ) => {
    return (
      <Select
        style={{ backgroundColor: statusColorMapping[status] }}
        value={status}
        options={statuses}
        disabled={[]}
        captions={['Ouvert', 'Fermer']}
        autoFill={false}
        onChange={handleChangeSelect(id)}
        colors={statusColorMapping}
      />
    )
  }

  const handleChangeSelect = (id) => e => {
    const value = e.target.value

    issue.changeStatus({ id, status: value }).then(data => {
      setTrackings(trackings.map(track => track._id === id ? ({...track, status: value}) : track))
    })
  }

  const handlePageChange = page => {
    setState({...state, currentPage: page }, () => {
      this.getRestaurantRequests()
    })
  }

  const handleClose = () => {
    setOpenTrackingModal(false);
  };

  const handleChange = (value) => {
    setType(value);
  };

  const ids = trackings.map((t) => t._id)

  return    <div>
                <div style={{display: "flex", justifyContent: !restaurantId ? "space-between" : "flex-end", alignItems: "center"}}>
                {!restaurantId && <div style={rushSwitchStyle}>
			  
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={checked}
                        onChange={switchHandler}
                      />
                    }
                    label="Toutes les villes"
                  />
                  <p> Ouverts:</p>
                  <FormControl style = {{alignItems:'center'}}>
										<ToggleButtonGroup style={{alignItems:'center'}}
											value={type}
											exclusive
											onChange={(e, value) => {
												handleChange(value);
											}}
											aria-label="text alignment"
										>
                      <ToggleButton value={null} aria-label="centered">
												<span>TOUT</span>
											</ToggleButton>
                      {types?.map((type) => (
											<ToggleButton value={type?.value} aria-label="left aligned">
												<span>{type?.text}</span>
											</ToggleButton>
                      ))}
										</ToggleButtonGroup>
									</FormControl>
                </div>}
                </div>
                
              <div>

              {
                restaurantId && <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center', 
                  fontSize: 20,
                  width: '100%',
                  backgroundColor: 'lightgrey',
                  paddingTop: 5,
                  paddingBottom:5,
  
                }}>
                  Restaurant : {restaurant && restaurant.nom} - {restaurant && cityRider?.name}
                </div>
              }
              <br></br>

              {restaurantId && <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>

                   <FormControl style = {{alignItems:'center', marginRight:10 }}>
										<ToggleButtonGroup 
											value={type}
											exclusive
											onChange={(e, value) => {
												handleChange(value);
											}}
											aria-label="text alignment"
										>
                      <ToggleButton value={null} aria-label="centered">
												<span>TOUT</span>
											</ToggleButton>
                      {types?.map((type) => (
											<ToggleButton value={type?.value} aria-label="left aligned">
												<span>{type?.text}</span>
											</ToggleButton>
                      ))}
										</ToggleButtonGroup>
									</FormControl>

                  <Button variant="contained" color="secondary" onClick={() => setOpenTrackingModal(true)}>
                    Ajouter un ticket
                  </Button>
              </div>}
            <List 
              style={{
                width: '0%'
              }}
              columns={[
                'Création',
                'Nom',
                'Téléphone',
                'Ville',
                'Titre',
                'Commentaire',
                'Statut',                
                'Mise à jour',
                'Ajouté par'
              ]}
              list={parsedIssues}
              ids={ids}
              actions={{
                
              }}
              customPage={state.currentPage}
              numberPerPage={state.numberPerPage}
              onPageChange={handlePageChange}
            />

          </div>
                  
          {openTrackingModal && restaurantId &&  
          <ModalAddTracking open={openTrackingModal} setReload={setReload} reload={reload} onClose={handleClose} restaurantId={restaurantId} restaurant={"nom"} />
          } 
        </div>
           
}

const mapStateToProps = (state, ownProps) => ({
  currentCityRestaurants: restaurantsSelectors.getAllRestaurants(state),
  cities: citiesSelectors.getAllCities(state)
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(RestaurantsTracking)