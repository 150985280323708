export const firebaseConfig = {
  apiKey: 'AIzaSyBvUGM20bjoTH6ZEd2gSh1G_HWHYd_tNCg',
  authDomain: 'lyveat-32355.firebaseapp.com',
  databaseURL: 'https://lyveat-32355.firebaseio.com/',
  projectId: 'lyveat-32355',
  storageBucket: 'lyveat-32355.appspot.com',
  messagingSenderId: '61855691334',
  appId: '1:61855691334:web:84431a0ba93b101f469047',
  measurementId: 'G-ELT4P3YMFS'
}
