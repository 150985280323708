
import { findIndex } from 'lodash'
import { regulation } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { invoiceAPI, order } from '../../core.services/api'

export const {
    GENERATE_REGULATION_RECAP_REQUESTED,
    GENERATE_REGULATION_RECAP_SUCCEEDED,
    GENERATE_REGULATION_RECAP_FAILED,
    generateRegulationsRecapSucceeded,
    generateRegulationsRecapFailed
  } = createRequestActions('GENERATE_REGULATION_RECAP')
  
  export const generateRegulationRecapRequested = (startDate, endDate) => (dispatch) => {
    dispatch({
      type: GENERATE_REGULATION_RECAP_REQUESTED
    })
    regulation
      .generateRecap(startDate, endDate)
      .then((data) => {
        dispatch(generateRegulationsRecapSucceeded(data))
      })
      .catch((error) => {
 
      })
  }
  
export const defaultState = { allRegulations: [], allRegulationStatuses: [], requestState: '' }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case GENERATE_REGULATION_RECAP_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }   
    case GENERATE_REGULATION_RECAP_SUCCEEDED:
      return {
        ...state,
        requestState: requestStates.SUCCESS
      }
    case GENERATE_REGULATION_RECAP_FAILED:
      return { ...state, requestState: requestStates.FAILED }
    default:
      return state
  }
}
