import React, {Component, useState, useEffect, useRef} from 'react'
import { render } from 'react-dom';
import L from 'leaflet'
import { riderPositionRequests } from 'core.services/api'
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet-defaulticon-compatibility';
import { FormControlLabel, Switch } from '@material-ui/core'
import ButtonWithIcon from 'core.ui/ButtonWithIcon'

const mapStyle = {
    height: "550px",
    margin: "auto",
    width: "80%",
    marginBottom: "100px"
  };

const rowButtonsStyle = {
  display: "flex",
  flexDirection:"row"
}

const rushSwitchStyle = {
  marginTop: 20,
  marginLeft: 80,
  marginBottom: 20
}

const refreshButtonStyle = {
  marginTop: 20,
  marginBottom: 20
}


const RidersMap = (props) => {

  const [checked, setChecked] = useState(false);
  const [cityMap, setCityMap] = useState(null);
  const [restaurantsMarkers, setRestaurantsMarkers] = useState([]);
  const [ridersMarkers, setRidersMarkers] = useState([]);
  const rushRef = useRef(null);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  var ridersIcon = new L.Icon({
    iconUrl: '/Lyveat-pin-green.png',
    iconAnchor: new L.Point(32, 48),
    iconSize: new L.Point(32, 48),
    size: new L.Point(32, 48),
    popupAnchor:  new L.Point(-16, -48)
  });

  var restaurantIcon = new L.Icon({
    iconUrl: '/Lyveat-pin-black.png',
    iconAnchor: new L.Point(32, 48),
    iconSize: new L.Point(32, 48),
    size: new L.Point(32, 48),
    popupAnchor:  new L.Point(-16, -48)
  });

  const  getRidersData = async () => {
    const {data} = await riderPositionRequests.getRidersInfos(props.city)
    return data
  }

  const getRestaurantsData = async () => {
    const {data} = await riderPositionRequests.getRestaurantsInfos(props.city)
    return data
  }

  const  getMap = async () => {
    
    const ridersData = await getRidersData()
    const restaurantData = await getRestaurantsData()

    //draw map and tileLayer
    let map = L.map("map", {
      center: [
        ridersData.cityPosition.longitude,
        ridersData.cityPosition.latitude
      ],
      zoom: 10,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ]
    });

    //add riders markers to map
    ridersData.riders.map((rider) => {
      if (!rider.position || !rider.position.coordinates || !rider.position.coordinates.length) return
      const minutes = rider.minutesSinceLastPositionUpdate
      let lastActifMessage
      if(minutes < 1) lastActifMessage = "Il y a moins d'une minute"
      else if(minutes < 60) lastActifMessage = `Il y a ${minutes} minutes`
      else if(minutes > 1440) lastActifMessage = "Plus que 1j"
      else lastActifMessage = `Il y a ${parseInt(minutes/60, 10)} h ${minutes % 60} m`

      const marker = L.marker([rider.position.coordinates[1], rider.position.coordinates[0]],{ icon: ridersIcon }).addTo(map)
        .bindPopup(`${rider.firstName} ${rider.lastName} <br> ${rider.phone} <br> Commandes : ${rider.numberOfOrders} <br> ${rider.transport} <br> ${lastActifMessage}`)
        .openPopup();

      ridersMarkers.push(marker);
    })

    //add restaurant Markers to map
    restaurantData.map((restaurant) => {
      const marker = L.marker([restaurant.adress.latitude, restaurant.adress.longitude],{ icon: restaurantIcon }).addTo(map)
        .bindPopup(`${restaurant.nom}`)
        .openPopup();

      restaurantsMarkers.push(marker)
    })

    setCityMap(map)
  }

  const deleteRestaurantsMarkers = () => {
    for (let index = 0; index < restaurantsMarkers.length; index++) {
      cityMap.removeLayer(restaurantsMarkers[index])
    }
  }

  const deleteRidersMarkers = () => {
    for (let index = 0; index < ridersMarkers.length; index++) {
      cityMap.removeLayer(ridersMarkers[index])
    }
  }

  const updateRestaurantsPositions = async () => {
    //clear old restaurants' markers
    deleteRestaurantsMarkers()

    const restaurantData = await getRestaurantsData()

    //add restaurants Markers to map
    restaurantData.map((restaurant) => {
      const marker = L.marker([restaurant.adress.latitude, restaurant.adress.longitude], { icon: restaurantIcon }).addTo(cityMap)
        .bindPopup(`${restaurant.nom}`)
        .openPopup();

      restaurantsMarkers.push(marker)
    })
  }

  const updateRidersPositions = async () => {
    //clear old rider's markers
    deleteRidersMarkers()

    const ridersData = await getRidersData()

    //add riders Markers to map
    ridersData.riders.map((rider) => {
      if (!rider.position || !rider.position.coordinates || !rider.position.coordinates.length) return

      const minutes = rider.minutesSinceLastPositionUpdate
      let lastActifMessage = ""
      if(minutes < 1) lastActifMessage = "Il y a moins d'une minute"
      else if(minutes < 60) lastActifMessage = `Il y a ${minutes} minutes`
      else if(minutes > 1440) lastActifMessage = "Plus que 1j"
      else if(minutes >= 60 && minutes <= 1440) lastActifMessage = `Il y a ${parseInt(minutes/60, 10)} h ${minutes % 60} m`

     const marker = L.marker([rider.position.coordinates[1], rider.position.coordinates[0]],{icon: ridersIcon}).addTo(cityMap)
        .bindPopup(`${rider.firstName} ${rider.lastName} <br> ${rider.phone} <br> Commandes : ${rider.numberOfOrders} <br> ${rider.transport} <br> ${lastActifMessage}`)
        .openPopup();

      ridersMarkers.push(marker);
    })
  }

  const changeCity = async () => {

    const ridersData = await getRidersData()

    cityMap.setView(new L.LatLng(ridersData.cityPosition.longitude, ridersData.cityPosition.latitude), 10);

    updateRidersPositions()
    updateRestaurantsPositions()
  }

  useEffect(()=>{
    if(!cityMap) getMap()
    else changeCity()
  }, [props.city])

  useEffect(()=>{
    if(checked){
      rushRef.current = setInterval(() => {
        updateRidersPositions()
      }, 60000);
    }else if(rushRef.current && !checked){
      clearInterval(rushRef.current)
    }
    return () => clearInterval(rushRef.current);
  }, [checked])

  
  return  <div>
            <div style={rowButtonsStyle}>
              <div style={rushSwitchStyle}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      checked={checked}
                      onChange={switchHandler}
                    />
                  }
                  label="Rush"
                />
              </div>
              <div style={refreshButtonStyle}>
                <ButtonWithIcon
                featherIcon="RefreshCw"
                size={60}
                onClick={updateRidersPositions}
                />
              </div>
            </div>
            <div id="map" style={mapStyle}></div>
          </div>
}

export default RidersMap