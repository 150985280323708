import axios from 'axios'
import { addDays } from 'date-fns'
import fileDownload from 'js-file-download'
import { usersFactory } from 'test_utils/factories'
import { isEmpty } from 'lodash'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
})

export const apiWithoutPrefix = axios.create({
  baseURL: String(process.env.REACT_APP_API_URL).replace('/v1', ''),
  timeout: 30000
})

api.interceptors.request.use(
  async (request) => {
    const token = await localStorage.getItem('token')
    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
  },
  (error) => {
    Promise.reject(error)
  }
)

export const apiv2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`.replace('/v1', '/v2'),
  timeout: 60000
})

export const apiNotifier = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_URL,
  timeout: 60000
})

apiv2.interceptors.request.use(
  async (request) => {
    const token = await localStorage.getItem('token')
    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
  },
  (error) => {
    Promise.reject(error)
  }
)

export const admin = {
  async getInfo() {
    const { data } = await api.get('/admin/me')
    return data
  },
}

export const item = {
  async getByHeader(restaurantId, headerId) {
    const { data } = await apiv2.get(`/restaurants/${restaurantId}/headers/${headerId}/items/name`)
    return data
  }
}

export const v2header = {
  async getByRestaurant(restaurantId) {
    const { data } = await apiv2.get(`/restaurants/${restaurantId}/headers/name`)
    return data
  },
}

export const restaurant = {
  async add(restaurantInfos) {
    const { data } = await api.post('/restaurant', {
      ...restaurantInfos
    })
    return data
  },
  async edit(id, restaurantInfos) {
    const { data } = await api.put(`/restaurant/${id}`, {
      ...restaurantInfos
    })
    return data
  },

  async searchProducts(searchData, restaurantId) {
    const { data } = await apiv2.post(
      `/restaurants/${restaurantId}/products/search`,
      searchData
    )
    return data
  },

  async uploadRestaurantImage(restaurantId, file) {
    const { data: uploadUrlInfo } = await api.get(
      `/restaurant/${restaurantId}/upload-url`
    )
    const { data } = await axios.post(uploadUrlInfo.uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Bz-File-Name': uploadUrlInfo.fileName,
        'X-Bz-Content-Sha1': 'do_not_verify',
        Authorization: `${uploadUrlInfo.authorizationToken}`
      }
    })
    return data
  },
  async addImage(restaurantId, image) {
    const formData = new FormData()
    formData.append('image', image)
    const { data } = await api.put(
      `/restaurant/image/${restaurantId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return data
  },
  async getImage(imageUrl) {
    if (!imageUrl) return
    let { data } = await apiWithoutPrefix.get(
      imageUrl,
      {
        responseType: 'blob'
      }
    )
    return data
  },
  async delete(id) {
    const { data } = await api.delete(`/restaurant/${id}`)
    return data
  },
  async getById(id) {
    const { data } = await api.get(`/restaurant/${id}/admin`)
    return data
  },
  async getByCity(cityId) {
    const { data } = await api.get('/restaurant', {
      params: { city: cityId }
    })
    return data
  },
  async getAll(cityId) {
    const { data } = await api.get(`/restaurant/city/${cityId}/name`)
    return data
  },
  async getAllPaginated(cityId, page = 0, perPage = 20) {
    page += 1
    if (page < 1) page += 1
    const { data } = await api.get(`/restaurant/city/${cityId}?page=${page}&limit=${perPage}`)

    return data
  },
  async getAllStatus() {
    const { data } = await api.get('/restaurant/all/status?displayAll=true')
    return data
  },
  async getRestaurantStatus() {
    const { data } = await api.get('/restaurant/state')
    return data
  },
  async getRestaurantProducts(restaurantId) {
    const { data } = await api.get(`/restaurant/all-products/${restaurantId}`)
    return data
  },
  async duplicateRestaurant(
    restaurantId,
    cityId,
    email,
    adress,
    comission,
    telephone
  ) {
    const { data } = await api.post(
      `/restaurant/duplicate`,
      { email, adress, comission, telephone },
      {
        params: { restaurantId, cityId }
      }
    )
    return data
  },
  async generateRecap(startDate, endDate) {
    const result = await api.get(`/restaurant/generate-recap`, {
      params: { startDate, endDate }
    })
    fileDownload(
      result.data,
      `Recap des Restaurants du ${startDate} au ${endDate}.csv`
    )
    return result.data
  },
  async fetchRestaurantLogs(restaurantId) {
    const { data } = await api.get(`/restaurant/logs/${restaurantId}`)
    return data
  },
  async forceRestaurantOrdersRefresh(restaurantId) {
    const { data } = await api.post(
      `/restaurant/force-orders-refresh/${restaurantId}`
    )
    return data
  },
  async updateHeaders(restaurantId, newHeaders, oldHeader, newHeader) {
    const { data } = await api.put(`/restaurant/headers/${restaurantId}`, {
      oldHeader,
      newHeader,
      newHeaders
    })
    return data
  },
  async updateDelivery(restaurantId, value) {
    const { data } = await api.put(`/restaurant/${restaurantId}/delivery`, {
      value
    })
    return data
  },
  async updatePickup(restaurantId, value) {
    const { data } = await api.put(`/restaurant/${restaurantId}/pickup`, {
      value
    })
    return data
  },
  async updateDisable(restaurantId, value) {
    const { data } = await api.put(`/restaurant/${restaurantId}/disable`, {
      value
    })
    return data
  },
  async updatePrices(restaurantId, percentage) {
    const { data } = await apiv2.put(`/restaurants/${restaurantId}/prices`, {
      percentage
    })
    return data
  },
  async getProductsfromRestaurant(restaurantId) {
    const { data } = await apiv2.get(`/restaurants/${restaurantId}/products`)
    return data
  },
  async updateProductlist(restaurantId, products) {
    const res = await apiv2.put(`/restaurants/${restaurantId}/products/order`, {
      products
    })
    return res
  },
  async extractCsv() {
    const result = await api.get(`/extraction/restaurants/info`, {})
    fileDownload(result.data, `Recap des Restaurants.csv`)
    return result.data
  },
  async getListFranchises() {
    const res = await api.get('/franchise')

    return res.data
  }
}

export const category = {
  async add(categoryInfos) {
    const { data } = await api.post('/category', {
      ...categoryInfos
    })
    return data
  },
  async getAll() {
    const { data } = await api.get('/category/all')
    return data
  }
}

export const option = {
  async add(restaurantId, optionInfos) {
    const { data } = await apiv2.post(`/restaurants/${restaurantId}/options`, {
      ...optionInfos
    })
    return data
  },
  async getRestaurantOptions(restaurantId) {
    const { data } = await apiv2.get(`/restaurants/${restaurantId}/options`)
    return data
  },
  async editOption(restaurantId, optionId, option) {
    let { data } = await apiv2.put(
      `/restaurants/${restaurantId}/options/${optionId}`,
      {
        ...option
      }
    )
    return data
  },
  async editTemplateOption(templateId, optionId, option) {
    let { data } = await api.put(
      `/template/${templateId}/options/${optionId}`,
      {
        ...option
      }
    )
    return data
  },
  async deleteOption(restaurantId, optionId) {
    let res = await apiv2.delete(
      `/restaurants/${restaurantId}/options/${optionId}`
    )
    return res
  },
  async confirmDeleteOption(restaurantId, optionId) {
    let res = await apiv2.delete(
      `/restaurants/${restaurantId}/options/${optionId}/confirm`
    )
    return res
  },
  async getProductsByOption(optionId, restaurantId) {
    const { data } = await apiv2.get(
      `/restaurants/${restaurantId}/options/${optionId}/products`
    )
    return data
  },
  async getTemplateProductsByOption(optionId, templateId) {
    const { data } = await api.get(
      `/template/${templateId}/options/${optionId}/products`
    )
    return data
  }
}

export const supplement = {
  async add(restaurantId, supplementInfos) {
    const { data } = await apiv2.post(
      `/restaurants/${restaurantId}/supplements`,
      {
        ...supplementInfos
      }
    )
    return data
  },
  async delete(restaurantId, supplementId) {
    const { data } = await apiv2.delete(
      `/restaurants/${restaurantId}/supplements/${supplementId}`
    )
    return data
  },
  async confirmDelete(restaurantId, supplementId) {
    const { data } = await apiv2.delete(
      `/restaurants/${restaurantId}/supplements/${supplementId}/confirm`
    )
    return data
  },
  async getRestaurantSupplements(restaurantId) {
    const { data } = await apiv2.get(`/restaurants/${restaurantId}/supplements`)
    return data
  },
  async assignToGroup(supplementId, body) {
    const { data } = await api.put(`/supplement/${supplementId}/assign`, body)
    return data
  },
  async update(restaurantId, supplementId, body) {
    const { data } = await apiv2.put(
      `/restaurants/${restaurantId}/supplements/${supplementId}`,
      body
    )
    return data
  },
  async updateTemplateSupp(templateId, supplementId, body) {
    const { data } = await api.put(
      `/template/${templateId}/supplements/${supplementId}`,
      body
    )
    return data
  }
}

export const supplementGroup = {
  async add(restaurantId, supplementGroupInfos) {
    const { data } = await api.post(
      `/restaurant/supplement-group/${restaurantId}`,
      {
        ...supplementGroupInfos
      }
    )
    return data
  },
  async edit(supplementGroupId, supplementGroupInfos) {
    const { data } = await api.put(
      `/restaurant/supplement-group/${supplementGroupId}`,
      {
        ...supplementGroupInfos
      }
    )
    return data
  },
  async getSupplementGroupsByRestaurant(restaurantId) {
    const { data } = await api.get(
      `/restaurant/${restaurantId}/supplement-group`
    )
    return data
  },
  async deleteSupplementGroup(supplementGroupId, restaurantId) {
    let res = await api.delete(
      `/restaurant/supplement-group/${supplementGroupId}/${restaurantId}`
    )
    return res
  },
  async confirmDeleteSupplementGroup(
    supplementGroupId,
    deleteSupplements,
    restaurantId
  ) {
    let res
    if (deleteSupplements) {
      res = await api.delete(
        `/restaurant/supplement-group/${supplementGroupId}/${restaurantId}/confirm?deleteSupplements=true`
      )
    } else {
      res = await api.delete(
        `/restaurant/supplement-group/${supplementGroupId}/${restaurantId}/confirm`
      )
    }
    return res
  }
}

export const product = {
  async add(restaurantId, productInfos) {
    const { data } = await api.post(`/product/`, {
      ...productInfos,
      restaurantId
    })
    return data
  },
  async uploadProductImage(productId, file) {
    const { data: uploadUrlInfo } = await api.get(
      `/product/${productId}/upload-url`
    )
    const { data } = await axios.post(uploadUrlInfo.uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Bz-File-Name': uploadUrlInfo.fileName,
        'X-Bz-Content-Sha1': 'do_not_verify',
        Authorization: `${uploadUrlInfo.authorizationToken}`
      }
    })
    return data
  },
  async addImage(productId, image) {
    const formData = new FormData()
    formData.append('image', image)
    const { data } = await api.put(`/product/image/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return data
  },
  async getImage(imagePath) {
    if (!imagePath) return
    const { data } = await apiWithoutPrefix.get(
      imagePath,
      {
        responseType: 'blob'
      }
    )
    return data
  },
  async edit(productId, productInfos) {
    const { data } = await api.put(`/product/${productId}`, {
      ...productInfos
    })
    return data
  },
  async delete(productId) {
    const { data } = await api.delete(`/product/${productId}`)
    return data
  },
  async duplicate(productId) {
    const { data } = await api.post(`/product/${productId}/duplicate`)
    return data
  },
  async PopulatedProducts(productId) {
    const { data } = await apiv2.get(`/products/${productId}`)
    return data
  },

  async deleteImage(productId) {
    const { data } = await api.delete(`/product/image/${productId}`, {})
    return data
  }
}

export const order = {
  async getOrder(orderId) {
    const { data } = await api.get(`/order/${orderId}/admin`)
    return data
  },
  async getCityOrders(cityId, page, numberPerPage, status = null) {
    const { data } = await api.get(`/order`, {
      params: { city: cityId, page, limit: numberPerPage, status }
    })
    return data
  },
  /**
   *
   * @param {Object} params
   * @param {Object} params.page Page of the pagination
   * @param {Object} params.limit Limit of the pagination
   * @param {Object} params.status Status of the order
   * @param {Object} params.city Id of the city
   */
  async getAll(params = {}) {
    const { data } = await api.get(`/order`, { params })
    return data
  },
  async getOrdersOverview(cityId = null) {
    const { data } = await api.get(`/order/overview`, {
      params: { city: cityId }
    })
    return data
  },
  async getRestaurantOrders(restaurantId) {
    const { data } = await api.get(`/order/restaurant`, {
      params: { id: restaurantId }
    })
    return data
  },
  async assignRiderToOrder(orderId, riderId) {
    const { data } = await api.put(`/order/admin/assign/${orderId}`, {
      riderId
    })
    return data
  },
  async changeOrderStatus(orderId, status) {
    const { data } = await api.post(`/order/set-status/${orderId}`, { status })
    return data
  },
  async delete(orderId) {
    const { data } = await api.delete(`/order/${orderId}`)
    return data
  },
  async editNote(orderId, note) {
    const { data } = await api.put(`/order/note/${orderId}`, { note })
    return data
  },
  async search(searchData, params = {}) {
    const { data } = await api.post(`/order/search`, searchData, {
      params
    })
    return data
  },
  async activateOrder(orderId) {
    const { data } = await api.put(`/order/${orderId}/activate`)
    return data
  },
  async getTracking(orderId) {
    const { data } = await api.get(`/order/tracking/${orderId}`)
    return data
  },
  async sendNewOrderNotifToRestaurant(orderId) {
    const { data } = await api.post(`/order/send-notif-restaurant/${orderId}`)
    return data
  },
  async generateOrdersRecapByRestaurant(restaurant, startDate, endDate) {
    const result = await api.get(
      `/order/generate-recap/${restaurant.id || restaurant._id}`,
      {
        params: { startDate, endDate }
      }
    )
    fileDownload(
      result.data,
      `Recap de ${restaurant.nom} du ${startDate} au ${endDate}.csv`
    )
    return result.data
  },
  async getPriorityOrders(cityId = null) {
    const { data } = await api.get(`/order/priority`, {
      params: { cityId }
    })
    return data?.orders
  },
  async getPriorityOrdersNotes(orderId = null) {
    const { data } = await api.get(`/order/${orderId}/priority`)
    return data
  },
  async addPriorityOrdersNote(orderId = null, note) {
    const { data } = await api.post(`/order/${orderId}/priority`, {
      isChecked: false,
      note
    })
    return data
  },
  async checkPriorityOrderAction(orderId, action) {
    const { data } = await api.put(`/order/priority/${orderId}/check`, {
      action
    })
    return data
  },
  async getRegulations(orderId) {
    const { data } = await api.get(`/order/${orderId}/regulations`)
    return data?.regulations
  },
  async deleteRegulation(id) {
    const { data } = await api.delete(`/regulation/${id}`)
    return data?.regulations
  },
  async updateRegulation(id, payload) {
    const { data } = await api.put(`/regulation/${id}`, payload)
    return data?.regulations
  },
  async isCheckoutSystemResendEnabled(orderid) {
    const { data } = await api.post(`/order/${orderid}/checkout-system/send`)
    return data
  }
}

export const regulation = {
  async add(regulationData) {
    const { data } = await api.post(`/regulation`, regulationData)
    return data
  },
  async generateRecap(startDate, endDate) {
    const result = await api.get(`/regulation/generate-recap`, {
      params: { startDate, endDate }
    })
    fileDownload(
      result.data,
      `Recap des Regulations du ${startDate} au ${endDate}.csv`
    )
    return result.data
  }
}

export const noteAdmin = {
  async generateRecap(startDate, endDate) {
    const result = await api.get(`/admin/generate-recap`, {
      params: { startDate, endDate }
    })
    fileDownload(
      result.data,
      `Recap des Notes Admin du ${startDate} au ${endDate}.csv`
    )
    return result.data
  }
}

export const region = {
  async getRegions() {
    const { data } = await api.get('/region')
    return data.regions
  }
}

export const city = {
  async getAll() {
    const { data } = await api.get(`/city/name`)
    return data
  },

  async getAllPaginated(page = 0, perPage = 20) {
    page += 1
    if (page < 1) page += 1
    const { data } = await api.get(`/city?page=${page}&limit=${perPage}`)
    return data
  },
  async getCityById(cityId) {
    const { data } = await api.get(`/city/${cityId}`)

    return data
  },

  async updateDelivery(cityId, value) {
    const { data } = await api.put(`/city/${cityId}/delivery`, { value })
    return data
  },
  async updatePickup(cityId, value) {
    const { data } = await api.put(`/city/${cityId}/pickup`, { value })
    return data
  },
  async updateRadiusTo5(cityId, value) {
    const { data } = await api.put(`/city/${cityId}/limit-5`, {
      value
    })
    return data
  },
  async updateRadiusTo10(cityId, value) {
    const { data } = await api.put(`/city/${cityId}/limit-10`, {
      value
    })
    return data
  },
  async updateIsOpenAllDay(cityId, value) {
    const { data } = await api.put(`/city/${cityId}/open-all-day`, {
      value
    })
    return data
  },
  async createCity(cityInfos) {
    const { data } = await api.post(`/city`, {
      ...cityInfos
    })

    return data
  },
  async extractCsv() {
    const result = await api.get(`/extraction/city/info`, {})
    fileDownload(result.data, `Recap des villes.csv`)
    return result.data
  },

  async getCity(cityId) {
    const { data } = await api.get(`/restaurant/city/${cityId}`) 
    return data
  },

  async updateCity(cityId, value) {
    const {data} = await api.put(`/city/${cityId}`, value)


    return data
  }
}

export const cityLog = {
  async get(params) {
    const { data } = await api.get(`/city-log`, { params })
    return data
  }
}

export const user = {
  async register(userInfos) {
    const { data } = await api.post(`/auth/register-user`, { ...userInfos })
    return data
  },
  async edit(userId, userInfos) {
    const { data } = await api.put(`/user/admin/${userId}`, { ...userInfos })
    return data
  },
  async getAll(page, limit) {
    const { data } = await api.get(`/user/all`, {
      params: { page, limit }
    })
    return data
  },
  async search(searchData) {
    const { data } = await api.post(`/user/search`, searchData)
    return data
  },
  async delete(userId) {
    const { data } = await api.delete(`/user/${userId}`)
    return data
  }
}

export const rider = {
  async getCityRiders(cityId, includeInactive) {
    const { data } = await api.get(`/rider/city/${cityId}`, { params: { includeInactive } })
    return data
  },
  async getActiveRiders() {
    const { data } = await api.get(`/rider`)
    return data
  },
  async getAll() {
    const { data } = await api.get(`/rider/all`)
    return data
  },
  async add(riderInfos) {
    if (riderInfos.isDummy === '') delete riderInfos.isDummy
    const { data } = await api.post('/auth/register', riderInfos)
    return data
  },
  async generateRecapOfAll(startDate, endDate) {
    const result = await api.get(`/rider/generate-recap`, {
      params: { startDate, endDate }
    })
    fileDownload(
      result.data,
      `Recap des Livreurs du ${startDate} au ${endDate}.csv`
    )
    return result.data
  },
  async generateRecapOfRider(rider, startDate, endDate) {
    const result = await api.get(
      `/rider/${rider._id || rider.id}/generate-recap/deliveries`,
      {
        params: { startDate, endDate }
      }
    )
    fileDownload(
      result.data,
      `Recap des Livraison de ${rider.firstName} ${rider.lastName} du ${startDate} au ${endDate}.csv`
    )
    return result.data
  },
  async editRider(riderId, riderInfos) {
    if (riderInfos.isDummy === '') delete riderInfos.isDummy
    const { data } = await api.put(`/rider/edit/${riderId}`, riderInfos)
    return data
  },
  async updateDisabled(riderId, isDisabled) {
    const { data } = await api.put(`/rider/${riderId}/disable`, {
      value: isDisabled
    })
    return data
  },
  async updateArchived(riderId, isArchived) {
    const { data } = await api.put(`/rider/${riderId}/archive`, {
      value: isArchived
    })
    return data
  },
  async deleteRider(riderId) {
    const { data } = await api.delete(`/rider/${riderId}`)
    return data
  },
  async resetCounter() {
    const { data } = await api.put(`/rider/reset-counter`)
    return data
  },

  async cityRidersWithCompletedOrders(cityId) {
    const { data } = await api.get(`/rider/city/${cityId}/completed-orders`)
    return data
  }
}

export const requestCity = {
  async addRequestCity(cityInfos) {
    const { data } = await api.post(`/request-city`, cityInfos)
    return data
  },
  async getAll() {
    const { data } = await api.get(`/request-city/managed?displayAll=true`)
    return data
  },
  async delete(requestCityId) {
    const { data } = await api.delete(`/request-city/${requestCityId}`)
    return data
  }
}

export const restaurantRequest = {
  async edit(restaurantRequestId, restaurantRequestInfo) {
    restaurantRequestInfo = {
      status: restaurantRequestInfo.status,
      note: restaurantRequestInfo.note
    }
    const { data } = await api.put(
      `/restaurant-request/${restaurantRequestId}`,
      restaurantRequestInfo
    )
    return data
  },
  async getAll(page, limit, requestCity = null) {
    const { data } = await api.get(`/restaurant-request/all`, {
      params: { page, limit, city: requestCity }
    })
    return data
  },
  async delete(restaurantRequestId) {
    const { data } = await api.delete(
      `/restaurant-request/${restaurantRequestId}`
    )
    return data
  },
}

export const issue = {

  async getIssues({cityId, restaurantId, riderId, status, type, origin, concernedParty, page = 1, limit = 20}) {
    const { data } = await api.get('/issue', {
      params: { concernedParty, page, limit, cityId, type, origin, restaurantId, riderId, status }
    })

    return data
  },

  async changeStatus({id, status}) {
    const { data } = await api.put(`/issue/${id}`, { status })
    return data
  },

  async createIssue(body) {
    const { data } = await api.post(`/issue`, body)
    return data
  },

  async getConfig() {
    const { data } = await api.get(
      `/issue/config`
    )

    return data
  }
}

export const riderRequest = {
  async edit(riderRequestId, riderRequestInfo) {
    riderRequestInfo = {
      status: riderRequestInfo.status,
      register: riderRequestInfo.register,
      contract: riderRequestInfo.contract,
      bag: riderRequestInfo.bag,
      note: riderRequestInfo.note
    }
    const { data } = await api.put(
      `/rider-request/${riderRequestId}`,
      riderRequestInfo
    )
    return data
  },
  async getAll(page, limit, requestCity = null) {
    const { data } = await api.get(`/rider-request/all`, {
      params: { page, limit, city: requestCity }
    })
    return data
  },
  async delete(riderRequestId) {
    const { data } = await api.delete(`/rider-request/${riderRequestId}`)
    return data
  }
}

export const marketing = {
  async sendNotificationsToAllUsers(body, title) {
    const { data } = await api.post(`/marketing/user/send-notification/all`, {
      body,
      title
    })
    return data
  },

  async sendNotificationsToUsersByCity(cities, body, title) {
    const citiesIds = cities.map(city => city._id || city._id)
    const { data } = await api.post(
      `/marketing/user/send-notification/cities`,
      { body, title, cities: citiesIds }
    )
    return data
  },
  async getOrderStats(startDate, endDate, cityId) {
    const { data } = await api.get(`/marketing/order/stats`, {
      params: { startDate, endDate, cityId }
    })
    return data
  },
  async getRiderStats(startDate, endDate, cityId) {
    const { data } = await api.get(`/marketing/rider/stats`, {
      params: { startDate, endDate, cityId }
    })
    return data
  },
  async getUserStats(startDate, endDate, cityId) {
    const { data } = await api.get(`/marketing/user/stats`, {
      params: { startDate, endDate, cityId }
    })
    return data
  },
  async getOrderStatsExport(startDate, endDate, cityId, isExport = true) {
    const { data } = await api.get(`/marketing/order/stats`, {
      params: { startDate, endDate, cityId, export: isExport }
    })
    fileDownload(data, `Stats Commandes du ${startDate} au ${endDate}.csv`)
    return data
  },
  async getRiderStatsExport(startDate, endDate, cityId, isExport = true) {
    const { data } = await api.get(`/marketing/rider/stats`, {
      params: { startDate, endDate, cityId, export: isExport }
    })
    fileDownload(data, `Stats Livreurs du ${startDate} au ${endDate}.csv`)
    return data
  },
  async getUserStatsExport(startDate, endDate, cityId, isExport = true) {
    const { data } = await api.get(`/marketing/user/stats`, {
      params: { startDate, endDate, cityId, export: isExport }
    })
    fileDownload(data, `Stats Utilisateurs du ${startDate} au ${endDate}.csv`)
    return data
  },
  async getNotificationHistory({targetType, page = 1, limit = 10}) {
    const { data } = await api.get(`/notification-history?targetType=${targetType}&page=${page}&limit=${limit}`)

    return data
  },
  async createNotificationByRegion(datas) {
    const {data} = await apiNotifier.post(`/wf/schedule-region-notification`, datas)

    return data
  },
  async createNotificationByCity(datas) {
    const {data} = await apiNotifier.post(`/wf/schedule-city-notification`, datas)

    return data
  },
  async searchPlanifiedNotifications(datas) {
    const {data} = await apiNotifier.post(`/wf/get-notifications`, datas)

    return data
  },
  async deleteNotification(datas) {
    const {data} = await apiNotifier.post(`/wf/cancel-notification`, datas)

    return data
  }
}

// fileDownload(
//   result.data,
//   `Recap des Restaurants du ${startDate} au ${endDate}.csv`
// )

export const planningAPI = {
  async getCityPlannings(cityId,startWeek) {
    const { data } = await api.get(`/planning/plannings?city=${cityId}&startWeek=${startWeek}`)
    return data
  },
  async getAll() {
    const { data } = await api.get(`/planning/plannings?all=true`)
    return data
  },
  async createPlanning(riderId, startWeekDate, week) {
    const result = await api.post(`/planning/${riderId}`, {
      startWeekDate,
      week
    })
    return result.data //req.params.riderId, req.body.startWeekDate, req.body.week
  },
  async updatePlanning(planningId, startWeekDate, week) {
    const { data } = await api.put(`/planning/${planningId}/admin`, {
      startWeekDate,
      week
    })
    return data
  },
  async updatePlanningStatusAccept(planningId, planning) {
    const { data } = await api.put(
      `/planning/${planningId}/accept?day=${planning.day}&session=${planning.session}`,
      planning
    )
    return data
  },
  async updatePlanningStatusReserve(planningId, planning) {
    const { data } = await api.put(
      `/planning/${planningId}/reserve?day=${planning.day}&session=${planning.session}`,
      planning
    )
    return data
  },
  async updatePlanningStatusDeny(planningId, planning) {
    const { data } = await api.put(
      `/planning/${planningId}/deny?day=${planning.day}&session=${planning.session}`,
      planning
    )
    return data
  },
  async deletePlanningSession(planningId, day, session) {
    await api.delete(`/planning/${planningId}/admin?session=${session}`, {
      data: { day }
    })
  },
  async deletePlanning(planningId) {
    await api.delete(`/planning/${planningId}/admin?deleteAll=true`)
  }
}

export const planningConfigAPI = {
  async getPlanningConfigByCity(cityId) {
    const { data } = await api.get(`/planning-config/city/${cityId}`)
    return data
  },
  async updatePlanningConfigByCity(planningConfigId, planningConfig) {
    const { data } = await api.put(
      `/planning-config/${planningConfigId}`,
      planningConfig
    )
    return data
  },
  async getWarningsByCity(cityId) {
    const { data } = await api.get(`/planning-config/warnings/${cityId}`)
    return data
  },
  async setDisplayWarning(planningConfigId, day, weekType) {
    const { data } = await api.put(
      `/planning-config/display/${planningConfigId}`,
      {
        weekType,
        [day]: false
      }
    )
    return data
  }
}

export const invoiceAPI = {
  async getRestaurantInvoiceInfos(cityId) {
    const { data } = await api.get(`/invoice/${cityId}/restaurants-info`)
    return data
  },
  async generateInvoice(restaurant, month, year) {
    const result = await api.get(
      `/invoice/restaurants/${restaurant.id || restaurant._id}`,
      {
        params: { month, year },
        responseType: 'blob'
      }
    )

    fileDownload(
      result.data,
      `Facture ${restaurant.nom} du ${month}-${year}.pdf`
    )

    // const file = new Blob([result.data], {type: 'application/pdf'})

    // //Build a URL from the file
    // const fileURL = URL.createObjectURL(file)

    // //Open the URL on new Window
    // window.open(fileURL);
    return result.data
  },

  async generateRiderInvoice(rider, month, year) {
    const result = await api.get(`/invoice/riders/${rider.id || rider._id}`, {
      params: { month, year },
      responseType: 'blob'
    })

    fileDownload(
      result.data,
      `Facture ${rider.firstName} ${rider.lastName} du ${month}-${year}.pdf`
    )
    return result.data
  }
}

export const promoCode = {
  async createPromoCode(promoCodeInfos) {
    const { data } = await api.post(`/promo-code`,{
      ...promoCodeInfos
    })
    return data
  }
}

export const ads = {
  async addAd(body) {
    const res = await api.post(`/ad/`, body)
    return res
  },
  async getAds() {
    const res = await api.get('/ad/general')
    return res.data.ads
  },
  async updateAds(AdId, body) {
    const data = await api.put(`/ad/${AdId}`, body)
    return data.data
  },
  async deleteAd(adId) {
    const data = await api.delete(`/ad/${adId}`)
    return data
  },
  async getAdByCity(cityid) {
    const res = await api.get(`/ad/city/${cityid}`)
    return res.data
  },
  async getSingleAd(adId) {
    const res = await api.get(`/ad/${adId}`)
    return res.data
  },
  async uploadAdImage(adId, file) {
    const { data: uploadUrlInfo } = await api.get(`/ad/${adId}/upload-url`)
    const { data } = await axios.post(uploadUrlInfo.uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Bz-File-Name': uploadUrlInfo.fileName,
        'X-Bz-Content-Sha1': 'do_not_verify',
        Authorization: `${uploadUrlInfo.authorizationToken}`
      }
    })
    return data
  },
  async getImage(adId) {
    try {
      let { data } = await apiWithoutPrefix.get(`/ads/web/${adId}.png`, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      try {
        let { data } = await apiWithoutPrefix.get(`/ads/web/${adId}.jpeg`, {
          responseType: 'blob'
        })
        return data
      } catch (error) {
        try {
          let { data } = await apiWithoutPrefix.get(`/ads/web/${adId}.jpg`, {
            responseType: 'blob'
          })
          return data
        } catch (error) {
          return
        }
      }
    }
  }
}
export const claims = {
  async AcceptRejectClaim(claimId, formData) {
    const res = await api.put(`/reclamation/respond/${claimId}`, formData)
    return res
  },
  async getAllClaims() {
    const res = await api.get('/reclamation/all?type=all')
    return res.data
  },
  async getClaimsOrderGroup(
    paginationOptions,
    cityId,
    email,
    restaurantId,
    startDate,
    endDate,
    closed
  ) {
    const res = await api.get('/reclamation/group/order', {
      params: {
        page: paginationOptions.page,
        limit: paginationOptions.limit,
        city: cityId,
        email,
        restaurantId,
        startDate,
        endDate,
        closed
      }
    })
    return res.data
  },
  async getClaimDetails(ClaimId) {
    const res = await api.get(`/reclamation/${ClaimId}`)
    return res.data
  },
  async getOrderClaim(OrderId) {
    const res = await api.get(`/reclamation/order/${OrderId}`)
    return res.data
  },
  async addClaim(userId, body) {
    const res = await api.post(`/reclamation/${userId}`, body)
    return res.data
  },
  async config() {
    const res = await api.get('/reclamation/config')
    return res.data
  },
  async getImage(claimId) {
    try {
      let { data } = await apiWithoutPrefix.get(`/reclamation/${claimId}.png`, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      try {
        let { data } = await apiWithoutPrefix.get(
          `/reclamation/${claimId}.jpeg`,
          {
            responseType: 'blob'
          }
        )
        return data
      } catch (error) {
        try {
          let { data } = await apiWithoutPrefix.get(
            `/reclamation/${claimId}.jpg`,
            {
              responseType: 'blob'
            }
          )
          return data
        } catch (error) {
          return
        }
      }
    }
  },
  async uploadClaimImage(reclamationId, file) {
    const { data: uploadUrlInfo } = await api.get(
      `/reclamation/${reclamationId}/upload-url`
    )
    const { data } = await axios.post(uploadUrlInfo.uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Bz-File-Name': uploadUrlInfo.fileName,
        'X-Bz-Content-Sha1': 'do_not_verify',
        Authorization: `${uploadUrlInfo.authorizationToken}`
      }
    })
    return data
  },

  async uploadClaimResponseImage(reclamationId, file) {
    const { data: uploadUrlInfo } = await api.get(
      `/reclamation/response/${reclamationId}/upload-url`
    )
    const { data } = await axios.post(uploadUrlInfo.uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Bz-File-Name': uploadUrlInfo.fileName,
        'X-Bz-Content-Sha1': 'do_not_verify',
        Authorization: `${uploadUrlInfo.authorizationToken}`
      }
    })
    return data
  }
}
export const template = {
  async getTemplate() {
    const res = await api.get(`/template`)
    return res.data.templates
  },
  async addTemplate(formData) {
    const res = await api.post(`/template`, formData)
    return res.data
  },
  async add(templateId, optionInfos) {
    const { data } = await api.post(`/template/${templateId}/options`, {
      ...optionInfos
    })
    return data
  },
  async addSupplements(templateId, supplementInfos) {
    const { data } = await api.post(`/template/${templateId}/supplements`, {
      ...supplementInfos
    })
    return data
  },
  async getOptionBytemplateId(templateId) {
    const res = await api.get(`/template/${templateId}/options`)
    return res.data
  },
  async getSupplementsBytemplateId(templateId) {
    const res = await api.get(`/template/${templateId}/supplements`)
    return res.data
  },
  async getproductsBytemplateId(templateId) {
    const res = await api.get(`/product/template/${templateId}`)
    return res.data
  },
  // async changeProductNumber(productId, order, ids, criteria, page){
  //   const res= await api.post(`/product/dragAndDrop/${productId}/${order}/${criteria}/${page}`,{
  //     ids
  //   })
  // },
  async updateProductlist(templateId, products) {
    const res = await api.put(`/template/${templateId}/products/order`, {
      products
    })
    return res
  },
  async duplicateTemplate(templateId, newName) {
    const res = await api.post(`/template/${templateId}/duplicate`, { newName })
    return res
  },
  async deleteOption(templateId, optionId) {
    const res = await api.delete(`/option/template/${templateId}/${optionId}`)
    return res
  },
  async confirmDeleteOption(templateId, optionId) {
    const res = await api.delete(
      `/option/template/confirm/${templateId}/${optionId}`
    )
    return res
  },
  async syncProducts(templateId) {
    const res = await api.post(`/template/${templateId}/sync/products`)
    return res
  },
  async syncOptions(templateId) {
    const res = await api.post(`/template/${templateId}/sync/options`)
    return res
  },
  async syncSupplements(templateId) {
    const res = await api.post(`/template/${templateId}/sync/supplements`)
    return res
  },
  async deleteSupplement(templateId, supplementId) {
    const res = await api.delete(
      `/supplement/template/${templateId}/${supplementId}`
    )
    return res
  },
  async confirmDeleteSupplement(templateId, supplementId) {
    const res = await api.delete(
      `/supplement/template/confirm/${templateId}/${supplementId}`
    )
    return res
  },
  async restaurantsGroup(templateId, body) {
    const res = api.post(`/template/${templateId}/assign`, body)
    return res
  }
}

export const deal = {
  async getRestaurantDeals(restaurantId) {
    const { data } = await api.get(`/deal/restaurant/${restaurantId}`)
    return data
  },

  async add(dealInfos, restaurantId) {
    const result = await api.post(`/deal/`, {
      ...dealInfos,
      restaurantId
    })
    return result
  },

  async edit(dealId, { startDate, endDate, days, hour}) {
    const { data } = await api.put(`/deal/${dealId}`, {
       startDate, endDate, days, hour
    })
    return data
  },

  async delete(dealId) {
    const { data } = await api.delete(`/deal/${dealId}`)
    return data
  },

  async archive(dealId) {
    const result = await api.put(`/deal/${dealId}/archive`)
    return result
  }
}

export const riderPositionRequests = {
  async getRidersInfos(cityId) {
    const data = await api.get(`/rider/city/${cityId}/positions`)
    return data
  },

  async getRestaurantsInfos(cityId){
    const data = await api.get(`/restaurant/city/${cityId}/positions`)
    return data
  }
}
