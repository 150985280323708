import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  citiesFetchRequested,
  selectors as citiesSelectors
} from 'core.modules/cities/reducer'
import { getCity } from '../../core.modules/currentCity/Actions'
import { categoriesFetchRequested } from 'core.modules/categories/reducer'
import { getTemplates } from '../../core.modules/Template/Actions'
import FinancialManagement from 'components/FinancialManagement'
import DashboardHeader from './DashboardHeader'
import DashboardNavigation from './DashboardNavigation'
import PriorityOrders from '../PriorityOrders'
import RidersPosition from '../RidersPosition'
import RestaurantsList from 'components/RestaurantsList'
import AugmentationRestaurant from '../AugmentationRestau/AugmentationRestaurant'
import RestaurantsStatusList from 'components/RestaurantsStatusList'
import Headers from 'components/Headers'
import Products from 'components/Products'
import RidersList from 'components/RidersList'
import Options from 'components/Options'
import Supplements from 'components/Supplements'
import Requests from 'components/Requests'
import Marketing from 'components/Marketing'
import UsersList from '../UsersList'
import { FinancesPrivateRoute } from 'core.ui/PrivateRoute/PrivateRoute'
import SupplementGroupsList from '../SupplementGroupsList'
import CityList from '../CityList'
import OrderPageContainer from '../../pages/OrderPage/OrderPageContainer'
import RestaurantsStatus from '../RestaurantsStatus'
import PromoCodeForm from '../promoCodeForm'
import NewPriorityOrders from '../PriorityOrders/NewPriorityOrders'
import Ads from '../Ads/Ads'
import AddAd from '../Ads/AddAd'
import EditAd from '../Ads/EditAd'
import ClaimList from '../Claims/ClaimList'
import OrderClaimList from '../Claims/OrderClaimList'
import Addclaim from '../Claims/Addclaim'
import Addtracking from '../Claims/Addtracking'
import { isAuthorized } from '../../core.utils/utils'
import { Routes } from '../../core.utils/routes'
import { getConfig, resetError } from '../../core.modules/Claims/Actions'
import Template from '../Templates/Template'
import ProductsTemplate from '../Templates/ProductsTemplate'
import OptionsTemplate from '../Templates/OptionsTemplate'
import SupplementsTemplate from '../Templates/SupplementsTemplate'
import GroupTemplate from '../Templates/GroupTemplate'
import Deals from '../Deals/Deals'
import { getInfo } from '../../core.services/login';
import { useAuth } from '../../contexts/AuthContext';
import RestaurantsTracking from '../RestaurantsTracking';
import RidersTracking from "../RidersTracking";
import { fetchRestaurantsInfoRequested, fetchAllRestaurantsRequested } from '../../core.modules/restaurants/reducer'
import { cityInfoFetchRequested } from '../../core.modules/cities/reducer'

function Dashboard({ history, getTemplate, currentCity }) {
	const { setUserToken } = useAuth()

	const profiles = JSON.parse(localStorage.getItem('profiles') || null)

	const dispatch = useDispatch();
	const allCities = useSelector((state) => citiesSelectors.getAllCities(state));
	const [permissions, setPermissions ] = useState(JSON.parse(localStorage.getItem('permissions') || '{}'));
	useEffect(()=> {
		dispatch(getConfig())
		dispatch(resetError())
		getInfo().then((info) => { 
			localStorage.setItem('permissions', JSON.stringify(info.permissions))
			
			const userId = localStorage.getItem('userId')

			if(!userId) {
				localStorage.setItem('userId',info.id)
			}
			setPermissions(info.permissions)
		}).catch((err) => setUserToken(null))
	}, [getConfig]
	)
	const [ city, setCity ] = useState(localStorage.getItem('selectedCity') || null);

  useEffect(() => {
    dispatch(citiesFetchRequested())
    dispatch(categoriesFetchRequested())
  }, [])

  useEffect(() => {
    const selectedCity = city
    if (selectedCity) setCity(selectedCity)
    else {
      if (allCities && allCities.length > 0) {
        const collectedCities = [...allCities]
        const sortedCities = collectedCities.sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        setCity(sortedCities[0]._id)
      }
    }
  }, [allCities])

  useEffect(() => {
    localStorage.setItem('selectedCity', city)
		dispatch(cityInfoFetchRequested(city))
  }, [city])
	
  useEffect(() => {
    dispatch(getTemplates())
  }, [getTemplates])

  const onCityChange = (cityId) => {
		dispatch(cityInfoFetchRequested(cityId))
		dispatch(fetchRestaurantsInfoRequested(cityId))
		dispatch(fetchAllRestaurantsRequested(cityId))
    setCity(cityId)
  }

  return (
    <div id="dashboard-main-container">
      <DashboardHeader
        city={city}
        handleCityChange={onCityChange}
        history={history}
      />
      <DashboardNavigation />

			<div id="dashboard-content">
				<Switch>

					{permissions?.restaurant?.list && <FinancesPrivateRoute
						exact path="/"
						component={(props) => <RestaurantsStatusList city={city} {...props} />}
					/>}
					{isAuthorized(profiles, Routes.OrderRoutes) && 
						<Route path={Routes.OrderRoutes} component={(props) => <OrderPageContainer city={city} {...props} />} />
					}
					<Route path="/priorités" render={() => <PriorityOrders key="priorityOrders" city={city} />} />
					<Route path="/newprio" render={() => <NewPriorityOrders key="prio" city={city} />} />
					{isAuthorized(profiles, Routes.RiderRoutes) &&
						<Route path="/livreurs/positions" render={(props) => <RidersPosition key="ridersPosition" city={city} {...props} />} />
					}
					<Route
						path="/livreurs-tracking/:riderId?"
						render={(props) => <RidersTracking city={city} {...props} />}
					/>
					{isAuthorized(profiles, Routes.RiderRoutes) && 
						<Route path={Routes.RiderRoutes} render={(props) => <RidersList city={city} {...props} />} />
					}
					{ permissions?.product?.list && <Route path="/restaurants/headers" render={(props) => <Headers city={city} {...props} />} /> }
					{ permissions?.product?.list && <Route path="/restaurants/supplements" render={(props) => <Supplements city={city} {...props} />} /> }
					{ permissions?.product?.list && <Route
						path="/restaurants/supplements-group"
						render={(props) => <SupplementGroupsList city={city} {...props} />}
					/>}
					{ permissions?.product?.list && <Route path="/restaurants/options" render={(props) => <Options city={city} {...props} />} /> }
					{ permissions?.restaurant?.list && <Route path={Routes.RestaurantRoutes} render={(props) => <RestaurantsList city={city} {...props} />} /> }
					{ permissions?.product?.list && <Route path="/restaurant-augmentation" 
						component={(props) => <AugmentationRestaurant city={city} {...props} />}/>
 					}
					{ permissions?.restaurant?.list && <Route
						path="/restaurants-status"
						render={(props) => <RestaurantsStatus city={city} {...props} />}
					/>}
					{ permissions?.restaurant?.list && <Route
						path="/restaurants-tracking/:restaurantId?"
						render={(props) => <RestaurantsTracking city={city} {...props} />}
					/>}
					{isAuthorized(profiles, Routes.CityRoutes) && 
					<Route path={Routes.CityRoutes} render={(props) => <CityList city={city} {...props} />} />
					}
					{permissions?.user?.list && isAuthorized(profiles, Routes.UserRoutes) && 
						<FinancesPrivateRoute
							path={Routes.UserRoutes}
							component={(props) => <UsersList city={city} {...props} />}
						/>
					}
					{ permissions?.product?.list && <Route path={Routes.ProductRoutes} render={(props) => <Products city={city} {...props} />} /> }
					{permissions?.application?.list && isAuthorized(profiles, Routes.CandidatureRoutes) && 
						<Route path={Routes.CandidatureRoutes} render={(props) => <Requests city={city} {...props} />} />
					}
					{ isAuthorized(profiles, Routes.FinancialRoutes) && <FinancesPrivateRoute
						path={Routes.FinancialRoutes}
						component={(props) => <FinancialManagement city={city} {...props} />}
					/>
					}

					{ permissions?.reclamation?.list && <Route  path={`${Routes.ClaimsRoutes}/ordergroup`} render={(props) => <OrderClaimList  handleCityChange={onCityChange}city={city}{...props}   />} />}
					{ permissions?.reclamation?.list && <Route path={`${Routes.ClaimsRoutes}/add/:orderId`} render={(props)=><Addclaim {...props}/>}/>}
					{ permissions?.reclamation?.list && <Route exact path={`${Routes.ClaimsRoutes}/:id`} render={(props) => <ClaimList city={city} {...props} />} />}

					{/* Template */}
					{ permissions?.template?.list && <Route path={`${Routes.TemplatesRoutes}/Group`} render={(props) => <GroupTemplate city={city} {...props} />}/> }
					{ permissions?.template?.list && <Route path={`${Routes.TemplatesRoutes}/Options`} render={(props) => <OptionsTemplate  {...props} />}/> }
					{ permissions?.template?.list && <Route path={`${Routes.TemplatesRoutes}/Supplements`} render={(props) => <SupplementsTemplate  {...props} />}/> }
					{ permissions?.template?.list && <Route path={`${Routes.TemplatesRoutes}/Products`} render={(props) => <ProductsTemplate  {...props} />}/> }
					{ permissions?.template?.list && <Route exact path={`${Routes.TemplatesRoutes}`} render={(props) => <Template  {...props} />}/> }

					{ permissions?.marketing?.list && isAuthorized(profiles, Routes.MarketingRoutes) && <>
						<Route path={`${Routes.MarketingRoutes}/ads/add/:tabValue`} render={(props) => <AddAd city={city} {...props} />} />
						<Route exact path={`${Routes.MarketingRoutes}/ads/:id`} render={(props) => <EditAd city={city} {...props} />} />
						<Route exact path={`${Routes.MarketingRoutes}/ads`} render={(props) => <Ads city={city} {...props} />} />
						<Route path={`${Routes.MarketingRoutes}/deals`} render={(props) => <Deals city={city} {...props} />} />
						<Route exact path={`${Routes.MarketingRoutes}/tabs/:tabValue`} render={(props) => <Marketing city={city} {...props} />} />
						</>
					}


					

				</Switch>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
  currentCity: state.currentCity
})

export default connect(mapStateToProps)(Dashboard)
