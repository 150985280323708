import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  editRiderRequested,
  selectors as ridersSelectors
} from 'core.modules/riders/reducer'
import RiderForm from 'components/RiderForm'
import Snackbar from 'core.ui/Snackbar'
import { requestStates } from 'core.utils/requestStates'


import { rider } from 'core.services/api'

class RiderEdit extends Component {
  state = {
    waitingForRequest: false,
    openSnackBar: false
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleSubmit = (riderInfos, riderId) => {
    const { editRestaurant,currentCity } = this.props
    const { password, ...dataWithoutPassword } = riderInfos
    if (password !== '') {
      editRestaurant(riderId, riderInfos,currentCity)
      if(!this.state.openSnackBar){
      }
    } else {
      editRestaurant(riderId, dataWithoutPassword,currentCity)
      if(!this.state.openSnackBar){
      }
    }
    this.setState({ waitingForRequest: true })
  }
  handleCancel = () => {
    this.props.history.goBack()
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  render() {
    const { id,errorMessage } = this.props
    return (
      <React.Fragment>
        <RiderForm
          id={id}
          data={this.props.data}
          showStatus={true}
          onCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          isPasswordRequired={false}
          showLoading={this.state.waitingForRequest}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  currentRequestState: ridersSelectors.getRequestState(state),
  errorMessage: ridersSelectors.getErrorMessage(state)
})

const mapDispatchToProps = (dispatch) => ({
  editRestaurant: (riderId, riderInfos,currentCity) => {
    dispatch(editRiderRequested(riderId, riderInfos,currentCity))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RiderEdit)
