import {
    ADD_TEMPLATE,
    CHECK_TEMPLATE,
    GET_TEMPLATE,
    DISCARD_TEMPLATE,
    GET_TEMPLATE_OPTIONS,
    GET_TEMPLATE_SUPP,
    GET_TEMPLATE_PRODUCTS,
    CLEAR,
    DUPLICATE_PRODUCTS,
    DUPLICATE_TEMPLATE,
    TEMPLATE_ERROR,
    ASSIGN_RESTAURANT,
    DELETE_SUPPLEMENT,
    DELETE_OPTION,
    SYNC_PRODUCTS,
    SYNC_OPTIONS,
    SYNC_SUPPLEMENTS,
    TEMPLATE_DRAG_DROP_SUCCEEDED
  } from './Types'
  const initialState = {
    templates: [],
    loading:true,
    checkTemplate:false,
    templateid:null,
    options:[],
    supplements:[],
    products:[],
    err:null
  }
  export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
      case GET_TEMPLATE:
          return {
              ...state,
              templates:payload,
              loading:false
          }
        case ADD_TEMPLATE:
            return {
                ...state,
                templates:[payload,...state.templates],
                err:null
            }
      case TEMPLATE_DRAG_DROP_SUCCEEDED: {
        const { data } = action.payload
        return {
          ...state,
          products: data,
        }
      }
        case CHECK_TEMPLATE:
            return {
                ...state,
                checkTemplate:true,
                templateid:payload
            }
          case DISCARD_TEMPLATE:
            return {
              ...state,
              checkTemplate:false,
              templateid:null
            }
          case GET_TEMPLATE_OPTIONS:
            return {
              ...state,
              options:payload
            }
            case DELETE_SUPPLEMENT:
            case GET_TEMPLATE_SUPP:
              return {
                ...state,
                supplements:payload
              }
              case GET_TEMPLATE_PRODUCTS:
              return {
                ...state,
                products:payload.filter((product)=>!product.isArchived )
              }
              case DUPLICATE_PRODUCTS:
                return {
                  ...state,
                  products:[payload,...state.products]
                }
              case DUPLICATE_TEMPLATE:
                return {
                  ...state,
                  templates:[...state.templates,payload.newTemplate]
                }
               case ASSIGN_RESTAURANT:
                 return {
                   ...state,
                   templates:state.templates.map((template)=>template._id === payload._id ? payload:template),
                   err:null
                 }
                case DELETE_OPTION:
                  return {
                    ...state,
                    options:state.options.filter((option)=>option._id !==payload)
                  }
                  case SYNC_PRODUCTS:
                  case SYNC_OPTIONS:
                  case SYNC_SUPPLEMENTS:
                    return {
                      ...state,
                      err:null
                    }
              case CLEAR: 
              return {
                ...state,
                products:[],
                options:[],
                supplements:[],
                templateid:null
              }
              case TEMPLATE_ERROR:
                return {
                  ...state,
                  err:payload || 'erreur survenue'
                }
      default:
        return state
    }
  }
  