export const Routes = {
  ProductRoutes: '/produits',
	OrderRoutes: '/commandes',
	RiderRoutes: '/livreurs',
	CandidatureRoutes: '/candidatures',
	RestaurantRoutes: '/restaurants',
	CityRoutes: '/villes',
	UserRoutes: '/utilisateurs',
	MarketingRoutes: '/marketing',
	FinancialRoutes: '/finances',
	TemplatesRoutes:'/templates',
	ClaimsRoutes:'/Claims'
}