import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import MuiTextField from '@material-ui/core/TextField'

const styles = {
  textField: { margin: '6px 0' }
}

class TextField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.setState({ value })
    }
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({ value })
  }

  handleChangeComplete = () => {
    const { value } = this.state
    const { type } = this.props
    const output = type === 'number' ? Number(value) : value
    this.props.onChangeComplete({ target: { value: output } })
  }

  handleEnterPress = event => {
    if (event.key === 'Enter') {
      this.handleChangeComplete()
    }
  }

  render() {
    const {
      classes,
      className,
      value, // should not be passed
      onChange, // should not be passed
      onChangeComplete, // should not be passed
      onBlur, // should not be passed
      ...props
    } = this.props
    return (
      <MuiTextField
        className={classNames(classes.textField, className)}
        variant="outlined"
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.handleChangeComplete}
        onKeyPress={this.handleEnterPress}
        {...props}
      />
    )
  }
}

export default withStyles(styles)(TextField)
