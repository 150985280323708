import moment from 'moment'
import Status from './Status'
import { Alert } from '@material-ui/lab'
import MaterialTable from 'material-table'
import ParseSettings from './ParseSettings'
import { Snackbar } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import { deal } from '../../../core.services/api'
import React, { useState, useEffect } from 'react'

const DealDataGrid = ({ data }) => {
  const history = useHistory()
  const [deals, setDeals] = useState([])
  const [notifier, setNotifier] = useState({
    open: false,
    type: '',
    content: []
  })

  useEffect(() => {
    setDeals(data)
  }, [data])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setNotifier({
      open: false,
      type: '',
      content: []
    })
  }

  const archive = (row) => {
    const choice = window.confirm('Voulez-vous archiver cette offre?')
    if (choice) {
      Promise.resolve(deal.archive(row._id))
        .then((value) => {
          const result = deals.filter((item) => item._id !== row._id)
          setDeals(result)
          setNotifier({
            open: true,
            type: 'success',
            content: [{ message: 'Votre offre a bien été archivée' }]
          })
        })
        .catch((error) => {
          if (error.response) {
            const { errors } = error.response.data
            setNotifier({
              open: true,
              type: 'error',
              content: errors
            })
          }
        })
    }
  }

  const columns = [
    {
      field: 'type',
      title: 'Type',
      render: (rowData) => (
        <strong>
          {rowData.type === 'spend_x_get_y'
            ? `Dépensez x, Recevez y`
            : `x Acheté = y Offerts`}
        </strong>
      ),
      lookup: {
        spend_x_get_y: 'Dépensez x, Recevez y',
        buy_x_get_y: 'x Acheté = y Offerts'
      }
    },
    {
      field: 'startDate',
      title: 'Date de début',
      render: (rowData) =>
        `${moment(rowData.startDate).locale('fr').format('Do MMMM YYYY ')}`,
      filtering: false
    },
    {
      field: 'endDate',
      title: 'Date de fin',
      render: (rowData) =>
        `${moment(rowData.endDate).locale('fr').format('Do MMMM YYYY ')}`,
      filtering: false
    },
    {
      field: 'days',
      title: 'Jours',
      render: (rowData) => <ParseSettings days={rowData.days} />,
      filtering: false
    },
    {
      field: 'hours',
      title: 'Heures',
      render: (rowData) => `De ${rowData.hour.start} à ${rowData.hour.end}`,
      filtering: false
    },
    {
      field: 'status',
      title: 'Statut',
      render: (rowData) => <Status state={rowData?.status} />,
      lookup: {
        not_started: 'Pas débuté',
        active: 'En cours',
        ended: 'Terminé'
      }
    }
  ]

  return (
    <>
      <MaterialTable
        title={<h1>Deals</h1>}
        data={deals}
        columns={columns}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit this deal',
            onClick: (event, rowData) => {
              history.push({
                pathname: `/marketing/deals/${rowData._id}/edit`,
                state: { data: rowData },
              });
            }
          },
          {
            icon: 'archive',
            tooltip: 'Archive this deal',
            onClick: (event, rowData) => archive(rowData)
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          pageSizeOptions: [20],
          paginationType: 'stepped',
          searchFieldVariant: 'outlined',
          search: false,
          showTitle: false,
          toolbar: false,
          filtering: true
        }}
      />

      {notifier.open && (
        <Snackbar
          open={notifier.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity={notifier.type} onClose={handleClose}>
            {notifier.content.map((data) => data?.message)}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default DealDataGrid
