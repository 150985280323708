import React from 'react'
import { connect } from 'react-redux'

import { selectors as restaurantSupplementsSelectors } from 'core.modules/currentRestaurant/supplements/reducer'
import TransferList from 'core.ui/TransferList'

class ChooseSupplement extends React.Component {
  render() {
    const { onChange, allSupplements, selectedSupplements } = this.props
    return (
      <div>
        <TransferList
          list={allSupplements}
          currentRight={selectedSupplements}
          onChange={onChange}
          withMax
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allSupplements: restaurantSupplementsSelectors.getSupplements(state)
})
export default connect(mapStateToProps)(ChooseSupplement)
