import { rider } from 'core.services/api'
import {
  createRequestActions,
  parseErrorMessage
} from 'core.utils/requestActions'
import { requestStates } from 'core.utils/requestStates'
import { findIndex } from 'lodash'

export const {
  FETCH_ALL_RIDERS_REQUESTED,
  FETCH_ALL_RIDERS_SUCCEEDED,
  FETCH_ALL_RIDERS_FAILED,
  fetchAllRidersSucceeded,
  fetchAllRidersFailed,
  fetchAllRidersRequestStateReducer
} = createRequestActions('FETCH_ALL_RIDERS')

export const fetchAllRidersRequested = () => (dispatch) => {
  dispatch({
    type: FETCH_ALL_RIDERS_REQUESTED
  })
  rider
    .getAll()
    .then((data) => {
      dispatch(fetchAllRidersSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchAllRidersFailed(error))
    })
}

export const {
  FETCH_CITY_RIDERS_REQUESTED,
  FETCH_CITY_RIDERS_SUCCEEDED,
  FETCH_CITY_RIDERS_FAILED,
  fetchCityRidersSucceeded,
  fetchCityRidersFailed,
  fetchCityRidersRequestStateReducer
} = createRequestActions('FETCH_CITY_RIDERS')

export const fetchCityRidersRequested = (cityId, includeInactive) => (dispatch) => {
  dispatch({
    type: FETCH_CITY_RIDERS_REQUESTED,
    payload: {
      cityId
    }
  })
  rider
    .getCityRiders(cityId, includeInactive)
    .then((data) => {
      dispatch(fetchCityRidersSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchCityRidersFailed(error))
    })
}

export const {
  CREATE_CITY_RIDER_REQUESTED,
  CREATE_CITY_RIDER_SUCCEEDED,
  CREATE_CITY_RIDER_FAILED,
  createCityRiderSucceeded,
  createCityRiderFailed,
  createCityRiderRequestStateReducer
} = createRequestActions('CREATE_CITY_RIDER')

export const createCityRiderRequested = (riderInfos) => (dispatch) => {
  dispatch({
    type: CREATE_CITY_RIDER_REQUESTED,
    payload: {
      riderInfos
    }
  })
  rider
    .add(riderInfos)
    .then((data) => {
      dispatch(createCityRiderSucceeded(data))
    })
    .catch((error) => {
      dispatch(createCityRiderFailed(error))
    })
}

export const {
  GENERATE_RIDERS_RECAP_REQUESTED,
  GENERATE_RIDERS_RECAP_SUCCEEDED,
  GENERATE_RIDERS_RECAP_FAILED,
  generateRidersRecapSucceeded,
  generateRidersRecapFailed
} = createRequestActions('GENERATE_RIDERS_RECAP')

export const generateRidersRecapRequested = (startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: GENERATE_RIDERS_RECAP_REQUESTED
  })
  rider
    .generateRecapOfAll(startDate, endDate)
    .then((data) => {
      dispatch(generateRidersRecapSucceeded(data))
    })
    .catch((error) => {
      dispatch(generateRidersRecapFailed(error))
    })
}

export const {
  GENERATE_RIDER_RECAP_REQUESTED,
  GENERATE_RIDER_RECAP_SUCCEEDED,
  GENERATE_RIDER_RECAP_FAILED,
  generateRiderRecapSucceeded,
  generateRiderRecapFailed
} = createRequestActions('GENERATE_RIDER_RECAP')

export const generateRiderRecapRequested = (
  selectedRider,
  startDate,
  endDate
) => (dispatch) => {
  dispatch({
    type: GENERATE_RIDER_RECAP_REQUESTED
  })
  rider
    .generateRecapOfRider(selectedRider, startDate, endDate)
    .then((data) => {
      dispatch(generateRiderRecapSucceeded(data))
    })
    .catch((error) => {
      dispatch(generateRiderRecapFailed(error))
    })
}

export const {
  EDIT_RIDER_REQUESTED,
  EDIT_RIDER_SUCCEEDED,
  EDIT_RIDER_FAILED,
  editRiderSucceeded,
  editRiderFailed
} = createRequestActions('EDIT_RIDER')

export const editRiderRequested = (riderId, riderInfos,currentCity) => (dispatch) => {
  dispatch({
    type: EDIT_RIDER_REQUESTED,
    payload: {
      riderId,
      riderInfos,
      currentCity
    }
  })
  rider
    .editRider(riderId, riderInfos)
    .then((data) => {
      dispatch(editRiderSucceeded({ riderId, riderInfos,currentCity }))
    })
    .catch((error) => {
      dispatch(editRiderFailed(error))
    })
}

export const {
  UPDATE_DISABLE_RIDER_REQUESTED,
  UPDATE_DISABLE_RIDER_SUCCEEDED,
  UPDATE_DISABLE_RIDER_FAILED,
  updateDisableRiderSucceeded,
  updateDisableRiderFailed,
  updateDisableRiderStateReducer
} = createRequestActions('UPDATE_DISABLE_RIDER')

export const updateDisableRiderRequested = (riderId, isDisabled) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_DISABLE_RIDER_REQUESTED,
    payload: {
      riderId,
      isDisabled
    }
  })
  rider
    .updateDisabled(riderId, isDisabled)
    .then(() => {
      dispatch(
        updateDisableRiderSucceeded({
          riderId,
          isDisabled
        })
      )
    })
    .catch((error) => {
      dispatch(updateDisableRiderFailed(error))
    })
}

export const {
  UPDATE_ARCHIVE_RIDER_REQUESTED,
  UPDATE_ARCHIVE_RIDER_SUCCEEDED,
  UPDATE_ARCHIVE_RIDER_FAILED,
  updateArchiveRiderSucceeded,
  updateArchiveRiderFailed,
  updateArchiveRiderStateReducer
} = createRequestActions('UPDATE_ARCHIVE_RIDER')

export const updateArchiveRiderRequested = (riderId, isArchived) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_ARCHIVE_RIDER_REQUESTED,
    payload: {
      riderId,
      isArchived
    }
  })
  rider
    .updateArchived(riderId, isArchived)
    .then(() => {
      dispatch(
        updateArchiveRiderSucceeded({
          riderId,
          isArchived
        })
      )
    })
    .catch((error) => {
      dispatch(updateArchiveRiderFailed(error))
    })
}

export const {
  DELETE_RIDER_REQUESTED,
  DELETE_RIDER_SUCCEEDED,
  DELETE_RIDER_FAILED,
  deleteRiderSucceeded,
  deleteRiderFailed
} = createRequestActions('DELETE_RIDER')

export const deleteRiderRequested = (riderId) => (dispatch) => {
  dispatch({
    type: DELETE_RIDER_REQUESTED,
    payload: {
      riderId
    }
  })
  rider
    .deleteRider(riderId)
    .then((data) => {
      dispatch(deleteRiderSucceeded({ riderId }))
    })
    .catch((error) => {
      dispatch(deleteRiderFailed(error))
    })
}

export const {
  FETCH_CITY_RIDERS_COMPLETED_REQUESTED,
  FETCH_CITY_RIDERS_COMPLETED_SUCCEEDED,
  FETCH_CITY_RIDERS_COMPLETED_FAILED,
  fetchCityRidersCompletedSucceeded,
  fetchCityRidersCompletedFailed,
  fetchCityRidersCompletedRequestStateReducer
} = createRequestActions('FETCH_CITY_RIDERS_COMPLETED')

export const fetchCityRidersCompletedRequested = (cityId) => (dispatch) => {
  dispatch({
    type: FETCH_CITY_RIDERS_COMPLETED_REQUESTED,
    payload: {
      cityId
    }
  })
  rider
    .cityRidersWithCompletedOrders(cityId)
    .then((data) => {
      dispatch(fetchCityRidersCompletedSucceeded(data))
    })
    .catch((error) => {
      dispatch(fetchCityRidersCompletedFailed(error))
    })
}

export const defaultState = {
  allRiders: [],
  currentCityRiders: [],
  cityRidersWithCompletedOrders:[], 
  requestState: ''
}
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case UPDATE_DISABLE_RIDER_REQUESTED:
    case UPDATE_ARCHIVE_RIDER_REQUESTED:
    case EDIT_RIDER_REQUESTED:
    case GENERATE_RIDERS_RECAP_REQUESTED:
    case GENERATE_RIDER_RECAP_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
    case EDIT_RIDER_SUCCEEDED:
      const { currentCityRiders } = state
      const { riderId, riderInfos, currentCity } = action.payload.data
      const index = findIndex(
        currentCityRiders,
        (r) => (r.id || r._id) === riderId
      )
      if(riderInfos.city !== currentCity){
        return {
          ...state,
          currentCityRiders: [
            ...currentCityRiders.slice(0, index),
            ...currentCityRiders.slice(index + 1)
          ],
          requestState: requestStates.SUCCESS,
          errorMessage: ''
        }
      }else{
        return {
          ...state,
          currentCityRiders: [
            ...currentCityRiders.slice(0, index),
            { ...currentCityRiders[index], ...riderInfos },
            ...currentCityRiders.slice(index + 1)
          ],
          requestState: requestStates.SUCCESS,
          errorMessage: ''
        }
      }
      

    case EDIT_RIDER_FAILED: {
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
    }

    case DELETE_RIDER_REQUESTED:
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      }

    case DELETE_RIDER_SUCCEEDED: {
      const { currentCityRiders } = state
      const { riderId } = action.payload.data
      const index = findIndex(currentCityRiders, (r) => r.id === riderId)
      return {
        ...state,
        currentCityRiders: [
          ...currentCityRiders.slice(0, index),
          ...currentCityRiders.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    }

    case DELETE_RIDER_FAILED:
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      }

    case FETCH_ALL_RIDERS_REQUESTED:
      return {
        ...state,
        requestState: fetchAllRidersRequestStateReducer(state, action),
        errorMessage: ''
      }
    case FETCH_ALL_RIDERS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        allRiders: data,
        requestState: fetchAllRidersRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case FETCH_ALL_RIDERS_FAILED: {
      return {
        ...state,
        requestState: fetchAllRidersRequestStateReducer(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }

    case FETCH_CITY_RIDERS_REQUESTED:
      return {
        ...state,
        requestState: fetchCityRidersRequestStateReducer(state, action),
        errorMessage: ''
      }
    case FETCH_CITY_RIDERS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        currentCityRiders: data,
        requestState: fetchCityRidersRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case FETCH_CITY_RIDERS_FAILED: {
      return {
        ...state,
        requestState: fetchCityRidersRequestStateReducer(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    
    case CREATE_CITY_RIDER_REQUESTED:
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      }
    case CREATE_CITY_RIDER_SUCCEEDED: {
      const { data: newRider } = action.payload
      return {
        ...state,
        currentCityRiders: [...state.currentCityRiders, newRider],
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: ''
      }
    }
    case CREATE_CITY_RIDER_FAILED: {
      return {
        ...state,
        requestState: createCityRiderRequestStateReducer(state, action),
        errorMessage: parseErrorMessage(action)
      }
    }
    case GENERATE_RIDERS_RECAP_SUCCEEDED:
    case GENERATE_RIDER_RECAP_SUCCEEDED:
      return {
        ...state,
        requestState: requestStates.SUCCESS
      }
      case UPDATE_DISABLE_RIDER_SUCCEEDED: {
        const { currentCityRiders } = state
        const { riderId, isDisabled } = action.payload.data
        const index = currentCityRiders.findIndex((rider) => (rider.id || rider._id) === riderId)
        return {
          ...state,
          currentCityRiders: [
            ...currentCityRiders.slice(0, index),
            { ...currentCityRiders[index], isDisabled},
            ...currentCityRiders.slice(index + 1)
          ],
          requestState: requestStates.SUCCESS
        }
      }
    case UPDATE_ARCHIVE_RIDER_SUCCEEDED: {
      const { currentCityRiders } = state
      const { riderId, isArchived } = action.payload.data
      const index = currentCityRiders.findIndex((rider) => (rider.id || rider._id) === riderId)
      return {
        ...state,
        currentCityRiders: [
          ...currentCityRiders.slice(0, index),
          { ...currentCityRiders[index], isArchived},
          ...currentCityRiders.slice(index + 1)
        ],
        requestState: requestStates.SUCCESS
      }
    }
    case GENERATE_RIDERS_RECAP_FAILED:
    case UPDATE_DISABLE_RIDER_FAILED:
    case UPDATE_ARCHIVE_RIDER_FAILED:
    case GENERATE_RIDER_RECAP_FAILED:
      return {
        ...state,
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
      case FETCH_CITY_RIDERS_COMPLETED_REQUESTED:
        return {
          ...state,
          requestState: fetchCityRidersCompletedRequestStateReducer(state, action),
          errorMessage: ''
        }
      case FETCH_CITY_RIDERS_COMPLETED_SUCCEEDED: {
        const { data } = action.payload
        return {
          ...state,
          cityRidersWithCompletedOrders: data,
          requestState: fetchCityRidersCompletedRequestStateReducer(state, action),
          errorMessage: ''
        }
      }
      case FETCH_CITY_RIDERS_COMPLETED_FAILED: {
        return {
          ...state,
          requestState: fetchCityRidersCompletedRequestStateReducer(state, action),
          errorMessage: parseErrorMessage(action)
        }
      }
  
    default:
      return state
  }
}

const getState = (state) => state.riders
const getAllRiders = (state) => getState(state).allRiders
const getCurrentCityRiders = (state) => getState(state).currentCityRiders
const getCityRidersWithCompltedOrders = (state) => getState(state).cityRidersWithCompletedOrders
const getRequestState = (state) => getState(state).requestState
const getErrorMessage = (state) => getState(state).errorMessage

export const selectors = {
  getState,
  getAllRiders,
  getCurrentCityRiders,
  getRequestState,
  getCityRidersWithCompltedOrders,
  getErrorMessage
}
