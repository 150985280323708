import React, { useEffect, useState } from 'react'
import List from 'core.ui/List'
import { connect, useDispatch } from 'react-redux'
import {
  getClaimsOrderGroup,
  goback,
  getConfig
} from '../../core.modules/Claims/Actions'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Link } from 'react-router-dom'
import InfoIcon from '@material-ui/icons/Info'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import TimerIcon from '@material-ui/icons/Timer'
import moment from 'moment'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import Badge from '@material-ui/core/Badge'
import { Button, MenuItem, Select, Switch, TextField } from '@material-ui/core'
import { selectors as citiesSelectors } from 'core.modules/cities/reducer'
import Autocomplete from '@material-ui/lab/Autocomplete'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { fetchAllRestaurantsRequested } from '../../core.modules/restaurants/reducer'
import SearchIcon from '@material-ui/icons/Search'
import { loadClaimPage } from '../../pages/OrderPage/OrderList/ui-slice'
import { restaurant, city } from '../../core.services/api'

const OrderClaimList = ({
  getClaimsOrderGroup,
  Claims: { ClaimGroup, loading, config, filter },
  goback,
  getConfig,
  cities = [],
  restaurants
}) => {
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const [search, setSearch] = useState('')
  const [cityId, setCityId] = useState('')
  const [email, setEmail] = useState('')
  const [restaurantId, setRestaurantId] = useState('')
  const [claimDate, setClaimDate] = useState({
    startDate: '',
    endDate: ''
  })
  const [listRestaurants, setListRestaurants] = useState([])

  const { startDate, endDate } = claimDate

  useEffect(() => {
    const cityId = localStorage.getItem('selectedCity')
    dispatch(fetchAllRestaurantsRequested(cityId))
    getConfig()
  }, [getConfig])

  useEffect(() => {
    getClaimsOrderGroup(
      filter.pagination,
      filter.city,
      filter.email,
      filter.restaurantId,
      filter.startDate,
      filter.endDate,
      checked
    )
  }, [getClaimsOrderGroup, filter.pagination.page])

  const CheckStatus = (claim) => {
    let Markstyle = { color: 'green', width: '10px', height: '10px' }
    let Markstyle1 = { color: 'orange', width: '10px', height: '10px' }
    const status =
      !loading &&
      claim.find((claim) => claim.status === config?.status?.pending?.value)
    const result = status ? (
      <span style={Markstyle1}>
        <b>
          <TimerIcon /> Ouvert
        </b>
      </span>
    ) : (
      <span style={Markstyle}>
        <b>
          <HighlightOffIcon /> Fermé
        </b>
      </span>
    )
    return result
  }
  const renderRestaurantNameAndCity = (claim) => {
    return (
      <div>
        {claim?.restaurant?.nom} <br /> Ville :{' '}
        {claim?.city?.name || claim?.restaurant?.city}
      </div>
    )
  }
  const renderList = (claim) => {
    return [
      moment(claim.reclamations[claim.reclamations.length - 1].created_at)
        .locale('fr')
        .format('Do MMMM YYYY HH:mm '),
      moment(claim.reclamations[claim.reclamations.length - 1].updated_at)
        .locale('fr')
        .format('Do MMMM YYYY HH:mm'),
      moment(claim.order.created_at).locale('fr').format('Do MMMM YYYY HH:mm'),
      claim.order.billNumber?.split('-')[4],
      renderRestaurantNameAndCity(claim.reclamations[0]),
      <Badge badgeContent={claim.reclamations.length} color="error">
        <NotificationImportantIcon />
      </Badge>,
      CheckStatus(claim.reclamations),
      <Link to={`/Claims/${claim.order._id}`} onClick={goback}>
        <InfoIcon />
      </Link>
    ]
  }

  const handleSelectchange = (e) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      getClaimsOrderGroup(filter.pagination, '', '', '', '', '', false)
    }
  }
  const onCityChange = (e, value) => {
    if (!value) return
    const idx = cities.map((c) => c.name).indexOf(value)
    if (cities[idx]) {
      setCityId(cities[idx].id || cities[idx]._id)
    }
  }
  const onRestaurantChange = (e, value) => {
    if (!value) return
    const { id } = value
    setRestaurantId(id)
  }
  const onCityChange1 = async (e, value) => {
    if (!value || !value.id) return

    const res = await city.getCity(value.id)

    setListRestaurants(res)
    setCityId(value.id)
  }
  const resetFilter = () => {
    setSearch('')
    if (checked) {
      getClaimsOrderGroup(filter.pagination, '', '', '', '', '', true)
    } else {
      getClaimsOrderGroup(filter.pagination, '', '', '', '', '', false)
    }
  }
  const searchByMail = () => {
    setCityId('')
    setRestaurantId('')
    if (checked) {
      getClaimsOrderGroup(
        filter.pagination,
        '',
        email,
        '',
        claimDate.startDate,
        claimDate.endDate,
        true
      )
    } else {
      getClaimsOrderGroup(
        filter.pagination,
        '',
        email,
        '',
        claimDate.startDate,
        claimDate.endDate,
        false
      )
    }
  }
  const handleDateChange = (e) => {
    setClaimDate({ ...claimDate, [e.target.name]: e.target.value })
  }
  const searchByCityDate = () => {
    setRestaurantId('')
    setEmail('')
    if (checked) {
      getClaimsOrderGroup(
        filter.pagination,
        cityId,
        '',
        '',
        claimDate.startDate,
        claimDate.endDate,
        true
      )
    } else {
      getClaimsOrderGroup(
        filter.pagination,
        cityId,
        '',
        '',
        claimDate.startDate,
        claimDate.endDate,
        false
      )
    }
  }
  /*  const searchByDates = () => {
    getClaimsOrderGroup('', '', '', claimDate.startDate, claimDate.endDate)
  } */
  const searchByRestaurantDates = () => {
    setEmail('')
    setCityId('')
    if (checked) {
      getClaimsOrderGroup(
        filter.pagination,
        '',
        '',
        restaurantId,
        claimDate.startDate,
        claimDate.endDate,
        true
      )
    } else {
      getClaimsOrderGroup(
        filter.pagination,
        '',
        '',
        restaurantId,
        claimDate.startDate,
        claimDate.endDate,
        false
      )
    }
  }
  const handleSwitchChange = (e, value) => {
    setChecked(value)
    if (value === true) {
      getClaimsOrderGroup(
        filter.pagination,
        cityId,
        email,
        restaurantId,
        claimDate.startDate,
        claimDate.endDate,
        true
      )
    } else {
      getClaimsOrderGroup(
        filter.pagination,
        cityId,
        email,
        restaurantId,
        claimDate.startDate,
        claimDate.endDate,
        false
      )
    }
  }
  return (
    <div>
      {loading ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <h3>Liste des reclamations</h3>
          <div style={{ paddingBottom: '2%' }}>
            <b> Filter les reclamations par : </b>
            <br />
            Ouvert{' '}
            <Switch
              checked={checked}
              onChange={handleSwitchChange}
              name="checked"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />{' '}
            Fermé
            <div>
              {' '}
              <Select
                value={search}
                onChange={(e) => handleSelectchange(e)}
                name="search"
                displayEmpty
              >
                {' '}
                <MenuItem value="">
                  <em>Aucun filtre</em>
                </MenuItem>
                <MenuItem value="city">Ville</MenuItem>
                <MenuItem value="restaurant">Restaurant</MenuItem>
                <MenuItem value="email">Email Client</MenuItem>
                {/*   <MenuItem value="date">Date reclamation</MenuItem> */}
              </Select>
              <Button onClick={(e) => resetFilter()}>
                <RotateLeftIcon />
              </Button>
            </div>
          </div>
          {search === 'city' ? (
            <div style={{ width: '30%', paddingLeft: '35%' }}>
              <Autocomplete
                freeSolo={true}
                options={cities.map((c) => c.name).sort()}
                style={{ width: '100%' }}
                onChange={onCityChange}
                renderInput={(params) => (
                  <TextField {...params} label="Ville" variant="outlined" />
                )}
              />
              Du
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={startDate}
                onChange={(e) => handleDateChange(e)}
                name="startDate"
              />
              Au
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={endDate}
                name="endDate"
                onChange={(e) => handleDateChange(e)}
              />
              <Button onClick={(e) => searchByCityDate()}>
                <SearchIcon />
              </Button>
            </div>
          ) : search === 'restaurant' ? (
            <div>
              <table style={{ width: '100%' }}>
                <th></th>
                <th></th>
                <tr>
                  <td>
                    <Autocomplete
                      freeSolo={true}
                      options={cities.sort()}
                      getOptionLabel={(option) => option.name}
                      style={{ width: '40%', paddingLeft: '60%' }}
                      onChange={onCityChange1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ville"
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      freeSolo={true}
                      options={listRestaurants}
                      getOptionLabel={(option) => option.nom}
                      style={{ width: '40%' }}
                      onChange={onRestaurantChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Restaurant"
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                </tr>
              </table>
              Du
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={startDate}
                onChange={(e) => handleDateChange(e)}
                name="startDate"
              />
              Au
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={endDate}
                name="endDate"
                onChange={(e) => handleDateChange(e)}
              />
              <Button onClick={(e) => searchByRestaurantDates()}>
                <SearchIcon />
              </Button>
            </div>
          ) : search === 'email' ? (
            <div>
              {' '}
              <TextField
                style={{ width: '30%' }}
                label="email Client"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              Du
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={startDate}
                onChange={(e) => handleDateChange(e)}
                name="startDate"
              />
              Au
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={endDate}
                name="endDate"
                onChange={(e) => handleDateChange(e)}
              />
              <Button onClick={(e) => searchByMail()}>
                <SearchIcon />
              </Button>
            </div>
          ) : (
            /* search === 'date' ? (
            <div>
              Du
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={startDate}
                onChange={(e) => handleDateChange(e)}
                name="startDate"
              />
              Au
              <input
                style={{ marginRight: '20px', marginLeft: '20px' }}
                type="date"
                value={endDate}
                name="endDate"
                onChange={(e) => handleDateChange(e)}
              />
              <Button onClick={(e) => searchByDates()}>
                <SearchIcon />
              </Button>
            </div>
          ) : */ <div></div>
          )}
          <List
            ids={ClaimGroup.map(
              (claim) =>
                claim.reclamations.map((recl) => recl._id) ||
                claim.reclamations.map((recl) => recl.id)
            )}
            columns={[
              'Date reclamation',
              'Date mise à jour',
              'Date commande',
              'N° Commande',
              'Restaurant',
              'Nombre Reclamations',
              'Statut',
              ''
            ]}
            // actions={{ showLogs: showDetails }}
            emptyMessage="aucune Reclamation"
            list={ClaimGroup.map((claim) => {
              return renderList(claim)
            })}
            customPage={filter.pagination.page}
            numberPerPage={filter.pagination.limit}
            onPageChange={(page) => {
              if (loading) return
              dispatch(loadClaimPage(page, filter))
            }}
          />
        </div>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  Claims: state.Claims,
  cities: citiesSelectors.getAllCities(state),
  restaurants: state.restaurants
})

export default connect(mapStateToProps, {
  getClaimsOrderGroup,
  goback,
  getConfig
})(OrderClaimList)
