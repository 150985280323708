import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Fab } from '@material-ui/core'

import { fetchAllRestaurantsRequested } from 'core.modules/restaurants/reducer'
import {
  selectors as currentRestaurantInfosSelectors,
  editCurrentRestaurantRequested,
  updateRestaurantHeadersRequested
} from 'core.modules/currentRestaurant/infos/reducer'

import SelectRestaurant from 'components/SelectRestaurant'
import TextField from 'core.ui/TextField'
import Dialog from 'core.ui/Dialog'
import List from 'core.ui/List'
import Select from 'core.ui/Select'
import { Check } from 'react-feather'
import { requestStates } from 'core.utils/requestStates'

const styles = {
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '600px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: { margin: '0', width: '200px' },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  }
}

class Headers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      indexToDelete: null,
      indexToEdit: null,
      headers: this.props.currentRestaurantHeaders,
      isOrderChanged: false
    }
  }

  componentDidMount() {
    this.props.fetchAllRestaurants()
  }

  componentWillReceiveProps({ currentRestaurantHeaders, currentRequestState }) {
    this.setState({ headers: currentRestaurantHeaders })
    if(currentRequestState === requestStates.SUCCESS){
      this.setState({isOrderChanged: false})
    }
  }


  handleEdit = (index) => {
    this.setState({
      indexToEdit: index
    })
  }

  confirmEdit = (event) => {
    const { value: headerString } = event.target
    const { headers } = this.state
    const { indexToEdit } = this.state
    if (headerString !== headers[indexToEdit]) {
      const newHeaders = [
        ...headers.slice(0, indexToEdit),
        headerString,
        ...headers.slice(indexToEdit + 1)
      ]
      this.props.updateRestaurantHeaders(newHeaders, headers[indexToEdit],headerString)
    }
    this.setState({ indexToEdit: null })
  }

  handleDelete = (index) => {
    this.setState({
      openDeleteDialog: true,
      indexToDelete: index
    })
  }

  confirmDelete = () => {
    const { indexToDelete } = this.state
    const {
      currentRestaurantHeaders,
      editCurrentRestaurantHeaders
    } = this.props
    const newHeaders = [
      ...currentRestaurantHeaders.slice(0, indexToDelete),
      ...currentRestaurantHeaders.slice(indexToDelete + 1)
    ]
    editCurrentRestaurantHeaders(newHeaders)
    this.closeDeleteDialog()
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      indexToDelete: null
    })
  }

  handleOrderChange = (i) => (e) => {
    this.setState({ isOrderChanged: true })
    const { value } = e.target
    const { headers } = this.state
    var element = headers[i]
    headers.splice(i, 1)
    headers.splice(value-1, 0, element)
    this.setState({ headers })
  }

  makeOrderSelectColumn = (h, i) => {
    const { currentRestaurantHeaders } = this.props
    var choices = Array(currentRestaurantHeaders.length)
      .fill()
      .map((_, i) => i+1)
    return (
      <Select
        value={currentRestaurantHeaders.indexOf(h)+1}
        options={choices}
        captions={choices}
        onChange={this.handleOrderChange(i)}
        disabled={[currentRestaurantHeaders.indexOf(h)+1]}
      />
    )
  }

  parseHeaders = () => {
    const { headers } = this.state
    const { classes } = this.props
    const { indexToEdit } = this.state
    return headers.map((h, i) => [
      this.makeOrderSelectColumn(h, i),
      i === indexToEdit
        ? [
            <TextField
              size="small"
              autoFocus
              className={classes.textField}
              value={h}
              onChangeComplete={this.confirmEdit}
            />
          ]
        : [h]
    ])
  }


  confirmOrderChange = () => {
    const { headers } = this.state
    this.props.editCurrentRestaurantHeaders(headers)
  }

  render() {
    const { openDeleteDialog, indexToEdit } = this.state
    const { classes, city } = this.props
    const { headers } = this.state
    const parsedHeaders = this.parseHeaders()
    return (
      <div key="list-main-container" className={classes.mainListContainer}>
        <SelectRestaurant
          key="select-restaurants"
          city={city}
        />
        <List
          className={classes.list}
          key="list-of-headers"
          list={parsedHeaders}
          columns={['Ordre', 'Nom']}
          ids={headers.map((h, i) => i)}
          actions={{
            handleEdit: this.handleEdit,
            handleDelete: this.handleDelete
          }}
          emptyMessage="Ce restaurant n’a aucune catégorie personnalisée"
          changeOrder={true}
        />
        <Dialog
          open={openDeleteDialog}
          dialogText="Confirmer la suppression"
          dialogTitle="Confirmation"
          cancelButtonCaption="Annuler"
          confirmButtonCaption="Ok"
          onCancel={this.closeDeleteDialog}
          onValidation={this.confirmDelete}
        />
        {this.state.isOrderChanged && (
          <Fab
            variant="extended"
            style={{
              marginBottom: '2%',
              backgroundColor: '#33cc99',
              color: 'white'
            }}
            onClick={this.confirmOrderChange}
          >
            <Check style={{ marginRight: '4' }} />
            Valider les modifications
          </Fab>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRestaurantHeaders: currentRestaurantInfosSelectors.getHeaders(state),
  currentRequestState: currentRestaurantInfosSelectors.getRequestState(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchAllRestaurants: () => {
    dispatch(fetchAllRestaurantsRequested())
  },
  editCurrentRestaurantHeaders: (restaurantId) => (headers) => {
    dispatch(editCurrentRestaurantRequested(restaurantId, { headers }))
  },
  updateRestaurantHeaders: (restaurantId) => (newHeaders,oldHeader,newHeader) => {
    dispatch(updateRestaurantHeadersRequested(restaurantId,newHeaders, oldHeader, newHeader))
  }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  editCurrentRestaurantHeaders: dispatchProps.editCurrentRestaurantHeaders(
    stateProps.currentRestaurantId
  ),
  updateRestaurantHeaders: dispatchProps.updateRestaurantHeaders(
    stateProps.currentRestaurantId
  )
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withStyles(styles)
)(Headers)
