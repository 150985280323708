import React, { Component } from 'react'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import {
  editRestaurantProductRequested,
  selectors as currentRestaurantProductsSelectors
} from 'core.modules/currentRestaurant/products/reducer'
import { selectors as currentRestaurantInfosSelectors } from 'core.modules/currentRestaurant/infos/reducer'
import { requestStates } from 'core.utils/requestStates'

import Snackbar from 'core.ui/Snackbar'
import ProductForm from 'components/ProductForm'
import {discardTemplate} from '../../core.modules/Template/Actions'
const styles = {}

class ProductEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRequest: false,
      openSnackBar: false
    }
  }

  componentDidUpdate(prevProps) {
    const { currentRequestState } = this.props
    const { waitingForRequest } = this.state
    if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.SUCCESS
    ) {
      this.props.history.goBack()
      this.setState({ waitingForRequest: false })
    } else if (
      waitingForRequest &&
      prevProps.currentRequestState === requestStates.PENDING &&
      currentRequestState === requestStates.FAILED
    ) {
      this.setState({ waitingForRequest: false, openSnackBar: true })
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ openSnackBar: false })
  }

  handleSubmitForm = productInfos => {
    const { data, editProduct, discardchanges} = this.props
    const id = get(data, '_id', get(data, 'id'))

    editProduct(id, productInfos)
    this.setState({ waitingForRequest: true })
    discardchanges()
  }

  handleCancel = () => {
    this.props.history.goBack()
    this.props.discardchanges()
  }

  render() {
    const { data, currentRequestState, errorMessage } = this.props
    return (
      <React.Fragment>
        <ProductForm
          onSubmitForm={this.handleSubmitForm}
          data={data}
          onCancel={this.handleCancel}
          buttonLabel="Modifier le produit"
          mainTitle="Éditer un produit"
          showLoading={currentRequestState === requestStates.PENDING}
        />
        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.handleCloseSnackbar}
          severity="error"
          message={errorMessage}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentRestaurantId: currentRestaurantInfosSelectors.getId(state),
  currentRequestState: currentRestaurantProductsSelectors.getRequestState(state),
  errorMessage: currentRestaurantProductsSelectors.getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  editProduct: (restaurantId, productInfos) => {
    dispatch(editRestaurantProductRequested(restaurantId, productInfos))
  },
  discardchanges:()=>{
    dispatch(discardTemplate())
  }
})
 
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ProductEdit)