import { combineReducers, configureStore } from '@reduxjs/toolkit'

import restaurants from 'core.modules/restaurants/reducer'
import currentRestaurant from 'core.modules/currentRestaurant/reducer'
import users from 'core.modules/users/reducer'
import riders from 'core.modules/riders/reducer'
import cities from 'core.modules/cities/reducer'
import categories from 'core.modules/categories/reducer'
import notifications from 'core.modules/notifications/reducer'
import requests from 'core.modules/requests/reducer'
import plannings from 'core.modules/plannings/reducer'
import planningConfigs from 'core.modules/planningConfigs/reducer'
import promoCode from 'core.modules/promoCode/reducer'

import uiOrderList from './../pages/OrderPage/OrderList/ui-slice'
import ads from 'core.modules/Ads/reducers'
import Claims from 'core.modules/Claims/Reducer'
import templates from 'core.modules/Template/Reducers'
import currentCity from 'core.modules/currentCity/reducers'
const reducer = combineReducers({
  restaurants,
  currentRestaurant,
  users,
  riders,
  cities,
  categories,
  notifications,
  requests,
  plannings,
  planningConfigs,
  promoCode,
  uiOrderList,
  ads,
  Claims,
  templates, 
  currentCity
})

export default configureStore({ reducer })
