import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import Dialog from 'core.ui/Dialog'
import { selectors as requestsSelectors } from 'core.modules/requests/reducer'
import List from 'core.ui/List'
import Select from 'core.ui/Select'
import TextField from 'core.ui/TextField'
import { makeDateString } from 'core.utils/parser/time'
import MatUiTextField from '@material-ui/core/TextField'

import { fetchRestaurantRequestsRequested, deleteRestaurantRequestRequested, editRestaurantRequestRequested, fetchRequestCityRequested } from '../../core.modules/requests/reducer'
import { parseRestaurantRequestRole, parseRestaurantRequestOrdersPerWeek, restaurantRequestStatus, statusColorMapping, getTranslatedStatus, statusFontColorMapping } from '../../core.utils/parser/restaurantRequest'
import Autocomplete from '@material-ui/lab/Autocomplete'

const styles = {
  list: {
    width: '1400px'
  },
  mainListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px'
  },
  addButton: {
    textDecoration: 'none !important',
    marginLeft: '10px',
    fontSize: '10px'
  },
  formTitle: {
    fontWeight: 'bold',
  },
  inputForm: {
    marginRight: '20px', 
    marginLeft: '20px'
  },
  topBarControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}


class RestaurantRequestsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      idToDelete: null,
      currentPage: 1,
      numberPerPage: 20,
      requestCity: null,
      previousRequestCity: null,
    }
  }

  componentDidMount() {
    const { fetchRequestCities } = this.props
    fetchRequestCities()
    this.setState({requestCity: 'All'}, () => {
      this.getRestaurantRequests()
    })
  }

  componentDidUpdate() {
    const { previousRequestCity, requestCity } = this.state
    if (requestCity !== previousRequestCity) {
      this.getRestaurantRequests(requestCity)
      this.setState({previousRequestCity: requestCity})
    }
  }

  handleStatusChange = (restaurantRequestIndex) => (e) => {
    const { allRestaurantRequests, editRestaurantRequest } = this.props
    const { value: status } = e.target
    const restaurantRequestId = allRestaurantRequests[restaurantRequestIndex].id || allRestaurantRequests[restaurantRequestIndex]._id
    const restaurantRequestInfos = {...allRestaurantRequests[restaurantRequestIndex], status}
    editRestaurantRequest(restaurantRequestId, restaurantRequestInfos)
  }

  handleNoteChange = (restauranRequestIndex) => (e) => {
    const { allRestaurantRequests, editRestaurantRequest } = this.props
    const { value: note } = e.target
    const restauranRequestId = allRestaurantRequests[restauranRequestIndex].id || allRestaurantRequests[restauranRequestIndex]._id
    const restauranRequestInfos = {...allRestaurantRequests[restauranRequestIndex], note}
    editRestaurantRequest(restauranRequestId, restauranRequestInfos)
  }

  makeOrderNoteTextfield = (note = '', restaurantRequestIndex) => {
    return (
    <TextField
      className={this.props.classes.noteTextField}
      style={{width:200}}
      value={note}
      onChangeComplete={this.handleNoteChange(restaurantRequestIndex)}
    />
  )}

  makeRows = (restaurantRequests, restaurantRequestIndex) => {
    const {
      restaurantName,
      firstName,
      lastName,
      email,
      phoneNumber,
      restaurantAddress,
      city,
      ordersPerWeek,
      role,
      status,
      note,
      submittedCity,
      created_at,
      updated_at
    } = restaurantRequests
    return [
        restaurantName,
        lastName + " " + firstName,
        makeDateString(created_at),
        makeDateString(updated_at),
        email,
        phoneNumber,
        restaurantAddress,
        city?.name || 'Ville Supprimée',
        submittedCity,
        parseRestaurantRequestOrdersPerWeek(ordersPerWeek),
        parseRestaurantRequestRole(role),
        this.makeStatusSelect(status, restaurantRequestIndex),
        this.makeOrderNoteTextfield(note, restaurantRequestIndex),
      ]
  }

  makeStatusSelect = (status = '', orderIndex) => {
    const statuses = Object.values(restaurantRequestStatus)
    return (
      <Select
        style={{ backgroundColor: statusColorMapping[status] }}
        value={status}
        options={statuses}
        disabled={['']}
        captions={statuses.map((status) => getTranslatedStatus(status))}
        autoFill={false}
        onChange={this.handleStatusChange(orderIndex)}
        colors={statusFontColorMapping}
      />
    )
  }

  getRestaurantRequests = () => {
    const { fetchRestaurantRequests } = this.props
    const { currentPage, numberPerPage, requestCity } = this.state
    let requestCityValue = requestCity
    if (requestCity === 'All') {
      requestCityValue = null
    }
    fetchRestaurantRequests(currentPage, numberPerPage, requestCityValue);
  }

  handlePageChange = page => {
    this.setState({ currentPage: page }, () => {
      this.getRestaurantRequests()
    })
  }

  handleRequestCityChange = e => {
    const { requestCity } = this.state
    let value = e.target.value
    this.setState({ 
      previousRequestCity: requestCity,
      requestCity: value
    })
  }

  handleDelete = id => {
    this.setState({
      openDeleteDialog: true,
      idToDelete: id
    })
  }
  
  confirmDelete = () => {
    this.props.deleteRestaurantRequest(this.state.idToDelete)
    this.closeDeleteDialog()
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      idToDelete: null
    })
  }

  handleRequestCityAutoCompleteChange = (e, value) => {
    if (value?.id) {
      const {  allRequestCities } = this.props
      const newRequestCity = allRequestCities.find(requestCityElem => requestCityElem.id === value?.id)
      if (newRequestCity) this.setState({ requestCity: newRequestCity?.id })
    } else this.setState({ requestCity: null }) 
  }

  render() {
    const { allRestaurantRequests, allRequestCities, classes, history } = this.props
    const { openDeleteDialog, requestCity } = this.state
    const ids = allRestaurantRequests.map((restaurantRequest) => restaurantRequest.id)
    const parsedRestaurantRequests = allRestaurantRequests.map(this.makeRows)
    return (
      <React.Fragment>
        <div style={styles.mainListContainer}>
          Candidatures de restaurant
        </div>
        <div className={classes.topBarControls}>
          Tri par ville:
          <Autocomplete
          options={[{ name: 'Toutes les villes' }].concat(allRequestCities)}
          getOptionLabel={(requestCity) => requestCity?.name}
          defaultValue=""
          value={allRequestCities[0]?.name}
          style={{ width: '30%',marginBottom:'1%' }}
          onChange={this.handleRequestCityAutoCompleteChange}
          renderInput={(params) => <MatUiTextField {...params} label="Ville" variant="outlined" />}
          
        /> 
        </div>
        <List
          className={classes.list}
          list={parsedRestaurantRequests}
          columns={[
            'Nom du restaurant',
            'Nom Prénom',
            'Création',
            'Mise à jour',
            'email',
            'Telephone',
            'Adresse du restaurant',
            'Ville',
            'Ville soumise',
            'Commandes prévues (par semaine)',
            'Role',
            'Status',
            'Note'
          ]}
          ids={ids}
          actions={{
            handleDelete: this.handleDelete,
          }}
          onPageChange={this.handlePageChange}
          emptyMessage="Aucune demande de restaurant"
        />
        <Dialog
          open={openDeleteDialog}
          dialogText="Confirmer la suppression"
          dialogTitle="Confirmation"
          cancelButtonCaption="Annuler"
          confirmButtonCaption="Ok"
          onCancel={this.closeDeleteDialog}
          onValidation={this.confirmDelete}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  allRequestCities: requestsSelectors.getAllRequestCities(state),
  allRestaurantRequests: requestsSelectors.getAllRestaurantRequests(state)
})

const mapDispatchToProps = dispatch => ({
  fetchRequestCities: () => {
    dispatch(fetchRequestCityRequested())
  },
  fetchRestaurantRequests: (page, limit, requestCity = null) => {
    dispatch(fetchRestaurantRequestsRequested(page, limit, requestCity))
  },
  editRestaurantRequest: (restaurantRequestId, restaurantRequestInfos) => {
    dispatch(editRestaurantRequestRequested(restaurantRequestId, restaurantRequestInfos))
  },
  deleteRestaurantRequest: (restaurantRequestId) => {
    dispatch(deleteRestaurantRequestRequested(restaurantRequestId))
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(RestaurantRequestsList)
