import React from 'react';

import CardActions from '@material-ui/core/CardActions';

import StripeAction from './StripeAction';
import DetailsAction from './DetailsAction';
import NewNoteAction from './NewNoteAction';
import { Checkbox } from '@material-ui/core';

import { order } from 'core.services/api'

export default function PriorityOrderCardActions({ currentOrder, refreshOrders }) {    
  const getOrderButtons = (status, isRestaurantLater) => {
    const buttons = {
      'waiting for payment': ['stripe', 'details', 'force'],
      failed: ['details'],
      canceled: ['details'],
      processing: ['details', 'scale'],
      later: ['details'],
      cooking: ['details'],
      'waiting for rider': ['details'],
      'driver assigned': ['details'],
      collected: ['details'],
      completed: ['details']
    }

    switch (status) {
      case 'waiting for payment':
        return buttons['waiting for payment']
      case 'failed':
        return buttons['failed']
      case 'canceled':
        return buttons['canceled']
      case 'processing':
        if (isRestaurantLater) {
          return buttons['later']
        }
        return buttons['processing']
      case 'cooking':
        return buttons['cooking']
      case 'waiting for rider':
        return buttons['waiting for rider']
      case 'driver assigned':
        return buttons['driver assigned']
      case 'collected':
        return buttons['collected']
      case 'completed':
        return buttons['completed']

      default:
        break
    }
  }

	const orderButtons = getOrderButtons(currentOrder?.status, currentOrder?.isRestaurantLater);

  const onCheck = async () => {
    try {
      await order.addPriorityOrdersNote(currentOrder._id, "-")                      
      refreshOrders()
    } catch(e) {
      console.log("Error ", e)
    } 
  }

	return (
		<CardActions>
			<div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
				<StripeAction buttons={orderButtons} />
        <DetailsAction orderId={currentOrder?._id} buttons={orderButtons} />
        <NewNoteAction currentOrder={currentOrder} refreshOrders={refreshOrders} />
        {currentOrder.isUrgent && <Checkbox
          style={{margin:0}}
          checked={false}
          onChange={onCheck}                            
          disableRipple
        />}        
			</div>
		</CardActions>
	);
}
