import { product, restaurant } from 'core.services/api'
import { createRequestActions } from 'core.utils/requestActions'
import { findIndex, has } from 'lodash'

import { requestStates } from 'core.utils/requestStates'
import { parseErrorMessage } from '../../../core.utils/requestActions'
import { fetchAllRestaurantsRequested } from '../../../core.modules/restaurants/reducer'
import { option, template } from '../../../core.services/api'
import { useHistory } from 'react-router'
export const {
  DRAG_DROP_REQUESTED,
  DRAG_DROP_SUCCEEDED,
  DRAG_DROP_FAILED,
  dragDropSucceeded,
  dragDropFailed,
  dragDropStateReducer
  

} = createRequestActions('DRAG_DROP')
export const {
  FETCH_RESTAURANT_PRODUCTS_REQUESTED,
  FETCH_RESTAURANT_PRODUCTS_SUCCEEDED,
  FETCH_RESTAURANT_PRODUCTS_FAILED,
  fetchRestaurantProductsSucceeded,
  fetchRestaurantProductsFailed,
  fetchRestaurantProductsStateReducer
} = createRequestActions('FETCH_RESTAURANT_PRODUCTS')

export const fetchRestaurantDragAndDropRequested = (ProductList, restaurantId) => async (dispatch) =>{
await restaurant.updateProductlist(restaurantId, ProductList.map((product, index) => ({ _id: product._id, order: index+1 })))
  //setup all products in redux State
  dispatch({
    type: DRAG_DROP_REQUESTED,
    data: {
      restaurantId
    }
  })
  dispatch(dragDropSucceeded(ProductList))
}
export const fetchRestaurantProductsRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_PRODUCTS_REQUESTED,
    data: {
      restaurantId: id
    }
  })
  restaurant
    .getRestaurantProducts(id)
    .then(data => {
      dispatch(fetchRestaurantProductsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantProductsFailed(error))
    })
}

export const fetchTemplateProductsRequested = id => dispatch => {
  dispatch({
    type: FETCH_RESTAURANT_PRODUCTS_REQUESTED,
    data: {
      templateId: id
    }
  })
  template
    .getproductsBytemplateId(id)
    .then(data => {
      dispatch(fetchRestaurantProductsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchRestaurantProductsFailed(error))
    })
}

export const {
  CREATE_RESTAURANT_PRODUCT_REQUESTED,
  CREATE_RESTAURANT_PRODUCT_SUCCEEDED,
  CREATE_RESTAURANT_PRODUCT_FAILED,
  createRestaurantProductSucceeded,
  createRestaurantProductFailed,
  createRestaurantProductStateReducer
} = createRequestActions('CREATE_RESTAURANT_PRODUCT')

export const createRestaurantProductRequested = (
  restaurantId,
  productInfos
) => dispatch => {
  dispatch({
    type: CREATE_RESTAURANT_PRODUCT_REQUESTED,
    data: {
      restaurantId,
      productInfos
    }
  })
  
  const localData = { 
    ...productInfos, options: productInfos.optionIds, supplements: productInfos.supplementIds,
    optionIds: productInfos.optionIds && productInfos.optionIds.map(o => ({ optionId: o._id || o.id })),
    supplementIds: productInfos.supplementIds && productInfos.supplementIds.map(s => ({ supplementId: s._id || s.id, maxNumber: s.maxNumber })) 
  }

  productInfos = {
    ...productInfos,
    optionIds: productInfos.optionIds && productInfos.optionIds.map(o => ({ optionId: o._id || o.id })),
    supplementIds: productInfos.supplementIds && productInfos.supplementIds.map(s => ({ supplementId: s._id || s.id, maxNumber: s.maxNumber }))
  }

  product
    .add((restaurantId || undefined), productInfos)
    .then(newProduct => {
      const { image } = productInfos
      if (image) {
        return Promise.all([newProduct, product.uploadProductImage(newProduct.id, image)])
      }
      return [newProduct]
    })
    .then(data => {
      dispatch(createRestaurantProductSucceeded({ ...data[0], options: localData.options, supplements: localData.supplements }))
    })
    .catch(error => {
      dispatch(createRestaurantProductFailed(error))
    })
}

export const {
  EDIT_RESTAURANT_PRODUCT_REQUESTED,
  EDIT_RESTAURANT_PRODUCT_SUCCEEDED,
  EDIT_RESTAURANT_PRODUCT_FAILED,
  editRestaurantProductSucceeded,
  editRestaurantProductFailed
} = createRequestActions('EDIT_RESTAURANT_PRODUCT')

export const editRestaurantProductRequested = (
  productId,
  productInfos
) => dispatch => {
  dispatch({
    type: EDIT_RESTAURANT_PRODUCT_REQUESTED,
    data: {
      productId,
      productInfos
    }
  })

  const localData = { 
    ...productInfos, options: productInfos.optionIds, supplements: productInfos.supplementIds,
    optionIds: productInfos.optionIds && productInfos.optionIds.map(o => ({ optionId: o._id || o.id, csInfo: o.csInfo })),
    supplementIds: productInfos.supplementIds && productInfos.supplementIds.map(s => ({ supplementId: s._id || s.id, maxNumber: s.maxNumber, csInfo: s.csInfo })) 
  }

  productInfos = {
    ...productInfos,
    optionIds: productInfos.optionIds && productInfos.optionIds.map(o => ({ optionId: o._id || o.id, csInfo: o.csInfo })),
    supplementIds: productInfos.supplementIds && productInfos.supplementIds.map(s => ({ supplementId: s._id || s.id, maxNumber: s.maxNumber, csInfo: s.csInfo }))
  }
  const { image, ...productData } = localData
  product
    .edit(productId, productInfos)
    .then(data => {
      const { image, hasImageChanged, removeImage } = productInfos
      if (hasImageChanged && image) {
        return product.uploadProductImage(productId, image)
      } else if (removeImage) {
         product.deleteImage(productId)
      }
      return data
    })
    .then(data => {
      dispatch(
        editRestaurantProductSucceeded({
          productId,
          productInfos: productData
        })
      )
 
    })
    .catch(error => {
      dispatch(editRestaurantProductFailed(error))
    })
}

export const {
  DELETE_RESTAURANT_PRODUCT_REQUESTED,
  DELETE_RESTAURANT_PRODUCT_SUCCEEDED,
  DELETE_RESTAURANT_PRODUCT_FAILED,
  deleteRestaurantProductSucceeded,
  deleteRestaurantProductFailed
} = createRequestActions('DELETE_RESTAURANT_PRODUCT')

export const deleteRestaurantProductRequested = productId => dispatch => {
  dispatch({
    type: DELETE_RESTAURANT_PRODUCT_REQUESTED,
    data: {
      productId
    }
  })
  product
    .delete(productId)
    .then(data => {
      dispatch(
        deleteRestaurantProductSucceeded({
          productId
        })
      )
    })
    .catch(error => {
      dispatch(deleteRestaurantProductFailed(error))
    })
}

export const {
  DUPLICATE_RESTAURANT_PRODUCT_REQUESTED,
  DUPLICATE_RESTAURANT_PRODUCT_SUCCEEDED,
  DUPLICATE_RESTAURANT_PRODUCT_FAILED,
  duplicateRestaurantProductSucceeded,
  duplicateRestaurantProductFailed
} = createRequestActions('DUPLICATE_RESTAURANT_PRODUCT')

export const duplicateRestaurantProductRequested = (productId, currentRestaurantId) => dispatch => {
  dispatch({
    type: DUPLICATE_RESTAURANT_PRODUCT_REQUESTED,
    data: {
      productId
    }
  })
  product
    .duplicate(productId)
    .then(data => {
      dispatch(duplicateRestaurantProductSucceeded(data))
      dispatch(fetchRestaurantProductsRequested(currentRestaurantId))
    })
    .catch(error => {
      dispatch(duplicateRestaurantProductFailed(error))
    })
}
export const {
  SEARCH_PRODUCTS_REQUESTED,
  SEARCH_PRODUCTS_SUCCEEDED,
  SEARCH_PRODUCTS_FAILED,
  searchProductsSucceeded,
  searchProductsFailed
} = createRequestActions('SEARCH_PRODUCTS')

export const searchProductsRequested = (query,restaurantId) => dispatch => {
  dispatch({
    type: SEARCH_PRODUCTS_REQUESTED,
    data: {
      restaurantId
    }
  })
  restaurant
    .searchProducts(query,restaurantId)
    .then(searchedProducts => {
      dispatch(searchProductsSucceeded(searchedProducts))
    })
    .catch(error => {
      dispatch(searchProductsFailed(error))
    })
}

export const {
  FETCH_OPTION_PRODUCTS_REQUESTED,
  FETCH_OPTION_PRODUCTS_SUCCEEDED,
  FETCH_OPTION_PRODUCTS_FAILED,
  fetchOptionProductsSucceeded,
  fetchOptionProductsFailed,
  fetchOptionProductsStateReducer
} = createRequestActions('FETCH_OPTION_PRODUCTS')

export const fetchOptionProductsRequested = optionId => dispatch => {
  dispatch({
    type: FETCH_OPTION_PRODUCTS_REQUESTED,
    data: {
      optionId: optionId
    }
  })
  option
    .getProductsByOption(optionId)
    .then(data => {
      dispatch(fetchOptionProductsSucceeded(data))
    })
    .catch(error => {
      dispatch(fetchOptionProductsFailed(error))
    })
}
export const defaultState = { list: [],searchedProducts:[],  displayingSearchResult: false, requestState: '', optionProducts:[] }
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_RESTAURANT_PRODUCTS_REQUESTED:
    case CREATE_RESTAURANT_PRODUCT_REQUESTED:
    case EDIT_RESTAURANT_PRODUCT_REQUESTED:
    case DELETE_RESTAURANT_PRODUCT_REQUESTED:
    case FETCH_OPTION_PRODUCTS_REQUESTED:
    case DUPLICATE_RESTAURANT_PRODUCT_REQUESTED:
      return { ...state, requestState: requestStates.PENDING }
      case SEARCH_PRODUCTS_REQUESTED:
        return {
          ...state,
          displayingSearchResult: true,
          requestState: requestStates.PENDING
        }
    case DRAG_DROP_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        list: data,
        requestState: requestStates.SUCCESS
      }
    }
      
    case FETCH_RESTAURANT_PRODUCTS_SUCCEEDED: {
      const { data } = action.payload
      const simplifiedProducts = data.map(p => {
        const { optionsDetails, supplementsDetails, ...rest } = p
        // const supplements = p.supplements.map(s => {
        //   const suppDetail = supplementsDetails.supplements.find(
        //     sd => sd.name === s.name
        //   )
        //   return { ...s, ...suppDetail }
        // })
        return { ...rest, options: optionsDetails, supplements: supplementsDetails }
      })
      return {
        ...state,
        list: simplifiedProducts,
        requestState: requestStates.SUCCESS
      }
    }
    case CREATE_RESTAURANT_PRODUCT_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        list: [...state.list, data],
        requestState: requestStates.SUCCESS
      }
    }
    case EDIT_RESTAURANT_PRODUCT_SUCCEEDED: {
      const { productId, productInfos } = action.payload.data
      const index = findIndex(
        state.list,
        p => p.id === productId || p._id === productId
      )
        return {
          ...state,
          list: [
           ...state.list.slice(0, index),
            { ...state.list[index], ...productInfos  },
            ...state.list.slice(index + 1)
          ],
          requestState: requestStates.SUCCESS
        }
      
      
    }
    case SEARCH_PRODUCTS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        searchedProducts: data,
        requestState: requestStates.SUCCESS,
        errorMessage: ''
      }
    }
    case DELETE_RESTAURANT_PRODUCT_SUCCEEDED: {
      const { productId } = action.payload.data
      const index = findIndex(
        state.list,
        p => p.id === productId || p._id === productId
      )
      const searchedProductsindex = state.searchedProducts.findIndex(p => p.id === productId || p._id === productId)
      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          { ...state.list[index], isArchived: true },  
          ...state.list.slice(index + 1)
        ],
        searchedProducts: [
          ...state.searchedProducts.slice(0, searchedProductsindex), 
          ...state.searchedProducts.slice(searchedProductsindex + 1)          
        ],
        requestState: requestStates.SUCCESS
      }
    }
    case DUPLICATE_RESTAURANT_PRODUCT_SUCCEEDED: {
      const { data: product } = action.payload
      return {
        ...state,
        list: [...state.list, product],
        requestState: requestStates.SUCCESS
      }
    }
    case FETCH_OPTION_PRODUCTS_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        optionProducts: data,
        requestState: requestStates.SUCCESS,
        errorMessage: ''
      }
    }
    
    case FETCH_RESTAURANT_PRODUCTS_FAILED:
    case CREATE_RESTAURANT_PRODUCT_FAILED:
    case EDIT_RESTAURANT_PRODUCT_FAILED:
    case DELETE_RESTAURANT_PRODUCT_FAILED:
    case FETCH_OPTION_PRODUCTS_FAILED:
    case DUPLICATE_RESTAURANT_PRODUCT_FAILED:
      return { 
        ...state, 
        requestState: requestStates.FAILED,
        errorMessage: parseErrorMessage(action)
      }
      case SEARCH_PRODUCTS_FAILED:
        return {
          ...state,
          searchedProducts: [],
          displayingSearchResult: false,
          requestState: requestStates.FAILED,
          errorMessage: parseErrorMessage(action)
        }
      
    default:
      return state
  }
}

const getState = state => state.currentRestaurant.products
const getRestaurantId = state => state.currentRestaurant.infos.id
const getProducts = state => getState(state).list
const getDisplayingSearchResult = state => getState(state).displayingSearchResult
const getRequestState = state => getState(state).requestState
const getSearchedProducts = state => getState(state).searchedProducts
const getProductsByOption = state => getState(state).optionProducts
const getErrorMessage = state => getState(state).errorMessage

export const selectors = {
  getState,
  getRestaurantId,
  getProducts,
  getRequestState,
  getDisplayingSearchResult,
  getSearchedProducts,
  getProductsByOption,
  getErrorMessage
}
