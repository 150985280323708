import React, { useContext } from 'react';
import createDataContext from './createDataContext';

const ACTIONS = {
	LOGIN: 'LOGIN',
	SET_TOKEN: 'SET_TOKEN',
	SET_PUSH_TOKEN: 'SET_PUSH_TOKEN',
	LOGOUT: 'LOGOUT',
	SET_USER_ID: 'SET_USER_ID'
};

const initialState = {
	user: null,
	token: null,
	pushToken: null,
	userId: null
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.LOGIN:
			return { ...state, user: action.payload.user };
		case ACTIONS.SET_TOKEN:
			return { ...state, token: action.payload.token };
		case ACTIONS.SET_PUSH_TOKEN:
			return { ...state, pushToken: action.payload.pushToken };
		case ACTIONS.SET_USER_ID:
			return {...state, userId: action.payload.userId}
		case ACTIONS.LOGOUT:
			return initialState;
		default:
			return state;
	}
};

const setUser = (dispatch) => {
	return (user) => {
		dispatch({ type: ACTIONS.LOGIN, payload: { user } });
	};
};

const setUserToken = (dispatch) => {
	return (token) => {
		dispatch({ type: ACTIONS.SET_TOKEN, payload: { token } });
	};
};

const setUserId = (dispatch) => {
	return (id) => {
		dispatch({ type: ACTIONS.SET_USER_ID, payload: { userId: id } });
	};
};

const removeUser = (dispatch) => {
	return () => {
		dispatch({ type: ACTIONS.LOGOUT });
	};
};

export const { Context, Provider } = createDataContext(
	authReducer,
	{ setUser, setUserToken, removeUser, setUserId },
	initialState
);

export const useAuth = () => {
	return useContext(Context);
};
